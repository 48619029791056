import * as MobX from "mobx";

import { IsEmail, IsNotEmpty, IsPhoneNumber, Equals } from "class-validator";

import { ModelBase } from "../../Core/Models/ModelBase";

export class ContactModel extends ModelBase<ContactModel, ContactModelDTO> {
    @MobX.observable
    @IsNotEmpty({ message: "Required" })
    @IsEmail(undefined, { message: "Invalid e-mail address" })
    public email: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Required" })
    public firstName: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Required" })
    public lastName: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Required" })
    @IsPhoneNumber("US", { message: "Invalid phone number" })
    public tel: string = "";

    @MobX.observable
    @Equals(true, { message: "Required" })
    public terms: boolean = false;

    public fromDto = (model: ContactModelDTO) => {};
    public toDto = (model: ContactModel) => {};

    @MobX.action
    public resetContact = () => {
        this.email = "";
        this.firstName = "";
        this.lastName = "";
        this.tel = "";
        this.terms = false;
    };
}

export interface ContactModelDTO {
    email: string;
    firstName: string;
    lastName: string;
    tel: string;
    terms: boolean;
}
