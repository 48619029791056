import * as MobX from "mobx";
import { ModelBase } from "../../Core/Models/ModelBase";

export class FloodDataResponseModel extends ModelBase<FloodDataResponseModel, FloodDataResponseModelDTO> {
    @MobX.observable
    public fldZone: string = "";

    @MobX.observable
    public communityNumber: string = "";

    @MobX.observable
    public mapPanel: string = "";

    @MobX.observable
    public mapSuffix: string = "";

    public fromDto = (model: FloodDataResponseModelDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                this[key] = model[key];
            }
        }
    };
    public toDto = (model: FloodDataResponseModel) => {
        return {
            fldZone: this.fldZone,
            communityNumber: this.communityNumber,
            mapPanel: this.mapPanel,
            mapSuffix: this.mapSuffix,
        };
    };

    @MobX.action
    public resetFloodData = () => {
        this.fldZone = "";
        this.communityNumber = "";
        this.mapPanel = "";
        this.mapSuffix = "";
    };
}

export interface FloodDataResponseModelDTO {
    fldZone: string;
    communityNumber: string;
    mapPanel: string;
    mapSuffix: string;
}
