import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "@material-ui/core";

export interface IComponentProps {
    padding?: string;
    margin?: string;
    fontcolor?: string;
    backgroundcolor?: string;
    additionalcss?: string;
    fontsize?: string;
    lineheight?: string;
    maxwidth?: string;
}

export const H1Hidden: any = styled.h1`
    width: 0px;
    height: 0px;
    margin: 0px;
    padding: 0px;
    text-indent: -500vh;
    overflow: hidden;
`;

export const H1WithStyle: any = styled.h1<IComponentProps>`
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-size: ${props => (props.fontsize !== undefined ? props.fontsize : "24px")};
    padding: ${props => (props.padding !== undefined ? props.padding : "unset")};
    margin: ${props => (props.margin !== undefined ? props.margin : "unset")};
    font-size: ${props => (props.fontsize !== undefined ? props.fontsize : "20px")};
    @media (max-width: 767px) {
        font-size: ${props => (props.fontsize !== undefined ? props.fontsize : "20px")};
        line-height: ${props => (props.lineheight !== undefined ? props.lineheight : "1")};
    }
    ${props => props.additionalcss};
`;

export const PreambleText: any = styled.p<IComponentProps>`
    text-align: center;
    color: ${props => (props.fontcolor !== undefined ? props.fontcolor : "#ffffff")};
    font-size: ${props => (props.fontsize !== undefined ? props.fontsize : "24px")};
    font-weight: 700;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    letter-spacing: -1.2px;
    padding: ${props => (props.padding !== undefined ? props.padding : "unset")};
    margin: ${props => (props.margin !== undefined ? props.margin : "unset")};
    span {
        color: #129bdb;
    }
    @media (max-width: 767px) {
        font-size: ${props => (props.fontsize !== undefined ? props.fontsize : "20px")};
        line-height: ${props => (props.lineheight !== undefined ? props.lineheight : "1")};
    }
    ${props => props.additionalcss};
`;

export const HeaderLogo = styled.img<IComponentProps>`
    width: 100%;
    max-width: 126px;
    @media (min-width: 768px) {
        padding: 7px 0px 0px;
    }
    height: auto;

    /*BETA logo styles */
    padding: 0px !important;
    /* margin-top: -17px; */
    width: 160px;
    max-width: 160px;

    @media (max-width: 767px) {
    }

    @media (max-width: 599px) {
        /* margin-top: -11px; */
        max-width: 140px;
    }
`;

export const HeaderLinks: any = styled.div`
    a {
        color: #ffffff;
        padding: 0px 12px;
        text-decoration: none;
        font: 700 14px "Montserrat";
        letter-spacing: -0.14px;
    }
    @media (min-width: 768px) {
        span {
            padding: 0px 5px 3px;
            border-right: solid 2px #ffffff;
        }
        span:last-child {
            border: none;
        }
    }
`;

export const HdrImg: any = styled.img<IComponentProps>`
    max-width: ${props => (props.maxwidth !== undefined ? props.maxwidth : "319px")};
    width: 100%;
    margin: ${props => (props.margin !== undefined ? props.margin : "unset")};
    @media (max-width: 767px) {
        width: 335px;
    }
    height: auto;
`;

export const HdrImgDisc: any = styled.img`
    width: 64px;
    height: auto;
    margin-top: 15px;
    @media (min-width: 768px) {
        width: 140px;
        margin-top: 33px;
    }
`;

export const USPWrapper: any = styled.div<IComponentProps>`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

export const USPHolder: any = styled.div<IComponentProps>`
    width: 100%;
    img,
    div {
        display: inline-block;
    }

    div {
        vertical-align: top;
        p,
        h2 {
            font-family: "Montserrat", Arial, Helvetica, sans-serif;
        }
    }

    img {
        margin: 0px 20px 10px;
        width: 65px;
        height: auto;
    }

    h2 {
        text-align: left;
        font-weight: Bold;
        font-size: 18px;
        letter-spacing: -0.9px;
        color: #129bdb;
        margin: 10px 0px 0px;
    }

    p {
        text-align: left;
        font-weight: Bold;
        font-size: 12px;
        letter-spacing: -0.3px;
        color: #ffffff;
        margin: 0;
        padding: 0;
    }
    @media (max-width: 767px) {
        width: 95%;
        margin: 0px auto;
        padding: 0px 15px;
        font-size: 12px;
        letter-spacing: -0.3px;
        text-align: center;

        h2,
        p {
            color: #ffffff;
            text-align: center;
        }

        h2 {
            margin: 0px;
        }

        img {
            max-width: 43px;
            margin: 0px 20px 0px;
        }
    }
`;

export const RoundLink: any = styled(Link)`
    border-radius: 68px;
    width: 101px;
    height: 101px;
    text-align: center;
    background-color: #129bdb;
    color: #ffffff;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: -0.45px;
    margin: 0px auto;
    padding-top: 38px;
    display: block;

    @media (max-width: 767px) {
        border-radius: 64px;
        width: 64px;
        height: 64px;
        font-size: 12px;
        padding-top: 21px;
    }
`;

export const HeaderAnchor = styled.a`
    position: relative;
    top: -64px;

    @media (max-width: 767px) {
        top: -56px;
    }
`;

export const BulletedListStyling: any = styled.ul`
    list-style: none;
    padding: 0px;

    li {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: -0.45px;
        line-height: 1.25;
    }
    > li::before {
        content: "•";
        color: #129bdb;
        font-size: 25px;
        padding: 5px 14px 0px 0px;
    }

    li ul {
        list-style: none;
        li::before {
            content: "•";
            color: #ffffff;
            font-size: 25px;
            padding: 5px 14px 0px 0px;
        }
    }
`;

export const AppButtonWrapper: any = styled.div`
    display: flex;
    flex-direction: row;
    max-width: 561px;
    justify-content: flex-start;

    > div:not(:last-child) {
        margin-right: 24px;
    }

    @media (max-width: 767px) {
        padding-top: 20px;
        margin-right: 0;
        justify-content: space-around;
    }
`;
