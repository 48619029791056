// Libraries
import { useObserver } from "mobx-react-lite";
import React, { useRef, ReactElement } from "react";

/**
 * File selection props interface.
 */
interface IProps {
    allowMultipleFiles?: boolean;
    control: React.ReactElement;
    fileTypes?: string;
    onChange?: (files: FileList | null) => void;
}

/**
 * File selection view.
 *
 * @param props The props required for this view.
 */
export const FileSelectionButton: React.FC<IProps> = (props: IProps) => {
    const inputReference = useRef<HTMLInputElement>(null);

    const getAllowMultipleFiles = (): boolean => {
        const allowMultipleFiles = props.allowMultipleFiles;

        return allowMultipleFiles !== null && allowMultipleFiles !== undefined ? allowMultipleFiles : true;
    };

    const getFileTypes = (): string => {
        const fileTypes = props.fileTypes;

        return fileTypes !== null && fileTypes !== undefined ? fileTypes : "";
    };

    const onFileSelectionChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const onChange = props.onChange;

        if (onChange !== null && onChange !== undefined) {
            onChange(event.target.files);
        }

        // Do this to allow the file(s) to be reselected the next time the file explorer is open.
        event.target.value = "";
    };

    const onClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        if (inputReference !== null && inputReference !== undefined) {
            inputReference!.current!.click();
        }
    };

    return useObserver(() => (
        <React.Fragment>
            <input
                accept={getFileTypes()}
                multiple={getAllowMultipleFiles()}
                onChange={onFileSelectionChange}
                ref={inputReference}
                style={{ display: "none" }}
                type="file"
            />
            {React.cloneElement(props.control, {
                onClick,
            })}
        </React.Fragment>
    ));
};
