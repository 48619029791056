// Libraries
import React from "react";

// App
import { StoresInstance } from "../../Stores";
import { useRouter } from "../../../Core/Utils";

// Components

// Styling & images

export const WidgetRedirect: React.FC = () => {
    const domainStores = StoresInstance.domain;
    const quoteRequestVM = domainStores.QuoteRequestViewModel;

    const { history, match } = useRouter();

    if (match.params) {
        // from the widget as URI - set the address by replicating the address selector action
        const { address, city, county, state, zipcode, latitude, longitude, urisource } = match.params as any;
        quoteRequestVM.setValue("address", decodeURIComponent(address));
        quoteRequestVM.setValue("city", decodeURIComponent(city));
        quoteRequestVM.setValue("county", decodeURIComponent(county));
        quoteRequestVM.setValue("state", decodeURIComponent(state));
        quoteRequestVM.setValue("zipcode", decodeURIComponent(zipcode));
        quoteRequestVM.setValue("latitude", decodeURIComponent(latitude));
        quoteRequestVM.setValue("longitude", decodeURIComponent(longitude));
        quoteRequestVM.setValue("widgetSource", decodeURIComponent(urisource));
    }

    const requestPopulated: boolean = quoteRequestVM.getValue<string>("address").length > 0 ? true : false;

    if (!requestPopulated) {
        history.push("./");
    } else {
        history.push("/step1");
    }

    return <></>;
};
