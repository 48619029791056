import { PropertyQuoteModel } from "../../Models/PropertyQuoteModel";

export default class PropertyQuoteStore {
    // #region Public Interface

    public static get instance() {
        if (this._instance === undefined) {
            this._instance = new PropertyQuoteStore();
        }

        return this._instance;
    }

    // #endregion Public Interface

    private static _instance: PropertyQuoteStore;
    private _model: PropertyQuoteModel;

    private constructor() {
        this._model = new PropertyQuoteModel();
    }

    public get model(): PropertyQuoteModel {
        return this._model;
    }
}
