// User interface friendly strings.
const CONSTRUCTIONTYPE_MASONRY = "Masonry / Concrete / Block / Solid Brick";
const CONSTRUCTIONTYPE_BRICKVENEER = "Brick Veneer";
const CONSTRUCTIONTYPE_FRAME = "Frame / Wood / Siding";
const CONSTRUCTIONTYPE_LOG = "Log";
const CONSTRUCTIONTYPE_STEEL = "Steel";
const CONSTRUCTIONTYPE_UNKNOWN = "Other, not sure";

// Transform by construction type.
export const getConstructionType = (type: string): string => {
    let name = "";

    switch (type) {
        case "masonry":
            name = CONSTRUCTIONTYPE_MASONRY;
            break;

        case "frame":
            name = CONSTRUCTIONTYPE_FRAME;
            break;

        case "brickVeneer":
            name = CONSTRUCTIONTYPE_BRICKVENEER;
            break;

        case "log":
            name = CONSTRUCTIONTYPE_LOG;
            break;

        case "steel":
            name = CONSTRUCTIONTYPE_STEEL;
            break;

        case "other":
        default:
            name = CONSTRUCTIONTYPE_UNKNOWN;
    }

    return name;
};
