import React, { useState, useEffect } from "react";
import { styled, StoresInstance, useRouter, useObserver } from "../Globals/Base";
import { Modal, Box, Typography, Link, Checkbox } from "@material-ui/core";
import { LoginBox, LoginForm, SignInButton, RememberMeLabel, ShowPasswordIcon } from "Custom/StylesAppSpecific/LoginStyling";
import { EditableInput } from "Core/Components/EditableInput";
import { LoginViewModel } from "Custom/ViewModels/LoginViewModel";
import radioYes from "Content/radioYes.svg";
import radioYesChecked from "Content/radioYes-checked.svg";
import EyeClosed from "Content/icon-Eye-closed.svg";
import EyeOpen from "Content/icon-Eye-open.svg";

const domainStores = StoresInstance.domain;

interface IProps {
    open: boolean;
    onClose?: () => void;
    onSuccess?: () => void;
    viewModel: LoginViewModel;
}

export const LoginModal: React.FC<IProps> = ({ open, onClose, onSuccess, viewModel: loginViewModel }) => {
    const { history, location, match } = useRouter();
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const doSubmit = async (e: any) => {
        e.preventDefault();
        if (loginViewModel.isModelValid()) {
            setIsLoading(true);
            let apiResult = await loginViewModel.login();
            if (apiResult !== null) {
                if (apiResult.wasSuccessful) {
                    domainStores.AccountStore.getLoginState(apiResult.payload);
                    /* const { from } = (location.state as any) || { from: { pathname: "/" } };
					if (from) {
						history.push(from.pathname);
					} */
                    onSuccess && onSuccess();
                } else {
                    if (apiResult && apiResult.errors && apiResult.errors.length > 0) {
                        setErrorMessage("* " + apiResult.errors[0].message);
                    } else {
                        setErrorMessage("* Unknown error has occurred.");
                    }
                }
            }
            setIsLoading(false);
        }
    };

    const goToForgotPassword = () => {
        history.push("/forgotpassword");
    };

    let handleShowPassword = (e: any) => {
        setShowPassword(!showPassword);
    };

    return useObserver(() => (
        <Modal open={open} onClose={onClose}>
            <SelectModalStyles>
                <div>
                    <Box border="10px #129BDB solid" borderRadius="28px" bgcolor="white" padding="30px 30px 0">
                        <LoginBox padding="0 115px 5px">
                            <span className="blue-text">Login</span> to your account
                        </LoginBox>
                        <LoginForm onSubmit={doSubmit}>
                            {errorMessage !== "" && (
                                <Typography variant="caption" style={{ color: "red" }}>
                                    {errorMessage}
                                </Typography>
                            )}
                            <label htmlFor="input-email">
                                Your email address
                                <EditableInput
                                    type="email"
                                    validateOnBlur={true}
                                    viewModel={loginViewModel}
                                    fieldName="emailAddress"
                                    inputProps={
                                        {
                                            id: "input-email",
                                            placeholder: "Your email address",
                                            variant: "outlined",
                                        } as any
                                    }
                                />
                            </label>
                            <Box position="relative">
                                <label htmlFor="input-pass" style={{ position: "relative" }}>
                                    Your password
                                    <EditableInput
                                        type={showPassword ? "text" : "password"}
                                        viewModel={loginViewModel}
                                        fieldName="password"
                                        inputProps={
                                            {
                                                id: "input-pass",
                                                placeholder: "Your password",
                                                variant: "outlined",
                                            } as any
                                        }
                                    />
                                    <ShowPasswordIcon onClick={(e: any) => handleShowPassword(e)} title={showPassword ? "Hide password" : "Show password"}>
                                        <img src={showPassword ? EyeClosed : EyeOpen} alt="" />
                                    </ShowPasswordIcon>
                                </label>
                                <Link
                                    className="forgot-pass-link"
                                    onClick={() => goToForgotPassword()}
                                    style={{
                                        position: "absolute",
                                        right: 0,
                                        bottom: loginViewModel.getValid("password") ? undefined : 0,
                                    }}
                                >
                                    Forgotten password
                                </Link>
                            </Box>
                            <RememberMeLabel
                                control={
                                    <Checkbox
                                        name="rememberMe"
                                        icon={<img src={radioYes} alt="Y" />}
                                        checkedIcon={<img src={radioYesChecked} alt="N" />}
                                        checked={loginViewModel.getValue("rememberMe")}
                                        onChange={e => loginViewModel.setValue("rememberMe", e.target.checked)}
                                    />
                                }
                                label="Keep me signed in"
                            />
                            <SignInButton type="submit" variant="contained" disabled={isLoading}>
                                {/* isLoading ? "Please Wait..." : "Sign in" */}Sign in
                            </SignInButton>
                        </LoginForm>
                    </Box>
                </div>
            </SelectModalStyles>
        </Modal>
    ));
};

const SelectModalStyles = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none !important;
    pointer-events: none;

    > div {
        /* width: 45%; * /
		min-width: 640px;
		/* max-height: 65%; * /
		/* box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.75); */
        /* display: flex;
		flex-direction: column; */
        pointer-events: all;
        /* border-radius: 5px; */
        /* height: 100px; */
        max-width: 100vw;
    }
`;
