const usLocale = "en-US";
const usCurrency = "USD";

/**
 * Formats a number into a US-locale currency value with no dollar symbol.
 *
 * @param value The value to format.
 * @param includePadding This will pad the value to two decimal places.
 */
export const moneyFormat = (value: number, includePadding: boolean = false, floor: boolean = true): string => {
    console.log("formatting", value);
    if (floor) {
        value = Math.floor(value * 100) / 100;
    }

    const format = new Intl.NumberFormat(usLocale, {
        maximumFractionDigits: includePadding ? 2 : 0,
        minimumFractionDigits: includePadding ? 2 : 0,
    });

    const ret = format.format(value);
    console.log("as", ret);
    return ret;
};

/**
 * Formats a number into a US-locale currency value with a dollar symbol.
 *
 * @param value The value to format.
 * @param includePadding This will pad the value to two decimal places.
 */
export const moneyDollarFormat = (value: number, includePadding: boolean = false): string => {
    const format = new Intl.NumberFormat(usLocale, {
        style: "currency",
        currency: usCurrency,
        maximumFractionDigits: includePadding ? 2 : 0,
        minimumFractionDigits: includePadding ? 2 : 0,
    });

    return format.format(value);
};
