import React from "react";
import { EditableInput } from "../../../Core/Components/EditableInput";
import { SettingContainer, CarrierAccountDetailStyle } from "../../StylesAppSpecific/CarrierSettingsStyling";
import { useObserver } from "mobx-react-lite";
import { CarrierSettingViewModel } from "../../ViewModels/CarrierSettingViewModel";

interface IProps {
    viewModel: CarrierSettingViewModel;
    customText?: string;
}

export const CarrierApiSetting: React.FC<IProps> = ({ viewModel, customText }) => {
    const bind = viewModel.getContext();

    return useObserver(() => (
        <>
            <div className="divider" />
            <SettingContainer>
                <div className="setting">
                    <div className="image">
                        <img src={viewModel.image} alt="" />
                    </div>
                    <div className="name-option">
                        <div className="name">
                            <h3>{viewModel.name}</h3>
                        </div>
                        <div className="about">
                            {customText ? (
                                <p>{customText}</p>
                            ) : (
                                <p>
                                    Please add your API key below to use your account with this carrier. What is an API key? This is a long code that allows you
                                    to connect with the carrier’s service. If you don't know your API key you can get it from the carrier directly. Don't worry
                                    - you can add this later by going to the carrier settings option on your user dashboard.
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                <CarrierAccountDetailStyle>
                    <h3>Your API key</h3>
                    <h3></h3>
                    <label htmlFor="agencyApiKey" className="api">
                        <EditableInput
                            viewModel={viewModel}
                            inputProps={{ placeholder: "API key", autoComplete: "new-password" }}
                            fieldName="agencyApiKey"
                            fullwidth={true}
                        />
                    </label>
                </CarrierAccountDetailStyle>
            </SettingContainer>
        </>
    ));
};
