import styled from "styled-components";

export interface IComponentProps {
    fontWeight?: string;
    fontcolor?: string;
    backgroundcolor?: string;
    hovercolor?: string;
    padding?: string;
    margin?: string;
    additionalcss?: string;
    fontsize?: string;
    textAlign?: string;
    border?: string;
    borderLeft?: string;
    borderRight?: string;
    width?: string;
}

export const ContactFormStyle: any = styled.form<IComponentProps>`
    position: relative;
    display: block;
    font-size: 16px;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-weight: 500;
    margin-bottom: 46px;
    span {
        color: #129bdb;
        font-size: 24px;
        font-weight: 700;
        letter-spacing: -1.2px;
    }

    button,
    button:hover {
        position: absolute;
        left: 40%;
        bottom: -135px;
        background: #129bdb 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 68px;
        width: 103px;
        height: 103px;
    }

    .MuiButton-label {
        color: #ffffff;
        font-weight: 700;
        font-size: 18px;
        img {
            width: 50%;
        }
    }

    button.success {
        background: #ffffff 0% 0% no-repeat padding-box;
    }

    .emailaddress {
        max-width: 607px;
        width: 100%;
        margin: 0 auto;

        input.MuiInput-input,
        input {
            background-color: #ffffff;
            padding: 8px 0px;
            font-size: 14px;
            font-weight: normal;
            text-align: center;
            color: #858585;
            border: 1px solid #8cd2f7;
            border-radius: 2px;
        }

        .MuiInput-underline:after,
        .MuiInput-underline:before {
            border-bottom: 0px;
        }
    }

    @media (max-width: 767px) {
        font-size: 14px;
        margin-bottom: 20px;
        span {
            font-size: 21px;
        }
        button,
        button:hover {
            left: 38%;
            bottom: -84px;
            border-radius: 65px;
            width: 65px;
            height: 65px;
        }

        .MuiButton-label {
            font-size: 14px;
        }

        p {
            margin: 0px 0px 5px;
            font-size: 12px;
        }
    }
`;

export const ContactDetailStyle: any = styled.div<IComponentProps>`
    width: 100%;
    height: auto;
    padding: ${props => (props.padding ? props.padding : "35px 40px")};
    //margin: 0 auto 40px;
    background-color: rgba(255, 255, 255, 0.1);
    background-repeat: no-repeat;
    border-radius: 5px;

    &.completed {
        background-color: #005fab;
    }

    @media (max-width: 767px) {
        margin: 0 auto 20px;
    }

    h2 {
        width: 100%;
        text-align: left;
        font-weight: 700;
        font-size: 24px;
        font-family: "Montserrat", Arial, Helvetica, sans-serif;
        letter-spacing: 0px;
        color: #ffffff;
        margin: 0px 0px 34px;

        @media (max-width: 767px) {
            margin-bottom: 15px;
        }
    }

    h2.contact {
        margin-top: -10px;

        @media (max-width: 767px) {
            margin-top: 0;
        }
    }

    > div:not(.contact-bottom) {
        /* needed as MUI sticks inputs in a DIV (ffs!) */
        display: inline-flex;
        width: 100%;
        padding: 14px 20px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 7px solid #f2af4c;
        border-radius: 15px;

        @media (max-width: 767px) {
            border-width: 3px;
            border-radius: 10px;
        }
    }

    input.MuiInput-input,
    input {
        text-align: left;
        font-size: 16px;
        font-family: "Montserrat", Arial, Helvetica, sans-serif;
        letter-spacing: -0.53px;

        ::placeholder {
            font-style: italic;
        }
    }

    p.Mui-error {
        position: absolute;
        top: 53px;
        right: 0;
        font-size: 0.75rem;
        color: #f44336 !important;
        text-align: left;

        &.custom {
            top: 67px;

            @media (max-width: 767px) {
                top: unset;
                bottom: -22px;
            }
        }

        /* font-style: italic; */

        @media (max-width: 767px) {
            font-size: 10px;
            top: unset;
            bottom: -35px;
            left: 0;
            white-space: nowrap;
        }
    }

    div.contact-bottom {
        margin-bottom: 5px;
    }

    @media (max-width: 767px) {
        padding: 20px;

        h2 {
            font-size: 10px;

            &.start-from {
                font-size: 14px;
            }
        }
    }
`;

export const ContactDetailV2Style: any = styled.div`
    width: 100%;
    height: auto;
    padding: 20px 40px;
    margin: 0 auto 40px;
    /*background-color: rgba(255, 255, 255, 0.1);*/
    background-repeat: no-repeat;
    border-radius: 5px;

    @media (max-width: 767px) {
        margin: 0 auto 0px;
    }

    h2 {
        width: 100%;
        text-align: left;
        font-weight: 700;
        font-size: 24px;
        font-family: "Montserrat", Arial, Helvetica, sans-serif;
        letter-spacing: 0px;
        color: #ffffff;
        margin: 0px 0px 34px;

        @media (max-width: 767px) {
            margin-bottom: 15px;
        }
    }

    h2.contact {
        margin-top: -10px;
        margin-bottom: 20px;
    }

    > div:not(.contact-bottom) {
        /* needed as MUI sticks inputs in a DIV (ffs!) */
        display: inline-flex;
        width: 100%;
        padding: 14px 20px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 7px solid #f2af4c;
        border-radius: 15px;

        @media (max-width: 767px) {
            border-width: 3px;
            border-radius: 7.5px;
        }
    }

    input.MuiInput-input,
    input {
        text-align: left;
        font-size: 16px;
        font-family: "Montserrat", Arial, Helvetica, sans-serif;
        letter-spacing: -0.53px;

        ::placeholder {
            font-style: italic;
        }
    }

    p.Mui-error {
        position: absolute;
        top: 53px;
        font-size: 14px;
        font-style: italic;

        @media (max-width: 767px) {
            bottom: -32px;
            font-size: 10px;
            top: unset;
            bottom: -32px;
        }
    }

    div.contact-bottom {
        margin-bottom: 5px;
    }

    @media (max-width: 767px) {
        padding: 20px;

        h2 {
            font-size: 10px;
        }
    }
`;

export const ContactDetailV2TextAreaStyle: any = styled.div`
    p.Mui-error {
        top: 164px !important;

        @media (max-width: 767px) {
            bottom: -32px;
            font-size: 10px;
            top: unset;
            bottom: -32px;
        }
    }
`;

export const AddressFindStyling: any = styled(ContactFormStyle)`
    p {
        margin: 0px 0px 16px;
        width: 100%;
    }
    p,
    p span {
        font-size: 32px;
        font-weight: 700;
    }
    input {
        width: 100%;
    }

    .emailaddress {
        input {
            text-align: left;
            padding: 8px;
            letter-spacing: -0.53px;
            color: rgba(0, 0, 0, 0.87);
        }

        input::placeholder {
            color: #bbbbbb;
        }
    }

    @media (max-width: 767px) {
        p,
        p span {
            font-size: 21px;
            letter-spacing: -1.05px;
            line-height: 1.1;
        }

        p span {
            display: block;
            text-align: center;
        }

        p {
            margin: 10px 0px 20px;
        }
        button,
        button:hover {
            left: 31%;
            bottom: -100px;
            border-radius: 101px;
            width: 101px;
            height: 101px;
        }

        .MuiButton-label {
            font-size: 18px;
        }

        input {
            margin-bottom: 30px;
        }
    }
`;

export const AddressInputStyling: any = styled.div`
    max-width: 660px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 5px solid #f2af4c;
    border-radius: 15px;
    padding: 14px 20px;
    @media (max-width: 767px) {
        width: 335px;
        border: 3px solid #f2af4c;
        border-radius: 10px;
        padding: 5px 20px;
    }
    input {
        border: none;
        outline: none;
        width: 100%;
        text-align: left;
        font: Bold 24px/29px Montserrat;
        letter-spacing: -0.6px;
        color: #464545;
        @media (max-width: 767px) {
            font: Bold 12px/15px Montserrat;
        }
    }
`;

export const AddressInputHome: any = styled(AddressInputStyling)`
    width: 561px;
`;

export const AddressWidgetStyling: any = styled(AddressInputStyling)`
    width: 100% !important;
    min-width: 240px;
    /* width: 300px !important; */
`;

export const DashboardAddressFind: any = styled(AddressFindStyling)`
    margin-bottom: 0px;
    .emailaddress {
        margin: 0px;
        width: 571px;
        text-align: left;
        input {
            width: 90%;
            margin: 0px;
        }
    }
    p {
        text-align: left;
        font-size: 24px;
        letter-spacing: -1.2px;
        line-height: 1;
        span {
            display: block;
            font-size: 36px;
            letter-spacing: -1.8px;
        }
    }

    button,
    button:hover {
        left: 95.5%;
        top: -5px;
        width: 101px;
        height: 101px;
    }
`;

export const MyQuotesAddressFind: any = styled(AddressFindStyling)`
    margin: 0;
    .emailaddress {
        margin: 0px;
        max-width: 960px;
        width: 100%;
        text-align: left;
        input {
            width: 90%;
            margin: 0px;
        }
    }
    p {
        text-align: left;
        font-size: 24px;
        letter-spacing: -1.2px;
        line-height: 1;
        span {
            display: block;
            font-size: 36px;
            letter-spacing: -1.8px;
        }
    }

    button,
    button:hover {
        left: 97.25%;
        top: -5px;
        width: 101px;
        height: 101px;
    }
`;

export const PropertyCoverageContactStyling = styled.div`
    @media (max-width: 767px) {
        > div > div:not(.contact-bottom) {
            border-radius: 7.5px;
            border-width: 7px;
        }
    }
`;
