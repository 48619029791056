// Libraries.
import { computed, action, observable, runInAction } from "mobx";

// Core
import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "../../Core/ViewModels/ViewModelBase";
import { ApiResult } from "Core/Models";

// Models & Stores
import { StoresInstance } from "../Stores";
import { Server } from "../Globals/AppUrls";
import { EstatedRequestModel, EstatedResponseModel, EstatedResponseModelDTO } from "../Models/";
import { roundToNearestMinutesWithOptions } from "date-fns/fp";

export class PropertyDetailViewModel extends ViewModelBase<EstatedResponseModel> {
    constructor() {
        super(new EstatedResponseModel());
        this.setDecorators(EstatedResponseModel);
    }

    public isFieldValid(fieldName: keyof FieldType<EstatedResponseModel>, value: any, showErrors: boolean = true): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        if (showErrors) {
            this.setError(fieldName, errorMessage);
            this.setValid(fieldName, isValid);
        }

        return isValid;
    }

    // Observables
    @observable
    public canProceed = false;

    @observable
    public addressPopulated = false;

    @observable
    public propertyType = "";

    @observable
    public modalState = false;

    @observable
    public modalTitle = "";

    @observable
    public modalBodyView = "";

    // Actions
    @action
    public setCanProcced(val: boolean) {
        this.canProceed = val;
    }

    @action
    public setAddressPopulated(val: boolean) {
        this.addressPopulated = val;
    }

    @action
    public setPropertyType(val: string) {
        this.propertyType = val;
    }

    @action
    public setModalState(val: boolean) {
        this.modalState = val;
    }

    @action
    public setModalTitle(val: string) {
        this.modalTitle = val;
    }

    @action
    public setModalBodyView(val: string) {
        this.modalBodyView = val;
    }

    @action
    public resetEstatedHasStructure() {
        let domainStores = StoresInstance.domain;
        domainStores.EstatedHasStructure = false;
    }

    @action
    public async submitAddress() {
        this.setIsLoading(true);
        let domainStores = StoresInstance.domain;
        let quoteRequestVM = domainStores.QuoteRequestViewModel;

        let tempStr: string = quoteRequestVM.getValue("address");
        let tempArr: string[] = tempStr.split(",");
        let tempCity: string = quoteRequestVM.getValue("city");
        let propertyRequest: EstatedRequestModel = {
            street: tempArr.length > 0 ? tempArr[0] : tempStr,
            city: tempCity.length > 0 ? tempCity : tempArr[1],
            state: quoteRequestVM.getValue("state"),
            zipcode: quoteRequestVM.getValue("zipcode"),
        };
        this.getProperty(propertyRequest).then((apiResult: ApiResult<EstatedResponseModelDTO>) => {
            if (apiResult.wasSuccessful) {
                if (domainStores.EstatedHasStructure) {
                    let hasCrawlspace: string = "false";

                    let basementType: string = apiResult.payload.data?.structure?.basement_type;
                    const hasGotBasementType = !!basementType;
                    if (hasGotBasementType) {
                        quoteRequestVM.setValue("hasBasement", basementType.toLowerCase() === "none" ? "false" : "true");
                    }
                    let standardized_land_use_type: string = apiResult.payload.data?.parcel?.standardized_land_use_type;
                    const hasGotLandUseType = !!standardized_land_use_type;
                    if (hasGotLandUseType) {
                        quoteRequestVM.setValue("isMobileHome", standardized_land_use_type.toLowerCase() === "mobile or manufactured home" ? "true" : "false");
                        if (standardized_land_use_type.toLowerCase() === "mobile or manufactured home") {
                            quoteRequestVM.setValue("occupationType", "MobileHome");
                        }
                        quoteRequestVM.addGotField("isMobileHome");
                    }
                    const gotYear = apiResult.payload.data?.structure?.year_built;
                    const hasGotYear = !!gotYear;
                    if (hasGotYear) {
                        quoteRequestVM.setValue("yearBuilt", gotYear);
                        quoteRequestVM.addGotField("yearBuilt");
                        // needed as we no longer manually set isNewProperty
                        quoteRequestVM.doIsNewProperty(gotYear);
                    }
                    const gotStories = apiResult.payload.data?.structure?.stories;
                    const hasGotStories = !!gotStories;
                    if (hasGotStories) {
                        quoteRequestVM.setValue("numberOfFloors", parseInt(gotStories));
                        quoteRequestVM.addGotField("numberOfFloors");
                    }
                    const gotSqft = apiResult.payload.data?.structure?.total_area_sq_ft;
                    const hasGotSqft = !!gotSqft;
                    if (hasGotSqft) {
                        quoteRequestVM.setValue("buildingSqft", gotSqft);
                        quoteRequestVM.addGotField("buildingSqft");
                    }
                    const gotGarage = apiResult.payload.data?.structure?.parking_type;
                    const hasGotGarage = !!gotGarage;
                    if (hasGotGarage) {
                        quoteRequestVM.setValue("hasGarage", gotGarage.toLowerCase() == "garage" ? "true" : "false");
                        quoteRequestVM.addGotField("hasGarage");
                    }
                    const foundationType: string = apiResult.payload.data?.structure?.foundation_type;
                    let newFoundType: string = "";
                    if (foundationType) {
                        switch (foundationType.toLowerCase()) {
                            case "raised": {
                                if (hasGotBasementType) {
                                    if (quoteRequestVM.getValue("hasBasement") === "true") {
                                        newFoundType = "basement";
                                    }
                                    if (quoteRequestVM.getValue("hasBasement") === "false") {
                                        newFoundType = "crawlspace";
                                        hasCrawlspace = "true";
                                    }
                                }
                                break;
                            }
                            case "slab": {
                                newFoundType = "slab";
                                break;
                            }
                            case "piers": {
                                newFoundType = "piers";
                                break;
                            }
                            default: {
                                break;
                            }
                        }
                    }
                    const hasGotFoundationType = !!newFoundType;
                    if (hasGotFoundationType) {
                        quoteRequestVM.setValue("foundationType", newFoundType);
                        quoteRequestVM.addGotField("foundationType");
                        quoteRequestVM.setValue("hasBasement", newFoundType === "basement" ? "true" : "false");
                        quoteRequestVM.addGotField("hasBasement");
                    }
                    const constructionType: string = apiResult.payload.data?.structure?.construction_type;
                    let newConstructionType: string = "";
                    if (constructionType) {
                        switch (constructionType.toLowerCase()) {
                            case "masonry": {
                                newConstructionType = "masonry";
                                break;
                            }
                            case "brick": {
                                newConstructionType = "masonry";
                                break;
                            }
                            case "frame": {
                                newConstructionType = "frame";
                                break;
                            }
                            case "wood": {
                                newConstructionType = "frame";
                                break;
                            }
                            case "concrete": {
                                newConstructionType = "masonry";
                                break;
                            }
                            case "log": {
                                newConstructionType = "log";
                                break;
                            }
                            case "steel": {
                                newConstructionType = "steel";
                                break;
                            }
                            case null:
                            case "": {
                                break;
                            }
                            default: {
                                newConstructionType = "other";
                                break;
                            }
                        }
                    }
                    const hasGotConstructionType = !!newConstructionType;
                    if (hasGotConstructionType) {
                        quoteRequestVM.setValue("constructionType", newConstructionType);
                        quoteRequestVM.addGotField("constructionType");
                    }

                    quoteRequestVM.setValue("isOverWater", "false");
                    quoteRequestVM.addGotField("isOverWater");

                    quoteRequestVM.setValue("buildingType", "residential");
                    quoteRequestVM.setValue("hasCrawlSpace", hasCrawlspace);
                } else {
                    quoteRequestVM.setValue("buildingType", "residential");
                    quoteRequestVM.setValue("hasCrawlSpace", "false");
                }
                this.setCanProcced(true);
            } else {
                console.log(apiResult.errors);
                //this.setCanProcced(false);
            }
            this.setIsLoading(false);
        });
    }

    // Computed
    @computed
    public get sfrValidate() {
        let quoteRequestVM = StoresInstance.domain.QuoteRequestViewModel;
        const isPropertyValid =
            quoteRequestVM.isFieldDecoratorsValid("numberOfFloors", quoteRequestVM.getValue("numberOfFloors")) &&
            quoteRequestVM.isFieldDecoratorsValid("constructionType", quoteRequestVM.getValue("constructionType")) &&
            quoteRequestVM.isFieldDecoratorsValid("foundationType", quoteRequestVM.getValue("foundationType"));

        return isPropertyValid;
    }

    @computed
    public get mobileValidate() {
        let quoteRequestVM = StoresInstance.domain.QuoteRequestViewModel;
        const isPropertyValid =
            quoteRequestVM.isFieldDecoratorsValid("constructionType", quoteRequestVM.getValue("constructionType")) &&
            quoteRequestVM.isFieldDecoratorsValid("make", quoteRequestVM.getValue("make")) &&
            quoteRequestVM.isFieldDecoratorsValid("isMobileHome", quoteRequestVM.getValue("isMobileHome"));

        return isPropertyValid;
    }

    @computed
    public get condoValidate() {
        let quoteRequestVM = StoresInstance.domain.QuoteRequestViewModel;
        const isPropertyValid =
            quoteRequestVM.isFieldDecoratorsValid("numberOfFloors", quoteRequestVM.getValue("numberOfFloors")) &&
            quoteRequestVM.isFieldDecoratorsValid("constructionType", quoteRequestVM.getValue("constructionType")) &&
            quoteRequestVM.isFieldDecoratorsValid("foundationType", quoteRequestVM.getValue("foundationType"));

        return isPropertyValid;
    }

    @computed
    public get rcbapValidate() {
        let quoteRequestVM = StoresInstance.domain.QuoteRequestViewModel;
        const isPropertyValid =
            quoteRequestVM.isFieldDecoratorsValid("numberOfFloors", quoteRequestVM.getValue("numberOfFloors")) &&
            quoteRequestVM.isFieldDecoratorsValid("constructionType", quoteRequestVM.getValue("constructionType")) &&
            quoteRequestVM.isFieldDecoratorsValid("foundationType", quoteRequestVM.getValue("foundationType"));

        return isPropertyValid;
    }

    @computed
    public get commercialValidate() {
        let quoteRequestVM = StoresInstance.domain.QuoteRequestViewModel;
        const isPropertyValid =
            quoteRequestVM.isFieldDecoratorsValid("numberOfFloors", quoteRequestVM.getValue("numberOfFloors")) &&
            quoteRequestVM.isFieldDecoratorsValid("constructionType", quoteRequestVM.getValue("constructionType")) &&
            quoteRequestVM.isFieldDecoratorsValid("foundationType", quoteRequestVM.getValue("foundationType"));

        return isPropertyValid;
    }

    @computed
    public get okayToProceed() {
        let retVal = this.canProceed;
        let quoteRequestVM = StoresInstance.domain.QuoteRequestViewModel;

        switch (quoteRequestVM.getValue<string>("chosenPropertyType")) {
            case "sfr":
                retVal = this.sfrValidate;
                break;
            case "mobile":
                retVal = this.mobileValidate;
                break;
            case "condo":
                retVal = this.condoValidate;
                break;
            case "commercial":
                retVal = this.commercialValidate;
                break;
            case "rcbap":
                retVal = this.rcbapValidate;
                break;
            default:
                break;
        }

        // the following values apply to all property types
        retVal =
            retVal &&
            quoteRequestVM.isFieldDecoratorsValid("yearBuilt", quoteRequestVM.getValue("yearBuilt")) &&
            quoteRequestVM.isFieldDecoratorsValid("isOverWater", quoteRequestVM.getValue("isOverWater")) &&
            quoteRequestVM.isFieldDecoratorsValid("hasBasement", quoteRequestVM.getValue("hasBasement")) &&
            quoteRequestVM.isFieldDecoratorsValid("buildingSqft", quoteRequestVM.getValue("buildingSqft")) &&
            quoteRequestVM.isFieldDecoratorsValid("hasGarage", quoteRequestVM.getValue("hasGarage")) &&
            //quoteRequestVM.isFieldDecoratorsValid("isNewProperty", quoteRequestVM.getValue("isNewProperty")) &&
            quoteRequestVM.isFieldDecoratorsValid("ownershipType", quoteRequestVM.getValue("ownershipType")) &&
            quoteRequestVM.isFieldDecoratorsValid("propertyOccupy", quoteRequestVM.getValue("propertyOccupy")) &&
            quoteRequestVM.isFieldDecoratorsValid("insurancePolicy", quoteRequestVM.getValue("insurancePolicy"));
        return retVal;
    }

    @computed
    public get isAddressPopulated() {
        let quoteRequestVM = StoresInstance.domain.QuoteRequestViewModel;
        const requestAddress: string = quoteRequestVM.getValue("address");
        return requestAddress.length > 0 ? true : false;
    }

    public async getProperty(propertyRequest: EstatedRequestModel) {
        this.setIsLoading(true);
        let domainStores = StoresInstance.domain;
        runInAction(() => {
            domainStores.EstatedHasStructure = false;
        });
        let apiResult = await this.Post<EstatedResponseModelDTO>(Server.Api.Property.GetPropertyDetails, propertyRequest);
        if (apiResult.wasSuccessful) {
            this.model.fromDto(apiResult.payload);
            domainStores.EstatedHasLoaded = true;
            if (apiResult.payload.data?.structure) {
                runInAction(() => {
                    domainStores.EstatedHasStructure = true;
                });
            }
        } else {
            console.log("Payload Errors: " + apiResult.errors);
        }
        this.setIsLoading(false);
        return apiResult;
    }

    // Arrays
    public propertyOptionsArr = [
        { key: "Please select type", value: "" },
        { key: "Single family residence", value: "sfr" },
        { key: "Mobile/manufactured home", value: "mobile" },
        { key: "Condominium", value: "condo" },
        { key: "Commercial property", value: "commercial" },
        { key: "Residential condo building association policy", value: "rcbap" },
    ];

    public mobileAnchorArr = [
        { key: "Please select type", value: "" },
        { key: "In accordance with Manufacturers Specifications?", value: "manuspec" },
        { key: "In accordance with community floodplain management specifications?", value: "cfmspec" },
        { key: "Over the top or frame ties to ground anchors?", value: "anchors" },
    ];

    public commercialListArr = [
        { key: "Please select", value: "" },
        { key: "Restaurant", value: "Restaurant" },
        { key: "Office", value: "Office" },
        { key: "Retail", value: "Retail" },
        { key: "House of Worship", value: "HouseOfWorship" },
        { key: "Industrial / Warehouse", value: "IndustrialWarehouse" },
        { key: "Other", value: "Other" },
    ];

    public afterUpdate: any;
    public beforeUpdate: undefined;
}
