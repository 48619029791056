import { ComponentState, ISelectModalProps } from "../Forms/SelectModal";
import { Grid, Theme } from "@material-ui/core";
import { styled, useObserver, useState } from "../../Globals/Base";

import { FieldType } from "../../../Core/Utils/Utils";
import { IViewModel } from "../../../Core/ViewModels/IViewModel";
import { KeyValuePair } from "../../../Core/Models/KeyValuePair";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useMediaQuery as useMuiMediaQuery } from "@material-ui/core";

interface IProps<T> {
    viewModel: IViewModel<T>;
    fieldName: keyof FieldType<T>;
    valueMap: any;
    children?: (obj: [ISelectModalProps<T>, any]) => React.ReactNode;
}

export function SimpleMultichoice<T>(props: IProps<T>) {
    const { viewModel, fieldName, valueMap, children } = props;
    const [openModal, setOpenModal] = useState(false);
    const mdMatches = useMuiMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

    return useObserver(() => (
        <>
            <ContainerGrid container mdMatches={mdMatches}>
                <Grid item xs={8}>
                    <p>{valueMap[viewModel.getValue<any>(fieldName)]}</p>
                </Grid>
                <Grid item xs={4} className="changeBtnItem">
                    <div>
                        <p className="change-modal" role="button" tabIndex={0} onClick={() => setOpenModal(true)}>
                            Change
                        </p>
                    </div>
                </Grid>
            </ContainerGrid>

            {children && children([{ fieldName, modalState: [openModal, setOpenModal], viewModel }, valueMap])}
        </>
    ));
}

interface IContainerGridProps {
    mdMatches?: boolean;
}

const ContainerGrid = styled(Grid)<IContainerGridProps>`
    width: 100%;
    height: 42px;

    .change-modal {
        font-size: 12px;
        text-decoration: underline;
        color: #f2af4c;
        font-weight: normal;
        cursor: pointer;
        display: inline;
    }

    > div > p {
        ${props => (props.mdMatches ? "font-size: 16px;" : "")}
    }

    .changeBtnItem {
        ${props => (props.mdMatches ? "align-self: center; text-align: right; position: relative; right: -10px;" : "")}
    }

    ${props => (props.mdMatches ? "padding-botttom: 7px;" : "")}
`;
