/*global google*/

// Libraries
import React, { useContext, useRef, useState, useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { runInAction } from "mobx";

// App
import { StoresInstance, Stores, StoresContext } from "../../Stores";
import { MapStore } from "../../Stores/Domain";
import { GeocodeResult } from "../../Models/GeocodeResult";
import { CoverageModel } from "../../Models";

// Components
import { googlePlaceResultToGeocodeResult } from "../../../Core/Utils/Utils";

// Styling & images
import { AddressWidgetStyling } from "../../StylesAppSpecific/FormStyling";

export const AddressSelectorForWidget: React.FC = () => {
    const domainStores = StoresInstance.domain;
    const mapStoresContext: MapStore = useContext<Stores>(StoresContext).domain.MapStore;

    let searchLocationAutoComplete: google.maps.places.Autocomplete;
    let searchLocationInput: HTMLInputElement;
    let searchEventListener: google.maps.MapsEventListener;

    let inputReference: React.MutableRefObject<any> = useRef(null);

    const setLocation: any = (result: GeocodeResult) => {
        // reset and allow Estated API to pull fresh stuff
        domainStores.EstatedHasLoaded = false;
        runInAction(() => {
            domainStores.EstatedHasStructure = false;
        });
        domainStores.QuoteRequestViewModel.setValue("buildingCoverage", CoverageModel.MODEL_DEFAULT_BUILDINGCOVERAGE);
        domainStores.QuoteRequestViewModel.setValue("contentsCoverage", CoverageModel.MODEL_DEFAULT_CONTENTSCOVERAGE);
        domainStores.QuoteRequestViewModel.setValue("deductible", CoverageModel.MODEL_DEFAULT_DEDUCTIBLEVALUE);
        domainStores.QuoteRequestViewModel.setValue("address", result.placeName);
        domainStores.QuoteRequestViewModel.setValue("city", result.city);
        domainStores.QuoteRequestViewModel.setValue("county", result.county);
        domainStores.QuoteRequestViewModel.setValue("state", result.region);
        domainStores.QuoteRequestViewModel.setValue("zipcode", result.zipcode);
        domainStores.QuoteRequestViewModel.setValue("latitude", result.center[1].toString());
        domainStores.QuoteRequestViewModel.setValue("longitude", result.center[0].toString());
        domainStores.QuoteRequestViewModel.model.resetDetails();
        domainStores.QuoteRequestViewModel.resetGotFields();

        domainStores.EstatedHasStructure = false;
        domainStores.EstatedHasLoaded = false;

        mapStoresContext.setRouteWithDetails(
            result.placeName,
            result.city,
            result.county,
            result.region,
            result.zipcode,
            result.center[1].toString(),
            result.center[0].toString(),
        );

        mapStoresContext.setIsAvailable(true);
    };

    useEffect(() => {
        if (inputReference.current != null) {
            searchLocationInput = inputReference.current!;

            searchLocationAutoComplete = new google.maps.places.Autocomplete(searchLocationInput, {
                types: ["address"],
                componentRestrictions: { country: "us" },
            });

            searchEventListener = google.maps.event.addListener(searchLocationAutoComplete, "place_changed", () => {
                domainStores.MapStore.ResetLocation();
                const location: google.maps.places.PlaceResult = searchLocationAutoComplete!.getPlace();
                if (location && location.geometry && location.formatted_address && location.types) {
                    const result = googlePlaceResultToGeocodeResult(location);
                    setLocation(result);
                }
            });
        }

        return () => {
            google.maps.event.removeListener(searchEventListener!);
        };
    }, []);

    return useObserver(() => {
        return (
            <>
                <AddressWidgetStyling>
                    <div className="emailaddress">
                        <input
                            ref={inputReference}
                            onKeyPress={(e: any) => {
                                if (e.keyCode === 13 || e.charCode === 13 || e.which === 13) {
                                    e.preventDefault();
                                    return false;
                                }
                            }}
                            onChange={(e: any) => {
                                domainStores.QuoteRequestViewModel.setValue("address", e.target.value);
                            }}
                            autoFocus={true}
                            placeholder={"Please enter your street address here..."}
                            value={domainStores.QuoteRequestViewModel.getValue("address") as string}
                        />
                    </div>
                </AddressWidgetStyling>
            </>
        );
    });
};
