// Libs
import axios, * as Axios from "axios";

// App
import { CheckHttpStatus } from "./CheckHttpStatus";
import { MergeDefaultConfig } from "./MergeDefaultConfig";
import { ApiResult } from "../../Models";

export const PostFormWithFile = <TPayload = undefined>(
    url: string,
    file: File,
    model?: any,
    config?: Axios.AxiosRequestConfig,
): Promise<ApiResult<TPayload>> => {
    const formConfig: Axios.AxiosRequestConfig = {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    };

    const requestConfig = MergeDefaultConfig(formConfig, config);
    const formData = new FormData();

    // By passing the model as a json file, it allows us to deserialize it
    // properly on the server side preserving any nested objects.
    if (!!model) {
        formData.append("model", JSON.stringify(model));
    }

    // Add the file.
    formData.append("file", file);

    const postFormPromise = axios.post<ApiResult<TPayload>>(url, formData, requestConfig).then(response => {
        CheckHttpStatus(response);

        return response.data;
    });

    return postFormPromise;
};

export const PostFormWithFiles = <TPayload = undefined>(
    url: string,
    files: FileList,
    model?: any,
    config?: Axios.AxiosRequestConfig,
): Promise<ApiResult<TPayload>> => {
    const formConfig: Axios.AxiosRequestConfig = {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    };

    const requestConfig = MergeDefaultConfig(formConfig, config);
    const formData = new FormData();

    // By passing the model as a json file, it allows us to deserialize it
    // properly on the server side preserving any nested objects.
    if (!!model) {
        formData.append("model", JSON.stringify(model));
    }

    // Add the files.
    for (var fileIndex = 0; fileIndex < files.length; fileIndex++) {
        formData.append(`file${fileIndex}`, files[fileIndex]);
    }

    const postFormPromise = axios.post<ApiResult<TPayload>>(url, formData, requestConfig).then(response => {
        CheckHttpStatus(response);

        return response.data;
    });

    return postFormPromise;
};
