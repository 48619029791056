// Libraries
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

// Core
import { useRouter } from "../../../Core/Utils";

// App
import { UsersTable } from "Custom/AdminComponents/AdminDashboard/UsersTable";
import { easternStandardTime, diffDates } from "Custom/Utils/Time";
import BinIcon from "Content/bin.svg";
import AgentReportsViewModel from "Custom/ViewModels/Agent/AgentReportsViewModel";
import { TableContainer, DeleteButton } from "../../StylesAppSpecific/AgentReportStyling";
import { WarningModal, OkButton, CancelButton } from "../../StylesAppSpecific/ModalStyling";
import Warning from "Content/warning.svg";
import { dateFormat } from "../../Utils/Time";

export const AgentReports: React.FC = () => {
    const { history } = useRouter();
    const [viewModel] = useState(() => new AgentReportsViewModel());
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [email, setEmail] = React.useState<string>("");

    useEffect(() => {
        // If the viewmodel is to allow navigation. set the viewmodel router.
        viewModel.setRouter(history, location);

        // With SPAs, we need to move back to the top of the page.
        window.scrollTo(0, 0);
    }, []);

    const renderDeleteButton = (rowData: any) => {
        return (
            <>
                <DeleteButton
                    onClick={(event: any) => {
                        event.preventDefault();
                        event.stopPropagation();

                        openDeleteModal(rowData.userName);
                    }}
                >
                    <img src={BinIcon} alt="Delete"></img>
                </DeleteButton>
            </>
        );
    };

    const doDelete = () => {
        viewModel.deletePropertyQuotes(email).then(() => {
            setEmail("");
        });
        setOpenModal(false);
    };

    const openDeleteModal = (email: string) => {
        setEmail(email);
        setOpenModal(true);
    };

    const closeDeleteModal = () => {
        setOpenModal(false);
        setEmail("");
    };

    const deleteModal = (
        <>
            <div className="header">Warning</div>
            <div className="body">
                <img src={Warning} alt="Warning - Are you sure you want to delete this?" />
                <p>Are you sure you want to delete this?</p>
            </div>
            <div className="footer">
                <OkButton
                    onClick={() => {
                        doDelete();
                    }}
                >
                    OK
                </OkButton>
                <CancelButton
                    onClick={() => {
                        closeDeleteModal();
                    }}
                >
                    Cancel
                </CancelButton>
            </div>
        </>
    );

    return useObserver(() => (
        <>
            <div style={{ maxWidth: "1124px", margin: "0 auto" }}>
                <TableContainer>
                    <UsersTable
                        data={viewModel.reports}
                        columns={[
                            {
                                title: "Policyholder name",
                                field: "name",
                                render: (rowData: any) => {
                                    return `${rowData.firstName} ${rowData.lastName}`;
                                },
                                customFilterAndSearch: (value: any, rowData: any) => {
                                    return `${rowData.firstName} ${rowData.lastName}`.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                                },
                                customSort: (lhsValue: any, rhsValue: any) => {
                                    var lhsName = `${lhsValue.firstName} ${lhsValue.lastName}`.toLowerCase();
                                    var rhsName = `${rhsValue.firstName} ${rhsValue.lastName}`.toLowerCase();

                                    return lhsName.localeCompare(rhsName);
                                },
                            },
                            {
                                title: "Email address",
                                field: "userName",
                            },
                            {
                                title: "No. of quotes",
                                field: "quoteCount",
                            },
                            {
                                title: "Latest quote date (ET)",
                                field: "latestCreatedDate",
                                render: (rowData: any) => {
                                    return easternStandardTime(rowData.latestCreatedDate).format(dateFormat);
                                },
                                customFilterAndSearch: (value: any, rowData: any) => {
                                    return (
                                        easternStandardTime(rowData.latestCreatedDate)
                                            .format(dateFormat)
                                            .indexOf(value) != -1
                                    );
                                },
                                customSort: (lhsValue: any, rhsValue: any) => {
                                    return diffDates(lhsValue.latestCreatedDate, rhsValue.latestCreatedDate);
                                },
                            },
                            {
                                title: "",
                                field: "",
                                render: (rowData: any) => {
                                    return renderDeleteButton(rowData);
                                },
                            },
                        ]}
                        onSelect={viewModel.selectUser}
                        title="Reports"
                    />
                </TableContainer>
            </div>
            <WarningModal open={openModal} onClose={closeDeleteModal}>
                {deleteModal}
            </WarningModal>
        </>
    ));
};
