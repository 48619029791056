// Libraries
import { useObserver } from "mobx-react-lite";
import React, { useState, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Link } from "@material-ui/core";

// Core
import { useMediaQuery } from "Core/Utils/Media";

// Content
import MFILogoFooter from "Content/logoForDarkBG.png";
import iconEmail from "Content/icon-email.svg";
import iconTwitter from "Content/icon-twitter.svg";
import iconFacebook from "Content/icon-facebook.svg";
import poweredBy from "Content/poweredBy.svg";

// Custom
import { NavigationLinkModel } from "Custom/Models";
import { FooterWrapper, GridSpaced } from "Custom/StylesAppSpecific/GridHelperStyling";
import { HdrImg } from "Custom/StylesAppSpecific/PageStyling";
import { FooterViewModel } from "Custom/ViewModels/FooterViewModel";
import { FootNav, SocialGroups } from "./FooterStyling";
import { StoresContext, Stores, DomainStores, StoresInstance } from "Custom/Stores";
import { useRouter } from "../../Globals/Base";

/**
 * Footer view props interface.
 */
export interface IFooter {
    isTemp?: boolean | undefined;
}

/**
 * Footer view.
 *
 * @param props The props required for this view.
 */

declare var purechatApi: any;

export const Footer: React.FC<IFooter> = props => {
    const [viewModel] = useState(() => FooterViewModel.instance);
    const isMobile = !!useMediaQuery("screen and (max-width: 767px)");
    const { history } = useRouter();
    const storesContext = useContext<Stores>(StoresContext);
    const domainStores = StoresInstance.domain;

    const currentYear = () => {
        let d = new Date();
        return d.getFullYear();
    };

    const disablePureChat = () => {
        try {
            purechatApi.set("chatbox.visible", false);
        } catch {
            console.log("disablePureChat is in error");
        }
    };

    if (domainStores.wrapper) {
        disablePureChat();
    }

    /**
     * Scrolls the window back to the top-left corner.
     *
     * By default, when clicked, react-roter-dom links will not scroll
     * the windows back to the top. So we have to do this manually.
     */
    const scrollToTopOfPage = (): void => {
        window.scrollTo(0, 0);
    };

    /**
     * Renders a collection of navigation links.
     *
     * @param prefix			The prefix url segment.
     * @param navigationLinks	A collection of models to be rendered.
     *
     * @return A collection of link elements.
     */
    const renderNavigationLinks = (prefix: string, navigationLinks: NavigationLinkModel[]): JSX.Element[] => {
        const navigationLinkElements = [];

        for (const navigationLink of navigationLinks) {
            navigationLinkElements.push(
                <Box key={navigationLink.link} minWidth={110} width="16.5%">
                    <Link component={RouterLink} onClick={scrollToTopOfPage} to={`/${prefix}/${navigationLink.link}`}>
                        {navigationLink.displayName}
                    </Link>
                </Box>,
            );
        }

        return navigationLinkElements;
    };

    const liveFooter = (): JSX.Element => (
        <>
            <GridSpaced container margin={isMobile ? "0px auto" : "40px auto 0px"} additionalcss={"max-width: 1124px; width: 100%;"}>
                <GridSpaced item xs={12} sm={6}>
                    <HdrImg src={MFILogoFooter} alt="My Flood Insurance" maxwidth={"150px"} />
                </GridSpaced>
                <GridSpaced
                    item
                    xs={12}
                    sm={6}
                    padding={isMobile ? "" : "10px 0px 0px"}
                    additionalcss={isMobile ? "text-align: center;" : "text-align: right;"}
                ></GridSpaced>
            </GridSpaced>
            <GridSpaced container margin={"10px auto 0px"} additionalcss={"max-width: 1124px; width: 100%;"}>
                <GridSpaced item xs={12} sm={4}>
                    <FootNav>
                        <h2>My Flood Insurance</h2>
                        <Link
                            href="./#faq"
                            onClick={(e: any) => {
                                e.preventDefault();
                                history.push(`/#faq`);
                            }}
                            title="FAQ"
                        >
                            FAQ
                        </Link>
                        <Link
                            href="./about-us"
                            onClick={(e: any) => {
                                e.preventDefault();
                                history.push(`/about-us`);
                            }}
                            title="About Us"
                        >
                            About Us
                        </Link>
                        <Link
                            href="./Partners"
                            onClick={(e: any) => {
                                e.preventDefault();
                                history.push(`/partners`);
                            }}
                            title="Our Partners"
                        >
                            Our Partners
                        </Link>
                        <Link
                            href="./contact"
                            onClick={(e: any) => {
                                e.preventDefault();
                                history.push(`/contact`);
                            }}
                            title="Contact Us"
                        >
                            Contact Us
                        </Link>
                        <br />
                        <br />
                        <Link
                            href="./privacy-policy"
                            onClick={(e: any) => {
                                e.preventDefault();
                                history.push(`/privacy-policy`);
                            }}
                            title="Privacy Policy"
                        >
                            Privacy Policy
                        </Link>
                        {/* <Link
                            href="./#"
                            onClick={(e: any) => {
                                e.preventDefault();
                                history.push(`/#`);
                            }}
                            title="Site Map"
                        >
                            Site Map
                        </Link> */}
                        <Link
                            href="./terms"
                            onClick={(e: any) => {
                                e.preventDefault();
                                history.push(`/terms`);
                            }}
                            title="Terms of Use"
                        >
                            Terms of Use
                        </Link>
                    </FootNav>

                    <SocialGroups additionalcss={"margin-left: -5px; margin-top: 45px; margin-bottom: 25px;"}>
                        <Link
                            href="./contact"
                            onClick={(e: any) => {
                                e.preventDefault();
                                history.push(`/contact`);
                            }}
                            title="Contact us"
                        >
                            <img src={iconEmail} alt={"Contact"} />
                        </Link>
                        <Link href="https://twitter.com/@myfloodinsur" title="Follow us on twitter" target="_blank">
                            <img src={iconTwitter} alt={"Follow us on twitter"} />
                        </Link>
                        <Link href="https://www.facebook.com/MyFloodInsurance/" title="Like us on Facebook" target="_blank">
                            <img src={iconFacebook} alt={"Like us on Facebook"} />
                        </Link>
                    </SocialGroups>
                </GridSpaced>
                <GridSpaced item xs={12} sm={8}>
                    {viewModel.canDisplayStates && (
                        <FootNav additionalcss={"margin-bottom: 50px;"}>
                            <h2>Flood Insurance in your State</h2>
                            <Box display="flex" flexWrap="wrap">
                                {renderNavigationLinks("state", viewModel.StateNavigationLinks)}
                            </Box>
                        </FootNav>
                    )}
                    {viewModel.canDisplayCities && (
                        <FootNav additionalcss={"margin-bottom: 25px;"}>
                            <h2>Flood Insurance in your City</h2>
                            <Box display="flex" flexWrap="wrap">
                                {renderNavigationLinks("city", viewModel.CityNavigationLinks)}
                            </Box>
                        </FootNav>
                    )}
                </GridSpaced>
            </GridSpaced>
            <GridSpaced container margin={"10px auto 40px"} additionalcss={"max-width: 1124px; width: 100%;"}>
                <GridSpaced
                    item
                    sm={8}
                    padding={"34px 0px 0px"}
                    additionalcss={
                        isMobile
                            ? "font-weight: 700; font-size: 14px; text-align: center; color: #ffffff;"
                            : "font-weight: 700; font-size: 14px; text-align: left; "
                    }
                >
                    © {currentYear()}{" "}
                    <Link href={"https://www.aurorainsurtech.com"} target={"_blank"} title={"Aurora Insurance Technologies"} style={{ color: "#ffffff" }}>
                        Aurora Insurance Technologies
                    </Link>
                    , Inc. v{storesContext.domain.version}
                </GridSpaced>
                <GridSpaced item sm={4} additionalcss={"text-align: right;"}>
                    <Link href={"https://shoothill.com"} target={"_blank"} title={"powered by Shoothill"}>
                        <img src={poweredBy} alt="Powered By Shoothill" />
                    </Link>
                </GridSpaced>
            </GridSpaced>
        </>
    );

    const liveMobileFooter = (): JSX.Element => (
        <>
            <GridSpaced container margin={"0px auto"} additionalcss={"max-width: 767px; width: 100%;"}>
                <GridSpaced item xs={12} padding={"18px 0px 14px"} additionalcss={"text-align: center;"}>
                    <HdrImg src={MFILogoFooter} alt="My Flood Insurance" maxwidth={"165px"} />
                </GridSpaced>
                <GridSpaced item xs={12} padding={"0px"} additionalcss={"text-align: center;"}>
                    <SocialGroups>
                        <Link href="mailto:info@myfloodrisk.org" title="Email us">
                            <img src={iconEmail} alt={"email"} />
                        </Link>

                        <Link href="https://twitter.com/@myfloodinsur" title="Follow us on twitter" target="_blank">
                            <img src={iconTwitter} alt={"Follow us on twitter"} />
                        </Link>
                        <Link href="https://www.facebook.com/MyFloodInsurance/" title="Like us on Facebook" target="_blank">
                            <img src={iconFacebook} alt={"Like us on Facebook"} />
                        </Link>
                    </SocialGroups>
                </GridSpaced>
            </GridSpaced>
            <GridSpaced item xs={12} padding={"0px"} additionalcss={"text-align: center;"}>
                <FootNav
                    additionalcss={
                        "display: inline-flex; text-align: center; font-weight: bold; font-size: 12px; line-height: 12px; margin: 0px; flex-direction: row;"
                    }
                >
                    <Link
                        href="./terms"
                        onClick={(e: any) => {
                            e.preventDefault();
                            history.push(`/terms`);
                        }}
                        title="Terms of Use"
                        style={{
                            borderRight: "2px solid #ffffff",
                            paddingRight: "20px",
                            marginBottom: "5px",
                            width: "130px",
                        }}
                    >
                        Terms of Use
                    </Link>
                    <Link
                        href="./privacy-policy"
                        onClick={(e: any) => {
                            e.preventDefault();
                            history.push(`/privacy-policy`);
                        }}
                        title="Privacy Policy"
                        style={{
                            paddingLeft: "20px",
                            marginBottom: "5px",
                            width: "130px",
                        }}
                    >
                        Privacy Policy
                    </Link>
                </FootNav>
            </GridSpaced>
            <GridSpaced container margin={"10px auto"} additionalcss={"max-width: 767px; width: 100%; margin: 0px;"}>
                <GridSpaced item xs={12} sm={8} padding={"0px"} additionalcss={"font-weight: 700; font-size: 12px; text-align: center; color: #ffffff;"}>
                    © {currentYear()}{" "}
                    <Link href={"https://www.aurorainsurtech.com"} target={"_blank"} title={"Aurora Insurance Technologies"} style={{ color: "#ffffff" }}>
                        Aurora Insurance Technologies
                    </Link>
                    , Inc. v{storesContext.domain.version}
                </GridSpaced>
                <GridSpaced item xs={12} sm={4} additionalcss={"text-align: center;"}>
                    <Link href={"https://shoothill.com"} target={"_blank"} title={"powered by Shoothill"}>
                        <img src={poweredBy} alt="Powered By Shoothill" />
                    </Link>
                </GridSpaced>
            </GridSpaced>
        </>
    );

    const tempFooter = (): JSX.Element => (
        <>
            <GridSpaced container margin={isMobile ? "0px auto" : "10px auto 0px"} additionalcss={"max-width: 1124px; width: 100%;"}>
                {isMobile && (
                    <GridSpaced
                        item
                        padding={"10px"}
                        xs={12}
                        sm={6}
                        fontcolor={"#ffffff"}
                        additionalcss={"font-size: 14px; font-weight: 700; color: #ffffff; text-align: center;"}
                    >
                        © 2020 My Flood Insurance
                    </GridSpaced>
                )}
                {!isMobile && (
                    <GridSpaced
                        item
                        padding={"5px 0px 0px 20px"}
                        xs={12}
                        sm={6}
                        fontcolor={"#ffffff"}
                        additionalcss={"font-size: 14px; font-weight: 700; color: #ffffff; text-align: left;"}
                    >
                        © {currentYear()} My Flood Insurance
                    </GridSpaced>
                )}
                <GridSpaced item padding={isMobile ? "10px" : ""} xs={12} sm={6} additionalcss={isMobile ? "text-align: center;" : "text-align: right;"}>
                    <SocialGroups>
                        <Link href="mailto:info@myfloodrisk.org" title="Email us">
                            <img src={iconEmail} alt={"email"} />
                        </Link>
                        <Link href="https://twitter.com/@myfloodinsur" title="Follow us on twitter" target="_blank">
                            <img src={iconTwitter} alt={"Follow us on twitter"} />
                        </Link>
                        <Link href="https://www.facebook.com/MyFloodInsurance/" title="Like us on Facebook" target="_blank">
                            <img src={iconFacebook} alt={"Like us on Facebook"} />
                        </Link>
                    </SocialGroups>
                </GridSpaced>
            </GridSpaced>
        </>
    );

    return useObserver(() => (
        <>
            {props.isTemp && (
                <FooterWrapper backgroundcolor={"#11181D"} padding={"5px 0px"}>
                    {tempFooter()}
                </FooterWrapper>
            )}
            {(props.isTemp === undefined || !props.isTemp) && (
                <FooterWrapper backgroundcolor={"#11181D"}>
                    {isMobile && liveMobileFooter()}
                    {!isMobile && liveFooter()}
                </FooterWrapper>
            )}
        </>
    ));
};
