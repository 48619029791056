import React, { useEffect } from "react";
import { StoresInstance, styled } from "../Globals/Base";
import { SelectModalStyles } from "./Forms/SelectModal";
import { useObserver } from "mobx-react-lite";
import { Modal, Button } from "@material-ui/core";
import { observe } from "mobx";
import iconLogout from "Content/icon-logout.svg";

export const LogoutModal: React.FC = () => {
    useEffect(() => {
        const unlisten = observe(StoresInstance.domain.AccountStore, "LogoutModalOpen", change => {
            const onClose = (e: KeyboardEvent) => e.key === "Enter" && doClose();
            if (change.newValue) {
                window.addEventListener("keydown", onClose);
            } else {
                window.removeEventListener("keydown", onClose);
            }
        });

        return () => unlisten();
    }, []);

    const doClose = () => {
        StoresInstance.domain.AccountStore.setLogoutModalOpen(false);
    };

    const doOpen = () => {
        StoresInstance.domain.AccountStore.setLogoutModalOpen(false);
    };

    const doLogout = () => {
        StoresInstance.domain.AccountStore.doLogout();
        StoresInstance.domain.AccountStore.setLogoutModalOpen(false);
        StoresInstance.domain.AccountStore.LogoutCallback();
    };

    return useObserver(() => (
        <>
            <Modal open={StoresInstance.domain.AccountStore.LogoutModalOpen} onClose={doClose}>
                <LogoutModalStyles>
                    <div className="logout-wrapper">
                        <div className="title-bar">
                            <h4>Log out</h4>
                            <div role="button" tabIndex={0} className="close" onClick={doClose}>
                                ✕
                            </div>
                        </div>
                        <div className="content">
                            <div className="mobile-scroll">
                                <img src={iconLogout} alt="" />
                                <h5>
                                    Are you sure you want to
                                    <br />
                                    log out?
                                </h5>
                            </div>
                            <div className="btns">
                                <Button className="ok-btn" variant="contained" onClick={doLogout}>
                                    OK
                                </Button>
                                <Button className="cancel-btn" variant="contained" onClick={doClose}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                </LogoutModalStyles>
            </Modal>
        </>
    ));
};

const LogoutModalStyles = styled(SelectModalStyles)`
    div.logout-wrapper {
        @media (max-width: 767px) {
            height: 100%;
        }
    }

    > div {
        height: auto;
        overflow: hidden;
        min-width: auto;
    }

    .content {
        padding: 0 !important;

        @media (max-width: 767px) {
            height: 100% !important;
        }

        > .mobile-scroll {
            padding: 35px 30px;
            text-align: center;

            h5 {
                text-align: center;
                font-size: 1.313rem;
                margin: 0;
            }
        }
    }

    .title-bar > h4 {
        text-align: center;
        font-size: 1.5rem;
    }

    .btns {
        @media (max-width: 767px) {
            padding-bottom: 80px !important;
        }
    }

    .btns > button {
        margin: 25px 10px !important;
        padding: 10px !important;
        font-weight: bold;
        border-radius: 1000px !important;
    }
`;
