import moment, { Moment } from "moment";
import "moment-timezone";

const TimeZone: string = "America/New_York";
const InputTimeFormat = "YYYY-MM-DDTHH:mm:ss";

export const dateTimeFormat = "DD MMM YYYY HH:mm";
export const dateFormat = "MM/DD/YYYY";

/**
 * Converts a string of format 'YYYY-MM-DDTHH:mm:ss' to a moment
 * object using Eastern Standard Time.
 *
 * @param utcTime The time to be converted.
 *
 * @return A moment object using EST.
 */
export const easternStandardTime = (utcTime: string): Moment => {
    const utcMoment = moment.utc(utcTime, InputTimeFormat);

    return utcMoment.clone().tz(TimeZone);
};

/**
 * Converts a string of format 'YYYY-MM-DDTHH:mm:ss' to a moment
 * object.
 *
 * @param time The time to be converted.
 *
 * @return A moment object.
 */
export const localTime = (time: string): Moment => {
    const localMoment = moment(time, InputTimeFormat);

    return localMoment.clone();
};

/**
 * Determines if the specified day, month and year are a valid date.
 *
 * @param day The day of the month (1-based).
 * @param month The month of the year (0-based).
 * @param year The year.
 *
 * @return True if the date is valid.
 */
export const isValidDate = (day: number, month: number, year: number): boolean => {
    return moment([year, month, day]).isValid();
};

/**
 * Determines if the specified date string is an empty default string.
 *
 * @param date The string to check.
 *
 * @return true if empty.
 */
export const isEmptyDate = (date: string): boolean => {
    return date === "0001-01-01T00:00:00";
};

/**
 * Converts a day, month and year into an ISO-formatted string.
 *
 * @param day The day of the month (1-based).
 * @param month The month of the year (0-based).
 * @param year The year.
 *
 * @return An ISO-formatted string.
 */
export const dateTimeISOString = (day: number, month: number, year: number): string => {
    return moment([year, month, day]).format(InputTimeFormat);
};

/**
 * Gets the last day of the month for the specified month and year.
 *
 * @param month The month of the year (0-based).
 * @param year.
 *
 * @return The last day of the month.
 */
export const lastDayOfMonth = (month: number, year: number): number => {
    return moment([year, month, 1])
        .endOf("month")
        .date();
};

/**
 * Returns Boolean if in Office Hours
 * 8am - 8pm
 *
 * @return boolean (true if in office hours)
 */
export const isOfficeHours = () => {
    let retVal: boolean = false;

    let currentTime = moment.utc();
    let currDay = easternStandardTime(currentTime.toISOString()).format("YYYY-MM-DD");

    let openTime = moment.tz(currDay + "T08:00:00", TimeZone);
    let closeTime = moment.tz(currDay + "T20:00:00", TimeZone);

    retVal = openTime < currentTime && closeTime > currentTime;

    return retVal;
};

/**
 * Returns a formatted string for the policy start date and end date.
 *
 * @param time The policy start time.
 *
 * @return The formatted string.
 */
export const effectiveDateRange = (time: string): string => {
    const startLocalMoment = moment(time, InputTimeFormat);
    const endLocalMoment = startLocalMoment.clone().add(1, "year");

    return `${startLocalMoment.format("MM/DD/YYYY")} - ${endLocalMoment.format("MM/DD/YYYY")}`;
};

/**
 * Returns the difference in milliseconds between two dates.
 *
 * @param lhsUtcTime The left-hand time.
 * @param rhsUtcTime The right-hand time.
 *
 * @return The difference in milliseconds.
 */
export const diffDates = (lhsUtcTime: string, rhsUtcTime: string): number => {
    const lhsMoment = moment.utc(lhsUtcTime, InputTimeFormat);
    const rhsMoment = moment.utc(rhsUtcTime, InputTimeFormat);

    return lhsMoment.diff(rhsMoment);
};
