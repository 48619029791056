import * as MobX from "mobx";
import { ModelBase } from "../../../Core/Models/ModelBase";
import { WarningMetaDataModel } from "./WarningMetaData";

export class WarningModel extends ModelBase<WarningModel, WarningModelDTO> {
    @MobX.observable
    public code: string = "";

    @MobX.observable
    public title: string = "";

    @MobX.observable
    public description: string = "";

    @MobX.observable
    public warningMetaData: WarningMetaDataModel[] = [];

    public fromDto = (model: WarningModelDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                this[key] = model[key];
            }
        }
    };
    public toDto = (model: WarningModel) => {};

    public resetWarning = () => {
        this.code = "";
        this.title = "";
        this.description = "";
        this.warningMetaData = [];
    };
}

export interface WarningModelDTO {
    code: "";
    title: "";
    description: "";
    warningMetaData: [];
}
