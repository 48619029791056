import styled from "styled-components";
import EnterYourLocationHdr from "Content/EnterYourLocation-Header.svg";
import { AddressInputStyling } from "./FormStyling";
import HereToHelp from "Content/hereToHelp.svg";
import realestateIcon from "Content/realestate-icon.svg";
import propertyOwnerIcon from "Content/propertyOwner-Icon.svg";
import ArrowRight from "Content/ArrowGrey-right.svg";
import { Button } from "@material-ui/core";

export interface IComponentProps {
    fontWeight?: string;
    fontcolor?: string;
    backgroundcolor?: string;
    hovercolor?: string;
    padding?: string;
    margin?: string;
    additionalcss?: string;
    fontsize?: string;
    textAlign?: string;
    border?: string;
    borderLeft?: string;
    borderRight?: string;
    width?: string;
    display?: string;
    overflow?: string;
    height?: string;
}

export const StepsBase: any = styled.div`
    padding: unset;
    margin: 0px auto;
    background-color: unset;
    max-width: 1124px;
    width: 100%;
`;

export const PanelBase: any = styled(StepsBase)`
    display: flex;
    flex-direction: column;
    @media (min-width: 768px) {
        background-color: #3a4650;
        border-radius: 5px;
        opacity: 1;
    }
`;

/**
 * Home Page - Start Steps
 */
export const HomePrimaryPanel: any = styled(StepsBase)<IComponentProps>`
    ${props => props.additionalcss};
`;

export const UspPanel: any = styled(StepsBase)`
    display: flex;
    flex-direction: row;
    padding: 20px 0px;
    img,
    div,
    button {
        margin-right: 20px;
    }
    .textcontainer {
        display: inline-flex;
        flex-direction: column;
        margin-top: 1%;
        margin-right: 40px;
        h2,
        p {
            font-weight: 700;
            font-size: 33px;
            margin: 0px;
            padding: 0px;
            letter-spacing: -1.8px;
            line-height: 1;
        }

        p {
            font-size: 28px;
            letter-spacing: -1.4px;
        }
    }
    button {
        font-size: 18px;
        letter-spacing: -0.45px;
        margin: 2.5% 0 0;
        max-height: 34px;
    }
`;

export const ConfirmAddressPanel: any = styled(PanelBase)<IComponentProps>`
    @media (min-width: 768px) {
        padding: 40px;
    }

    .dashed {
        width: 100%;
        margin-top: 40px;
        padding-top: 40px;
        border-top: dashed 1px #8cd2f7;

        >div:first-of-type {
            margin-right: 40px;
        }

        >div:last-of-type {
            margin-left: 40px;
        }
    }

    .confirmAddress {
        display: flex;
        flex-direction: column;
        .address,
        .map {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        .address {
            padding: 7px 20px 0px;

            select {
                outline: none;
            }

            .propertyType-select {
                display: flex;
            }

            .propertyType-icon {
                width: 9%;
                margin-left: -9px;
                margin-right: 10px;
            }
        }

        .mb30 {
            margin-bottom: 30px;
        }

        @media (min-width: 768px) {
            flex-direction: row;
            .address,
            .map {
                display: flex;
                flex-direction: column;
                max-width: 482px;
            }
            .address {
                margin-right: 23px;
                padding: 0px;
            }

            .map {
                margin-left: 20px;
                max-width: 519px;
            }
        }

        @media (max-width: 767px) {
            .map {
                max-height: 170px;
            }
        }
    }    

    h2 {
        line-height: 1;
        margin: 0;
        padding: 5px 0px 0px 48px;
        background: url("${EnterYourLocationHdr}") no-repeat -6px center;
        background-size: 48px;
        height: 48px;
        font-size: 18px;
        letter-spacing: -0.45px;

        @media (max-width: 767px) {
            span {
                display: block;
            }
        }
    }

    h3 {
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 0;
    }

    p {
        font-size: 10px;
        letter-spacing: -0.25px;
    }

    h2,
    p {
        span {
            color: #8cd2f7;
        }
    }

    @media (min-width: 768px) {
        h2 {
            line-height: 1;
            padding: 16px 0 0 68px;
            background: url("${EnterYourLocationHdr}") no-repeat -8px center;
            background-size: 64px;
            height: 64px;
            font-size: 30px;
            letter-spacing: -0.75px;
        }

        h3 {
            font-size: 24px;
            font-weight: 500;
            letter-spacing: -0.6px;
        }

        p {
            font-size: 14px;
            letter-spacing: -0.35px;
        }
    }
`;

export const ConfirmDetailsPanel: any = styled(PanelBase)<IComponentProps>`
    padding: 40px;

    .uniqueOptions {
        h2.propertyheader {
            margin: 0px 0px 20px;
            font-size: 30px;
            span {
                color: #8cd2f7;
            }
            img {
                vertical-align: middle;
                margin-left: -10px;
                margin-right: 20px;
            }
        }

        p.propertyheadertext {
            font-weight: 700;
            font-size: 24px;
            letter-spacing: 0;
            margin: 0;
        }

        .stepsVariant {
            background-color: unset;
            margin: 20px 0px 0px;
        }

        .gridItem,
        .multi-heading {
            padding: 14px 20px 14px 0px;
            background-color: unset;
            h2 {
                font-size: 24px;
                font-weight: 700;
                letter-spacing: 0px;
                color: #ffffff;
                margin: 30px 0px;
            }
        }

        .multi-heading {
            border-radius: 0px;
            border-top: 1px dashed #8cd2f7;
            h2 {
                text-align: left;
                margin: 30px 0px;
            }
        }

        @media (max-width: 767px) {
            display: flex;
            flex-direction: column;
            align-items: center;

            img,
            h2.propertyheader,
            h2.propertyheader span {
                text-align: center;
            }

            h2.propertyheader span {
                display: block;
            }

            > img {
                margin: -5px;
                max-width: 68px;
                width: 100%;
                height: auto;
            }

            h2.propertyheader {
                font-size: 18px;
            }

            p.propertyheadertext {
                font-size: 14px;
                text-align: center;
            }

            .gridItem {
                padding: 0;

                h2 {
                    font-size: 10px;
                    margin: 0;
                }
            }

            .stepsVariant {
                background-color: #3a4650;
            }

            .multi-heading {
                border-top: none;
                padding: 18px 20px;
                background-color: #3a4650;

                h2 {
                    font-size: 10px;
                    margin: 0;
                }

                p.error {
                    font-size: 8px;
                }
            }

            > div:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    > div:not(.uniqueOptions) {
        border-top: 1px solid #8cd2f7;
        padding-top: 30px;
        > div:first-of-type {
            background-color: unset;
            .gridItem {
                padding: 14px 0px;
                margin: 0px 0px 30px;
                h2 {
                    font-size: 24px;
                    font-weight: 700;
                    letter-spacing: 0px;
                    color: #ffffff;
                }
            }
        }

        @media (max-width: 767px) {
            border-top: none;
            padding: 0;
            border: 0;

            > div:first-of-type {
                background-color: #3a4650;

                .gridItem {
                    margin-bottom: 0;
                    padding: 0;

                    p.error {
                        font-size: 8px;
                        color: red;
                    }

                    h2 {
                        font-size: 10px;
                    }

                    button {
                        margin-top: 0;

                        > span {
                            font-size: 10px;
                        }
                    }
                }
            }
        }

        .steps-format {
            background-color: unset;
            padding: 0px;
        }
        /* 
        .alignleft {
            justify-content: left;
        }
        */
    }

    .noThumbs div div {
        .img-container {
            outline: red 1px;
            padding: 19px 20px;
        }
    }

    .multi-heading {
        padding: 0px 20px 14px 0px;
        background-color: unset;
        h2 {
            font-size: 24px;
            font-weight: 700;
            letter-spacing: 0px;
            color: #ffffff;
            margin: 30px 0px;
        }

        @media (max-width: 767px) {
            border-top: none;
            padding: 18px 20px;
            background-color: #3a4650;

            h2 {
                font-size: 10px;
                margin: 0;
            }
        }
    }

    .pro-register-step {
        padding: 15px 0px 0px !important;
        p,
        h2 {
            margin-left: 0px;
        }

        > div:not(.contact-bottom) {
            margin: 0px 0px 40px;
            flex-basis: 502px;
        }

        > div:first-of-type {
            margin-right: 40px;
        }
    }

    .alignleft {
        justify-content: left;
    }

    @media (max-width: 767px) {
        padding: 20px 20px 0;

        .register-finally {
            font-size: 18px;
            color: #8cd2f7;
            text-align: center;
            margin-bottom: 2px;
            margin-top: 15px;
        }

        .register-paragraph {
            margin-top: 0;
            font-size: 14px;
            text-align: center;

            &.realtor {
                margin-bottom: 40px;
            }
        }

        p.Mui-error {
            bottom: -27px !important;
        }
    }
`;

const BlueWhiteButton: any = styled.div`
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: 10px;
    border: 5px solid #129bdb;
    border-radius: 15px;
    opacity: 1;
    max-width: 335px;
    width: 100%;
    height: 51px;
    cursor: pointer;
    margin: 0px auto 5px;

    div {
        width: auto;
        color: #333333;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.81px;
        line-height: 1;
        background-image: url(${ArrowRight});
        background-size: 14px 24px;
        background-repeat: no-repeat;
        background-position: 240px 10px;
        height: 74px;
        padding-top: 15px;
        margin-left: 59px;
        span {
            color: #129bdb;
            margin-right: 5px;
        }
    }

    @media (min-width: 768px) {
        margin: 0px;
        height: 84px;

        div {
            max-width: 220px;
            padding-top: 18px;
            font-size: 18px;
            height: 64px;
            background-size: 34px 54px;
            background-position: 175px 10px;
            margin-left: 90px;
            span {
                display: block;
                margin-right: 0;
            }
        }
    }
`;

export const PropertyOwnerBtn: any = styled(BlueWhiteButton)<IComponentProps>`
    background-image: url(${propertyOwnerIcon});
    ${props => props.additionalcss};
    @media (max-width: 767px) {
        background-size: 40px;
    }
`;

export const RealEstateBtn: any = styled(BlueWhiteButton)<IComponentProps>`
    background-image: url(${realestateIcon});
    background-position: 20px;
    ${props => props.additionalcss};
    @media (max-width: 767px) {
        background-position: 15px;
        background-size: 30px;
    }
`;

export const InsuranceAgentBtn: any = styled(BlueWhiteButton)<IComponentProps>`
    background-image: url(${HereToHelp});
    background-size: 16%;
    background-position: 20px;
    ${props => props.additionalcss};
    @media (max-width: 767px) {
        background-size: 9%;
        background-position: 17px;
    }
`;

export const ContinueBtn: any = styled(BlueWhiteButton)<IComponentProps>`
    background-image: url(${propertyOwnerIcon});
    ${props => props.additionalcss};
    @media (min-width: 768px) {
        margin-left: 0px !important;
        margin-right: unset !important;
    }
    @media (max-width: 767px) {
        background-size: 40px;
    }
`;

export const PropertyDetailWrapper: any = styled.div<IComponentProps>`
    width: 100%;
    margin: 40px 0px;
    padding: 40px 0px 0px;
    border-top: 1px dashed #8cd2f7;
    //border-bottom: 1px solid #8cd2f7;

    h2 {
        margin: 0px auto 8px;
        text-align: center;
        text-align: center;
        font: normal bold 32px Montserrat;
        letter-spacing: 0px;
        color: #8cd2f7;
        opacity: 1;
    }

    h3 {
        margin: 0 auto;
        text-align: center;
        text-align: center;
        font: normal bold 24px Montserrat;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
    }

    h3.yourProperty {
        text-align: left;
        margin-top: 40px;

        img {
            vertical-align: middle;
            margin-right: 40px;
        }
    }

    @media (max-width: 767px) {
        margin: 20px 0;
        padding: 20px 0 0;

        > h2 {
            font-size: 18px;
        }

        > h3 {
            font-size: 14px;
            padding: 0 45px;
        }

        .yourPropertyBox {
            background-color: #3a4650;
            padding: 20px;
            margin-top: 20px;

            > h3 {
                margin-top: 0;
                font-size: 14px;

                img {
                    width: 48px;
                    margin-right: 10px;
                }
            }

            > div > div {
                width: 100%;
            }

            .yourPropertyQuestion {
                flex-direction: column;
                padding: 0;

                > div {
                    padding: 0 !important;
                    width: 100%;

                    div,
                    select,
                    .MuiFormControl-root {
                        width: 100%;
                    }

                    #anchorType {
                        width: 100% !important;
                    }
                }

                > div:first-of-type {
                    padding: 20px 0 10px !important;
                    margin: 0;

                    > h2 {
                        text-align: left;
                    }
                }
            }
        }
    }
`;

export const PropertySelect: any = styled(AddressInputStyling)`
    @media (max-width: 767px) {
        width: 100%;
    }

    select {
        border: none;
        width: 100%;
        font: normal normal 12px Montserrat;
        letter-spacing: -0.3px;
        color: #93a0ab;
        cursor: pointer;

        .optionPT_ {
            font-style: italic;
        }

        @media (min-width: 768px) {
            font: normal normal 21px Montserrat;
            letter-spacing: -0.53px;
            font-size-adjust: 0.3;
        }
    }

    &&.not-valid {
        border: 5px solid red;
    }

    &.deductible-select-wrapper {
        @media (max-width: 767px) {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
`;

const Divider: any = styled.div`
    width: 100%;
    margin: 40px 0px 39px;
    padding: 0;
    height: 1px;
`;

export const DashedDiv: any = styled(Divider)`
    border-top: dashed 1px #8cd2f7;
`;

export const SolidDiv: any = styled(Divider)`
    border-top: solid 1px #8cd2f7;
`;

export const PlaceHolder: any = styled(PanelBase)`
    background-color: unset !important;
    flex-direction: row;
    padding: 40px;

    @media (max-width: 767px) {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 82px;

        span.MuiButton-label {
            font-size: 18px;
        }
    }
`;

const ButtonBase: any = styled(Button)`
    width: 232px;
    height: 58px;
    background: #005fab 0% 0% no-repeat padding-box;
    color: #ffffff;
    border-radius: 80px;
    opacity: 1;
    margin: 0 auto;
    font: normal normal bold 24px/37px Montserrat;
    letter-spacing: -0.6px;
`;

export const PreviousButton: any = styled(ButtonBase)`
    margin-right: 20px;
`;

export const NextButton: any = styled(ButtonBase)`
    margin-left: 20px;
    background-color: #129bdb;
`;

export const GetQuotesButton: any = styled(ButtonBase)`
    width: 502px;
    height: 82px;
    background-color: #129bdb;
`;
