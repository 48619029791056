// Libraries
import clsx from "clsx";
import React from "react";
import { Button, Divider, Paper, Grid, Typography, useMediaQuery as useMuiMediaQuery, Theme } from "@material-ui/core";

// Content
import nfiLogo from "Content/national-flood-insurance.png";
import wrightLogo from "Content/wright-logo.png";

// Components
import { ResultQuoteModel } from "../../Models/";
import { StoresInstance } from "../../Stores";

// Styling & images
import { useNFIPCardStyles, useResultStyles } from "./ResultPropsStyling";
import { useMediaQuery } from "Core/Utils/Media";

interface IProp {
    position: number;
    callback: (chosenVal: number) => void;
}

export const NFIPEmptyCard: React.FC<IProp> = props => {
    const classes = useResultStyles();
    const customClasses = useNFIPCardStyles();
    const xsOrUnder = useMuiMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
    const isSelectedQuote = "";
    const isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;
    const domainStores = StoresInstance.domain;

    const selectButtonText = "Select";

    return (
        <Paper className={isSelectedQuote ? classes.selectedPaper : classes.paper}>
            <Grid container>
                <Grid className={classes.header} container>
                    <Grid className={classes.imageGridItem} item>
                        <img alt="National Flood Insurance Program" src={wrightLogo} />
                    </Grid>
                    {isMobile || <Divider orientation="vertical" />}
                    <Grid className={classes.headerDetailsGridItem} item xs={6} md={9}>
                        <Grid className={classes.headerDetailsGridContainer} container>
                            <Grid className={classes.headerGridItem} item xs={12} md={6}>
                                <Typography className={classes.insurerText}>National Flood Insurance Program</Typography>
                            </Grid>
                        </Grid>
                        <Grid className={classes.headerDetailsGridContainer} container>
                            <Grid className={classes.headerGridItem} item xs={12} md={12} style={{ fontWeight: "bold" }}>
                                In order to provide you with an accurate NFIP quote, one of our expert agents will need some further information from you. This
                                may be because of the property type, flood zone, or loss history.
                                <span style={{ color: "#129bdb" }}> Click select to continue</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    {!xsOrUnder && (
                        <Grid className={classes.selectButtonGridItem} item>
                            <Button
                                className={isSelectedQuote ? classes.selectedButton : classes.selectButton}
                                variant="contained"
                                onClick={() => props.callback(props.position)}
                            >
                                {selectButtonText}
                            </Button>
                        </Grid>
                    )}
                </Grid>
                <Grid className={clsx(classes.body, customClasses.body)} container>
                    <Grid container>
                        <Grid className={clsx(classes.bodyGridItem, customClasses.bodyGridItemTitle)} item xs={12}>
                            <img alt="National Flood Insurance Program" src={nfiLogo} />
                            <Typography className={clsx(classes.bodyTitle, customClasses.bodyTitle)}>NFIP policy</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid className={classes.bodyGridItem} item xs={12}>
                            <Typography className={classes.bodyItemText}>
                                The National Flood Insurance Program (NFIP) is administered by the Federal Emergency Management Administration (FEMA). Flood
                                insurance offered through the NFIP is based on set rates that do not vary from carrier to carrier. The NFIP has maximum
                                residential coverage limits of $250,000 for building and $100,000 for contents.
                            </Typography>
                        </Grid>
                        {xsOrUnder && (
                            <Grid container>
                                <Grid className={classes.selectButtonGridItem} item>
                                    <Button
                                        className={isSelectedQuote ? classes.selectedButton : classes.selectButton}
                                        variant="contained"
                                        onClick={() => props.callback(props.position)}
                                    >
                                        {selectButtonText}
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};
