// Libraries
import * as MobX from "mobx";

// Core
import { ModelBase } from "../../Core/Models/ModelBase";
import { AdminStatsModel, AdminStatsModelDTO } from "./AdminStatsModel";
import { LatestUserQuoteModel } from "./LatestUserQuoteModel";

export class AdminStatsAndLatestUserQuotesModel extends ModelBase<AdminStatsAndLatestUserQuotesModel, AdminStatsAndLatestUserQuotesDTO> {
    // #region Statistics

    @MobX.observable
    public adminStatistics = new AdminStatsModel();

    // #endregion Statistics

    // #region Users

    @MobX.observable
    public users = MobX.observable<LatestUserQuoteModel>([]);

    // #endregion Users

    @MobX.action
    public fromDto = (model: AdminStatsAndLatestUserQuotesDTO): void => {
        this.adminStatistics.fromDto(model.adminStats);
        this.users.replace(model.latestUserQuotes);
    };

    public toDto = (): AdminStatsAndLatestUserQuotesDTO => {
        throw "toDto is not supported";
    };
}

export interface AdminStatsAndLatestUserQuotesDTO {
    adminStats: AdminStatsModelDTO;
    latestUserQuotes: LatestUserQuoteModel[];
}
