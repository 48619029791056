// Libraries
import * as MobX from "mobx";

// Core
import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";
import { BlankModel } from "../../../Core/Models/BlankModel";

// Custom
import { moneyFormat } from "../../Utils/Money";
import AgentInsurerStatisticsViewModel from "./AgentInsurerStatisticsViewModel";
import { Server } from "../../Globals/AppUrls";
import { UserAgencyDetailsModel, UserAgencyDetailsModelDTO } from "../../Models/UserAgencyDetailsModel";

const groupedAgencyDetailsList = {
    Wright: ["Wright NFIP", "Hiscox FloodPlus", "Zurich"],
};

export default class AgentDashboardViewModel extends ViewModelBase<BlankModel> {
    // #region Overall Statistics

    @MobX.observable
    private quotesProducedCount = 0;

    @MobX.observable
    private quotesSelectedCount = 0;

    @MobX.computed
    public get numberOfQuotesProduced() {
        return moneyFormat(this.quotesProducedCount);
    }

    @MobX.computed
    public get numberOfQuotesSelected() {
        return moneyFormat(this.quotesSelectedCount);
    }

    // #endregion Overall Statistics

    // #region Download Files

    public downloadAgreement = () => {};

    // #endregion

    // #region Insurer Statistics

    @MobX.observable
    public userAgencyDetails = MobX.observable<AgentInsurerStatisticsViewModel>([]);

    @MobX.observable
    public groupedAgencyDetails = MobX.observable<string, MobX.IObservableArray<AgentInsurerStatisticsViewModel>>(
        new Map<string, MobX.IObservableArray<AgentInsurerStatisticsViewModel>>(),
    );

    @MobX.action
    public createuserAgencyDetailViewModels(userAgencyDetails: UserAgencyDetailsModelDTO[]) {
        const insurers: AgentInsurerStatisticsViewModel[] = [];
        let quotesProducedCount = 0;
        let quotesSelectedCount = 0;

        for (const model of userAgencyDetails) {
            const insurer = new AgentInsurerStatisticsViewModel();

            insurer.createModel(model);
            insurers.push(insurer);

            quotesProducedCount += model.numberOfQuotesProduced;
            quotesSelectedCount += model.numberOfQuotesSelected;

            Object.entries(groupedAgencyDetailsList).forEach(([key, value]) => {
                if (value.includes(model.insurerName)) {
                    const has = this.groupedAgencyDetails.has(key);
                    const arr = has ? this.groupedAgencyDetails.get(key)! : MobX.observable<AgentInsurerStatisticsViewModel>([]);

                    if (!arr.find(i => i.insurerName === insurer.insurerName)) {
                        arr.push(insurer);

                        if (!has) {
                            this.groupedAgencyDetails.set(key, arr);
                        }
                    }
                }
            });

            insurer.setDashboardViewModel(this);
        }

        this.userAgencyDetails.replace(insurers);
        this.quotesProducedCount = quotesProducedCount;
        this.quotesSelectedCount = quotesSelectedCount;
    }

    // #endregion Insurer Statistics

    /**
     * Constructor
     */
    constructor() {
        super(new BlankModel());

        this.setDecorators(BlankModel);

        this.load();
    }

    // #region Server Api

    public load = async (): Promise<void> => {
        try {
            this.setIsLoading(true);

            const apiResult = await this.Post<UserAgencyDetailsModelDTO[]>(Server.Api.Agent.GetUserAgencyDetails);

            if (apiResult.wasSuccessful) {
                this.createuserAgencyDetailViewModels(apiResult.payload);
            } else {
                this.setIsErrored(true);
            }
        } catch (exception) {
            this.setIsErrored(true);
        } finally {
            this.setIsLoading(false);
        }
    };

    // #endregion Server Api

    // #region Viewmodel Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Viewmodel Boilerplate
}
