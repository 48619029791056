import * as MobX from "mobx";

import { ModelBase } from "../../Core/Models/ModelBase";
import { Min } from "class-validator";
import { AgentReportModel } from "./AgentReportModel";
import { runInAction } from "mobx";

export class AgentReportsModel extends ModelBase<AgentReportsModel, AgentReportsModelDTO> {
    @MobX.observable
    public reports = MobX.observable<AgentReportModel>([]);

    @MobX.action
    public fromDto = (model: AgentReportsModelDTO) => {
        this.reports.replace(model.reports);
    };
    public toDto = () => {};

    public resetAll = () => {};
}

// export interface AgentReportModelDTO {
// 	id: "";
// 	firstName: "";
// 	lastName: "";
// 	userName: "";
// 	quoteCount: 0;
// 	latestCreatedDate: "";
// }

export interface AgentReportsModelDTO {
    reports: AgentReportModel[];
}
