// Libraries
import React from "react";
import ReactHtmlParser from "react-html-parser";

// Components

// Styling & image

export const PolicyChat: React.FC = () => {
    return (
        <>
            {ReactHtmlParser(
                '<iframe style="height:525px; width:407px; background-color:#e7f6fe; border:none;" src="https://app.purechat.com/w/2ngrq"></iframe>',
            )}
        </>
    );
};
