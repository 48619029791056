import { observable } from "mobx";
import { IsNotEmpty, IsIn, Equals } from "class-validator";
import { ModelBase } from "../../Core/Models/ModelBase";

export class RealtorRegisterModel extends ModelBase<RealtorRegisterModel, RealtorRegisterModel> {
    @observable
    @IsNotEmpty({ message: "Realtor name is required" })
    public realtorName: string = "";

    @observable
    @IsNotEmpty({ message: "Password is required" })
    /* @Matches(isStandardPasswordStrengthEightChars, {
		message: "Password must contain at least 8 characters and include upper & lower case letters and at least one digit.",
	}) */
    public password: string = "";

    @observable
    @IsNotEmpty({ message: "You must confirm your password" })
    public confirmPassword: string = "";

    @observable
    @IsIn([true], { message: "You must accept the terms" })
    public hasAcceptedTerms: boolean = false;

    @observable
    @Equals(true, { message: "You must pass the captcha" })
    public passCaptcha: boolean = false;

    public isRealtorAccount = true;

    public fromDto = (model: RealtorRegisterModel) => {};
    public toDto = (model: RealtorRegisterModel) => {};
}
