// Libraries
import clsx from "clsx";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";
import { Button, Divider, Grid, Paper, Typography } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

// Core

// Content
import PXFuel from "Content/quoteBG.jpg";

// Custom
import { Footer } from "../Components/Footer";
import { useEffect, useRouter, StoresInstance } from "../Globals/Base";
import { DivWrapper, DivWrapperBGFixed } from "../StylesAppSpecific/GridHelperStyling";
import { Container } from "../StylesAppSpecific/Quote/Quote";
import QuoteViewModel from "../ViewModels/QuoteViewModel";
import { useQuoteStyles } from "../StylesAppSpecific/Quote/Quote";
import { getInsurerLogo } from "../Utils/InsurerLogo";
import { insurerParagraphMap } from "../Utils/InsurerParagraphMap";
import { isNullOrUndefined } from "util";
import { localTime } from "Custom/Utils/Time";
import PropertyQuoteStore from "Custom/Stores/Domain/PropertyQuoteStore";

export const Quote: React.FC = () => {
    const classes = useQuoteStyles();
    const { history, location, match } = useRouter();
    const { insurerName, id } = match.params as any;
    const quoteResponseVM = StoresInstance.domain.QuoteResultViewModel;

    const [viewModel] = useState(() => new QuoteViewModel(insurerName, id));

    useEffect(() => {
        // If the viewmodel is to allow navigation. set the viewmodel router.
        viewModel.setRouter(history, location);

        if (id === "notQuoted") {
            viewModel.setValue("quoteNumber", id);
            PropertyQuoteStore.instance.model.setValue("quoteNumber", id);
            viewModel.nfipNoQuoteSelected();
        }

        if (!quoteResponseVM.clickedQuotes.includes(id)) {
            viewModel.sendQuoteDetails(true);
            quoteResponseVM.clickedQuotes.push(id);
        }

        // With SPAs, we need to move back to the top of the page.
        window.scrollTo(0, 0);
    }, []);

    let lastQuoteDateFormatted = (quoteDateString: string) => {
        if (isNullOrUndefined(quoteDateString) || quoteDateString === "") {
            return "";
        }

        return localTime(quoteDateString).format("MM/DD/YY");
    };

    return useObserver(() => (
        <React.Fragment>
            <DivWrapperBGFixed additionalcss={"background-image: url(" + PXFuel + ");"} className={classes.background}>
                <div style={{ margin: "0 20px" }}>
                    <DivWrapper margin={"0px auto"} additionalcss={"max-width: 1124px;"}>
                        <Container>
                            <Button className={classes.navigationButton} onClick={viewModel.navigateBack} startIcon={<ChevronLeftIcon />} variant="contained">
                                Back to your quotes
                            </Button>
                            <Paper className={classes.paper}>
                                <Grid container>
                                    <Grid className={classes.header} container>
                                        <Grid container>
                                            <Grid className={classes.gridItem} item>
                                                <Typography className={classes.h1} component="h1" variant="h1">
                                                    You have selected the following policy:
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider className={classes.solidDivider} />
                                        <Grid container>
                                            <Grid className={classes.imageGridItem} item>
                                                <img alt={viewModel.policyName} src={getInsurerLogo(viewModel.insurerName)} />
                                            </Grid>
                                            <Grid className={classes.gridItem} item>
                                                <Grid container>
                                                    <Grid item>
                                                        <Typography>
                                                            {viewModel.model.quoteName.length > 0 ? viewModel.model.quoteName : viewModel.policyName}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container>
                                                    <Grid className={classes.headerLabelTextGridItem} item>
                                                        <Typography className={classes.label}>Policy term:</Typography>
                                                        <Typography className={classes.text}>{viewModel.policyStartDate}</Typography>
                                                    </Grid>
                                                </Grid>
                                                {viewModel.policyQuoteReference != null && viewModel.policyQuoteReference.length > 0 && (
                                                    <Grid container>
                                                        <Grid className={classes.headerLabelTextGridItem} item>
                                                            <Typography className={classes.label}>Carrier ref:</Typography>
                                                            <Typography className={classes.text}>{viewModel.policyQuoteReference}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </Grid>
                                            <Grid className={clsx(classes.gridItemNoTopPadding)} item>
                                                <Grid container>
                                                    <Grid xs={12} item>
                                                        <Typography className={classes.text}>{insurerParagraphMap(viewModel.insurerName)}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.body} container>
                                        <Grid container>
                                            <Grid className={clsx(classes.gridItem, classes.gridItemFlex)} item>
                                                <Typography className={clsx(classes.h3, classes.title)} component="h3" variant="h2">
                                                    Policy holder
                                                </Typography>
                                                <Grid container>
                                                    <Grid className={classes.policyHolderContent} item>
                                                        <Typography className={classes.text}>{viewModel.policyHolderName}</Typography>
                                                    </Grid>

                                                    <Grid className={clsx(classes.policyHolderLabelTextGridItem, classes.policyHolderContent)} item>
                                                        <Typography className={classes.label}>
                                                            <strong>Phone:</strong>
                                                        </Typography>
                                                        <Typography className={classes.text}>{viewModel.policyHolderPhoneNumber}</Typography>
                                                    </Grid>

                                                    <Grid className={clsx(classes.policyHolderLabelTextGridItem, classes.policyHolderContent)} item>
                                                        <Typography className={classes.label}>
                                                            <strong>Email:</strong>
                                                        </Typography>
                                                        <Typography className={classes.text}>{viewModel.policyHolderEmailAddress}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Divider className={classes.dottedDivider} />
                                        <Grid container>
                                            <Grid className={clsx(classes.gridItem, classes.gridItemFlex)} item>
                                                <Typography className={clsx(classes.h3, classes.title)} component="h3" variant="h2">
                                                    Property to be insured
                                                </Typography>

                                                <Grid container>
                                                    <Grid item>
                                                        <Typography className={classes.text}>{viewModel.propertyAddress}</Typography>
                                                    </Grid>
                                                </Grid>

                                                <br className={classes.labelTextLineBreak} />

                                                <Grid className={classes.gridContainerRowSpacing} container>
                                                    <Grid className={classes.labelGridItem} item>
                                                        <Typography className={classes.label}>Flood Zone</Typography>
                                                    </Grid>
                                                    <Grid className={classes.paragraphGridItem} item>
                                                        <Typography className={classes.text}>{viewModel.propertyFloodZone}</Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid className={classes.gridContainerRowSpacing} container>
                                                    <Grid className={classes.labelGridItem} item>
                                                        <Typography className={classes.label}>What year was the property built?</Typography>
                                                    </Grid>
                                                    <Grid className={classes.paragraphGridItem} item>
                                                        <Typography className={classes.text}>{viewModel.propertyConstructionYear}</Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid className={classes.gridContainerRowSpacing} container>
                                                    <Grid className={classes.labelGridItem} item>
                                                        <Typography className={classes.label}>How many stories does the property have?</Typography>
                                                    </Grid>
                                                    <Grid className={classes.paragraphGridItem} item>
                                                        <Typography className={classes.text}>{viewModel.propertyStories}</Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid className={classes.gridContainerRowSpacing} container>
                                                    <Grid className={classes.labelGridItem} item>
                                                        <Typography className={classes.label}>What square footage is the property</Typography>
                                                    </Grid>
                                                    <Grid className={classes.paragraphGridItem} item>
                                                        <Typography className={classes.text}>{viewModel.propertySize}</Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid className={classes.gridContainerRowSpacing} container>
                                                    <Grid className={classes.labelGridItem} item>
                                                        <Typography className={classes.label}>Is the property partially or completely over water?</Typography>
                                                    </Grid>
                                                    <Grid className={classes.paragraphGridItem} item>
                                                        <Typography className={classes.text}>{viewModel.propertyIsOverWater}</Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid className={classes.gridContainerRowSpacing} container>
                                                    <Grid className={classes.labelGridItem} item>
                                                        <Typography className={classes.label}>Is the property a mobile or manufactured home?</Typography>
                                                    </Grid>
                                                    <Grid className={classes.paragraphGridItem} item>
                                                        <Typography className={classes.text}>{viewModel.propertyIsMobileHome}</Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid className={classes.gridContainerRowSpacing} container>
                                                    <Grid className={classes.labelGridItem} item>
                                                        <Typography className={classes.label}>Does the property have a garage?</Typography>
                                                    </Grid>
                                                    <Grid className={classes.paragraphGridItem} item>
                                                        <Typography className={classes.text}>{viewModel.propertyHasGarage}</Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid className={classes.gridContainerRowSpacing} container>
                                                    <Grid className={classes.labelGridItem} item>
                                                        <Typography className={classes.label}>What is the property constructed with?</Typography>
                                                    </Grid>
                                                    <Grid className={classes.paragraphGridItem} item>
                                                        <Typography className={classes.text}>{viewModel.propertyConstructionType}</Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid className={classes.gridContainerRowSpacing} container>
                                                    <Grid className={classes.labelGridItem} item>
                                                        <Typography className={classes.label}>What foundation type is used on the property?</Typography>
                                                    </Grid>
                                                    <Grid className={classes.paragraphGridItem} item>
                                                        <Typography className={classes.text}>{viewModel.propertyFoundationType}</Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid className={classes.gridContainerRowSpacing} container>
                                                    <Grid className={classes.labelGridItem} item>
                                                        <Typography className={classes.label}>Does the property have an enclosure / basement?</Typography>
                                                    </Grid>
                                                    <Grid className={classes.paragraphGridItem} item>
                                                        <Typography className={classes.text}>{viewModel.propertyHasEnclosure}</Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid className={classes.gridContainerRowSpacing} container>
                                                    <Grid className={classes.labelGridItem} item>
                                                        <Typography className={classes.label}>The property is:</Typography>
                                                    </Grid>
                                                    <Grid className={classes.paragraphGridItem} item>
                                                        <Typography className={classes.text}>{viewModel.propertyNewProperty}</Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid className={classes.gridContainerRowSpacing} container>
                                                    <Grid className={classes.labelGridItem} item>
                                                        <Typography className={classes.label}>When do you want the policy coverage to start from?</Typography>
                                                    </Grid>
                                                    <Grid className={classes.paragraphGridItem} item>
                                                        <Typography className={classes.text}>
                                                            {lastQuoteDateFormatted(viewModel.propertyPolicyEffectiveDate)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid className={classes.gridContainerRowSpacing} container>
                                                    <Grid className={classes.labelGridItem} item>
                                                        <Typography className={classes.label}>I own and occupy this property</Typography>
                                                    </Grid>
                                                    <Grid className={classes.paragraphGridItem} item>
                                                        <Typography className={classes.text}>{viewModel.propertyOwnershipType}</Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid className={classes.gridContainerRowSpacing} container>
                                                    <Grid className={classes.labelGridItem} item>
                                                        <Typography className={classes.label}>
                                                            For how much of the next year will you occupy this property?
                                                        </Typography>
                                                    </Grid>
                                                    <Grid className={classes.paragraphGridItem} item>
                                                        <Typography className={classes.text}>{viewModel.propertyOccupy}</Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid className={classes.gridContainerRowSpacing} container>
                                                    <Grid className={classes.labelGridItem} item>
                                                        <Typography className={classes.label}>Do you currently have a flood insurance policy?</Typography>
                                                    </Grid>
                                                    <Grid className={classes.paragraphGridItem} item>
                                                        <Typography className={classes.text}>{viewModel.propertyInsurancePolicy}</Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid className={classes.gridContainerRowSpacing} container>
                                                    <Grid className={classes.labelGridItem} item>
                                                        <Typography className={classes.label}>Elevation Certificate</Typography>
                                                    </Grid>
                                                    <Grid className={classes.paragraphGridItem} item>
                                                        <Typography className={classes.text}>{viewModel.propertyElevationCertificate}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Divider className={classes.dottedDivider} />
                                        <Grid container>
                                            <Grid className={clsx(classes.gridItem, classes.gridItemFlex)} item>
                                                <Typography className={clsx(classes.h3, classes.title)} component="h3" variant="h2">
                                                    Property coverages
                                                </Typography>
                                                {viewModel.policyInstruction.length > 0 && viewModel.insurerName === "Hiscox FloodPlus" && (
                                                    <Grid container className={classes.gridContainerRowSpacing}>
                                                        <Grid xs={12} item>
                                                            <Typography className={classes.text}>{viewModel.policyInstruction}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                                <Grid className={classes.gridContainerRowSpacing} container>
                                                    <Grid className={classes.labelGridItem} item>
                                                        <Typography className={classes.label}>Building coverage:</Typography>
                                                    </Grid>
                                                    <Grid className={classes.paragraphGridItem} item>
                                                        <Typography className={classes.paragraph}>$</Typography>
                                                        <Typography className={classes.paragraph}>{viewModel.buildingCoverage}</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid className={classes.gridContainerRowSpacing} container>
                                                    <Grid className={classes.labelGridItem} item>
                                                        <Typography className={classes.label}>Contents coverage:</Typography>
                                                    </Grid>
                                                    <Grid className={classes.paragraphGridItem} item>
                                                        <Typography className={classes.paragraph}>$</Typography>
                                                        <Typography className={classes.paragraph}>{viewModel.contentCoverage}</Typography>
                                                    </Grid>
                                                </Grid>
                                                {viewModel.structuresCover != "" && (
                                                    <Grid className={classes.gridContainerRowSpacing} container>
                                                        <Grid className={classes.labelGridItem} item>
                                                            <Typography className={classes.label}>Other Structures coverage:</Typography>
                                                        </Grid>
                                                        <Grid className={classes.paragraphGridItem} item>
                                                            <Typography className={classes.paragraph}>$</Typography>
                                                            <Typography className={classes.paragraph}>{viewModel.structuresCover}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                                {viewModel.lossOfUse != "" && (
                                                    <Grid className={classes.gridContainerRowSpacing} container>
                                                        <Grid className={classes.labelGridItem} item>
                                                            <Typography className={classes.label}>Loss of use:</Typography>
                                                        </Grid>
                                                        <Grid className={classes.paragraphGridItem} item>
                                                            <Typography className={classes.paragraph}>$</Typography>
                                                            <Typography className={classes.paragraph}>{viewModel.lossOfUse}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Divider className={classes.dottedDivider} />
                                        <Grid container>
                                            <Grid className={clsx(classes.gridItem, classes.gridItemFlex)} item>
                                                <Typography className={clsx(classes.h3, classes.title)} component="h3" variant="h2">
                                                    Deductibles
                                                </Typography>
                                                <Grid container>
                                                    <Grid className={classes.labelGridItem} item>
                                                        <Typography className={clsx(classes.label, classes.deductibleColor)}>Deductible:</Typography>
                                                    </Grid>
                                                    <Grid className={classes.paragraphGridItem} item>
                                                        <Typography className={clsx(classes.paragraph, classes.deductibleColor)}>$</Typography>
                                                        <Typography className={clsx(classes.paragraph, classes.deductibleColor)}>
                                                            {viewModel.deductible}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Divider variant="middle" className={classes.dottedDivider} />
                                        <Grid container>
                                            <Grid className={clsx(classes.gridItem, classes.gridItemFlex)} item>
                                                <Typography className={clsx(classes.h3, classes.title)} component="h3" variant="h2">
                                                    Premium breakdown
                                                </Typography>
                                                <Grid className={classes.gridContainerRowSpacing} container>
                                                    <Grid className={classes.labelGridItem} item>
                                                        <Typography className={classes.label}>Building/Contents premium:</Typography>
                                                    </Grid>
                                                    <Grid className={classes.paragraphGridItem} item>
                                                        <Typography className={classes.paragraph}>$</Typography>
                                                        <Typography className={classes.paragraph}>{viewModel.premium}</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container>
                                                    <Grid className={classes.labelGridItem} item>
                                                        <Typography className={classes.label}>
                                                            {viewModel.insurerName === "Wright NFIP" ? <>Including taxes & fees:</> : <>Taxes & Fees:</>}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid className={classes.paragraphGridItem} item>
                                                        <Typography className={classes.paragraph}>$</Typography>
                                                        <Typography className={classes.paragraph}>{viewModel.taxesAndFees}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Divider className={classes.solidDivider} />
                                        <Grid container>
                                            <Grid className={clsx(classes.totalGridItem, classes.gridItemFlex)} item>
                                                <Grid container>
                                                    <Grid className={classes.labelGridItem} item>
                                                        <Typography className={classes.h3} component="h3" variant="h2">
                                                            Total annual premium
                                                        </Typography>
                                                    </Grid>
                                                    <Grid className={classes.paragraphGridItem} item>
                                                        <Typography className={classes.h3} component="h3" variant="h2">
                                                            $
                                                        </Typography>
                                                        <Typography className={classes.h3} component="h3" variant="h2">
                                                            {viewModel.total}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Divider className={classes.solidDivider} />
                                        <Grid container>
                                            <Grid className={classes.buyButtonGridItem} item>
                                                <Button
                                                    className={classes.buyButton}
                                                    disabled={!viewModel.canAcceptQuote}
                                                    onClick={viewModel.acceptQuote}
                                                    variant="contained"
                                                >
                                                    Buy this policy
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Container>
                    </DivWrapper>
                </div>
            </DivWrapperBGFixed>
            <Footer isTemp={false} />
        </React.Fragment>
    ));
};
