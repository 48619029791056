import { Grid } from "@material-ui/core";
import styled from "styled-components";

export interface IComponentProps {
    padding?: string;
    margin?: string;
    fontcolor?: string;
    backgroundcolor?: string;
    additionalcss?: string;
    maxwidth?: string;
    minheight?: string;
}

const ContainerBase: any = styled(Grid)<IComponentProps>`
	width: 100%;	
	${props => (props.backgroundcolor !== undefined ? "background-color: " + props.backgroundcolor + ";" : "")}
	${props => (props.fontcolor !== undefined ? "color: " + props.fontcolor + ";" : "")}
	${props => (props.maxwidth !== undefined ? "max-width: " + props.maxwidth + ";" : "")}
	${props => (props.minheight !== undefined ? "min-height: " + props.minheight + ";" : "")}
	margin: ${props => (props.margin !== undefined ? props.margin : "unset")};
	padding: ${props => (props.padding !== undefined ? props.padding : "unset")};
    ${props => props.additionalcss}
`;

export const Container: any = styled(ContainerBase)`
    h2.thankyou,
    p.online,
    p.offline {
        color: #129bdb;
        font-family: "Montserrat" Arial, Helvetica, sans-serif;
        font-weight: 700;
        font-size: 32px;
        letter-spacing: -0.8px;
        margin: 30px 0px 0px;
        @media (max-width: 767px) {
            font-size: 18px;
            margin: 0;
            padding: 0;
        }
    }

    p.online,
    p.offline {
        color: #191919;
        font-size: 24px;
        @media (max-width: 767px) {
            border-bottom: solid 1px #129bdb;
            padding-bottom: 16px;
            margin-bottom: 16px;
            font-size: 18px;
        }
    }
`;

export const PolicyContainer: any = styled(ContainerBase)`
    flex-direction: row;
    background-color: #ffffff;
    border: solid 5px #129bdb;
    border-radius: 15px;
`;

export const DetailsGrid: any = styled(ContainerBase)`
    display: inline-flex;
    flex-direction: column;
    padding: 45px 40px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    @media (max-width: 767px) {
        padding: 25px;
    }
`;

export const ChatGrid: any = styled(ContainerBase)`
    display: inline-flex;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: #e7f6fe;
`;

export const PolicyCardItem: any = styled(ContainerBase)`
    @media (max-width: 767px) {
        border-left: solid 1px #129bdb;
        padding-left: 15px;
    }
`;

export const PolicyCardImage: any = styled(ContainerBase)`
    text-align: center;
    vertical-align: middle;
    height: 100%;
    img {
        margin: 20% auto !important;
    }
    @media (max-width: 767px) {
        img {
            width: 76px;
            height: auto;
            margin: 10% auto !important;
        }
    }
`;

export const PolicyCardBox: any = styled(ContainerBase)`
    @media (min-width: 768px) {
        border: solid 5px #129bdb;
        border-radius: 15px;
    }

    h2,
    h3 {
        margin: 0px 0px 10px;
        padding: 0px;
        @media (max-width: 767px) {
            margin: 0;
        }
    }
    h2.insurer {
        letter-spacing: -0.6px;
        font-weight: 100;
        font-size: 24px;
        color: #191919;
        @media (max-width: 767px) {
            font-size: 14px;
        }
    }

    h2.total {
        font-weight: 600;
        font-size: 18px;
        letter-spacing: -0.45px;
        @media (max-width: 767px) {
            border-bottom: solid 1px #129bdb;
            border-top: solid 1px #129bdb;
            font-size: 14px;
            width: 100%;
            padding: 16px 0px;
            margin-top: 16px;
            span {
                float: right;
            }
        }
    }

    h3 {
        font-weight: 100;
        font-size: 14px;
        letter-spacing: -0.35px;

        span {
            display: inline-block;
            font-weight: 700;
            @media (min-width: 768px) {
                width: 100px;
            }
        }
        @media (max-width: 767px) {
            font-size: 10px;
        }
    }

    img {
        margin: auto;
    }
`;
