// Libraries
import * as MobX from "mobx";

// Core
import { FieldType, isEmptyOrWhitespace } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";

// Custom
import { Server } from "../../Globals/AppUrls";
import { AdminUserAndQuoteModel } from "../../Models/AdminUserAndQuoteModel";
import { moneyDollarFormat, moneyFormat } from "../../Utils/Money";
import AdminUserAndQuoteResultStore from "../../Stores/Domain/Admin/AdminUserAndQuoteResultStore";
import { AdminUserAndQuoteResultModelDTO } from "../../Models/AdminUserAndQuoteResultModel";
import { ResultModel, ResultQuoteModel } from "../../Models";
import { getConstructionType } from "../../Utils/ConstructionType";
import { getFoundationType } from "../../Utils/FoundationType";
import { getInsurancePolicy } from "../../Utils/InsurancePolicy";
import { getPropertyOccupyType } from "../../Utils/PropertyOccupyType";
import { getPropertyOwnershipType } from "../../Utils/PropertyOwnershipType";
import { getPropertySize } from "../../Utils/PropertySize";
import { localTime } from "../../Utils/Time";
import { ResultModelDTO } from "../../Models/ResultModel";

export default class AdminUserReportQuoteViewModel extends ViewModelBase<AdminUserAndQuoteModel> {
    @MobX.observable
    public blobId: string = "";

    @MobX.observable
    public quoteId: string = "";

    @MobX.computed
    public get name() {
        return `${AdminUserAndQuoteResultStore.instance.model.firstName} ${AdminUserAndQuoteResultStore.instance.model.lastName}`;
    }

    // #region Insurer

    @MobX.computed
    public get quote() {
        return this.returnQuote;
    }

    @MobX.computed
    public get policyName() {
        return !!this.returnQuote ? this.returnQuote.name : "";
    }

    @MobX.computed
    public get policyPremium() {
        return !!this.returnQuote ? moneyDollarFormat(this.returnQuote.total, true) : moneyDollarFormat(0, true);
    }

    @MobX.computed
    public get policyDeductible() {
        return !!this.returnQuote ? moneyDollarFormat(this.returnQuote.deductible, true) : moneyDollarFormat(0, true);
    }

    @MobX.computed
    public get policyQuoteNumber() {
        return !!this.returnQuote ? this.returnQuote.quoteNumber : "";
    }

    @MobX.computed
    public get policyInternalQuoteNumber() {
        return !!this.returnQuote ? this.returnQuote.internalQuoteNumber : "";
    }

    @MobX.computed
    public get isNFIPQuote() {
        return !!this.returnQuote ? this.returnQuote.name === "Wright NFIP" : false;
    }

    // #endregion Insurer

    // #region Policy Holder

    @MobX.computed
    public get policyHolderEmailAddress() {
        return AdminUserAndQuoteResultStore.instance.model.request.policyHolderEmail;
    }

    @MobX.computed
    public get policyHolderName() {
        return `${AdminUserAndQuoteResultStore.instance.model.request.policyHolderFirstName} ${AdminUserAndQuoteResultStore.instance.model.request.policyHolderLastName}`;
    }

    @MobX.computed
    public get policyHolderPhoneNumber() {
        return AdminUserAndQuoteResultStore.instance.model.request.policyHolderPhoneNumber;
    }

    // #endregion Policy Holder

    // #region Property

    @MobX.computed
    public get propertyAddress() {
        return AdminUserAndQuoteResultStore.instance.model.request.address;
    }

    @MobX.computed
    public get propertyConstructionType() {
        return getConstructionType(AdminUserAndQuoteResultStore.instance.model.request.constructionType);
    }

    @MobX.computed
    public get propertyConstructionYear() {
        return AdminUserAndQuoteResultStore.instance.model.request.yearBuilt;
    }

    @MobX.computed
    public get propertyFoundationType() {
        return getFoundationType(AdminUserAndQuoteResultStore.instance.model.request.foundationType);
    }

    @MobX.computed
    public get propertySize() {
        return getPropertySize(AdminUserAndQuoteResultStore.instance.model.request.buildingSqft);
    }

    @MobX.computed
    public get propertyStories() {
        return AdminUserAndQuoteResultStore.instance.model.request.numberOfFloors;
    }

    @MobX.computed
    public get propertyIsOverWater() {
        return AdminUserAndQuoteResultStore.instance.model.request.isOverWater ? "Yes" : "No";
    }

    @MobX.computed
    public get propertyIsMobileHome() {
        return AdminUserAndQuoteResultStore.instance.model.request.isMobileHome ? "Yes" : "No";
    }

    @MobX.computed
    public get propertyHasGarage() {
        return AdminUserAndQuoteResultStore.instance.model.request.hasGarage ? "Yes" : "No";
    }

    @MobX.computed
    public get propertyHasEnclosure() {
        return AdminUserAndQuoteResultStore.instance.model.request.hasBasement ? "Yes" : "No";
    }

    @MobX.computed
    public get propertyNewProperty() {
        return AdminUserAndQuoteResultStore.instance.model.request.isNewProperty ? "A new property" : "An existing property";
    }

    @MobX.computed
    public get propertyPolicyEffectiveDate() {
        return isEmptyOrWhitespace(AdminUserAndQuoteResultStore.instance.model.request.dateEffective)
            ? "Not sure"
            : localTime(AdminUserAndQuoteResultStore.instance.model.request.dateEffective!).format("MM/DD/YY");
    }

    @MobX.computed
    public get propertyOwnershipType() {
        return getPropertyOwnershipType(AdminUserAndQuoteResultStore.instance.model.request.ownershipType);
    }

    @MobX.computed
    public get propertyOccupy() {
        return getPropertyOccupyType(AdminUserAndQuoteResultStore.instance.model.request.propertyOccupy);
    }

    @MobX.computed
    public get propertyInsurancePolicy() {
        return getInsurancePolicy(AdminUserAndQuoteResultStore.instance.model.request.insurancePolicy);
    }

    @MobX.computed
    public get propertyElevationCertificate() {
        return !isEmptyOrWhitespace(AdminUserAndQuoteResultStore.instance.model.request.elevationCertificateUrl) ? "Yes" : "No";
    }

    // #endregion property

    // #region Figures

    @MobX.computed
    public get buildingCoverage() {
        return !!this.returnQuote ? moneyFormat(this.returnQuote.buildingCover, true) : moneyFormat(0, true);
    }

    @MobX.computed
    public get contentCoverage() {
        return !!this.returnQuote ? moneyFormat(this.returnQuote.contentsCover, true) : moneyFormat(0, true);
    }

    @MobX.computed
    public get deductible() {
        return !!this.returnQuote ? moneyFormat(this.returnQuote.deductible, true) : moneyFormat(0, true);
    }

    @MobX.computed
    public get premium() {
        return !!this.returnQuote ? moneyFormat(this.returnQuote.premium, true) : moneyFormat(0, true);
    }

    @MobX.computed
    public get taxesAndFees() {
        return !!this.returnQuote ? moneyFormat(this.returnQuote.fees + this.returnQuote.taxes, true) : moneyFormat(0, true);
    }

    @MobX.computed
    public get total() {
        return !!this.returnQuote ? moneyFormat(this.returnQuote.total, true) : moneyFormat(0, true);
    }

    // #endregion Figures

    /**
     * Constructor
     *
     * @param blobId The quote result to load (if defined).
     * @param quoteId The quote/policy to load (if defined).
     */
    constructor(blobId: string, quoteId: string) {
        super(new AdminUserAndQuoteModel());
        this.setDecorators(AdminUserAndQuoteModel);
        this.blobId = blobId;
        this.quoteId = quoteId;

        if (!isEmptyOrWhitespace(blobId) && blobId !== "null") {
            // Only load the quote results if the blob id has changed.
            if (blobId !== AdminUserAndQuoteResultStore.instance.model.blobId) {
                AdminUserAndQuoteResultStore.instance.model.reset();
                this.load(blobId);
            }
        } else {
            AdminUserAndQuoteResultStore.instance.model.reset();
        }
    }

    @MobX.computed
    private get returnQuote() {
        let quotesArr: ResultQuoteModel[] = [];

        quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.wrightResult.quotes);
        quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.aonEdgeResult.quotes);
        quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.argeniaResult.quotes);
        quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.fRSResult.quotes);
        quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.neptuneResult.quotes);
        quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.sterlingResult.quotes);
        quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.hiscoxFloodPlusResult.quotes);
        quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.selectiveResult.quotes);
        quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.wrightPrivateFloodResult.quotes);
        //quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.zurichResult.quotes);

        return quotesArr.find(q => q.id === this.quoteId);
    }

    // #region Navigation

    public backToDashboard = () => {
        this.history.push("/admin");
    };

    public backToUserReport = () => {
        this.history.push(`/adminuserreport/${AdminUserAndQuoteResultStore.instance.model.id}`);
    };

    public backToQuotes = () => {
        this.history.push(`/adminuserreportquotes/${this.blobId}`);
    };

    // #endregion Navigation

    // #region Server Api

    /**
     * Loads the specified quote from the server.
     *
     * @param id The unique identifier of the blob to load.
     */
    public load = async (id: string): Promise<void> => {
        try {
            this.setIsLoading(true);

            const apiResult = await this.Post<AdminUserAndQuoteResultModelDTO>(Server.Api.Admin.GetUserAndQuoteResult, { id });

            if (apiResult.wasSuccessful) {
                AdminUserAndQuoteResultStore.instance.model.fromDto(apiResult.payload);

                const values = Object.values(apiResult.payload.result);
                let foundThisQuote = false;
                for (const value of values) {
                    if (typeof value !== "object") continue;
                    if ((value as ResultModelDTO).quotes.find(q => q.id === this.quoteId)) {
                        foundThisQuote = true;
                        break;
                    }
                }

                if (!foundThisQuote) {
                    const apiResult1 = await this.Post<string | undefined>(Server.Api.Admin.GetAdminQuotes, { id: this.blobId });

                    if (apiResult1.wasSuccessful) {
                        if (apiResult1.payload) {
                            const apiResult2 = await this.Post<AdminUserAndQuoteResultModelDTO>(Server.Api.Admin.GetUserAndQuoteResult, {
                                id: apiResult1.payload,
                            });

                            if (apiResult2.wasSuccessful) {
                                AdminUserAndQuoteResultStore.instance.model.fromDto(apiResult2.payload);
                            } else {
                                this.setIsErrored(true);
                            }
                        } else {
                            this.setIsErrored(true);
                        }
                    } else {
                        this.setIsErrored(true);
                    }
                }
            } else {
                this.setIsErrored(true);
            }
        } catch (exception) {
            this.setIsErrored(true);
        } finally {
            this.setIsLoading(false);
        }
    };

    // #endregion Server Api

    // #region Viewmodel Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Viewmodel Boilerplate
}
