import * as MobX from "mobx";

import { FieldType, sortByString } from "../../Core/Utils/Utils";
import { GenericIncludeDeleted, LoginModel, Role, RoleDTO, AccessLevel } from "../Models";
import { User, UserDTO } from "Custom/Models/User";
import { action, computed } from "mobx";

import { ApiResult } from "Core/Models/ApiResult";
import { StoresInstance } from "Custom/Stores/Stores";
import { UserStore } from "Custom/Stores/Domain";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";

import { Server, Admin } from "../Globals/AppUrls";
import { AccessLevelDTO } from "../Models/AccessLevel";
import { KeyValuePair } from "../../Core/Models/KeyValuePair";

const domainStores = StoresInstance.domain;

export default class UserViewModel extends ViewModelBase<User> {
    @MobX.observable public userCount: number = 0;
    @MobX.observable private ascOrder = true;
    private userStore: UserStore = StoresInstance.domain.UserStore;
    @MobX.observable public Valid: boolean = false;
    @MobX.observable public AccessLevelOptions = MobX.observable<AccessLevel>([]);
    @MobX.observable public pwResetStatus?: boolean | null;

    constructor() {
        super(new User(""));
        this.setDecorators(User);
    }

    public setUser(user: User, newUser: boolean) {
        this.setValue("id", newUser ? "" : user.id);
        this.setValue("firstName", newUser ? "" : user.firstName);
        this.setValue("lastName", newUser ? "" : user.lastName);
        this.setValue("email", newUser ? "" : user.email);
        this.setValue("password", newUser ? "" : user.password);
        this.setValue("accessLevel", newUser ? "" : user.accessLevel);
        this.setValue("terms", newUser ? "" : user.terms);
        this.setValue("createdDate", newUser ? new Date().toISOString() : user.createdDate);
    }

    public get(fieldName: any) {
        return this.getValue(fieldName);
    }

    @action
    public set(fieldName: any, value: string | number | boolean | Date) {
        this.setValue(fieldName, value as string);
    }

    public isFieldValid(fieldName: keyof FieldType<User>, value: any): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @action
    public async loadUsersAsync(): Promise<ApiResult<UserDTO[]>> {
        const includeGenericDeleted: GenericIncludeDeleted = {
            includeDeleted: true,
        };
        var apiResult = await this.Post<UserDTO[]>(Admin.Users.GetAll, includeGenericDeleted);

        if (apiResult.wasSuccessful) {
            this.userStore.setUsers(apiResult.payload);
            MobX.runInAction(() => (this.userCount = this.userStore.getUserCount));
        }
        return apiResult;
    }

    public async getUserRoles(): Promise<ApiResult<RoleDTO[]>> {
        MobX.runInAction(() => {
            this.model.userRoles.clear();
        });
        var apiResult = await this.Post<RoleDTO[]>(Admin.Users.GetRoles, { id: this.getValue("id") });
        if (apiResult.wasSuccessful) {
            apiResult.payload.forEach(role => {
                MobX.runInAction(() => {
                    const existingRole = domainStores.RoleStore.getRoles.find(r => r.id === role.id);

                    if (existingRole) {
                        this.model.userRoles.push(existingRole);
                    }
                });
            });
        }
        return apiResult;
    }

    public async getAccessLevelOptions(): Promise<ApiResult<AccessLevelDTO[]>> {
        const apiResult = await this.Get<AccessLevelDTO[]>(Admin.Roles.GetAllAccessLevelOptions);
        if (apiResult && apiResult.wasSuccessful) {
            const accessLevels = apiResult.payload.map(al => {
                const model = new AccessLevel();
                model.fromDto(al);
                return model;
            });

            MobX.runInAction(() => {
                this.AccessLevelOptions.replace(accessLevels);
            });
        } else {
            console.error("Failed to fetch access level options.");
        }

        return apiResult;
    }

    @computed
    public get accessLevelOptionsSelect(): KeyValuePair[] {
        return this.AccessLevelOptions.map(al => ({
            key: al.name,
            value: al.value,
        }));
    }

    @computed get getUsers(): User[] {
        let users = this.userStore.getUsers.slice();
        users.sort((a: UserDTO, b: UserDTO) => {
            if (this.ascOrder) return sortByString(a.firstName, b.firstName);
            else return sortByString(b.firstName, a.firstName);
        });
        return users;
    }

    public getUser = (id: string) => {
        if (id) {
            return this.userStore.getUsers.find(u => u.id === id);
        } else {
            return new User("");
        }
    };

    @computed get getUserCount(): number {
        return this.userCount;
    }

    @computed get getIsLoadingData(): boolean {
        return this.userStore.getIsLoadingData;
    }

    @action
    public setOrderAsc() {
        this.ascOrder = !this.ascOrder;
    }

    @computed get getOrderAsc(): boolean {
        return this.ascOrder;
    }

    public async postUserDetailAsync() {
        var apiResult = await this.Post<any>(Server.Api.Account.UpsertUser, this.getModel);
        if (apiResult.wasSuccessful) {
            let existingUser = this.userStore.getUsers.find(u => u.id === this.getValue("id"));
            if (existingUser) {
                const index = this.userStore.getUsers.indexOf(existingUser, 0);
                if (index > -1) {
                    this.userStore.getUsers.splice(index, 1);
                }
            }
            this.userStore.getUsers.push(apiResult.payload);
        }
        return apiResult.payload;
    }

    public async resetUserPasswordAsync() {
        MobX.runInAction(() => {
            this.pwResetStatus = null;
        });
        const apiResult = await this.Post<boolean>(Admin.Users.ResetPassword, { id: this.getModel.id });
        if (apiResult && apiResult.wasSuccessful && apiResult.payload) {
            MobX.runInAction(() => {
                this.pwResetStatus = true;
            });
        } else {
            MobX.runInAction(() => {
                this.pwResetStatus = false;
            });
        }
    }
}
