// Libraries
import React, { useState, useEffect } from "react";
import { useObserver } from "../../Globals/Base";
import { StoresInstance } from "../../Stores";
import Autocomplete from "@material-ui/lab/Autocomplete";

// Styling & images
import { StyledDiv, ContactDetailStyle2, TermsContainer } from "../../StylesAppSpecific/ContactForms";

// App
import { AgencyDetailViewModel } from "../../ViewModels/Agent/AgencyDetailViewModel";
import { AgencyDetailModel } from "../../Models/AgencyDetailModel";
import { statesArrState } from "../../Utils/StatesArr";

// Components
import { EditableInput } from "../../../Core/Components/EditableInput";
import { TextField, FormHelperText } from "@material-ui/core";

interface IProps {
    viewModel: AgencyDetailViewModel;
    noBackground?: boolean;
    hideErrorBorder?: boolean;
}

export const AgencyDetails: React.FC<IProps> = props => {
    /* export const ContactDetail: React.FC = () => { */
    /* const { viewModel } = props; */
    const domainStores = StoresInstance.domain;
    const thisViewModel = props.viewModel;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return useObserver(() => (
        <>
            <ContactDetailStyle2 className="pro-register-step" additionalcss={props.noBackground ? "background-color: transparent;" : ""}>
                <h2 className="contact">Agency name</h2>
                <StyledDiv className="input-wrapper" isValid={true}>
                    <EditableInput<AgencyDetailModel>
                        viewModel={thisViewModel}
                        type="text"
                        fieldName="name"
                        fullwidth
                        inputProps={{ placeholder: "Agency name", autoComplete: "new-password" }}
                    />
                </StyledDiv>
                <StyledDiv className="input-wrapper" isValid={true}>
                    <EditableInput<AgencyDetailModel>
                        viewModel={thisViewModel}
                        type="text"
                        fieldName="principal"
                        fullwidth
                        inputProps={{ placeholder: "Agency Principal", autoComplete: "new-password" }}
                    />
                </StyledDiv>
                <div className="contact-bottom" />
            </ContactDetailStyle2>
            <ContactDetailStyle2 className="pro-register-step" additionalcss={props.noBackground ? "background-color: transparent;" : ""}>
                <h2 className="contact">Agency address</h2>
                <StyledDiv className="input-wrapper" isValid={true}>
                    <EditableInput<AgencyDetailModel>
                        viewModel={thisViewModel}
                        type="text"
                        fieldName="address1"
                        fullwidth
                        inputProps={{ placeholder: "Agency address 1", autoComplete: "new-password" }}
                    />
                </StyledDiv>
                <StyledDiv className="input-wrapper" isValid={true}>
                    <EditableInput<AgencyDetailModel>
                        viewModel={thisViewModel}
                        type="text"
                        fieldName="address2"
                        fullwidth
                        inputProps={{ placeholder: "Agency address 2", autoComplete: "new-password" }}
                    />
                </StyledDiv>
                <div className="contact-bottom" />
            </ContactDetailStyle2>
            <ContactDetailStyle2 className="pro-register-step" additionalcss={props.noBackground ? "background-color: transparent;" : ""}>
                <StyledDiv className="input-wrapper" isValid={true}>
                    <EditableInput<AgencyDetailModel>
                        viewModel={thisViewModel}
                        type="text"
                        fieldName="address3"
                        fullwidth
                        inputProps={{ placeholder: "Town / City", autoComplete: "new-password" }}
                    />
                </StyledDiv>
                <StyledDiv className="input-wrapper" isValid={true}>
                    <EditableInput<AgencyDetailModel>
                        viewModel={thisViewModel}
                        type="text"
                        fieldName="county"
                        fullwidth
                        inputProps={{ placeholder: "County", autoComplete: "new-password" }}
                    />
                </StyledDiv>
                <div className="contact-bottom" />
            </ContactDetailStyle2>
            <ContactDetailStyle2 className="pro-register-step" additionalcss={props.noBackground ? "background-color: transparent;" : ""}>
                <StyledDiv isValid={true} className="stateslist input-wrapper" isPlaceholder={thisViewModel.getValue("state") === ""}>
                    <Autocomplete
                        options={statesArrState()}
                        getOptionLabel={o => o.key}
                        getOptionSelected={(a, b) => a.key === b.key}
                        onChange={(_, newValue) => {
                            thisViewModel.setValue("state", newValue?.value || "");
                            thisViewModel.isFieldValid("state", newValue?.value || "");
                        }}
                        renderInput={params => (
                            <TextField
                                {...params}
                                inputProps={{ ...params.inputProps, autoComplete: "new-password" } as any}
                                fullWidth
                                placeholder="Select State"
                                error={!thisViewModel.getValid("state")}
                            />
                        )}
                    />
                    <FormHelperText className="Mui-error custom" style={{ color: "red" }}>
                        {thisViewModel.getError("state")}
                    </FormHelperText>
                    {/* <EditableInput<AgencyDetailModel>
                        viewModel={thisViewModel}
                        type="select"
                        selectItems={statesArrState()}
                        fieldName="state"
                        fullwidth
                        selectPlaceholder="Select State"
                    /> */}
                </StyledDiv>
                <StyledDiv className="input-wrapper" isValid={true}>
                    <EditableInput<AgencyDetailModel>
                        viewModel={thisViewModel}
                        type="text"
                        fieldName="zipCode"
                        fullwidth
                        inputProps={{ placeholder: "Zip code", autoComplete: "new-password" }}
                    />
                </StyledDiv>
                <div className="contact-bottom" />
            </ContactDetailStyle2>
        </>
    ));
};
