import * as MobX from "mobx";
import { ModelBase } from "../../../Core/Models/ModelBase";

export class AddressModel extends ModelBase<AddressModel, AddressModelDTO> {
    @MobX.observable
    public street_number: string = "";

    @MobX.observable
    public street_pre_direction: string = "";

    @MobX.observable
    public street_name: string = "";

    @MobX.observable
    public street_suffix: string = "";

    @MobX.observable
    public street_post_direction: string = "";

    @MobX.observable
    public unit_type: string = "";

    @MobX.observable
    public unit_number: string = "";

    @MobX.observable
    public formatted_street_address: string = "";

    @MobX.observable
    public city: string = "";

    @MobX.observable
    public state: string = "";

    @MobX.observable
    public zip_code: string = "";

    @MobX.observable
    public zip_code_four_code: string = "";

    @MobX.observable
    public carrier_code: string = "";

    @MobX.observable
    public latitude: number = 0;

    @MobX.observable
    public longitude: number = 0;

    @MobX.observable
    public geocoding_accuracy: string = "";

    @MobX.observable
    public census_tract: string = "";

    public fromDto = (model: AddressModelDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                this[key] = model[key];
            }
        }
    };
    public toDto = (model: AddressModel) => {};

    public resetAddress = () => {
        this.street_number = "";
        this.street_pre_direction = "";
        this.street_name = "";
        this.street_suffix = "";
        this.street_post_direction = "";
        this.unit_type = "";
        this.unit_number = "";
        this.formatted_street_address = "";
        this.city = "";
        this.state = "";
        this.zip_code = "";
        this.zip_code_four_code = "";
        this.carrier_code = "";
        this.latitude = 0;
        this.longitude = 0;
        this.geocoding_accuracy = "";
        this.census_tract = "";
    };
}

export interface AddressModelDTO {
    street_number: string;
    street_pre_direction: string;
    street_name: string;
    street_suffix: string;
    street_post_direction: string;
    unit_type: string;
    unit_number: string;
    formatted_street_address: string;
    city: string;
    state: string;
    zipcode: string;
    zip_code_four_code: string;
    carrierCode: string;
    latitude: number;
    longitude: number;
    geocoding_accuracy: string;
    census_tract: string;
}
