import { observable, runInAction } from "mobx";

// Core
import { FieldType } from "../../Core/Utils/Utils";
import { ViewModelBase } from "../../Core/ViewModels/ViewModelBase";

// Custom
import { PropertyQuoteModel, PropertyQuoteModelDTO } from "../Models";
import { StoresInstance } from "../Stores";
import { Server } from "../Globals/AppUrls";

export class PropertyQuoteViewModel extends ViewModelBase<PropertyQuoteModel> {
    constructor() {
        super(new PropertyQuoteModel());
        this.setDecorators(PropertyQuoteModel);
    }

    public resetAll = () => {
        this.resetAll();
    };

    public isFieldValid(fieldName: keyof FieldType<PropertyQuoteModel>, value: any, showErrors: boolean = true): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        if (showErrors) {
            this.setError(fieldName, errorMessage);
            this.setValid(fieldName, isValid);
        }

        return isValid;
    }

    public afterUpdate: any;
    public beforeUpdate: undefined;
}
