// Libraries
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { Button } from "@material-ui/core";

// Components
import { StoresInstance, useRouter } from "Custom/Globals/Base";
import { useMediaQuery } from "Core/Utils/Media/mediaQuery";
import { RealtorDetails } from "Custom/Components/ContactForm";
import { RealtorRegisterViewModel } from "Custom/ViewModels/RealtorRegisterViewModel";
import { EditableInput } from "Core/Components/EditableInput";
import { PolicyHolder } from "Custom/Components/PropertyCoverage/PolicyHolder";

// Styling & images
import QuoteBackground from "Content/quoteBG.jpg";
import { DivWrapper, DivWrapperBGFixed } from "Custom/StylesAppSpecific/GridHelperStyling";
import { DashboardContentContainer, DashboardHeaderContainer } from "./Dashboard";
import { DetailsStyles } from "Custom/StylesAppSpecific/EditDashboardStyling";

export const EditRealtorDetail: React.FC = () => {
    const domainStores = StoresInstance.domain;
    const accountStore = domainStores.AccountStore;
    const { history } = useRouter();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const realtorDetailVM = domainStores.RealtorDetailViewModel;

    useEffect(() => {
        //let name = accountStore.RealtorName ? accountStore.RealtorName : "";
        //let logo = accountStore.RealtorLogo ? accountStore.RealtorLogo : "";
        let name = accountStore.RealtorName ? accountStore.RealtorName : realtorDetailVM.getValue<string>("name");
        let logo = accountStore.RealtorLogo ? accountStore.RealtorLogo : realtorDetailVM.getValue<string>("logo");
        let _ = realtorDetailVM.setFromLogin(name, logo);
    }, []);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        realtorDetailVM.setValue("userId", accountStore.RealtorId);
        setIsLoading(true);
        realtorDetailVM.UpsertRealtorDetailAsync().then(apiResult => {
            setIsLoading(false);
        });
    };

    return useObserver(() => (
        <>
            <DivWrapperBGFixed additionalcss={"background-image: url(" + QuoteBackground + ");"}>
                <DashboardContentContainer>
                    <DashboardHeaderContainer>
                        <h1>
                            <span>{accountStore.FirstName},</span> amend your Realtor details here
                        </h1>
                    </DashboardHeaderContainer>
                    <form onSubmit={handleSubmit}>
                        <DetailsStyles>
                            <RealtorDetails viewModel={realtorDetailVM} isEditor={false} />
                            <div className="btns">
                                <Button className="save-btn" variant="contained" type="submit" disabled={isLoading}>
                                    {isLoading ? "Saving..." : "Save"}
                                </Button>
                                <Button className="cancel-btn" variant="contained" onClick={() => history.push("./dashboard")}>
                                    Cancel
                                </Button>
                            </div>
                        </DetailsStyles>
                    </form>
                </DashboardContentContainer>
            </DivWrapperBGFixed>
        </>
    ));
};
