// Libs

import * as MobX from "mobx";

import { IsEmail, IsNotEmpty, Equals } from "class-validator";
import { ModelBase } from "../../Core/Models/ModelBase";
import { Role } from ".";

// App

export class User extends ModelBase<User, UserDTO> {
    public id: string = "";
    @IsNotEmpty({ message: "First name is required" })
    @MobX.observable
    public firstName: string = "";
    @IsNotEmpty({ message: "Last name is required" })
    @MobX.observable
    public lastName: string = "";
    @MobX.observable
    @IsEmail({}, { message: "Email is not valid" })
    @IsNotEmpty({ message: "Email is required" })
    public email: string = "";
    @MobX.observable public password: string = "";
    @MobX.observable
    public phoneNumber: string = "";
    @MobX.observable public role: string = "";
    @MobX.observable public concurrencyToken: string = "";
    @MobX.observable public isDeleted: boolean = false;
    @MobX.observable public userRoles = MobX.observable<Role>([]);
    @MobX.observable public createdDate: string = "";
    @IsNotEmpty({ message: "Access level is required" })
    @MobX.observable
    public accessLevel: string = "";
    @MobX.observable
    @Equals(true, { message: "Required" })
    public terms: boolean = false;
    @MobX.observable
    public agencyAgreement = false;

    constructor(id: string) {
        super();
        this.id = id;
    }

    @MobX.action
    public fromDto = (model: UserDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                this[key] = model[key];
            }
        }
    };

    public toDto = (model: UserDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                model[key] = this[key];
            }
        }
    };
}

export interface UserDTO {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    phoneNumber: string;
    role: string;
    concurrencyToken: string;
    isDeleted: boolean;
    accessLevel: string;
    terms: boolean;
    createdDate: string;
    agencyAgreement: boolean;
}
