// Libraries
import React, { useContext } from "react";
import { useMediaQuery } from "Core/Utils/Media";
import { Link as RouterLink } from "react-router-dom";

// App

// Components
import { MetaHeader } from "../Components/SEOComponents";
import { SEOHeader } from "../Models";
import { Footer } from "../Components/Footer";
import { SignUpPro } from "../Components/Home/signUpPro";
//import { ContactForm } from "../Components/ContactForm";
import { AddressSelectorGoogle, AddressSelectorHome } from "../Components/AddressSelector";
import { QandAWHY, QandAFAQ } from "../Components/QandA";
import { HomeUSP } from "Custom/Components/USPGroups/HomeUSP";

// Styling & images
import { Grid, Box, Link } from "@material-ui/core";
import { PreambleText, HdrImg, HeaderAnchor, AppButtonWrapper } from "../StylesAppSpecific/PageStyling";
import { DivWrapper, DivWrapperNoVH, GridSpaced, DialogueWrapper, DivWrapperBGFixed } from "../StylesAppSpecific/GridHelperStyling";

import FamilyPicture from "Content/mobileHomeBG.png";
import FamilyPictureDT from "Content/mainBG.png";
import MFIhomepage from "Content/MFIhomepageopt-min.jpg";
import Puddles from "Content/homeBG2.png";
import ArrowRight from "Content/Arrow-right.svg";
import MFRLogo from "Content/MFR-logo.svg";
import HereToHelp from "Content/hereToHelp.svg";
import ProBackground from "Content/ProBackground.png";
import AppStore from "Content/appleAppStore.svg";
import GooglePlay from "Content/googlePlay.svg";

import { DotWrapper, Dot } from "Core/Components/Styles";
import { CalltoActionContact, CalltoActionMFI, HomeVerticalCenter } from "Custom/StylesAppSpecific/HomeStyling";
import { HomePrimaryPanel } from "Custom/StylesAppSpecific/StepsStyling";
import { useHashLinks } from "../../Core/Utils/Hooks/UseHashLinks";
import { StoresInstance, Stores, StoresContext } from "Custom/Stores";
import { MapStore } from "Custom/Stores/Domain";
import { WarningBanner } from "../Components/WarningBanner/WarningBanner";
import { Observer, useObserver } from "mobx-react-lite";

export const Home: React.FC = () => {
    const domainStores = StoresInstance.domain;
    const mapStoresContext: MapStore = useContext<Stores>(StoresContext).domain.MapStore;
    let isMobile: boolean;
    isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;
    let isiPhone: boolean;
    isiPhone = useMediaQuery("screen and (min-width: 350px) and (max-width: 414px)") ? true : false;
    let isWrap: boolean;
    isWrap = useMediaQuery("screen and (max-width: 1120px)") ? true : false;
    domainStores.QuoteRequestViewModel.setValue("address", "");
    domainStores.QuoteRequestViewModel.setValue("city", "");
    domainStores.QuoteRequestViewModel.setValue("county", "");
    domainStores.QuoteRequestViewModel.setValue("state", "");
    domainStores.QuoteRequestViewModel.setValue("zipcode", "");
    domainStores.QuoteRequestViewModel.setValue("latitude", "");
    domainStores.QuoteRequestViewModel.setValue("longitude", "");
    domainStores.PropertyDetailViewModel.setPropertyType("");
    domainStores.EstatedHasLoaded = false;
    mapStoresContext.ResetLocation();
    useHashLinks();

    let metaData: SEOHeader = {
        title: "My Flood Insurance - a revolutionary new way of buying flood insurance",
        description: "My Flood Insurance - Instant Flood Insurance Quotes",
    };
    const contentHdr = (
        <>
            {!isMobile && (
                <>
                    <GridSpaced container justify={"center"} margin={"50px 0px 0px"} additionalcss={"max-width: 570px; width: 100%;"}>
                        <Grid item xs={12} sm={12}>
                            <PreambleText
                                padding={"0"}
                                margin={"28px 0px 0px"}
                                fontsize={"73px"}
                                additionalcss={"letter-spacing: -4.43px; line-height: 1; text-align: left;"}
                            >
                                Get a quote
                                <br />
                                in 3 simple steps
                            </PreambleText>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <PreambleText padding={"0"} margin={"5px 0px 26px"} fontsize={"28px"}>
                                simply enter your address below to begin
                            </PreambleText>
                        </Grid>
                    </GridSpaced>
                </>
            )}

            {isMobile && (
                <>
                    <GridSpaced container justify={"center"} margin={"0px 0px 70px"} additionalcss={"max-width: 570px; width: 100%;"}>
                        <Grid item xs={12} sm={12}>
                            <PreambleText
                                padding={"0"}
                                margin={"35px 0px 0px"}
                                fontsize={"38px"}
                                additionalcss={"letter-spacing: -1.9px; line-height: 1; text-align: center;"}
                            >
                                Get a quote in 3
                                <br />
                                simple steps
                            </PreambleText>
                        </Grid>
                    </GridSpaced>
                </>
            )}
        </>
    );

    const appStorePanel = (
        <>
            <AppButtonWrapper>
                <div>
                    <Link
                        href={"https://play.google.com/store/apps/details?id=com.shoothill.mfi&hl=en-GB"}
                        target={"_blank"}
                        title={"Download the MFI App from Google Play"}
                    >
                        <img src={GooglePlay} alt="Download the MFI App from Google Play" />
                    </Link>
                </div>
                <div>
                    <Link
                        href={"https://apps.apple.com/gb/app/my-flood-insurance/id1567185081"}
                        target={"_blank"}
                        title={"Download the MFI App from the App Store"}
                    >
                        <img src={AppStore} alt="Download the MFI App from the App Store" />
                    </Link>
                </div>
            </AppButtonWrapper>
        </>
    );

    const formPlacement = (extraFocusScroll?: number) => (
        <>
            {isMobile && (
                <>
                    <GridSpaced container margin={"0px auto"} additionalcss={"max-width: 688px; width: 100%;"}>
                        <GridSpaced item margin={"0px auto"}>
                            <AddressSelectorHome gotoNext={true} extraFocusScroll={extraFocusScroll} />
                            {/* 
                            <DialogueWrapper additionalcss={"border-radius: 20px;"}>
                                <AddressSelectorGoogle />
                            </DialogueWrapper> 
                            */}
                        </GridSpaced>
                    </GridSpaced>
                    {!domainStores.wrapper && <>{appStorePanel}</>}
                </>
            )}

            {!isMobile && (
                <>
                    <GridSpaced container margin={"0px"} additionalcss={"max-width: 561px; width: 100%;"}>
                        <GridSpaced item margin={"0px auto 40px"}>
                            <AddressSelectorHome gotoNext={true} />
                        </GridSpaced>
                    </GridSpaced>
                    {appStorePanel}
                </>
            )}
        </>
    );

    const homePrimaryPanel = (extraFocusScroll: number) => (
        <>
            {isMobile ? (
                <>
                    <HomeVerticalCenter margin="0 auto" display="block" overflow="auto" height="100%">
                        {contentHdr}
                        <HomeUSP />
                        <Grid container>
                            <Grid item xs={12} sm={12}>
                                <PreambleText padding={"0"} margin={"16px 0px 16px"} fontsize={"17px"} fontcolor={"#8cd2f7"}>
                                    simply enter your address below to begin
                                </PreambleText>
                            </Grid>
                        </Grid>
                        {formPlacement(extraFocusScroll)}
                    </HomeVerticalCenter>
                </>
            ) : (
                <>
                    <HomePrimaryPanel>
                        <HomeVerticalCenter margin="auto" display="block" overflow="auto" height="auto" additionalcss="margin-top: 7vh;">
                            {contentHdr}
                            <HomeUSP />
                            {formPlacement()}
                        </HomeVerticalCenter>
                    </HomePrimaryPanel>
                </>
            )}
        </>
    );

    const callToAction = (
        <>
            <GridSpaced
                container
                margin={"0px auto"}
                additionalcss={`max-width: 1107px; width: 100%; margin-top: 35px; display: flex;  ${
                    isWrap ? "flex-direction: column; justify-content: center; align-items: center;" : "flex-direction: row; justify-content: space-between;"
                }`}
            >
                {/* {isMobile && (
                    <>
                        <GridSpaced item></GridSpaced>
                        <GridSpaced item margin={"0px auto"}></GridSpaced>
                    </>
                )} */}

                <>
                    <GridSpaced item additionalcss={"max-width: 540px;"}>
                        <CalltoActionContact>
                            <Link component={RouterLink} to="/contact">
                                <div>
                                    <img className="here-to-help-icon" src={HereToHelp} alt="Here to help icon" />
                                </div>
                                <div style={{ padding: isMobile ? "0px 17px" : "0px 25px" }}>
                                    <div>
                                        <h2>We're here to help...</h2>
                                    </div>
                                    <div>
                                        <div>
                                            <p>Unsure on why you need flood insurance?</p>
                                        </div>
                                        <div>
                                            <p>Contact us now</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <img className="arrow-right-icon" src={ArrowRight} alt="" />
                                </div>
                            </Link>
                        </CalltoActionContact>
                    </GridSpaced>
                    {/* <div style={{ width: "30px" }}></div> */}
                    <GridSpaced item additionalcss={"max-width: 540px;"}>
                        <CalltoActionMFI>
                            <a href="https://myfloodrisk.org/" target="_blank">
                                <div>
                                    <h2>Find out your level of risk...</h2>
                                </div>
                                <div style={{ padding: "0px 25px" }}>
                                    <img className="mfr-logo" src={MFRLogo} alt="My Flood Risk Logo" />
                                </div>
                                <div>
                                    <img className="arrow-right-icon" src={ArrowRight} alt="" />
                                </div>
                            </a>
                        </CalltoActionMFI>
                    </GridSpaced>
                </>
            </GridSpaced>
        </>
    );

    const canSeeProAdvert = () => {
        let retVal = true;
        if (
            StoresInstance.domain.AccountStore.IsProAccount ||
            StoresInstance.domain.AccountStore.isInRole("admin") ||
            StoresInstance.domain.AccountStore.isInRole("producer")
        ) {
            retVal = false;
        }
        if (StoresInstance.domain.AccountStore.IsRealtorAccount) {
            retVal = false;
        }

        return retVal;
    };

    const renderProAdvert = (
        <>
            {isMobile ? (
                <>
                    <DivWrapper className="proadvert" additionalcss="background-color: #3A4650; min-height: 160px;">
                        <div style={{ backgroundColor: "#3A4650" }}>
                            <SignUpPro />
                        </div>
                    </DivWrapper>
                </>
            ) : (
                <>
                    <div style={{ minHeight: "127px !important", paddingTop: "70px" }}>
                        <SignUpPro />
                    </div>
                </>
            )}
        </>
    );

    return (
        <>
            <WarningBanner top={isMobile ? 56 : 64}>
                {height =>
                    useObserver(() => {
                        return (
                            <>
                                <div style={{ marginTop: `${height}px` }}>
                                    <MetaHeader Model={metaData} />
                                    <DivWrapper
                                        className="intro"
                                        additionalcss={`${
                                            isMobile
                                                ? "background-image: linear-gradient(#1615157f, rgba(0, 0, 0, 0.50)), url(" +
                                                  MFIhomepage +
                                                  "); background-size: 300%; min-height: 70vh; background-position: -500px 0px; /*background-color: #293843;*/"
                                                : "background-image: linear-gradient(#1615157f, rgba(0, 0, 0, 0.50)), url(" +
                                                  MFIhomepage +
                                                  "); min-height: 83vh;"
                                        } display: flex;`}
                                    >
                                        {homePrimaryPanel(height)}
                                    </DivWrapper>
                                    <DivWrapper additionalcss={"background-image: url(" + Puddles + "); min-height: 100%;"}>
                                        <div style={{ backgroundColor: "linear-gradient(rgba(1, 1, 1, 0.95) 3%, transparent)" }}>
                                            <DivWrapper backgroundcolor={"rgba(27, 43, 55, 0.75)"} additionalcss={"min-height: 100%; padding-bottom: 100px;"}>
                                                {canSeeProAdvert() && renderProAdvert}
                                                <HeaderAnchor id="faq" />
                                                <QandAWHY />
                                                <QandAFAQ />
                                                {callToAction}
                                            </DivWrapper>
                                        </div>
                                    </DivWrapper>
                                    <Footer isTemp={false} />
                                </div>
                            </>
                        );
                    })
                }
            </WarningBanner>
        </>
    );
};

export const Loaders: React.FC = () => {
    return (
        <>
            <Box paddingTop={2}>
                Animated Dots
                <DotWrapper>
                    <Dot delay="0s" />
                    <Dot delay=".1s" />
                    <Dot delay=".2s" />
                </DotWrapper>
            </Box>
        </>
    );
};
