import React, { useState, useEffect } from "react";
import { useMediaQuery } from "../../Core/Utils/Media/mediaQuery";
import { useRouter, useObserver, StoresInstance } from "../Globals/Base";
import { GridSpaced, TitleWrapper, DivWrapper, DivWrapperBGFixed } from "../StylesAppSpecific/GridHelperStyling";
import { HdrImgDisc } from "../StylesAppSpecific/PageStyling";
import { Grid, Button, Typography } from "@material-ui/core";
import iconProRegister from "../../Content/icon-proRegister.svg";
import { Container } from "../StylesAppSpecific/CarrierSettingsStyling";
import PXFuel from "../../Content/quoteBG.jpg";
import { Footer } from "../Components/Footer/Footer";
import { CarrierSetting } from "../Components/Pro/CarrierSetting";
import { CarrierApiSetting } from "../Components/Pro/CarrierApiSetting";
import { AONApiSetting } from "../Components/Pro/AONApiSetting";
import { CarrierSettingsViewModel } from "../ViewModels/CarrierSettingsViewModel";
import { isNullOrEmpty } from "Core/Utils/Utils";
import { AgencyAPIValidationLoading } from "Custom/Components/IsLoading";
import ProBackground from "Content/ProBackground.png";

export const CarrierSettingsPage: React.FC = () => {
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const isMobile = !!useMediaQuery("(max-width: 767px)");
    const { history } = useRouter();
    const [viewModel] = useState(() => StoresInstance.domain.CarrierSettingsViewModel || new CarrierSettingsViewModel());

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        //isValid will check all fields to make sure they are in a valid state.
        if (viewModel.checkModelValidity()) {
            viewModel.setPayload();
            StoresInstance.domain.CarrierSettingsViewModel = viewModel;
            if (
                Object.values(viewModel.CarrierViewModels).find(
                    cvm => isNullOrEmpty(cvm!.getValue("apiAgentLogin")) && isNullOrEmpty(cvm!.getValue("apiAgentPassword")),
                )
            ) {
                history.push(`/nfipartner`);
            } else {
                setIsLoading(true);
                const result = await viewModel.upsert();
                setIsLoading(false);
                if (result.wasSuccessful) {
                    history.push(`/agent`);
                } else if (result.errors && result.errors[0] && result.errors[0].message) {
                    setErrorMessage(result.errors[0].message);
                } else {
                    setErrorMessage("An unknown error occured.");
                }
            }
        }
    };

    const contentHdr = (
        <>
            <GridSpaced container margin={"0px auto"} additionalcss={"max-width: 688px; width: 100%;"}>
                <GridSpaced item xs={12} sm={12} additionalcss={"text-align: center;"}>
                    <HdrImgDisc src={iconProRegister} alt={""} />
                </GridSpaced>
            </GridSpaced>

            <GridSpaced container margin={"0px auto"} additionalcss={"width: 100%; max-width: 700px;"}>
                <Grid item xs={12} sm={12} justify={"center"}>
                    <TitleWrapper padding={"0"} margin={"0"}>
                        {/* <h1>Select your carriers and add credentials</h1> */}
                        <h1>Select your existing carrier contracts and store your login credentials</h1>
                    </TitleWrapper>
                </Grid>
            </GridSpaced>

            <GridSpaced container margin={"0px auto"} additionalcss={"width: 100%; max-width: 1125px;"}>
                <GridSpaced item xs={12}>
                    <h2>
                        The following carriers will allow your My Flood Insurance quotes to be generated in their systems under your existing agency codes, so
                        you can easily retrieve them. To enable this feature, please enter your login credentials for those carriers with whom you have a direct
                        contract.
                    </h2>
                    <h2>
                        If you do not have a direct contract with a carrier, or if you wish to have National Flood Insurance, LLC write and service their
                        policies for you, please leave those carrier login fields blank.
                    </h2>
                </GridSpaced>
            </GridSpaced>
        </>
    );

    return useObserver(() => (
        <>
            <DivWrapperBGFixed
                additionalcss={
                    "background: linear-gradient(to top, rgb(20, 32, 41) 10%, rgb(0, 0, 0, 0.0)), url(" + ProBackground + ") center center / cover no-repeat;"
                }
            >
                {
                    /* isLoading ? (
					<AgencyAPIValidationLoading />
				) : ( */
                    <div style={{ margin: "0 20px" }}>
                        <DivWrapper
                            margin={"0px auto"}
                            additionalcss={isMobile ? "max-width: 1124px; padding-bottom: 80px;" : "max-width: 1124px; padding-bottom: 100px;"}
                        >
                            <Container>
                                {contentHdr}
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <CarrierApiSetting
                                        viewModel={viewModel.CarrierViewModels.wrightFloodNFIP!}
                                        customText="Please add your API key below to use your account with this carrier. What is an API key? This is a long code that allows you to connect with the carrier’s service. You can request your Wright API key on the Agent Maintenance page of the Wright Flood agent portal. Wright Flood agents are not permitted to partner with NFI on Wright Flood policies."
                                    />
                                    <CarrierSetting viewModel={viewModel.CarrierViewModels.sterling!} />
                                    <CarrierApiSetting viewModel={viewModel.CarrierViewModels.neptune!} /> 
                                    <CarrierApiSetting viewModel={viewModel.CarrierViewModels.floodRiskSolutions!} />
                                    <CarrierApiSetting viewModel={viewModel.CarrierViewModels.wrightPrivateFlood!} />
                                    <div className="divider" />
                                    {errorMessage && <p style={{ color: "red", marginLeft: "40px", marginTop: "20px" }}>{errorMessage}</p>}
                                    <div className="btns">
                                        <Button className="save-btn" variant="contained" type="submit" disabled={isLoading}>
                                            {isLoading ? "Loading..." : "Continue"}
                                        </Button>
                                        <Button className="cancel-btn" variant="contained" onClick={() => history.goBack()}>
                                            Cancel
                                        </Button>
                                    </div>
                                </form>
                            </Container>
                        </DivWrapper>
                    </div>
                    /* ) */
                }
            </DivWrapperBGFixed>
            {/* isLoading ||  */ <Footer isTemp={false} />}
        </>
    ));
};
