import * as MobX from "mobx";
import { ModelBase } from "../../../Core/Models/ModelBase";
import { WarningPropertyModel } from "./WarningProperty";

export class WarningMetaDataModel extends ModelBase<WarningMetaDataModel, WarningMetaDataModelDTO> {
    @MobX.observable
    public warningProperty: WarningMetaDataModel[] = [];

    public fromDto = (model: WarningMetaDataModelDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                this[key] = model[key];
            }
        }
    };
    public toDto = (model: WarningMetaDataModel) => {};

    public resetWarningMetaData = () => {
        this.warningProperty = [];
    };
}

export interface WarningMetaDataModelDTO {
    warningProperty: [];
}
