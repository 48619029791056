// Libraries
import React, { useState } from "react";

// App
import { ContactModel } from "../../Models";
import { ContactViewModel } from "../../ViewModels/ContactViewModel";

// Components
import { EditableInput } from "../../../Core/Components/EditableInput";

// Styling & images
import { ContactFormStyle } from "../../StylesAppSpecific/FormStyling";
import { DialogueWrapper } from "../../StylesAppSpecific/GridHelperStyling";
import { Button } from "@material-ui/core";
import iconTick from "Content/icon-check.svg";

/* export interface IContactProps  */

export const ContactForm: React.FC = () => {
    const [viewModel] = useState(() => new ContactViewModel());

    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    const doSubmit = (e: any) => {
        e.preventDefault();

        if (viewModel.isModelValid()) {
            viewModel.UpsertContactAsync().then(() => {});
            setIsSubmitted(true);
        }
    };

    return (
        <>
            <DialogueWrapper>
                <ContactFormStyle onSubmit={doSubmit} id="contactForm">
                    <span>I'd like to know more</span>
                    <p>enter your email address to be kept up to date about myfloodinsurance</p>
                    <div className="emailaddress">
                        <EditableInput<ContactModel> viewModel={viewModel} type="text" fieldName="email" fullwidth />
                    </div>
                    {!isSubmitted && (
                        <>
                            <Button onClick={doSubmit}>Add me</Button>
                        </>
                    )}
                    {isSubmitted && (
                        <>
                            <Button onClick={doSubmit} className="success">
                                <img src={iconTick} alt="Submitted Successfully" />
                            </Button>
                        </>
                    )}
                </ContactFormStyle>
            </DialogueWrapper>
        </>
    );
};
