import React from "react";
import { Button } from "@material-ui/core";
import { ApiResult } from "../../Core/Models";

export const User: React.FC = () => {
    return (
        <div className="App">
            <header className="App-header">
                <h1>User</h1>
                <Button onClick={() => {}}>Test</Button>
            </header>
        </div>
    );
};
