import React, { useContext } from "react";
import { Header } from "../../Core/Components/General";
import { useMediaQuery } from "../../Core/Utils/Media/mediaQuery";
import { LogoutModal } from "../Components/LogoutModal";
import { Container } from "@material-ui/core";

//EN: use empty generic type to get default react props
export const Layout: React.FC<{}> = props => {
    const isMobile = !!useMediaQuery("(max-width: 767px)");
    let isWidget = false;

    if (document.location.pathname.toLowerCase().indexOf("/widget") > -1) {
        isWidget = true;
        // needed for header when redirected - header won't appear from step 2 otherwise
        if (document.location.pathname.toLowerCase().indexOf("/widget/redirect") > -1) {
            isWidget = false;
        }
    }

    if (isWidget) {
        let el: HTMLElement = document.getElementById("purechat-container") as HTMLElement;
        if (el && el.parentNode) {
            el.parentNode!.removeChild(el);
        }
    }

    return (
        <React.Fragment>
            {!isWidget ? (
                <>
                    <Header />
                    <div style={{ display: "block", height: isMobile ? "56px" : "64px", width: "100%" }} />
                </>
            ) : (
                <></>
            )}
            <Container disableGutters maxWidth={false}>
                {props.children!}
            </Container>
            <LogoutModal />
        </React.Fragment>
    );
};
