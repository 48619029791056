import { ContactUsModel } from "../Models/ContactUsModel";
import { FieldType } from "Core/Utils/Utils";
import { Server } from "../Globals/AppUrls";
import { ViewModelBase } from "../../Core/ViewModels/ViewModelBase";
import { runInAction, action } from "mobx";

export class ContactUsViewModel extends ViewModelBase<ContactUsModel> {
    public ResponseModel = new ContactUsModel();
    public captchaToken = "";

    constructor() {
        super(new ContactUsModel());
        this.setDecorators(ContactUsModel);
    }

    public UpsertContactUsAsync = async (): Promise<boolean> => {
        let apiResult = await this.Post<ContactUsModel>(`${Server.Api.Contact.UpsertContactUs}?token=${this.captchaToken}`, this.getModel);
        runInAction(() => {
            if (apiResult.wasSuccessful) {
                this.ResponseModel = new ContactUsModel();
                this.ResponseModel.fromDto(apiResult.payload);
                this.setValue("contactType", "");
                this.setValue("firstName", "");
                this.setValue("lastName", "");
                this.setValue("email", "");
                this.setValue("tel", "");
                this.setValue("message", "");
                this.setValue("passCaptcha", false);
            }
        });

        return apiResult.wasSuccessful;
    };

    public isFieldValid(fieldName: keyof FieldType<ContactUsModel>, value: any, showErrors: boolean = true): boolean {
        let { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        if (showErrors) {
            this.setError(fieldName, errorMessage);
            this.setValid(fieldName, isValid);
        }

        return isValid;
    }

    @action
    public setCaptchaToken(token: string) {
        this.captchaToken = token;
    }

    public afterUpdate: any;
    public beforeUpdate: undefined;
}
