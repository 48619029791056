import * as MobX from "mobx";
import { ModelBase } from "../../../Core/Models/ModelBase";

import { MetaDataObjectModel } from "./MetaDataObject";
import { AddressModel } from "./Address";
import { ParcelModel } from "./Parcel";
import { StructureModel } from "./Structure";
import { TaxesModel } from "./Taxes";
import { AssessmentsModel } from "./Assessments";
import { MarketAssessmentsModel } from "./MarketAssessments";
import { ValuationModel } from "./Valuation";
import { OwnerModel } from "./Owner";
import { DeedsModel } from "./Deeds";

export class DataModel extends ModelBase<DataModel, DataModelDTO> {
    @MobX.observable
    public metaData: MetaDataObjectModel = new MetaDataObjectModel();

    @MobX.observable
    public address: AddressModel = new AddressModel();

    @MobX.observable
    public parcel: ParcelModel = new ParcelModel();

    @MobX.observable
    public structure: StructureModel = new StructureModel();

    @MobX.observable
    public taxes: TaxesModel = new TaxesModel();

    @MobX.observable
    public assessments: AssessmentsModel = new AssessmentsModel();

    @MobX.observable
    public marketAssessments: MarketAssessmentsModel = new MarketAssessmentsModel();

    @MobX.observable
    public valuation: ValuationModel = new ValuationModel();

    @MobX.observable
    public owner: OwnerModel = new OwnerModel();

    @MobX.observable
    public deeds: DeedsModel = new DeedsModel();

    public fromDto = (model: DataModelDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                this[key] = model[key];
            }
        }
    };
    public toDto = (model: DataModel) => {};
}

export interface DataModelDTO {
    metaData: MetaDataObjectModel;
    address: AddressModel;
    parcel: ParcelModel;
    structure: StructureModel;
    taxes: TaxesModel;
    assessments: AssessmentsModel;
    marketAssessments: MarketAssessmentsModel;
    valuation: ValuationModel;
    owner: OwnerModel;
    deeds: DeedsModel;
}
