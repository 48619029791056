// Libraries
import React, { useState, useContext, useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { useRouter, useMediaQuery } from "../../../Core/Utils";

// App

// Components
import { Footer } from "../../Components/Footer";
import { Button, Modal, Typography } from "@material-ui/core";

// Styling & images
import { Grid, Box } from "@material-ui/core";
import iconProRegister from "../../../Content/icon-proRegister.svg";
import { DivWrapper, GridSpaced, DialogueWrapper, DivWrapperBGFixed } from "../../StylesAppSpecific/GridHelperStyling";
import { EditableInput } from "Core/Components/EditableInput";
import styled from "styled-components";
import { HdrImgDisc } from "Custom/StylesAppSpecific/PageStyling";
import { isNullOrUndefined } from "util";
import { SEOHeader, PreviousPropertyQuoteModelDTO, PropertyQuoteModel } from "Custom/Models";
import { MetaHeader } from "Custom/Components/SEOComponents";
import QuoteBackground from "Content/quoteBG.jpg";
import { AddressSelectorGoogle, AddressSelectorMyQuotes } from "Custom/Components/AddressSelector";
import { SearchCardStyle, UpgradeToPro } from "Custom/StylesAppSpecific/DashboardStyling";
import { StoresContext, Stores } from "../../Stores";
import { StoresInstance } from "../../Stores/Stores";
import moment from "moment";
import { easternStandardTime } from "Custom/Utils/Time";
import Warning from "Content/warning.svg";
import { WarningModal, OkButton, CancelButton } from "Custom/StylesAppSpecific/ModalStyling";
import ClearIcon from "@material-ui/icons/Clear";
import BinIcon from "Content/bin.svg";
import { useDashboardStyles } from "Custom/StylesAppSpecific/Agent/AgentDashboard";

export const AgentMyQuotes: React.FunctionComponent = () => {
    const store = useContext<Stores>(StoresContext);
    const { history } = useRouter();
    const domainStores = StoresInstance.domain;
    const dashboardViewModel = domainStores.DashboardViewModel;
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [quoteId, setQuoteId] = React.useState<string>("");
    const classes = useDashboardStyles();

    let isMobile: boolean;
    isMobile = useMediaQuery("screen and (max-width: 767px)");

    useEffect(() => {
        let _ = dashboardViewModel.load();
    }, []);

    const doSubmit = (propertyQuoteId: string) => {
        dashboardViewModel.loadPropertyQuote(propertyQuoteId).then(() => history.push("/Step2"));
    };

    const handleEditDetails = (e: React.MouseEvent) => {
        e.preventDefault();
        history.push(`/editdetails`);
    };

    let metaData: SEOHeader = {
        title: "My Flood Insurance - Instant Flood Insurance Quotes",
        description: "My Flood Insurance - a revolutionary new way of buying flood insurance",
    };

    let createdDateFormatted = (createdDateString: string) => {
        if (isNullOrUndefined(createdDateString) || createdDateString === "") {
            return "";
        }

        return easternStandardTime(createdDateString).format("MM/DD/YY");
    };

    const formPlacement = (
        <>
            {isMobile && (
                <>
                    <GridSpaced container margin={"0px auto"} additionalcss={"width: 100%;"}>
                        <GridSpaced item margin={"15px auto 45px"}>
                            <DialogueWrapper additionalcss={"border-radius: 20px;"}>
                                <AddressSelectorGoogle />
                            </DialogueWrapper>
                        </GridSpaced>
                    </GridSpaced>
                </>
            )}

            {!isMobile && (
                <>
                    <DialogueWrapper additionalcss={"max-width: 1015px; height: 186px; margin: 0;"}>
                        <AddressSelectorMyQuotes />
                    </DialogueWrapper>
                </>
            )}
        </>
    );

    const doDelete = () => {
        dashboardViewModel.deletePropertyQuote(quoteId).then(() => {
            setQuoteId("");
        });
        setOpenModal(false);
    };

    const openDeleteModal = (propertyQuoteId: string) => {
        setQuoteId(propertyQuoteId);
        setOpenModal(true);
    };

    const closeDeleteModal = () => {
        setOpenModal(false);
        setQuoteId("");
    };

    const deleteModal = (
        <>
            <div className="header">Warning</div>
            <div className="body">
                <img src={Warning} alt="Warning - Are you sure you want to delete this?" />
                <p>Are you sure you want to delete this?</p>
            </div>
            <div className="footer">
                <OkButton
                    onClick={() => {
                        doDelete();
                    }}
                >
                    OK
                </OkButton>
                <CancelButton
                    onClick={() => {
                        closeDeleteModal();
                    }}
                >
                    Cancel
                </CancelButton>
            </div>
        </>
    );

    const renderPropertyQuoteCards = (propertyQuotes: PreviousPropertyQuoteModelDTO[]): JSX.Element[] => {
        const propertyQuotesElements = [];

        for (const propertyQuote of propertyQuotes) {
            propertyQuotesElements.push(
                <SearchListItem item margin={"0px auto"}>
                    <div style={{ width: "70%" }}>
                        {domainStores.AccountStore.IsRealtorAccount && <>Policy Holder: {propertyQuote.firstName + " " + propertyQuote.lastName}</>}
                        <h2>{propertyQuote.addressLine1}</h2>
                    </div>
                    <div style={{ width: "20%", minWidth: "155px" }}>
                        <p>Last quote date</p>
                        <h2>{createdDateFormatted(propertyQuote.createdDate)}</h2>
                    </div>
                    <div style={{ width: "10%", minWidth: "104px", textAlign: "center" }}>
                        <Button
                            onClick={() => {
                                doSubmit(propertyQuote.propertyQuoteId);
                            }}
                        >
                            GO
                        </Button>
                    </div>
                    <div style={{ width: "10%", minWidth: "72px", textAlign: "center" }}>
                        <DeleteButton
                            onClick={() => {
                                openDeleteModal(propertyQuote.propertyQuoteId);
                            }}
                        >
                            <img src={BinIcon} alt=""></img>
                        </DeleteButton>
                    </div>
                </SearchListItem>,
            );
        }

        return propertyQuotesElements;
    };

    const renderPropertyQuoteCardsMobile = (propertyQuotes: PreviousPropertyQuoteModelDTO[]): JSX.Element[] => {
        const propertyQuotesElements = [];

        for (const propertyQuote of propertyQuotes) {
            propertyQuotesElements.push(
                <SearchListItem item margin={"0px auto"}>
                    <div>
                        {domainStores.AccountStore.IsRealtorAccount && <>Policy Holder: {propertyQuote.firstName + " " + propertyQuote.lastName}</>}
                        <h2>{propertyQuote.addressLine1 + ", " + propertyQuote.city + ", " + propertyQuote.state + ", " + propertyQuote.zipcode}</h2>
                    </div>
                    <div
                        style={{
                            flexDirection: "row",
                            fontSize: "10px",
                            margin: "0px",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                        }}
                    >
                        <p
                            style={{
                                height: "10px",
                                lineHeight: "10px",
                                marginRight: "5px",
                            }}
                        >
                            Last quote date:
                        </p>
                        <h2
                            style={{
                                fontSize: "10px",
                                height: "10px",
                                lineHeight: "10px",
                                minHeight: "10px",
                            }}
                        >
                            {createdDateFormatted(propertyQuote.createdDate)}
                        </h2>
                    </div>
                    <div style={{ alignItems: "center", padding: "0px", marginBottom: "0px" }}>
                        <Button
                            onClick={() => {
                                doSubmit(propertyQuote.propertyQuoteId);
                            }}
                        >
                            GO
                        </Button>
                    </div>
                    <div>
                        <DeleteButton
                            onClick={() => {
                                openDeleteModal(propertyQuote.propertyQuoteId);
                            }}
                        >
                            <img src={BinIcon} alt=""></img>
                        </DeleteButton>
                    </div>
                </SearchListItem>,
            );
        }

        return propertyQuotesElements;
    };

    return useObserver(() => (
        <>
            {!isMobile && (
                <>
                    <DivWrapper additionalcss={"padding-bottom: 40px;"}>
                        <DashboardContentContainer>
                            <DashboardHeaderContainer>
                                <h1>
                                    <span>Hi {dashboardViewModel.FirstName},</span>
                                </h1>
                            </DashboardHeaderContainer>
                            <GridSpaced container>
                                <GridSpaced item xs={12}>
                                    {formPlacement}
                                </GridSpaced>
                            </GridSpaced>
                            <Typography variant="h1" component="h2">
                                Here are your previous quotes...
                            </Typography>
                            <SearchListContainer container margin={"0px auto"}>
                                {renderPropertyQuoteCards(
                                    dashboardViewModel.PropertyQuotes.sort((a, b) =>
                                        dashboardViewModel.dateStrAsDate(a.createdDate) < dashboardViewModel.dateStrAsDate(b.createdDate)
                                            ? 1
                                            : dashboardViewModel.dateStrAsDate(b.createdDate) < dashboardViewModel.dateStrAsDate(a.createdDate)
                                            ? -1
                                            : 0,
                                    ),
                                )}
                            </SearchListContainer>
                        </DashboardContentContainer>
                    </DivWrapper>
                </>
            )}
            {isMobile && (
                <>
                    <DivWrapper>
                        <DashboardContentContainer>
                            <GridSpaced container>
                                <GridSpaced item xs={12}>
                                    {formPlacement}
                                </GridSpaced>
                            </GridSpaced>
                            <DashboardHeaderContainer>
                                <h1>
                                    <span>Hi {dashboardViewModel.FirstName},</span> Here are your previous quotes...
                                </h1>
                            </DashboardHeaderContainer>
                            <SearchListContainer container margin={"0px auto"}>
                                {renderPropertyQuoteCardsMobile(
                                    dashboardViewModel.PropertyQuotes.sort((a, b) =>
                                        dashboardViewModel.dateStrAsDate(a.createdDate) < dashboardViewModel.dateStrAsDate(b.createdDate)
                                            ? 1
                                            : dashboardViewModel.dateStrAsDate(b.createdDate) < dashboardViewModel.dateStrAsDate(a.createdDate)
                                            ? -1
                                            : 0,
                                    ),
                                )}
                            </SearchListContainer>
                        </DashboardContentContainer>
                    </DivWrapper>
                </>
            )}

            <WarningModal open={openModal} onClose={closeDeleteModal}>
                {deleteModal}
            </WarningModal>
        </>
    ));
};

const DeleteButton: any = styled(Button)`
    background: none !important;
    box-shadow: unset !important;
    height: 32px !important;
    .MuiButton-label {
        height: 64px;
        width: 64px;
        position: relative;
        text-align: center;

        @media (max-width: 767px) {
            width: 32px;
            height: 32px;
        }

        svg {
            position: absolute;
            top: 10px;
        }
        div {
            position: absolute;
            bottom: 10px;
        }
    }
`;

const SearchListItem = styled(SearchCardStyle)`
    width: 100%;

    h2 {
        min-height: 24px;

        @media screen and (max-width: 767px) {
            font-size: 14px;
        }
    }

    > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-right: 1px solid #129bdb;
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 10px 20px;

        @media screen and (max-width: 767px) {
            border-right: none;
        }

        :first-child {
            @media screen and (max-width: 767px) {
                padding-top: 0px;
                margin-bottom: 0px;
                padding-bottom: 5px;
                margin-top: 5px;
            }
        }

        :last-child {
            width: 10%;
            min-width: 104px;
            text-align: center;
            border-right: none;
            padding: 0px 20px;
            align-items: center;
            width: 100%;
        }
    }

    @media screen and (max-width: 767px) {
        flex-direction: column;
        text-align: center;

        button,
        button:active {
            width: 132px;
            height: 48px;
            font-size: 14px;
        }
    }
`;

const SearchListContainer = styled(GridSpaced)`
    margin-bottom: 40px;

    @media screen and (max-width: 767px) {
        margin-bottom: 20px;
    }

    > div:nth-of-type(even) {
        background-color: #e7f6fe;
    }

    > div:nth-of-type(odd) {
        background-color: #ffffff;
    }
`;

export const DashboardContentContainer = styled(Box)`
    max-width: 1124px;
    width: 100%;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;

    @media screen and (max-width: 767px) {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 30px;
    }

    > h2 {
        font-size: 32px;
        line-height: 32px;
        padding: 32px 0px;
        text-align: left;

        @media screen and (max-width: 767px) {
            font-size: 24px;
            padding: 60px 0px 18px 0px;
            text-align: center;
        }
    }
`;

export const DashboardHeaderContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 35px;
    padding-bottom: 35px;

    @media screen and (max-width: 767px) {
        text-align: center;
        padding-top: 18px;
        padding-bottom: 18px;
    }

    h1,
    p {
        margin: 0px;

        @media screen and (max-width: 767px) {
            text-align: center;
            width: 100%;
        }
    }

    .edit-account-link {
        color: #f2af4c;

        @media screen and (max-width: 767px) {
            text-align: center;
            width: 100%;
            margin-top: 12px;
            font-size: 12px;
        }
    }

    h1 {
        font-size: 32px;
        line-height: 32px;

        > span {
            color: #8cd2f7;
        }

        @media screen and (max-width: 767px) {
            font-size: 24px;
        }
    }
`;
