import * as MobX from "mobx";
import { ModelBase } from "../../../Core/Models/ModelBase";

export class MarketAssessmentsModel extends ModelBase<MarketAssessmentsModel, MarketAssessmentsModelDTO> {
    @MobX.observable
    public year: number = 0;

    @MobX.observable
    public land_value: number = 0;

    @MobX.observable
    public improvement_value: number = 0;

    @MobX.observable
    public total_value: number = 0;

    public fromDto = (model: MarketAssessmentsModelDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                this[key] = model[key];
            }
        }
    };
    public toDto = (model: MarketAssessmentsModel) => {};

    public resetMarketAssessments = () => {
        this.year = 0;
        this.land_value = 0;
        this.improvement_value = 0;
        this.total_value = 0;
    };
}

export interface MarketAssessmentsModelDTO {
    year: number;
    land_value: number;
    improvement_value: number;
    total_value: number;
}
