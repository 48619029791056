import * as Mui from "@material-ui/core";

import { FieldType } from "Core/Utils/Utils";
import { IViewModel } from "Core/ViewModels";
import { ObjPathProxy } from "ts-object-path";
import React, { useState, useEffect } from "react";
import { SliderProps, Mark } from "@material-ui/core";
import { Validation } from "Core/Components/Validation";
import styled from "styled-components";
import { useObserver } from "mobx-react-lite";

interface IProps<T> {
    viewModel: IViewModel<T>;
    fieldName: keyof FieldType<T>;
    marks?: number[];
    min?: number;
    max?: number;
    step?: number;
    ThumbComponent?: SliderProps["ThumbComponent"];
    thumbbackgroundimage?: string;
}

export function Slider<T>(props: IProps<T>) {
    const { viewModel, fieldName, ThumbComponent, marks: _marks, min: _min, max: _max, step: _step, ...rest } = props;
    const value = viewModel.getValue<number>(fieldName);
    const [currentValue, setCurrentValue] = useState<number | undefined>(
        _marks === undefined || _marks.length <= 0 ? value : _marks.findIndex(m => m === value),
    );

    const [min, setMin] = useState<number | undefined>();
    const [max, setMax] = useState<number | undefined>();
    const [step, setStep] = useState<number | null | undefined>();
    const [marks, setMarks] = useState<Mark[] | undefined>();

    useEffect(() => {
        if (_marks === undefined || _marks.length <= 0) {
            setMin(_min);
            setMax(_max);
            setStep(_step);
            setMarks(undefined);

            setCurrentValue(value);
        } else {
            setMarks(_marks.map((_, index) => ({ value: index })));
            setMin(0);
            setMax(_marks.length - 1);
            setStep(null);

            setCurrentValue(_marks.findIndex(m => m === value));
        }
    }, [_min, _max, _step, _marks]);

    return useObserver(() => (
        <SliderStyles thumbbackgroundimage={props.thumbbackgroundimage}>
            <Mui.Slider
                {...rest}
                value={currentValue}
                onChange={(_, value) => {
                    value = value as number;
                    setCurrentValue(value);
                    if (_marks !== undefined) {
                        value = _marks[value];
                    }
                    viewModel.setValue(fieldName, value!);
                    viewModel.setDirty(fieldName, true);
                }}
                ThumbComponent={ThumbComponent}
                min={min}
                max={max}
                marks={marks}
                step={step}
            />
        </SliderStyles>
    ));
}

export interface IComponentProps {
    thumbbackgroundimage?: string;
    additionalcss?: string;
}

const SliderStyles = styled.div<IComponentProps>`
    .MuiSlider-thumb {
        width: 68px;
        height: 68px;
        margin-top: -30px;
        margin-left: -33px;
        background-color: white;

        /* Used background-image instead of using the ThumbComponent as it breaks dragging the pin on touchscreen */
        background-image: ${props => (props.thumbbackgroundimage !== undefined ? `url(${props.thumbbackgroundimage})` : "none")};
        background-size: ${props => (props.thumbbackgroundimage !== undefined ? "cover" : "unset")};
        background-repeat: ${props => (props.thumbbackgroundimage !== undefined ? "no-repeat" : "unset")};
    }

    .MuiSlider-track {
        opacity: 0;
    }

    .MuiSlider-rail {
        color: #fff;
        opacity: 0.75;
        height: 8px;
        border-radius: 50px;
    }

    .MuiSlider-root {
        padding: 28px 0;
    }

    .MuiSlider-mark {
        display: none;
    }

    @media (max-width: 767px) {
        .MuiSlider-thumb {
            width: 57px;
            height: 57px;
            margin-top: -27px;
            margin-left: -26px;
        }

        .MuiSlider-rail {
            height: 4px;
        }
    }
`;
