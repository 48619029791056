// Libraries
import React from "react";
import { Box, Typography, Paper } from "@material-ui/core";

// Custom
import { useOverallStatisticsStyles } from "../../StylesAppSpecific/Agent/AgentDashboard";
import { getInsurerLogo } from "../../Utils/InsurerLogo";

interface IProps {
    numberOfQuotesProduced: string;
    numberOfQuotesSelected: string;
}

export const OverallStatisticsCard: React.FC<IProps> = (props: IProps) => {
    const classes = useOverallStatisticsStyles();

    return (
        <Paper className={`${classes.paper} ${classes.quoteCounterContainer}`}>
            <Box className={classes.imageContainer}>
                <img alt={"MFI Pro User"} src={getInsurerLogo("MFI Pro User")} />
            </Box>
            <Box className={classes.statisticsContainer}>
                <Box className={classes.statisticsSubContainer}>
                    <Typography className={classes.title} component="div">
                        Total quotes produced:
                    </Typography>
                    <Typography className={classes.text} component="div">
                        {props.numberOfQuotesProduced}
                    </Typography>
                </Box>
                <Box className={classes.statisticsSubContainer}>
                    <Typography className={classes.title} component="div">
                        Total quotes selected:
                    </Typography>
                    <Typography className={classes.text} component="div">
                        {props.numberOfQuotesSelected}
                    </Typography>
                </Box>
            </Box>
        </Paper>
    );
};
