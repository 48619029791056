// Libraries
import { useObserver } from "mobx-react-lite";
import MaterialTable from "material-table";
import React, { useEffect } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";

// Core
import { useRouter } from "../../../Core/Utils";

// Custom
import { StoresInstance } from "../../Stores";
import { useListStyles } from "./ListStyling";
import { easternStandardTime, dateTimeFormat } from "../../Utils/Time";
import styled from "styled-components";
import { ButtonLink } from "../../StylesAppSpecific/AdminStyling";

import iconDownloadPDF from "Content/icon-downloadPDF.svg";

const TableStyles = styled.div`
    .MuiToolbar-root {
        background-color: #3a4650;
        color: white;
        padding: 37px 30px 30px;

        .table-header {
            display: flex;
            align-items: baseline;

            .new-user-btn {
                padding-left: 10px;
            }
        }

        input {
            color: white;
        }

        .MuiIconButton-root,
        .MuiIcon-root {
            color: white;

            &.Mui-disabled {
                opacity: 0.5;
            }
        }

        .MuiInput-underline:before {
            border-color: rgba(255, 225, 225, 0.42);
        }

        .MuiInput-underline:after {
            border-color: #fff;
        }
    }

    .MuiTableCell-footer {
        border: none;

        path {
            color: white;
        }

        .MuiToolbar-root {
            padding: 14px 30px;
        }

        .MuiTypography-caption {
            color: white;
        }
    }

    .height-100p {
        height: 100%;
    }

    .float-right {
        float: right;
    }
`;

export const Widgets: React.FC = () => {
    const classes = useListStyles();
    const { history } = useRouter();
    const domainStores = StoresInstance.domain;
    const widgetViewModel = domainStores.WidgetViewModel;

    useEffect(() => {
        domainStores.WidgetViewModel.load();
    }, []);

    const handleRowClick = (rowData: any) => {
        history.push("/adminwidgetreport/" + rowData.id);
    };

    const handleEditRowClick = (rowData: any) => {
        history.push("/editwidget/" + rowData.id);
    };

    const handleNewClick = () => {
        history.push(`/editwidget/0`);
    };

    return useObserver(() => (
        <>
            <TableStyles>
                <MaterialTable
                    columns={[
                        {
                            title: "Referrer",
                            field: "referrerUri",
                        },
                        {
                            title: "Referrals",
                            field: "referrals",
                            render: rowData => {
                                return (
                                    <>
                                        <span onClick={e => handleRowClick(rowData)} style={{ cursor: "pointer" }}>
                                            {rowData.referrals}
                                        </span>
                                    </>
                                );
                            },
                        },
                        {
                            title: "Created on (ET)",
                            field: "createdDate",
                            render: rowData => easternStandardTime(rowData.createdDate).format(dateTimeFormat),
                            customFilterAndSearch: (value, rowData) => {
                                return (
                                    easternStandardTime(rowData.createdDate)
                                        .format(dateTimeFormat)
                                        .indexOf(value) != -1
                                );
                            },
                        },
                        {
                            title: "Published",
                            field: "isEnabled",
                            render: rowData => (rowData.isEnabled ? "No" : "Yes"),
                            customFilterAndSearch: (value, rowData) => {
                                return (rowData.isEnabled ? "No" : "Yes").indexOf(value) != -1;
                            },
                        },
                        {
                            title: "",
                            field: "",
                            render: rowData => {
                                return (
                                    <>
                                        <span onClick={e => handleEditRowClick(rowData)}>Edit/Get code</span>
                                    </>
                                );
                            },
                        },
                    ]}
                    options={{
                        pageSize: 30,
                        pageSizeOptions: [10, 30, 60],
                        emptyRowsWhenPaging: false,
                    }}
                    data={widgetViewModel.Widgets}
                    /* onRowClick={handleRowClick} */
                    title={
                        <div className="table-header">
                            <Typography variant="h1" component="h3">
                                Widgets
                            </Typography>
                            <ButtonLink
                                className="new-user-btn"
                                onClick={handleNewClick}
                                color="secondary"
                                disableElevation
                                disableFocusRipple
                                disableRipple
                                disableTouchRipple
                            >
                                Add new widget
                            </ButtonLink>
                        </div>
                    }
                />
            </TableStyles>
            <div style={{ display: "none" }}>{widgetViewModel.Widgets.length}</div>
        </>
    ));
};
