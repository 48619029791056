import { Grid, ExpansionPanel, Box, Button } from "@material-ui/core";
import { GridSpaced } from "../StylesAppSpecific/GridHelperStyling";
import styled from "styled-components";

export interface IComponentProps {
    padding?: string;
    margin?: string;
    fontcolor?: string;
    backgroundcolor?: string;
    additionalcss?: string;
}

export const SearchCardStyle: any = styled(Box)<IComponentProps>`
    display: inline-flex;
    margin: ${props => (props.margin !== undefined ? props.margin : "unset")};
    background: #ffffff 0% 0% no-repeat padding-box;
    border: none;
    border-bottom: 1px solid black;
    width: 100%;
    height: auto;
    color: #191919;

    :last-child {
        border-bottom: none;
    }

    > div {
        width: 100%;
        padding: 16px;
    }

    p,
    h2 {
        margin: 0px;
    }

    h2 {
        font-size: 24px;
        line-height: 24px;
    }

    button,
    button:hover {
        border-radius: 101px;
        width: 64px;
        height: 64px;
        background: #129bdb 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        margin: 0px;
        padding: 0px;
        color: #ffffff;
        font-size: 12px;
        font-weight: bold;
    }

    /* h2.premium {
        font-size: 18px;
        letter-spacing: -0.45px;
    }
    h2.premiumValue {
        font-size: 32px;
        letter-spacing: -0.8px;
    }

    h2.deductable {
        font-size: 14px;
        letter-spacing: -0.35px;
    }
    h2.deductableValue {
        font-size: 24px;
        letter-spacing: -0.6px;
    } */

    /*position: relative;*/
    /* button,
    button:hover {
        position: absolute;
        left: 38%;
        bottom: -48px;
    } */

    @media (max-width: 767px) {
    }
`;

export const UpgradeToPro: any = styled(Button)`
    flex-direction: row;
    margin: 0px;
    background: #005fab 0% 0% no-repeat padding-box;
    border-radius: 10px;
    max-width: 352px;
    width: 100%;
    height: 186px;
    color: #ffffff;
    font: Bold 18px Montserrat;
    letter-spacing: -0.45px;
    p {
        margin: 10px 0px;
    }
    span.prompt {
        color: #8cd2f7;
        font: Bold 14px Montserrat;
        letter-spacing: -0.35px;
        display: block;
        width: 100%;
    }
    img {
        width: 87px;
        height: auto;
    }
    :hover {
        background: #005fab 0% 0% no-repeat padding-box;
    }
    .MuiButton-label {
        flex-direction: column;
    }
`;
