import * as MobX from "mobx";

import { FieldType, sortByString } from "../../Core/Utils/Utils";
import { Role, RoleDTO } from "../Models/Role";
import { action, computed } from "mobx";

import { ApiResult } from "Core/Models/ApiResult";
import { RoleStore } from "../Stores/Domain/Admin/RoleStore";
import { StoresInstance } from "Custom/Stores/Stores";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";

import { Admin } from "../Globals/AppUrls";

export default class RoleViewModel extends ViewModelBase<Role> {
    @MobX.observable public roleCount: number = 0;
    @MobX.observable private ascOrder = true;
    private roleStore: RoleStore = StoresInstance.domain.RoleStore;
    @MobX.observable public Valid: boolean = false;

    constructor() {
        super(new Role(""));
        this.setDecorators(Role);
    }

    public setRole(role: Role, newRole: boolean) {
        this.setValue("id", newRole ? "" : role.id);
        this.setValue("name", newRole ? "" : role.name);
    }

    public get(fieldName: any) {
        return this.getValue(fieldName);
    }

    @action
    public set(fieldName: any, value: string | number | boolean | Date) {
        this.setValue(fieldName, value as string);
    }

    public isFieldValid(fieldName: keyof FieldType<Role>, value: any): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @action
    public async loadRolesAsync(): Promise<ApiResult<RoleDTO[]>> {
        var apiResult = await this.Get<RoleDTO[]>(Admin.Roles.GetAll);

        if (apiResult.wasSuccessful) {
            this.roleStore.setRoles(apiResult.payload);
            MobX.runInAction(() => (this.roleCount = this.roleStore.getRoleCount));
        }
        return apiResult;
    }

    @computed get getRoles(): Role[] {
        let roles = this.roleStore.getRoles.slice();
        roles.sort((a: RoleDTO, b: RoleDTO) => {
            if (this.ascOrder) return sortByString(a.name, b.name);
            else return sortByString(b.name, a.name);
        });
        return roles;
    }

    public getRole = (id: string) => {
        if (id) {
            return this.roleStore.getRoles.find(u => u.id === id);
        } else {
            return new Role("");
        }
    };

    @computed get getRoleCount(): number {
        return this.roleCount;
    }

    @computed get getIsLoadingData(): boolean {
        return this.roleStore.getIsLoadingData;
    }

    @action
    public setOrderAsc() {
        this.ascOrder = !this.ascOrder;
    }

    @computed get getOrderAsc(): boolean {
        return this.ascOrder;
    }

    public async postRoleDetailAsync() {
        var apiResult = await this.Post<RoleDTO>(Admin.Roles.UpsertRole, this.getModel);
        if (apiResult.wasSuccessful) {
            let existingRole = this.roleStore.getRoles.find(u => u.id === this.getValue("id"));
            if (existingRole) {
                const index = this.roleStore.getRoles.indexOf(existingRole, 0);
                if (index > -1) {
                    this.roleStore.getRoles.splice(index, 1);
                }
            }
            const newRole = new Role(apiResult.payload.id);
            newRole.fromDto(apiResult.payload);
            this.roleStore.getRoles.push(newRole);
        }
        return apiResult.payload;
    }
}
