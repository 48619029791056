// User interface friendly strings.
const PROPERTYOCCUPYTYPE_50 = "0-50%";
const PROPERTYOCCUPYTYPE_79 = "51-79%";
const PROPERTYOCCUPYTYPE_DEFAULT = "80-100%";

// Transform by property occupy type.
export const getPropertyOccupyType = (type: number | undefined): string => {
    let name = "";

    switch (type) {
        case 50:
            name = PROPERTYOCCUPYTYPE_50;
            break;

        case 79:
            name = PROPERTYOCCUPYTYPE_79;
            break;

        default:
            name = PROPERTYOCCUPYTYPE_DEFAULT;
    }

    return name;
};
