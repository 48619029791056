/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect } from "react";
import { StoresInstance } from "Custom/Stores";
import { getHistory } from "../../Core/Utils/Utils";

export const Logout: React.FC = () => {
    useEffect(() => {
        //getHistory().push("/");
        StoresInstance.domain.AccountStore.doLogout();
    }, []);

    return <></>;
};
