import { makeStyles, Button } from "@material-ui/core";
import styled from "styled-components";

export const useFilterBarStyles = makeStyles(theme => ({
    paper: {
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        borderRadius: "0",
        color: "white",
        marginBottom: "35px",
        padding: "20px",
        [theme.breakpoints.down("xs")]: {
            backgroundColor: "transparent",
            border: "none",
            boxShadow: "none",
            marginBottom: "25px",
            padding: "0",
        },
    },
    container: {
        alignItems: "center",
    },
    title: {
        fontSize: "21px",
        fontWeight: "bold",
        [theme.breakpoints.down("xs")]: {
            fontSize: "14px",
        },
    },
    sliderTitle: {
        order: 1,
    },
    sliderBox: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        },
    },
    slider: {
        flex: "1",
        marginLeft: "60px",
        marginRight: "60px",
        order: 2,
        [theme.breakpoints.down("xs")]: {
            marginLeft: "0",
            marginRight: "0",
            paddingLeft: "20px",
            paddingRight: "20px",
            order: "3",
            width: "100%",
        },
    },
    sliderValue: {
        fontSize: "21px",
        order: 3,
        width: "100px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "14px",
            order: "2",
            textAlign: "center",
        },
    },
    sortBox: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        },
    },
    sortTitle: {
        [theme.breakpoints.down("xs")]: {
            marginBottom: "7px",
        },
    },
    select: {
        display: "flex",
        flex: "1",
        marginLeft: "20px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "12px",
            marginRight: "20px",
            width: "100%",
        },
    },
}));

export const SliderStyles = styled.div`
    .MuiSlider-thumb {
        width: 68px;
        height: 68px;
        margin-top: -30px;
        margin-left: -33px;
        background-color: white;
    }

    .MuiSlider-track {
        opacity: 0;
    }

    .MuiSlider-rail {
        color: #fff;
        opacity: 0.75;
        height: 8px;
        border-radius: 50px;
    }

    .MuiSlider-root {
        padding: 28px 0;
    }

    @media (max-width: 767px) {
        .MuiSlider-thumb {
            width: 40px;
            height: 40px;
            border-width: 3px;
            margin-top: -20px;
            margin-left: -20px;
        }

        .MuiSlider-rail {
            height: 4px;
        }
    }
`;

export const SelectStyles = styled.div`
    .MuiInputBase-root {
        color: white;
        flex: 1;
        font-size: 18px;
    }

    .MuiSelect-root {
        background-color: rgba(255, 255, 255, 0.4);
        padding-bottom: 6px;
        padding-top: 6px;
    }

    .MuiSelect-icon {
        color: white;
    }

    div:not(.Mui-error) {
        fieldset {
            border-color: #8cd2f7 !important;
        }
    }

    ${props => props.theme.breakpoints.down("xs")} {
        .MuiSelect-root {
            font-size: 12px;
        }
    }
`;

export const IconButton: any = styled(Button)`
    height: 64px;
    margin: 0px 15px 0px;
    padding: 0;
    width: 64px;
    background-color: unset;
    box-shadow: none;
    &:hover {
        background-color: unset;
        box-shadow: none;
    }
`;
