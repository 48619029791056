import { ViewModelBase } from "../../Core/ViewModels";
import { CarrierSettingModel } from "../Models/CarrierSettingModel";
import { FieldType, isNullOrEmpty } from "../../Core/Utils/Utils";
import { ApiResult } from "Core/Models";
import { AppUrls } from "Custom/Globals";
import { observable, runInAction } from "mobx";

export class CarrierSettingViewModel extends ViewModelBase<CarrierSettingModel> {
    public name: string;
    public image: string;

    @observable
    public apiValidationError = "";

    private inheritors: CarrierSettingViewModel[] = [];

    constructor(name: string, image: string) {
        super(new CarrierSettingModel());
        this.setDecorators(CarrierSettingModel);

        this.name = name;
        this.image = image;
    }

    isFieldValid(fieldName: keyof FieldType<CarrierSettingModel>, value: any, dontCheckOther?: boolean): boolean {
        let { isValid, errorMessage } = this.validateDecorators(fieldName);

        if (
            (fieldName === "apiAgentPassword" && isNullOrEmpty(this.getValue("apiAgentLogin"))) ||
            (fieldName === "apiAgentLogin" && isNullOrEmpty(this.getValue("apiAgentPassword")))
        ) {
            isValid = true;
            errorMessage = "";
        }

        this.setValid(fieldName, isValid);
        this.setError(fieldName, errorMessage);

        if (!dontCheckOther) {
            if (fieldName === "apiAgentLogin") {
                this.isFieldValid("apiAgentPassword", this.getValue("apiAgentPassword"), true);
            } else {
                this.isFieldValid("apiAgentLogin", this.getValue("apiAgentLogin"), true);
            }
        }

        return isValid;
    }

    async validateAPIDetails() {
        const response = await this.Post<ApiResult<boolean>>(AppUrls.Server.Api.Pro.ValidateAgencyDetails, { ...this.model, insurerName: this.name });
        if (response && response.wasSuccessful) {
        } else if (response && response.errors && response.errors[0] && response.errors[0].message) {
            runInAction(() => {
                this.apiValidationError = response.errors[0].message;
            });
        } else {
            runInAction(() => {
                this.apiValidationError = "An unexpected error occured validating your details.";
            });
        }
    }

    addInheritingViewModel(inheritor: CarrierSettingViewModel) {
        this.inheritors.push(inheritor);
    }

    beforeUpdate: undefined;

    public afterUpdate(fieldName: keyof FieldType<CarrierSettingModel>, value: any) {
        this.inheritors.forEach(inh => {
            inh.setValue(fieldName, value);
            console.log(inh);
        });
    }
}
