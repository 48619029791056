import React from "react";
import { Typography } from "@material-ui/core";
import moment from "moment";
import { StoresInstance } from "../../Stores/Stores";
import { isNullOrUndefined } from "util";
import Group4475 from "Content/Group4475.svg";
import TunaSignature from "Content/TunaSignature.jpg";
import NFILogo from "Content/NFI-logo.png";
import { useObserver } from "mobx-react-lite";
import styled from "styled-components";

function getDay() {
    let date = moment();
    let dayNames = [
        "",
        "First",
        "Second",
        "Third",
        "Fourth",
        "Fifth",
        "Sixth",
        "Seventh",
        "Eighth",
        "Ninth",
        "Tenth",
        "Eleventh",
        "Twelfth",
        "Thirteenth",
        "Fourteenth",
        "Fifteenth",
        "Sixteenth",
        "Seventeenth",
        "Eighteenth",
        "Nineteenth",
        "Twentieth",
        "Twenty First",
        "Twenty Second",
        "Twenty Third",
        "Twenty Fourth",
        "Twenty Fifth",
        "Twenty Sixth",
        "Twenty Seventh",
        "Twenty Eighth",
        "Twenty Ninth",
        "Thirtieth",
        "Thirty First",
    ];

    return dayNames[date.date()];
}

function getMonth() {
    let month = moment(new Date()).format("MMMM");
    return month;
}

function getYear() {
    let year = moment().year();
    return year;
}

const StlyedContainer = styled.div`
    height: 240px;
    max-width: 100%;

    p:not(.MuiTypography-h3) {
        color: black;
        margin: 0 0 14px;
        font-size: 12px;
    }

    ol {
        color: #000000;
        font-weight: bold;
        padding: 0px 14px;

        > li {
            color: black;
            font-size: 12px;
            padding-left: 8px;
            > span {
                font-weight: bold;
                text-transform: uppercase;
            }
        }
    }

    ul {
        padding-left: 20px;
        > li {
            p {
                margin: 0px !important;
            }
        }
    }

    h3,
    .MuiTypography-h3 {
        color: #000000;
        font-size: 16px;
    }

    p,
    td,
    th {
        color: #000000;

        > span {
            font-weight: bold;
            text-transform: uppercase;
        }
    }

    div.terms-header {
        p {
            margin: 0px;
        }
    }

    div.terms-section-header {
        margin-top: 60px;
    }

    table {
        border-collapse: collapse;
        width: 100%;
        margin: 20px 0px 10px;

        td,
        th {
            font-size: 11px;
            border: 1px solid #000000;
            padding: 8px 25px;

            @media (max-width: 767px) {
                padding: 5px 12px;
            }
        }

        th:first-child {
            border: none;
            border-bottom: 1px solid black;
        }

        tr {
            td {
                font-weight: 400;
                text-align: center;
                border-top: 1px solid rgba(0, 0, 0, 0.4);
                border-bottom: 1px solid rgba(0, 0, 0, 0.4);
            }

            td:first-child {
                text-align: left;
            }
        }

        tr:last-child {
            td {
                border-bottom: 1px solid black;
            }
        }
    }

    div.terms-footer {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        margin-top: 40px;
        img {
            margin-right: auto;
            max-width: 195px;
            width: 100%;
            height: auto;
            @media (max-width: 767px) {
                margin-bottom: 20px;
            }
        }

        @media (max-width: 767px) {
            flex-direction: column;
            margin-top: 25px;
        }

        .links-container {
            display: flex;
            align-items: flex-end;

            > p {
                margin-right: 5px;
                padding-right: 5px;
                border-right: 1px solid #000000;
                margin-bottom: 0px;
            }

            > p:last-child {
                border-right: none;
                padding-right: 0px;
            }

            @media (max-width: 767px) {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }
`;

export const NFIPartnerAgreementTerms: React.FC = () => {
    return useObserver(() => (
        <StlyedContainer>
            <div className="terms-header">
                <Typography variant="h3">Referral agreement between</Typography>
                <br />
                <br />
                <p>
                    <span>NATIONAL FLOOD INSURANCE, LLC</span>
                </p>
                <p>and</p>
                <p>
                    <span>{StoresInstance.domain.AccountStore.AgencyName}</span>
                </p>
                <p>
                    <span>THIS REFERAL AGREEMENT</span> made and entered into this
                </p>
                <p>
                    <span>{getDay()}</span> day of{" "}
                    <span>
                        {getMonth()}, {getYear()}
                    </span>
                    , by and between <br />
                    <span>NATIONAL FLOOD INSURANCE, LLC</span>, a Florida Corporation, hereinafter referred to as "<span>NFI</span>"
                </p>
                <p>and</p>
                <p>
                    <span>{StoresInstance.domain.AccountStore.AgencyName}</span>
                </p>
                <p>With their principal office at:</p>
                <p>
                    {!isNullOrUndefined(StoresInstance.domain.AgencyDetailViewModel.getValue("id")) && (
                        <>
                            <p>
                                <strong>{StoresInstance.domain.AgencyDetailViewModel.getValue("address1")}</strong>
                            </p>
                            <p>
                                <strong>{StoresInstance.domain.AgencyDetailViewModel.getValue("address2")}</strong>
                            </p>
                            <p>
                                <strong>{StoresInstance.domain.AgencyDetailViewModel.getValue("address3")}</strong>
                            </p>
                            <p>
                                <strong>{StoresInstance.domain.AgencyDetailViewModel.getValue("county")}</strong>
                            </p>
                            <p>
                                <strong>{StoresInstance.domain.AgencyDetailViewModel.getValue("state")}</strong>
                            </p>
                            <p>
                                <strong>{StoresInstance.domain.AgencyDetailViewModel.getValue("zipCode")}</strong>
                            </p>
                        </>
                    )}

                    {isNullOrUndefined(StoresInstance.domain.AgencyDetailViewModel.getValue("id")) && (
                        <p>
                            <strong>COMPANY ADDRESS</strong>
                        </p>
                    )}
                </p>
                <p>
                    hereinafter referred to as "<span>PARTNER AGENCY</span>".
                </p>
            </div>
            <p>
                WHEREAS, the Partner Agency would like NFI to place insurable business with and for acceptance by floodinsurance carriers in compliance with the
                laws, rules, and regulations, pertaining thereto regarding the placementof such business; and the Partner Agency warrants that he/she is an
                independent contractor duly licensed by theDepartment of Insurance, State of{" "}
                <span>
                    {!isNullOrUndefined(StoresInstance.domain.AgencyDetailViewModel.getValue("id"))
                        ? StoresInstance.domain.AgencyDetailViewModel.getValue("state")
                        : "STATE"}
                </span>{" "}
                and authorized through NFI to promote and present applications for coverage.
            </p>
            <p>
                WHEREAS, NFI agrees to allow a commission on such business, if and when placed, in accordance with acommission schedule as shall be agreed upon
                by both parties from time to time.
            </p>
            <p>
                WHEREAS, the Partner Agency is a{" "}
                <span>
                    {!isNullOrUndefined(StoresInstance.domain.AgencyDetailViewModel.getValue("id"))
                        ? StoresInstance.domain.AgencyDetailViewModel.getValue("state")
                        : "STATE"}
                </span>{" "}
                (state in which business is conducted) insurance agency or agent who wishes to participate in the sale of insurance programs underwritten by
                NFI. Partner Agency is considered an independent contractor and not an employee of NFI and is free to represent other companies as the Partner
                Agency shall consider appropriate. Employees of the Partner Agency who solicit and submit applications to
            </p>
            <p>
                NFI shall be duly licensed by the State of{" "}
                <span>
                    {!isNullOrUndefined(StoresInstance.domain.AgencyDetailViewModel.getValue("id"))
                        ? StoresInstance.domain.AgencyDetailViewModel.getValue("state")
                        : "STATE"}
                </span>
                .
            </p>
            <Typography variant="h3">NOW, THEREFORE, IT IS AGREED AS FOLLOWS:</Typography>
            <ol>
                <li>
                    <p>
                        The Partner Agency shall actively maintain a Multiple Line insurance license as required within the State of{" "}
                        <span>
                            {!isNullOrUndefined(StoresInstance.domain.AgencyDetailViewModel.getValue("id"))
                                ? StoresInstance.domain.AgencyDetailViewModel.getValue("state")
                                : "STATE"}
                        </span>
                        . The Producer shall supply a copy of such license to NFI at or before the time of execution of this Agreement, and shall notify
                        promptly of any license change, termination or cancellation of its Multiple Line license for any reason whatsoever.
                    </p>
                </li>
                <li>
                    <p>
                        The Partner Agency warrants and represents to NFI that the Partner Agency now has in force and effect a valid and binding contract of
                        liability insurance covering the Partner Agency for damages occasioned by errors or omissions alleged to have been caused by the Partner
                        Agency. The Partner Agency further warrants and represents that the premium for said policy has been fully paid and that the Partner
                        Agency shall keep such policy, or one similar thereto, in full force and effect at all times during the continuance of this Agreement,
                        in limits of not less than $1,000,000.
                    </p>
                </li>
                <li>
                    <p>
                        The Partner Agency shall provide a Certificate of Insurance evidencing the Partner Agency's errors and omissions insurance policy to NFI
                        at or before the time of execution of this Agreement and annually thereafter, and shall notify NFI promptly of any change, termination
                        or cancellation of such insurance policy for any reason whatsoever.
                    </p>
                </li>
                <li>
                    <p>
                        The Partner Agency shall give no indications, quotations or binders on NFI's behalf, without first receiving notice from NFI of its
                        terms, conditions, rates and limitations on each and every presentation made by Partner Agency, or without prior written binding
                        authority from NFI.
                    </p>
                </li>
                <li>
                    <p>
                        The Partner Agency shall have no authority to bind any coverage or make any changes in the terms and conditions of any flood insurance
                        policy. Unmodified ACORD Certificates of Insurance may be issued by the Partner Agency and a copy must be sent to NFI.
                    </p>
                </li>
                <li>
                    <p>
                        The Partner Agency shall not be liable to NFI for the payment of all premium items and as indicated by the NFI underwriter for direct
                        bill items. Non-payment by the Partner Agency of the account shall result in immediate suspension of this Agreement. Premiums collected
                        on or on behalf of NFI will be forwarded to NFI offices within seven (7) business days.
                    </p>
                </li>
                <li>
                    <p>
                        The Partner Agency shall refund to NFI any and all unearned commission caused by cancellations or reduced premium endorsements, at the
                        same rate at which commissions were originally allowed Partner Agency. Such refund shall be paid to NFI within thirty (30) days after
                        Partner Agency is advised of the cancellation or reduction adjustment and NFI rules and regulations with respect to cancellations would
                        be fully applicable.
                    </p>
                </li>
                <li>
                    <p>
                        Any interim or final additional premiums developed by audit or under a reporting form policy shall become the sole responsibility of the
                        Policyholder for collection and payment. Any exception to this will be subject to approval by NFI's underwriting manager.
                    </p>
                </li>
                <li>
                    <p>
                        If the Partner Agency shall extend credit to any insured, it shall be at the Partner Agency’s sole risk and premiums shall be paid to
                        NFI by the Agent when due, whether or not they are collected by the Partner Agency and whether or not the Partner Agency has extended
                        credit to the insured.
                    </p>
                </li>
                <li>
                    <p>The National Flood Insurance Program, (NFIP) rules with respect to policy cancellations will be applicable.</p>
                </li>
                <li>
                    <p>
                        The renewal/expiring policies are not automatically renewed and Partner Agency agrees to furnish updated applications and information
                        necessary to secure renewal quotations from NFI.
                    </p>
                </li>
                <li>
                    <p>
                        Both parties agree that all of the flood insurance business placed through NFI shall become and hence remain a part of NFI’s Book of
                        Business. Partner Agency, in placing business under this Agreement, recognizes that he is a representative of the insured and is not
                        acting as an agent or representative of NFI or its companies. The Partner Agency and NFI recognize that during the term of this
                        Agreement, each may possess certain confidential information about the business of the other. Therefore, the parties agree that all such
                        information shall be held in confidence and that neither party will divulge such information to any third party without the express
                        written consent of the other.
                    </p>
                </li>
                <li>
                    <p>
                        NFI and its related affiliates are prohibited from any type of solicitation and/or marketing of the flood insurance policyholders for
                        any other line of business, including property, casualty, life, and health insurance.
                    </p>
                </li>
                <li>
                    <p>This Agreement may not be changed or modified except in writing and signed by the parties hereto.</p>
                </li>
                <li>
                    <p>
                        This Agreement may be terminated at any time by either party, upon thirty (30) days written notice to the other party, sent by
                        Registered or Certified Mail, Return Receipt Requested. Such termination, however, shall in no event affect the respective rights or
                        liabilities of either party accruing up to the date of termination.
                    </p>
                </li>
                <li>
                    <p>
                        The parties hereto agree that this Agreement shall not become effective until accepted by NFI and, when accepted, shall supersede all
                        previous agreements, whether oral or written, between the parties.
                    </p>
                </li>
                <li>
                    <p>
                        It is specifically understood and agreed that notwithstanding anything to the contrary herein set forth, this Agreement shall not be
                        interpreted or construed so as to compel NFI to accept or place all or any of the business offered to it by the Partner Agency.
                    </p>
                </li>
                <li>
                    <p>
                        Notwithstanding any other provision of this Agreement and as a special consideration of the execution of this Agreement by NFI, the
                        Partner Agency agrees for him or herself, or if a corporation, by it and its principals individually, that it will hold NFI, and NFI's
                        agents, servants and employees free and harmless, and indemnify them from such and every claim of alleged errors and omissions caused
                        by, or related to, the acts of the Partner Agency, its agents, servants, principals and employees, including legal fees, costs and
                        disbursements that may reasonably be incurred by NFI in the defense of such claim or claims to the full extent thereof, with interest
                        thereon, until paid.
                    </p>
                </li>
                <li>
                    <p>
                        NFI and Partner Agency both jointly agree that this Agreement shall be governed and construed in accordance with the laws of the State
                        of Florida.
                    </p>
                </li>
            </ol>
            <div className="terms-header terms-section-header">
                <Typography variant="h3">Addendum A to Agreement between</Typography>
                <Typography variant="h3">
                    <span>NATIONAL FLOOD INSURANCE, LLC (NFI)</span>
                </Typography>

                <Typography variant="h3" component="p">
                    and{" "}
                    <span>
                        {!isNullOrUndefined(StoresInstance.domain.AccountStore.AgencyName)
                            ? StoresInstance.domain.AccountStore.AgencyName
                            : "COMPANY NAME HERE"}
                    </span>{" "}
                    ("Partner Agency"),
                </Typography>
                <Typography variant="h3" component="p">
                    dated this <span>{getDay()}</span> day of{" "}
                    <span>
                        {getMonth()}, {getYear()}
                    </span>
                </Typography>
            </div>
            <br />
            <p>This Addendum is attached to, and forms part of the Agreement between NFI and the Partner Agency.</p>
            <p>THE FOLLOWING PROVISIONS ARE ADDED TO THE AGREEMENT:</p>
            <ol>
                <li>
                    <p style={{ marginBottom: "0px" }}>
                        The Partner Agent is required to provide all necessary and accurate information required for NFI to underwrite each policy. This
                        includes, but is not limited to:
                    </p>
                    <ul>
                        <li>
                            <p>Complete Property Address</p>
                        </li>
                        <li>
                            <p>Building original date of construction</p>
                        </li>
                        <li>
                            <p>Ownership and occupancy of the risk (or intended ownership and occupancy of the risk, if a change is imminent)</p>
                        </li>
                        <li>
                            <p>Elevation Certificates (when required)</p>
                        </li>
                        <li>
                            <p>Prior policy declarations (when applicable)</p>
                        </li>
                        <li>
                            <p>Desired coverages</p>
                        </li>
                    </ul>
                    <p>Property photos are also desired, but not absolutely mandatory.</p>
                </li>
                <li>
                    <p>
                        If NFI should discover that accurate and complete information has not been provided by the Partner Agent, NFI shall notify the Partner
                        Agent that the quoting process will not commence (or will cease) until accurate and complete information has been provided to quote the
                        risk properly.
                    </p>
                </li>
                <li>
                    <p>
                        At the point of sale, the Partner Agent shall provide contact information for the customer, so that NFI can review and underwrite the
                        policy with the customer before binding, and further communicate with the customer for the purposes of servicing and retention of the
                        policy.
                    </p>
                </li>
            </ol>
            <p>
                Agreement accepted and effective at Melbourne, Florida, <br />
                this <span>{getDay()}</span> day of{" "}
                <span>
                    {getMonth()}, {getYear()}
                </span>
                ,
            </p>
            <div className="signature-container">
                <p>
                    <strong>NATIONAL FLOOD INSURANCE, LLC</strong>
                </p>
                <img src={TunaSignature} alt="signature" style={{ maxWidth: "150px" }} />
                <p style={{ marginBottom: "0px" }}>Tuna Siraci</p>
                <p>President</p>
                {/* <p style={{ marginBottom: "0px" }}>Amanda Bryant</p>
                <p>Agency Principal</p> */}
            </div>
            {/* <div className="terms-header terms-section-header">
                <Typography variant="h3">Addendum B to Agreement between</Typography>
                <Typography variant="h3">
                    <span>NATIONAL FLOOD INSURANCE, LLC (NFI)</span>
                </Typography>

                <Typography variant="h3" component="p">
                    and{" "}
                    <span>
                        {!isNullOrUndefined(StoresInstance.domain.AccountStore.AgencyName)
                            ? StoresInstance.domain.AccountStore.AgencyName
                            : "COMPANY NAME HERE"}
                    </span>{" "}
                    ("Partner Agency"),
                </Typography>
                <Typography variant="h3" component="p">
                    dated this <span>{getDay()}</span> day of{" "}
                    <span>
                        {getMonth()}, {getYear()}
                    </span>
                </Typography>
            </div>
            <p style={{ marginTop: "20px", marginBottom: "20px" }}>
                This Addendum is attached to, and forms part of the Agreement between NFI and the Partner Agency.
            </p>
            <Typography variant="h3">COMMISSION SCHEDULE</Typography>
            <p style={{ marginTop: "20px", marginBottom: "20px" }}>
                This schedule is part of your agreement, and is in effect on the later to occur of (1) The effective date of your agreement, (2) The effective
                date of the latest approved revised commission schedule.
            </p>

            <ol type="A">
                <li>
                    <p>
                        COMMISSION <br /> National Flood Insurance, LLC. shall use the following commission schedule for policies produced by you and your
                        agency:
                    </p>
                </li>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Year 1</th>
                            <th>Years 2+</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>American National, Flood Risk Solutions*, Wright NFIP**</td>
                            <td>15%</td>
                            <td>15%</td>
                        </tr>
                        <tr>
                            <td>Argenia, Superior</td>
                            <td>13%</td>
                            <td>13%</td>
                        </tr>
                        <tr>
                            <td>Tower Hill</td>
                            <td>12%</td>
                            <td>12%</td>
                        </tr>
                        <tr>
                            <td>Assurant Voyager, Gridiron, Trusted, Wright Hiscox</td>
                            <td>11%</td>
                            <td>11%</td>
                        </tr>
                        <tr>
                            <td>CatCoverage, Wright Incline, Wright Zurich</td>
                            <td>11%</td>
                            <td>8%</td>
                        </tr>
                        <tr>
                            <td>National General</td>
                            <td>11%</td>
                            <td>10%</td>
                        </tr>
                        <tr>
                            <td>Clearwater, Insurmark</td>
                            <td>9%</td>
                            <td>9%</td>
                        </tr>
                        <tr>
                            <td>Aon Edge, Neptune</td>
                            <td>9%</td>
                            <td>8%</td>
                        </tr>
                        <tr>
                            <td>Amelia, Sterling Surplus, TFIA, TypTap</td>
                            <td>7%</td>
                            <td>7%</td>
                        </tr>
                        <tr>
                            <td>Carrier not listed</td>
                            <td>7% - 15% (TBD)</td>
                            <td>7% - 15% (TBD)</td>
                        </tr>
                    </tbody>
                </table>
                <p className="table-note">*Some FRS policies may pay a lower commission rate, depending on the underlying carrier.</p>
                <p className="table-note">**15% on the ﬁrst $2,000 in commissionable premium, and 5% on the commissionable premium over $2,000.</p>
                <li>
                    <p>COMMISSION RULES</p>
                    <ol type="1">
                        <li>
                            <p>Commission will be paid during the calendar month following the month in which it is earned.</p>
                        </li>
                        <li>
                            <p>NFI may, from time to time, amend or replace this schedule per market ﬂuctuations.</p>
                        </li>
                    </ol>
                </li>
            </ol> */}

            <div className="terms-footer">
                <img src={NFILogo} alt="NFI Logo" />
                <div className="links-container">
                    <p>www.NationalFloodInsurance.org</p>
                    <p>Toll Free (888) 900-0404</p>
                    <p>Fax (888) 444-5434</p>
                </div>
            </div>
        </StlyedContainer>
    ));
};
