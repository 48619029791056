import * as MobX from "mobx";

import { ModelBase } from "../../Core/Models/ModelBase";
import { Min } from "class-validator";

export class CoverageModel extends ModelBase<CoverageModel, CoverageModelDTO> {
    // #region Default State

    public static readonly MODEL_DEFAULT_DEDUCTIBLEOPTION = 0;
    public static readonly MODEL_DEFAULT_DEDUCTIBLEVALUE = 1000;
    public static readonly MODEL_DEFAULT_DEDUCTIBLEVALUE_COMMERCIAL = 1000;
    public static readonly MODEL_DEFAULT_BUILDINGCOVERAGE = 100000;
    public static readonly MODEL_DEFAULT_CONTENTSCOVERAGE = 25000;

    public static readonly MODEL_DEFAULT_BUILDINGCOVERAGE_ARR = [
        { key: "sfr", min: 0, max: 3000000, maxstr: "3,000,000" },
        { key: "mobile", min: 25000, max: 150000, maxstr: "150,000" },
        { key: "condo", min: 0, max: 1500000, maxstr: "1,500,000" },
        { key: "commercial", min: 0, max: 5000000, maxstr: "5,000,000" },
        { key: "rcbap", min: 200000, max: 75000000, maxstr: "75,000,000" },
    ];

    public static readonly MODEL_DEFAULT_CONTENTSCOVERAGE_ARR = [
        { key: "sfr", min: 0, max: 500000, maxstr: "500,000" },
        { key: "mobile", min: 0, max: 100000, maxstr: "100,000" },
        { key: "condo", min: 0, max: 250000, maxstr: "250,000" },
        { key: "commercial", min: 0, max: 1000000, maxstr: "1,000,000" },
        { key: "rcbap", min: 0, max: 1000000, maxstr: "1,000,000" },
    ];

    // #endregion Default State

    // #region Deductible
    @MobX.observable
    public deductibleOption: number = this.deductibleOptionMin;

    @MobX.computed
    public get deductible() {
        return CoverageModel.deductibleOptions[this.deductibleOption].value;
    }

    public get deductibleOptionMin() {
        return CoverageModel.deductibleOptions[0].key;
    }

    public get deductibleOptionMax() {
        return CoverageModel.deductibleOptions[CoverageModel.deductibleOptions.length - 1].key;
    }

    @MobX.observable
    public deductibleAlternateOption: number = this.deductibleAlternateOptionMin;

    @MobX.computed
    public get deductibleAlternate() {
        return CoverageModel.deductibleAlternateOptions[this.deductibleAlternateOption].value;
    }

    public get deductibleAlternateOptionMin() {
        return CoverageModel.deductibleAlternateOptions[0].key;
    }

    public get deductibleAlternateOptionMax() {
        return CoverageModel.deductibleAlternateOptions[CoverageModel.deductibleAlternateOptions.length - 1].key;
    }

    // #endregion Deductible

    // #region Building Coverage

    /**
     * The actual building coverage value being sent to the server.
     */
    @MobX.observable
    public buildingCoverage: number = CoverageModel.MODEL_DEFAULT_BUILDINGCOVERAGE;

    /**
     * The formated building coverage string that the user directly views and edits. Formatted with commas.
     */
    @MobX.observable
    public buildingCoverageFormatted: string = this.buildingCoverage.toLocaleString();

    public get buildingCoverageMarks() {
        return [75000, 100000, 125000, 150000, 175000, 200000, 225000, 250000, 300000, 350000, 400000, 500000, 600000, 700000, 800000, 900000, 1000000];
    }

    // #endregion Building Coverage

    // #region Contents Coverage

    /**
     * The actual contents coverage value being sent to the server.
     */
    @MobX.observable
    public contentsCoverage: number = CoverageModel.MODEL_DEFAULT_CONTENTSCOVERAGE;

    /**
     * The formated contents coverage string that the user directly views and edits. Formatted with commas.
     */
    @MobX.observable
    public contentsCoverageFormatted: string = this.contentsCoverage.toLocaleString();

    public get contentsCoverageMarks() {
        return [25000, 50000, 75000, 100000, 125000, 150000, 175000, 200000, 250000, 300000, 350000, 400000, 500000, 600000, 700000, 800000, 900000, 1000000];
    }
    // #region Contents Coverage

    public fromDto = () => {};
    public toDto = () => {};

    public resetAll = () => {
        this.buildingCoverage = CoverageModel.MODEL_DEFAULT_BUILDINGCOVERAGE;
        this.buildingCoverageFormatted = CoverageModel.MODEL_DEFAULT_BUILDINGCOVERAGE.toLocaleString();
        this.contentsCoverageFormatted = CoverageModel.MODEL_DEFAULT_CONTENTSCOVERAGE.toLocaleString();
        this.contentsCoverage = CoverageModel.MODEL_DEFAULT_CONTENTSCOVERAGE;
        this.deductibleOption = CoverageModel.MODEL_DEFAULT_DEDUCTIBLEOPTION;
        this.deductibleAlternateOption = CoverageModel.MODEL_DEFAULT_DEDUCTIBLEOPTION;
    };

    public static readonly deductibleOptions: any[] = [
        { key: 0, value: 1000 },
        { key: 1, value: 1250 },
        { key: 2, value: 2000 },
        { key: 3, value: 3000 },
        { key: 4, value: 5000 },
        { key: 5, value: 10000 },
    ];

    public static readonly deductibleAlternateOptions: any[] = [
        { key: 0, value: 1000 },
        { key: 1, value: 2000 },
        { key: 2, value: 5000 },
        { key: 3, value: 10000 },
        { key: 4, value: 25000 },
        { key: 5, value: 50000 },
    ];
}

export interface CoverageModelDTO {}
