import { TextField, Theme, makeStyles } from "@material-ui/core";

import { EditableInput } from "Core/Components/EditableInput";
import { FieldType } from "Core/Utils/Utils";
import { IViewModel } from "Core/ViewModels";
import React from "react";
import { styled } from "../../Globals/Base";
import { isNullOrEmpty } from "../../../Core/Utils/Utils";
import { useMediaQuery as useMuiMediaQuery } from "@material-ui/core";

interface IProps<T> {
    viewModel: IViewModel<T>;
    fieldName: keyof FieldType<T>;
    min?: number;
    max?: number;
    placeholder?: string;
}

export function StyledNumberInput<T>(props: IProps<T>) {
    const { viewModel, fieldName, min, max, placeholder } = props;
    const mdMatches = useMuiMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

    return (
        <Container mdMatches={mdMatches}>
            <EditableInput
                viewModel={viewModel}
                fieldName={fieldName}
                type="number"
                validateOnBlur={false}
                inputProps={{ inputProps: { min, max }, variant: "outlined", placeholder: placeholder }}
            />
        </Container>
    );
}

interface IContainerProps {
    mdMatches?: boolean;
}

const Container = styled.div<IContainerProps>`
    display: flex;
    align-items: center;

    > div {
        margin: 3px 0 0;
    }

    p.Mui-error {
        @media (max-width: 767px) {
            font-size: 10px;
        }
    }

    div.MuiFormControl-root {
        width: 243px;

        @media (max-width: 767px) {
            width: 152px;
        }

        input,
        > div > div {
            z-index: 1;
        }

        input {
            font-size: 18px;
            height: 22px;
            color: #464545;
            padding: 14px 5px 13px 20px;
            border-width: 7px;
            border-style: solid;
            border-color: transparent;
            border-top-width: 3px;
            text-align: center;
            font-weight: bold;

            @media (max-width: 767px) {
                padding: 6px 7px 4px;
                font-size: 0.875rem;
                text-align: left;
                border-width: 3px;
                border-top-width: 0;
            }

            ::placeholder {
                font-weight: 500;
                color: #93a0ab;
                opacity: 1;
                width: 0;
            }
        }

        div:not(.Mui-error) {
            fieldset {
                border-color: #f2af4c !important;
            }
        }

        fieldset {
            background-color: rgba(255, 255, 255, 1);
            border-radius: 15px;
            border-width: 7px !important;

            @media (max-width: 767px) {
                border-radius: 10px;
                border-width: 3px !important;
            }
        }
    }
`;
