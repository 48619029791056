export enum ApiResultErrorType {
    Unknown = 0,
    Basic = 1,
    Validation = 2,
    Concurrency = 3,
}

export interface ApiResultError {
    type: ApiResultErrorType;
    message: string;
}

export interface ApiResultBasicError extends ApiResultError {
    type: ApiResultErrorType.Basic;
}

export function isApiResultBasicError(apiResultError: ApiResultError): apiResultError is ApiResultBasicError {
    return apiResultError.type === ApiResultErrorType.Basic;
}

export const filterApiResultBasicErrors = (apiResultErrors: ApiResultError[]) =>
    apiResultErrors.filter(error => isApiResultBasicError(error)) as ApiResultBasicError[];

export interface ApiResultConcurrencyError extends ApiResultError {
    type: ApiResultErrorType.Concurrency;
}

export function isApiResultConcurrencyError(apiResultError: ApiResultError): apiResultError is ApiResultConcurrencyError {
    return apiResultError.type === ApiResultErrorType.Concurrency;
}

export const filterApiResultConcurrencyErrors = (apiResultErrors: ApiResultError[]) =>
    apiResultErrors.filter(error => isApiResultConcurrencyError(error)) as ApiResultConcurrencyError[];

export interface ApiResultValidationError extends ApiResultError {
    type: ApiResultErrorType.Validation;
    fieldId: string;
}

export function isApiResultValidationError(apiResultError: ApiResultError): apiResultError is ApiResultValidationError {
    return apiResultError.type === ApiResultErrorType.Validation;
}

export const filterApiResultValidationErrors = (apiResultErrors: ApiResultError[]) =>
    apiResultErrors.filter(error => isApiResultValidationError(error)) as ApiResultValidationError[];
