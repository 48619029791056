import { observable, styled } from "../../Globals/Base";

import { ContactDetailStyle } from "Custom/StylesAppSpecific/FormStyling";
import { FieldType } from "Core/Utils/Utils";
import { IObservableArray } from "mobx";
import { IOptionProps } from ".";

import { IViewModel } from "../../../Core/ViewModels/IViewModel";
import React from "react";
import { useObserver } from "mobx-react-lite";
import { ViewModelBase } from "../../../Core/ViewModels/ViewModelBase";
import { IModel } from "../../../Core/Models/IModel";

interface IProps<T extends IModel<T>> {
    viewModel: IViewModel<T> & ViewModelBase<T>;
    fieldName: keyof FieldType<T>;
    question: string;
    children: (props: IOptionProps) => React.ReactNode;
    multiselect?: boolean;
    disabled?: boolean;
}

export function LargeMultichoice<T extends IModel<T>>(props: IProps<T>) {
    const { viewModel, fieldName, question, children, multiselect, disabled } = props;

    const onChange = (value: any) => {
        if (multiselect) {
            const items = viewModel.getValue<IObservableArray<string>>(fieldName).toJS();
            if (items.includes(value)) {
                items.splice(items.indexOf(value), 1);
            } else {
                items.push(value);
            }
            value = observable<string>(items);
        }
        viewModel.setValue(fieldName, value);
    };

    return useObserver(() => (
        <Container className={disabled ? "disabled" : ""}>
            <HeadingBox className="multi-heading">
                <h2>{question}</h2>
                <p className="error">{viewModel.getError(fieldName)}</p>
            </HeadingBox>
            {children({ onClick: onChange, selected: viewModel.getValue(fieldName) })}
        </Container>
    ));
}

const HeadingBox = styled(ContactDetailStyle)`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0 !important;
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
        flex-direction: column;
    }

    h2 {
        margin: 0;

        @media (max-width: 767px) {
            font-size: 10px;
        }
    }

    .error {
        white-space: nowrap;
        margin: auto -13px;

        @media (max-width: 767px) {
            width: 100%;
        }
    }
`;

const Container = styled.div`
    > div:last-of-type {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    &.disabled {
        > div/* :not(.multi-heading) */ {
            pointer-events: none;

            > * {
                opacity: 0.5;
            }
        }
    }

    margin-bottom: 40px;
    overflow-y: hidden;

    @media (max-width: 767px) {
        margin-bottom: 10px;
    }
`;
