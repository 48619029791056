import styled from "styled-components";

export const HorizonalCenter = styled.div`
    display: flex;
    justify-content: center;
`;

export const BtnWrapper = styled(HorizonalCenter)`
    /* background-color: #129bdb; */
    background-color: rgba(18, 155, 219, 0.5);
    width: 100%;
    min-width: 250px;
    border-radius: 10px;
    padding: 5px 20px;
    text-decoration: none;

    &:hover {
        cursor: pointer;
    }

    a,
    a:link,
    a:visited,
    a:active {
        margin: 0px;
        color: rgba(255, 255, 255, 0.5);
        text-decoration: none;
        text-transform: capitalize;
    }

    &.selected {
        background-color: rgba(18, 155, 219, 1);
        a,
        a:link,
        a:visited,
        a:active {
            color: rgba(255, 255, 255, 1);
        }
    }
`;
