// Libraries
import React, { useState, useContext, useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "Core/Utils";
import { Redirect } from "react-router";

// App
import { ContactUsViewModel } from "../ViewModels/ContactUsViewModel";
import ContactUsBackground from "Content/contactBG.jpg";
import iconTick from "Content/icon-check.svg";

// Components
import { Footer } from "../Components/Footer";
import { ContactDetailV2 } from "Custom/Components/ContactForm";

// Styling & images
import { Fade, Box } from "@material-ui/core";

import { DivWrapper, GridSpaced, DialogueWrapper } from "../StylesAppSpecific/GridHelperStyling";
import { EditableInput } from "Core/Components/EditableInput";
import { SubmitButton } from "Custom/StylesAppSpecific/Coverage/CoverageDetails";
import styled from "styled-components";
import iconContact from "Content/icon-contact.svg";
import { HdrImgDisc } from "Custom/StylesAppSpecific/PageStyling";
import ReCAPTCHA from "react-google-recaptcha";
import { isNullOrUndefined } from "util";
import { SEOHeader } from "Custom/Models";
import { MetaHeader } from "Custom/Components/SEOComponents";
import { RecaptchaContainer } from "Custom/StylesAppSpecific/ContactForms";

let contactUsViewModel = new ContactUsViewModel();

const ContactSubmitButton = styled(SubmitButton)`
    @media (max-width: 767px) {
        margin-top: 130px !important;
    }
`;

export const ContactUsPage: React.FunctionComponent = () => {
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const doSubmit = (e: any) => {
        e.preventDefault();

        if (contactUsViewModel.isModelValid()) {
            setIsSubmitting(true);
            contactUsViewModel.UpsertContactUsAsync().then(() => {
                setIsSubmitting(false);
                setIsSubmitted(true);
            });
        }
    };

    const onChange = (value: string | null) => {
        contactUsViewModel.setCaptchaToken(value || "");
        if (value !== null && value.length > 0) {
            contactUsViewModel.setValue("passCaptcha", true);
        } else {
            contactUsViewModel.setValue("passCaptcha", false);
        }
    };

    let metaData: SEOHeader = {
        title: "Contact a Flood Expert at My Flood Insurance",
        description:
            "Our goal is to make Flood Insurance simple and that includes being available to help you in any way we can. Contact one of our flood experts.",
    };

    return useObserver(() => (
        <>
            <MetaHeader Model={metaData} />
            <ContactUsPageContainer>
                <ContactUsContainer>
                    <GridSpaced container margin={"0px auto"} additionalcss={"max-width: 688px; width: 100%;"}>
                        <GridSpaced item xs={12} sm={12} additionalcss={"text-align: center;"}>
                            <HdrImgDisc src={iconContact} alt={""} />
                        </GridSpaced>
                    </GridSpaced>

                    <h1>Contact us</h1>
                    <p style={{ marginBottom: "40px", letterSpacing: "-0.35px" }} className="contact-header-text">
                        Our goal is to make flood insurance simple, and that includes being available to help you in any way that we can. We make it easy and
                        convenient to get in touch with us. Fill out the form below or contact us by phone on (888) 900-0404, email, or chat.
                    </p>

                    <ContactUsBoxSection>
                        <Fade in timeout={1000}>
                            <Box>
                                <ContactDetailV2 viewModel={contactUsViewModel} />

                                <RecaptchaContainer>
                                    <ReCAPTCHA sitekey="6LfxGfcUAAAAAFnE3EplKmp5SQxfNkQbxfaobwos" onChange={onChange} />
                                    <span>{contactUsViewModel.getError("passCaptcha")}</span>
                                </RecaptchaContainer>

                                {!isSubmitted && (
                                    <>
                                        <ContactSubmitButton variant="contained" onClick={(e: any) => doSubmit(e)} disabled={isSubmitting}>
                                            <p>Submit your inquiry</p>
                                        </ContactSubmitButton>
                                    </>
                                )}
                                {isSubmitted && (
                                    <>
                                        <ContactSubmitButton variant="contained" onClick={(e: any) => doSubmit(e)} disabled={isSubmitting}>
                                            <img src={iconTick} alt="Submitted Successfully" />
                                        </ContactSubmitButton>
                                    </>
                                )}
                            </Box>
                        </Fade>
                    </ContactUsBoxSection>
                </ContactUsContainer>
                <div style={{ height: "100px", width: "100%" }}></div>
            </ContactUsPageContainer>
            <Footer isTemp={false} />
        </>
    ));
};

const ContactUsBoxSection = styled(Box)`
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    max-width: 1140px;
    padding: 20px 20px;

    button {
        margin: 20px auto 20px;
    }
`;

const ContactUsContainer = styled(Box)`
    max-width: 1124px;
    margin: 0 auto;
`;

const ContactUsPageContainer = styled(DivWrapper)`
    color: #ffffff;
    /*margin-bottom: 100px;*/
    background-image: url("${ContactUsBackground}");
    /* background-image: 
    linear-gradient(
        rgba(27, 43, 55, 0.75), 
        rgba(27, 43, 55, 0.75)
    ),
    url("${ContactUsBackground}"); */
	background-attachment: fixed;

    h1 {
        font-size: 32px;
        text-align: center;
    }

    h2 {
        font-size: 24px;
    }

    p {
        font-size: 14px;
    }

    

    button {
        width: 100%;
        max-width: 468px;
    }

    

    @media (max-width: 767px) {
        padding-left: 20px;
        padding-right: 20px;
    }
`;
