// Libraries
import React, { useState } from "react";
import { Helmet } from "react-helmet";

// App
import { SEOHeader } from "../../Models/";

// Components
import Logo from "Content/OGlogo.jpg";

// Interface
interface IProps {
    Model: SEOHeader;
}

export const MetaHeader: React.FC<IProps> = props => {
    const { Model } = props;

    let title: string = Model.title;
    let metaDescription: string = Model.description;
    let metaKeywords: string | undefined = Model.keywords;
    let image: string = Logo;

    let siteUrl: string = "https://myfloodinsurance.com";
    let fbAppId: string = "";

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <link rel="canonical" href={siteUrl} />
                <meta name="description" content={metaDescription} />
                <meta property="og:title" content={title} />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="My Flood Insurance" />
                <meta property="og:description" content={metaDescription} />
                <meta property="og:url" content={siteUrl} />
                <meta property="fb:app_id" content={fbAppId} />
                <meta property="og:image:url" content={siteUrl + image} />
                <meta property="og:image" content={siteUrl + image} />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="og:image:width" content="300" />
                <meta property="og:image:height" content="300" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={metaDescription} />
                <meta name="twitter:image" content={siteUrl + image} />
                <meta name="twitter:domain" content={siteUrl} />
                <meta name="twitter:creator" content="My Flood Insurance" />
            </Helmet>
        </>
    );
};
