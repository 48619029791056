import * as MobX from "mobx";
import { ModelBase } from "../../../Core/Models/ModelBase";

export class TypeObjectModel extends ModelBase<TypeObjectModel, TypeObjectModelDTO> {
    @MobX.observable
    public type: string = "";

    @MobX.observable
    public sq_ft: string = "";

    public fromDto = (model: TypeObjectModelDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                this[key] = model[key];
            }
        }
    };
    public toDto = (model: TypeObjectModel) => {};

    public resetTypeObject = () => {
        this.type = "";
        this.sq_ft = "";
    };
}

export interface TypeObjectModelDTO {
    type: string;
    sq_ft: string;
}
