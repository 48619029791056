// Libraries
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useMediaQuery } from "Core/Utils/Media";

// Custom
import { InsurerHeader } from "../../AdminComponents/AdminDashboard/InsurerHeader";
import { InsurerStatisticCard } from "../../AdminComponents/AdminDashboard/InsurerStatisticCard";
import { OverallStatisticCard } from "../../AdminComponents/AdminDashboard/OverallStatisticCard";
import { UsersTable } from "../../AdminComponents/AdminDashboard/UsersTable";
import { useRouter, StoresInstance } from "../../Globals/Base";
import { getInsurerLogo } from "../../Utils/InsurerLogo";
import { dateTimeFormat, diffDates, easternStandardTime, isEmptyDate } from "../../Utils/Time";
import AdminDashboardViewModel from "../../ViewModels/Admin/AdminDashboardViewModel";
import { BindingRestrictions } from "./BindingRestrictions";

export const AdminDashboard: React.FC = () => {
    const { history, location } = useRouter();
    const [viewModel] = useState(() => new AdminDashboardViewModel());

    let isMobile: boolean;
    isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;

    useEffect(() => {
        // If the viewmodel is to allow navigation. set the viewmodel router.
        viewModel.setRouter(history, location);

        // With SPAs, we need to move back to the top of the page.
        window.scrollTo(0, 0);
    }, []);

    const renderOverallStatistics = () => {
        return (
            <React.Fragment>
                <Grid xs={4} md={4} item>
                    <OverallStatisticCard text={viewModel.numberOfRegsisteredUsers} title="Registered users:" />
                </Grid>
                <Grid xs={4} md={4} item>
                    <OverallStatisticCard text={viewModel.numberOfQuotesProduced} title="Quotes produced:" />
                </Grid>
                <Grid xs={4} md={4} item>
                    <OverallStatisticCard text={viewModel.numberOfQuotesSelected} title="Quotes selected:" />
                </Grid>
            </React.Fragment>
        );
    };

    const renderInsurerStatisticsHeader = () => {
        return (
            <React.Fragment>
                <Grid xs={12} item>
                    <InsurerHeader />
                </Grid>
            </React.Fragment>
        );
    };

    const renderInsurerStatisticsBody = () => {
        return (
            <React.Fragment>
                {viewModel.insurerStatistics.map(insurer => (
                    <Grid xs={12} md={4} item>
                        <InsurerStatisticCard image={getInsurerLogo(insurer[0])} title={insurer[0]} text={insurer[1]} />
                    </Grid>
                ))}
            </React.Fragment>
        );
    };

    const renderReportsTableAndFilterBar = () => {
        return (
            <React.Fragment>
                <Grid xs={12} item>
                    <UsersTable
                        data={viewModel.users}
                        columns={[
                            {
                                title: "Name",
                                field: "name",
                                render: (rowData: any) => {
                                    return `${rowData.firstName} ${rowData.lastName}`;
                                },
                                customFilterAndSearch: (value: any, rowData: any) => {
                                    return `${rowData.firstName} ${rowData.lastName}`.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                                },
                                customSort: (lhsValue: any, rhsValue: any) => {
                                    var lhsName = `${lhsValue.firstName} ${lhsValue.lastName}`.toLowerCase();
                                    var rhsName = `${rhsValue.firstName} ${rhsValue.lastName}`.toLowerCase();

                                    return lhsName.localeCompare(rhsName);
                                },
                            },
                            {
                                title: "Username",
                                field: "userName",
                            },
                            {
                                title: "No. of quotes produced",
                                field: "generatedQuoteCount",
                            },
                            {
                                title: "No. of quotes selected",
                                field: "quoteCount",
                            },
                            {
                                title: "Latest quote date (ET)",
                                field: "latestCreatedDate",
                                render: (rowData: any) => {
                                    const date = isEmptyDate(rowData.latestCreatedDate)
                                        ? "Unknown"
                                        : easternStandardTime(rowData.latestCreatedDate).format(dateTimeFormat);

                                    return date;
                                },
                                customFilterAndSearch: (value: any, rowData: any) => {
                                    const date = isEmptyDate(rowData.latestCreatedDate)
                                        ? "Unknown"
                                        : easternStandardTime(rowData.latestCreatedDate).format(dateTimeFormat);

                                    return date.indexOf(value) != -1;
                                },
                                customSort: (lhsValue: any, rhsValue: any) => {
                                    return diffDates(lhsValue.latestCreatedDate, rhsValue.latestCreatedDate);
                                },
                                defaultSort: "desc",
                            },
                        ]}
                        onSelect={viewModel.selectUser}
                        title="Reports"
                    />
                </Grid>
            </React.Fragment>
        );
    };

    return useObserver(() => (
        <React.Fragment>
            {!isMobile && (
                <>
                    <Grid container spacing={5}>
                        {renderOverallStatistics()}
                    </Grid>
                    <Grid container spacing={5}>
                        {renderInsurerStatisticsHeader()}
                        {renderInsurerStatisticsBody()}
                        {StoresInstance.domain.AccountStore.isInRole("admin") && <BindingRestrictions viewModel={viewModel} />}
                        {renderReportsTableAndFilterBar()}
                    </Grid>
                </>
            )}
            {isMobile && (
                <>
                    <Grid container spacing={2}>
                        {renderOverallStatistics()}
                    </Grid>
                    <Grid container spacing={2}>
                        {renderInsurerStatisticsHeader()}
                        {renderInsurerStatisticsBody()}
                        {StoresInstance.domain.AccountStore.isInRole("admin") && <BindingRestrictions viewModel={viewModel} />}
                        {renderReportsTableAndFilterBar()}
                    </Grid>
                </>
            )}
        </React.Fragment>
    ));
};
