// Libraries
import React, { useState, useEffect } from "react";
import { useObserver } from "../../Globals/Base";
import { StoresInstance } from "../../Stores";

// Styling & images
import { StyledDiv, ContactDetailStyle2, TermsContainer, ContactDetailsMobileStyle } from "../../StylesAppSpecific/ContactForms";
import radioYes from "Content/radioYes.svg";
import radioYesChecked from "Content/radioYes-checked.svg";
// App
import { ContactModel } from "../../Models";
import { ContactViewModel } from "../../ViewModels/ContactViewModel";
// Components
import { EditableInput } from "../../../Core/Components/EditableInput";
import { isNullOrEmpty } from "Core/Utils/Utils";
import { LoginModal } from "../LoginModal";
import { LoginViewModel } from "Custom/ViewModels/LoginViewModel";
import { Checkbox } from "@material-ui/core";
import { useMediaQuery } from "Core/Utils/Media";

interface IProps {
    viewModel?: ContactViewModel;
    namesLabel?: string;
    contactsLabel?: string;
    noBackground?: boolean;
    hideLogin?: boolean;
    hideTerms?: boolean;
    hideErrorBorder?: boolean;
    className?: string;
}

export const ContactDetail: React.FC<IProps> = props => {
    /* export const ContactDetail: React.FC = () => { */
    /* const { viewModel } = props; */
    const domainStores = StoresInstance.domain;
    const viewModel = props.viewModel === undefined ? domainStores.ContactViewModel : props.viewModel;
    const quoteRequestVM = domainStores.QuoteRequestViewModel;
    const [showLogin, setShowLogin] = useState<boolean>(false);
    const isMobile = !!useMediaQuery("(max-width: 767px)");

    const [loginViewModel] = useState(new LoginViewModel());

    useEffect(() => {
        if (domainStores.AccountStore.IsLoggedIn) {
            viewModel.fillContactInfo();
        }

        if (domainStores.AccountStore.IsProAccount && props.hideTerms) {
            quoteRequestVM.contactViewModel.setValue("terms", true);
        }
        window.scrollTo(0, 0);
    }, []);

    const logMeIn = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        setShowLogin(true);
    };

    const renderContactDetail = () => (
        <>
            <ContactDetailStyle2 className={props.className ? props.className : ""} additionalcss={props.noBackground ? "background-color: transparent;" : ""}>
                <h2 className="contact">{props.namesLabel || "What is the policy holder's name?"}</h2>
                {props.hideLogin || (
                    <p>
                        I have an account{" "}
                        <a href="./login" onClick={logMeIn}>
                            log me in
                        </a>
                    </p>
                )}

                <StyledDiv
                    className="input-wrapper"
                    isValid={props.hideErrorBorder ? true : viewModel.isFieldValid("firstName", viewModel.getValue("firstName"))}
                >
                    <EditableInput<ContactModel>
                        viewModel={viewModel}
                        type="text"
                        fieldName="firstName"
                        fullwidth
                        inputProps={{ placeholder: "Your first name *", autoComplete: "new-password" }}
                    />
                </StyledDiv>
                <StyledDiv
                    className="input-wrapper"
                    isValid={props.hideErrorBorder ? true : viewModel.isFieldValid("lastName", viewModel.getValue("lastName"))}
                >
                    <EditableInput<ContactModel>
                        viewModel={viewModel}
                        type="text"
                        fieldName="lastName"
                        fullwidth
                        inputProps={{ placeholder: "Your last name *", autoComplete: "new-password" }}
                    />
                </StyledDiv>
                <div className="contact-bottom" />
            </ContactDetailStyle2>
            <ContactDetailStyle2 className={props.className ? props.className : ""} additionalcss={props.noBackground ? "background-color: transparent;" : ""}>
                <h2 className="contact details">{props.contactsLabel || "Your preferred method of contact is?"}</h2>
                <StyledDiv className="input-wrapper" isValid={props.hideErrorBorder ? true : viewModel.isFieldValid("tel", viewModel.getValue("tel"))}>
                    <EditableInput<ContactModel>
                        viewModel={viewModel}
                        type="text"
                        fieldName="tel"
                        fullwidth
                        inputProps={{ placeholder: "Contact phone number eg. 123-456-7890", autoComplete: "new-password" }}
                    />
                </StyledDiv>
                <StyledDiv className="input-wrapper" isValid={props.hideErrorBorder ? true : viewModel.isFieldValid("email", viewModel.getValue("email"))}>
                    <EditableInput<ContactModel>
                        viewModel={viewModel}
                        type="text"
                        fieldName="email"
                        fullwidth
                        inputProps={{ placeholder: "Contact email address *", autoComplete: "new-password" }}
                    />
                </StyledDiv>
                <div className="contact-bottom" />
            </ContactDetailStyle2>

            {props.hideTerms || (
                <TermsContainer>
                    <Checkbox
                        name="terms"
                        icon={<img src={radioYes} alt="Y" />}
                        checkedIcon={<img src={radioYesChecked} alt="N" />}
                        checked={viewModel.getValue("terms")}
                        onChange={e => viewModel.setValue("terms", e.target.checked)}
                    />

                    <label>
                        I accept Aurora Insurance Technologies, Inc.{" "}
                        <a href="/terms" target="_blank">
                            Terms of use
                        </a>
                    </label>
                </TermsContainer>
            )}

            <LoginModal
                open={showLogin}
                onClose={() => setShowLogin(false)}
                onSuccess={() => {
                    setShowLogin(false);
                    viewModel.fillContactInfo();
                }}
                viewModel={loginViewModel}
            />
        </>
    );

    return useObserver(() => <>{isMobile ? <ContactDetailsMobileStyle>{renderContactDetail()}</ContactDetailsMobileStyle> : renderContactDetail()}</>);
};
