import { makeStyles } from "@material-ui/core";

/**
 * Styles for use with the admin edit pages.
 */
export const useEditStyles = makeStyles(theme => ({
    paperWrapper: {
        backgroundColor: "rgba(27, 43, 55, 0.75)",
        marginLeft: "auto !important",
        marginRight: "auto !important",
        maxWidth: 1140,
    },
    paper: {
        border: "10px solid #129bdb",
        borderRadius: 30,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(3),
    },
    isPublishedCheckboxWrapper: {
        display: "flex",
    },
    buttonWrapper: {
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
    },
    submitButtonWrapper: {
        display: "flex",
        justifyContent: "right",
    },
    cancelButtonWrapper: {
        display: "flex",
    },
    button: {
        width: 90,
    },
}));
