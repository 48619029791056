import { styled } from "../../Globals/Base";

export const CoverageLevelsContainer = styled.div`
    .coverage-row-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 22px;

        @media (max-width: 767px) {
            flex-direction: column;
            margin-bottom: 0;
            margin-top: 10px;
        }

        > div.coverage {
            max-width: 48%;

            > div {
                margin: 16px 0 !important;
            }

            @media (max-width: 767px) {
                max-width: 500px;

                > div {
                    margin-bottom: 10px !important;
                }
            }
        }
    }

    .coverage {
        padding: 0 40px 20px 40px;
        text-align: center;

        display: flex;
        width: 100%;
        max-width: none;

        @media (max-width: 767px) {
            padding: 0;
        }

        > span,
        input {
            width: auto !important;
            margin: auto 0 !important;
            font-weight: bold;
            font-size: 24px !important;
        }

        h2,
        > span {
            font-size: 14px;
            width: 100%;
            text-align: center;
            color: white;
            display: block;
            margin: 0px;
        }

        > div {
            margin: 10px 0;
            flex-grow: 1;
            margin: 16px 32px !important;
        }

        :last-of-type {
            padding-bottom: 0 !important;
        }

        @media (max-width: 767px) {
            flex-direction: column-reverse;

            > span {
                font-size: 16px !important;
            }
        }

        @media (min-width: 768px) {
            padding: 0 0 20px 0;

            h2,
            > span {
                font-size: 24px;
                margin: 0px;
            }

            h2 {
                margin-right: 28px;
            }

            > div {
                margin: 16px 0;
            }

            > span {
                margin-top: 8px;
            }
        }
    }

    .coverage.contents-coverage {
        > div {
            margin-left: 0px !important;
            margin-right: 72px !important;

            .MuiSlider-thumb {
                margin-left: -2px !important;
            }
        }

        > span {
            font-size: 36px !important;
        }

        .deductible-select {
            @media (max-width: 767px) {
                font-size: 16px;
                font-weight: bold;
                color: black;
            }
        }
    }

    .coverage-parent {
        max-width: 1140px;
        margin: 0 auto;
        /* padding: 35px 40px; */
        background-color: #3a4650;
        margin-bottom: 45px;
        border-radius: 5px;

        h2 {
            font-size: 24px;
            margin: 0;

            @media (max-width: 767px) {
                text-align: left;
                font-size: 14px;

                &:last-of-type {
                    font-size: 10px;
                }
            }
        }

        p {
            @media (max-width: 767px) {
                font-size: 10px;
                text-align: left;
            }
        }

        @media (max-width: 767px) {
            margin-bottom: 25px;
            padding: 0px 20px 15px 20px;
            border-radius: 0;

            .coverage {
                h2 {
                    font-size: 10px;
                    margin-bottom: 10px;
                }
            }
        }
    }
`;

export const CoverageDetailStyle: any = styled.div`
    padding: 0px !important;
    margin: 0px !important;
    text-align: left;
    color: #000000;

    h2 {
        text-align: left !important;
    }

    .coverage-input-container {
        font-size: 24px;
        font-weight: bold;
        display: flex;
        align-items: center;

        div.MuiInputBase-root {
            width: 100%;

            > input {
                width: 100% !important;
                padding: 0px !important;
            }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type="number"] {
            -moz-appearance: textfield;
        }

        @media (max-width: 767px) {
            input {
                font-size: 16px !important;
            }
            font-size: 16px !important;
            padding: 10px 14px;
        }

        p {
            margin: 0px;

            @media (max-width: 767px) {
                font-size: 16px !important;
            }
        }

        img {
        }
    }

    width: 100%;
    height: auto;
    background-repeat: no-repeat;
    border-radius: 5px;

    @media (max-width: 767px) {
        margin: 0 auto 0px;
    }

    h2 {
        width: 100%;
        text-align: left;
        font-weight: 700;
        font-size: 24px;
        font-family: "Montserrat", Arial, Helvetica, sans-serif;
        letter-spacing: 0px;
        color: #ffffff;
        margin: 0px 0px 34px;

        @media (max-width: 767px) {
            margin-bottom: 15px;
        }
    }

    h2.contact {
        margin-top: -10px;
        margin-bottom: 36px;

        @media (max-width: 767px) {
            margin-bottom: 18px;
        }
    }

    > div:not(.contact-bottom) {
        /* needed as MUI sticks inputs in a DIV (ffs!) */
        display: inline-flex;
        width: 100%;
        padding: 12px 20px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 7px solid #f2af4c;
        border-radius: 15px;

        @media (max-width: 767px) {
            border-width: 3px;
            border-radius: 7.5px;
            padding: 5px 15px;
        }
    }

    input.MuiInput-input,
    input {
        text-align: left;
        font-size: 16px;
        font-family: "Montserrat", Arial, Helvetica, sans-serif;
        letter-spacing: -0.53px;

        ::placeholder {
            font-style: italic;
        }
    }

    p.Mui-error {
        position: absolute;
        top: 53px;
        font-size: 14px;
        font-style: italic;

        @media (max-width: 767px) {
            bottom: -32px;
            font-size: 10px;
            top: unset;
            bottom: -32px;
        }
    }

    div.contact-bottom {
        margin-bottom: 5px;
    }

    @media (max-width: 767px) {
        padding: 20px;

        h2 {
            font-size: 10px;
        }
    }
`;
