import * as MobX from "mobx";

import { ModelBase } from "../../Core/Models/ModelBase";

export enum FileStatus {
    New = 0,
    ToBeAdded = 1,
    Added = 2,
    ToBeDeleted = 3,
}

export default class FileModel extends ModelBase<FileModel, FileModelDTO> {
    // #region Default State

    public static readonly MODEL_DEFAULT_ID = undefined;
    public static readonly MODEL_DEFAULT_NAME = "";
    public static readonly MODEL_DEFAULT_CONTENTTYPE = "";
    public static readonly MODEL_DEFAULT_SIZE = 0;
    public static readonly MODEL_DEFAULT_ABSOLUTEPATH = "";
    public static readonly MODEL_DEFAULT_RELATIVEPATH = "";
    public static readonly MODEL_DEFAULT_FILE = undefined;
    public static readonly MODEL_DEFAULT_STATUS = FileStatus.New;

    // #endregion Default State

    @MobX.observable
    public id?: string = FileModel.MODEL_DEFAULT_ID;

    @MobX.observable
    public name: string = FileModel.MODEL_DEFAULT_NAME;

    @MobX.observable
    public contentType: string = FileModel.MODEL_DEFAULT_CONTENTTYPE;

    @MobX.observable
    public size: number = FileModel.MODEL_DEFAULT_SIZE;

    @MobX.observable
    public absolutePath: string = FileModel.MODEL_DEFAULT_ABSOLUTEPATH;

    @MobX.observable
    public relativePath: string = FileModel.MODEL_DEFAULT_RELATIVEPATH;

    @MobX.observable
    public file?: File = FileModel.MODEL_DEFAULT_FILE;

    @MobX.observable
    public status: FileStatus = FileModel.MODEL_DEFAULT_STATUS;

    @MobX.action
    public fromDto = (model: FileModelDTO): void => {
        this.id = model.id;
        this.name = model.name;
        this.contentType = model.contentType;
        this.size = model.size;
        this.absolutePath = model.absolutePath;
        this.relativePath = model.relativePath;
        this.file = FileModel.MODEL_DEFAULT_FILE;
        this.status = model.status;
    };

    public toDto = (): FileModelDTO => {
        return {
            id: this.id,
            name: this.name,
            contentType: this.contentType,
            size: this.size,
            absolutePath: this.absolutePath,
            relativePath: this.relativePath,
            status: this.status,
        };
    };
}

export interface FileModelDTO {
    id?: string;
    name: string;
    contentType: string;
    size: number;
    absolutePath: string;
    relativePath: string;
    status: FileStatus;
}
