import * as MobX from "mobx";
import { ModelBase } from "../../../Core/Models/ModelBase";

export class ParcelModel extends ModelBase<ParcelModel, ParcelModelDTO> {
    @MobX.observable
    public apn_original: string = "";

    @MobX.observable
    public apn_unformatted: string = "";

    @MobX.observable
    public apn_previous: string = "";

    @MobX.observable
    public fips_code: string = "";

    @MobX.observable
    public depth_ft: number = 0;

    @MobX.observable
    public frontage_ft: number = 0;

    @MobX.observable
    public area_sq_ft: number = 0;

    @MobX.observable
    public area_acres: number = 0;

    @MobX.observable
    public country_name: string = "";

    @MobX.observable
    public country_land_use_code: string = "";

    @MobX.observable
    public country_land_use_description: string = "";

    @MobX.observable
    public standardized_land_use_category: string = "";

    @MobX.observable
    public standardized_land_use_type: string = "";

    @MobX.observable
    public location_descriptions: string[] = [];

    @MobX.observable
    public zoning: string = "";

    @MobX.observable
    public building_count: number = 0;

    @MobX.observable
    public tax_account_number: string = "";

    @MobX.observable
    public legal_description: string = "";

    @MobX.observable
    public lot_code: string = "";

    @MobX.observable
    public lot_number: string = "";

    @MobX.observable
    public subdivision: string = "";

    @MobX.observable
    public municipality: string = "";

    @MobX.observable
    public section_township_range: string = "";

    public fromDto = (model: ParcelModelDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                this[key] = model[key];
            }
        }
    };
    public toDto = (model: ParcelModel) => {};

    public resetParcel = () => {
        this.apn_original = "";
        this.apn_unformatted = "";
        this.apn_previous = "";
        this.fips_code = "";
        this.depth_ft = 0;
        this.frontage_ft = 0;
        this.area_sq_ft = 0;
        this.area_acres = 0;
        this.country_name = "";
        this.country_land_use_code = "";
        this.country_land_use_description = "";
        this.standardized_land_use_category = "";
        this.standardized_land_use_type = "";
        this.location_descriptions = [];
        this.zoning = "";
        this.building_count = 0;
        this.tax_account_number = "";
        this.legal_description = "";
        this.lot_code = "";
        this.lot_number = "";
        this.subdivision = "";
        this.municipality = "";
        this.section_township_range = "";
    };
}

export interface ParcelModelDTO {
    apn_original: string;
    apn_unformatted: string;
    apn_previous: string;
    fips_code: string;
    depth_ft: number;
    frontage_ft: number;
    area_sq_ft: number;
    area_acres: number;
    country_name: string;
    country_land_use_code: string;
    country_land_use_description: string;
    standardized_land_use_category: string;
    standardized_land_use_type: string;
    location_descriptions: string;
    Zoning: string;
    building_count: number;
    tax_account_number: string;
    legal_description: string;
    lot_code: string;
    lot_number: string;
    subdivision: string;
    municipality: string;
    section_township_range: string;
}
