// Libraries
import React from "react";
import { useObserver } from "mobx-react-lite";
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from "@material-ui/core/";

// Components
import { StoresInstance } from "../../Stores";
import { ResultModel } from "Custom/Models";

export const ApiDiagnoseQuote: React.FC = () => {
    const domainStores = StoresInstance.domain;
    const quoteResponseVM = domainStores.QuoteResultViewModel;

    const renderResult = (result: ResultModel) => {
        return (
            <div className={"quoteData"}>
                <div>
                    <span>Date: </span>
                    {result != undefined ? result.date : ""}
                </div>
                <div>
                    <span>Quote Number: </span>
                    {result != undefined ? result.quoteNumber : ""}
                </div>
                <div>
                    <span>Quotes: </span>
                    {result != undefined ? JSON.stringify(result.quotes) : ""}
                </div>
                <div>
                    <span>Warnings: </span>
                    {result != undefined ? result.warnings[0] : ""}
                </div>
            </div>
        );
    };

    const aonEdgeResult = () => {
        return renderResult(quoteResponseVM.forAPIDiagnosis?.aonEdgeResult!);
    };

    const argeniaResult = () => {
        return renderResult(quoteResponseVM.forAPIDiagnosis?.argeniaResult!);
    };

    const frsResult = () => {
        return renderResult(quoteResponseVM.forAPIDiagnosis?.fRSResult!);
    };

    const sterlingResult = () => {
        return renderResult(quoteResponseVM.forAPIDiagnosis?.sterlingResult!);
    };

    const selectiveResult = () => {
        return renderResult(quoteResponseVM.forAPIDiagnosis?.selectiveResult!);
    };

    const neptuneResult = () => {
        return renderResult(quoteResponseVM.forAPIDiagnosis?.neptuneResult!);
    };

    const hiscoxFloodPlusResult = () => {
        return renderResult(quoteResponseVM.forAPIDiagnosis?.hiscoxFloodPlusResult!);
    };

    const wrightResult = () => {
        return renderResult(quoteResponseVM.forAPIDiagnosis?.wrightResult!);
    };

    const wrightPrivateFlood = () => {
        return renderResult(quoteResponseVM.forAPIDiagnosis?.wrightPrivateFloodResult!);
    };

    const nfipResult = () => {
        return renderResult(quoteResponseVM.forAPIDiagnosis?.nFIPResult!);
    };

    const femaResult = () => {
        return renderResult(quoteResponseVM.forAPIDiagnosis?.femaResult!);
    };

    return useObserver(() => (
        <>
            <div className="header">API Details</div>
            <div className="body">
                <ExpansionPanel>
                    <ExpansionPanelSummary aria-controls="fema-content" id="fema-header">
                        FEMA Results
                    </ExpansionPanelSummary>
                    {femaResult()}
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary aria-controls="aonEdge-content" id="aonEdge-header">
                        aonEdge Results
                    </ExpansionPanelSummary>
                    {aonEdgeResult()}
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary aria-controls="argenia-content" id="argenia-header">
                        argenia Results
                    </ExpansionPanelSummary>
                    {argeniaResult()}
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary aria-controls="frs-content" id="frs-header">
                        FRS Results
                    </ExpansionPanelSummary>
                    {frsResult()}
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary aria-controls="sterling-content" id="sterling-header">
                        Sterling Results
                    </ExpansionPanelSummary>
                    {sterlingResult()}
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary aria-controls="hiscoxFloodPlus-content" id="hiscoxFloodPlus-header">
                        Hiscox FloodPlus Results
                    </ExpansionPanelSummary>
                    {hiscoxFloodPlusResult()}
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary aria-controls="wright-content" id="wright-header">
                        Wright Results
                    </ExpansionPanelSummary>
                    {wrightResult()}
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary aria-controls="nfip-content" id="nfip-header">
                        NFIP Results
                    </ExpansionPanelSummary>
                    {nfipResult()}
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary aria-controls="neptune-content" id="neptune-header">
                        Neptune Results
                    </ExpansionPanelSummary>
                    {neptuneResult()}
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary aria-controls="wrightpriv-content" id="wrightpriv-header">
                        Wright Private Flood Results
                    </ExpansionPanelSummary>
                    {wrightPrivateFlood()}
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary aria-controls="selective-content" id="selective-header">
                        Selective/Torrent Results
                    </ExpansionPanelSummary>
                    {selectiveResult()}
                </ExpansionPanel>
            </div>
        </>
    ));
};
