import * as MobX from "mobx";

import { IsEmail, IsNotEmpty, IsPhoneNumber, Length, IsNumberString } from "class-validator";

import { ModelBase } from "../../Core/Models/ModelBase";

export class AgencyDetailModel extends ModelBase<AgencyDetailModel, AgencyDetailModelDTO> {
    // #region Default State

    public static readonly MODEL_DEFAULT_ID = undefined;
    public static readonly MODEL_DEFAULT_USERID = "";
    public static readonly MODEL_DEFAULT_NAME = "";
    public static readonly MODEL_DEFAULT_ADDRESS1 = "";
    public static readonly MODEL_DEFAULT_ADDRESS2 = "";
    public static readonly MODEL_DEFAULT_ADDRESS3 = "";
    public static readonly MODEL_DEFAULT_STATE = "";
    public static readonly MODEL_DEFAULT_ZIPCODE = "";
    public static readonly MODEL_DEFAULT_PRINCIPAL = "";
    public static readonly MODEL_DEFAULT_ISDELETED = false;
    public static readonly MODEL_DEFAULT_COUNTY = "";

    // #endregion Default State

    @MobX.observable
    public id: string | undefined = AgencyDetailModel.MODEL_DEFAULT_ID;

    @MobX.observable
    public userId: string = AgencyDetailModel.MODEL_DEFAULT_USERID;

    @MobX.observable
    @IsNotEmpty({ message: "Required" })
    public name: string = AgencyDetailModel.MODEL_DEFAULT_NAME;

    @MobX.observable
    @IsNotEmpty({ message: "Required" })
    public address1: string = AgencyDetailModel.MODEL_DEFAULT_ADDRESS1;

    @MobX.observable
    public address2: string = AgencyDetailModel.MODEL_DEFAULT_ADDRESS2;

    @MobX.observable
    public address3: string = AgencyDetailModel.MODEL_DEFAULT_ADDRESS3;

    @MobX.observable
    @IsNotEmpty({ message: "Required" })
    public county: string = AgencyDetailModel.MODEL_DEFAULT_COUNTY;

    @MobX.observable
    @IsNotEmpty({ message: "Required" })
    public state: string = AgencyDetailModel.MODEL_DEFAULT_STATE;

    @MobX.observable
    @IsNotEmpty({ message: "Required" })
    @Length(5, 5, { message: "Invalid Zipcode" })
    @IsNumberString({ message: "Invalid Zipcode" })
    public zipCode: string = AgencyDetailModel.MODEL_DEFAULT_ZIPCODE;

    @MobX.observable
    @IsNotEmpty({ message: "Required" })
    public principal: string = AgencyDetailModel.MODEL_DEFAULT_PRINCIPAL;

    @MobX.observable
    public isDeleted: boolean = AgencyDetailModel.MODEL_DEFAULT_ISDELETED;

    @MobX.action
    public fromDto = (model: AgencyDetailModelDTO): void => {
        this.id = model.id;
        this.userId = model.userId;
        this.name = model.name;
        this.address1 = model.address1;
        this.address2 = model.address2;
        this.address3 = model.address3;
        this.state = model.state;
        this.zipCode = model.zipCode;
        this.principal = model.principal;
        this.isDeleted = model.isDeleted;
    };

    public toDto = (): AgencyDetailModelDTO => {
        return {
            id: this.id,
            userId: this.userId,
            name: this.name,
            address1: this.address1,
            address2: this.address2,
            address3: this.address3,
            state: this.state,
            zipCode: this.zipCode,
            principal: this.principal,
            isDeleted: this.isDeleted,
        };
    };

    @MobX.action
    public reset = () => {
        this.id = AgencyDetailModel.MODEL_DEFAULT_ID;
        this.userId = AgencyDetailModel.MODEL_DEFAULT_USERID;
        this.name = AgencyDetailModel.MODEL_DEFAULT_NAME;
        this.address1 = AgencyDetailModel.MODEL_DEFAULT_ADDRESS1;
        this.address2 = AgencyDetailModel.MODEL_DEFAULT_ADDRESS2;
        this.address3 = AgencyDetailModel.MODEL_DEFAULT_ADDRESS3;
        this.state = AgencyDetailModel.MODEL_DEFAULT_STATE;
        this.zipCode = AgencyDetailModel.MODEL_DEFAULT_ZIPCODE;
        this.principal = AgencyDetailModel.MODEL_DEFAULT_PRINCIPAL;
        this.isDeleted = AgencyDetailModel.MODEL_DEFAULT_ISDELETED;
    };
}

export interface AgencyDetailModelDTO {
    id?: string;
    userId: string;
    name: string;
    address1: string;
    address2: string;
    address3: string;
    state: string;
    zipCode: string;
    principal: string;
    isDeleted: boolean;
}
