import React, { useState } from "react";
import { EditableInput } from "Core/Components/EditableInput";
import { SettingContainer, CarrierAccountDetailStyle, AgentPasswordIcon } from "../../StylesAppSpecific/CarrierSettingsStyling";
import { useObserver } from "mobx-react-lite";
import { CarrierSettingViewModel } from "../../ViewModels/CarrierSettingViewModel";
import EyeClosed from "Content/icon-Eye-closed.svg";
import EyeOpen from "Content/icon-Eye-open.svg";
import { useMediaQuery } from "Core/Utils";

interface IProps {
    viewModel: CarrierSettingViewModel;
}

export const CarrierSetting: React.FC<IProps> = ({ viewModel }) => {
    const bind = viewModel.getContext();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const isMobile = !!useMediaQuery("(max-width: 767px)");

    let handleShowPassword = (e: any) => {
        setShowPassword(!showPassword);
    };

    return useObserver(() => (
        <>
            <div className="divider" />
            <SettingContainer>
                <div className="setting">
                    <div className="image">
                        <img src={viewModel.image} alt="" />
                    </div>
                    <div className="name-option">
                        <div className="name">
                            <h3>{viewModel.name}</h3>
                        </div>
                        <div className="about">
                            <p>
                                Please add your log in details below so you are able to use your account with this carrier. If you don't have an account or
                                don't have the details on hand, don't worry! You can add them later on your dashboard from the carrier settings option.
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    style={isMobile ? { display: "flex", flexDirection: "column" } : { display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                >
                    <CarrierAccountDetailStyle additionalcss={isMobile ? "flex-direction: column; width: 100%;" : "flex-direction: column; width: 48%;"}>
                        <h3>Your agent id</h3>
                        <EditableInput
                            viewModel={viewModel}
                            inputProps={{ placeholder: "Agent login", autoComplete: "new-password" }}
                            fieldName="apiAgentLogin"
                        />
                    </CarrierAccountDetailStyle>
                    <CarrierAccountDetailStyle additionalcss={isMobile ? "flex-direction: column; width: 100%;" : "flex-direction: column; width: 48%;"}>
                        <h3>Your login password</h3>

                        <label htmlFor="apiAgentPassword" style={{ position: "relative" }}>
                            <EditableInput
                                viewModel={viewModel}
                                inputProps={{ placeholder: "Agent password", autoComplete: "new-password" }}
                                fieldName="apiAgentPassword"
                                type={showPassword ? "text" : "password"}
                            />
                            <AgentPasswordIcon
                                onClick={(e: any) => handleShowPassword(e)}
                                title={showPassword ? "Hide password" : "Show password"}
                                className="show-pass-icon"
                            >
                                <img src={showPassword ? EyeClosed : EyeOpen} alt="" />
                            </AgentPasswordIcon>
                        </label>
                    </CarrierAccountDetailStyle>
                </div>
            </SettingContainer>
        </>
    ));
};
