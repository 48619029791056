import { ViewModelBase } from "../../Core/ViewModels/ViewModelBase";
import { BlankModel } from "../../Core/Models/BlankModel";
import { CarrierSettingViewModel } from "./CarrierSettingViewModel";
import logoHiscox from "../../Content/icon-logoHiscox.png";
import logoWrightNFIP from "../../Content/icon-logoWrightNFIP.png";
import logoWrightPFA from "../../Content/icon-logoWrightPFA.png";
import logoSterling from "../../Content/icon-logoSterling.png";
import logoAonEdge from "../../Content/icon-logoAonEdge.png";
import logoFloodRiskSolutions from "../../Content/icon-logoFloodRiskSolutions.png";
import logoArgenia from "../../Content/icon-logoArgenia.png";
import logoNeptune from "../../Content/icon-logoNeptune.png";
import { getInsurerLogo } from "../Utils/InsurerLogo";
import logoZurich from "../../Content/icon-logoWrightPFA.png";
import * as AppUrls from "../Globals/AppUrls";
import { IUserAgencyDetailDTO } from "../Models/UserAgencyDetail";
import { StoresInstance } from "Custom/Stores";
import { isNullOrEmpty } from "Core/Utils/Utils";
import { UserAgencyDetailsModelDTO } from "Custom/Models/UserAgencyDetailsModel";

const carriers = ["wrightFloodNFIP", "sterling", /* "zurich", */ "aonEdge", "floodRiskSolutions", "argenia", "hiscoxFloodPlus", "neptune", "wrightPrivateFlood"] as const;

type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<infer ElementType> ? ElementType : never;

type Carrier = ElementType<typeof carriers>;

export const carrierDBNameMap: { [key in Carrier]: string } = {
    aonEdge: "AON Edge",
    argenia: "Argenia",
    floodRiskSolutions: "Flood Risk Solutions",
    //zurich: "Zurich",
    sterling: "Sterling",
    wrightFloodNFIP: "Wright NFIP",
    hiscoxFloodPlus: "Hiscox FloodPlus",
    neptune: "Neptune",
    wrightPrivateFlood: "Wright Private Flood",
};

export class CarrierSettingsViewModel extends ViewModelBase<BlankModel> {
    public CarrierViewModels: { [id in Carrier]?: CarrierSettingViewModel } = {};
    public userAgencyDetails: IUserAgencyDetailDTO[] = [];

    constructor() {
        super(new BlankModel());
        this.setDecorators(BlankModel);

        this.CarrierViewModels.wrightFloodNFIP = new CarrierSettingViewModel("Wright Flood NFIP / Hiscox Flood plus", logoWrightNFIP);
        this.CarrierViewModels.sterling = new CarrierSettingViewModel("Sterling Surplus", logoSterling);
        this.CarrierViewModels.hiscoxFloodPlus = new CarrierSettingViewModel("Hiscox FloodPlus", logoWrightPFA);
        this.CarrierViewModels.floodRiskSolutions = new CarrierSettingViewModel("Flood Risk Solutions", logoFloodRiskSolutions);
        this.CarrierViewModels.argenia = new CarrierSettingViewModel("Argenia", logoArgenia);
        this.CarrierViewModels.neptune = new CarrierSettingViewModel("Neptune", logoNeptune);
        this.CarrierViewModels.wrightPrivateFlood = new CarrierSettingViewModel("Wright Private Flood", logoWrightPFA);
        this.CarrierViewModels.wrightFloodNFIP.addInheritingViewModel(this.CarrierViewModels.hiscoxFloodPlus);
    }

    async upsert() {
        const response = await this.Post<UserAgencyDetailsModelDTO[]>(AppUrls.Server.Api.Pro.AgencyUpsert, {
            userAgencyId: StoresInstance.domain.AccountStore.UserAgencyId,
            userAgencyDetails: this.userAgencyDetails,
        });

        if (response && response.wasSuccessful) {
            //StoresInstance.domain.AccountStore.getLoginState(response.payload);
        }
        return response;
    }

    setPayload() {
        this.userAgencyDetails = [];
        for (const vmKey in this.CarrierViewModels) {
            const vm: CarrierSettingViewModel = this.CarrierViewModels[vmKey];
            const isPartner = isNullOrEmpty(vm.getValue("apiAgentLogin")) && isNullOrEmpty(vm.getValue("agencyApiKey"));
            this.userAgencyDetails.push({
                isNFIPartnerAgency: isPartner,
                agencyLogin: isPartner ? undefined : vm.getValue("apiAgentLogin"),
                agencyPassword: isPartner ? undefined : vm.getValue("apiAgentPassword"),
                agencyApiKey: isPartner ? undefined : vm.getValue("agencyApiKey"),
                insurerName: carrierDBNameMap[vmKey],
                userAgencyId: StoresInstance.domain.AccountStore.UserAgencyId || "",
            });
        }
    }

    checkModelValidity() {
        let valid = true;
        for (const vmKey in this.CarrierViewModels) {
            const vm: CarrierSettingViewModel = this.CarrierViewModels[vmKey];
            const vmValid = vm.isModelValid();
            if (!vmValid) {
                valid = false;
                // Keep going through all to generate error messages
            }
        }
        return valid;
    }

    isFieldValid(fieldName: "firstName" | "testNo", value: any, showErrors?: boolean): boolean {
        return true;
    }

    beforeUpdate: undefined;
    afterUpdate: undefined;
}
