// Libraries
import React, { useContext } from "react";
import { useMediaQuery } from "Core/Utils/Media";
import { Link } from "@material-ui/core";

// Components
import { MetaHeader } from "../Components/SEOComponents";
import { SEOHeader } from "../Models";
import { Footer } from "../Components/Footer";
import { useRouter } from "../Globals/Base";

// Styling & images
import { DivWrapper, GridSpaced, DialogueWrapper } from "../StylesAppSpecific/GridHelperStyling";
import { PartnerDiv, PartnerItem, PartnerImageContainer } from "../StylesAppSpecific/PartnersStyling";
import PartnersBackground from "Content/PartnersBackground.jpg";

import auroralogo from "Content/partners/Aurora-logo.png";
import shoothilllogo from "Content/partners/shoothill-logo.png";
import nfilogo from "Content/partners/national-flood-insurance-logo.png";

export const AboutUs: React.FC = () => {
    let isMobile: boolean;
    window.scrollTo(0, 0);
    isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;
    const { history } = useRouter();

    let metaData: SEOHeader = {
        title: "About the Flood and Data Experts Behind My Flood Insurance",
        description: "My Flood Insurance was created to make it easier than ever for property owners and insurance agents to quote Flood Insurance.",
    };

    const contentHdr = (
        <>
            <GridSpaced container justify={"center"} margin={"0px auto"} padding={isMobile ? "" : "30px 0px"} additionalcss={"max-width: 1000px; width: 100%;"}>
                <GridSpaced
                    container
                    justify={"center"}
                    margin={"0px auto"}
                    padding={isMobile ? "14px 0px" : "30px 0px"}
                    additionalcss={isMobile ? "max-width: 300px; width: 100%; " : "max-width: 1000px; width: 100%;"}
                >
                    <h1>Who We Are:</h1>
                </GridSpaced>
            </GridSpaced>
            <GridSpaced
                container
                justify={"center"}
                margin={"0px auto"}
                additionalcss={isMobile ? "max-width: 335px; width: 100%;" : "max-width: 1110px; width: 100%;"}
            >
                <GridSpaced item xs={12} sm={12}>
                    <p>
                        My Flood Insurance was created to make it easier than ever for property owners to have access to multiple, top-rated flood insurance
                        companies. The flood insurance market has grown in the past few years, with private insurance companies offering coverage and premium
                        alternatives to the government-backed National Flood Insurance Program. With recent flooding events highlighting the flood coverage gap
                        in the United States, our hope is that having direct access to these policy options will give more consumers the opportunity to purchase
                        this valuable protection.
                    </p>
                </GridSpaced>
            </GridSpaced>
        </>
    );

    const aboutUs = (
        <>
            <GridSpaced
                container
                justify={"center"}
                margin={"0px auto 40px"}
                padding="30px 0px 0px"
                additionalcss={isMobile ? "max-width: 335px; width: 100%;" : "max-width: 1124px; width: 100%;"}
            >
                <PartnerImageContainer item xs={12} sm={2}>
                    <img src={auroralogo} alt="Aurora Insurance Technologies, Inc." />
                </PartnerImageContainer>
                <PartnerItem item xs={12} sm={10} margin={isMobile ? "10px 0px" : "0px 0px 0px 40px"}>
                    <h3>Aurora Insurance Technologies, Inc.</h3>
                    <p>
                        The hard-working minds behind National Flood Insurance, LLC and Shoothill Software, Inc joined together to create Aurora. Together the
                        team concentrates on data management and insurance technologies.
                    </p>
                    <Link title="Aurora Insurance Technologies, Inc." target="_blank">
                        aurorainsurtech.com
                    </Link>
                </PartnerItem>
            </GridSpaced>
            <GridSpaced
                container
                justify={"center"}
                margin={"0px auto 40px"}
                padding="30px 0px 0px"
                additionalcss={isMobile ? "max-width: 335px; width: 100%;" : "max-width: 1124px; width: 100%;"}
            >
                <PartnerImageContainer item xs={12} sm={2}>
                    <img src={nfilogo} alt="National Flood Insurance, LLC" />
                </PartnerImageContainer>
                <PartnerItem item xs={12} sm={10} margin={isMobile ? "10px 0px" : "0px 0px 0px 40px"}>
                    <h3>National Flood Insurance, LLC</h3>
                    <p>
                        As a national flood insurance agency headquartered in Florida, National Flood Insurance has seen the devastating effects of flooding
                        firsthand. With decades of experience in the flood insurance industry, we are experts in protecting American homes and businesses with a
                        variety of affordable and valuable coverage options. Inspired by our clients, our own experiences, and the flooding stories from around
                        the country, we co-created My Flood Insurance to make flood insurance more accessible for property owners, renters, and business owners
                        nationwide.
                    </p>
                    <Link href={"https://nationalfloodinsurance.org"} title="nationalfloodinsurance.org" target="_blank">
                        nationalfloodinsurance.org
                    </Link>
                </PartnerItem>
            </GridSpaced>
            <GridSpaced
                container
                justify={"center"}
                margin={"0px auto 40px"}
                padding="30px 0px 80px"
                additionalcss={isMobile ? "max-width: 335px; width: 100%;" : "max-width: 1124px; width: 100%;"}
            >
                <PartnerImageContainer item xs={12} sm={2}>
                    <img src={shoothilllogo} alt="Shoothill Software, Inc." />
                </PartnerImageContainer>
                <PartnerItem item xs={12} sm={10} margin={isMobile ? "10px 0px" : "0px 0px 0px 40px"}>
                    <h3>Shoothill Software, Inc.</h3>
                    <p>
                        Shoothill Software Inc. is a US based sister company to the award winning Shoothill Ltd in the UK. Founded in 2006, the company
                        specialises in creating web applications, cell phone apps, data visualization techniques and custom commercial software for a diverse
                        client base including Microsoft, Disney, Warner Brothers and Black & Decker among many others.
                    </p>
                    <Link href={"https://shoothill.com"} title="Shoothill Software, Inc." target="_blank">
                        shoothill.com
                    </Link>
                </PartnerItem>
            </GridSpaced>
        </>
    );

    return (
        <>
            <MetaHeader Model={metaData} />
            <PartnerDiv additionalcss={"background-image: url(" + PartnersBackground + ");"}>
                {contentHdr}
                {aboutUs}
            </PartnerDiv>
            <Footer isTemp={false} />
        </>
    );
};
