// Libraries
import React, { EventHandler } from "react";
import { Editor } from "@tinymce/tinymce-react";

interface IProps {
    htmlContent?: string;
    htmlLength?: number;
    setHtmlContent: (htmContent: string) => void;
}

export const HtmlEditor: React.FC<IProps> = props => {
    const handleEditorChange = (event: any): void => {
        props.setHtmlContent(event.target.getContent());
    };

    return (
        <Editor
            apiKey="g828m3lar5y087wnket2v38pbvjugg2m1091afqssqvjj8ze"
            init={{
                min_height: 50,
                plugins: "link image code autoresize",
                toolbar: "undo redo | bold italic | alignleft aligncenter alignright",
            }}
            initialValue={props.htmlContent ? props.htmlContent : ""}
            onChange={handleEditorChange}
            value={props.htmlContent ? props.htmlContent : ""}
        />
    );
};
