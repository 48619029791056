export const insurerParagraphMap = (insurerName: string): string => {
    const insurerParagraphMap = {
        "Hiscox FloodPlus": `Available exclusively for the US market, Hiscox FloodPlus builds on Hiscox’s decades of
        experience in protecting homeowners from the devastating effects of flood. With a wider scope of
        cover and a choice of higher limits, Hiscox FloodPlus offers more comprehensive protection than
        alternative flood products currently available. Hiscox can trace its Lloyd’s roots back to 1901
        and underwrites through Syndicate 33, one of the oldest and most respected syndicates in
        Lloyd’s. All syndicates benefit from the security of Lloyd’s high quality A+ financial rating
        (S&P), as well as its Central Fund.`,
        Zurich: `Zurich Residential Private Flood Insurance combines all the financial security offered by Zurich’s AM Best A+ (Superior) XV rating with Wright’s more than 30 years’ flood claims service and experience. Together, they deliver the first personal and customizable, private flood insurance that is admitted and approved by your state’s Department of Insurance.`,
        "Wright NFIP": `Wright Flood has been writing federal flood insurance for 30+ years and is the largest provider of NFIP policies in the United States. Wright Flood prides itself on excellent customer service and claims handling as well, as the flood expertise of its team.`,
        Sterling: `Sterling Surplus Underwriters has been the industry leader for Private Flood placement since 2008. Dedicated to solving problems for flood related issues Sterling Surplus Underwriters creatively packaged a program offering bank accepted coverage for risks throughout the Atlantic and Gulf Coasts. We’re relentless in providing our customers with a first class experience by offering flood solutions through multiple markets managed by our dedicated staff of Flood Underwriters and Claim Professionals.`,
        "Flood Risk Solutions": `Flood Risk Solutions offers home and business owners access to multiple flood insurance options. Using advanced flood modeling technology, our system quotes a unique flood insurance package for each property through multiple flood insurance underwriting companies.`,
        "AON Edge": `Aon Edge is a division of Aon. Our colleagues help deliver superior policy administration, claims processing, customer service and agency support for our clients. We are the leading provider of customizable private flood insurance, dedicated to making flood insurance accessible to all insurance agents and property owners.“`,
        Argenia: `Since 1976 Argenia has been helping insurance agents connect their customers with quality flood insurance packages. Currently available in 48 states, we are one of the fastest growing alternatives to the NFIP for flood insurance products.`,
    };

    return insurerParagraphMap[insurerName];
};
