// Libraries
import React, { useState } from "react";
import { useMediaQuery } from "Core/Utils/Media";

// App

// Components

// Styling & images
import { GridSpaced } from "../../StylesAppSpecific/GridHelperStyling";
import AddSomeDetail from "Content/AddSomeDetailloading.svg";
import { LoadingImg, LoadingH1, LoadingH2 } from "./Styling";
import { MFILoader } from "./Loader";

export const PropertyLoading: React.FC = () => {
    let isMobile: boolean;
    isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;

    let cssStr: string = isMobile ? "max-width: 300px; width: 100%;" : "max-width: 1140px; width: 100%;";

    return (
        <>
            <GridSpaced container margin={"0px auto"} padding={"20vh 0px"} additionalcss={cssStr}>
                <GridSpaced item xs={12} sm={12} justify={"center"} additionalcss={"text-align: center;"}>
                    <MFILoader />
                    <LoadingH1>Loading property details</LoadingH1>
                    {/* <LoadingH1>Checking our data sources for your property details</LoadingH1> */}
                    <LoadingH2>for just a few moments to save you some work</LoadingH2>
                </GridSpaced>
            </GridSpaced>
        </>
    );
};
