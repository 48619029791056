import * as MobX from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsNotEmpty, IsIn } from "class-validator";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class NFIPartnerAgreementModel extends ModelBase<NFIPartnerAgreementModel, NFIPartnerAgreementModelDTO> {
    @MobX.observable
    @IsIn([true], { message: "You must agree to the agency agreement" })
    public hasAgreed: boolean = false;

    //fromDto is required but you can leave it blank
    fromDto(model: NFIPartnerAgreementModelDTO): void {}

    //toDto is required but you can leave it blank
    toDto(model: NFIPartnerAgreementModel): void {}
}

export type NFIPartnerAgreementModelDTO = {
    hasAgreed: boolean;
};
