// User interface friendly strings.
const PROPERTYTYPE_COMMERCIAL = "Commercial";
const PROPERTYTYPE_RESIDENTIAL = "Residential";
const PROPERTYTYPE_UNKNOWN = "Other, not sure";

// Transform by property type id.
export const getPropertyTypeById = (typeId: string): string => {
    let name = "";

    switch (typeId) {
        case "0":
            name = PROPERTYTYPE_RESIDENTIAL;
            break;

        case "1":
            name = PROPERTYTYPE_COMMERCIAL;
            break;

        default:
            name = PROPERTYTYPE_UNKNOWN;
    }

    return name;
};

// Transform by property type.
export const getPropertyType = (type: string): string => {
    let name = "";

    switch (type) {
        case "residential":
            name = PROPERTYTYPE_RESIDENTIAL;
            break;

        case "commercial":
            name = PROPERTYTYPE_COMMERCIAL;
            break;

        default:
            name = PROPERTYTYPE_UNKNOWN;
    }

    return name;
};
