import React from "react";

import loaderCenter from "../../../Content/loaderCenter.svg";
import loaderSpinner from "../../../Content/loaderSpinner.svg";
import styled from "styled-components";

export const MFILoader: React.FC = () => {
    return (
        <LoaderAnimation>
            <img src={loaderCenter} />
            <img className="spinner" src={loaderSpinner} />
        </LoaderAnimation>
    );
};

const LoaderAnimation = styled.div`
    position: relative;
    display: flex;

    img {
        margin: auto;
    }

    img:not(.spinner) {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    img.spinner {
        animation: rotate 8s cubic-bezier(0.15, 0, 0.85, 1) infinite alternate;
    }

    @keyframes rotate {
        from {
            transform: rotate(-117deg);
        }
        to {
            transform: rotate(3483deg);
        }
    }
`;
