import * as MobX from "mobx";
import { ModelBase } from "../../Core/Models/ModelBase";
import { ResultModel } from "./ResultModel";

export class QuoteResponseModel extends ModelBase<QuoteResponseModel, QuoteResponseModelDTO> {
    @MobX.observable
    public counter: number = 0;

    @MobX.observable
    public aonEdgeResult: ResultModel = new ResultModel();

    @MobX.observable
    public argeniaResult: ResultModel = new ResultModel();

    @MobX.observable
    public neptuneResult: ResultModel = new ResultModel();

    @MobX.observable
    public sterlingResult: ResultModel = new ResultModel();

    @MobX.observable
    public wrightResult: ResultModel = new ResultModel();

    @MobX.observable
    public nFIPResult: ResultModel = new ResultModel();

    @MobX.observable
    public selectiveResult: ResultModel = new ResultModel();

    @MobX.observable
    public hiscoxFloodPlusResult: ResultModel = new ResultModel();

    @MobX.observable
    public fRSResult: ResultModel = new ResultModel();

    @MobX.observable
    public wrightPrivateFloodResult: ResultModel = new ResultModel();

    @MobX.observable
    public zurichResult: ResultModel = new ResultModel();

    @MobX.observable
    public femaResult: ResultModel = new ResultModel();

    @MobX.observable
    public completedResults: boolean = false;

    @MobX.action
    public fromDto = (model: QuoteResponseModelDTO) => {
        this.counter++;

        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                this[key] = model[key];
            }
        }
    };

    public toDto = (model: QuoteResponseModel) => {};

    @MobX.action
    public resetAll = () => {
        this.aonEdgeResult = new ResultModel();
        this.argeniaResult = new ResultModel();
        this.neptuneResult = new ResultModel();
        this.sterlingResult = new ResultModel();
        this.wrightResult = new ResultModel();
        this.nFIPResult = new ResultModel();
        this.selectiveResult = new ResultModel();
        this.hiscoxFloodPlusResult = new ResultModel();
        this.fRSResult = new ResultModel();
        this.wrightPrivateFloodResult = new ResultModel();
        this.zurichResult = new ResultModel();
        this.femaResult = new ResultModel();

        this.completedResults = false;
    };
}

export interface QuoteResponseModelDTO {
    aonEdgeResult: ResultModel;
    argeniaResult: ResultModel;
    neptuneResult: ResultModel;
    sterlingResult: ResultModel;
    wrightResult: ResultModel;
    nFIPResult: ResultModel;
    selectiveResult: ResultModel;
    hiscoxFloodPlusResult: ResultModel;
    fRSResult: ResultModel;
    wrightPrivateFloodResult: ResultModel;
    zurichResult: ResultModel;
    femaResult: ResultModel;
    completedResults: boolean;
}
