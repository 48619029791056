// Libraries
import * as MobX from "mobx";

// Core
import { FieldType, isEmptyOrWhitespace } from "Core/Utils/Utils";
import { ViewModelBase } from "../../Core/ViewModels/ViewModelBase";

// Custom
import { ResultQuoteModel } from "../Models/ResultQuoteModel";
import { ChosenQuoteModel } from "../Models/ChosenQuoteModel";
import { moneyFormat } from "../Utils/Money";
import { StoresInstance } from "../Stores";
import PropertyQuoteStore from "../Stores/Domain/PropertyQuoteStore";
import { effectiveDateRange, isOfficeHours } from "../Utils/Time";
import { Server } from "Custom/Globals/AppUrls";
import { getFoundationType } from "../Utils/FoundationType";
import { getPropertyTypeById } from "../Utils/PropertyType";

/**
 * View quote viewmodel.
 */
export default class BuyPolicyViewModel extends ViewModelBase<ResultQuoteModel> {
    @MobX.observable
    public insurerName: string = "";

    @MobX.observable
    public id: string = "";

    @MobX.computed
    public get policyStartDate() {
        return PropertyQuoteStore.instance.model.policyEffectiveDate;
    }

    @MobX.computed
    public get policyDateRange() {
        return effectiveDateRange(this.policyStartDate);
    }

    @MobX.computed
    public get isInOfficeHours() {
        return isOfficeHours;
    }

    @MobX.computed
    public get premVal() {
        let retVal: string = StoresInstance.domain.PremVal;
        StoresInstance.domain.PremVal = "";
        return retVal;
    }

    @MobX.computed
    public get quoteNumber() {
        return PropertyQuoteStore.instance.model.quoteNumber;
    }

    @MobX.computed
    public get internalQuoteNumber() {
        return PropertyQuoteStore.instance.model.internalQuoteNumber;
    }

    @MobX.computed
    public get taxesAndFees() {
        return moneyFormat(this.model.taxes + this.model.fees, true);
    }

    //@MobX.computed
    public propertyOccupy(setVal: number) {
        let retVal: string = "";
        switch (setVal) {
            case 50:
                retVal = "0-50%";
                break;
            case 79:
                retVal = "51-79%";
                break;
            default:
                retVal = "80-100%";
        }
        return retVal;
    }

    //@MobX.computed
    public ownershipType(setVal: string) {
        let retVal: string = "";
        switch (setVal) {
            case "ownAndOccupy":
                retVal = "I own and occupy this property";
                break;
            case "ownAndRent":
                retVal = "I own this property and rent it out to someone else";
                break;
            default:
                retVal = "I rent this property from someone else";
        }
        return retVal;
    }

    public formatTrueFalse(setVal: string) {
        let retVal: string = "";
        switch (setVal) {
            case "true":
                retVal = "yes";
                break;
            default:
                retVal = "no";
        }
        return retVal;
    }

    public navigateBack = (): void => {
        this.history.push("/availablepolicies");
    };

    /**
     * Constructor
     *
     * @param insurer The name of the insurer.
     * @param id The quote to load (if defined).
     */
    constructor(insurerName: string, id: string) {
        super(new ResultQuoteModel());
        this.setDecorators(ResultQuoteModel);
        if (!isEmptyOrWhitespace(insurerName) && !isEmptyOrWhitespace(id)) {
            this.insurerName = insurerName;
            this.id = id;
        }
    }

    // #region Viewmodel Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<ResultQuoteModel>): boolean {
        return true;
    }

    // #endregion Viewmodel Boilerplate
}
