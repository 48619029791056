import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { DivWrapper, GridSpaced } from "./GridHelperStyling";

export const PartnerDiv: any = styled(DivWrapper)`
    h1,
    h2,
    h3,
    p {
        margin: 0px;
        padding: 0px;
    }

    h1 {
        font-weight: 700;
        font-size: 50px;
        letter-spacing: -1.25px;
        text-align: center;
        @media (max-width: 767px) {
            font-size: 24px;
            letter-spacing: -1.2px;
        }
    }

    h2 {
        font-weight: 700;
        font-size: 36px;
        letter-spacing: -0.9px;
        text-align: center;
        @media (max-width: 767px) {
            font-size: 24px;
            letter-spacing: -1.2px;
        }
    }

    h3 {
        font-weight: 700;
        font-size: 18px;
        letter-spacing: 0;
        @media (max-width: 767px) {
            font-size: 14px;
            letter-spacing: 0px;
        }
    }

    p {
        text-align: left;
        font-weight: 100;
        font-size: 13px;
        letter-spacing: 0px;
        margin: 8px 0px;
        @media (max-width: 767px) {
            font-size: 12px;
            letter-spacing: 0px;
        }
    }

    a,
    a:visited {
        color: #f2af4c;
        text-decoration: none;
    }
`;

export const PartnerItem: any = styled(GridSpaced)``;

export const PartnerImageContainer: any = styled(GridSpaced)`
    position: relative;
    background-color: #ffffff;
    text-align: center;
    height: 130px;
    max-width: 130px;
    vertical-align: middle;

    @media (max-width: 767px) {
        display: block;
        max-width: 335px;
        width: 100%;
        overflow: hidden;
        margin-bottom: 15px;
    }

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;
