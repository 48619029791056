// Libraries
import * as MobX from "mobx";
import { runInAction } from "mobx";

// Core
import { FieldType, isEmptyOrWhitespace } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";

// Custom
import { Server } from "Custom/Globals/AppUrls";
import { WidgetQuoteModel, WidgetQuoteModelDTO } from "../../Models/WidgetQuoteModel";

/**
 * Widget viewmodel.
 */
export default class WidgetQuotesViewModel extends ViewModelBase<WidgetQuoteModel> {
    @MobX.observable
    public id: string = "";

    /**
     * Constructor
     *
     * @param id The quote result to load (if defined).
     */
    constructor(id: string) {
        super(new WidgetQuoteModel());
        this.setDecorators(WidgetQuoteModel);

        if (!isEmptyOrWhitespace(id)) {
            this.id = id;

            this.load(id);
        }
    }

    // #region Navigation

    public selectQuoteResult = (data: WidgetQuoteModel) => {
        if (!isEmptyOrWhitespace(data.blobId)) {
            this.history.push(`/adminuserreportquotes/${data.blobId}`);
        }
    };

    public backToDashboard = () => {
        this.history.push("/editwidget");
    };

    // #endregion Navigation

    /**
     * An observable collection of widgets.
     */
    @MobX.observable
    public WidgetQuotes = MobX.observable<WidgetQuoteModel>([]);

    // #region Server Api

    /**
     * Loads the widgets from the server.
     */
    public load = async (id: string): Promise<void> => {
        try {
            this.setIsLoading(true);

            const apiResult = await this.Post<WidgetQuoteModel[]>(Server.Api.Admin.GetQuotesByWidgetId, { id });

            if (apiResult.wasSuccessful) {
                runInAction(() => this.WidgetQuotes.replace(apiResult.payload));
            } else {
                // Error. What to do?
                this.setIsErrored(true);
            }
        } catch (exception) {
            // Error. What to do?
            this.setIsErrored(true);
        } finally {
            this.setIsLoading(false);
        }
    };

    // #endregion Server Api

    // #region Viewmodel Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Viewmodel Boilerplate
}
