import React, { Dispatch, SetStateAction, useRef } from "react";
import { action, computed, observable } from "mobx";
import { styled, useContext, useEffect, useState } from "../../Globals/Base";

import { Button } from "@material-ui/core";
import { FieldType } from "../../../Core/Utils/Utils";
import { IModalOptionProps } from "./ModalOption";
import { IViewModel } from "Core/ViewModels";
import { Modal } from "@material-ui/core";
import { useObserver } from "mobx-react-lite";

export interface ISelectModalProps<T> {
    viewModel: IViewModel<T>;
    fieldName: keyof FieldType<T>;
    modalState: ComponentState<boolean>;
}

interface IProps<T> extends ISelectModalProps<T> {
    title: string;
    text: string;
    image?: string;
    children: (props: IModalOptionProps) => React.ReactNode;
}

export function SelectModal<T>(props: IProps<T>) {
    const {
        title,
        text,
        modalState: [open, setOpen],
        children,
        viewModel,
        fieldName,
    } = props;

    const revertValueRef = useRef<any>();
    const revertValueSetRef = useRef<any>(false);

    useEffect(() => {
        const onClose = (e: KeyboardEvent) => e.key === "Enter" && setOpen(false);
        if (open) {
            revertValueRef.current = viewModel.getValue(fieldName);
            revertValueSetRef.current = true;
            window.addEventListener("keydown", onClose);
        } else {
            window.removeEventListener("keydown", onClose);
        }
    }, [open]);

    const onCancel = () => {
        revertValueSetRef.current && onChange(revertValueRef.current);
        setOpen(false);
    };

    const onChange = (value: any) => {
        viewModel.setValue(fieldName, value);
    };

    return useObserver(() => (
        <>
            <Modal open={open} onClose={onCancel}>
                <SelectModalStyles>
                    <div>
                        <div className="title-bar">
                            <h4>{title}</h4>
                            <div role="button" tabIndex={0} className="close" onClick={onCancel}>
                                ✕
                            </div>
                        </div>
                        <div className="content" style={{ height: "calc(100vh - 51px)" }}>
                            <div className="mobile-scroll">
                                {text.length > 0 && (
                                    <>
                                        <p>{text}</p>
                                    </>
                                )}
                                <div className="options">{children({ onClick: onChange, selected: viewModel.getValue(fieldName) })}</div>
                            </div>
                            <div className="btns">
                                <Button className="ok-btn" variant="contained" onClick={() => setOpen(false)}>
                                    OK
                                </Button>
                                <Button className="cancel-btn" variant="contained" onClick={onCancel}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                </SelectModalStyles>
            </Modal>
        </>
    ));
}

export const SelectModalStyles = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none !important;
    pointer-events: none;

    > div {
        /* width: 45%; */
        min-width: 640px;
        /* max-height: 65%; */
        box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.75);
        display: flex;
        flex-direction: column;
        pointer-events: all;
        border-radius: 5px;

        @media (max-width: 767px) {
            height: 100%;
            min-width: auto;
            width: 100%;
        }

        height: 100%;

        div.title-bar {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border-bottom: 1px black solid;
            padding: 15px 20px;
            position: relative;
            background-color: #005fab;

            @media (max-width: 767px) {
                border-radius: 0;
            }

            h4 {
                margin: 0;
            }

            div.close {
                position: absolute;
                font-weight: bold;
                top: 10px;
                right: 10px;
                cursor: pointer;
                line-height: 100%;
            }
        }

        div.content {
            padding: 35px 30px;
            background-color: #3a4650;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;

            @media (max-width: 767px) {
                height: calc(100vh - 51px);
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 10px 20px;

                div.mobile-scroll {
                    overflow-y: auto;
                    margin: 10px 0;
                }
            }

            div.options {
                margin: 25px 0 30px;
                display: flex;
                flex-direction: column;

                @media (max-width: 767px) {
                    margin: 10px 0;
                }
            }

            > div > p {
                margin: 0 10px;
                font-size: 18px;
            }

            div.btns {
                width: 100%;
                display: flex;
                justify-content: center;
                background-color: #666f77;

                @media (max-width: 767px) {
                    margin: 0 -20px -10px;
                    width: calc(100% + 40px);
                    padding-bottom: 67px;
                }

                button {
                    margin: 5px 10px;
                    border-radius: 20px;
                    width: 126px;
                    padding: 8px;

                    @media (max-width: 767px) {
                        margin-top: 20px;
                        margin-bottom: 20px;
                    }

                    span.MuiButton-label {
                        color: white;
                        font-size: 14px;
                    }
                }

                .ok-btn {
                    background-color: #129bdb;
                }

                .cancel-btn {
                    background-color: #333333;
                }
            }
        }
    }
`;

export type ComponentState<T> = [T, (t: T) => void];
