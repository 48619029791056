import styled from "styled-components";
import { baseValidation } from "../../../Core/Utils/Validations";

export const BindingRestrictionsStyling = styled.div`
    flex-basis: 100%;
    margin: 20px 20px;
    padding: 35px 0 21px;
    border-top: 1px solid #129bdb;
    border-bottom: 1px solid #129bdb;

    p:nth-of-type(2) {
        margin: 12px 0 16px;
    }

    > div {
        display: flex;
    }

    div.MuiTextField-root {
        flex-grow: 1;

        > div.MuiInputBase-root {
            background-color: white;
            border: 7px solid #f2af4c;
            border-radius: 5px;

            > input {
                padding: 7px 18px 6px;
                height: auto;
                line-height: 100%;
                font-size: 14px;
            }

            > fieldset {
                border: none;
            }
        }
    }

    span.MuiFormControlLabel-label.Mui-disabled {
        color: white;
        opacity: 0.8;
    }

    label.MuiFormControlLabel-root {
        margin-top: 20px;

        &.Mui-disabled {
            opacity: 0.8;
        }
    }

    button {
        margin: 0 0 0 30px;
        background-color: #129bdb !important;
        border-radius: 1000px;
        color: white !important;
        padding: 13.5px 31px;
        font-size: 14px;
        line-height: 100%;

        &.Mui-disabled {
            opacity: 0.8;
        }
    }

    @media (max-width: 767px) {
        div.MuiTextField-root {
            > div.MuiInputBase-root {
                border-width: 7px;
                font-size: 12px;

                > input {
                    padding: 10px 20px;
                }
            }

            > p {
                font-size: 10px;
            }
        }
    }
`;
