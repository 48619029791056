// Libraries
import React, { useState, useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { Box, Checkbox, FormControlLabel, IconButton } from "@material-ui/core";

// Content
import radioNo from "../../../Content/radioYes.svg";
import radioYes from "../../../Content/radioYes-checked.svg";
import EyeClosed from "../../../Content/icon-Eye-closed.svg";
import EyeOpen from "../../../Content/icon-Eye-open.svg";

// Core
import { EditableInput } from "../../../Core/Components/EditableInput";

// Custom
import {
    Adornment,
    PolicyHolderRegistrationContainer,
    PolicyHolderRegistrationItem,
    PolicyHolderRegistrationInput,
    PolicyHolderRegistrationAdornedInput,
    PolicyHolderRegistrationInputRow,
    PolicyHolderRegistrationTitle,
    PolicyHolderRegistrationSubTitle,
    PolicyHolderHaveAccount,
    PolicyHolderTermsAndConditions,
} from "./PolicyHolderRegistration.Style";
import { LoginModal } from "../LoginModal";
import { QuoteRequestModelV2 } from "../../Models";
import { QuoteRequestViewModel } from "../../ViewModels";
import { LoginViewModel } from "../../ViewModels/LoginViewModel";

interface IProps {
    viewModel: QuoteRequestViewModel;
}

export const Registration: React.FC<IProps> = props => {
    const [showLogin, setShowLogin] = useState<boolean>(false);
    const [loginViewModel] = useState(new LoginViewModel());
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const onLoginModalClose = () => {
        setShowLogin(false);
    };

    const onLoginModalSuccess = () => {
        setShowLogin(false);
    };

    const onLoginModalShow = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        setShowLogin(true);
    };

    const onTermsAndConditionsChange = (event: any) => {
        props.viewModel.setValue("terms", event.target.checked);
    };

    const onHandleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const onHandleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const StyledCheckBoxButton = (props: any) => {
        return <Checkbox checkedIcon={<img src={radioYes} alt="Y" />} icon={<img src={radioNo} alt="N" />} {...props} />;
    };

    return useObserver(() => (
        <PolicyHolderRegistrationContainer>
            <PolicyHolderRegistrationItem className="steps-format">
                <PolicyHolderRegistrationTitle>What is your name?</PolicyHolderRegistrationTitle>
                <PolicyHolderHaveAccount>
                    I have an account{" "}
                    <a href="./login" onClick={onLoginModalShow}>
                        log me in
                    </a>
                </PolicyHolderHaveAccount>
                <PolicyHolderRegistrationInputRow>
                    <PolicyHolderRegistrationInput isValid={props.viewModel.isFieldValid("firstName", props.viewModel.getValue("firstName"))}>
                        <EditableInput<QuoteRequestModelV2>
                            viewModel={props.viewModel}
                            type="text"
                            fieldName="firstName"
                            fullwidth
                            inputProps={{ placeholder: "Your first name *", autoComplete: "new-password" }}
                        />
                    </PolicyHolderRegistrationInput>
                    <PolicyHolderRegistrationInput isValid={props.viewModel.isFieldValid("lastName", props.viewModel.getValue("lastName"))}>
                        <EditableInput<QuoteRequestModelV2>
                            viewModel={props.viewModel}
                            type="text"
                            fieldName="lastName"
                            fullwidth
                            inputProps={{ placeholder: "Your last name *", autoComplete: "new-password" }}
                        />
                    </PolicyHolderRegistrationInput>
                </PolicyHolderRegistrationInputRow>
            </PolicyHolderRegistrationItem>
            <PolicyHolderRegistrationItem className="steps-format">
                <PolicyHolderRegistrationTitle>Please add your contact details</PolicyHolderRegistrationTitle>
                <PolicyHolderRegistrationInputRow>
                    <PolicyHolderRegistrationInput isValid={props.viewModel.isFieldValid("phone", props.viewModel.getValue("phone"))}>
                        <EditableInput<QuoteRequestModelV2>
                            viewModel={props.viewModel}
                            type="text"
                            fieldName="phone"
                            fullwidth
                            inputProps={{ placeholder: "Contact phone number eg. 123-456 - 7890 *", autoComplete: "new-password" }}
                        />
                    </PolicyHolderRegistrationInput>
                    <PolicyHolderRegistrationInput isValid={props.viewModel.isFieldValid("email", props.viewModel.getValue("email"))}>
                        <EditableInput<QuoteRequestModelV2>
                            viewModel={props.viewModel}
                            type="text"
                            fieldName="email"
                            fullwidth
                            inputProps={{ placeholder: "Contact email address *", autoComplete: "new-password" }}
                        />
                    </PolicyHolderRegistrationInput>
                </PolicyHolderRegistrationInputRow>
            </PolicyHolderRegistrationItem>
            <PolicyHolderRegistrationItem className="steps-format">
                <PolicyHolderRegistrationTitle>Set your password</PolicyHolderRegistrationTitle>
                <PolicyHolderRegistrationSubTitle>
                    Password must contain at least 8 characters and include upper & lower case letters and at least one digit.
                </PolicyHolderRegistrationSubTitle>
                <PolicyHolderRegistrationInputRow>
                    <PolicyHolderRegistrationAdornedInput isValid={props.viewModel.isFieldValid("password", props.viewModel.getValue("password"))}>
                        <EditableInput<QuoteRequestModelV2>
                            viewModel={props.viewModel}
                            type={showPassword ? "text" : "password"}
                            fieldName="password"
                            fullwidth
                            inputProps={{ placeholder: "Password *", autoComplete: "new-password" }}
                        />
                        <Adornment>
                            <IconButton onClick={onHandleShowPassword} tabIndex={-1}>
                                <img src={showPassword ? EyeClosed : EyeOpen} />
                            </IconButton>
                        </Adornment>
                    </PolicyHolderRegistrationAdornedInput>
                    <PolicyHolderRegistrationAdornedInput
                        isValid={props.viewModel.isFieldValid("confirmPassword", props.viewModel.getValue("confirmPassword"))}
                    >
                        <EditableInput<QuoteRequestModelV2>
                            viewModel={props.viewModel}
                            type={showConfirmPassword ? "text" : "password"}
                            fieldName="confirmPassword"
                            fullwidth
                            inputProps={{ placeholder: "Confirm password *", autoComplete: "new-password" }}
                        />
                        <Adornment>
                            <IconButton onClick={onHandleShowConfirmPassword} tabIndex={-1}>
                                <img src={showConfirmPassword ? EyeClosed : EyeOpen} />
                            </IconButton>
                        </Adornment>
                    </PolicyHolderRegistrationAdornedInput>
                </PolicyHolderRegistrationInputRow>
            </PolicyHolderRegistrationItem>
            <PolicyHolderTermsAndConditions className="steps-format alignleft">
                <FormControlLabel
                    control={<StyledCheckBoxButton checked={props.viewModel.getValue("terms")} onChange={onTermsAndConditionsChange} />}
                    label={
                        <div>
                            I accept Aurora Insurance Technologies, Inc.{" "}
                            <a href="/terms" target="_blank">
                                Terms of use
                            </a>
                        </div>
                    }
                />
            </PolicyHolderTermsAndConditions>
            <LoginModal open={showLogin} onClose={onLoginModalClose} onSuccess={onLoginModalSuccess} viewModel={loginViewModel} />
        </PolicyHolderRegistrationContainer>
    ));
};
