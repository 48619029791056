import * as MobX from "mobx";

export class AgentReportModel {
    @MobX.observable
    public id = "";

    @MobX.observable
    public firstName = "";

    @MobX.observable
    public lastName = "";

    @MobX.observable
    public userName = "";

    @MobX.observable
    public quoteCount = 0;

    @MobX.observable
    public latestCreatedDate = "";
}
