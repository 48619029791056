import styled from "styled-components";
import { ContactDetailStyle2 } from "./ContactForms";
import { ShowPasswordIcon } from "./LoginStyling";

export const Container = styled.div`
    div.carriers-label {
        background-color: #005fab;
        padding: 15px 20px 16px;

        h2 {
            margin: 0;
        }
    }

    div.btns {
        padding-top: 20px;
        display: flex;
        justify-content: center;

        button {
            border-radius: 1000px;
            width: 200px;
            margin: 0 7.5px;
            padding: 14px 0 15px;
            color: white;
            font-weight: bold;
            font-size: 1.5rem;
            line-height: 100%;
        }

        button.save-btn {
            background-color: #129bdb;
        }

        button.cancel-btn {
            background-color: #333333;
        }
    }

    div.divider {
        border-bottom: 1px solid #129bdb;
    }
`;

export const SettingContainer = styled.div`
    margin: 40px 0;
    display: flex;
    flex-direction: column;

    h3 {
        font-size: 21px;
        margin: 0;
    }

    div.setting {
        display: flex;
        flex-direction: row;
        margin-bottom: 34px;

        @media (max-width: 767px) {
            flex-direction: column;
            align-items: center;
            margin-bottom: 10px;
        }
    }

    div.image {
        display: flex;

        img {
            align-self: flex-start;
        }
    }

    div.name-option {
        flex-grow: 1;
        padding: 0 0 0 40px;
        display: flex;
        flex-direction: column;

        @media (max-width: 767px) {
            padding: 20px 0px 0px 0px;
        }

        p {
            text-align: left;
            font: Regular 14px/24px Montserrat;
            letter-spacing: -0.35px;
        }
    }

    div.option {
        margin: -9px 0;

        > div > div {
            width: 100%;

            > div > label:nth-of-type(2) {
                margin: auto;
            }
        }
    }
`;

export const CarrierAccountDetailStyle = styled(ContactDetailStyle2)`
    padding: 0 !important;
    margin: 0px;
    background-color: transparent;

    h3 {
        /*width: 50%;*/
        text-align: left;
        font-weight: 700;
        font-size: 21px;
        font-family: "Montserrat", Arial, Helvetica, sans-serif;
        letter-spacing: 0px;
        color: #ffffff;
        margin: 0;
        padding: 0 20px 0px 0px;

        @media (max-width: 767px) {
            margin-bottom: 15px;
        }
    }

    > div {
        padding: 13px 20px !important;
        margin: 0px !important;
        max-width: 522px;
    }

    label {
        display: inline-flex;
        width: 100%;
        max-width: 522px;
        padding: 14px 20px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 7px solid #f2af4c;
        border-radius: 15px;
        margin: 0px;

        .MuiBox-root {
            width: 90%;
        }

        input {
            width: 100%;
        }

        @media (max-width: 767px) {
            border-width: 3px;
            border-radius: 10px;
        }
    }

    label.api {
        max-width: unset;
        input,
        div {
            width: 100%;
        }
    }

    .show-pass-icon {
        top: 15px;
        right: 18px;
    }
`;

export const PartnerContainer = styled.div`
    div.terms-container {
        display: inline-flex;
        width: 100%;
        padding: 14px 20px;
        border: 5px solid #129bdb;
        border-radius: 15px;
        background-color: white;
        flex-basis: 100%;
        overflow-y: auto;

        @media (max-width: 767px) {
            padding: 14px 10px;
        }
    }

    span > label h2 {
        margin-bottom: 0;
        margin-top: 0;
        padding-left: 20px;
        line-height: 100%;
        font-size: 1.35rem;

        > span {
            font-weight: normal !important;
            font-size: 1rem;
        }
    }

    div.btns {
        padding-top: 80px;
        display: flex;
        justify-content: center;

        button {
            border-radius: 1000px;
            width: 200px;
            margin: 0 7.5px;
            padding: 14px 0 15px;
            color: white;
            font-weight: bold;
            font-size: 1.5rem;
            line-height: 100%;
        }

        button.save-btn {
            background-color: #129bdb;
        }

        button.cancel-btn {
            background-color: #333333;
        }
    }

    div.agency-info {
        display: flex;

        h2 {
            width: 100%;
        }
    }

    div.divider {
        border-bottom: 1px solid #129bdb;
    }

    div.carrier-list {
        margin: 30px 0;
    }

    div.carrier-logos {
        margin: 30px -30px;

        img {
            padding: 0 30px;
        }
    }

    p,
    ul {
        font-size: 1.125rem;
        font-weight: 500;
    }
`;

export const AgentPasswordIcon: any = styled(ShowPasswordIcon)`
    top: 15px;
`;
