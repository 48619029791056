import { Grid, Theme, Tooltip } from "@material-ui/core";
import React from "react";

import { useMediaQuery as useMuiMediaQuery } from "@material-ui/core";
/* import { useObserver } from "mobx-react-lite"; */
import { useMediaQuery } from "Core/Utils";
import { StyledGrid } from "./Steps.Styling";
import { useObserver } from "Custom/Globals/Base";

interface IProps {
    question: string;
    children?: React.ReactNode;
    completed?: boolean;
    additionalcss?: string;
    image?: string;
    className?: string;
}

export const SimpleQuestion: React.FC<IProps> = props => {
    const { question, children, completed, additionalcss, image, className } = props;
    const mdMatches = useMuiMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
    const mdUpMatches = useMuiMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
    const isMobile = !!useMediaQuery("(max-width: 767px)");

    return useObserver(() => (
        <>
            <StyledGrid container className={className} mdMatches={mdMatches} mdUpMatches={mdUpMatches} completed={completed} additionalcss={additionalcss}>
                {image && !isMobile && (
                    <Grid item xs={1} className="gridImage">
                        <img src={image} alt="" />
                    </Grid>
                )}
                <Grid item xs className="gridItem bottom7">
                    <h2 className="question">{question}</h2>
                </Grid>
                <Grid item className="gridItem">
                    <div className="tooltip-div">
                        <div>{children}</div>
                    </div>
                </Grid>
            </StyledGrid>
        </>
    ));
};
