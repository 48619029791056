import { CoverageEditContainer } from "./Styles";
import { EditPreviousOptions } from ".";
import React from "react";
import SelectCoverage from "Content/SelectCoverage.svg";
import { StoresInstance } from "../../Stores";
import { USDFormat } from "../../../Core/Utils/Utils";
import { useMediaQuery } from "../../../Core/Utils/Media/mediaQuery";

export interface ICoverageEdit {
    editlink?: string;
}

export const CoverageEdit: React.FC<ICoverageEdit> = props => {
    const domainStores = StoresInstance.domain;
    const quoteRequestVM = domainStores.QuoteRequestViewModel;
    const isMobile = !!useMediaQuery("(max-width: 767px)");

    const editLink = () => {
        if (props.editlink) {
            return props.editlink;
        } else {
            return "/coverage";
        }
    };

    return (
        <>
            <EditPreviousOptions image={SelectCoverage} editLink={editLink()} heightOverride={isMobile ? 50 : undefined}>
                <CoverageEditContainer>
                    <div className="labels">
                        <p>Building coverage:</p>
                        <p>Contents coverage:</p>
                        <p>Deductible:</p>
                    </div>
                    <div className="values">
                        <p>{USDFormat.format(quoteRequestVM.getValue("buildingCoverage"))}</p>
                        <p>{USDFormat.format(quoteRequestVM.getValue("contentsCoverage"))}</p>
                        <p>{USDFormat.format(quoteRequestVM.getValue("deductible"))}</p>
                    </div>
                </CoverageEditContainer>
            </EditPreviousOptions>
        </>
    );
};
