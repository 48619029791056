import * as MobX from "mobx";
import { ModelBase } from "../../../Core/Models/ModelBase";

export class AssessmentsModel extends ModelBase<AssessmentsModel, AssessmentsModelDTO> {
    @MobX.observable
    public year: number = 0;

    @MobX.observable
    public land_value: number = 0;

    @MobX.observable
    public improvement_value: number = 0;

    @MobX.observable
    public totalValue: number = 0;

    public fromDto = (model: AssessmentsModelDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                this[key] = model[key];
            }
        }
    };
    public toDto = (model: AssessmentsModel) => {};

    public resetAssessments = () => {
        this.year = 0;
        this.land_value = 0;
        this.improvement_value = 0;
        this.totalValue = 0;
    };
}

export interface AssessmentsModelDTO {
    year: number;
    land_value: number;
    improvement_value: number;
    total_value: number;
}
