import { IObservable, IObservableArray, isObservableArray } from "mobx";
import React, { useRef } from "react";
import radioYes from "../../../Content/radioYes.svg";
import radioYesChecked from "../../../Content/radioYes-checked.svg";
import styled from "styled-components";
import { useMediaQuery } from "../../../Core/Utils/Media/mediaQuery";
import { useObserver } from "../../Globals/Base";

export interface IOptionProps {
    onClick: (value: any) => void;
    selected: any;
}

interface IProps extends IOptionProps {
    value?: any;
    image?: string;
    label: string;
    bold?: boolean;
    imgMargin?: string;
    uploader?: boolean;
    buttonText?: string;
    className?: string;
}

export const LargeMultichoiceOption: React.FC<IProps> = props => {
    const { onClick, selected, value, image, label, bold, imgMargin, uploader, buttonText, className } = props;
    const isMobile = !!useMediaQuery("(max-width: 767px)");
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleClick = () => {
        if (!uploader) {
            onClick(value);
        }
    };

    const isSelected = () => {
        if (isObservableArray(selected)) {
            return (selected as IObservableArray<string>).includes(value);
        } else if (uploader) {
            return selected !== undefined;
        } else {
            return value === selected;
        }
    };

    const getImage = () => image !== undefined && <img className="option-image" src={image} alt="" />;

    const getOption = () => (
        <>
            <p className={image === undefined ? "noimage" : ""}>{uploader && isSelected() ? selected : label}</p>
            {uploader ? (
                <>
                    <div className="upload-btn">
                        <div>
                            <input
                                type="file"
                                accept=".pdf"
                                onChange={e => {
                                    onClick(e.target!.files!);
                                }}
                                ref={fileInputRef}
                            />
                            <div
                                role="button"
                                tabIndex={isSelected() ? 0 : undefined}
                                onClick={() => {
                                    if (isSelected()) {
                                        onClick(undefined);
                                        if (fileInputRef.current) {
                                            fileInputRef.current.value = "";
                                        }
                                    }
                                }}
                            >
                                {isSelected() ? "Remove" : buttonText}
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <img className="select-icon" src={isSelected() ? radioYesChecked : radioYes} alt={isSelected() ? "✓" : ""} />
            )}
        </>
    );

    return useObserver(() => (
        <>
            <Container
                role={uploader ? undefined : "button"}
                tabIndex={uploader ? undefined : 0}
                onClick={handleClick}
                bold={bold}
                imgMargin={imgMargin}
                pointer={!uploader}
                selected={isSelected()}
                uploader={uploader}
                className={className}
            >
                {isMobile ? getImage() : <div className="img-container">{getImage()}</div>}
                {isMobile ? getOption() : <div>{getOption()}</div>}
            </Container>
        </>
    ));
};

interface IContainerProps {
    bold?: boolean;
    imgMargin?: string;
    pointer?: boolean;
    selected?: boolean;
    uploader?: boolean;
}

const Container = styled.div<IContainerProps>`
    height: 77px;
    padding: 0;
    background-color: ${props => (props.selected ? "#005FAB" : "#666F77")};
    display: flex;
    margin-top: 1px;
    cursor: ${props => (props.pointer ? "pointer" : "default")};
    position: sticky;
    bottom: ${({ selected }) => (selected ? 0 : undefined)};

    @media (max-width: 767px) {
        padding: 10px 40px;
    }

    p {
        font-size: ${props => (props.bold ? "28px" : "18px")};
        margin: 0;
        align-self: center;
        text-align: left;
        font-weight: ${props => (props.bold ? "bold" : "normal")};
    }

    p:not(.noimage) {
        @media (max-width: 767px) {
            position: absolute;
            left: 129px;
        }
    }

    img.option-image {
        @media (max-width: 767px) {
            margin: ${props => props.imgMargin};

            + p {
                padding-right: 72px;
                font-size: calc(10px + (18 - 10) * ((100vw - 350px) / (1366 - 350)));
            }
        }
    }

    img.option-image {
        margin: ${props => props.imgMargin};
    }

    > div.img-container {
        display: flex;
        padding: 19px 70px;
        height: 100%;
        justify-content: center;
        margin-right: -20px;
        width: 0;
    }

    > div:not(.img-container) {
        display: flex;
        width: 100%;
        padding: 10px 40px 10px 20px;

        > p {
            padding-right: ${props => (props.uploader ? 251 : 122)}px;

            @media (max-width: 767px) {
                padding-right: 72px;
                font-size: calc(10px + (18 - 10) * ((100vw - 350px) / (1366 - 350)));
            }
        }
    }

    img.select-icon {
        margin-left: auto;

        @media (max-width: 767px) {
            width: 32px;
        }
    }

    div.upload-btn {
        margin-left: auto;
        display: flex;
        align-items: center;

        input {
            display: inline-block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            z-index: 10;
            opacity: 0;
            cursor: ${props => (props.selected ? "default" : "pointer")};
            pointer-events: ${props => (props.selected ? "none" : "all")};
        }

        input::-webkit-file-upload-button {
            cursor: ${props => (props.selected ? "default" : "pointer")};
        }

        > div {
            position: relative;
        }

        > div > div {
            border-radius: 20px;
            background-color: white;
            border: solid 7px #f2af4c;
            color: black;
            font-size: 14px;
            font-weight: bold;
            padding: 4px 12px;
            text-align: center;
            width: 172px;
            cursor: pointer;
        }
    }

    @media (max-width: 767px) {
        padding: 10px 20px;

        ${props => (props.uploader ? "flex-wrap: wrap; height: auto; padding: 12px 26px 20px;" : "")} p {
            ${props => (props.uploader ? "padding-bottom: 62px; padding-right: 0 !important;" : "")}
        }

        .upload-btn {
            ${props => (props.uploader ? "width: 100%; margin-top: 20px;" : "")}

            div {
                ${props => (props.uploader ? "width: 100% !important;" : "")}
            }
        }
    }
`;
