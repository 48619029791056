// Libraries
import React, { useState } from "react";
import { useMediaQuery } from "Core/Utils/Media";

// Components
import { Footer } from "../Components/Footer";
import { AddressSelectorInputGoogle } from "../Components/AddressSelector";
import { GoogleMap } from "../Components/Map";
import { ButtonGroup, Grid } from "@material-ui/core";
import { StoresInstance } from "../Stores";
import { useRouter } from "Core/Utils";
import { useObserver } from "mobx-react-lite";
import { PropertyLoading } from "../Components/IsLoading";

// Styling & images
import { GridSpaced, MapWrapper, TitleWrapper, DivWrapperBGFixed } from "../StylesAppSpecific/GridHelperStyling";
import { ConfirmAddressPanel, PropertySelect, PropertyOwnerBtn, RealEstateBtn, InsuranceAgentBtn, ContinueBtn } from "Custom/StylesAppSpecific/StepsStyling";
import { HdrImgDisc, RoundLink } from "Custom/StylesAppSpecific/PageStyling";
import EnterYourLocation from "Content/EnterYourLocation.svg";
import PXFuel from "Content/quoteBG.jpg";
import iconHouse from "Content/icon-existingProperty.svg";

/**
 * Step 1: Confirm your address
 */
export const Step1ConfirmAddress: React.FC = () => {
    const domainStores = StoresInstance.domain;
    const quoteRequestVM = domainStores.QuoteRequestViewModel;
    const viewModel = domainStores.PropertyDetailViewModel;
    const femaViewModel = domainStores.FloodDataViewModel;

    const { history } = useRouter();
    window.scrollTo(0, 0);

    const requestPopulated: boolean = viewModel.isAddressPopulated;
    const [haveSelected, setselection] = useState<boolean>(false);

    let isMobile: boolean;
    isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;

    if (!requestPopulated || quoteRequestVM.getValue<string>("address").length === 0) {
        history.push("./");
    }

    const setPropertyType = (val: string) => {
        if (val === "") {
            setselection(false);
        } else {
            setselection(true);
        }
    };

    const contentHdr = () => (
        <>
            <h2>
                <span>Step 1:</span> Confirm the address
            </h2>
            {!isMobile && (
                <>
                    <p>
                        <span>Is this the property location?</span> If not please click and drag the location pin to the correct place.
                    </p>
                </>
            )}
        </>
    );

    const googleMapPlacement = (
        <>
            {isMobile ? (
                <>
                    <MapWrapper padding={"0"}>
                        <GoogleMap />
                    </MapWrapper>
                </>
            ) : (
                <>
                    <GoogleMap />
                </>
            )}
        </>
    );

    const mobileFormAndMap = () => {
        return (
            <>
                <div className="address">
                    <AddressSelectorInputGoogle />
                    <p>
                        <span>Is this the property location?</span> If not please click and drag the location pin to the correct place.
                    </p>
                </div>
                <div className="map">{googleMapPlacement}</div>
                <div className="address mb30">
                    <h3>Is this property a</h3>
                    <PropertySelect className={haveSelected ? "is-valid" : "not-valid"}>
                        <select
                            value={quoteRequestVM.getValue<string>("chosenPropertyType")}
                            onChange={e => {
                                quoteRequestVM.setValue("chosenPropertyType", e.target.value);
                                setPropertyType(e.target.value);
                            }}
                        >
                            {viewModel.propertyOptionsArr.map(o => (
                                <option className={"optionPT_" + o.value} key={o.value} value={o.value}>
                                    {o.key}
                                </option>
                            ))}
                        </select>
                    </PropertySelect>
                </div>
            </>
        );
    };

    const formPlacement = () => {
        return (
            <>
                <AddressSelectorInputGoogle />
                <h3>Is this property a</h3>
                <PropertySelect className={`propertyType-select ${haveSelected ? "is-valid" : "not-valid"}`}>
                    <img className="propertyType-icon" alt="" src={iconHouse} />
                    <select
                        value={quoteRequestVM.getValue<string>("chosenPropertyType")}
                        onChange={e => {
                            quoteRequestVM.setValue("chosenPropertyType", e.target.value);
                            setPropertyType(e.target.value);
                        }}
                    >
                        {viewModel.propertyOptionsArr.map(o => (
                            <option className={"optionPT_" + o.value} key={o.value} value={o.value}>
                                {o.key}
                            </option>
                        ))}
                    </select>
                </PropertySelect>
            </>
        );
    };

    const handleClick = (e: string) => {
        viewModel.setAddressPopulated(requestPopulated);
        quoteRequestVM.setUserType(e);
        let propertyType = quoteRequestVM.getValue<string>("chosenPropertyType");
        if (viewModel.addressPopulated && propertyType.length > 0) {
            femaViewModel.getFloodData();
            viewModel.submitAddress();
            history.push("/step2");
        }
    };

    const buttonGroup = (
        <>
            <PropertyOwnerBtn onClick={() => handleClick("propertyOwner")}>
                <div>
                    <span>Continue as a</span>
                    Property owner
                </div>
            </PropertyOwnerBtn>
            <RealEstateBtn onClick={() => handleClick("realEstateAgent")}>
                <div>
                    <span>Continue as a</span>
                    Real Estate Agent
                </div>
            </RealEstateBtn>
            <InsuranceAgentBtn onClick={() => handleClick("insuranceAgent")}>
                <div>
                    <span>Continue as a</span>
                    Insurance Agent
                </div>
            </InsuranceAgentBtn>
        </>
    );

    const userType = () => {
        let retVal = "";
        if (domainStores.AccountStore.IsLoggedIn) {
            if (domainStores.AccountStore.IsProAccount) {
                retVal = "insuranceAgent";
            } else if (domainStores.AccountStore.IsRealtorAccount) {
                retVal = "realEstateAgent";
            } else {
                retVal = "propertyOwner";
            }
        }
        return retVal;
    };

    const continueButton = (
        <>
            <ContinueBtn onClick={() => handleClick(userType())}>
                <div>
                    <span>Continue to</span>
                    Step 2
                </div>
            </ContinueBtn>
        </>
    );

    return useObserver(() => (
        <>
            <DivWrapperBGFixed additionalcss={"background-image: url(" + PXFuel + ");"}>
                {!viewModel.IsLoading ? (
                    <>
                        {isMobile ? (
                            <>
                                <ConfirmAddressPanel>
                                    <div className="confirmAddress">
                                        <div className="address">{contentHdr()}</div>
                                        {mobileFormAndMap()}
                                    </div>
                                    <div className="confirmAddress">{domainStores.AccountStore.IsLoggedIn ? continueButton : buttonGroup}</div>
                                </ConfirmAddressPanel>
                            </>
                        ) : (
                            <>
                                <div style={{ paddingTop: "4%" }}>
                                    <ConfirmAddressPanel>
                                        <div className="confirmAddress">
                                            <div className="address">
                                                {contentHdr()}
                                                {formPlacement()}
                                            </div>

                                            <div className="map">{googleMapPlacement}</div>
                                        </div>
                                        <div className="dashed confirmAddress">{domainStores.AccountStore.IsLoggedIn ? continueButton : buttonGroup}</div>
                                    </ConfirmAddressPanel>
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <PropertyLoading />
                    </>
                )}
            </DivWrapperBGFixed>
            <Footer isTemp={false} />
        </>
    ));
};
