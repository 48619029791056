import * as MobX from "mobx";

import { ModelBase } from "../../Core/Models/ModelBase";

export class InsurerModel extends ModelBase<InsurerModel, InsurerModelDTO> {
    @MobX.observable
    public id: string | undefined = undefined;

    @MobX.observable
    public name: string = "";

    @MobX.observable
    public icon: string = "";

    @MobX.observable
    public description: string = "";

    @MobX.observable
    public isDeleted: boolean = false;

    @MobX.action
    public fromDto = (model: InsurerModelDTO): void => {
        this.id = model.id;
        this.name = model.name;
        this.icon = model.icon;
        this.description = model.description;
        this.isDeleted = model.isDeleted;
    };

    public toDto = (): InsurerModelDTO => {
        return {
            id: this.id,
            name: this.name,
            icon: this.icon,
            description: this.description,
            isDeleted: this.isDeleted,
        };
    };

    @MobX.action
    public reset = () => {
        this.id = undefined;
        this.name = "";
        this.icon = "";
        this.description = "";
        this.isDeleted = false;
    };
}

export interface InsurerModelDTO {
    id?: string;
    name: string;
    icon: string;
    description: string;
    isDeleted: boolean;
}
