import styled from "styled-components";
import { Button, Modal, Box, Grid } from "@material-ui/core";

export const StepPropertySummary: any = styled.div`
    width: 100%;
    padding: 0px 0px 10px;
    border-bottom: 1px solid #8cd2f7;
    margin: 0px 0px 40px;
    display: flex;
    flex-flow: row wrap;

    @media (max-width: 767px) {
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    > div {
        width: 50%;
        text-align: left;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0px;
        color: #ffffff;
        margin-bottom: 30px;

        img {
            width: 25px;
            vertical-align: sub;
            margin-right: 10px;
        }

        span {
            text-align: left;
            text-decoration: underline;
            font: normal 300 12px Montserrat;
            letter-spacing: -0.24px;
            color: #f2af4c;
            cursor: pointer;
        }

        @media (max-width: 767px) {
            width: 100%;
            display: flex;
            align-items: center;
            font-size: 12px;

            img {
                width: 26px;
            }

            span {
                margin-left: auto;
                padding-left: 10px;
            }

            &.response:last-of-type {
                margin-bottom: 0;
            }
        }
    }
`;

const ModalBase: any = styled(Modal)`
    padding: 0px;
    margin: 10% auto;
    border-radius: 5px;
    max-width: 680px;
    outline: none;
    &:focus {
        outline: none;
    }

    > div {
        margin: 0;
        padding: 0;
    }

    .modalhead,
    .modalbody,
    .modalfooter {
        background-color: #3a4650;
        outline: none;
        padding: 40px;
    }
`;

export const PropertyDetailModal: any = styled(ModalBase)`
    > div.step-2-modal {
        outline: none;

        @media (max-width: 767px) {
            padding: 25px;
            height: 100%;
            display: flex;
            flex-direction: column;

            .modalbody {
                overflow-y: auto;
            }
        }
    }

    .modalhead {
        display: flex;
        flex-direction: row;
        height: 58px;
        padding: 15px 0px 15px 20px;
        background-color: #005fab;

        text-align: left;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
        h2 {
            margin: 0 auto;
            font-size: 1.8em;
            text-transform: uppercase;
            font-weight: 500;
        }
    }

    .modalbody {
        @media (max-width: 767px) {
            padding: 20px;
        }

        > div,
        .multichoice > div {
            margin: 0px;
            background-color: unset;

            > div {
                padding: 0px;
            }

            .gridImage {
                padding: 19px 70px 19px 25px;
            }

            .gridItem h2 {
                font-size: 21px;
                letter-spacing: 0px;
            }
        }

        .multichoice > div > div {
            @media (max-width: 767px) {
                padding: 10px;
            }
        }

        .radiogroup [role="radiogroup"] {
            width: 175px;
        }

        .img-container {
            padding: 19px 55px 19px 55px;
        }

        > div [role="button"] > div:not(.img-container) {
            padding: 10px 20px;

            p {
                padding-right: 0;
            }
        }
    }

    .modalfooter {
        height: 82px;
        background-color: #666f77;
        padding: 20px 25px 25px;
        text-align: center;

        .save,
        .cancel {
            font: normal normal bold 14px/18px Montserrat;
            letter-spacing: -0.7px;
            color: #ffffff;
            opacity: 1;
            border-radius: 25px;
            margin: 0px auto;
            width: 112px;
            height: 40px;
        }

        .save {
            background-color: #129bdb;
            margin-right: 10px;
        }

        .cancel {
            background-color: #333333;
            margin-left: 10px;
        }
    }
`;

interface IStyledGridProps {
    mdMatches?: boolean;
    mdUpMatches?: boolean;
    completed?: boolean;
    additionalcss?: string;
}

export const StyledGrid: any = styled(Grid)<IStyledGridProps>`
	background-color: ${({ completed }) => (completed ? "#005FAB" : "rgba(255, 255, 255, 0.1)")};
	margin: 20px 0 40px;
	border-radius: 5px;
	align-items: center;

	${({ additionalcss }) => additionalcss}

	@media (max-width: 767px) {
		margin: 10px 0;
		height: auto;
		flex-direction: column;
		align-items: flex-start;
		border-radius: 0;
		padding: 20px;
	}

	.tooltip-div {
		display: flex;

		> div {
			display: flex;

			:first-of-type {
				flex-grow: 1;
			}

			:nth-of-type(2) {
				flex-grow: 1;
				justify-content: flex-end;

				img.info-tooltip {
					padding: 5px;
				}
			}
		}

		label + .MuiInput-formControl {
			margin-top: 0;
		}
	}

	h2 {
		/* font-size: 18px; */
		font-weight: bold;
		margin: 0;
		display: flex;
		height: 100%;
		align-items: center;
		font-size: 24px;
	}

	/* ${props => (props.mdMatches ? "padding: 20px; position: relative; height: 90px;" : "")} */

	.gridItem {
		${props => (props.mdUpMatches ? "padding: 14px 20px;" : "")}
	}

	.gridImage {
		display: flex;
		justify-content: center;
		margin-right: -20px;
		padding: 19px 70px;
		height: 90px;
	}

	.question {
		/* ${props => (props.mdMatches ? "font-size: 10px;" : "")} */
		@media (max-width: 767px) {
			font-size: 10px;
		}
	}

	.bottom7 {
		${props => (props.mdMatches ? "margin-bottom: 7px;" : "")}
	}

	.info-tooltip {
		${props => (props.mdMatches ? "position: absolute; top: 5px; right: 5px;" : "")}
    }
`;

export const GridProperty: any = styled(Grid)<IStyledGridProps>`
    background-color: unset;

    > div.stacked {
        width: 100%;
        margin: 0px;
        background-color: unset;

        > div {
            padding: 0px 20px 14px 0px !important;

            .MuiFormControl-root {
                width: 321px;
            }

            .MuiFormControl-root input {
                text-align: left;
                font: normal normal 21px Montserrat;
            }

            .MuiFormControl-root input:placeholder {
                font-style: italic;
            }
        }
    }

    > div.i135 {
        > div {
            width: 100%;
            .MuiFormControl-root {
                width: 135px;
            }
        }
    }

    .paddedTop {
        > div:first-of-type {
            padding: 14px 20px 14px 0px !important;
        }
    }

    h2.question {
        margin: 0px !important;
    }

    #anchorType {
        width: 482px;
    }
`;

export const DatePickerContainer: any = styled.div`
    max-width: 1140px;
    //margin: auto;
    width: 100%;

    .date-selects {
        width: auto !important;
        background: none !important;
        border: none !important;
        padding: 0 !important;

        > div:first-of-type {
            margin: auto 60px auto 0;

            @media (max-width: 767px) {
                margin-right: 5px;

                img {
                    width: 32px;
                }
            }
        }
    }

    .MuiBox-root {
        padding: 0;
    }

    .MuiSelect-select.MuiSelect-select {
        padding: 14px 37px 13px 20px;

        @media (max-width: 767px) {
            padding: 8px 25px 8px 7px;
        }
    }

    svg.MuiSelect-icon {
        margin: 0 20px 0 8px;
        top: auto;

        @media (max-width: 767px) {
            margin: 0 10px;
        }
    }

    .MuiInput-underline:before,
    .MuiInput-underline:after {
        content: none !important;
    }

    .MuiInputBase-root {
        margin: 0;
    }

    .MuiInputBase-input {
        font-weight: bold;
    }

    > div {
        background-color: #3a4650;

        @media (max-width: 767px) {
            border-radius: 0;
            margin-bottom: 0;
        }
    }
`;
