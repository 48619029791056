import { ModelBase } from "Core/Models";
import * as MobX from "mobx";
import { IsNotEmpty } from "class-validator";

export class RegisterAddressModel extends ModelBase<RegisterAddressModel, RegisterAddressModel> {
    @MobX.observable
    public address1: string = "";

    @MobX.observable
    public address2: string = "";

    @MobX.observable
    public city: string = "";

    @MobX.observable
    public county: string = "";

    @MobX.observable
    public postCode: string = "";

    @MobX.observable
    public saveAddress: boolean = false;

    public fromDto = (model: RegisterAddressModel) => {};
    public toDto = (model: RegisterAddressModel) => {};
}
