import { action, computed, runInAction } from "mobx";

import { ContactViewModel } from "./ContactViewModel";
import { ProRegisterModel } from "../Models/ProRegisterModel";
import { FieldType } from "Core/Utils/Utils";
import { AccountStatus } from "../../Core/Models/AccountStatus";
import { AppUrls } from "../Globals";
import { StoresInstance } from "../Globals/Base";
import { ViewModelBase } from "../../Core/ViewModels/ViewModelBase";
import { AgencyUserDTO } from "../Models/AgencyUser";

import { AgencyDetailViewModel } from "./Agent/AgencyDetailViewModel";
import { isEmptyOrWhitespace } from "../../Core/Utils/Utils";
import { IsPhoneNumber } from "class-validator";

export class ProRegisterViewModel extends ViewModelBase<ProRegisterModel> {
    public contactViewModel = new ContactViewModel();
    public captchaToken = "";

    private agencyViewModel = new AgencyDetailViewModel();

    constructor() {
        super(new ProRegisterModel());
        this.setDecorators(ProRegisterModel);

        this.contactViewModel.setValue("terms", true);
    }

    public async registerPro() {
        const response = await this.Post<AccountStatus>(`${AppUrls.Server.Api.Account.ProRegister}?token=${this.captchaToken}`, {
            ...this.model,
            ...this.contactViewModel.model,
            agencyDetails: this.agencyViewModel.model,
            emailAddress: this.contactViewModel.model.email,
            phoneNumber: this.contactViewModel.model.tel,
            postCode: "",
        });
        if (response && response.wasSuccessful) {
            StoresInstance.domain.AccountStore.getLoginState(response.payload);
        }
        return response;
    }

    @computed
    public get canRegisterPro(): boolean {
        // Check validity of Agency ViewModel.
        const isAgencyNameValid = !isEmptyOrWhitespace(this.agencyViewModel.model.name);
        const isAgencyPrincipalValid = !isEmptyOrWhitespace(this.agencyViewModel.model.principal);
        const isAddress1Valid = !isEmptyOrWhitespace(this.agencyViewModel.model.address1);
        const isCountyValid = !isEmptyOrWhitespace(this.agencyViewModel.model.county);
        const isStateValid = !isEmptyOrWhitespace(this.agencyViewModel.model.state);
        const isZipCodeValid =
            !isEmptyOrWhitespace(this.agencyViewModel.model.zipCode) &&
            this.agencyViewModel.model.zipCode.length == 5 &&
            parseInt(this.agencyViewModel.model.zipCode) !== NaN;

        const isAgencyModelValid = isAgencyNameValid && isAgencyPrincipalValid && isAddress1Valid && isCountyValid && isStateValid && isZipCodeValid;

        // Check validity of Contact viewModel.
        // Rules:
        const isFirstNameValid = !isEmptyOrWhitespace(this.contactViewModel.model.firstName);
        const isLastNameValid = !isEmptyOrWhitespace(this.contactViewModel.model.lastName);
        const isPhoneNumberValid = !isEmptyOrWhitespace(this.contactViewModel.model.tel);
        const isEmailValid = !isEmptyOrWhitespace(this.contactViewModel.model.email);

        const isContactModelValid = isFirstNameValid && isLastNameValid && isPhoneNumberValid && isEmailValid;

        // Check validity of this ViewModal.
        // Rules:
        const isPasswordValid = !isEmptyOrWhitespace(this.model.password);
        const isConfirmPassword = this.model.confirmPassword === this.model.password;
        const isTermsAndConditionsValid = this.model.hasAcceptedTerms;
        //const isRecaptchaValid = this.model.passCaptcha;

        const isModelValid = isPasswordValid && isConfirmPassword && isTermsAndConditionsValid;
        // && isRecaptchaValid;

        // All models must be valid.
        return isAgencyModelValid && isContactModelValid && isModelValid;
    }

    public async updateProAccountDetails(newPassword: string) {
        const response = await this.Post<AgencyUserDTO>(AppUrls.Server.Api.Account.UpdateProAccountDetails, {
            ...this.model,
            ...this.contactViewModel.model,
            emailAddress: this.contactViewModel.model.email,
            phoneNumber: this.contactViewModel.model.tel,
            confirmPassword: newPassword,
            password: newPassword,
        });

        if (response && response.wasSuccessful) {
            runInAction(() => {
                StoresInstance.domain.AccountStore.FirstName = response.payload.firstName;
                StoresInstance.domain.AccountStore.LastName = response.payload.lastName;
                StoresInstance.domain.AccountStore.Email = response.payload.email;
                StoresInstance.domain.AccountStore.Phone = response.payload.phoneNumber;
                StoresInstance.domain.AccountStore.Terms = response.payload.hasAcceptedTerms;
                StoresInstance.domain.AccountStore.AgencyName = response.payload.agencyName;
            });
        }

        return response;
    }

    public isFieldValid(fieldName: keyof FieldType<ProRegisterModel>, value: any): boolean {
        let { isValid, errorMessage } = this.validateDecorators(fieldName as any);

        if (isValid && fieldName === "confirmPassword") {
            errorMessage = this.isConfirmPasswordValid;
            isValid = errorMessage === "";
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public get isConfirmPasswordValid(): string {
        if (this.getValue("password") !== this.getValue("confirmPassword") && this.getValue("password") !== "") return "Passwords must match";
        return "";
    }

    public checkModelValidity() {
        const me = this.isModelValid();
        const sub = this.contactViewModel.isModelValid();
        const sub2 = this.agencyViewModel.isModelValid();

        return me && sub && sub2;
    }

    public get agencyDetails() {
        return this.agencyViewModel;
    }

    /**
     * Populates ContactModel from the AccountStore
     */
    @action
    public fillContactInfo = () => {
        let domainStores = StoresInstance.domain;
        this.contactViewModel.model.tel = domainStores.AccountStore.Phone;
        this.contactViewModel.model.firstName = domainStores.AccountStore.FirstName;
        this.contactViewModel.model.lastName = domainStores.AccountStore.LastName;
        this.contactViewModel.model.email = domainStores.AccountStore.Email;
    };

    @action
    public setCaptchaToken(token: string) {
        this.captchaToken = token;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
