import { makeStyles } from "@material-ui/core";

/**
 * Styles for use with the admin list pages.
 */
export const useListStyles = makeStyles(theme => ({
    title: {
        display: "flex",
        alignItems: "center",
    },
    addItem: {
        display: "flex",
        alignItems: "center",
        justifyContent: "right",
    },
    button: {
        width: 120,
    },
}));
