import * as MobX from "mobx";

export class ChosenQuoteForPDFModel {
    @MobX.observable
    public base64Header: string = "";

    @MobX.observable
    public base64InsurerLogo: string = "";

    @MobX.observable
    public base64AgencyRealtorLogo: string = "";

    @MobX.observable
    public tel: string = "";

    @MobX.observable
    public firstName: string = "";

    @MobX.observable
    public lastName: string = "";

    @MobX.observable
    public emailAddress: string = "";

    @MobX.observable
    public insurerName: string = "";

    @MobX.observable
    public policyName: string = "";

    @MobX.observable
    public policyQuoteReference: string = "";

    @MobX.observable
    public policyStartDate: string = "";

    @MobX.observable
    public propertyAddress: string = "";

    @MobX.observable
    public propertyConstructionType: string = "";

    @MobX.observable
    public propertyConstructionYear: string = "";

    @MobX.observable
    public propertySize: string = "";

    @MobX.observable
    public propertyType: string = "";

    @MobX.observable
    public buildingCoverage: string = "";

    @MobX.observable
    public contentsCoverage: string = "";

    @MobX.observable
    public otherStructures: string = "";

    @MobX.observable
    public lossOfUse: string = "";

    @MobX.observable
    public deductible: string = "";

    @MobX.observable
    public premium: string = "";

    @MobX.observable
    public taxes: string = "";

    @MobX.observable
    public fees: string = "";

    @MobX.observable
    public total: string = "";

    @MobX.observable
    public floodZone: number = 0;

    @MobX.observable
    public isOverWater: string = "";

    @MobX.observable
    public isMobileHome: string = "";

    @MobX.observable
    public hasGarage: string = "";

    @MobX.observable
    public foundationType: string = "";

    @MobX.observable
    public basementUsage: string = "";

    @MobX.observable
    public isNewProperty: string = "";

    @MobX.observable
    public dateEffective: string = "";

    @MobX.observable
    public ownershipType: string = "";

    @MobX.observable
    public propertyOccupy: string = "";

    @MobX.observable
    public insurancePolicy: string = "";

    @MobX.observable
    public elevationCertificateUrl: string = "";

    @MobX.observable
    public attachmentMarkUp: string = "";

    @MobX.observable
    public agentTel: string = "";

    @MobX.observable
    public agentFirstName: string = "";

    @MobX.observable
    public agentLastName: string = "";

    @MobX.observable
    public agencyName: string = "";

    @MobX.observable
    public quoteName: string = "";

    @MobX.observable
    public quoteInstruction: string = "";

    @MobX.observable
    public quoteMisc: string = "";

    @MobX.observable
    public isPro: boolean = false;

    @MobX.observable
    public isMFI: boolean = false;

    @MobX.observable
    public policyComment: string = "";
}
