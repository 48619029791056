import { TextField, Theme } from "@material-ui/core";

import { EditableInput } from "Core/Components/EditableInput";
import { FieldType } from "Core/Utils/Utils";
import { IViewModel } from "Core/ViewModels";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { styled } from "../../Globals/Base";
import { useMediaQuery as useMuiMediaQuery } from "@material-ui/core";

interface IProps<T> {
    viewModel: IViewModel<T>;
    fieldName: keyof FieldType<T>;
}

export function StyledYearPicker<T>(props: IProps<T>) {
    const { viewModel, fieldName } = props;
    const mdMatches = useMuiMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

    return (
        <Container mdMatches={mdMatches}>
            <EditableInput
                viewModel={viewModel}
                fieldName={fieldName}
                type="date"
                validateOnBlur={false}
                inputProps={{
                    format: "YYYY",
                    placeholder: "yyyy",
                    mask: "____",
                    inputVariant: "outlined",
                    views: ["year"],
                }}
            />
        </Container>
    );
}

interface IContainerProps {
    mdMatches?: boolean;
}

const Container = styled.div<IContainerProps>`
    display: flex;
    align-items: center;

    > div {
        margin: 4px 0;
    }

    div.MuiFormControl-root {
        width: 152px;

        input,
        > div > div {
            z-index: 1;
        }

        input {
            font-size: 18px;
            height: 22px;
            color: white;
            padding: 7px 0 5px 10px;
        }

        div:not(.Mui-error) {
            fieldset {
                border-color: #8cd2f7 !important;
            }
        }

        fieldset {
            background-color: rgba(255, 255, 255, 0.4);
            border-radius: 2px;
        }

        div.MuiInputAdornment-root,
        div.MuiOutlinedInput-adornedEnd {
            padding: 0;
        }

        svg {
            width: 0.7em;
            height: 0.7em;
            color: white;
        }
    }

    .MuiBox-root {
        ${props => (props.mdMatches ? "margin: -2px 0 0;" : "")}
    }
`;
