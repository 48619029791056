import { Button } from "@material-ui/core";
import { styled } from "../../Globals/Base";

export const Container = styled.div`
    > p {
        color: white;
        text-align: center;
        width: 100%;
        font-size: calc(10px + (16 - 10) * ((100vw - 350px) / (1366 - 350)));

        @media (max-width: 767px) {
            letter-spacing: -0.2px;
        }

        @media (min-width: 768px) {
            font-size: 14px;
        }

        span {
            font-weight: bold;
            color: #8cd2f7;
        }
    }

    .Mui-error {
        text-align: right;

        @media (max-width: 767px) {
            text-align: left;
        }
    }

    .error {
        font-size: 0.75rem;
        text-align: right;
        color: #f44336;

        @media (max-width: 767px) {
            text-align: left;
            font-size: 10px;
        }
    }

    .questions-2 {
        max-width: none;

        @media (max-width: 767px) {
            margin-bottom: 10px;
            margin-top: 6px;
        }

        + h3 {
            max-width: none;
        }
    }

    .questions-2:not(.no-top-margin) {
        margin-top: 50px;

        @media (max-width: 767px) {
            margin-top: 10px;
        }
    }

    .MuiInput-underline:before,
    .MuiInput-underline:after {
        content: none !important;
    }

    .MuiSelect-select.MuiSelect-select {
        padding: 15px 37px 14px 20px;

        @media (max-width: 767px) {
            padding: 5px 35px 5px 5px;
            font-size: 0.875rem;
        }
    }

    svg.MuiSelect-icon {
        margin: 0 20px 0 8px;
        top: auto;
    }

    .MuiSelect-select {
        text-align: center;
        font-weight: bold;
        font-size: 18px;
    }

    .MuiFormHelperText-contained {
        margin-right: 7px;
        margin-left: 7px;

        @media (max-width: 767px) {
            margin-left: 3px;
            margin-right: 3px;
        }
    }

    @media (max-width: 767px) {
        .something-not-right {
            display: block;
        }
    }

    div.log-me-in {
        p {
            text-align: center;
            font-size: 1.125rem;
            font-weight: bold;
            color: white;

            a {
                color: #129bdb;
            }
        }
    }
`;

export const EmailExistsContainer = styled.div`
    div.emailexists-main {
        display: flex;
        align-items: center;
        margin: 30px 0;
        flex-direction: column;

        .emailexists-btn {
            padding: 0 300px;
            min-width: 100%;

            @media (max-width: 767px) {
                padding: 0;
            }

            > button {
                background-color: #129bdb;
                padding: 27px 40px 26px;
                text-transform: none;
                border-radius: 100px;
                width: 100%;
                margin: 0;
                color: white;
                font-weight: bold;
                font-size: 1.5rem;
                white-space: nowrap;
                line-height: 100%;

                @media (max-width: 767px) {
                    font-size: 1.25rem;
                    white-space: initial;
                    padding: 22px 40px 21px;
                }
            }
        }

        .emailexists-login-btn {
            > button {
                padding: 17px 0 16px;
            }
        }

        .emailexists-or {
            margin: 26px 0;
            display: flex;
            width: 100%;
            font-size: 1.5rem;
            font-weight: bold;
            align-items: center;

            .emailexists-or-txt {
                margin: 0 20px;
            }

            .emailexists-line {
                height: 1px;
                flex-grow: 1;
                background-color: #129bdb;
            }
        }
    }
`;

export const SubmitButton = styled(Button)`
    border-radius: 80px;
    background-color: #129bdb;
    color: white;
    padding: 23px 60px 24px;
    text-align: center;
    display: block;
    margin: 40px auto 100px;

    :hover {
        background-color: #0f89c2;
    }

    span {
        display: block;

        p {
            margin: 0;
            font-weight: bold;
            line-height: 37px;
            letter-spacing: -1px;

            :first-of-type {
                font-size: 32px;
            }

            :last-of-type {
                font-size: 24px;
            }
        }
    }

    &.Mui-disabled {
        background-color: #129bdb;
        opacity: 0.4;

        span.MuiButton-label {
            color: #ffffff;
        }
    }
`;

export const AgencyButton: any = styled(SubmitButton)`
    background-color: #ffffff;
    padding: 15px 60px;
    :hover {
        background-color: #ffffff;
    }
    span p,
    p {
        letter-spacing: -0.6px;
        line-height: 1;
        color: #24313c;
        span {
            color: #129bdb;
        }
    }

    &.Mui-disabled {
        background-color: #f8fdff;
    }
`;
