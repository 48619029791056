// Libraries
import clsx from "clsx";
import { useObserver } from "mobx-react-lite";
import React, { useState, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useRouter } from "Core/Utils";
import { AppBar, Link, Toolbar, Button, Drawer, List, Box } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles, useTheme } from "@material-ui/styles";

// Core
import { useMediaQuery } from "../../Core/Utils/Media";
import { getHistory } from "../../Core/Utils/Utils";

// Custom
import MFIheaderlogo from "Content/logoForDarkBG.png";
import MFIheaderlogoBETA from "Content/logo-beta.png";

import { StoresContext, Stores, StoresInstance } from "../Stores";
import { GridSpaced } from "../StylesAppSpecific/GridHelperStyling";
import { HeaderLogo, HeaderLinks } from "../StylesAppSpecific/PageStyling";
import { styled } from "../Globals/Base";

import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { fade } from "@material-ui/core/styles/colorManipulator";

import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import MailIcon from "@material-ui/icons/Mail";

import Divider from "@material-ui/core/Divider";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import iconProStar from "Content/icon-ProStar.svg";

const StyledIconButton = styled(IconButton)`
    padding: 0;
    color: white;
`;

const DrawerList = styled(List)`
    button,
    a {
        width: 100%;
    }

    .MuiTypography-root {
        text-align: center;
        color: white;
        font-weight: bold;
    }
`;

export const Header: React.FC = () => {
    const isMobile = !!useMediaQuery("screen and (max-width: 767px)");
    const { history, location, match } = useRouter();

    let cssStr: string = isMobile ? "font-size: 12px; font-weight: 700;" : "text-align: left; font-size: 14px; font-weight: 700;";

    let navStr: string = isMobile ? "" : "text-align: right;";

    const [open, setOpen] = useState(false);
    const storesContext = useContext<Stores>(StoresContext);

    const handleDrawerOpen = () => setOpen(true);
    const handleDrawerClose = () => setOpen(false);

    const adminOptions = [
        <Link component={RouterLink} onClick={handleDrawerClose} to="/contact">
            Contact
        </Link>,
        <Link component={RouterLink} onClick={handleDrawerClose} to="/dashboard">
            My quotes dashboard
        </Link>,
        <Link component={RouterLink} onClick={handleDrawerClose} to="/admin">
            Admin dashboard
        </Link>,
        <Link
            style={{ cursor: "pointer" }}
            onClick={() => {
                storesContext.domain.AccountStore.Logout(() => {
                    let domainStores = StoresInstance.domain;

                    const { model: quoteRequestModelV2, contactViewModel } = domainStores.QuoteRequestViewModel;
                    quoteRequestModelV2.reset();
                    quoteRequestModelV2.resetDetails();
                    contactViewModel.model.resetContact();

                    handleDrawerClose();
                    history.push("/");
                });
            }}
        >
            Logout
        </Link>,
    ];

    const loggedInOptions = [
        <Link component={RouterLink} onClick={handleDrawerClose} to="/dashboard">
            My quotes dashboard
        </Link>,
        <Link component={RouterLink} onClick={handleDrawerClose} to="/editdetails">
            My Account
        </Link>,
        <Link component={RouterLink} onClick={handleDrawerClose} to="/contact">
            Contact
        </Link>,
        <Link
            style={{ cursor: "pointer" }}
            onClick={() => {
                storesContext.domain.AccountStore.Logout(() => {
                    let domainStores = StoresInstance.domain;

                    const { model: quoteRequestModelV2, contactViewModel } = domainStores.QuoteRequestViewModel;
                    quoteRequestModelV2.reset();
                    quoteRequestModelV2.resetDetails();
                    contactViewModel.model.resetContact();

                    handleDrawerClose();
                    history.push("/");
                });
            }}
        >
            Logout
        </Link>,
    ];

    const loggedInProOptions = [
        <Link component={RouterLink} onClick={handleDrawerClose} to="/agent">
            Dashboard
        </Link>,
        <Link component={RouterLink} onClick={handleDrawerClose} to="/agent/myquotes">
            Quotes
        </Link>,
        <Link component={RouterLink} onClick={handleDrawerClose} to="/agent/report">
            Reports
        </Link>,
        <Link component={RouterLink} onClick={handleDrawerClose} to="/agent/profile">
            My Account
        </Link>,
        <Link component={RouterLink} onClick={handleDrawerClose} to="/contact">
            Contact
        </Link>,
        <Link
            style={{ cursor: "pointer" }}
            onClick={() => {
                storesContext.domain.AccountStore.Logout(() => {
                    let domainStores = StoresInstance.domain;

                    const { model: quoteRequestModelV2, contactViewModel } = domainStores.QuoteRequestViewModel;
                    quoteRequestModelV2.reset();
                    quoteRequestModelV2.resetDetails();
                    contactViewModel.model.resetContact();

                    handleDrawerClose();
                    history.push("/");
                });
            }}
        >
            Logout
        </Link>,
    ];

    const loggedInAdminProOptions = [
        <Link component={RouterLink} onClick={handleDrawerClose} to="/agent">
            Dashboard
        </Link>,
        <Link component={RouterLink} onClick={handleDrawerClose} to="/agent/myquotes">
            Quotes
        </Link>,
        <Link component={RouterLink} onClick={handleDrawerClose} to="/agent/report">
            Reports
        </Link>,
        <Link component={RouterLink} onClick={handleDrawerClose} to="/admin">
            Admin
        </Link>,
        <Link component={RouterLink} onClick={handleDrawerClose} to="/agent/profile">
            My Account
        </Link>,
        <Link component={RouterLink} onClick={handleDrawerClose} to="/contact">
            Contact
        </Link>,
        <Link
            style={{ cursor: "pointer" }}
            onClick={() => {
                storesContext.domain.AccountStore.Logout(() => {
                    let domainStores = StoresInstance.domain;

                    const { model: quoteRequestModelV2, contactViewModel } = domainStores.QuoteRequestViewModel;
                    quoteRequestModelV2.reset();
                    quoteRequestModelV2.resetDetails();
                    contactViewModel.model.resetContact();

                    handleDrawerClose();
                    history.push("/");
                });
            }}
        >
            Logout
        </Link>,
    ];

    const loggedOutOptions = [
        <Link component={RouterLink} onClick={handleDrawerClose} to="/contact">
            Contact
        </Link>,
        <Link component={RouterLink} onClick={handleDrawerClose} to="/login">
            Login
        </Link>,
    ];

    let headerOptions = () => {
        if (storesContext.domain.AccountStore.IsLoggedIn) {
            if (storesContext.domain.AccountStore.isInRole("admin") || storesContext.domain.AccountStore.isInRole("producer")) {
                return adminOptions;
            } else {
                if (storesContext.domain.AccountStore.IsProAccount) {
                    return loggedInProOptions;
                } else {
                    return loggedInOptions;
                }
            }
        } else {
            return loggedOutOptions;
        }
    };

    let newHeaderOptions = () => {
        if (storesContext.domain.AccountStore.IsLoggedIn) {
            if (storesContext.domain.AccountStore.isInRole("admin")) {
                if (storesContext.domain.AccountStore.IsProAccount){
                    console.log("Admin and Pro")
                    return loggedInAdminProOptions;
                } else {
                    return adminOptions;
                }
            } else {
                if (storesContext.domain.AccountStore.IsProAccount) {
                    return loggedInProOptions;
                } else {
                    return loggedInOptions;
                }
            }
        } else {
            return loggedOutOptions;
        }
    };

    const canSeeProAdvert = () => {
        let retVal = true;
        if (
            StoresInstance.domain.AccountStore.IsProAccount ||
            StoresInstance.domain.AccountStore.isInRole("admin") ||
            StoresInstance.domain.AccountStore.isInRole("producer")
        ) {
            retVal = false;
        }
        if (StoresInstance.domain.AccountStore.IsRealtorAccount) {
            retVal = false;
        }

        return retVal;
    };

    const renderProAdvert = (
        <>
            {isMobile ? (
                <>
                    <GridSpaced additionalcss={isMobile ? "display: flex; flex-direction: column; justify-content: center; flex: 1;" : ""}>
                        <ProSection onClick={() => history.push("/register")}>
                            <div>
                                <h3 className="top">Insurance Agent?</h3>
                                <h3>Sign up now for free</h3>
                            </div>
                        </ProSection>
                    </GridSpaced>
                </>
            ) : (
                <>
                    <GridSpaced>
                        <ProSection onClick={() => history.push("/register")}>
                            <img src={iconProStar} alt="" />
                            <div>
                                <h3 className="top">Are you an Insurance Agent?</h3>
                                <h3>Sign up now for a free Pro account &gt;</h3>
                            </div>
                        </ProSection>
                    </GridSpaced>
                </>
            )}
        </>
    );

    return useObserver(() => (
        <>
            {(window as any).IE11 ? (
                <></>
            ) : (
                <>
                    <AppBar>
                        <Toolbar>
                            <GridSpaced container margin={"0px auto"} additionalcss={"max-width: 1124px; width: 100%;"}>
                                <GridSpaced item xs={isMobile || 5} sm={isMobile || 3}>
                                    <Link component={RouterLink} title={"My Flood Insurance"} to={"/"}>
                                        <HeaderLogo src={MFIheaderlogo} alt={"My Flood Insurance Logo"} />
                                    </Link>
                                </GridSpaced>
                                {canSeeProAdvert() && renderProAdvert}
                                <GridSpaced item xs={!isMobile || undefined} padding={"10px 0px"} additionalcss={navStr}>
                                    {isMobile ? (
                                        <StyledIconButton color="primary" aria-label="Open drawer" onClick={handleDrawerOpen}>
                                            <MenuIcon />
                                        </StyledIconButton>
                                    ) : (
                                        <>
                                            <HeaderLinks>
                                                {newHeaderOptions().map((opt, index) => (
                                                    <span key={`opt-${index}`}>{opt}</span>
                                                ))}
                                            </HeaderLinks>
                                        </>
                                    )}
                                </GridSpaced>
                            </GridSpaced>
                        </Toolbar>
                    </AppBar>
                    <Drawer PaperProps={{ style: { backgroundColor: "black" } }} anchor="right" onClose={handleDrawerClose} open={open}>
                        <div style={{ display: "block", height: "56px" }}>
                            <IconButton onClick={handleDrawerClose} style={{ display: "block", marginLeft: "auto", height: "100%" }}>
                                <ChevronRightIcon style={{ color: "white" }} />
                            </IconButton>
                        </div>
                        <Divider style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }} />
                        <DrawerList>
                            {newHeaderOptions().map((opt, index) => (
                                <ListItem key={`opt-${index}`}>{opt}</ListItem>
                            ))}
                        </DrawerList>
                    </Drawer>
                </>
            )}
        </>
    ));
};

const ProSection = styled.div`
    display: flex;
    cursor: pointer;
    img {
        padding: 0 10px;
    }

    :hover {
        h3:not(.top) {
            text-decoration: underline;
        }
    }

    div {
        font-size: 0.75rem;

        h3 {
            margin: 0;

            @media (max-width: 767px) {
                font-size: 12px;
                line-height: 15px;
                letter-spacing: -0.3px;
            }
        }

        h3.top {
            color: #8cd2f7;
        }
    }
`;
