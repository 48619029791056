import React from "react";
import { TabsCustom, useAdminStyles } from "../StylesAppSpecific/AdminStyling";
import { useRouter } from "../../Core/Utils/Hooks/UseReactRouter";
import { Tab } from "@material-ui/core";

interface IProps {
    value: number;
    onChange?: (newValue: number) => void;
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export const AdminTabs: React.FC<IProps> = ({ value, onChange: handleChange }) => {
    const classes = useAdminStyles();
    const { history } = useRouter();

    return (
        <TabsCustom
            value={value}
            onChange={handleChange !== undefined ? (event: React.ChangeEvent<{}>, newValue: number) => handleChange(newValue) : undefined}
            aria-label="simple tabs example"
            className={classes.tabsWidth}
        >
            <Tab label="Dashboard" {...a11yProps(0)} onClick={handleChange === undefined ? () => history.push(`/admin`) : undefined} />
            <Tab label="Manage users" {...a11yProps(1)} onClick={handleChange === undefined ? () => history.push(`/admin/userslist`) : undefined} />
            <Tab label="Manage widgets" {...a11yProps(2)} onClick={handleChange === undefined ? () => history.push(`/admin/widgetslist`) : undefined} />
            <Tab label="Manage States" {...a11yProps(3)} onClick={handleChange === undefined ? () => history.push(`/admin/editstate`) : undefined} />
            <Tab label="Manage Cities" {...a11yProps(4)} onClick={handleChange === undefined ? () => history.push(`/admin/editcity`) : undefined} />
        </TabsCustom>
    );
};
