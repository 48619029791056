import * as MobX from "mobx";

import { ModelBase } from "../../Core/Models/ModelBase";

export class PreviousPropertyQuoteModel extends ModelBase<PreviousPropertyQuoteModel, PreviousPropertyQuoteModelDTO> {
    @MobX.observable
    public id: string | undefined = undefined;

    @MobX.observable
    public propertyQuoteId: string = "";

    @MobX.observable
    public createdDate: string = "";

    @MobX.observable
    public propertyId: string = "";

    @MobX.observable
    public addressLine1: string = "";

    @MobX.observable
    public addressLine2: string = "";

    @MobX.observable
    public city: string = "";

    @MobX.observable
    public state: string = "";

    @MobX.observable
    public zipcode: string = "";

    @MobX.observable
    public firstName: string = "";

    @MobX.observable
    public lastName: string = "";

    @MobX.observable
    public email: string = "";

    @MobX.observable
    public phone: string = "";

    @MobX.action
    public fromDto = (model: PreviousPropertyQuoteModelDTO): void => {
        this.id = model.id;
        this.propertyQuoteId = model.propertyQuoteId;
        this.createdDate = model.createdDate;
        this.propertyId = model.propertyId;
        this.addressLine1 = model.addressLine1;
        this.addressLine2 = model.addressLine2;
        this.city = model.city;
        this.state = model.state;
        this.zipcode = model.zipcode;
        this.firstName = model.firstName;
        this.lastName = model.lastName;
        this.email = model.email;
        this.phone = model.phone;
    };

    public toDto = (): PreviousPropertyQuoteModelDTO => {
        return {
            id: this.id,
            propertyQuoteId: this.propertyQuoteId,
            createdDate: this.createdDate,
            propertyId: this.propertyId,
            addressLine1: this.addressLine1,
            addressLine2: this.addressLine2,
            city: this.city,
            state: this.state,
            zipcode: this.zipcode,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            phone: this.phone,
        };
    };

    @MobX.action
    public reset = () => {
        this.id = undefined;
        this.propertyQuoteId = "";
        this.createdDate = "";
        this.propertyId = "";
        this.addressLine1 = "";
        this.addressLine2 = "";
        this.city = "";
        this.state = "";
        this.zipcode = "";
        this.firstName = "";
        this.lastName = "";
        this.email = "";
        this.phone = "";
    };
}

export interface PreviousPropertyQuoteModelDTO {
    id?: string;
    propertyQuoteId: string;
    createdDate: string;
    propertyId: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipcode: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
}

export interface DashboardModelDTO {
    previousPropertyQuotes: PreviousPropertyQuoteModelDTO[];
    firstName: string;
}
