import React from "react";
import { Button } from "@material-ui/core";
import styled from "styled-components";

import rerunQuote from "Content/rerunQuote.svg";

/* backgroundColor: "rgb(0 0 0 / 10%) !important",
		boxShadow: "none !important", */

interface IReRunBtnProps {
    spinRerun?: boolean;
    margin?: string;
}

const ReRunBtn = styled(Button)<IReRunBtnProps>`
    background-color: ${props => (props.spinRerun ? "rgb(0 0 0 / 10%)" : "#129bdb")};
    border-radius: 50px;
    padding: 16px 30px;
    margin: ${props => (props.margin ? props.margin : "-8px -10px -8px 20px")};
    white-space: nowrap;
    color: #fff;
    font-size: 18px;
    letter-spacing: -0.9px;
    line-height: 22px;
    font-weight: bold;
    box-shadow: ${props => (props.spinRerun ? "none !important" : "")};

    :hover {
        background-color: ${props => (props.spinRerun ? "rgb(0 0 0 / 10%)" : "#0f89c2")};
    }

    :disabled {
        background-color: ${props => (props.spinRerun ? "rgb(0 0 0 / 10%)" : "rgb(35 69 146 / 20%)")};
        color: rgb(255 255 255 / 70%);
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    }

    .rerun-spin-img {
        opacity: ${props => (props.disabled ? "0.7" : "")};
    }
`;

interface IProps {
    onClick: () => void;
    disabled?: boolean;
    spinRerun?: boolean;
    margin?: string;
}

export const ReRunButton: React.FC<IProps> = ({ onClick, disabled, spinRerun, margin }) => {
    const spinnerRef = React.useRef<HTMLImageElement | null>(null);
    const spinRerunRef = React.useRef(spinRerun || false);
    const frameIdRef = React.useRef(-1);
    const doSpin = () => {
        if (frameIdRef.current >= 0) {
            cancelAnimationFrame(frameIdRef.current);
        }
        let deg = 0;

        const doRotation = () => {
            deg += 5;
            spinnerRef.current!.style.transform = `rotate(${deg}deg)`;
            if (deg !== 360) {
                frameIdRef.current = requestAnimationFrame(() => doRotation());
            } else {
                deg = 0;
                if (spinRerunRef.current) {
                    frameIdRef.current = requestAnimationFrame(() => doRotation());
                }
            }
        };

        frameIdRef.current = requestAnimationFrame(() => doRotation());
    };

    React.useEffect(() => {
        spinRerunRef.current = spinRerun || false;
        if (spinnerRef.current && spinRerun) {
            doSpin();
        }
    }, [spinRerun, spinnerRef.current]);

    return (
        <ReRunBtn
            margin={margin}
            spinRerun={spinRerun}
            variant="contained"
            disabled={disabled}
            startIcon={<img src={rerunQuote} ref={spinnerRef} className="rerun-spin-img" />}
            onClick={onClick}
        >
            Rerun with additional carriers
        </ReRunBtn>
    );
};
