import * as MobX from "mobx";
import { ModelBase } from "../../Core/Models/ModelBase";
import { ResultQuoteModel } from "./ResultQuoteModel";

export class ResultModel extends ModelBase<ResultModel, ResultModelDTO> {
    @MobX.observable
    public date: string = "";

    @MobX.observable
    public quoteNumber: string = "";

    @MobX.observable
    public ineligibilityReview: boolean = false;

    @MobX.observable
    public warnings: string[] = [];

    @MobX.observable
    public quotes: ResultQuoteModel[] = [];

    @MobX.observable
    public finalPage: string = "";

    public fromDto = (model: ResultModelDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                this[key] = model[key];
            }
        }
    };
    public toDto = (model: ResultModel) => {};
}

export interface ResultModelDTO {
    date: string;
    quoteNumber: string;
    ineligibilityReview: boolean;
    warnings: string[];
    quotes: ResultQuoteModel[];
    finalPage: string;
}
