// Libraries
import React from "react";
import { Box, Typography, Paper } from "@material-ui/core";

// Custom
import { useInsurerStatisticsBodyStyles } from "../../StylesAppSpecific/Admin/AdminDashboard";

interface IProps {
    image: string;
    title: string;
    text: [any, any];
}

export const InsurerStatisticCard: React.FC<IProps> = (props: IProps) => {
    const classes = useInsurerStatisticsBodyStyles();

    return (
        <Paper className={classes.paper}>
            <Box className={classes.imageContainer}>
                <Box style={{ backgroundImage: `url(${props.image})` }} />
            </Box>
            <Box className={classes.textContainer}>
                <Typography className={classes.title} component="div">
                    {props.title}
                </Typography>
                <Typography className={classes.text} component="div">
                    {props.text[1]} / {props.text[0]}
                </Typography>
            </Box>
        </Paper>
    );
};
