// Libraries
import React, { useState, useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { Box, Checkbox, FormControlLabel } from "@material-ui/core";

// Core
import { EditableInput } from "../../../Core/Components/EditableInput";

// Custom
import {
    PolicyHolderRegistrationContainer,
    PolicyHolderRegistrationItem,
    PolicyHolderRegistrationInput,
    PolicyHolderRegistrationInputRow,
    PolicyHolderRegistrationTitle,
} from "./PolicyHolderRegistration.Style";
import { QuoteRequestModelV2 } from "../../Models";
import { QuoteRequestViewModel } from "../../ViewModels";
import { ViewModel } from "mobx-utils";

interface IProps {
    viewModel: QuoteRequestViewModel;
}

export const PolicyHolder: React.FC<IProps> = props => {
    return useObserver(() => (
        <PolicyHolderRegistrationContainer>
            <PolicyHolderRegistrationItem>
                <PolicyHolderRegistrationTitle>What is the policy holder's name?</PolicyHolderRegistrationTitle>
                <PolicyHolderRegistrationInputRow>
                    <PolicyHolderRegistrationInput
                        isValid={props.viewModel.isFieldValid("policyHolderFirstName", props.viewModel.getValue("policyHolderFirstName"))}
                    >
                        <EditableInput<QuoteRequestModelV2>
                            viewModel={props.viewModel}
                            type="text"
                            fieldName="policyHolderFirstName"
                            fullwidth
                            inputProps={{ placeholder: "Your first name *", autoComplete: "new-password" }}
                        />
                    </PolicyHolderRegistrationInput>
                    <PolicyHolderRegistrationInput
                        isValid={props.viewModel.isFieldValid("policyHolderLastName", props.viewModel.getValue("policyHolderLastName"))}
                    >
                        <EditableInput<QuoteRequestModelV2>
                            viewModel={props.viewModel}
                            type="text"
                            fieldName="policyHolderLastName"
                            fullwidth
                            inputProps={{ placeholder: "Your last name *", autoComplete: "new-password" }}
                        />
                    </PolicyHolderRegistrationInput>
                </PolicyHolderRegistrationInputRow>
            </PolicyHolderRegistrationItem>
            <PolicyHolderRegistrationItem>
                <PolicyHolderRegistrationTitle>Please add their contact details</PolicyHolderRegistrationTitle>
                <PolicyHolderRegistrationInputRow>
                    <PolicyHolderRegistrationInput
                        isValid={props.viewModel.isFieldValid("policyHolderPhoneNumber", props.viewModel.getValue("policyHolderPhoneNumber"))}
                    >
                        <EditableInput<QuoteRequestModelV2>
                            viewModel={props.viewModel}
                            type="text"
                            fieldName="policyHolderPhoneNumber"
                            fullwidth
                            inputProps={{ placeholder: "Contact phone number eg. 123-456 - 7890 *", autoComplete: "new-password" }}
                        />
                    </PolicyHolderRegistrationInput>
                    <PolicyHolderRegistrationInput isValid={props.viewModel.isFieldValid("policyHolderEmail", props.viewModel.getValue("policyHolderEmail"))}>
                        <EditableInput<QuoteRequestModelV2>
                            viewModel={props.viewModel}
                            type="text"
                            fieldName="policyHolderEmail"
                            fullwidth
                            inputProps={{ placeholder: "Contact email address *", autoComplete: "new-password" }}
                        />
                    </PolicyHolderRegistrationInput>
                </PolicyHolderRegistrationInputRow>
            </PolicyHolderRegistrationItem>
        </PolicyHolderRegistrationContainer>
    ));
};
