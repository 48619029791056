import React, { useState, useRef } from "react";
import { Button } from "@material-ui/core";
import styled from "styled-components";
import { NFIPartnerAgreement } from "../Pro/NFIPartnerAgreement";
import { NFIPartnerAgreementViewModel, QuoteResultViewModel } from "../../ViewModels";
import SignatureCanvas from "react-signature-canvas";
import { PartnerContainer } from "Custom/StylesAppSpecific/CarrierSettingsStyling";
import { useRouter } from "Core/Utils";
import { useObserver } from "mobx-react-lite";
import { CarrierSettingsViewModel } from "Custom/ViewModels/CarrierSettingsViewModel";

interface IProps {
    goBack: () => void;
    path?: string;
    resultViewModel: QuoteResultViewModel;
}

export const ResultNFIAgreement: React.FC<IProps> = ({ goBack, path, resultViewModel }) => {
    const [viewModel] = useState(() => new NFIPartnerAgreementViewModel());
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const signatureRef = useRef<SignatureCanvas>() as React.RefObject<SignatureCanvas>;
    const { history } = useRouter();

    const handleSubmit = async () => {
        if (viewModel.isModelValid()) {
            if (signatureRef.current!.toData().length <= 0) {
                setErrorMessage("You must sign this agreement");
                return;
            }

            const dataUrl = signatureRef.current!.toDataURL();

            setIsLoading(true);
            const charBytes = atob(dataUrl.split(",")[1]);
            const imageBytes = [];
            for (let i = 0; i < charBytes.length; i++) {
                imageBytes[i] = charBytes.charCodeAt(i);
            }
            const byteArray = new Uint8Array(imageBytes);
            const imageBlob = new Blob([byteArray], { type: "image/png" });

            const carrierVM = new CarrierSettingsViewModel();

            /*             
            console.log("carriers");
            console.log(carrierVM.userAgencyDetails.slice()); */
            carrierVM.setPayload();
            /* 
            console.log("carriers after Payload");
            console.log(carrierVM.userAgencyDetails.slice());
            */
            for (const detail of resultViewModel.apiDetails) {
                carrierVM.userAgencyDetails = carrierVM.userAgencyDetails.filter(d => d.insurerName !== detail.insurerName);
                //console.log("Insurer " + detail.insurerName);
                console.log("carriers after inside loop");
                console.log(carrierVM.userAgencyDetails.slice());
            }
            /* 
            console.log("carriers after For loop");
            console.log(carrierVM.userAgencyDetails.slice());
            */
            carrierVM.userAgencyDetails.push(...resultViewModel.apiDetails.map(d => d.toDto()));
            /* 
            console.log("carriers after Push");
            console.log(carrierVM.userAgencyDetails.slice());
            */
            const upsertResult = await carrierVM.upsert();

            if (upsertResult && upsertResult.wasSuccessful) {
                resultViewModel.handleAPIDetails(upsertResult.payload);
                const result = await viewModel.accept(imageBlob);
                setIsLoading(false);
                if (result && result.wasSuccessful) {
                    history.push(path || `/agent`);
                } else if (result.errors && result.errors[0] && result.errors[0].message) {
                    setErrorMessage("Error: " + result.errors[0].message);
                } else {
                    setErrorMessage("An unknown error occured...");
                }
            } else if (upsertResult.errors && upsertResult.errors[0] && upsertResult.errors[0].message) {
                setErrorMessage("Error: " + upsertResult.errors[0].message);
            } else {
                setErrorMessage("An unknown error occured..");
            }
        }
    };

    return useObserver(() => (
        <>
            <Container>
                <BackBtn onClick={goBack}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="13.66" height="18.969" viewBox="0 0 13.66 18.969">
                        <path
                            d="M10.9.9,9.952,0,3,6.711l6.952,6.711.943-.9L4.886,6.711Z"
                            transform="translate(-0.12 2.774)"
                            fill="#fff"
                            stroke="#fff"
                            stroke-width="4"
                        />
                    </svg>
                    <span className="text">Back to your quotes</span>
                </BackBtn>
                <h1>To buy this policy you require an existing carrier agreement or need to use National Flood Insurance as your partner agency.</h1>
                <div className="divider" />
                <h2>To partner with National Flood insurance please sign up below</h2>
                <PartnerContainer>
                    <NFIPartnerAgreement
                        errorMessage={errorMessage}
                        isLoading={isLoading}
                        onSubmit={handleSubmit}
                        signatureRef={signatureRef}
                        viewModel={viewModel}
                    />
                </PartnerContainer>
            </Container>
        </>
    ));
};

const Container = styled.div`
    max-width: 1140px;
    margin: auto auto 80px;

    .divider {
        border-bottom: 1px #129bdb solid;
        margin: 35px 0;
    }
`;

const BackBtn = styled(Button)`
    background-color: #129bdb !important;
    border-radius: 10000px;
    color: white;
    padding: 16px 40px;
    font-weight: bold;
    margin: 40px 0;

    span.text {
        padding-left: 10px;
    }
`;
