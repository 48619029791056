// Libraries
import React, { useState, useContext, useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "Core/Utils";
import { Redirect } from "react-router";

// App
import { StoresContext, Stores, StoresInstance } from "Custom/Stores";
import { LoginViewModel } from "../ViewModels/LoginViewModel";
import { nameofFactory } from "Core/Utils/Utils";
import { LoginModel } from "../Models";
import { Centered } from "Core/Components/General";

// Components
import { Footer } from "../Components/Footer";

// Styling & images
import { Theme, Fade, Box, Avatar, Typography, Button, Paper, Link, FormControlLabel, Checkbox } from "@material-ui/core";
import MFIheaderlogo from "Content/logoForDarkBG.png";
import radioYes from "Content/radioYes.svg";
import radioYesChecked from "Content/radioYes-checked.svg";
import { HeaderLogo, LoginForm, LoginBox, InstructBox, RememberMeLabel, SignInButton, ShowPasswordIcon } from "../StylesAppSpecific/LoginStyling";

import { AppHeader } from "../../Core/Components/Styles/Styles";
import { DivWrapper, GridSpaced, DialogueWrapper, DivWrapperBGFixed } from "../StylesAppSpecific/GridHelperStyling";
import { AdminWrapTabDiv } from "../StylesAppSpecific/MFIAdminStyling";
import Puddles from "Content/homeBG2.png";
import LockOutlinedIcon from "@material-ui/core/SvgIcon/SvgIcon";
import { EditableInput } from "Core/Components/EditableInput";
import { styled } from "../Globals/Base";
import { ResetPasswordViewModel } from "Custom/ViewModels";
import EyeClosed from "Content/icon-Eye-closed.svg";
import EyeOpen from "Content/icon-Eye-open.svg";

const domainStores = StoresInstance.domain;
const nameof = nameofFactory<LoginModel>();

export const ResetPasswordPage: React.FunctionComponent = () => {
    const { history, location, match } = useRouter();
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [viewModel] = useState(() => new ResetPasswordViewModel());
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    useEffect(() => {
        viewModel.getTokenFromUrl();
    }, []);

    /* 	if (domainStores.AccountStore.IsLoggedIn) {
		return <Redirect to={"/"} />;
	} */

    const goToForgotPassword = async (e: any) => {
        history.push("/forgotpassword");
    };

    const doSubmit = async (e: any) => {
        e.preventDefault();
        if (viewModel.isModelValid()) {
            setIsLoading(true);
            let apiResult = await viewModel.resetPasswordAsync();
            if (apiResult) {
                if (apiResult.wasSuccessful) {
                    domainStores.AccountStore.getLoginState(apiResult.payload);
                    history.push("/");
                } else {
                    if (apiResult && apiResult.errors && apiResult.errors.length > 0) {
                        setErrorMessage("* " + apiResult.errors[0].message);
                    } else {
                        setErrorMessage("* Unknown error has occurred.");
                    }
                }
            }
            setIsLoading(false);
        }
    };

    let handleShowPassword = (e: any) => {
        setShowPassword(!showPassword);
    };

    let handleShowPassword2 = (e: any) => {
        setShowPassword2(!showPassword2);
    };

    const getForm = () => (
        <>
            <LoginBox padding="0 115px 5px">
                <span className="blue-text">Set</span> your password
            </LoginBox>
            <LoginForm onSubmit={doSubmit}>
                {errorMessage !== "" && (
                    <Typography variant="caption" style={{ color: "red" }}>
                        {errorMessage}
                    </Typography>
                )}
                <label htmlFor="input-pass" style={{ position: "relative" }}>
                    Choose your password
                    <EditableInput
                        type={showPassword ? "text" : "password"}
                        viewModel={viewModel}
                        fieldName="newPassword"
                        validateOnBlur={true}
                        inputProps={
                            {
                                id: "input-pass",
                                placeholder: "Choose your password",
                                variant: "outlined",
                            } as any
                        }
                    />
                    <ShowPasswordIcon onClick={(e: any) => handleShowPassword(e)} title={showPassword ? "Hide password" : "Show password"}>
                        <img src={showPassword ? EyeClosed : EyeOpen} alt="" />
                    </ShowPasswordIcon>
                </label>
                <label htmlFor="input-confirm-pass" style={{ position: "relative" }}>
                    Confirm your password
                    <EditableInput
                        type={showPassword2 ? "text" : "password"}
                        viewModel={viewModel}
                        fieldName="confirmPassword"
                        validateOnBlur={true}
                        inputProps={
                            {
                                id: "input-confirm-pass",
                                placeholder: "Confirm your password",
                                variant: "outlined",
                            } as any
                        }
                    />
                    <ShowPasswordIcon onClick={(e: any) => handleShowPassword2(e)} title={showPassword2 ? "Hide password" : "Show password"}>
                        <img src={showPassword2 ? EyeClosed : EyeOpen} alt="" />
                    </ShowPasswordIcon>
                </label>

                {errorMessage !== "" && <Link onClick={goToForgotPassword}>Return to forgot password</Link>}
                <InstructBox>
                    <ol>
                        <li>At least eight characters.</li>
                        <li>
                            One or more of each of the following:
                            <ul>
                                <li>upper-case letter</li>
                                <li>lower-case letter</li>
                                <li>number</li>
                            </ul>
                        </li>
                    </ol>
                </InstructBox>
                <SignInButton type="submit" variant="contained" disabled={isLoading}>
                    {/* isLoading ? "Please Wait..." : "Sign in" */}
                    Save &<br />
                    Login
                </SignInButton>
            </LoginForm>
        </>
    );

    const invalidToken = () => (
        <>
            <Box justifyContent="center" textAlign="center" display="grid" padding="10px 30px 30px">
                <Typography variant="body1" gutterBottom color="textPrimary">
                    Url is expired or invalid, please try again.
                </Typography>
                <br />
                <Link onClick={goToForgotPassword} style={{ cursor: "pointer" }}>
                    Return to forgot password
                </Link>
            </Box>
        </>
    );

    const loading = () => (
        <>
            <Box justifyContent="center" textAlign="center" display="grid" padding="10px 30px 30px">
                <Typography variant="body1" gutterBottom color="textPrimary">
                    Loading...
                </Typography>
            </Box>
        </>
    );

    return useObserver(() => (
        <>
            <DivWrapperBGFixed additionalcss={"background-image: url(" + Puddles + ");"}>
                <DivWrapper backgroundcolor={"rgba(27, 43, 55, 0.75)"}>
                    <Centered>
                        <Box textAlign="center">
                            <HeaderLogo src={MFIheaderlogo} alt="My Flood Insurance" />
                        </Box>
                        <Fade in timeout={1000}>
                            <Box paddingBottom="80px">
                                <Box border="10px #129BDB solid" borderRadius="28px" bgcolor="white" padding="30px 30px 0">
                                    {viewModel.tokenValid === true ? getForm() : viewModel.tokenValid === false ? invalidToken() : loading()}
                                </Box>
                            </Box>
                        </Fade>
                    </Centered>
                </DivWrapper>
            </DivWrapperBGFixed>
            <Footer isTemp={false} />
        </>
    ));
};
