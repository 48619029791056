// Libraries
import React, { useState } from "react";
import { useMediaQuery } from "Core/Utils/Media";

// Styling & images
import { GridSpaced } from "../../StylesAppSpecific/GridHelperStyling";
import AddSomeDetail from "Content/AddSomeDetailloading.svg";
import { LoadingImg, LoadingH1, LoadingH2 } from "./Styling";
import { MFILoader } from "./Loader";

export const QuoteLoading: React.FC = () => {
    let isMobile: boolean;
    isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;

    let cssStr: string = isMobile
        ? "width: 100%; position: fixed; top: 0; bottom: 0; left: 0; right: 0; justify-content: center; align-items: center; z-index: 10000; background-color: rgba(0,0,0,0.75);"
        : "width: 100%; position: fixed; top: 0; bottom: 0; left: 0; right: 0; justify-content: center; align-items: center; z-index: 10000; background-color: rgba(0,0,0,0.75);";

    return (
        <>
            <GridSpaced container margin={"0px auto"} padding={"20vh 0px"} additionalcss={cssStr}>
                <GridSpaced item xs={12} sm={12} justify={"center"} additionalcss={"text-align: center;"}>
                    <MFILoader />
                    <LoadingH1>Matching your data with our partners</LoadingH1>
                    <LoadingH2>to get the best possible quotes</LoadingH2>
                </GridSpaced>
            </GridSpaced>
        </>
    );
};
