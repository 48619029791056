// Libraries
import React, { useEffect, useState, useRef } from "react";
import { useObserver } from "mobx-react-lite";
import { useMediaQuery } from "Core/Utils/Media";

// Components
import { Footer } from "../Components/Footer";
import { StoresInstance } from "../Stores";
import { useRouter } from "Core/Utils";
import { PropertyLoading } from "../Components/IsLoading";
import { EditBar, LocationEdit } from "../Components/EditBar";
import { CommercialStep, CommonOtions, CondoStep, FamilyResidenceStep, MobileStep, RCBAPStep } from "Custom/Components/PropertySteps";

// Styling & images
import { DivWrapperBGFixed } from "../StylesAppSpecific/GridHelperStyling";
import { ConfirmDetailsPanel, PropertyDetailWrapper, PlaceHolder, PreviousButton, NextButton } from "Custom/StylesAppSpecific/StepsStyling";
import PXFuel from "Content/quoteBG.jpg";
import Commercial from "Content/commercial.svg";
import Condo from "Content/condo.svg";
import Mobile from "Content/mobile.svg";
import SFRes from "Content/SFRes.svg";

/**
 * Step 2: Confirm these details about property
 */
export const Step2ConfirmDetails: React.FC = () => {
    const domainStores = StoresInstance.domain;
    const quoteRequestVM = domainStores.QuoteRequestViewModel;
    const propertyDetailVM = domainStores.PropertyDetailViewModel;
    let isMobile: boolean;
    isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;
    const { history } = useRouter();

    if (quoteRequestVM.getValue<string>("chosenPropertyType") == undefined || quoteRequestVM.getValue<string>("chosenPropertyType").length == 0) {
        history.push("/Step1");
    }

    const headerImg = () => {
        switch (quoteRequestVM.getValue<string>("chosenPropertyType")) {
            case "sfr":
                return SFRes;
                break;

            case "mobile":
                return Mobile;
                break;

            case "condo":
                return Condo;
                break;

            case "commercial":
                return Commercial;
                break;

            case "rcbap":
                return Condo;
                break;

            default:
                return <>???</>;
                break;
        }
    };

    const contentHdr = isMobile ? (
        <>
            <img src={headerImg()} alt="Confirm these details about your property" />
            <h2 className="propertyheader">
                <span>Step 2:</span>
                Confirm these details about
                <br />
                your property
            </h2>
            <p className="propertyheadertext">{quoteRequestVM.getValue("address")}</p>
        </>
    ) : (
        <>
            <h2 className="propertyheader">
                <img src={headerImg()} alt="Confirm these details about your property" />
                <span>Step 2:</span> Confirm these details about your property
            </h2>
            <p className="propertyheadertext">{quoteRequestVM.getValue("address")}</p>
        </>
    );

    const sfrView = () => {
        return (
            <>
                <FamilyResidenceStep />
            </>
        );
    };

    const mobilehomeView = () => {
        return (
            <>
                <MobileStep />
            </>
        );
    };

    const condoView = () => {
        return (
            <>
                <CondoStep />
            </>
        );
    };

    const commercialView = () => {
        return (
            <>
                <CommercialStep />
            </>
        );
    };

    const rcbapView = () => {
        return (
            <>
                <RCBAPStep />
            </>
        );
    };

    const propertyTypeChoices = () => {
        quoteRequestVM.setValue("isMobileHome", "false");
        console.log(quoteRequestVM.getValue<string>("chosenPropertyType"));
        switch (quoteRequestVM.getValue<string>("chosenPropertyType")) {
            case "sfr":
                return sfrView();
                break;

            case "mobile":
                quoteRequestVM.setValue("numberOfFloors", 1);
                quoteRequestVM.setValue("isMobileHome", "true");
                return mobilehomeView();
                break;

            case "condo":
                return condoView();
                break;

            case "commercial":
                return commercialView();
                break;

            case "rcbap":
                return rcbapView();
                break;

            default:
                return <>???</>;
                break;
        }
    };

    const handleClick = (e: string) => {
        switch (e) {
            case "previous":
                history.push("/Step1");
                break;

            case "next":
                history.push("/Step3");
                break;

            default:
                break;
        }
    };

    const editBar = (
        <EditBar>
            <LocationEdit editlink="/Step1" />
        </EditBar>
    );

    return useObserver(() => (
        <>
            <DivWrapperBGFixed additionalcss={"background-image: url(" + PXFuel + ");"}>
                {!propertyDetailVM.IsLoading ? (
                    <>
                        {editBar}
                        <ConfirmDetailsPanel>
                            <div className="uniqueOptions">
                                {contentHdr}
                                <PropertyDetailWrapper>{propertyTypeChoices()}</PropertyDetailWrapper>
                            </div>
                            <CommonOtions />
                        </ConfirmDetailsPanel>

                        <PlaceHolder className="jeff">
                            <PreviousButton variant="contained" onClick={() => handleClick("previous")}>
                                Previous
                            </PreviousButton>
                            <NextButton disabled={!propertyDetailVM.okayToProceed} variant="contained" onClick={() => handleClick("next")}>
                                Next
                            </NextButton>
                        </PlaceHolder>
                    </>
                ) : (
                    <>
                        <PropertyLoading />
                    </>
                )}
            </DivWrapperBGFixed>
            <Footer isTemp={false} />
        </>
    ));
};
