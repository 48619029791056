import { Grid } from "@material-ui/core";
import styled from "styled-components";

export interface IComponentProps {
    padding?: string;
    margin?: string;
    fontcolor?: string;
    backgroundcolor?: string;
    additionalcss?: string;
}

export const AdminWrapTabDiv: any = styled.div<IComponentProps>`
    /* border: 10px solid #129bdb;
	border-radius: 30px; */
    text-align: left;
    /* background: #e7f6fe 0% 0% no-repeat padding-box; */
    /* color: #464545; */
    padding: ${props => (props.padding !== undefined ? props.padding : "0")};
    margin: ${props => (props.margin !== undefined ? props.margin : "0 auto")};
    width: 100%;
    @media (max-width: 767px) {
        width: 335px;
        padding: 10px /* 20px */;
    }
    ${props => props.additionalcss};
`;
