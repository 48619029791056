import * as MobX from "mobx";

import { IsEmail, IsNotEmpty, IsPhoneNumber, Equals } from "class-validator";

import { ModelBase } from "../../Core/Models/ModelBase";

export class ContactUsModel extends ModelBase<ContactUsModel, ContactUsModelDTO> {
    @MobX.observable
    @IsNotEmpty({ message: "Required" })
    @IsEmail(undefined, { message: "Invalid e-mail address" })
    public email: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Required" })
    public firstName: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Required" })
    public lastName: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Required" })
    @IsPhoneNumber("US", { message: "Invalid phone number" })
    public tel: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Required" })
    public message: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Required" })
    public contactType: string = "";

    @MobX.observable
    @Equals(true, { message: "You must pass the captcha" })
    public passCaptcha: boolean = false;

    public fromDto = (model: ContactUsModelDTO) => {};
    public toDto = (model: ContactUsModel) => {};

    public resetContact = () => {
        this.email = "";
        this.firstName = "";
        this.lastName = "";
        this.tel = "";
        this.message = "";
        this.contactType = "";
    };
}

export interface ContactUsModelDTO {
    email: string;
    firstName: string;
    lastName: string;
    tel: string;
    message: string;
    contactType: string;
}
