import * as React from "react";
import { ErrorView } from "./Custom/Views/ErrorView";

interface ErrorBoundaryProps {
    hasError: boolean;
    error: string;
}
export class AppErrorBoundary extends React.Component<{}, ErrorBoundaryProps> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false, error: "" };
    }
    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error: error.toString() };
    }
    public componentDidCatch(error: any, info: any) {
        console.log("Error => " + error.toString());
    }

    public render() {
        if (this.state.hasError) {
            return <ErrorView error={this.state.error} />;
        }

        return this.props.children;
    }
}
