// Libraries
import React, { useState } from "react";
import { useEffect, useRouter } from "../../Globals/Base";

// Components
import ViewModel from "../../ViewModels/BuyPolicyViewModel";
import { useMediaQuery } from "Core/Utils/Media";
import { StoresInstance } from "../../Stores";

// Styling & image
import { PolicyCardBox, PolicyCardItem, PolicyCardImage } from "./Styling";
import aonedgeLogo from "Content/aonedge-logo.png";
import argeniaLogo from "Content/argenia-logo.png";
import assurantLogo from "Content/assurant-logo.png";
import floodrisksolutionsLogo from "Content/floodrisksolutions-logo.png";
import sterlingLogo from "Content/sterlingLogo.png";
import wrightLogo from "Content/wright-logo.png";
import { getInsurerLogo } from "../../Utils/InsurerLogo";

// Interface
interface IProps {
    Model: ViewModel;
}

export const PolicyCard: React.FC<IProps> = props => {
    let isMobile: boolean;
    isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;
    const InsurerLogo: string = getInsurerLogo(props.Model.insurerName);

    let showDetail: boolean = props.Model.quoteNumber === "notQuoted" ? false : true;

    return (
        <>
            {isMobile && (
                <>
                    <PolicyCardBox container>
                        <PolicyCardImage item xs={4}>
                            <img alt={props.Model.insurerName} src={InsurerLogo} />
                        </PolicyCardImage>
                        <PolicyCardItem item xs={8}>
                            <h2 className="insurer">{props.Model.insurerName}</h2>
                            {showDetail && (
                                <>
                                    <h3>
                                        <span>Policy term:</span> {props.Model.policyDateRange}
                                    </h3>
                                    <h3>
                                        <span>Quote ref:</span> {props.Model.internalQuoteNumber}
                                    </h3>
                                    <h3>
                                        <span>Carrier ref:</span> {props.Model.quoteNumber}
                                    </h3>
                                </>
                            )}
                        </PolicyCardItem>
                        {showDetail && (
                            <>
                                <h2 className="total">
                                    Total annual Premium: $<span>{StoresInstance.domain.PremVal}</span>
                                </h2>
                            </>
                        )}
                    </PolicyCardBox>
                </>
            )}
            {!isMobile && (
                <>
                    <PolicyCardBox container padding="10px 5px">
                        <PolicyCardImage item sm={4}>
                            <img alt={props.Model.insurerName} src={InsurerLogo} />
                        </PolicyCardImage>
                        <PolicyCardItem item sm={8}>
                            <h2 className="insurer">{props.Model.insurerName}</h2>
                            {showDetail ? (
                                <>
                                    <h3>
                                        <span>Policy term:</span> {props.Model.policyDateRange}
                                    </h3>
                                    <h3>
                                        <span>Quote ref:</span> {props.Model.internalQuoteNumber}
                                    </h3>
                                    <h3>
                                        <span>Carrier ref:</span> {props.Model.quoteNumber}
                                    </h3>
                                    <h2 className="total">Total annual Premium: ${StoresInstance.domain.PremVal}</h2>
                                </>
                            ) : (
                                <h3>
                                    In order to provide you with an accurate NFIP quote, one of our expert agents will need some further information from you.
                                    This may be because of the property type, flood zone, or loss history.
                                </h3>
                            )}
                        </PolicyCardItem>
                    </PolicyCardBox>
                </>
            )}
        </>
    );
};
