import { Box, Grid, Link, makeStyles } from "@material-ui/core";
import styled from "styled-components";

export const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(1),
    },
    input: {
        display: "none",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    datePicker: {
        backgroundColor: "#FFFFFF",
        border: "1px solid #E0DAF0",
        height: "54px",
        marginRight: "5px",
        marginBottom: "0px",
        marginTop: "0px",
        width: "100%",
        placeholder: {
            color: "#000000",
        },
    },
    datePickerBox: {},
    datePickersGridContainer: {
        justifyContent: "space-between",
    },
    datePickerGridContainer: {
        maxWidth: "96%",
    },
    subTitle: {
        marginBottom: "18px",
        paddingBottom: "0px !important",
    },
}));

export interface IComponentProps {
    fontWeight?: string;
    fontcolor?: string;
    backgroundcolor?: string;
    hovercolor?: string;
    padding?: string;
    margin?: string;
    additionalcss?: string;
    fontsize?: string;
    textAlign?: string;
    border?: string;
    borderLeft?: string;
    borderRight?: string;
    width?: string;
    display?: string;
    overflow?: string;
    height?: string;
}

export const CalltoActionBase: any = styled.div<IComponentProps>`
    a {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #ffffff;
        padding: 15px 30px;
        border-radius: 15px;
        max-width: 540px;
        text-decoration: none;

        h2 {
            font-size: 32px;
            margin: 0px;
            letter-spacing: -1.6px;

            @media (max-width: 767px) {
                font-size: 18px;
            }
        }

        p {
            font-size: 14px;
            margin: 0px;
            letter-spacing: -0.35px;

            @media (max-width: 767px) {
                font-size: 10px;
            }
        }

        @media (max-width: 767px) {
            max-width: 335px;
            padding: 15px 12px;

            .arrow-right-icon {
                width: 30px;
                height: 49px;
            }
            .here-to-help-icon {
                width: 50px;
                height: 49px;
            }

            .mfr-logo {
                width: 112px;
                height: 49px;
            }
        }
    }
`;

export const CalltoActionContact: any = styled(CalltoActionBase)<IComponentProps>`
    margin-bottom: 20px;

    a {
        background-color: #129bdb;
    }
`;

export const CalltoActionMFI: any = styled(CalltoActionBase)<IComponentProps>`
    a {
        background-color: #005fab;

        h2 {
            max-width: 215px;

            @media (max-width: 767px) {
                max-width: 120px;
            }
        }
    }
`;

export const HomePrimaryPanel: any = styled.div<IComponentProps>`
    padding: unset;
    margin: 0px auto;
    background-color: unset;
    max-width: 1124px;
    width: 100%;
    ${props => props.additionalcss};
`;

export const UspPanel: any = styled(HomePrimaryPanel)`
    display: flex;
    flex-direction: row;
    padding: 20px 0px;
    img,
    div,
    button {
        margin-right: 20px;
    }
    .textcontainer {
        display: inline-flex;
        flex-direction: column;
        margin-top: 1%;
        margin-right: 40px;
        h2,
        p {
            font-weight: 700;
            font-size: 33px;
            margin: 0px;
            padding: 0px;
            letter-spacing: -1.8px;
            line-height: 1;
        }

        p {
            font-size: 28px;
            letter-spacing: -1.4px;
        }
    }
    button {
        font-size: 18px;
        letter-spacing: -0.45px;
        margin: 2.5% 0 0;
        max-height: 34px;
    }
`;

export const ConfirmAddressPanel: any = styled(HomePrimaryPanel)<IComponentProps>`
    background: #3a4650 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
`;

export const HomeVerticalCenter = styled.div<IComponentProps>`
    height: ${props => (props.height === undefined ? "100vh" : props.height)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 64px;
    margin: ${props => props.margin};
    display: ${props => props.display};
    overflow: ${props => props.overflow};
    ${props => props.additionalcss};
`;
