// Libraries
import React, { useContext, useEffect } from "react";
import { Route, Redirect } from "react-router";
import { Switch, useLocation } from "react-router-dom";

import { Home } from "./Custom/Views/Home";
import { Holding } from "./Custom/Views/Holding";
import { LoginPage } from "./Custom/Views/LoginPage";
import { Logout } from "./Custom/Views/Logout";
import { PrivateRoute } from "Core/Utils";
import { RegisterPage } from "./Custom/Views/RegisterPage";
import { ForgotPasswordPage } from "./Custom/Views/ForgotPasswordPage";
import { StoresContext, Stores } from "./Custom/Stores";
import { User } from "./Custom/Views/User";
import { TestPage } from "./Core/Views/TestPage";
import { BuyPolicy } from "./Custom/Views/BuyPolicy";
import { SessionExpired } from "./Custom/Views/SessionExpired";
import { LogViewer } from "./Core/Views/LogViewer";
import { State } from "./Custom/Views/State";
import { City } from "./Custom/Views/City";
import { NoMatch } from "./Custom/Views/NoMatch";
import { ContactUsPage } from "./Custom/Views/ContactUs";
import { Dashboard } from "./Custom/Views/Dashboard";
import { Partners } from "./Custom/Views/Partners";
import { AboutUs } from "./Custom/Views/AboutUs";
import { TermsAndConditions } from "./Custom/Views/TermsAndConditions";
import { PrivacyPolicy } from "./Custom/Views/PrivacyPolicy";

// Used by Mobile App(s)
import { Wrapper } from "./Custom/Views/wrapper";

// Widget Page(s)
import { HomeWidget } from "./Custom/Views/Widget/Home";
import { WidgetRedirect } from "./Custom/Views/Widget/Redirect";

// Wizard Pages
import { Step1ConfirmAddress } from "./Custom/Views/Step1ConfirmAddress";
import { Step2ConfirmDetails } from "./Custom/Views/Step2ConfirmDetails";
import { Step3Coverage } from "./Custom/Views/Step3Coverage";
import { FloodInsuranceCoverage } from "./Custom/Views/FloodInsuranceCoverage";
import { PropertyCoverageDetails } from "./Custom/Views/PropertyCoverageDetails";
import { CoverageOptions } from "./Custom/Views/CoverageOptions";
import { Quote } from "./Custom/Views/Quote";
import { Results } from "./Custom/Views/Results";
import { ResetPasswordPage } from "./Custom/Views/ResetPasswordPage";
import { QuoteRequestEmailExists } from "./Custom/Views/QuoteRequestEmailExists";
import { EditAccountDetails } from "./Custom/Views/EditAccountDetails";
import { EditRealtorDetail } from "Custom/Views/EditRealtorDetail";

// Admin Pages
import { Admin } from "./Custom/Views/Admin/Admin";
import { AdminUserReport } from "./Custom/Views/Admin/AdminUserReport";
import { AdminUserReportQuote } from "./Custom/Views/Admin/AdminUserReportQuote";
import { AdminUserReportQuotes } from "./Custom/Views/Admin/AdminUserReportQuotes";
import { CityEdit } from "./Custom/Views/Admin/CityEdit";
import { StateEdit } from "./Custom/Views/Admin/StateEdit";
import { WidgetEdit } from "./Custom/Views/Admin/WidgetEdit";
import { UserDetails } from "./Custom/Views/Admin/UserDetails";
import { ErrorView } from "./Custom/Views/ErrorView";
import { ForgotPasswordSuccessPage } from "Custom/Views/ForgotPasswordSuccessPage";
import { CarrierSettingsPage } from "./Custom/Views/CarrierSettingsPage";
import { NFIPartnerAgreementPage } from "./Custom/Views/NFIPartnerAgreementPage";
import { NFIPartnerPage } from "./Custom/Views/NFIPartnerPage";
import { Widgets } from "./Custom/Views/Admin/Widgets";
import { AdminWidgetReport } from "./Custom/Views/Admin/AdminWidgetReport";

// Agent
import { Agent } from "./Custom/Views/Agent/Agent";
import { AgentUserReport } from "Custom/Views/Agent/AgentUserReport";
import { AgentUserReportQuotes } from "Custom/Views/Agent/AgentUserReportQuotes";
import { AgentUserReportQuote } from "Custom/Views/Agent/AgentUserReportQuote";
import * as GoogleAnalytics from "./Core/Utils/App/GoogleAnalyticsUtils";

const usePageViews = () => {
    let location = useLocation();

    useEffect(() => {
        GoogleAnalytics.pageview(location.pathname);
    }, [location]);
};

export const AppRoutes: React.FC = () => {
    const store = useContext<Stores>(StoresContext);
    usePageViews();

    return (
        <Switch>
            <Route exact path="/holding" component={Holding} />
            <Route exact path="/" component={Home} />
            <Route exact path="/state/:id" render={routeprops => <State key={routeprops.match.params.id} />} />
            <Route exact path="/city/:id" render={routeprops => <City key={routeprops.match.params.id} />} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/forgotpassword" component={ForgotPasswordPage} />
            <Route exact path="/forgotpassword-success" component={ForgotPasswordSuccessPage} />
            <Route exact path="/resetpassword" component={ResetPasswordPage} />
            <Route exact path="/register" component={RegisterPage} />
            <Route exact path="/sessionexpired" component={SessionExpired} />
            <Route exact path="/wrapper" component={Wrapper} />
            <Route exact path="/step1" component={Step1ConfirmAddress} />
            <Route exact path="/step2" component={Step2ConfirmDetails} />
            <Route exact path="/step3" component={Step3Coverage} />
            {/* FloodInsuranceCoverage depricated in favour of step1 */}
            <Route exact path="/coverage" component={FloodInsuranceCoverage} />
            <Route exact path="/coverageoptions" component={CoverageOptions} />
            {/* PropertyCoverageDetails depricated in favour of step2 */}
            <Route exact path="/coveragedetails" component={PropertyCoverageDetails} />
            <Route exact path="/emailexists" component={QuoteRequestEmailExists} />
            <Route exact path="/partners" component={Partners} />
            <Route exact path="/about-us" component={AboutUs} />
            <Route exact path="/coveragedetails/:id" render={routeprops => <PropertyCoverageDetails key={routeprops.match.params.id} />} />
            <Route exact path="/availablepolicies" component={Results} />
            <Route exact path="/policy/:insurerName/:id" render={routeprops => <Quote key={routeprops.match.params.id} />} />
            <Route exact path="/buypolicy/:insurerName/:id" render={routeprops => <BuyPolicy key={routeprops.match.params.id} />} />
            <Route exact path="/logviewer" component={LogViewer} />
            <Route exact path="/logviewer" component={LogViewer} />
            <Route exact path="/contact" component={ContactUsPage} />
            <Route exact path="/unknownerror" component={ErrorView} />
            <Route exact path="/terms" component={TermsAndConditions} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />

            <Route exact path="/widget/" component={HomeWidget} />
            <Route exact path="/widget/redirect/:address/:city/:county/:state/:zipcode/:latitude/:longitude/:urisource" component={WidgetRedirect} />

            <PrivateRoute
                exact
                path="/user"
                component={User}
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && store.domain.AccountStore.isInRole("admin");
                }}
            />

            {/* Administration: Home Page and Dashboard */}
            <PrivateRoute
                exact
                path={["/admin", "/admin/:tab"]}
                component={Admin}
                isAllowed={() => {
                    return (
                        store.domain.AccountStore.IsLoggedIn && (store.domain.AccountStore.isInRole("admin") || store.domain.AccountStore.isInRole("producer"))
                    );
                }}
            />

            {/* Administration: Widget Report */}
            <PrivateRoute
                exact
                path="/adminwidgetreport/:id"
                component={AdminWidgetReport}
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && store.domain.AccountStore.isInRole("admin");
                }}
            />

            <PrivateRoute
                exact
                path="/editwidget/:id"
                component={WidgetEdit}
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && store.domain.AccountStore.isInRole("admin");
                }}
            />
            <Redirect exact from="/editwidget" to="/admin/editwidget" />

            {/* Administration: User Report */}
            <PrivateRoute
                exact
                path="/adminuserreport/:id"
                component={AdminUserReport}
                isAllowed={() => {
                    return (
                        store.domain.AccountStore.IsLoggedIn && (store.domain.AccountStore.isInRole("admin") || store.domain.AccountStore.isInRole("producer"))
                    );
                }}
            />

            {/* Administration: User Report Quotes */}
            <PrivateRoute
                exact
                path="/adminuserreportquotes/:id"
                component={AdminUserReportQuotes}
                isAllowed={() => {
                    return (
                        store.domain.AccountStore.IsLoggedIn && (store.domain.AccountStore.isInRole("admin") || store.domain.AccountStore.isInRole("producer"))
                    );
                }}
            />

            {/* Administration: User Report Quote */}
            <PrivateRoute
                exact
                path="/adminuserreportquote/:id/:quoteId"
                component={AdminUserReportQuote}
                isAllowed={() => {
                    return (
                        store.domain.AccountStore.IsLoggedIn && (store.domain.AccountStore.isInRole("admin") || store.domain.AccountStore.isInRole("producer"))
                    );
                }}
            />

            {/* Administration: User List */}
            <PrivateRoute
                exact
                path="/userslist/user/:id"
                component={UserDetails}
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && store.domain.AccountStore.isInRole("admin");
                }}
            />
            <Redirect exact from="/userslist/user" to="/admin/userslist" />
            <Redirect exact from="/userslist" to="/admin/userslist" />

            {/* Administration: Cities and City Management */}
            <PrivateRoute
                exact
                path="/editcity/:id"
                component={CityEdit}
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && store.domain.AccountStore.isInRole("admin");
                }}
            />
            <Redirect exact from="/editcity" to="/admin/editcity" />

            {/* Administration: States and State Management */}
            <PrivateRoute
                exact
                path="/editstate/:id"
                component={StateEdit}
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && store.domain.AccountStore.isInRole("admin");
                }}
            />
            <Redirect exact from="/editstate" to="/admin/editstate" />

            <Route exact path="/test" component={TestPage} />
            {/*
			<Route component={NoMatch} />
			*/}
            <PrivateRoute
                exact
                path="/dashboard"
                component={Dashboard}
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && !store.domain.AccountStore.IsProAccount;
                }}
            />
            <PrivateRoute
                exact
                path="/carrier-settings"
                component={CarrierSettingsPage}
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && store.domain.AccountStore.IsProAccount;
                }}
            />
            <PrivateRoute
                exact
                path="/nfipartner-agreement"
                component={NFIPartnerAgreementPage}
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && store.domain.AccountStore.IsProAccount;
                }}
            />
            <PrivateRoute
                exact
                path="/nfipartner"
                component={NFIPartnerPage}
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && store.domain.AccountStore.IsProAccount;
                }}
            />

            {/* Agent: Page Container */}
            <PrivateRoute
                path="/agent"
                component={Agent}
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && store.domain.AccountStore.IsProAccount;
                }}
            />

            {/* Agent: User report */}
            <PrivateRoute
                exact
                path="/agentuserreport/:id"
                component={AgentUserReport}
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && store.domain.AccountStore.IsProAccount;
                }}
            />

            {/* Agent: User Report Quotes */}
            <PrivateRoute
                exact
                path="/agentuserreportquotes/:id"
                component={AgentUserReportQuotes}
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && store.domain.AccountStore.IsProAccount;
                }}
            />

            {/* Agent: User Report Quote */}
            <PrivateRoute
                exact
                path="/agentuserreportquote/:id/:quoteId"
                component={AgentUserReportQuote}
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && store.domain.AccountStore.IsProAccount;
                }}
            />

            <PrivateRoute exact path="/editdetails" component={EditAccountDetails} isAllowed={() => store.domain.AccountStore.IsLoggedIn} />
            <PrivateRoute
                exact
                path="/editrealtor"
                component={EditRealtorDetail}
                isAllowed={() => store.domain.AccountStore.IsLoggedIn && store.domain.AccountStore.IsRealtorAccount}
            />
        </Switch>
    );
};
