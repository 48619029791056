import * as MobX from "mobx";
import { ModelBase } from "../../Core/Models/ModelBase";
import { DataModel } from "./EstatedChildren/Data";
import { MetaDataObjectModel } from "./EstatedChildren/MetaDataObject";
import { WarningModel } from "./EstatedChildren/Warning";

export class EstatedResponseModel extends ModelBase<EstatedResponseModel, EstatedResponseModelDTO> {
    @MobX.observable
    public data: DataModel = new DataModel();

    @MobX.observable
    public metaDataObjectWithVersion: MetaDataObjectModel = new MetaDataObjectModel();

    @MobX.observable
    public warnings: WarningModel[] = [];

    public fromDto = (model: EstatedResponseModelDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                this[key] = model[key];
            }
        }
    };
    public toDto = (model: EstatedResponseModel) => {};
}

export interface EstatedResponseModelDTO {
    data: DataModel;
    metaDataObjectWithVersion: MetaDataObjectModel;
    warnings: WarningModel[];
}
