import { useObserver } from "mobx-react-lite";
import React, { useEffect } from "react";

import sliderBuildingThumb from "Content/sliderBuildingThumb.svg";
import sliderContentsThumb from "Content/sliderContentsThumb.svg";
import sliderDeductableThumb from "Content/sliderDeductableThumb.svg";

import { CoverageLevelsContainer, CoverageDetailStyle } from "../../StylesAppSpecific/Coverage/CoverageLevels";
import { CoverageModel } from "../../Models/CoverageModel";
import { CoverageViewModel } from "../../ViewModels/CoverageViewModel";
import { Slider } from "../Forms/Slider";
import { Input } from "@material-ui/core";
import { useMediaQuery } from "Core/Utils";
import { PropertySelect } from "Custom/StylesAppSpecific/StepsStyling";
import { moneyDollarFormat } from "Custom/Utils/Money";

interface IProps {
    viewModel: CoverageViewModel;
}

export const CoverageLevels: React.FC<IProps> = props => {
    const { viewModel } = props;
    const isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;

    useEffect(() => {
        viewModel.resetFlags();
    }, []);

    return useObserver(() => (
        <CoverageLevelsContainer>
            <div className="coverage-parent">
                <h2>Policy coverage levels:</h2>
                <p>
                    Building coverage should reflect the replacement value of the property. Most policy holders choose to match the limit on their property
                    policy
                </p>
                <div className="coverage-row-wrapper">
                    <div className="building-coverage coverage">
                        <CoverageDetailStyle>
                            <h2 className="contact">Building coverage:</h2>
                            <div className={"coverage-input-container"}>
                                <p>$</p>
                                <Input
                                    type="text"
                                    disableUnderline={true}
                                    onChange={(event: any) => viewModel.buildingCoverageOnChange(event.target.value)}
                                    value={viewModel.getValue("buildingCoverageFormatted")}
                                    inputProps={{ placeholder: "Please enter an amount", maxLength: 9 }}
                                    onBlur={(event: any) => viewModel.buildingCoverageOnBlur(event.target.value)}
                                />
                            </div>
                            <div className="contact-bottom" />
                        </CoverageDetailStyle>
                    </div>
                    <div className="contents-coverage coverage">
                        <CoverageDetailStyle>
                            <h2 className="contact">Contents coverage:</h2>
                            <div className={"coverage-input-container"}>
                                <p>$</p>
                                <Input
                                    type="text"
                                    disableUnderline={true}
                                    onChange={(event: any) => viewModel.contentsCoverageOnChange(event.target.value)}
                                    value={viewModel.getValue("contentsCoverageFormatted")}
                                    inputProps={{ placeholder: "Please enter an amount", maxLength: 9 }}
                                    onBlur={(event: any) => viewModel.contentsCoverageOnBlur(event.target.value)}
                                />
                            </div>
                            <div className="contact-bottom" />
                        </CoverageDetailStyle>
                    </div>
                </div>
                <h2>Deductible:</h2>
                <div className="contents-coverage coverage">
                    {isMobile ? (
                        <PropertySelect className="deductible-select-wrapper">
                            <select
                                className="deductible-select"
                                id="deductible"
                                name="deductible"
                                value={viewModel.getValue(viewModel.useAlternateDeductible ? "deductibleAlternateOption" : "deductibleOption")}
                                onChange={e =>
                                    viewModel.setValue(viewModel.useAlternateDeductible ? "deductibleAlternateOption" : "deductibleOption", e.target.value)
                                }
                            >
                                {(viewModel.useAlternateDeductible ? CoverageModel.deductibleAlternateOptions : CoverageModel.deductibleOptions).map(o => (
                                    <option className={"optionD_" + o.key} key={o.key} value={o.key}>
                                        {moneyDollarFormat(o.value)}
                                    </option>
                                ))}
                            </select>
                        </PropertySelect>
                    ) : (
                        <>
                            <Slider<CoverageModel>
                                viewModel={viewModel}
                                fieldName={viewModel.useAlternateDeductible ? "deductibleAlternateOption" : "deductibleOption"}
                                min={viewModel.minDeductible}
                                max={viewModel.maxDeductible}
                                step={1}
                                thumbbackgroundimage={sliderDeductableThumb}
                            />

                            <span>{viewModel.formattedDeductible}</span>
                        </>
                    )}
                </div>
            </div>
        </CoverageLevelsContainer>
    ));
};
