import { styled } from "../Globals/Base";
import { FormControlLabel, Button, Box } from "@material-ui/core";

export const TableContainer = styled.div`
    .MuiToolbar-root {
        background-color: #005fab;
        color: white;
        padding: 37px 30px 30px;

        @media (max-width: 767px) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 20px 25px 20px;
            overflow-wrap: anywhere;
        }

        .MuiFormControl-root {
            @media (max-width: 767px) {
                padding-left: 0px;
            }
        }

        .table-header {
            display: flex;
            align-items: baseline;
        }

        input {
            color: white;
        }

        .MuiIconButton-root,
        .MuiIcon-root {
            color: white;

            &.Mui-disabled {
                opacity: 0.5;
            }
        }

        .MuiInput-underline:before {
            border-color: rgba(255, 225, 225, 0.42);
        }

        .MuiInput-underline:after {
            border-color: #fff;
        }
    }

    .MuiTableCell-footer {
        border: none;

        path {
            color: white;
        }

        .MuiToolbar-root {
            padding: 14px 30px;

            @media (max-width: 767px) {
                flex-wrap: wrap;
                padding: 14px 20px;
            }
        }

        .MuiTypography-caption {
            color: white;
        }
    }
`;

export const DeleteButton = styled.button`
    border: none;
    box-shadow: none;
    background: none;
    cursor: pointer;
`;
