import { ContactModel, ContactModelDTO } from "../Models/ContactModel";
import { GenericId } from "../Models/GenericId";
import { FieldType } from "Core/Utils/Utils";
import { Server } from "../Globals/AppUrls";
import { ViewModelBase } from "../../Core/ViewModels/ViewModelBase";
import { runInAction, action } from "mobx";
import { StoresInstance } from "../Stores";
import { UserDTO } from "../Models/User";

export class ContactViewModel extends ViewModelBase<ContactModel> {
    public ResponseModel = new ContactModel();

    constructor() {
        super(new ContactModel());
        this.setDecorators(ContactModel);
    }

    public UpsertContactAsync = async (): Promise<boolean> => {
        let apiResult = await this.Post<ContactModel>(Server.Api.Contact.SetContact, this.getModel);
        runInAction(() => {
            if (apiResult.wasSuccessful) {
                this.ResponseModel = new ContactModel();
                this.ResponseModel.fromDto(apiResult.payload);
            }
        });

        return apiResult.wasSuccessful;
    };

    public UpsertAccountDetails = async (password: string) => {
        const apiResult = await this.Post<UserDTO>(Server.Api.Account.UpsertDetails, { ...this.getModel, password });
        if (apiResult && apiResult.wasSuccessful) {
            runInAction(() => {
                StoresInstance.domain.AccountStore.FirstName = apiResult.payload.firstName;
                StoresInstance.domain.AccountStore.LastName = apiResult.payload.lastName;
                StoresInstance.domain.AccountStore.Email = apiResult.payload.email;
                StoresInstance.domain.AccountStore.Phone = apiResult.payload.phoneNumber;
                StoresInstance.domain.AccountStore.Terms = apiResult.payload.terms;
                this.model.resetContact();
            });
        }

        return apiResult;
    };

    public UpsertRealtorAccountDetails = async (password: string, realtorName: string, realtorLogo: string) => {
        const apiResult = await this.Post<UserDTO>(Server.Api.Account.UpsertDetailsWithRealtor, { ...this.getModel, password, realtorLogo, realtorName });
        if (apiResult && apiResult.wasSuccessful) {
            runInAction(() => {
                StoresInstance.domain.AccountStore.FirstName = apiResult.payload.firstName;
                StoresInstance.domain.AccountStore.LastName = apiResult.payload.lastName;
                StoresInstance.domain.AccountStore.Email = apiResult.payload.email;
                StoresInstance.domain.AccountStore.Phone = apiResult.payload.phoneNumber;
                StoresInstance.domain.AccountStore.Terms = apiResult.payload.terms;
                this.model.resetContact();
            });
        }

        return apiResult;
    };

    public async getContact(id: GenericId) {
        this.setIsLoading(true);
        let apiResult = await this.Post<ContactModelDTO>(Server.Api.Contact.GetContact, id);
        if (apiResult.wasSuccessful) {
            this.model.fromDto(apiResult.payload);
        } else {
            console.log("Payload Errors: " + apiResult.errors);
        }
        this.setIsLoading(false);
        return apiResult;
    }

    /**
     * Populates the ContactModel from the AccountStore
     */
    @action
    public fillContactInfo = () => {
        let domainStores = StoresInstance.domain;
        this.model.tel = domainStores.AccountStore.Phone;
        this.model.firstName = domainStores.AccountStore.FirstName;
        this.model.lastName = domainStores.AccountStore.LastName;
        this.model.email = domainStores.AccountStore.Email;
    };

    public isFieldValid(fieldName: keyof FieldType<ContactModel>, value: any, showErrors: boolean = true): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        if (showErrors) {
            this.setError(fieldName, errorMessage);
            this.setValid(fieldName, isValid);
        }

        return isValid;
    }

    public afterUpdate: any;
    public beforeUpdate: undefined;
}
