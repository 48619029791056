// Libraries
import React, { useContext } from "react";
import { useMediaQuery } from "Core/Utils/Media";
import { Link } from "@material-ui/core";
import styled from "styled-components";

// Components
import { MetaHeader } from "../Components/SEOComponents";
import { SEOHeader } from "../Models";
import { Footer } from "../Components/Footer";
import { Terms } from "../Components/Terms";
import { useRouter } from "../Globals/Base";

// Styling & images
import { DivWrapper, GridSpaced, DialogueWrapper } from "../StylesAppSpecific/GridHelperStyling";
import { PartnerDiv, PartnerItem, PartnerImageContainer } from "../StylesAppSpecific/PartnersStyling";
import PXFuel from "Content/pxfuel.png";

import auroralogo from "Content/partners/Aurora-logo.png";
import shoothilllogo from "Content/partners/shoothill-logo.png";
import nfilogo from "Content/partners/national-flood-insurance-logo.png";

export const TermsAndConditions: React.FC = () => {
    let isMobile: boolean;
    window.scrollTo(0, 0);
    isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;
    const { history } = useRouter();

    let metaData: SEOHeader = {
        title: "My Flood Insurance - Terms of use",
        description: "Partners we work with to bring you the best possible quotes",
    };

    const contentHdr = (
        <>
            <GridSpaced
                container
                justify={"center"}
                margin={"0px auto"}
                padding={isMobile ? "" : "20px 0px 0px 0px"}
                additionalcss={"max-width: 1000px; width: 100%;"}
            >
                <GridSpaced
                    container
                    justify={"center"}
                    margin={"0px auto"}
                    padding={isMobile ? "14px 0px" : "30px 0px"}
                    additionalcss={isMobile ? "max-width: 300px; width: 100%; " : "max-width: 1000px; width: 100%;"}
                >
                    <h1>Terms of Use</h1>
                </GridSpaced>
            </GridSpaced>
            <GridSpaced
                container
                justify={"center"}
                margin={"0px auto"}
                additionalcss={isMobile ? "max-width: 335px; width: 100%;" : "max-width: 1110px; width: 100%;"}
            ></GridSpaced>
        </>
    );

    const terms = (
        <>
            <GridSpaced
                container
                justify={"center"}
                margin={"0px auto"}
                padding={isMobile ? "0px 0px 40px 0px" : "0px 0px 70px 0px"}
                additionalcss={isMobile ? "max-width: 335px; width: 100%;" : "max-width: 1124px; width: 100%;"}
            >
                <TermsContainer item xs={12} sm={12}>
                    <Terms />
                </TermsContainer>
            </GridSpaced>
        </>
    );

    return (
        <>
            <MetaHeader Model={metaData} />
            <PartnerDiv additionalcss={"background-image: url(" + PXFuel + "); background-attachment: fixed;"}>
                {contentHdr}
                {terms}
            </PartnerDiv>
            <Footer isTemp={false} />
        </>
    );
};

const TermsContainer = styled(GridSpaced)`
    p {
        font-size: 14px;
        margin-bottom: 15px;
        letter-spacing: -0.35px;
        line-height: 24px;
    }

    h1 {
        text-align: left;
        font-size: 28px;
    }

    h2 {
        text-align: left;
        font-size: 24px;
    }

    ol li {
        margin-bottom: 15px;
    }
`;
