// Libraries
import React from "react";
import { useMediaQuery } from "Core/Utils/Media";

// Styling & images
import { GridSpaced, TitleWrapper } from "../../StylesAppSpecific/GridHelperStyling";
import { Grid } from "@material-ui/core";
import { ExpandDetailContainer, QandAContainer } from "./QandAStyling";

export const QandAWHY: React.FC = () => {
    let isMobile: boolean;
    isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;

    return (
        <>
            <GridSpaced container padding={!isMobile ? "0px" : "35px 0px 0px"} margin={"0px auto"} additionalcss={"max-width: 1107px; width: 100%;"}>
                <Grid item xs={12} sm={12}>
                    <QandAContainer padding={"0"} margin={"0"}>
                        <h2>Why do I need flood insurance?</h2>
                        <p>
                            Floods can happen anywhere, at any time. In fact, 90 percent of natural disasters in the United States involve some form of
                            flooding, and all fifty states have experienced flooding within the past five years. A standard home insurance policy does not
                            include coverage for flooding. The best way to protect your property from the threat of rising water is with a flood insurance
                            policy. My Flood Insurance gives you the opportunity to quote multiple flood companies in one place.
                        </p>
                    </QandAContainer>
                </Grid>
            </GridSpaced>
        </>
    );
};
