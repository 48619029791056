// Libraries
import clsx from "clsx";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState, useContext } from "react";
import { Box, Grid, Button, Typography, Paper } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

// Content
import Puddles from "Content/homeBG2.png";

// Custom
import { Footer } from "../../Components/Footer/Footer";
import { useRouter } from "../../Globals/Base";
import { useAdminStyles, useAdminUserReportQuotesStyles } from "../../StylesAppSpecific/Admin/AdminDashboard";
import { DivWrapper, DivWrapperBGFixed } from "../../StylesAppSpecific/GridHelperStyling";
import AdminUserReportQuotesViewModel from "../../ViewModels/Admin/AdminUserReportQuotesViewModel";
import { NFIPQuoteCard, PCPQuoteCard } from "../../Components/Results";
import { ResultQuoteModel } from "../../Models";
import { AddressHeader } from "../../AdminComponents/AdminDashboard/AddressHeader";
import { FilterAndSortBar } from "../../AdminComponents/AdminDashboard/FilterAndSortBar";

export const AdminUserReportQuotes: React.FC = () => {
    const adminClasses = useAdminStyles();
    const classes = useAdminUserReportQuotesStyles();
    const { history, location, match } = useRouter();
    const { id } = match.params as any;
    const [viewModel] = useState(() => new AdminUserReportQuotesViewModel(id));

    useEffect(() => {
        // If the viewmodel is to allow navigation. set the viewmodel router.
        viewModel.setRouter(history, location);

        // With SPAs, we need to move back to the top of the page.
        window.scrollTo(0, 0);
    }, []);

    const handleChatClick = (e: any) => {};

    const renderBackButtons = () => {
        return (
            <React.Fragment>
                <Grid xs={12} item>
                    <Button
                        className={clsx(adminClasses.navigationButton, adminClasses.navigationGroupUnderButton)}
                        onClick={viewModel.backToDashboard}
                        startIcon={<ChevronLeftIcon />}
                        variant="contained"
                    >
                        Back to reports
                    </Button>
                    <Button
                        className={clsx(adminClasses.navigationButton, adminClasses.navigationGroupOverButton)}
                        onClick={viewModel.backToUserReport}
                        startIcon={<ChevronLeftIcon />}
                        variant="contained"
                    >
                        {viewModel.name}
                    </Button>
                </Grid>
            </React.Fragment>
        );
    };

    const renderAddressAndSortBar = () => {
        return (
            <React.Fragment>
                <Grid xs={12} item>
                    <AddressHeader
                        address={viewModel.address}
                        rerun={() => viewModel.rerunViewModel?.rerun(viewModel.blobId)}
                        spinRerun={viewModel.rerunViewModel?.rerunning}
                        disabled={
                            (viewModel.rerunViewModel?.reran || viewModel.rerunViewModel?.rerunning) === undefined
                                ? true
                                : viewModel.rerunViewModel?.reran || viewModel.rerunViewModel?.rerunning
                        }
                    />
                    <FilterAndSortBar
                        deductible={viewModel.deductible}
                        sortOptionSelection={viewModel.sortOptionSelection}
                        sortOptions={viewModel.sortOptions}
                        setSortOption={viewModel.setSortOption}
                    />
                </Grid>
            </React.Fragment>
        );
    };

    const renderNFIPQuotes = () => {
        if (viewModel.canDisplayNFIPQuotes) {
            return (
                <Grid className={classes.resultList} xs={12} item>
                    <Typography className={classes.resultTitle} component="h1" variant="h2">
                        National Flood Insurance Program (NFIP) quote:
                    </Typography>
                    {viewModel.nfipQuotes.map((aQuote: ResultQuoteModel) => (
                        <NFIPQuoteCard
                            key={`nfiresult-${aQuote.id}`}
                            quoteDetail={aQuote}
                            position={1}
                            chatClick={handleChatClick}
                            callback={() => viewModel.selectQuote(aQuote.id)}
                            pdfCallBack={() => viewModel.selectQuoteForPDF(aQuote)}
                        />
                    ))}
                </Grid>
            );
        } else {
            return <React.Fragment />;
        }
    };

    const renderPrivateQuotes = () => {
        if (viewModel.canDisplayPrivateQuotes) {
            return (
                <Grid className={classes.resultList} xs={12} item>
                    <Typography className={classes.resultTitle} component="h1" variant="h2">
                        Private carrier policy quotes:
                    </Typography>
                    {viewModel.privateQuotes.map((aQuote: ResultQuoteModel) => {
                        return (
                            <PCPQuoteCard
                                key={`pcpresult-${aQuote.id}`}
                                quoteDetail={aQuote}
                                position={1}
                                callback={() => viewModel.selectQuote(aQuote.id)}
                                pdfCallBack={() => viewModel.selectQuoteForPDF(aQuote)}
                                adminQuote={viewModel.rerunViewModel?.adminQuoteIds.includes(aQuote.id)}
                            />
                        );
                    })}
                </Grid>
            );
        } else {
            return <React.Fragment />;
        }
    };

    return useObserver(() => (
        <React.Fragment>
            <DivWrapperBGFixed additionalcss={"background-image: url(" + Puddles + ");"} className={adminClasses.root}>
                <Box>
                    <Box>
                        <Grid container spacing={5}>
                            {renderBackButtons()}
                            {renderAddressAndSortBar()}
                            {renderNFIPQuotes()}
                            {renderPrivateQuotes()}
                        </Grid>
                    </Box>
                </Box>
            </DivWrapperBGFixed>
            <Footer isTemp={false} />
        </React.Fragment>
    ));
};
