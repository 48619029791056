import { ViewModelBase } from "../../Core/ViewModels/ViewModelBase";
import { NFIPartnerAgreementModel } from "Custom/Models";
import { FieldType } from "Core/Utils/Utils";
import * as AppUrls from "../Globals/AppUrls";
import { StoresInstance } from "Custom/Stores";
import { isNullOrUndefined } from "util";

export class NFIPartnerAgreementViewModel extends ViewModelBase<NFIPartnerAgreementModel> {
    constructor() {
        super(new NFIPartnerAgreementModel());
        this.setDecorators(NFIPartnerAgreementModel);

        if (isNullOrUndefined(StoresInstance.domain.AgencyDetailViewModel.model.id) && StoresInstance.domain.AccountStore.IsProAccount) {
            StoresInstance.domain.AgencyDetailViewModel.load();
        }
    }

    isFieldValid(fieldName: keyof FieldType<NFIPartnerAgreementModel>, value: any): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public async accept(imageBlob: Blob) {
        const data = new FormData();
        data.append("signature", imageBlob);
        data.append("agencyId", StoresInstance.domain.AccountStore.AgencyId!);

        const response = await this.Post(AppUrls.Server.Api.Pro.UploadSignature, data);

        return response;
    }

    beforeUpdate: undefined;
    afterUpdate: undefined;
}
