import { createContext } from "react";
import * as Utils from "Core/Utils/Utils";
import * as Models from "Custom/Models";
// Libs
// App
import { BaseStore } from "./BaseStore";
import { action, computed, observable } from "mobx";
import { Stores } from "../Stores";
import { InitialState } from "Custom/Models";
import { AccountStatus } from "Core/Models";
import { getHistory } from "Core/Utils/Utils";
import { QuoteResultViewModel } from "Custom/ViewModels";

export class AccountStore extends BaseStore {
    @observable public IsLoggedIn: boolean = false;
    @observable public UserName: string = "";
    @observable public DisplayName: string = "";
    @observable public UserRoles: string[] = [];
    @observable public UseAdminGrids: boolean = false;
    @observable public UseAdminControlsLight: boolean = false;
    @observable public FirstName: string = "";
    @observable public LastName: string = "";
    @observable public Email: string = "";
    @observable public Phone: string = "";
    @observable public Terms: boolean = false;
    @observable public AgencyId?: string;
    @observable public AgencyName?: string;
    @observable public IsProAccount: boolean = false;
    @observable public IsAgentAccount: boolean = false;
    @observable public UserAgencyId?: string;
    @observable public HasAcceptedAgencyAgreement: boolean = false;
    @observable public LogoutModalOpen = false;
    @observable public RealtorId?: string;
    @observable public RealtorName?: string;
    @observable public RealtorLogo?: string;
    @observable public RealtorUserId?: string;
    @observable public IsRealtorAccount: boolean = false;
    @observable public LogoutCallback = () => {};

    private jwt: string | null = null;
    private refreshToken: string | null = null;
    private refreshTokenExpiryDate: Date | null = null;
    private tokenViewModel = {};

    public constructor() {
        super();
    }

    public init(stores: Stores, initialState: InitialState) {
        if (initialState) {
            this.UseAdminGrids = initialState.appSettings.useAdminGrids === "true";
            this.UseAdminControlsLight = initialState.appSettings.useAdminControlsLight === "true";
            this.getLoginState(initialState.accountStatus);
            //this.tokenViewModel = new TokenViewModel();
        } else {
            this.doLogout();
        }
    }

    @computed
    public get getHasAcceptedAgencyAgreement() {
        return this.HasAcceptedAgencyAgreement;
    }

    @action setLogoutModalOpen(open: boolean) {
        this.LogoutModalOpen = open;
    }

    @action
    public setIsLoggedIn(state: boolean) {
        this.IsLoggedIn = state;
    }

    @action
    public setHasAcceptedAgencyAgreement(accepted: boolean) {
        this.HasAcceptedAgencyAgreement = accepted;
    }

    public isInRole = (role: string): boolean => {
        if (this.UserRoles && this.UserRoles.length > 0) {
            return this.UserRoles.includes(role);
        }

        return false;
    };

    @action
    public Logout = (callback: () => void = () => {}): void => {
        this.LogoutModalOpen = true;
        this.LogoutCallback = callback;
    };

    @action
    public doLogout(redirect: boolean = false) {
        localStorage.removeItem(".auth");
        this.jwt = "";
        document.cookie = ".refreshtoken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
        this.IsLoggedIn = false;
        this.UserName = "";
        this.DisplayName = "";
        this.UserRoles = [];
        this.FirstName = "";
        this.LastName = "";
        this.Email = "";
        this.Phone = "";
        this.AgencyId = undefined;
        this.AgencyName = undefined;
        this.IsProAccount = false;
        this.HasAcceptedAgencyAgreement = false;
        this.UserAgencyId = undefined;
        this.RealtorId = undefined;
        this.RealtorName = undefined;
        this.RealtorLogo = undefined;
        this.RealtorUserId = undefined;
        this.IsRealtorAccount = false;
        //(window as any).jwt = null;
        window.location.reload(true);
        window.location.href = "/";
    }

    public getJwt() {
        return this.jwt;
    }

    @action
    public getLoginState = (apiResult: AccountStatus) => {
        let jwt = apiResult?.jwt ?? "";
        if (!jwt || jwt.length === 0) {
            this.jwt = localStorage.getItem(".auth") as string;
            //this.refreshToken = localStorage.getItem(".refreshToken");
            //this.refreshTokenExpiryDate = new Date(localStorage.getItem(".refreshTokenExpiryDate") as string);
        } else {
            this.jwt = jwt;
        }

        // User Basics.
        this.FirstName = apiResult.firstName;
        this.LastName = apiResult.lastName;
        this.Email = apiResult.email;
        this.Phone = apiResult.phone;

        // Extras.
        this.Terms = apiResult.terms;
        this.AgencyId = apiResult.agencyId;
        this.AgencyName = apiResult.agencyName;
        this.IsProAccount = apiResult.isProAccount || false;
        this.HasAcceptedAgencyAgreement = apiResult.hasAcceptedAgencyAgreement || false;
        this.UserAgencyId = apiResult.userAgencyId;
        this.RealtorId = apiResult.realtorId;
        this.RealtorName = apiResult.realtorName;
        this.RealtorLogo = apiResult.realtorLogo;
        this.RealtorUserId = apiResult.realtorUserId;
        this.IsRealtorAccount = apiResult.isRealtorAccount || false;
        if (this.jwt && this.jwt !== "undefined" && this.jwt !== "null") {
            const data = Utils.parseJwt(this.jwt);
            localStorage.setItem(".auth", this.jwt);

            this.IsLoggedIn = true;
            this.UserName = data["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"] as string;
            this.DisplayName = data["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"] as string;
            this.UserRoles = data["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] as string[];
            // EN: For Debugging
            //(window as any).jwt = this.jwt;
        }
    };

    public addDlAuthCookie() {
        const val = `Dl-Auth=${this.jwt} ;secure ;samesite=strict ;path=/`;
        document.cookie = val;
        console.log(val, document.cookie);
    }

    @computed get GetUseAdminGrids(): boolean {
        return this.UseAdminGrids;
    }

    @computed get GetUseAdminControlsLight(): boolean {
        return this.UseAdminControlsLight;
    }
}
