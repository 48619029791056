import { makeStyles } from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";
import styled from "styled-components";

export const useOverallStatisticsStyles = makeStyles(theme => ({
    paper: {
        backgroundColor: "transparent",
        boxShadow: "none",
        display: "flex",
    },
    imageContainer: {
        alignItems: "center",
        backgroundColor: "transparent",
        display: "flex",
        height: "132px",
        minWidth: "132px",
        justifyContent: "center",
        width: "132px",
        "& img": {
            height: "auto",
            maxWidth: "100%",
        },
    },
    statisticsContainer: {
        alignItems: "center",
        display: "flex",
        flex: "1",
        margin: "5px 0px 5px 40px",
        ["@media (max-width:480px)"]: {
            margin: "5px 0px 5px 0px",
        },
    },
    statisticsSubContainer: {
        flex: "1",
    },
    title: {
        color: "#8CD2F7",
        fontSize: "18px",
        fontWeight: "bold",
    },
    text: {
        color: "white",
        fontSize: "48px",
        fontWeight: "bold",
        marginTop: "-6px",
    },
    quoteCounterContainer: {
        ["@media (max-width:480px)"]: {
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
        },
    },
}));

export const useDashboardStyles = makeStyles(theme => ({
    pageGrid: {
        "& .MuiGrid-item": {
            ["@media (max-width:767px)"]: {
                padding: "15px 10px",
            },
        },
        width: "100%",
        margin: "0px",
    },
}));

const insurerStatisticsHeaderBaackgroundColor = "#005FAB";

export const useInsurerStatisticsHeaderStyles = makeStyles(theme => ({
    paper: {
        alignItems: "center",
        backgroundColor: insurerStatisticsHeaderBaackgroundColor,
        boxShadow: "none",
        borderRadius: "0",
        display: "flex",
        height: "95px",
        padding: "20px",
    },
    title: {
        color: "white",
        fontSize: "24px",
        fontWeight: "bold",
    },
    download: {
        color: "white",
        fontSize: "18px",
        ["@media (max-width:767px)"]: {
            fontSize: "12px",
        },
    },
}));

const insurerStatisticsBodyDividerColor = "#129BDB";

export const useInsurerStatisticsBodyStyles = makeStyles(theme => ({
    paper: {
        backgroundColor: "transparent",
        boxShadow: "none",
        display: "flex",
    },
    imageContainer: {
        alignItems: "center",
        backgroundColor: "white",
        display: "flex",
        height: "132px",
        minWidth: "132px",
        justifyContent: "center",
        width: "132px",
        "& img": {
            height: "auto",
            maxWidth: "100%",
        },
        ["@media (max-width:767px)"]: {
            width: "100px",
            height: "100px",
            minWidth: "100px",
        },
    },
    textContainer: {
        display: "flex",
        flex: "1",
        flexDirection: "column",
        justifyContent: "center",
        margin: "5px 0px 5px 40px",
        ["@media (max-width:767px)"]: {
            margin: "5px 0px 5px 20px",
        },
    },
    title: {
        color: "white",
        flex: "1",
        fontSize: "18px",
        fontWeight: "bold",
    },
    solidDivider: {
        borderBottomColor: insurerStatisticsBodyDividerColor,
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        height: "1px",
    },
    statisticsContainer: {
        display: "flex",
        flexWrap: "wrap",
    },
    statisticsSubContainer: {
        flex: "1",
    },
    statisticsText: {
        color: "white",
        flex: "1",
        fontSize: "36px",
        fontWeight: "bold",
        "& span": {
            color: "#8CD2F7",
        },
        ["@media (max-width:767px)"]: {
            fontSize: "25px",
            marginRight: "10px",
        },
    },
    actionsContainer: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
    },
    actionContainer: {
        display: "flex",
        alignItems: "center",
    },
    settingsButton: {
        color: "#F2AF4C",
        margin: "0",
    },
    verifiedText: {
        color: "#FFF",
    },
    unverifiedText: {
        color: "#FF5454",
    },
    verifyButton: {
        color: "#FF5454",
        margin: "0",
    },
}));

const startNewQuoteCardBorderColor = "#129BDB";

export const startNewQuoteCardStyles = makeStyles(theme => ({
    paper: {
        alignItems: "center",
        backgroundColor: "#E7F6FE",
        borderColor: startNewQuoteCardBorderColor,
        borderRadius: "15px",
        borderStyle: "solid",
        borderWidth: "10px",
        display: "flex",
        marginRight: "45px",
        padding: "20px 30px",
        ["@media (max-width:629px)"]: {
            margin: "10px 30px 10px 0px",
            padding: "16px 12px",
        },
    },
    instructionContainer: {
        flex: "1",
        margin: "0 30px",
        ["@media (max-width:629px)"]: {
            margin: "0px 10px",
        },
    },
    firstInstruction: {
        color: "#129BDB",
        fontSize: "36px",
        fontWeight: "bold",
        lineHeight: "1",
        ["@media (max-width:629px)"]: {
            fontSize: "25px",
        },
    },
    input: {
        backgroundColor: "#ffffff",
        padding: "8px",
        fontSize: "14px",
        fontWeight: "normal",
        textAlign: "left",
        color: "rgba(0, 0, 0, 0.87)",
        border: "1px solid #8cd2f7",
        borderRadius: "2px",
        marginTop: "15px",
        width: "100%",
        "::placeholder": {
            color: "#bbbbbb",
        },
        ["@media (max-width:629px)"]: {
            marginTop: "5px",
        },
    },
    secondInstruction: {
        fontSize: "24px",
        fontWeight: "bold",
        lineHeight: "1",
        ["@media (max-width:629px)"]: {
            fontsize: "18px",
        },
    },
    buyButtonBoxItem: {
        marginLeft: "auto",
        marginRight: "-84px",
        ["@media (max-width:629px)"]: {
            marginRight: "-56px",
        },
    },
    buyButton: {
        backgroundColor: "#129BDB",
        borderRadius: "50%",
        color: "#FFFFFF",
        height: "100px",
        fontSize: "18px",
        fontWeight: "bold",
        lineHeight: "22px",
        margin: "0",
        padding: "0",
        textTransform: "none",
        width: "100px",
        "&:hover": {
            backgroundColor: "#0F89C2",
        },
        "&.Mui-disabled": {
            backgroundColor: "#93A0AB",
            opacity: "1",
        },
        ["@media (max-width:629px)"]: {
            width: "80px",
            height: "80px",
        },
    },
}));

export const modalStyle = makeStyles(theme => ({
    paper: {
        maxHeight: "100%",
        overflowY: "auto",
        position: "absolute",
        width: 720,
        backgroundColor: "#3A4650",
        boxShadow: theme.shadows[5],
        padding: "0",
    },
    header: {
        backgroundColor: "#005FAB",
        minHeight: "60px",
        padding: "15px 20px",
    },
    headerTitle: {
        color: "white",
        fontSize: "24px",
        fontWeight: "bold",
    },
    body: {
        display: "flex",
        flexDirection: "column",
        padding: "15px 40px 25px 40px",
        ["@media (max-width:629px)"]: {
            padding: "15px 20px 25px 20px",
        },
    },
    bodyText: {
        color: "white",
        /* fontSize: "21px",
		fontWeight: "bold", */
    },
    footer: {
        backgroundColor: "#666F77",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80px",
        padding: "15px 20px",
    },
    button: {
        borderRadius: "20px",
        color: "white",
        fontSize: "14px",
        fontWeight: "bold",
        height: "40px",
        margin: "0 10px",
        width: "120px",
    },
    okButton: {
        backgroundColor: "#129BDB",
        "&:hover": {
            backgroundColor: "#0F89C2",
        },
        "&.Mui-disabled": {
            backgroundColor: "#93A0AB",
            opacity: "1",
        },
    },
    cancelButton: {
        backgroundColor: "#333333",
        "&:hover": {
            backgroundColor: "#222222",
        },
        "&.Mui-disabled": {
            backgroundColor: "#93A0AB",
            opacity: "1",
        },
    },
    signatureLikeness: {
        color: "white",
        marginBottom: "15px",
        marginTop: "15px",
    },
}));

const carrierModalDividerColor = "#129BDB";

export const carrierModalStyle = makeStyles(theme => ({
    paper: {
        maxWidth: 720,
        width: "100%",
    },
    titleBoxItem: {
        margin: "15px 0",
    },
    inputBoxItem: {
        margin: "15px 0",
    },
    label: {
        color: "#FFF",
        display: "flex",
        flexDirection: "column",
        fontSize: "21px",
        fontWeight: "bold",
    },
    input: {
        marginTop: "5px",
        "&.MuiInputBase-root": {
            backgroundColor: "#FFF",
            border: "7px solid #F2AF4C",
            borderRadius: "15px",
            fontSize: "23px",
            fontWeight: "bold",
            "&.Mui-focused": {
                borderColor: "#D99D44",
            },
            "&:hover": {
                borderColor: "#D99D44",
            },
            "& input": {
                padding: "10px",
            },
            "& fieldset": {
                border: "none",
            },
        },
    },
    inputBlue: {
        "&.MuiInputBase-root": {
            border: "5px solid #129BDB",
            "&.Mui-focused": {
                borderColor: "#0F89C2",
            },
            "&:hover": {
                borderColor: "#0F89C2",
            },
        },
    },
    radioGroupBoxItem: {
        margin: "15px 0",
    },
    radio: {
        "&.MuiFormControlLabel-root": {
            "& .MuiFormControlLabel-label": {
                color: "#FFF",
                fontSize: "21px",
                lineHeight: "21px",
                marginLeft: "8px",
            },
        },
    },
    checkbox: {
        "&.MuiFormControlLabel-root": {
            "& .MuiFormControlLabel-label": {
                color: "#FFF",
                fontSize: "21px",
                lineHeight: "21px",
                marginLeft: "8px",
            },
        },
    },
    solidDivider: {
        borderBottomColor: carrierModalDividerColor,
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        display: "flex",
        flex: "1",
        height: "1px",
        margin: "15px 0",
    },
    agentAgencyText: {
        color: "#FFF",
        fontSize: "14px",
    },
    agreementBoxItem: {
        border: "5px solid #129BDB",
        borderRadius: "15px",
        backgroundColor: "#FFF",
        maxHeight: "240px",
        marginBottom: "15px",
        overflowY: "auto",
    },
    agreementText: {
        fontSize: "12px",
        padding: "20px",
    },
    signatureBoxItem: {
        marginBottom: "15px",
    },
}));

export const verificationModalStyle = makeStyles(theme => ({
    verifyButtonBoxItem: {
        display: "flex",
        justifyContent: "center",
        margin: "10px 0",
    },
    verifyButton: {
        backgroundColor: "#F2AF4C",
        borderRadius: "50%",
        color: "#FFFFFF",
        height: "64px",
        fontSize: "12px",
        fontWeight: "bold",
        lineHeight: "15px",
        margin: "0",
        padding: "0",
        textTransform: "none",
        width: "64px",
        "&:hover": {
            backgroundColor: "#D99D44",
        },
        "&.Mui-disabled": {
            backgroundColor: "#93A0AB",
            opacity: "1",
        },
    },
}));
