// Libraries
import React, { useContext } from "react";
import { useMediaQuery } from "Core/Utils/Media";

// Components
import { Footer } from "../Components/Footer";
import { AddressSelectorInputGoogle } from "../Components/AddressSelector";
import { GoogleMap } from "../Components/Map";
import { Grid } from "@material-ui/core";
import PXFuel from "Content/quoteBG.jpg";
import { StoresInstance } from "../Stores";
import { useRouter } from "Core/Utils";
import { useObserver } from "mobx-react-lite";
import { SelectWidth } from "Custom/Components/PropertyDetails";
import { EditableInput } from "Core/Components/EditableInput";
/* import { MapStore } from "Custom/Stores/Domain"; */

// Styling & images
import { DivWrapper, GridSpaced, MapWrapper, TitleWrapper, DivWrapperBGFixed } from "../StylesAppSpecific/GridHelperStyling";
import { ConfirmAddressPanel, PropertySelect, PropertyOwnerBtn, RealEstateBtn, InsuranceAgentBtn } from "Custom/StylesAppSpecific/StepsStyling";
import { HdrImgDisc, RoundLink } from "Custom/StylesAppSpecific/PageStyling";
import { Button } from "@material-ui/core";
import EnterYourLocation from "Content/EnterYourLocation.svg";

/**
 * This page is now depricated
 */
export const FloodInsuranceCoverage: React.FC = () => {
    const domainStores = StoresInstance.domain;
    const quoteRequestVM = domainStores.QuoteRequestViewModel;

    const { history } = useRouter();

    const requestPopulated: boolean = quoteRequestVM.getValue<string>("address").length > 0 ? true : false;
    domainStores.EstatedHasLoaded = false;
    let isMobile: boolean;
    isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;

    if (!requestPopulated) {
        history.push("./");
    }

    const contentHdr = () => (
        <>
            {isMobile ? (
                <>
                    <GridSpaced container margin={"0px auto"} additionalcss={"max-width: 688px; width: 100%;"}>
                        <GridSpaced item xs={12} sm={12} additionalcss={"text-align: center;"}>
                            <HdrImgDisc src={EnterYourLocation} alt={"MyFloodInsurance Logo"} />
                        </GridSpaced>
                    </GridSpaced>

                    <GridSpaced container margin={"0px auto"} additionalcss={"max-width: 740px; width: 100%;"}>
                        <Grid item xs={12} sm={12} justify={"center"}>
                            <TitleWrapper padding={"0"} margin={"0"} thinSubheading>
                                <h1>
                                    <span>Step 1:</span> Confirm your address
                                </h1>
                            </TitleWrapper>
                        </Grid>
                    </GridSpaced>
                </>
            ) : (
                <>
                    <h2>
                        <span>Step 1:</span> Confirm the address
                    </h2>
                    <p>
                        <span>Is this the property location?</span> If not please click and drag the location pin to the correct place.
                    </p>
                </>
            )}
        </>
    );

    const googleMapPlacement = (
        <>
            {isMobile ? (
                <>
                    <MapWrapper padding={"0"}>
                        <GoogleMap />
                    </MapWrapper>
                </>
            ) : (
                <>
                    <GoogleMap />
                </>
            )}
        </>
    );

    const formPlacement = (
        <>
            {isMobile ? (
                <>
                    <GridSpaced container margin={"0px auto"} additionalcss={"max-width: 780px; width: 100%;"}>
                        <GridSpaced item margin={"0px auto 10px"}>
                            <AddressSelectorInputGoogle />
                        </GridSpaced>
                    </GridSpaced>
                </>
            ) : (
                <>
                    <AddressSelectorInputGoogle />
                    <h3>Is this property a</h3>
                    <PropertySelect>
                        <EditableInput
                            viewModel={quoteRequestVM}
                            fieldName="month"
                            type="select"
                            selectItems={[
                                { key: "Single family residence", value: 0 },
                                { key: "Mobile/manufactured home", value: 1 },
                                { key: "Condominium", value: 2 },
                                { key: "Commercial property", value: 3 },
                                { key: "Residential condo building association policy", value: 4 },
                            ]}
                        />
                    </PropertySelect>
                </>
            )}
        </>
    );

    const handleClick = (e: string) => {
        console.log(e);
        history.push("/coverageoptions");
    };

    return useObserver(() => (
        <>
            <DivWrapperBGFixed additionalcss={"background-image: url(" + PXFuel + ");"}>
                {isMobile ? (
                    <>
                        {contentHdr()}
                        {formPlacement}
                        {googleMapPlacement}
                        <TitleWrapper padding={"0"} margin={"0"}>
                            <p>
                                <span>Is this the property location?</span> If not please click and drag the location pin to the correct place.
                            </p>
                            <RoundLink to="/coverageoptions" title="Next Step">
                                Next
                            </RoundLink>
                        </TitleWrapper>
                    </>
                ) : (
                    <>
                        <div style={{ paddingTop: "5%" }}>
                            <ConfirmAddressPanel>
                                <div className="confirmAddress">
                                    <div className="address">
                                        {contentHdr()}
                                        {formPlacement}
                                    </div>

                                    <div className="map">{googleMapPlacement}</div>
                                </div>
                                <div className="dashed confirmAddress">
                                    <PropertyOwnerBtn onClick={() => handleClick("a")}>
                                        <div>
                                            <span>Continue as a</span>
                                            Property owner
                                        </div>
                                    </PropertyOwnerBtn>
                                    <RealEstateBtn onClick={() => handleClick("b")}>
                                        <div>
                                            <span>Continue as a</span>
                                            Real Estate Agent
                                        </div>
                                    </RealEstateBtn>
                                    <InsuranceAgentBtn onClick={() => handleClick("c")}>
                                        <div>
                                            <span>Continue as a</span>
                                            Insurance Agent
                                        </div>
                                    </InsuranceAgentBtn>
                                </div>
                            </ConfirmAddressPanel>
                        </div>
                    </>
                )}
            </DivWrapperBGFixed>
            <Footer isTemp={false} />
        </>
    ));
};
