import styled from "styled-components";

export interface IComponentProps {
    padding?: string;
    margin?: string;
    fontcolor?: string;
    backgroundcolor?: string;
    additionalcss?: string;
}

export const SocialGroups: any = styled.div<IComponentProps>`
    img {
        margin: 0px 7px;
        vertical-align: middle;

        @media screen and (max-width: 767px) {
            width: 37px;
            max-height: 34px;
        }
    }

    @media screen and (max-width: 767px) {
        margin-bottom: 6px;
    }

    ${props => props.additionalcss};
`;

export const FootNav: any = styled.div<IComponentProps>`
    background-color: #11181d;
    text-align: left;
    font-weight: 100;
    font-size: 12px;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    letter-spacing: -0.3px;
    color: #ffffff;
    margin: 18px 0px 23px;

    a {
        display: block;
        width: 100%;
    }

    a,
    a:hover {
        color: #ffffff;
    }

    a:hover {
        text-decoration: underline;
    }

    h2 {
        width: 100%;
        margin: 0px 0px 14px;
        font-weight: 700;
        font-size: 14px;
        font-family: "Montserrat", Arial, Helvetica, sans-serif;
    }
    ${props => props.additionalcss}
`;
