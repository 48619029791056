// User interface friendly strings.
const PROPERTYOWNERSHIPTYPE_OWNANDOCCUPY = "I own and occupy this property";
const PROPERTYOWNERSHIPTYPE_OWNANDRENT = "I own this property and rent it out to someone else";
const PROPERTYOWNERSHIPTYPE_RENT = "I rent this property from someone else";
const PROPERTYOWNERSHIPTYPE_UNKNOWN = "Other, not sure";

// Transform by property owndership type.
export const getPropertyOwnershipType = (type: string): string => {
    let name = "";

    switch (type) {
        case "ownAndOccupy":
            name = PROPERTYOWNERSHIPTYPE_OWNANDOCCUPY;
            break;

        case "ownAndRent":
            name = PROPERTYOWNERSHIPTYPE_OWNANDRENT;
            break;

        case "rent":
            name = PROPERTYOWNERSHIPTYPE_RENT;
            break;

        default:
            name = PROPERTYOWNERSHIPTYPE_UNKNOWN;
    }

    return name;
};
