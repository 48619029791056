// Libraries
import React from "react";
import { Box, Typography, Paper } from "@material-ui/core";

// Custom
import { useInsurerStatisticsHeaderStyles } from "../../StylesAppSpecific/Agent/AgentDashboard";
import { StoresInstance } from "../../Stores/Stores";

import iconDownloadPDF from "Content/icon-downloadPDF.svg";
import iconAgreement from "Content/icon-agreement.svg";
import { useObserver } from "../../Globals/Base";
import { CarrierSettingsModal } from "./CarrierSettingsModal";
import AgentInsurerStatisticsViewModel from "Custom/ViewModels/Agent/AgentInsurerStatisticsViewModel";

interface IProps {}

export const InsurerHeader: React.FC<IProps> = (props: IProps) => {
    const classes = useInsurerStatisticsHeaderStyles();
    const [viewModel, setViewModel] = React.useState<AgentInsurerStatisticsViewModel | null>(null);

    const downloadPdf = async () => {
        const urlResult = await StoresInstance.domain.AgencyDetailViewModel.getAgreementPdfUrl();
        if (urlResult && urlResult.wasSuccessful) {
            open(urlResult.payload, "_blank");
        }
    };

    const openSignAreementModal = () => {
        setViewModel(new AgentInsurerStatisticsViewModel(true));
    };

    return useObserver(() => (
        <Paper className={classes.paper}>
            <Typography className={classes.title}>Carriers</Typography>
            {StoresInstance.domain.AccountStore.getHasAcceptedAgencyAgreement ? (
                <Box onClick={downloadPdf} display="flex" alignItems="center" marginLeft="auto" style={{ cursor: "pointer" }}>
                    <img src={iconDownloadPDF} alt="" style={{ paddingRight: "8px", paddingLeft: "7px" }} />
                    <Typography className={classes.download} display="inline">
                        My NFI Partner Agency agreement.pdf
                    </Typography>
                </Box>
            ) : (
                <Box onClick={openSignAreementModal} display="flex" alignItems="center" marginLeft="auto" style={{ cursor: "pointer" }}>
                    <img src={iconAgreement} alt="" style={{ paddingRight: "8px", paddingLeft: "7px" }} />
                    <Typography className={classes.download} display="inline">
                        Sign Up as a National Flood Insurance Partner Agent
                    </Typography>
                </Box>
            )}
            {!!viewModel && <CarrierSettingsModal viewModel={viewModel} />}
        </Paper>
    ));
};
