import React, { useMemo } from "react";

import { EditBarContainer } from ".";
import { useMediaQuery } from "Core/Utils";

export const MOBILEHEIGHT = 37;
export const DESKTOPHEIGHT = 58;

export const EditBar: React.FC<{}> = props => {
    const { children } = props;

    const isMobile = !!useMediaQuery("screen and (max-width: 767px)");

    const editBarHeight = useMemo(() => {
        if (isMobile) {
            const childCount = React.Children.count(children);
            let retVal = MOBILEHEIGHT * childCount + 1 * (childCount - 1);
            return childCount >= 2 ? retVal + 13 : retVal;
        } else {
            return DESKTOPHEIGHT;
        }
    }, [isMobile, children]);

    return (
        <>
            <EditBarContainer height={editBarHeight}>
                <div style={{ zIndex: 1 }}>{children}</div>
            </EditBarContainer>
        </>
    );
};
