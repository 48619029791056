import { AdminUserAndQuoteResultModel } from "../../../Models/AdminUserAndQuoteResultModel";

export default class AdminUserAndQuoteResultStore {
    // #region Public Interface

    public static get instance() {
        if (this._instance === undefined) {
            this._instance = new AdminUserAndQuoteResultStore();
        }

        return this._instance;
    }

    // #endregion Public Interface

    private static _instance: AdminUserAndQuoteResultStore;
    private _model: AdminUserAndQuoteResultModel;

    private constructor() {
        this._model = new AdminUserAndQuoteResultModel();
    }

    public get model(): AdminUserAndQuoteResultModel {
        return this._model;
    }
}
