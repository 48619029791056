// Libraries
import * as MobX from "mobx";

// Core
import { ModelBase } from "../../Core/Models/ModelBase";
import { isEmptyOrWhitespace } from "../../Core/Utils/Utils";

export class UserAgencyDetailsModel extends ModelBase<UserAgencyDetailsModel, UserAgencyDetailsModelDTO> {
    @MobX.observable
    public id = "";

    @MobX.observable
    public userAgencyId = "";

    @MobX.observable
    public insurerId = "";

    @MobX.observable
    public insurerName = "";

    @MobX.observable
    public agencyLogin = "";

    @MobX.observable
    public agencyPassword = "";

    @MobX.observable
    public agencyApiKey = "";

    @MobX.observable
    public agencyName = "";

    @MobX.observable
    public isNFIPartnerAgency = "";

    @MobX.observable
    public isLoginPassword = false;

    @MobX.observable
    public isValid = false;

    @MobX.observable
    public validatedDate = "";

    @MobX.observable
    public numberOfQuotesProduced = 0;

    @MobX.observable
    public numberOfQuotesSelected = 0;

    @MobX.observable
    public requiresAPIKey = false;

    @MobX.observable
    public requiresLoginNameAndPassword = false;

    @MobX.action
    public fromDto = (model: UserAgencyDetailsModelDTO): void => {
        this.id = model.id;
        this.userAgencyId = model.userAgencyId;
        this.insurerId = model.insurerId;
        this.insurerName = model.insurerName;
        this.agencyLogin = model.agencyLogin;
        this.agencyPassword = model.agencyPassword;
        this.agencyApiKey = model.agencyApiKey;
        this.agencyName = model.agencyName;
        this.isLoginPassword = model.isLoginPassword;
        this.isValid = model.isValid;
        this.validatedDate = model.validatedDate;
        this.numberOfQuotesProduced = model.numberOfQuotesProduced;
        this.numberOfQuotesSelected = model.numberOfQuotesSelected;
        this.requiresAPIKey = model.requiresAPIKey;
        this.requiresLoginNameAndPassword = model.requiresLoginNameAndPassword;

        // If there is no user agency detail id, the user has not chosen
        // an agency. Therefore the isNFIPartnerAgency property must be
        // set to an undefined value.
        this.isNFIPartnerAgency =
            isEmptyOrWhitespace(model.agencyApiKey) && isEmptyOrWhitespace(model.agencyLogin) && !model.isNFIPartnerAgency
                ? ""
                : model.isNFIPartnerAgency
                ? "option-nfipartnergency"
                : "option-carrieraccount";
    };

    public toDto = (): UserAgencyDetailsModelDTO => {
        return {
            id: this.id,
            userAgencyId: this.userAgencyId,
            insurerId: this.insurerId,
            insurerName: this.insurerName,
            agencyLogin: this.agencyLogin,
            agencyPassword: this.agencyPassword,
            agencyApiKey: this.agencyApiKey,
            agencyName: this.agencyName,
            isNFIPartnerAgency: this.isNFIPartnerAgency === "option-nfipartnergency",
            isLoginPassword: this.isLoginPassword,
            isValid: this.isValid,
            validatedDate: this.validatedDate,
            numberOfQuotesProduced: this.numberOfQuotesProduced,
            numberOfQuotesSelected: this.numberOfQuotesSelected,
            requiresAPIKey: this.requiresAPIKey,
            requiresLoginNameAndPassword: this.requiresLoginNameAndPassword,
        };
    };
}

export interface UserAgencyDetailsModelDTO {
    id: string;
    userAgencyId: string;
    insurerId: string;
    insurerName: string;
    agencyLogin: string;
    agencyPassword: string;
    agencyApiKey: string;
    agencyName: string;
    isNFIPartnerAgency: boolean;
    isLoginPassword: boolean;
    isValid: boolean;
    validatedDate: string;
    numberOfQuotesProduced: number;
    numberOfQuotesSelected: number;
    requiresAPIKey: boolean;
    requiresLoginNameAndPassword: boolean;
}
