import * as MobX from "mobx";
import { ModelBase } from "../../Core/Models/ModelBase";

export class WidgetModel extends ModelBase<WidgetModel, WidgetModelDTO> {
    // #region Default State

    public static readonly MODEL_DEFAULT_ID = undefined;
    public static readonly MODEL_DEFAULT_REFERRERURI = "";
    public static readonly MODEL_DEFAULT_CREATEDDATE = "";
    public static readonly MODEL_DEFAULT_ISENABLED = false;
    public static readonly MODEL_DEFAULT_ISDELETED = false;
    public static readonly MODEL_DEFAULT_REFERRALS = 0;

    // #endregion Default State

    @MobX.observable
    public id: string | undefined = WidgetModel.MODEL_DEFAULT_ID;

    @MobX.observable
    public referrerUri: string = WidgetModel.MODEL_DEFAULT_REFERRERURI;

    @MobX.observable
    public referrals: number = WidgetModel.MODEL_DEFAULT_REFERRALS;

    @MobX.observable
    public createdDate: string = WidgetModel.MODEL_DEFAULT_CREATEDDATE;

    @MobX.observable
    public isEnabled: boolean = WidgetModel.MODEL_DEFAULT_ISENABLED;

    @MobX.observable
    public isDeleted: boolean = WidgetModel.MODEL_DEFAULT_ISDELETED;

    @MobX.action
    public fromDto = (model: WidgetModelDTO): void => {
        this.id = model.id;
        this.referrerUri = model.referrerUri;
        this.referrals = model.referrals;
        this.createdDate = model.createdDate;
        this.isEnabled = model.isEnabled;
        this.isDeleted = model.isDeleted;
    };

    public toDto = (): WidgetModelDTO => {
        return {
            id: this.id,
            referrerUri: this.referrerUri,
            referrals: this.referrals,
            createdDate: this.createdDate,
            isEnabled: this.isEnabled,
            isDeleted: this.isDeleted,
        };
    };

    @MobX.action
    public reset = () => {
        this.id = WidgetModel.MODEL_DEFAULT_ID;
        this.referrerUri = WidgetModel.MODEL_DEFAULT_REFERRERURI;
        this.referrals = WidgetModel.MODEL_DEFAULT_REFERRALS;
        this.createdDate = WidgetModel.MODEL_DEFAULT_CREATEDDATE;
        this.isEnabled = WidgetModel.MODEL_DEFAULT_ISENABLED;
        this.isDeleted = WidgetModel.MODEL_DEFAULT_ISDELETED;
    };

    @MobX.action
    public setIsEnabled = (isEnabled: boolean): void => {
        this.isEnabled = isEnabled;
    };
}

export interface WidgetModelDTO {
    id?: string;
    referrerUri: string;
    referrals: number;
    createdDate: string;
    isEnabled: boolean;
    isDeleted: boolean;
}
