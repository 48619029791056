// Core
import { FieldType, isEmptyOrWhitespace } from "../../Core/Utils/Utils";
import { ViewModelBase } from "../../Core/ViewModels";

// Custom
import { Server } from "../Globals/AppUrls";
import { StatesModel, StatesModelDTO, PageEnquiryModel } from "../Models";

export default class StateViewModel extends ViewModelBase<StatesModel> {
    constructor(link: string) {
        super(new StatesModel());

        this.setDecorators(StatesModel);

        // Load state.
        if (!isEmptyOrWhitespace(link)) {
            this.load(link);
        }
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #region Server Api

    public load = async (link: string): Promise<void> => {
        try {
            this.setIsLoading(true);

            const enquiryModel: PageEnquiryModel = { viewHidden: false, permaLink: link };
            const apiResult = await this.Post<StatesModelDTO>(Server.Api.Pages.StateItem, enquiryModel);

            if (apiResult.wasSuccessful) {
                this.model.fromDto(apiResult.payload);
            } else {
                // Error. What to do?
                this.setIsErrored(true);
                this.history.push("/");
            }
        } catch (exception) {
            // Error. What to do?
            this.setIsErrored(true);
            this.history.push("/");
        } finally {
            this.setIsLoading(false);
        }
    };

    // #endregion Server Api
}
