// Libraries
import React, { useState } from "react";
import { styled, useObserver } from "../../Globals/Base";

// Styling & images
import { ContactDetailV2Style, ContactDetailV2TextAreaStyle } from "../../StylesAppSpecific/FormStyling";
// App
import { ContactUsModel } from "../../Models";
import { ContactUsViewModel } from "../../ViewModels/ContactUsViewModel";
// Components
import { EditableInput } from "../../../Core/Components/EditableInput";
import { Box } from "@material-ui/core";

interface IProps {
    viewModel: ContactUsViewModel;
}

export const ContactDetailV2: React.FC<IProps> = props => {
    const { viewModel } = props;

    return useObserver(() => (
        <>
            <FormRowWrapper>
                <ContactDetailStyle2>
                    <h2 className="contact">I am...*</h2>
                    <EditableInput<ContactUsModel>
                        viewModel={viewModel}
                        fieldName="contactType"
                        type="select"
                        selectItems={[
                            {
                                key: "A policy holder",
                                value: "A policy holder",
                            },
                            {
                                key: "Looking for flood insurance",
                                value: "Looking for flood insurance",
                            },
                            {
                                key: "An insurance carrier",
                                value: "An insurance carrier",
                            },
                            {
                                key: "An insurance agent",
                                value: "An insurance agent",
                            },
                            {
                                key: "A member of the media",
                                value: "A member of the media",
                            },
                        ]}
                    />
                    <div className="contact-bottom" />
                </ContactDetailStyle2>

                <div className="placeholder-div"></div>
            </FormRowWrapper>

            <FormRowWrapper>
                <ContactDetailStyle2>
                    <h2 className="contact">First name*</h2>
                    <EditableInput<ContactUsModel>
                        viewModel={viewModel}
                        type="text"
                        fieldName="firstName"
                        fullwidth
                        inputProps={{
                            placeholder: "Your first name *",
                            autoComplete: "given-name",
                        }}
                    />
                    <div className="contact-bottom" />
                </ContactDetailStyle2>

                <ContactDetailStyle2>
                    <h2 className="contact">Last name*</h2>
                    <EditableInput<ContactUsModel>
                        viewModel={viewModel}
                        type="text"
                        fieldName="lastName"
                        fullwidth
                        inputProps={{
                            placeholder: "Your last name *",
                            autoComplete: "family-name",
                        }}
                    />
                    <div className="contact-bottom" />
                </ContactDetailStyle2>
            </FormRowWrapper>

            <FormRowWrapper>
                <ContactDetailStyle2>
                    <h2 className="contact">Contact email*</h2>
                    <EditableInput<ContactUsModel>
                        viewModel={viewModel}
                        type="text"
                        fieldName="email"
                        fullwidth
                        inputProps={{
                            placeholder: "Contact email address *",
                            autoComplete: "email",
                        }}
                    />
                    <div className="contact-bottom" />
                </ContactDetailStyle2>
                <ContactDetailStyle2>
                    <h2 className="contact">Contact number*</h2>
                    <EditableInput<ContactUsModel>
                        viewModel={viewModel}
                        type="text"
                        fieldName="tel"
                        fullwidth
                        inputProps={{
                            placeholder: "Contact phone number * eg. 123-456-7890",
                            autoComplete: "tel",
                        }}
                    />
                    <div className="contact-bottom" />
                </ContactDetailStyle2>
            </FormRowWrapper>

            <ContactDetailV2TextAreaStyle>
                <ContactDetailStyle2>
                    <h2 className="contact">Your message*</h2>
                    <EditableInput<ContactUsModel>
                        viewModel={viewModel}
                        type="text"
                        fieldName="message"
                        fullwidth
                        inputProps={{ placeholder: "Your message*", multiline: true }}
                    />
                    <div className="contact-bottom" />
                </ContactDetailStyle2>
            </ContactDetailV2TextAreaStyle>
        </>
    ));
};

const ContactDetailStyle2 = styled(ContactDetailV2Style)`
    display: flex;
    flex-wrap: wrap;

    .MuiSelect-root {
        font-size: 14px;
    }

    .MuiInput-root {
        font-size: 14px;
    }

    .MuiFormControl-root {
        width: 100%;
    }

    .MuiInputBase-root {
        margin-top: 0px;

        .MuiSelect-icon {
            top: 50%;
        }
    }

    .MuiInput-underline:before {
        border: none !important;
    }

    .MuiInput-underline {
        border: none !important;
    }

    .MuiInput-underline:after {
        border: none !important;
    }

    textarea {
        height: 130px !important;
    }

    @media (min-width: 768px) {
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 0px;

        > h2 {
            /*margin-left: 20px;*/
            /*margin-right: 20px;*/
        }

        > div {
            /*width: auto !important;*/
            /*flex-basis: calc(50% - 40px);*/
            /*margin: 0 20px;*/
        }
    }

    @media (max-width: 767px) {
        input {
            font-size: calc(12px + (21 - 12) * ((100vw - 350px) / (1366 - 350))) !important;
        }

        > div:not(:last-of-type) {
            padding: 10px 20px;
        }

        > div:first-of-type {
            margin-bottom: 20px;
        }
    }
`;

const FormRowWrapper = styled(Box)`
    display: flex;

    .placeholder-div {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    > div {
        > div {
            width: 100% !important;
            flex-basis: 100%;
        }
    }

    @media (min-width: 768px) {
        flex-direction: row;
    }

    @media (max-width: 767px) {
        flex-direction: column;
    }
`;
