import { DESKTOPHEIGHT, MOBILEHEIGHT } from "./EditBar";

import styled from "styled-components";

interface IEditBarProps {
    height: number;
}

export const EditBarContainer = styled.div<IEditBarProps>`
    padding-bottom: ${props => props.height}px;

    > div {
        position: fixed;
        display: flex;
        justify-content: center;
        width: 100vw;

        @media (min-width: 768px) {
            height: ${DESKTOPHEIGHT}px;
            background-color: #005fab;
            border-bottom: 1px solid #8cd2f7;
        }

        @media (max-width: 767px) {
            flex-direction: column;

            > div {
                margin-bottom: 1px;
                height: ${MOBILEHEIGHT}px;

                :last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
`;

export const LocationEditContainer = styled.p`
    font-size: 12px;
    margin: 0;
    color: #fff;

    @media (max-width: 767px) {
        font-size: 10px;
    }
`;

export const CoverageEditContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px) {
        justify-content: flex-start;
    }

    p {
        margin: 0;
        font-size: 12px;
        color: #fff;

        @media (max-width: 767px) {
            font-size: 10px;
        }
    }

    div.labels {
        p {
            font-weight: bold;
            white-space: nowrap;
        }
    }

    div.values {
        margin-left: 10px;
        text-align: right;
    }
`;
