import * as MobX from "mobx";

import { IsEmail, IsNotEmpty, IsPhoneNumber } from "class-validator";

import { ModelBase } from "../../Core/Models/ModelBase";

export class CityModel extends ModelBase<CityModel, CityModelDTO> {
    // #region Default State

    public static readonly MODEL_DEFAULT_ID = undefined;
    public static readonly MODEL_DEFAULT_TITLE = "";
    public static readonly MODEL_DEFAULT_IMAGE = "";
    public static readonly MODEL_DEFAULT_PERMALINK = "";
    public static readonly MODEL_DEFAULT_METADESCRIPTION = "";
    public static readonly MODEL_DEFAULT_PAGEBODY = "";
    public static readonly MODEL_DEFAULT_STATESID = "";
    public static readonly MODEL_DEFAULT_UPDATEDBY = "";
    public static readonly MODEL_DEFAULT_UPDATEDDATE = "";
    public static readonly MODEL_DEFAULT_CREATEDDATE = "";
    public static readonly MODEL_DEFAULT_ISHIDDEN = false;

    // #endregion Default State

    @MobX.observable
    public id: string | undefined = CityModel.MODEL_DEFAULT_ID;

    @MobX.observable
    public title: string = CityModel.MODEL_DEFAULT_TITLE;

    @MobX.observable
    public image: string = CityModel.MODEL_DEFAULT_IMAGE;

    @MobX.observable
    @IsNotEmpty({ message: "A valid Link is needed eg.. my-flood-insurance" })
    public permaLink: string = CityModel.MODEL_DEFAULT_PERMALINK;

    @MobX.observable
    public metaDescription: string = CityModel.MODEL_DEFAULT_METADESCRIPTION;

    @MobX.observable
    public pageBody: string = CityModel.MODEL_DEFAULT_PAGEBODY;

    @MobX.observable
    public statesId: string = CityModel.MODEL_DEFAULT_STATESID;

    @MobX.observable
    public updatedBy: string = CityModel.MODEL_DEFAULT_UPDATEDBY;

    @MobX.observable
    public updatedDate: string = CityModel.MODEL_DEFAULT_UPDATEDDATE;

    @MobX.observable
    public createdDate: string = CityModel.MODEL_DEFAULT_CREATEDDATE;

    @MobX.observable
    public isHidden: boolean = CityModel.MODEL_DEFAULT_ISHIDDEN;

    @MobX.action
    public fromDto = (model: CityModelDTO): void => {
        this.id = model.id;
        this.title = model.title;
        this.image = model.image;
        this.permaLink = model.permaLink;
        this.metaDescription = model.metaDescription;
        this.pageBody = model.pageBody;
        this.statesId = model.statesId;
        this.updatedBy = model.updatedBy;
        this.updatedDate = model.updatedDate;
        this.createdDate = model.createdDate;
        this.isHidden = model.isHidden;
    };

    public toDto = (): CityModelDTO => {
        return {
            id: this.id,
            title: this.title,
            image: this.image,
            permaLink: this.permaLink,
            metaDescription: this.metaDescription,
            pageBody: this.pageBody,
            statesId: this.statesId,
            updatedBy: this.updatedBy,
            updatedDate: this.updatedDate,
            createdDate: this.createdDate,
            isHidden: this.isHidden,
        };
    };

    @MobX.action
    public reset = () => {
        this.id = CityModel.MODEL_DEFAULT_ID;
        this.title = CityModel.MODEL_DEFAULT_TITLE;
        this.image = CityModel.MODEL_DEFAULT_IMAGE;
        this.permaLink = CityModel.MODEL_DEFAULT_PERMALINK;
        this.metaDescription = CityModel.MODEL_DEFAULT_METADESCRIPTION;
        this.pageBody = CityModel.MODEL_DEFAULT_PAGEBODY;
        this.statesId = CityModel.MODEL_DEFAULT_STATESID;
        this.updatedBy = CityModel.MODEL_DEFAULT_UPDATEDBY;
        this.updatedDate = CityModel.MODEL_DEFAULT_UPDATEDDATE;
        this.createdDate = CityModel.MODEL_DEFAULT_CREATEDDATE;
        this.isHidden = CityModel.MODEL_DEFAULT_ISHIDDEN;
    };

    @MobX.action
    public setPageBody = (pageBody: string): void => {
        this.pageBody = pageBody;
    };

    @MobX.action
    public setIsHidden = (isHidden: boolean): void => {
        this.isHidden = isHidden;
    };

    @MobX.action
    public setImage = (image: string): void => {
        this.image = image;
    };
}

export interface CityModelDTO {
    id?: string;
    title: string;
    image: string;
    permaLink: string;
    metaDescription: string;
    pageBody: string;
    statesId: string;
    updatedBy: string;
    updatedDate: string;
    createdDate: string;
    isHidden: boolean;
}
