import { Button, Grid } from "@material-ui/core";
import React from "react";
import iconProRegister from "Content/icon-proRegister.svg";
import prousericon from "Content/prouser-icon.svg";
import { styled } from "../../Globals/Base";
import { useRouter } from "Core/Utils";
import { useMediaQuery } from "Core/Utils/Media";
import { UspPanel } from "Custom/StylesAppSpecific/StepsStyling";

export const SignUpPro: React.FC = () => {
    const { history } = useRouter();

    let isMobile: boolean;
    isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;
    let isiPhone: boolean;
    isiPhone = useMediaQuery("screen and (min-width: 350px) and (max-width: 414px)") ? true : false;

    return (
        <>
            {isMobile ? (
                <Container>
                    <div className="flexrow">
                        <img src={iconProRegister} alt="My Flood Insurance Pro" />
                        <div className="copy">
                            <h2>Need flood coverage for your clients?</h2>
                            <p>Are you an Insurance Agent?</p>
                        </div>
                    </div>
                    <SignUpButton variant="contained" onClick={() => history.push("/register")}>
                        Sign up now for our PRO account
                    </SignUpButton>
                </Container>
            ) : (
                <UspPanel>
                    <img src={iconProRegister} alt="My Flood Insurance Pro" style={{ width: "87px", height: "auto" }} />
                    <div className="textcontainer">
                        <h2>Need flood coverage for your clients?</h2>
                        <p>Are you an Insurance Agent?</p>
                    </div>
                    <SignUpButton variant="contained" onClick={() => history.push("/register")}>
                        Sign up now for our PRO account
                    </SignUpButton>
                </UspPanel>
            )}
        </>
    );
};

const SignUpButton = styled(Button)`
    background-color: #129bdb;
    border-radius: 1000px;
    text-transform: none;
    font-weight: bold;
    color: white;
    margin: 40px 0 100px;
    padding: 14px 40px 15px;
    font-size: 1.5rem;
    line-height: 100%;

    @media (max-width: 767px) {
        font-size: 0.85rem;
        width: 335px;
        margin: 20px 0px 0px;
    }
`;

const Container = styled.div`
    width: 100%;
    text-align: center;

    h2 {
        margin: 0;
        padding: 72px 0 0;
        font-size: 2rem;
    }

    p {
        margin-bottom: 0;
        padding-bottom: 35px;
        font-size: 1rem;
        font-weight: bold;
    }

    div.flex {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    @media (max-width: 767px) {
        padding: 20px 0px;
    }

    .flexrow {
        display: flex;
        flex-direction: row;
        max-width: 335px;
        margin: 0 auto;

        .copy {
            display: flex;
            flex-direction: column;
            margin: 0px 0px 0px 20px;
            h2,
            p {
                text-align: left;
                font-size: 20px;
                letter-spacing: -1pt;
                line-height: 1;
                margin: 0px;
                padding: 0px;
                padding: 5px 0px 0px;
            }

            p {
                font-size: 14px;
                letter-spacing: -0.7pt;
            }
        }

        img {
            width: 71px;
        }
    }
`;
