import { makeStyles } from "@material-ui/core";

export const useResultsStyles = makeStyles(theme => ({
    background: {
        paddingBottom: "20px !important",
    },
    backgroundChild: {
        margin: "40px 20px 0 20px",
        [theme.breakpoints.down("xs")]: {
            margin: "20px 20px 0 20px",
        },
    },
    resultTitle: {
        marginBottom: "14px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "14px",
        },
    },
    resultList: {
        marginBottom: "35px",
    },
}));
