// Libraries
import React from "react";
import { useMediaQuery } from "Core/Utils/Media";
import { Helmet } from "react-helmet";

// Components
import { Footer } from "../Components/Footer";
import { ContactForm } from "../Components/ContactForm";
import { HomeUSP } from "Custom/Components/USPGroups/HomeUSP";

// Styling & images
import { Grid } from "@material-ui/core";
import { PreambleText, HdrImg, H1WithStyle, H1Hidden } from "../StylesAppSpecific/PageStyling";
import { DivWrapper, GridSpaced } from "../StylesAppSpecific/GridHelperStyling";

import FamilyPicture from "Content/homeBG.jpg";
import Puddles from "Content/homeBG2.png";
import MFILogodarkBG from "Content/logoForDarkBG.png";
import { HomeVerticalCenter } from "Custom/StylesAppSpecific/HomeStyling";

export const Holding: React.FC = () => {
    let isMobile: boolean;
    isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;

    let panelHeight: string = isMobile ? "min-height: 100vh;" : "min-height: 86vh;";

    const contentHdr = (
        <>
            {!isMobile && (
                <>
                    <GridSpaced container margin={"0px auto"} additionalcss={"max-width: 688px; width: 100%;"}>
                        <GridSpaced item xs={12} sm={12} additionalcss={"margin-top: 20px; text-align: center;"}>
                            <HdrImg margin={"30px auto"} maxwidth={"239px"} src={MFILogodarkBG} alt={"MyFloodInsurance Logo"} />
                        </GridSpaced>
                    </GridSpaced>

                    <GridSpaced container margin={"0px auto"} additionalcss={"max-width: 740px; width: 100%;"}>
                        <Grid item xs={12} sm={12}>
                            <PreambleText padding={"0"} margin={"0px 0px 27px"} additionalcss={"line-height: 1.1;"}>
                                <span>Coming soon</span> a revolutionary new way of buying flood insurance
                                <br />
                                How will it work?
                            </PreambleText>
                        </Grid>
                    </GridSpaced>
                </>
            )}
            {isMobile && (
                <>
                    <GridSpaced container margin={"0px auto"} additionalcss={"max-width: 350px; width: 100%;"}>
                        <Grid item xs={12} sm={12}>
                            <H1WithStyle margin={"12px auto"} additionalcss={"color: #129bdb; text-align: center;"}>
                                Coming soon
                            </H1WithStyle>
                            <PreambleText padding={"0"} margin={"0px 0px 15px"} fontsize={"13px"} lineheight={"1.5"}>
                                A revolutionary new way of buying flood insurance
                                <br />
                                How will it work?
                            </PreambleText>
                        </Grid>
                    </GridSpaced>
                </>
            )}
        </>
    );

    const formPlacement = (
        <>
            <GridSpaced container margin={"0px auto"} additionalcss={"max-width: 688px; width: 100%;"}>
                {isMobile && (
                    <>
                        <GridSpaced item margin={"0px auto"}>
                            <ContactForm />
                        </GridSpaced>
                    </>
                )}

                {!isMobile && (
                    <>
                        <GridSpaced item margin={"0px auto 70px"}>
                            <ContactForm />
                        </GridSpaced>
                    </>
                )}
            </GridSpaced>
        </>
    );

    const contentTwo = <></>;

    return (
        <>
            <Helmet>
                <title>My Flood Insurance - Instant Flood Insurance Quotes</title>
                <meta name="description" content="My Flood Insurance - a revolutionary new way of buying flood insurance" />
            </Helmet>

            <DivWrapper
                additionalcss={"background-image: linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url(" + FamilyPicture + "); " + panelHeight}
            >
                <HomeVerticalCenter>
                    {contentHdr}
                    <HomeUSP />
                    <H1Hidden>Coming soon - a revolutionary new way of buying flood insurance</H1Hidden>
                    {formPlacement}
                </HomeVerticalCenter>
            </DivWrapper>

            {/* 			<DivWrapper additionalcss={"background-image: url(" + Puddles + "); " + panelHeight}>
				<DivWrapper backgroundcolor={"rgba(27, 43, 55, 0.75)"}>{contentTwo}</DivWrapper>
			</DivWrapper>  */}
            <Footer isTemp={true} />
        </>
    );
};
