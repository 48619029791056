// Libraries
import * as MobX from "mobx";

// Core
import { ModelBase } from "../../Core/Models/ModelBase";
import { ResultModel, QuoteResponseModelDTO, QuoteRequestModelV2 } from ".";

export class AgentUserAndQuoteResultModel extends ModelBase<AgentUserAndQuoteResultModel, AgentUserAndQuoteResultModelDTO> {
    // #region User

    @MobX.observable
    public id: string = "";

    @MobX.observable
    public firstName: string = "";

    @MobX.observable
    public lastName: string = "";

    @MobX.observable
    public userName: string = "";

    @MobX.observable
    public phoneNumber: string = "";

    // #endregion User

    // #region Quote Details

    @MobX.observable
    public blobId: string = "";

    // #endregion Quote Details

    // #region Quote Request

    @MobX.observable
    public deductible: number = 0;

    @MobX.observable
    public request: QuoteRequestModelV2 = new QuoteRequestModelV2();

    // #endregion Quote Request

    // #region Insurer Results

    @MobX.observable
    public aonEdgeResult: ResultModel = new ResultModel();

    @MobX.observable
    public argeniaResult: ResultModel = new ResultModel();

    @MobX.observable
    public neptuneResult: ResultModel = new ResultModel();

    @MobX.observable
    public sterlingResult: ResultModel = new ResultModel();

    @MobX.observable
    public selectiveResult: ResultModel = new ResultModel();

    @MobX.observable
    public wrightResult: ResultModel = new ResultModel();

    @MobX.observable
    public wrightPrivateFloodResult: ResultModel = new ResultModel();

    @MobX.observable
    public hiscoxFloodPlusResult: ResultModel = new ResultModel();

    @MobX.observable
    public fRSResult: ResultModel = new ResultModel();

    @MobX.observable
    public zurichResult: ResultModel = new ResultModel();

    // #endregion Insurer Results

    @MobX.action
    public fromDto = (model: AgentUserAndQuoteResultModelDTO): void => {
        this.id = model.id;
        this.firstName = model.firstName;
        this.lastName = model.lastName;
        this.userName = model.userName;
        this.phoneNumber = model.phoneNumber;
        this.blobId = model.blobId;
        this.deductible = model.deductible;
        this.request = model.request;

        for (let key in model.result) {
            if (model.result.hasOwnProperty(key)) {
                this[key] = model.result[key];
            }
        }
    };

    public toDto = (): AgentUserAndQuoteResultModelDTO => {
        throw "toDto is not supported";
    };

    @MobX.action
    public reset = (): void => {
        this.id = "";
        this.firstName = "";
        this.lastName = "";
        this.userName = "";
        this.phoneNumber = "";
        this.blobId = "";
        this.deductible = 0;
        this.request = new QuoteRequestModelV2();

        this.aonEdgeResult = new ResultModel();
        this.argeniaResult = new ResultModel();
        this.neptuneResult = new ResultModel();
        this.sterlingResult = new ResultModel();
        this.selectiveResult = new ResultModel();
        this.wrightResult = new ResultModel();
        this.wrightPrivateFloodResult = new ResultModel();
        this.hiscoxFloodPlusResult = new ResultModel();
        this.fRSResult = new ResultModel();
        this.zurichResult = new ResultModel();
    };
}

export interface AgentUserAndQuoteResultModelDTO {
    id: string;
    firstName: string;
    lastName: string;
    userName: string;
    phoneNumber: string;
    blobId: string;
    deductible: number;
    request: QuoteRequestModelV2;
    result: QuoteResponseModelDTO;
}
