import React from "react";
import { QuoteRequestViewModel } from "../../ViewModels/QuoteRequestViewModel";
import { SimpleQuestion } from "./SimpleQuestion";
import iconCalendar from "Content/calendar.svg";
import { isNullOrEmpty } from "../../../Core/Utils/Utils";
import { StyledNumberInput, RadioYesNo, LargeMultichoiceOption, CollapsableLargeMultichoice, LargeMultichoice } from "../Forms";
import imageStories from "Content/icon-howManyStories.svg";
import styled from "styled-components";
import { ContactDetailStyle } from "Custom/StylesAppSpecific/FormStyling";
import { EditableInput } from "Core/Components/EditableInput";
import iconSqrFootage from "Content/icon-SqrFootage.svg";
import iconOverWater from "Content/icon-OverWater.svg";
import iconMobile from "Content/icon-mobileHome.svg";
import iconGarage from "Content/icon-haveGarage.svg";
import iconSteel from "Content/icon-steel.svg";
import iconMasonry from "Content/icon-masonry.svg";
import iconBrickVeneer from "Content/icon-brickVeneer.svg";
import iconFrame from "Content/icon-frame.svg";
import iconLog from "Content/icon-log.svg";
import iconOther from "Content/icon-other.svg";
import iconGenericHouse from "Content/icon-genericHouse.svg";
import foundationBasement from "Content/foundationBasement.svg";
import foundationCrawlspace from "Content/foundationCrawlspace.svg";
import foundationPiers from "Content/foundationPiers.svg";
import foundationSlab from "Content/foundationSlab.svg";
import { QuoteRequestModelV2 } from "Custom/Models";
import iconUnfinished from "Content/icon-unfinished.svg";
import iconLivingSpace from "Content/icon-livingSpace.svg";
import iconGameRoom from "Content/icon-gameRoom.svg";
import iconBathroom from "Content/icon-Bathroom.svg";
import iconEntrance from "Content/icon-entrance.svg";
import iconUsedForStorage from "Content/icon-usedForStorage.svg";
import iconExistingProperty from "Content/icon-existingProperty.svg";
import iconNewProperty from "Content/icon-newProperty.svg";
import { useObserver } from "mobx-react-lite";
import { useMediaQuery } from "../../../Core/Utils/Media/mediaQuery";
import { isNullOrUndefined } from "util";

interface IProps {
    viewModel: QuoteRequestViewModel;
}

interface ICollapsableProps extends IProps {
    disableCollapse?: boolean;
    value?: string;
}

interface ISelectPlaceholderStylesProps {
    placeholder: any;
}

const SelectPlaceholderStyles = styled.div<ISelectPlaceholderStylesProps>`
    .MuiSelect-select {
        color: ${({ placeholder }) => (placeholder ? "#93A0AB" : "inherit")};
        font-weight: ${({ placeholder }) => (placeholder ? "500 !important" : undefined)};
    }
`;

interface ISelectWidthProps {
    minWidth: number;
    additionalcss?: string;
}

export const SelectWidth = styled.div<ISelectWidthProps>`
    padding: 0 !important;
    width: auto !important;
    margin: 0 5px !important;

    .MuiFormControl-root {
        min-width: ${props => props.minWidth}px;
    }

    ${({ additionalcss }) => additionalcss}
`;

interface ISelectErrorProps {
    selectError?: boolean;
}

export const ContactDetailsContainerTransparent = styled(ContactDetailStyle)<ISelectErrorProps>`
    background-color: transparent;
    margin: 0 !important;
    padding: 0 !important;
    padding-bottom: ${({ selectError }) => (selectError ? "20px !important" : undefined)};

    > div {
        border-color: ${({ selectError }) => (selectError ? "#f44336 !important" : undefined)};
    }
`;

export const YearBuilt: React.FC<IProps> = ({ viewModel }) => {
    return useObserver(() => (
        <SimpleQuestion image={iconCalendar} question="What year was the property built?" completed={!isNullOrEmpty(viewModel.getValue("yearBuilt"))}>
            <StyledNumberInput viewModel={viewModel} fieldName="yearBuilt" min={1000} max={9999} placeholder="Add year" />
        </SimpleQuestion>
    ));
};

export const NumberOfFloors: React.FC<IProps> = ({ viewModel }) => {
    const isMobile = !!useMediaQuery("(max-width: 767px)");

    return useObserver(() => (
        <SimpleQuestion
            image={imageStories}
            question="How many stories does the property have?"
            completed={!isNullOrEmpty(viewModel.getValue("numberOfFloors"))}
        >
            {/* <StyledNumberInput viewModel={viewModel} fieldName="numberOfFloors" min={1} /> */}
            <ContactDetailsContainerTransparent selectError={!viewModel.getValid("numberOfFloors")}>
                <SelectWidth minWidth={isMobile ? 90 : 149} additionalcss="margin: 0 !important;">
                    <SelectPlaceholderStyles placeholder={viewModel.getValue("numberOfFloors") === ""}>
                        <EditableInput
                            viewModel={viewModel}
                            fieldName="numberOfFloors"
                            type="select"
                            selectPlaceholder="Select"
                            validateOnStart
                            validateOnBlur={false}
                            selectItems={[
                                { key: "1", value: 1 },
                                { key: "2", value: 2 },
                                { key: "3", value: 3 },
                                { key: "4", value: 4 },
                                { key: "5", value: 5 },
                                { key: "6", value: 6 },
                            ]}
                        />
                    </SelectPlaceholderStyles>
                </SelectWidth>
            </ContactDetailsContainerTransparent>
        </SimpleQuestion>
    ));
};

export const BuildingSqft: React.FC<IProps> = ({ viewModel }) => {
    return useObserver(() => (
        <SimpleQuestion image={iconSqrFootage} question="What square footage is the property?" completed={!isNullOrEmpty(viewModel.getValue("buildingSqft"))}>
            <StyledNumberInput viewModel={viewModel} fieldName="buildingSqft" min={1} placeholder="Add sqr. footage" />
        </SimpleQuestion>
    ));
};

export const OverWater: React.FC<IProps> = ({ viewModel }) => {
    return useObserver(() => (
        <SimpleQuestion
            image={iconOverWater}
            question="Is the property partially or completely over water?"
            completed={!isNullOrEmpty(viewModel.getValue("isOverWater"))}
        >
            <RadioYesNo viewModel={viewModel} fieldName="isOverWater" />
        </SimpleQuestion>
    ));
};

export const MobileHome: React.FC<IProps> = ({ viewModel }) => {
    return useObserver(() => (
        <SimpleQuestion
            image={iconMobile}
            question="Is the property a mobile or manufactured home?"
            completed={!isNullOrEmpty(viewModel.getValue("isMobileHome"))}
        >
            <RadioYesNo viewModel={viewModel} fieldName="isMobileHome" />
        </SimpleQuestion>
    ));
};

export const HasGarage: React.FC<IProps> = ({ viewModel }) => {
    return useObserver(() => (
        <SimpleQuestion image={iconGarage} question="Does the property have a garage?" completed={!isNullOrEmpty(viewModel.getValue("hasGarage"))}>
            <RadioYesNo viewModel={viewModel} fieldName="hasGarage" />
        </SimpleQuestion>
    ));
};

export const ConstructionType: React.FC<ICollapsableProps> = ({ viewModel, disableCollapse, value }) => {
    return useObserver(() => (
        <CollapsableLargeMultichoice
            viewModel={viewModel}
            fieldName="constructionType"
            question="What is the property constructed with?"
            disableCollapse={disableCollapse}
            image={iconGenericHouse}
            value={value}
        >
            {props => (
                <>
                    <LargeMultichoiceOption image={iconMasonry} label="Masonry / Concrete / Block / Solid Brick" value="masonry" {...props} />
                    <LargeMultichoiceOption image={iconFrame} label="Frame / Wood / Siding" value="frame" {...props} />
                    <LargeMultichoiceOption image={iconBrickVeneer} label="Brick Veneer" value="brickVeneer" {...props} />
                    <LargeMultichoiceOption image={iconLog} label="Log" value="log" {...props} />
                    <LargeMultichoiceOption image={iconSteel} label="Steel" value="steel" {...props} />
                    <LargeMultichoiceOption image={iconOther} label="Other" value="other" {...props} />
                </>
            )}
        </CollapsableLargeMultichoice>
    ));
};

export const FoundationType: React.FC<ICollapsableProps> = ({ viewModel, disableCollapse, value }) => {
    return useObserver(() => (
        <CollapsableLargeMultichoice
            viewModel={viewModel}
            fieldName="foundationType"
            question="What foundation type is used on the property?"
            disableCollapse={disableCollapse}
            image={iconGenericHouse}
            value={value}
        >
            {props => (
                <>
                    <LargeMultichoiceOption image={foundationSlab} label="Slab on grade" value="slab" {...props} />
                    <LargeMultichoiceOption image={foundationBasement} label="Raised floor with basement" value="basement" {...props} />
                    <LargeMultichoiceOption image={foundationCrawlspace} label="Raised floor with crawlspace" value="crawlspace" {...props} />
                    <LargeMultichoiceOption image={foundationPiers} label="Elevated on piers" value="piers" {...props} />
                </>
            )}
        </CollapsableLargeMultichoice>
    ));
};

export const Basement: React.FC<IProps> = ({ viewModel }) => {
    return useObserver(() => (
        <>
            <SimpleQuestion
                question="Does this property have an enclosure / basement?"
                completed={!isNullOrEmpty(viewModel.getValue("hasBasement"))}
                additionalcss={
                    (viewModel.getValue("foundationType") === "slab" && viewModel.getValue("hasBasement") === "false") ||
                    viewModel.getValue("hasBasement") === "false"
                        ? ""
                        : "border-bottom-left-radius: 0; border-bottom-right-radius: 0; margin-bottom: 1px !important;"
                }
                image={iconGenericHouse}
            >
                <RadioYesNo viewModel={viewModel} fieldName="hasBasement" />
            </SimpleQuestion>
            {viewModel.getValue("hasBasement") !== "false" && (
                <LargeMultichoice<QuoteRequestModelV2>
                    viewModel={viewModel}
                    fieldName="enclosureTypesArr"
                    question="Please select all that apply:"
                    multiselect
                    disabled={viewModel.getValue("hasBasement") === "false"}
                >
                    {props => (
                        <>
                            <LargeMultichoiceOption label="Unfinished" value="unfinished" image={iconUnfinished} {...props} />
                            <LargeMultichoiceOption label="Used for storage" value="storage" image={iconUsedForStorage} {...props} />
                            <LargeMultichoiceOption label="Entrance" value="entrance" image={iconEntrance} {...props} />
                            <LargeMultichoiceOption label="Bathroom" value="bathroom" image={iconBathroom} {...props} />
                            <LargeMultichoiceOption label="Game room" value="gameRoom" image={iconGameRoom} {...props} />
                            <LargeMultichoiceOption label="Living space" value="livingSpace" image={iconLivingSpace} {...props} />
                        </>
                    )}
                </LargeMultichoice>
            )}
        </>
    ));
};

export const NewProperty: React.FC<IProps> = ({ viewModel }) => {
    return useObserver(() => (
        <CollapsableLargeMultichoice
            viewModel={viewModel}
            fieldName="isNewProperty"
            question="Is this property:"
            disableCollapse={isNullOrUndefined(viewModel.model.isNewProperty)}
            image={iconGenericHouse}
            value={viewModel.model.isNewProperty}
        >
            {props => (
                <>
                    <LargeMultichoiceOption label="A new construction" value="true" image={iconNewProperty} {...props} />
                    <LargeMultichoiceOption label="An existing property" value="false" image={iconExistingProperty} {...props} />
                </>
            )}
        </CollapsableLargeMultichoice>
    ));
};
