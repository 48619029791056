import React from "react";
import { QuoteRequestViewModel } from "../../ViewModels/QuoteRequestViewModel";
import { PropertyDetailViewModel } from "../../ViewModels/PropertyDetailViewModel";
import { SimpleQuestion } from "./SimpleQuestion";
import iconCalendar from "Content/calendar.svg";
import { isNullOrEmpty } from "../../../Core/Utils/Utils";
import { StyledNumberInput, StyledTextInput, RadioYesNo, LargeMultichoiceOption, CollapsableLargeMultichoice, LargeMultichoice } from "../Forms";
import imageStories from "Content/icon-howManyStories.svg";
import styled from "styled-components";
import { ContactDetailStyle } from "Custom/StylesAppSpecific/FormStyling";
import { EditableInput } from "Core/Components/EditableInput";
import iconSqrFootage from "Content/icon-SqrFootage.svg";
import iconOverWater from "Content/icon-OverWater.svg";
import iconMobile from "Content/icon-mobileHome.svg";
import iconGarage from "Content/icon-haveGarage.svg";
import iconSteel from "Content/icon-steel.svg";
import iconMasonry from "Content/icon-masonry.svg";
import iconBrickVeneer from "Content/icon-brickVeneer.svg";
import iconFrame from "Content/icon-frame.svg";
import iconLog from "Content/icon-log.svg";
import iconOther from "Content/icon-other.svg";
import iconGenericHouse from "Content/icon-genericHouse.svg";
import foundationBasement from "Content/foundationBasement.svg";
import foundationCrawlspace from "Content/foundationCrawlspace.svg";
import foundationPiers from "Content/foundationPiers.svg";
import foundationSlab from "Content/foundationSlab.svg";
import { QuoteRequestModelV2 } from "Custom/Models";
import iconUnfinished from "Content/icon-unfinished.svg";
import iconLivingSpace from "Content/icon-livingSpace.svg";
import iconGameRoom from "Content/icon-gameRoom.svg";
import iconBathroom from "Content/icon-Bathroom.svg";
import iconEntrance from "Content/icon-entrance.svg";
import iconUsedForStorage from "Content/icon-usedForStorage.svg";
import iconExistingProperty from "Content/icon-existingProperty.svg";
import iconNewProperty from "Content/icon-newProperty.svg";
import { useObserver } from "mobx-react-lite";
import { useMediaQuery } from "../../../Core/Utils/Media/mediaQuery";
import { isNullOrUndefined } from "util";

import { StyledGrid } from "./Steps.Styling";
import { PropertySelect } from "Custom/StylesAppSpecific/StepsStyling";
import { Button, Grid } from "@material-ui/core";

interface IProps {
    viewModel: QuoteRequestViewModel;
    className?: string;
}

interface ICollapsableProps extends IProps {
    disableCollapse?: boolean;
    value?: string;
}

interface IPropertyTypes extends IProps {
    propertyDetailVM: PropertyDetailViewModel;
}

interface ISelectPlaceholderStylesProps {
    placeholder: any;
}

const SelectPlaceholderStyles = styled.div<ISelectPlaceholderStylesProps>`
    .MuiSelect-select {
        color: ${({ placeholder }) => (placeholder ? "#93A0AB" : "inherit")};
        font-weight: ${({ placeholder }) => (placeholder ? "500 !important" : undefined)};
    }
`;

interface ISelectWidthProps {
    minWidth: number;
    additionalcss?: string;
}

export const SelectWidth = styled.div<ISelectWidthProps>`
    padding: 0 !important;
    width: auto !important;
    margin: 0 5px !important;

    .MuiFormControl-root {
        min-width: ${props => props.minWidth}px;
    }

    ${({ additionalcss }) => additionalcss}
`;

interface ISelectErrorProps {
    selectError?: boolean;
}

export const ContactDetailsContainerTransparent = styled(ContactDetailStyle)<ISelectErrorProps>`
    background-color: transparent;
    margin: 0 !important;
    padding: 0 !important;
    padding-bottom: ${({ selectError }) => (selectError ? "20px !important" : undefined)};

    > div {
        border-color: ${({ selectError }) => (selectError ? "#f44336 !important" : undefined)};
    }
`;

export const YearBuilt: React.FC<IProps> = ({ viewModel, className }) => {
    return useObserver(() => (
        <SimpleQuestion
            className={className}
            image={iconCalendar}
            question="What year was the property built?"
            completed={!isNullOrEmpty(viewModel.getValue("yearBuilt"))}
        >
            <StyledNumberInput viewModel={viewModel} fieldName="yearBuilt" min={1000} max={9999} placeholder="Add year" />
        </SimpleQuestion>
    ));
};

export const FloorLocated: React.FC<IProps> = ({ viewModel, className }) => {
    return useObserver(() => (
        <SimpleQuestion
            className={`yourPropertyQuestion ${className}`}
            question="What floor is the unit located on?"
            completed={!isNullOrEmpty(viewModel.getValue("floorLocated"))}
        >
            <StyledNumberInput viewModel={viewModel} fieldName="floorLocated" min={1} max={20} placeholder="00" />
        </SimpleQuestion>
    ));
};

export const UnitsInBuilding: React.FC<IProps> = ({ viewModel, className }) => {
    return useObserver(() => (
        <SimpleQuestion
            className={`yourPropertyQuestion ${className}`}
            question="How many units in the building?"
            completed={!isNullOrEmpty(viewModel.getValue("unitsInBuilding"))}
        >
            <StyledNumberInput viewModel={viewModel} fieldName="unitsInBuilding" min={1} max={20} placeholder="00" />
        </SimpleQuestion>
    ));
};

export const Length: React.FC<IProps> = ({ viewModel, className }) => {
    return useObserver(() => (
        <SimpleQuestion className={`yourPropertyQuestion ${className}`} question="Length" completed={!isNullOrEmpty(viewModel.getValue("length"))}>
            <StyledNumberInput viewModel={viewModel} fieldName="length" min={1} max={20} placeholder="00" />
        </SimpleQuestion>
    ));
};

export const Width: React.FC<IProps> = ({ viewModel, className }) => {
    return useObserver(() => (
        <SimpleQuestion className={`yourPropertyQuestion ${className}`} question="Width" completed={!isNullOrEmpty(viewModel.getValue("width"))}>
            <StyledNumberInput viewModel={viewModel} fieldName="width" min={1} max={20} placeholder="00" />
        </SimpleQuestion>
    ));
};

export const Make: React.FC<IProps> = ({ viewModel, className }) => {
    return useObserver(() => (
        <SimpleQuestion className={`yourPropertyQuestion ${className}`} question="Make" completed={!isNullOrEmpty(viewModel.getValue("make"))}>
            <StyledTextInput viewModel={viewModel} fieldName="make" placeholder="The make" />
        </SimpleQuestion>
    ));
};

export const Model: React.FC<IProps> = ({ viewModel, className }) => {
    return useObserver(() => (
        <SimpleQuestion className={`yourPropertyQuestion ${className}`} question="Model (optional)" completed={!isNullOrEmpty(viewModel.getValue("model"))}>
            <StyledTextInput viewModel={viewModel} fieldName="model" placeholder="The model" />
        </SimpleQuestion>
    ));
};

export const SerialNumber: React.FC<IProps> = ({ viewModel, className }) => {
    return useObserver(() => (
        <SimpleQuestion
            className={`yourPropertyQuestion ${className}`}
            question="Serial number (optional)"
            completed={!isNullOrEmpty(viewModel.getValue("serialNumber"))}
        >
            <StyledTextInput viewModel={viewModel} fieldName="serialNumber" placeholder="The serial number" />
        </SimpleQuestion>
    ));
};

export const TotalBuildingValue: React.FC<IProps> = ({ viewModel, className }) => {
    return useObserver(() => (
        <SimpleQuestion
            className={`yourPropertyQuestion ${className}`}
            question="Total building value?"
            completed={!isNullOrEmpty(viewModel.getValue("totalBuildingValue"))}
        >
            <StyledNumberInput viewModel={viewModel} fieldName="totalBuildingValue" placeholder="00" />
        </SimpleQuestion>
    ));
};

export const _NumberOfFloors: React.FC<IProps> = ({ viewModel, className }) => {
    const isMobile = !!useMediaQuery("(max-width: 767px)");

    return useObserver(() => (
        <SimpleQuestion
            image={imageStories}
            question="How many stories does the property have?"
            completed={!isNullOrEmpty(viewModel.getValue("numberOfFloors"))}
            className={className}
        >
            {/* <StyledNumberInput viewModel={viewModel} fieldName="numberOfFloors" min={1} /> */}
            <ContactDetailsContainerTransparent selectError={!viewModel.getValid("numberOfFloors")}>
                <SelectWidth minWidth={isMobile ? 90 : 125} additionalcss="margin: 0 !important; text-align: center;">
                    <SelectPlaceholderStyles placeholder={viewModel.getValue("numberOfFloors") === ""} className="jeff">
                        <EditableInput
                            viewModel={viewModel}
                            fieldName="numberOfFloors"
                            type="select"
                            selectPlaceholder="Select"
                            validateOnStart
                            validateOnBlur={false}
                            selectItems={[
                                { key: "1", value: 1 },
                                { key: "2", value: 2 },
                                { key: "3", value: 3 },
                                { key: "4", value: 4 },
                                { key: "5", value: 5 },
                                { key: "6", value: 6 },
                                { key: "7", value: 7 },
                                { key: "2", value: 2 },
                                { key: "3", value: 3 },
                                { key: "4", value: 4 },
                                { key: "5", value: 5 },
                                { key: "6", value: 6 },
                            ]}
                        />
                    </SelectPlaceholderStyles>
                </SelectWidth>
            </ContactDetailsContainerTransparent>
        </SimpleQuestion>
    ));
};

export const NumberOfFloors: React.FC<IProps> = ({ viewModel, className }) => {
    return useObserver(() => (
        <SimpleQuestion
            className={className}
            image={imageStories}
            question="How many stories does the property have?"
            completed={!isNullOrEmpty(viewModel.getValue("numberOfFloors"))}
        >
            <StyledNumberInput viewModel={viewModel} fieldName="numberOfFloors" min={1} />
        </SimpleQuestion>
    ));
};

export const BuildingSqft: React.FC<IProps> = ({ viewModel, className }) => {
    return useObserver(() => (
        <SimpleQuestion
            className={className}
            image={iconSqrFootage}
            question="What square footage is the property?"
            completed={!isNullOrEmpty(viewModel.getValue("buildingSqft"))}
        >
            <StyledNumberInput viewModel={viewModel} fieldName="buildingSqft" min={1} placeholder="Add sqr. footage" />
        </SimpleQuestion>
    ));
};

export const OverWater: React.FC<IProps> = ({ viewModel, className }) => {
    return useObserver(() => (
        <SimpleQuestion
            image={iconOverWater}
            question="Is the property partially or completely over water?"
            completed={!isNullOrEmpty(viewModel.getValue("isOverWater"))}
            className={className}
        >
            <RadioYesNo viewModel={viewModel} fieldName="isOverWater" className="radiogroup" />
        </SimpleQuestion>
    ));
};

export const MobileHome: React.FC<IProps> = ({ viewModel, className }) => {
    return useObserver(() => (
        <SimpleQuestion
            image={iconMobile}
            question="Is the property a mobile or manufactured home?"
            completed={!isNullOrEmpty(viewModel.getValue("isMobileHome"))}
            className={className}
        >
            <RadioYesNo viewModel={viewModel} fieldName="isMobileHome" className="radiogroup" />
        </SimpleQuestion>
    ));
};

export const HasGarage: React.FC<IProps> = ({ viewModel, className }) => {
    return useObserver(() => (
        <SimpleQuestion
            className={className}
            image={iconGarage}
            question="Does the property have a garage?"
            completed={!isNullOrEmpty(viewModel.getValue("hasGarage"))}
        >
            <RadioYesNo viewModel={viewModel} fieldName="hasGarage" className="radiogroup" />
        </SimpleQuestion>
    ));
};

export const HasElevator: React.FC<IProps> = ({ viewModel, className }) => {
    return useObserver(() => (
        <SimpleQuestion className={"stepsVariant " + className} question="Is there an elevator?" completed={!isNullOrEmpty(viewModel.getValue("hasElevator"))}>
            <RadioYesNo viewModel={viewModel} fieldName="hasElevator" className="radiogroup" />
        </SimpleQuestion>
    ));
};

export const ConstructionType: React.FC<IProps> = ({ viewModel, className }) => {
    return useObserver(() => (
        <CollapsableLargeMultichoice
            viewModel={viewModel}
            fieldName="constructionType"
            question="What is the property constructed with?"
            disableCollapse={viewModel.getValue("constructionType") === ""}
            image={iconGenericHouse}
            value={viewModel.getValue("constructionType")}
            className={"multichoice " + className}
        >
            {props => (
                <>
                    <LargeMultichoiceOption image={iconMasonry} label="Masonry / Concrete / Block / Solid Brick" value="masonry" {...props} />
                    <LargeMultichoiceOption image={iconFrame} label="Frame / Wood / Siding" value="frame" {...props} />
                    <LargeMultichoiceOption image={iconBrickVeneer} label="Brick Veneer" value="brickVeneer" {...props} />
                    <LargeMultichoiceOption image={iconLog} label="Log" value="log" {...props} />
                    <LargeMultichoiceOption image={iconSteel} label="Steel" value="steel" {...props} />
                    <LargeMultichoiceOption image={iconOther} label="Other" value="other" {...props} />
                </>
            )}
        </CollapsableLargeMultichoice>
    ));
};

export const FoundationType: React.FC<IProps> = ({ viewModel, className }) => {
    return useObserver(() => (
        <CollapsableLargeMultichoice
            viewModel={viewModel}
            fieldName="foundationType"
            question="What foundation type is used on the property?"
            disableCollapse={viewModel.getValue("foundationType") === ""}
            image={iconGenericHouse}
            value={viewModel.getValue("foundationType")}
            className={"multichoice " + className}
        >
            {props => (
                <>
                    <LargeMultichoiceOption image={foundationSlab} label="Slab on grade" value="slab" {...props} />
                    <LargeMultichoiceOption image={foundationBasement} label="Raised floor with basement" value="basement" {...props} />
                    <LargeMultichoiceOption image={foundationCrawlspace} label="Raised floor with crawlspace" value="crawlspace" {...props} />
                    <LargeMultichoiceOption image={foundationPiers} label="Elevated on piers" value="piers" {...props} />
                </>
            )}
        </CollapsableLargeMultichoice>
    ));
};

export const Basement: React.FC<IProps> = ({ viewModel, className }) => {
    return useObserver(() => (
        <>
            <SimpleQuestion
                question="Does this property have an enclosure / basement?"
                completed={!isNullOrEmpty(viewModel.getValue("hasBasement"))}
                additionalcss={
                    (viewModel.getValue("foundationType") === "slab" && viewModel.getValue("hasBasement") === "false") ||
                    viewModel.getValue("hasBasement") === "false"
                        ? ""
                        : "border-bottom-left-radius: 0; border-bottom-right-radius: 0; margin-bottom: 1px !important;"
                }
                className={"stepsVariant " + className}
                /* image={iconGenericHouse} */
            >
                <RadioYesNo viewModel={viewModel} fieldName="hasBasement" />
            </SimpleQuestion>
            {viewModel.getValue("hasBasement") !== "false" && (
                <LargeMultichoice<QuoteRequestModelV2>
                    viewModel={viewModel}
                    fieldName="enclosureTypesArr"
                    question="Please select all that apply:"
                    multiselect
                    disabled={viewModel.getValue("hasBasement") === "false"}
                >
                    {props => (
                        <>
                            <LargeMultichoiceOption label="Unfinished" value="unfinished" image={iconUnfinished} {...props} />
                            <LargeMultichoiceOption label="Used for storage" value="storage" image={iconUsedForStorage} {...props} />
                            <LargeMultichoiceOption label="Entrance" value="entrance" image={iconEntrance} {...props} />
                            <LargeMultichoiceOption label="Bathroom" value="bathroom" image={iconBathroom} {...props} />
                            <LargeMultichoiceOption label="Game room" value="gameRoom" image={iconGameRoom} {...props} />
                            <LargeMultichoiceOption label="Living space" value="livingSpace" image={iconLivingSpace} {...props} />
                        </>
                    )}
                </LargeMultichoice>
            )}
        </>
    ));
};

export const NewProperty: React.FC<IProps> = ({ viewModel, className }) => {
    return useObserver(() => (
        <CollapsableLargeMultichoice
            viewModel={viewModel}
            fieldName="isNewProperty"
            question="Is this property:"
            disableCollapse={isNullOrUndefined(viewModel.model.isNewProperty)}
            image={iconGenericHouse}
            value={viewModel.model.isNewProperty}
            className={"multichoice " + className}
        >
            {props => (
                <>
                    <LargeMultichoiceOption label="A new construction" value="true" image={iconNewProperty} {...props} />
                    <LargeMultichoiceOption label="An existing property" value="false" image={iconExistingProperty} {...props} />
                </>
            )}
        </CollapsableLargeMultichoice>
    ));
};

export const PropertyType: React.FC<IPropertyTypes> = ({ viewModel, className, propertyDetailVM }) => {
    return useObserver(() => (
        <>
            <StyledGrid container>
                <Grid item xs className="gridItem bottom7">
                    <h2 className="question">This property is a</h2>
                </Grid>
                <Grid item className="gridItem">
                    <div className="tooltip-div">
                        <div>
                            <PropertySelect>
                                <select
                                    id="propertyType"
                                    name="propertyType"
                                    value={viewModel.getValue<string>("chosenPropertyType")}
                                    onChange={e => viewModel.setValue("chosenPropertyType", e.target.value)}
                                >
                                    {propertyDetailVM.propertyOptionsArr.map(o => (
                                        <option className={"optionPT_" + o.value} key={o.value} value={o.value}>
                                            {o.key}
                                        </option>
                                    ))}
                                </select>
                            </PropertySelect>
                        </div>
                    </div>
                </Grid>
            </StyledGrid>
        </>
    ));
};

export const AnchorType: React.FC<IPropertyTypes> = ({ viewModel, className, propertyDetailVM }) => {
    return useObserver(() => (
        <>
            <Grid container className={`yourPropertyQuestion ${className}`}>
                <Grid item className="gridItem">
                    <h2 className="question">How is your home anchored?</h2>
                </Grid>
                <Grid item className="gridItem">
                    <div className="tooltip-div">
                        <div>
                            <PropertySelect>
                                <select
                                    id="anchorType"
                                    name="anchorType"
                                    value={viewModel.getValue("anchorType")}
                                    onChange={e => viewModel.setValue("anchorType", e.target.value)}
                                >
                                    {propertyDetailVM.mobileAnchorArr.map(o => (
                                        <option className={"optionPT_" + o.value} key={o.value} value={o.value}>
                                            {o.key}
                                        </option>
                                    ))}
                                </select>
                            </PropertySelect>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    ));
};

export const BusinessType: React.FC<IPropertyTypes> = ({ viewModel, className, propertyDetailVM }) => {
    return useObserver(() => (
        <>
            <Grid container className={`yourPropertyQuestion ${className}`}>
                <Grid item xs className="gridItem">
                    <h2 className="question">What kind of business?</h2>
                </Grid>
                <Grid item className="gridItem">
                    <div className="tooltip-div">
                        <div>
                            <PropertySelect>
                                <select
                                    id="businessType"
                                    name="businessType"
                                    value={viewModel.getValue("businessType")}
                                    onChange={e => viewModel.setValue("businessType", e.target.value)}
                                >
                                    {propertyDetailVM.commercialListArr.map(o => (
                                        <option className={"optionPT_" + o.value} key={o.value} value={o.value}>
                                            {o.key}
                                        </option>
                                    ))}
                                </select>
                            </PropertySelect>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    ));
};
