import * as MobX from "mobx";
import { BlankModel } from "../../../Core/Models/BlankModel";
import { ViewModelBase } from "../../../Core/ViewModels/ViewModelBase";
import axios from "axios";
import { toCamel } from "../../../Core/Utils/Utils";
import { Server } from "../../Globals/AppUrls";
import { ResultModel } from "../../Models";
import AdminUserAndQuoteResultStore from "../../Stores/Domain/Admin/AdminUserAndQuoteResultStore";
import { AdminUserAndQuoteResultModelDTO } from "../../Models/AdminUserAndQuoteResultModel";
import { resultKeys } from "../QuoteResultViewModel";

export class ReRunQuotesViewModel extends ViewModelBase<BlankModel> {
    isFieldValid(fieldName: "firstName" | "testNo", value: any, showErrors?: boolean): boolean {
        throw new Error("Method not implemented.");
    }
    beforeUpdate?(fieldName: "firstName" | "testNo", value: any) {
        throw new Error("Method not implemented.");
    }
    afterUpdate?(fieldName: "firstName" | "testNo", value: any): void {
        throw new Error("Method not implemented.");
    }

    constructor(blobId?: string) {
        super(new BlankModel());

        if (blobId) {
            (async () => {
                const apiResult2 = await this.Post<AdminUserAndQuoteResultModelDTO>(Server.Api.Admin.GetUserAndQuoteResult, {
                    id: blobId,
                });

                if (apiResult2.wasSuccessful) {
                    const adminKeys = AdminUserAndQuoteResultStore.instance.model.fromAdminDto(apiResult2.payload);
                    MobX.runInAction(() => {
                        this.adminResultKeys.replace(adminKeys);
                    });
                } else {
                    this.setReran(false);
                    this.setIsErrored(true);
                }
            })();
        } else {
            this.setReran(false);
        }
    }

    @MobX.observable
    public adminResultKeys = MobX.observable<string>([]);

    @MobX.observable
    public rerunning = false;

    @MobX.observable
    public reran = true;

    public rerun = async (blobId: string): Promise<void> => {
        if (this.rerunning) return;
        this.setRerunning(true);

        try {
            const apiResult = await this.Post<string>(Server.Api.Admin.ReRunQuote, { id: blobId });

            if (apiResult.wasSuccessful) {
                let quoteInterval = setInterval(async () => {
                    //Do call to blob
                    let blobResult = "https://nationalfloodinsurance.blob.core.windows.net/filestore/" + apiResult.payload + "/GetQuotesJob.json";
                    const blobApiResult: any = await axios.get(blobResult);
                    let newCamelResult: any = toCamel(blobApiResult.data.Result);

                    MobX.runInAction(() => {
                        const adminResultKeys: string[] = [];
                        for (let key in newCamelResult) {
                            if (newCamelResult.hasOwnProperty(key)) {
                                if (typeof newCamelResult[key] === "object") {
                                    if ((newCamelResult[key] as ResultModel).quotes.length > 0) {
                                        AdminUserAndQuoteResultStore.instance.model[key] = newCamelResult[key];
                                        adminResultKeys.push(key);
                                    }
                                }
                            }
                        }

                        this.adminResultKeys.replace(adminResultKeys);
                    });

                    if (blobApiResult.data.Result.CompletedResults) {
                        clearInterval(quoteInterval);
                        this.setReran(true);
                        this.setRerunning(false);
                    }
                }, 2000);
            } else {
                this.setRerunning(false);
                this.setIsErrored(true);
            }
        } catch {
            this.setRerunning(false);
            this.setIsErrored(true);
        }
    };

    @MobX.computed
    public get adminQuoteIds() {
        const quoteIds: string[] = [];

        for (const key of resultKeys) {
            if (this.adminResultKeys.includes(key)) {
                const quotes = (AdminUserAndQuoteResultStore.instance.model[key] as ResultModel).quotes;
                quotes.forEach(q => {
                    quoteIds.push(q.id);
                });
            }
        }

        return quoteIds;
    }

    @MobX.action
    public setRerunning(rerunning: boolean) {
        this.rerunning = rerunning;
        this.setIsLoading(rerunning);
    }

    @MobX.action
    public setReran(reran: boolean) {
        this.reran = reran;
    }
}
