// Libraries
import * as MobX from "mobx";

// Core
import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";

// Custom
import { Server } from "../../Globals/AppUrls";
import { AgentReportsModel, AgentReportsModelDTO } from "../../Models/AgentReportsModel";
import { AgentReportModel } from "Custom/Models/AgentReportModel";
import { GenericId } from "Custom/Models";

export default class AgentReportsViewModel extends ViewModelBase<AgentReportsModel> {
    /**
     * Constructor
     */
    constructor() {
        super(new AgentReportsModel());

        this.setDecorators(AgentReportsModel);

        this.load();
    }

    // #region Navigation

    public selectUser = (data: AgentReportModel) => {
        this.history.push(`/agentuserreport/${data.id}`);
    };

    // #endregion Navigation

    @MobX.computed
    public get reports() {
        return this.model.reports.slice();
    }

    // #region Server Api

    public load = async (): Promise<void> => {
        try {
            this.setIsLoading(true);

            const apiResult = await this.Post<AgentReportsModelDTO>(Server.Api.Agent.GetLatestUserQuotes);

            if (apiResult.wasSuccessful) {
                this.model.fromDto(apiResult.payload);
            } else {
                this.setIsErrored(true);
            }
        } catch (exception) {
            this.setIsErrored(true);
        } finally {
            this.setIsLoading(false);
        }
    };

    public deletePropertyQuotes = async (propertyQuoteId: string): Promise<void> => {
        try {
            this.setIsSaving(true);

            const requestId: GenericId = {
                id: propertyQuoteId,
            };

            const apiResult = await this.Post(Server.Api.Agent.DeletePropertyQuotes, requestId);

            if (apiResult.wasSuccessful) {
                MobX.runInAction(() => {
                    if (apiResult.payload) {
                        var report = this.model.reports.find(r => r.userName === propertyQuoteId);

                        if (!!report) {
                            this.model.reports.remove(report);
                        }
                    }
                });
            }
        } catch (exception) {
            this.setIsErrored(true);
        } finally {
            this.setIsSaving(false);
        }
    };

    // #endregion Server Api

    // #region Viewmodel Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Viewmodel Boilerplate
}
