import React from "react";
import { Typography, Box } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useObserver } from "mobx-react-lite";
import styled from "styled-components";
import { LogViewerViewModel } from "../ViewModels/LogViewerViewModel";
import { runInAction } from "mobx";
import { Show } from "../Components/General/Show";
import ReactJson from "react-json-view";

const viewModel = new LogViewerViewModel();

export const LogFileStyle = styled.span`
    cursor: pointer;
    padding-bottom: 10px;
`;
export const Container = styled(Box)`
    .MuiTableCell-root {
        font-size: 0.8rem;
    }
    .MuiTableSortLabel-root {
        :hover {
            color: lightgray;
        }
    }
    /* .MuiIconButton-label {
		color: white;
	} */
    .MuiTableRow-root {
        cursor: pointer;
        /* user-select: none; */
        :hover {
            background-color: lightgray;
        }
    }
`;
//EN: use empty generic type to get default react props
export const LogViewer: React.FC = props => {
    React.useEffect(() => {
        let _ = viewModel.FetchLogListAsync();
    }, []);

    const backToList = () => {
        runInAction(() => {
            viewModel.LogContents = {};
            viewModel.LogLoaded = false;
        });
    };
    const fetchLogFile = (fileName: string) => {
        let _ = viewModel.FetchLogFileAsync(fileName);
    };

    console.log("Render");

    return useObserver(() => (
        <Container>
            <Box marginTop="45px" display="block">
                <Box paddingBottom={3}>
                    <Typography variant="h1" color="textPrimary">
                        Log Viewer
                    </Typography>
                </Box>
                <Show iff={!viewModel.LogLoaded}>
                    <Box>
                        {viewModel.FileList.map((item: string, index: number) => {
                            return (
                                <Box>
                                    <span title={"Download file"} style={{ paddingRight: "10px", cursor: "pointer", position: "relative", top: "7px" }}>
                                        <GetAppIcon onClick={() => viewModel.DownloadFileAsync(item)} />
                                    </span>
                                    <LogFileStyle title={"View File in Browser"} onClick={() => fetchLogFile(item)}>
                                        <span>{item}</span>
                                    </LogFileStyle>
                                </Box>
                            );
                        })}
                        {/*<LogFileStyle onClick={() => fetchLogFile("weblog-20200225.json")}>Test</LogFileStyle>*/}
                    </Box>
                </Show>
                <Show iff={viewModel.LogLoaded}>
                    <Box onClick={backToList} paddingBottom={2} style={{ cursor: "pointer" }}>
                        Back to List
                    </Box>
                    <ReactJson style={{ fontSize: "12px" }} src={viewModel.LogContents} theme={"monokai"} />
                </Show>
            </Box>
        </Container>
    ));
};
