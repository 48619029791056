// Libraries
import React from "react";
import { useObserver } from "mobx-react-lite";

// Content
import ProBackground from "Content/ProBackground.png";

// Custom
import { AgentTabs } from "../../AgentComponents/AgentTabs";
import { Footer } from "../../Components/Footer";
import { DivWrapperBGFixed, DivWrapper } from "../../StylesAppSpecific/GridHelperStyling";
import { AdminWrapTabDiv } from "../../StylesAppSpecific/MFIAdminStyling";
import { AgentWrapTabDiv } from "../../StylesAppSpecific/Agent/AgentStyling";
import { AgentRoutes } from "../../../AgentRoutes";
import { useMediaQuery } from "Core/Utils";

export const Agent: React.FC = () => {
    const isMobile = !!useMediaQuery("screen and (max-width: 767px)");

    return useObserver(() => (
        <>
            <DivWrapperBGFixed additionalcss={"background: url(" + ProBackground + ") center center / cover no-repeat;"}>
                <DivWrapper padding={isMobile ? "20px 0px" : "40px 20px 40px 20px"} backgroundcolor={"rgba(27, 43, 55, 0.88)"}>
                    <AgentWrapTabDiv additionalcss={"max-width: 1140px; width: 100%;"} padding={isMobile ? "0px 10px !important" : "10px 20px"}>
                        <AgentRoutes />
                    </AgentWrapTabDiv>
                </DivWrapper>
            </DivWrapperBGFixed>
            <Footer isTemp={false} />
        </>
    ));
};
