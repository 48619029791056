import * as MobX from "mobx";
import { ModelBase } from "../../../Core/Models/ModelBase";

export class MetaDataObjectModel extends ModelBase<MetaDataObjectModel, MetaDataObjectModelDTO> {
    @MobX.observable
    public timeStamp: string = "";

    @MobX.observable
    public version: string = "";

    public fromDto = (model: MetaDataObjectModelDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                this[key] = model[key];
            }
        }
    };
    public toDto = (model: MetaDataObjectModel) => {};

    public resetMetaDataObject = () => {
        this.timeStamp = "";
        this.version = "";
    };
}

export interface MetaDataObjectModelDTO {
    timeStamp: string;
    version: string;
}
