import { styled } from "../Globals/Base";
import { FormControlLabel, Button, Box } from "@material-ui/core";

export const HeaderLogo: any = styled.img`
    margin: 40px 0;
    height: 92px;
`;

export const SignInButton: any = styled(Button)`
    transform: translateY(calc(50% + 5px));
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin: auto;
    display: block;
    margin-top: -50px;
    background-color: #129bdb;
    color: white;
    font-weight: bold;
    line-height: 125%;
    font-size: 1.125rem;

    span {
        text-transform: none;
    }

    :hover {
        background-color: #129bdb;
    }

    &.Mui-disabled {
        background-color: #93a0ab;
    }
`;

export const ForgotPasswordButton: any = styled(SignInButton)`
    /*width: auto;*/
    border-radius: 1000px;
    /*padding: 0 40px;*/
    img {
        filter: brightness(0) invert(1); /* Converts the green tick svg to white. */
    }
`;

export const ForgotPasswordSuccessStyles: any = styled.div`
    h1 {
        text-align: center;
        font-size: 2rem;
    }
`;

export const HomeButton: any = styled(ForgotPasswordButton)`
    transform: none;
    margin-top: 60px;
`;

export const LoginBox: any = styled(Box)`
    color: #464545;
    font-size: 2.25rem;
    font-weight: bold;
    text-align: center;

    @media (max-width: 767px) {
        padding: 0 0 5px;
        text-align: center;
        font-size: 1.5rem;
    }

    .blue-text {
        color: #129bdb;
    }
`;

export const ShowPasswordBase: any = styled.div`
    width: 25px;
    height: 25px;
    margin: 0px !important;
    cursor: pointer;

    > img {
        width: 25px;
        height: 25px;
    }

    @media (max-width: 767px) {
        top: 43px;
        right: 12px;
    }
`;

export const ShowPasswordIcon: any = styled(ShowPasswordBase)`
    position: absolute;
    top: 50px;
    right: 24px;
`;

export const ShowPasswordIcon2: any = styled(ShowPasswordBase)`
    /*position: absolute;*/
    flex: 1;
`;

export const ShowPasswordContainer: any = styled.div`
    > div:first-child {
        width: 100%;
    }
`;

export const InstructBox: any = styled(Box)`
    ol {
        margin: 0px auto;
        @media (min-width: 768px) {
            margin: 0px;
            padding: 10px 25px;
        }
    }
    ol li {
        font-size: 0.75rem;
        color: #191919;
    }
`;

export const LoginForm: any = styled.form`
    padding: 5px 0 0;

    label {
        color: #464545;
        font-weight: bold;
        display: block;
        font-size: 1.5rem;
        line-height: 100%;
        margin-top: 15px;

        @media (max-width: 767px) {
            font-size: 1rem;
        }

        input {
            padding-left: 20px;
            padding-right: 20px;

            ::placeholder {
                font-style: italic;
            }
        }

        > div {
            margin-top: 14px;
            font-size: 0;
        }
    }

    .MuiOutlinedInput-notchedOutline {
        border-radius: 3px !important;
        border-width: 3px !important;
    }

    .MuiOutlinedInput-root:not(.Mui-error) .MuiOutlinedInput-notchedOutline {
        border-color: #f2af4c !important;
    }
`;

export const RememberMeLabel: any = styled(FormControlLabel)`
    margin-top: 11px !important;

    span {
        font-weight: bold !important;
        font-size: 0.875rem;
    }

    .MuiCheckbox-root {
        color: #12db4e;
    }
`;
