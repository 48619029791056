// Libraries
import React, { useState } from "react";

// Components
import { Footer } from "../Components/Footer";
import BuyPolicyViewModel from "../ViewModels/BuyPolicyViewModel";
import { PolicyChat } from "../Components/PurchasePolicy/PolicyChat";
import { PolicyCard } from "../Components/PurchasePolicy/PolicyCard";
import { useMediaQuery } from "Core/Utils/Media";
import { Button, Box } from "@material-ui/core";

// Styling & image
import { DivWrapper, DivWrapperBGFixed } from "../StylesAppSpecific/GridHelperStyling";
import { useQuoteStyles } from "../StylesAppSpecific/Quote/Quote";
import { PolicyContainer, DetailsGrid, Container, ChatGrid } from "../Components/PurchasePolicy/Styling";
import PXFuel from "Content/quoteBG.jpg";
import { useRouter } from "Core/Utils";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

export const BuyPolicy: React.FC = () => {
    const classes = useQuoteStyles();
    const { history, location, match } = useRouter();
    let isMobile: boolean;
    isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;
    const { insurerName, id } = match.params as any;
    const [viewModel] = useState(() => new BuyPolicyViewModel(insurerName, id));

    React.useEffect(() => {
        viewModel.setRouter(history, location, match);
        window.scrollTo(0, 0);
    }, []);

    let officeHours: boolean = viewModel.isInOfficeHours();

    let showDetail: boolean = viewModel.quoteNumber === "notQuoted" ? false : true;

    const inHours = (
        <>
            {!isMobile && (
                <>
                    <DetailsGrid xs={12} sm={7} padding={"0"} fontcolor={"#333333"}>
                        <PolicyCard Model={viewModel} />
                        <h2 className="thankyou">Thank you for choosing this policy</h2>
                        {showDetail && (
                            <>
                                <p className="online">Please begin chatting with one of our expert agents to complete your policy purchase.</p>
                            </>
                        )}
                        {!showDetail && (
                            <>
                                <p className="online">
                                    One of our expert agents will be along shortly. In order to provide you with an accurate NFIP quote they will need to get
                                    some further information from you regarding your property.
                                </p>
                            </>
                        )}
                    </DetailsGrid>
                    <ChatGrid xs={12} sm={5} padding={"25px"}>
                        <PolicyChat />
                    </ChatGrid>
                </>
            )}
            {isMobile && (
                <>
                    <DetailsGrid xs={12} sm={7} padding={"0"} fontcolor={"#333333"}>
                        <h2 className="thankyou">Thank you for choosing this policy</h2>
                        {showDetail && (
                            <>
                                <p className="online">Please begin chatting with one of our expert agents to complete your policy purchase.</p>
                            </>
                        )}
                        {!showDetail && (
                            <>
                                <p className="online">
                                    One of our expert agents will be along shortly. In order to provide you with an accurate NFIP quote they will need to get
                                    some further information from you regarding your property.
                                </p>
                            </>
                        )}
                        <PolicyCard Model={viewModel} />
                    </DetailsGrid>
                    <ChatGrid xs={12} sm={5} padding={"25px"}>
                        <PolicyChat />
                    </ChatGrid>
                </>
            )}
        </>
    );

    const outHours = (
        <>
            {!isMobile && (
                <>
                    <DetailsGrid maxwidth={"1124px"} fontcolor={"#333333"}>
                        <PolicyCard Model={viewModel} />
                        <h2 className="thankyou">Thank you for choosing this policy</h2>
                        <p className="offline">
                            You have reached us after normal office hours. One of our expert flood insurance agents will contact you as quickly as possible to
                            finish up your policy.
                        </p>
                    </DetailsGrid>
                </>
            )}
            {isMobile && (
                <>
                    <DetailsGrid maxwidth={"1124px"} fontcolor={"#333333"}>
                        <h2 className="thankyou">Thank you for choosing this policy</h2>
                        <p className="offline">
                            You have reached us after normal office hours. One of our expert flood insurance agents will contact you as quickly as possible to
                            finish up your policy.
                        </p>
                        <PolicyCard Model={viewModel} />
                    </DetailsGrid>
                </>
            )}
        </>
    );

    return (
        <>
            <DivWrapperBGFixed additionalcss={"background-image: url(" + PXFuel + ");"}>
                <Container padding="0">
                    <Box maxWidth="1124px" margin="auto">
                        <Button className={classes.navigationButton} onClick={viewModel.navigateBack} startIcon={<ChevronLeftIcon />} variant="contained">
                            Back to your quotes
                        </Button>
                    </Box>
                    <PolicyContainer
                        container
                        maxwidth={!isMobile ? "1124px" : "335px"}
                        margin={"0px auto"}
                        padding="0px"
                        minheight={officeHours ? "585px" : "398px"}
                    >
                        {officeHours && inHours}
                        {!officeHours && outHours}
                    </PolicyContainer>
                </Container>
            </DivWrapperBGFixed>
            <Footer isTemp={false} />
        </>
    );
};
