import { AccountStatus, ApiResult } from "Core/Models";
import { RegisterUserModel } from "Custom/Models/RegisterUserModel";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import validator from "validator";

//Pass in <any> as the generic type because we are showing the use of nested classes
export class RegisterUserViewModel extends ViewModelBase<any> {
    constructor() {
        super(new RegisterUserModel());
        this.setDecorators(RegisterUserModel);
    }

    public register = async (): Promise<ApiResult<AccountStatus>> => {
        return await this.Post<AccountStatus>("/api/account/register", this.getModel);
    };

    public isFieldValid(fieldName: string, value: any): boolean {
        let { isValid, errorMessage } = this.validateDecorators(fieldName);

        //Overriding the decorators to check password fields
        if (fieldName === "confirmPassword") {
            errorMessage = this.isConfirmPasswordValid;
            isValid = errorMessage === "";
        }

        //Manual override here because this is a nested class
        if (fieldName === "address.address1") {
            if (validator.isEmpty(value)) {
                errorMessage = "Address 1 is required";
                isValid = false;
            }
        }

        if (fieldName === "address.postCode") {
            if (validator.isEmpty(value)) {
                errorMessage = "Postcode is required";
                isValid = false;
            } else if (!validator.isPostalCode(value, "GB")) {
                errorMessage = "Postcode is not valid";
                isValid = false;
            }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public get isConfirmPasswordValid(): string {
        if (this.getValue("password") !== this.getValue("confirmPassword") && this.getValue("password") !== "") return "Passwords must match";
        return "";
    }
}
