// User interface friendly strings.
const INSURANCEPOLICY_YES = "Yes";
const INSURANCEPOLICY_NO = "No";
const INSURANCEPOLICY_EXPIRED = "Expired";
const INSURANCEPOLICY_DEFAULT = "Other, not sure";

// Transform by insurance policy type.
export const getInsurancePolicy = (type: string): string => {
    let name = "";

    switch (type) {
        case "yes":
            name = INSURANCEPOLICY_YES;
            break;

        case "no":
            name = INSURANCEPOLICY_NO;
            break;

        case "expired":
            name = INSURANCEPOLICY_EXPIRED;
            break;

        default:
            name = INSURANCEPOLICY_DEFAULT;
    }

    return name;
};
