// Libraries
import React from "react";
import { useObserver } from "mobx-react-lite";
import { Button, Grid } from "@material-ui/core";

// Components
import { StoresInstance } from "../../Globals/Base";
import { Responses } from "./Responses";
import {
    NumberOfFloors,
    YearBuilt,
    OverWater,
    BuildingSqft,
    HasGarage,
    ConstructionType,
    FoundationType,
    TotalBuildingValue,
    PropertyType,
    Basement,
} from "./Questions";

// Styling & images
import { StepPropertySummary, PropertyDetailModal, GridProperty } from "./Steps.Styling";
import { DashedDiv } from "Custom/StylesAppSpecific/StepsStyling";
import yourCondo from "Content/yourCondo.svg";
import greenTick from "Content/greenTick.svg";
import { useMediaQuery } from "Core/Utils/Media";

export const RCBAPStep: React.FC = () => {
    const domainStores = StoresInstance.domain;
    const viewModel = domainStores.QuoteRequestViewModel;
    const propertyDetailVM = domainStores.PropertyDetailViewModel;
    const isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;

    const numberOfFloors = () => {
        return (
            <>
                <NumberOfFloors viewModel={viewModel} />
            </>
        );
    };

    const yearBuilt = () => {
        return (
            <>
                <YearBuilt viewModel={viewModel} />
            </>
        );
    };

    const overWater = () => {
        return (
            <>
                <OverWater viewModel={viewModel} />
            </>
        );
    };

    const buildingSqFt = () => {
        return (
            <>
                <BuildingSqft viewModel={viewModel} />
            </>
        );
    };

    const hasGarage = () => {
        return (
            <>
                <HasGarage viewModel={viewModel} />
            </>
        );
    };

    const constructionType = () => {
        return (
            <>
                <ConstructionType viewModel={viewModel} /* disableCollapse={true} */ />
            </>
        );
    };

    const foundationType = () => {
        return (
            <>
                <FoundationType viewModel={viewModel} /* disableCollapse={true} */ />
            </>
        );
    };

    const propertyType = () => {
        return (
            <>
                <PropertyType viewModel={viewModel} propertyDetailVM={propertyDetailVM} />
            </>
        );
    };

    const handleChange = (val: string) => {
        propertyDetailVM.setModalState(true);
        propertyDetailVM.setModalBodyView(val);
    };

    const renderModalBody = () => {
        switch (propertyDetailVM.modalBodyView) {
            case "NumberOfFloors":
                return numberOfFloors();
                break;

            case "YearBuilt":
                return yearBuilt();
                break;

            case "OverWater":
                return overWater();
                break;

            case "BuildingSqFt":
                return buildingSqFt();
                break;

            case "HasGarage":
                return hasGarage();
                break;

            case "ConstructionType":
                return constructionType();
                break;

            case "FoundationType":
                return foundationType();
                break;

            case "PropertyType":
                return propertyType();
                break;
        }
    };

    const isOverWater = () => {
        let retVal = false;
        switch (viewModel.getValue("isOverWater")) {
            case "true":
                retVal = true;
                break;

            case "false":
                retVal = true;
                break;

            default:
                retVal = false;
                break;
        }
        return retVal;
    };

    const closeModal = () => {
        viewModel.doIsNewProperty(viewModel.getValue<number>("yearBuilt"));
        propertyDetailVM.setModalState(false);
    };

    return useObserver(() => (
        <>
            <StepPropertySummary>
                <div>
                    <img src={greenTick} />
                    is a RCAP <span onClick={() => handleChange("PropertyType")}>Change</span>
                </div>
                <Responses thisResponse={"NumberOfFloors"} thisModal={() => handleChange("NumberOfFloors")} />
                <Responses thisResponse={"YearBuilt"} thisModal={() => handleChange("YearBuilt")} />
                <Responses thisResponse={"OverWater"} thisModal={() => handleChange("OverWater")} />
                <Responses thisResponse={"BuildingSqFt"} thisModal={() => handleChange("BuildingSqFt")} />
                <Responses thisResponse={"HasGarage"} thisModal={() => handleChange("HasGarage")} />
                <Responses thisResponse={"ConstructionType"} thisModal={() => handleChange("ConstructionType")} />
                <Responses thisResponse={"FoundationType"} thisModal={() => handleChange("FoundationType")} />
            </StepPropertySummary>
            <h2>We just need to know a little bit more from you</h2>
            <h3>to make sure we can get the best possible price</h3>
            <div className="yourPropertyBox">
                <h3 className="yourProperty">
                    <img src={yourCondo} alt="Your RCBAP" />
                    Your RCBAP property
                </h3>
                {isMobile || <DashedDiv />}
                <Grid container>
                    <GridProperty item sm={4}>
                        <TotalBuildingValue viewModel={viewModel} className="stacked" />
                    </GridProperty>
                </Grid>
            </div>
            {isMobile || <DashedDiv />}
            <Basement viewModel={viewModel} />
            {viewModel.fieldRequired("numberOfFloors") && <NumberOfFloors viewModel={viewModel} />}
            {viewModel.fieldRequired("yearBuilt") && <YearBuilt viewModel={viewModel} />}
            {!isOverWater() && <OverWater viewModel={viewModel} />}
            {viewModel.fieldRequired("buildingSqft") && <BuildingSqft viewModel={viewModel} />}
            {viewModel.fieldRequired("hasGarage") && <HasGarage viewModel={viewModel} />}
            {viewModel.fieldRequired("constructionType") && 
                <ConstructionType viewModel={viewModel} />
            }
            {viewModel.fieldRequired("foundationType") && 
                <FoundationType viewModel={viewModel} />
            }

            <PropertyDetailModal open={propertyDetailVM.modalState} onClose={closeModal}>
                <div className="step-2-modal">
                    <div className="modalhead">{propertyDetailVM.modalTitle}</div>
                    <div className="modalbody">{renderModalBody()}</div>
                    <div className="modalfooter">
                        <div className="btns">
                            <Button className="save" variant="contained" onClick={closeModal}>
                                Save
                            </Button>
                            <Button className="cancel" variant="contained" onClick={closeModal}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </PropertyDetailModal>
        </>
    ));
};
