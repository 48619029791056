// Libraries
import * as MobX from "mobx";

// Core
import { ModelBase } from "../../Core/Models/ModelBase";
import { UserQuoteModel } from "./UserQuoteModel";

export class AdminUserAndQuotesModel extends ModelBase<AdminUserAndQuotesModel, AdminUserAndQuotesModelDTO> {
    // #region User

    @MobX.observable
    public id: string = "";

    @MobX.observable
    public firstName: string = "";

    @MobX.observable
    public lastName: string = "";

    @MobX.observable
    public userName: string = "";

    // #endregion User

    // region Quotes

    @MobX.observable
    public userQuotes = MobX.observable<UserQuoteModel>([]);

    // #endregion Quotes

    @MobX.action
    public fromDto = (model: AdminUserAndQuotesModelDTO): void => {
        this.id = model.id;
        this.firstName = model.firstName;
        this.lastName = model.lastName;
        this.userName = model.userName;
        this.userQuotes.replace(model.userQuotes);
    };

    public toDto = (): AdminUserAndQuotesModelDTO => {
        throw "toDto is not supported";
    };
}

export interface AdminUserAndQuotesModelDTO {
    id: string;
    firstName: string;
    lastName: string;
    userName: string;
    userQuotes: UserQuoteModel[];
}
