import { Button, Modal } from "@material-ui/core";
import styled from "styled-components";

export const WarningModal: any = styled(Modal)`
    width: 320px;
    height: 416px;
    padding: 0px;
    margin: 10% auto;
    background-color: #3a4650;
    border-radius: 5px 5px 0px 0px;
    .header {
        text-align: center;
        background-color: #ff5454;
        border-radius: 5px 5px 0px 0px;
        color: #ffffff;
        height: 58px;
        font: Bold 24px Montserrat;
        letter-spacing: 0px;
        padding: 16px 0px;
    }

    .body {
        text-align: center;
        font: Bold 21px Montserrat;
        letter-spacing: 0px;
        height: 306px;
        padding: 34px 0px;
    }

    .footer {
        background-color: #666f77;
        text-align: center;
        height: 82px;
    }
`;

export const ApiDetailModal: any = styled(WarningModal)`
    width: 60vw;
    height: auto;
    min-height: 60vh;

    .header {
        background-color: #129bdb;
    }

    .body {
        padding: 20px 10px;
        text-align: left;
        overflow-y: auto;
        height: calc(64vh - 58px);

        .quoteData {
            padding: 5px 25px;
            font-size: 14px;
            font-weight: normal;
            word-wrap: break-word;

            span {
                font-weight: 700;
            }
        }

        .MuiExpansionPanel-Root {
            padding: 0 14px 0 14px;

            .MuiExpansionPanelSummary-content.Mui-expanded {
                margin: 10px 0;
            }
        }
        .MuiExpansionPanel-root.Mui-expanded {
            margin: 1px 0;
        }
        .MuiExpansionPanelSummary-root.Mui-expanded {
            min-height: 34px;
        }
    }
`;

export const StyledButton: any = styled(Button)`
    display: inline-block;
    color: #ffffff;
    width: 112px;
    padding: 8px 0px;
    text-align: center;
    border-radius: 25px;
    font-size: 14px;
    letter-spacing: -0.7px;
    margin: 20px 15px;
`;

export const OkButton: any = styled(StyledButton)`
    background-color: #129bdb;
`;

export const CancelButton: any = styled(StyledButton)`
    background-color: #333333;
`;
