// Libraries
import * as MobX from "mobx";

// Core
import { FieldType, isEmptyOrWhitespace } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";

// Custom
import { Server } from "../../Globals/AppUrls";
import { AgentUserAndQuoteModel } from "../../Models/AgentUserAndQuoteModel";
import { moneyDollarFormat, moneyFormat } from "../../Utils/Money";
import AgentUserAndQuoteResultStore from "../../Stores/Domain/AgentUserAndQuoteResultStore";
import { AgentUserAndQuoteResultModelDTO } from "../../Models/AgentUserAndQuoteResultModel";
import { ResultQuoteModel } from "../../Models";
import { getConstructionType } from "../../Utils/ConstructionType";
import { getFoundationType } from "../../Utils/FoundationType";
import { getInsurancePolicy } from "../../Utils/InsurancePolicy";
import { getPropertyOccupyType } from "../../Utils/PropertyOccupyType";
import { getPropertyOwnershipType } from "../../Utils/PropertyOwnershipType";
import { getPropertySize } from "../../Utils/PropertySize";
import { localTime } from "../../Utils/Time";

export default class AgentUserReportQuoteViewModel extends ViewModelBase<AgentUserAndQuoteModel> {
    @MobX.observable
    public blobId: string = "";

    @MobX.observable
    public quoteId: string = "";

    // #region Insurer

    @MobX.computed
    public get quote() {
        return this.returnQuote;
    }

    @MobX.computed
    public get policyName() {
        return !!this.returnQuote ? this.returnQuote.name : "";
    }

    @MobX.computed
    public get policyPremium() {
        return !!this.returnQuote ? moneyDollarFormat(this.returnQuote.total, true) : moneyDollarFormat(0, true);
    }

    @MobX.computed
    public get policyDeductible() {
        return !!this.returnQuote ? moneyDollarFormat(this.returnQuote.deductible, true) : moneyDollarFormat(0, true);
    }

    @MobX.computed
    public get policyQuoteNumber() {
        return !!this.returnQuote ? this.returnQuote.quoteNumber : "";
    }

    @MobX.computed
    public get policyInternalQuoteNumber() {
        return !!this.returnQuote ? this.returnQuote.internalQuoteNumber : "";
    }

    @MobX.computed
    public get isNFIPQuote() {
        return !!this.returnQuote ? this.returnQuote.name === "Wright NFIP" : false;
    }

    // #endregion Insurer

    // #region Policy Holder

    @MobX.computed
    public get policyHolderEmailAddress() {
        return AgentUserAndQuoteResultStore.instance.model.userName;
    }

    @MobX.computed
    public get policyHolderName() {
        return `${AgentUserAndQuoteResultStore.instance.model.firstName} ${AgentUserAndQuoteResultStore.instance.model.lastName}`;
    }

    @MobX.computed
    public get policyHolderPhoneNumber() {
        return AgentUserAndQuoteResultStore.instance.model.phoneNumber;
    }

    // #endregion Policy Holder

    // #region Property

    @MobX.computed
    public get propertyAddress() {
        return AgentUserAndQuoteResultStore.instance.model.request.address;
    }

    @MobX.computed
    public get propertyConstructionType() {
        return getConstructionType(AgentUserAndQuoteResultStore.instance.model.request.constructionType);
    }

    @MobX.computed
    public get propertyConstructionYear() {
        return AgentUserAndQuoteResultStore.instance.model.request.yearBuilt;
    }

    @MobX.computed
    public get propertyFoundationType() {
        return getFoundationType(AgentUserAndQuoteResultStore.instance.model.request.foundationType);
    }

    @MobX.computed
    public get propertySize() {
        return getPropertySize(AgentUserAndQuoteResultStore.instance.model.request.buildingSqft);
    }

    @MobX.computed
    public get propertyStories() {
        return AgentUserAndQuoteResultStore.instance.model.request.numberOfFloors;
    }

    @MobX.computed
    public get propertyIsOverWater() {
        return AgentUserAndQuoteResultStore.instance.model.request.isOverWater ? "Yes" : "No";
    }

    @MobX.computed
    public get propertyIsMobileHome() {
        return AgentUserAndQuoteResultStore.instance.model.request.isMobileHome ? "Yes" : "No";
    }

    @MobX.computed
    public get propertyHasGarage() {
        return AgentUserAndQuoteResultStore.instance.model.request.hasGarage ? "Yes" : "No";
    }

    @MobX.computed
    public get propertyHasEnclosure() {
        return AgentUserAndQuoteResultStore.instance.model.request.hasBasement ? "Yes" : "No";
    }

    @MobX.computed
    public get propertyNewProperty() {
        return AgentUserAndQuoteResultStore.instance.model.request.isNewProperty ? "A new property" : "An existing property";
    }

    @MobX.computed
    public get propertyPolicyEffectiveDate() {
        return isEmptyOrWhitespace(AgentUserAndQuoteResultStore.instance.model.request.dateEffective)
            ? "Not sure"
            : localTime(AgentUserAndQuoteResultStore.instance.model.request.dateEffective!).format("MM/DD/YY");
    }

    @MobX.computed
    public get propertyOwnershipType() {
        return getPropertyOwnershipType(AgentUserAndQuoteResultStore.instance.model.request.ownershipType);
    }

    @MobX.computed
    public get propertyOccupy() {
        return getPropertyOccupyType(AgentUserAndQuoteResultStore.instance.model.request.propertyOccupy);
    }

    @MobX.computed
    public get propertyInsurancePolicy() {
        return getInsurancePolicy(AgentUserAndQuoteResultStore.instance.model.request.insurancePolicy);
    }

    @MobX.computed
    public get propertyElevationCertificate() {
        return !isEmptyOrWhitespace(AgentUserAndQuoteResultStore.instance.model.request.elevationCertificateUrl) ? "Yes" : "No";
    }

    // #endregion property

    // #region Figures

    @MobX.computed
    public get buildingCoverage() {
        return !!this.returnQuote ? moneyFormat(this.returnQuote.buildingCover, true) : moneyFormat(0, true);
    }

    @MobX.computed
    public get contentCoverage() {
        return !!this.returnQuote ? moneyFormat(this.returnQuote.contentsCover, true) : moneyFormat(0, true);
    }

    @MobX.computed
    public get deductible() {
        return !!this.returnQuote ? moneyFormat(this.returnQuote.deductible, true) : moneyFormat(0, true);
    }

    @MobX.computed
    public get premium() {
        return !!this.returnQuote ? moneyFormat(this.returnQuote.premium, true) : moneyFormat(0, true);
    }

    @MobX.computed
    public get taxesAndFees() {
        return !!this.returnQuote ? moneyFormat(this.returnQuote.fees + this.returnQuote.taxes, true) : moneyFormat(0, true);
    }

    @MobX.computed
    public get total() {
        return !!this.returnQuote ? moneyFormat(this.returnQuote.total, true) : moneyFormat(0, true);
    }

    // #endregion Figures

    /**
     * Constructor
     *
     * @param blobId The quote result to load (if defined).
     * @param quoteId The quote/policy to load (if defined).
     */
    constructor(blobId: string, quoteId: string) {
        super(new AgentUserAndQuoteModel());
        this.setDecorators(AgentUserAndQuoteModel);
        this.blobId = blobId;
        this.quoteId = quoteId;

        if (!isEmptyOrWhitespace(blobId) && blobId !== "null") {
            // Only load the quote results if the blob id has changed.
            if (blobId !== AgentUserAndQuoteResultStore.instance.model.blobId) {
                AgentUserAndQuoteResultStore.instance.model.reset();
                this.load(blobId);
            }
        } else {
            AgentUserAndQuoteResultStore.instance.model.reset();
        }
    }

    @MobX.computed
    private get returnQuote() {
        let quotesArr: ResultQuoteModel[] = [];

        quotesArr.push.apply(quotesArr, AgentUserAndQuoteResultStore.instance.model.wrightResult.quotes);
        quotesArr.push.apply(quotesArr, AgentUserAndQuoteResultStore.instance.model.aonEdgeResult.quotes);
        quotesArr.push.apply(quotesArr, AgentUserAndQuoteResultStore.instance.model.argeniaResult.quotes);
        quotesArr.push.apply(quotesArr, AgentUserAndQuoteResultStore.instance.model.fRSResult.quotes);
        quotesArr.push.apply(quotesArr, AgentUserAndQuoteResultStore.instance.model.neptuneResult.quotes);
        quotesArr.push.apply(quotesArr, AgentUserAndQuoteResultStore.instance.model.sterlingResult.quotes);
        quotesArr.push.apply(quotesArr, AgentUserAndQuoteResultStore.instance.model.selectiveResult.quotes);
        quotesArr.push.apply(quotesArr, AgentUserAndQuoteResultStore.instance.model.wrightPrivateFloodResult.quotes);
        quotesArr.push.apply(quotesArr, AgentUserAndQuoteResultStore.instance.model.zurichResult.quotes);

        return quotesArr.find(q => q.id === this.quoteId);
    }

    // #region Navigation

    public backToDashboard = () => {
        this.history.push("/agent/report");
    };

    public backToUserReport = () => {
        this.history.push(`/agentuserreport/${AgentUserAndQuoteResultStore.instance.model.id}`);
    };

    public backToQuotes = () => {
        this.history.push(`/agentuserreportquotes/${this.blobId}`);
    };

    // #endregion Navigation

    // #region Server Api

    /**
     * Loads the specified quote from the server.
     *
     * @param id The unique identifier of the blob to load.
     */
    public load = async (id: string): Promise<void> => {
        try {
            this.setIsLoading(true);

            const apiResult = await this.Post<AgentUserAndQuoteResultModelDTO>(Server.Api.Agent.GetUserAndQuoteResult, { id });

            if (apiResult.wasSuccessful) {
                AgentUserAndQuoteResultStore.instance.model.fromDto(apiResult.payload);
            } else {
                this.setIsErrored(true);
            }
        } catch (exception) {
            this.setIsErrored(true);
        } finally {
            this.setIsLoading(false);
        }
    };

    // #endregion Server Api

    // #region Viewmodel Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Viewmodel Boilerplate
}
