import { ContactModel } from "./ContactModel";
import { observable } from "mobx";
import { IsNotEmpty, Matches, IsIn, Equals } from "class-validator";
import { isStandardPasswordStrengthEightChars } from "Core/Utils/Validations";
import { AgencyDetailModel } from "./AgencyDetailModel";
import { ModelBase } from "../../Core/Models/ModelBase";

export class ProRegisterModel extends ModelBase<ProRegisterModel, ProRegisterModel> {
    @observable
    @IsNotEmpty({ message: "Agency name is required" })
    public agencyName: string = "";

    @observable
    @IsNotEmpty({ message: "Password is required" })
    /* @Matches(isStandardPasswordStrengthEightChars, {
		message: "Password must contain at least 8 characters and include upper & lower case letters and at least one digit.",
	}) */
    public password: string = "";

    @observable
    @IsNotEmpty({ message: "You must confirm your password" })
    public confirmPassword: string = "";

    @observable
    @IsIn([true], { message: "You must accept the terms" })
    public hasAcceptedTerms: boolean = false;

    @observable
    @Equals(true, { message: "You must pass the captcha" })
    public passCaptcha: boolean = false;

    public isProAccount = true;

    public fromDto = (model: ProRegisterModel) => {};
    public toDto = (model: ProRegisterModel) => {};
}
