import * as SignalR from "@microsoft/signalr";
import { StoresInstance } from "../Stores";
import { action, computed, observable } from "mobx";

export default class SignalRAuthenticatedConnection {
    // #region Public Interface

    public static get instance() {
        if (this._instance === undefined) {
            this._instance = new SignalRAuthenticatedConnection();
        }

        return this._instance;
    }

    public get connection(): SignalR.HubConnection {
        return this._connection;
    }

    // #endregion Public Interface

    // #region Status
    @observable
    public isSignalRConnected: boolean = false;

    @action
    public setIsSignalRConnected = (value: boolean) => {
        this.isSignalRConnected = value;
    };
    // #endregion Status

    // #region Private

    private static _instance: SignalRAuthenticatedConnection;

    private _connection: SignalR.HubConnection;
    private _reconnectionInterval = 5000;

    private constructor() {
        const token = StoresInstance.domain.AccountStore.getJwt();
        this._connection = new SignalR.HubConnectionBuilder()
            .withUrl("/hubs/data", {
                accessTokenFactory: () => (token === null ? "" : token),
            })
            .build();

        this._connection.onclose(async () => await this.start());

        this.start();
    }

    private async start(): Promise<void> {
        try {
            await this._connection.start();
            console.log("**** MFI: SIGNALR Connected.");
            this.setIsSignalRConnected(true);
        } catch (exception) {
            console.log(`**** MFI: SIGNALR Error. ${exception}`);
            setTimeout(() => this.start(), this._reconnectionInterval);

            // If this fails again, double the connection interval.
            this._reconnectionInterval *= 2;
            this.setIsSignalRConnected(false);
        }
    }

    // #endregion Private
}
