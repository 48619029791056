import { makeStyles, createMuiTheme } from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";
import styled from "styled-components";

export const useOverallStatisticsStyles = makeStyles(theme => ({
    paper: {
        backgroundColor: "transparent",
        boxShadow: "none",
    },
    title: {
        color: "#8CD2F7",
        fontSize: "18px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "14px",
        },
        fontWeight: "bold",
    },
    text: {
        color: "white",
        fontSize: "48px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "28px",
        },
        fontWeight: "bold",
        marginTop: "-6px",
    },
}));

export const useInsurerStatisticsHeaderStyles = makeStyles(theme => ({
    paper: {
        alignItems: "center",
        backgroundColor: "#3A4650",
        boxShadow: "none",
        borderRadius: "0",
        display: "flex",
        height: "95px",
        padding: "20px",
    },
    title: {
        color: "white",
        fontSize: "24px",
        fontWeight: "bold",
    },
}));

export const useInsurerStatisticsBodyStyles = makeStyles(theme => ({
    paper: {
        backgroundColor: "#666F77",
        boxShadow: "none",
        display: "flex",
        borderRadius: "0",
        padding: "5px 10px",
    },
    imageContainer: {
        backgroundColor: "white",
        height: "48px",
        margin: "5px 10px",
        padding: "4px",
        width: "64px",
        "& div": {
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            height: "100%",
            width: "100%",
        },
    },
    textContainer: {
        alignItems: "center",
        display: "flex",
        flex: "1",
        justifyContent: "center",
        margin: "5px 10px",
    },
    title: {
        color: "white",
        flex: "1",
        fontSize: "14px",
        fontWeight: "bold",
    },
    text: {
        color: "white",
        fontSize: "21px",
        fontWeight: "bold",
    },
}));

export const useAddressHeaderStyles = makeStyles(theme => ({
    paper: {
        alignItems: "center",
        backgroundColor: "#3A4650",
        boxShadow: "none",
        borderRadius: "0",
        display: "flex",
        padding: "30px 30px",
        ["@media (max-width:499px)"]: {
            padding: "20px 20px",
        },
    },
    title: {
        color: "white",
        fontSize: "24px",
        fontWeight: "bold",
        ["@media (max-width:499px)"]: {
            fontSize: "20px",
        },
    },
    titlePrefix: {
        color: "#8CD2F7",
    },
    rerunBtn: {
        backgroundColor: "#129BDB",
        borderRadius: "50px",
        padding: "16px 30px",
        margin: "-8px -10px -8px 20px",
        whiteSpace: "nowrap",
        color: "#FFFFFF",
        fontSize: "18px",
        letterSpacing: "-0.9px",
        lineHeight: "22px",
        fontWeight: "bold",
        "&:hover": {
            backgroundColor: "#0F89C2",
        },
        "&:disabled": {
            backgroundColor: "rgb(35 69 146 / 20%)",
            color: "rgb(255 255 255 / 70%)",
            boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
        },
    },
    rerunBtnLoading: {
        backgroundColor: "rgb(0 0 0 / 10%) !important",
        boxShadow: "none !important",
    },
    rerunSpinDisabled: {
        opacity: "0.7",
    },
}));

const filterAndSortBarTextColor = "#FFF";
const filterAndSortBarBackgroundColor = "#FFF";
const filterAndSortBarBackgroundOpacity = 0.2;

export const useFilterAndSortBarStyles = makeStyles(theme => ({
    paper: {
        alignItems: "center",
        backgroundColor: fade(filterAndSortBarBackgroundColor, filterAndSortBarBackgroundOpacity),
        boxShadow: "none",
        borderRadius: "0",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        padding: "30px 30px",
        ["@media (max-width:499px)"]: {
            padding: "20px 20px",
        },
    },
    title: {
        color: filterAndSortBarTextColor,
        fontSize: "21px",
    },
    sortBox: {
        display: "flex",
        alignItems: "center",
        ["@media (max-width:499px)"]: {
            flexDirection: "column",
            alignItems: "flex-start",
        },
    },
    sortTitle: {
        fontWeight: "bold",
    },
    select: {
        display: "flex",
        flex: "1",
        marginLeft: "20px",
        ["@media (max-width:499px)"]: {
            marginLeft: "0px",
        },
    },
}));

const adminDashboardTitleBarFooterBackgroundColor = "#3A4650";
const adminDashboardHeaderBackgroundColor = "#E7F6FE";
const adminDashboardForegroundColor = "#FFF";
const adminDashboardBodyBorderColor = "#F0F0F0";
const adminDashboardDisabledOpacity = 0.3;

export const useAdminStyles = makeStyles(theme => ({
    root: {
        "& > div": {
            backgroundColor: "rgba(27, 43, 55, 0.75)",
            padding: "40px 20px",
            minHeight: "100vh",
            "& > div": {
                marginLeft: "auto !important",
                marginRight: "auto !important",
                maxWidth: 1140,
            },
        },
    },
    navigationButton: {
        backgroundColor: "#129BDB",
        borderRadius: "27px",
        color: "#FFFFFF",
        fontSize: "18px",
        fontWeight: "bold",
        height: "54px",
        margin: "0",
        padding: "0 30px",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#0F89C2",
        },
        [theme.breakpoints.down("xs")]: {
            borderRadius: "20px",
            height: "40px",
            marginBottom: "10px",
            marginTop: "10px",
            width: "100%",
        },
    },
    navigationGroupUnderButton: {
        borderBottomRightRadius: "0",
        borderTopRightRadius: "0",
        paddingRight: "40px",
        backgroundColor: "#005FAB;",
        [theme.breakpoints.down("xs")]: {
            borderBottomRightRadius: "20px",
            borderTopRightRadius: "20px",
        },
    },
    navigationGroupMiddleUnderButton: {
        borderBottomRightRadius: "0",
        borderTopRightRadius: "0",
        marginLeft: "-30px",
        paddingRight: "40px",
        backgroundColor: "#0874B6;",
        [theme.breakpoints.down("xs")]: {
            marginLeft: "0px",
            borderBottomRightRadius: "20px",
            borderTopRightRadius: "20px",
        },
    },
    navigationGroupOverButton: {
        marginLeft: "-30px",
        [theme.breakpoints.down("xs")]: {
            marginLeft: "0px",
        },
    },
}));

export const useAdminDashboardTableStyles = makeStyles(theme => ({
    root: {
        // Remove border radius from corners of the table.
        "& .MuiPaper-root": {
            borderRadius: "0",
        },
        "& .MuiToolbar-root": {
            // Set title bar and footer background colour.
            backgroundColor: adminDashboardTitleBarFooterBackgroundColor,
            [theme.breakpoints.down("xs")]: {
                flexDirection: "column",
            },

            // Spacing.
            minHeight: "95px",
            padding: "15px 30px",

            // Palette and Font. Title bar title.
            "& .MuiTypography-h6": {
                color: adminDashboardForegroundColor,
                fontSize: "24px",
                fontWeight: "bold",
            },

            // Palette. Title bar search.
            "& .MuiInput-root": {
                color: adminDashboardForegroundColor,
            },
            "& .MuiInput-underline:before": {
                borderColor: fade(adminDashboardForegroundColor, adminDashboardDisabledOpacity),
            },
            "& .MuiInput-underline:after": {
                borderColor: adminDashboardForegroundColor,
            },
            "& button": {
                color: adminDashboardForegroundColor,
            },
            "& button:disabled": {
                color: fade(adminDashboardForegroundColor, adminDashboardDisabledOpacity),
            },
        },
        "& .MuiTableCell-head": {
            // Set header background colour.
            backgroundColor: adminDashboardHeaderBackgroundColor,

            // Font.
            fontWeight: "bold",

            // Spacing.
            padding: "15px 15px 15px 30px",
        },
        "& .MuiTableCell-body": {
            borderBottom: `1px solid ${adminDashboardBodyBorderColor}`,

            // Font.
            fontSize: "12px",

            // Spacing.
            padding: "15px 15px 15px 30px",
        },
        "& .MuiTablePagination-toolbar": {
            minHeight: "70px",
        },
        "& .MuiTablePagination-input": {
            // Spacing. Move n rows dropdown to the left of the footer. Alignment.
            paddingLeft: "0",
            marginRight: "auto",
            "& div": {
                paddingLeft: "0 !important",
            },

            // Palette. N rows dropdown.
            color: adminDashboardForegroundColor,
            "& svg": {
                color: adminDashboardForegroundColor,
            },
        },
        "& .MuiTablePagination-spacer": {
            // Spacing. Move n rows dropdown to the left of the footer.
            flex: "0",
        },
        "& .MuiTableCell-footer": {
            // Remove white border from bottom of footer.
            border: "none",

            // Palette. Previous/Next Page.
            "& button": {
                color: adminDashboardForegroundColor,
            },
            "& button:disabled": {
                color: fade(adminDashboardForegroundColor, adminDashboardDisabledOpacity),
            },
            "& .MuiTypography-caption": {
                color: adminDashboardForegroundColor,
            },
        },
    },
}));

export const SelectStyles = styled.div`
    .MuiInputBase-root {
        color: white;
        flex: 1;
        font-size: 18px;
    }

    .MuiSelect-root {
        background-color: rgba(255, 255, 255, 0.4);
        padding-bottom: 6px;
        padding-top: 6px;
    }

    .MuiSelect-icon {
        color: white;
    }

    div:not(.Mui-error) {
        fieldset {
            border-color: #8cd2f7 !important;
        }
    }
`;

export const useAdminUserReportStyles = makeStyles(theme => ({
    tableTitle: {
        color: "#FFF",
        fontSize: "21px",
        fontWeight: "bold",
    },
    tableTitlePrefix: {
        color: "#8CD2F7",
    },
}));

export const useAdminUserReportQuotesStyles = makeStyles(theme => ({
    resultTitle: {
        marginBottom: "14px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "14px",
        },
    },
    resultList: {
        paddingBottom: "15px !important",
    },
}));

export const useAdminUserReportQuoteStyles = makeStyles(theme => ({}));

const policyQuoteNumberColor = "#129BDB";
const policyDeductibleColor = "#129BDB";
const policyBorderColor = "#129BDB";
const policyBodyBackgroundColor = "#E7F6FE";
const policyDividerColor = "#129BDB";

export const usePolicyStyles = makeStyles(theme => ({
    // Text styles
    h1: {
        marginBottom: "-5px",
        marginTop: "-5px",
    },
    [theme.breakpoints.down("xs")]: {
        h1: {
            fontSize: "18px",
        },
        h2: {},
        h3: {
            fontSize: "14px",
        },
    },
    title: {
        paddingBottom: "8px",
        [theme.breakpoints.down("xs")]: {
            paddingBottom: "15px",
        },
    },
    label: {
        fontSize: "14px",
        fontWeight: "bold",
        [theme.breakpoints.down("xs")]: {
            fontSize: "10px",
        },
    },
    paragraph: {
        fontSize: "14px",
        fontWeight: "bold",
        [theme.breakpoints.down("xs")]: {
            fontSize: "10px",
        },
    },
    paragraphNormal: {
        fontSize: "14px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "10px",
        },
    },
    deductibleColor: {
        color: policyDeductibleColor,
    },
    contactParagraph: {
        paddingRight: "20px",
    },
    addressParagraph: {
        paddingBottom: "25px",
    },

    // Layout styles
    paper: {
        borderColor: policyBorderColor,
        borderRadius: "15px",
        borderStyle: "solid",
        borderWidth: "5px",
    },
    headerAndBody: {},
    header: {},
    body: {
        backgroundColor: policyBodyBackgroundColor,
        borderBottomLeftRadius: "15px",
        borderBottomRightRadius: "15px",
        paddingBottom: "40px",
    },
    labelGridItem: {
        width: "50%",
        [theme.breakpoints.down("xs")]: {
            width: "60%",
        },
    },
    paragraphGridItem: {
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: "35px",
        width: "20%",
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "15px",
            width: "40%",
        },
    },
    paragraphGridItemWide: {
        width: "50%",
    },
    gridContainerRowSpacing: {
        marginBottom: "9px",
        [theme.breakpoints.down("xs")]: {
            marginBottom: "0",
        },
    },
    gridItem: {
        padding: "35px",
        [theme.breakpoints.down("xs")]: {
            padding: "20px 15px",
        },
    },
    gridItemFlex: {
        flex: "1",
    },
    totalGridItem: {
        padding: "15px 35px",
        [theme.breakpoints.down("xs")]: {
            padding: "20px 15px",
        },
    },

    // Header Summary styles
    headerSummary: {
        display: "flex",
        marginBottom: "-5px",
        marginTop: "-5px",
    },
    headerSummaryQuoteNumber: {
        color: policyQuoteNumberColor,
    },
    headerSummaryImage: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "0 10px",
    },
    headerSummaryInformation: {
        display: "flex",
        flexFlow: "column",
        flex: "1",
    },
    headerSummaryInformationContainer: {
        alignItems: "baseline",
    },
    headerSummaryInsurer: {
        fontSize: "18px",
    },
    headerSummaryPremium: {
        fontSize: "24px",
        fontWeight: "bold",
    },
    headerSummaryDeductible: {
        color: policyDeductibleColor,
        fontSize: "18px",
        fontWeight: "bold",
    },

    // Header Details Styles
    headerDetailsTitleBox: {
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
        "& img": {
            marginRight: "20px",
        },
    },
    headerPrivateQuoteDetailsTitleBox: {
        marginBottom: "7.5px",
    },
    headerDetailsTitle: {
        fontSize: "18px",
        fontWeight: "bold",
        [theme.breakpoints.down("xs")]: {
            fontSize: "10px",
        },
    },
    headerDetailsItemText: {
        fontSize: "12px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "10px",
        },
    },
    headerPrivateQuoteDetailsTitleContainer: {
        width: "auto",
        marginLeft: "-15px",
        marginRight: "-15px",
    },
    headerPrivateQuoteDetailsItem: {
        paddingLeft: "15px",
        paddingRight: "15px",
        margin: "5px 0",
    },

    // Divider styles
    dottedDivider: {
        borderBottomColor: policyDividerColor,
        borderBottomStyle: "dashed",
        borderBottomWidth: "1px",
        display: "flex",
        flex: "1",
        height: "1px",
        margin: "0 35px",
        [theme.breakpoints.down("xs")]: {
            margin: "0 15px",
        },
    },
    solidDivider: {
        borderBottomColor: policyDividerColor,
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        display: "flex",
        flex: "1",
        height: "1px",
        margin: "0 35px",
        [theme.breakpoints.down("xs")]: {
            margin: "0 15px",
        },
    },
    verticalSolidDivider: {
        borderRightColor: policyDividerColor,
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        height: "auto",
        margin: "0 40px 0 0",
    },
}));
