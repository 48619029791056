// Libraries
import * as MobX from "mobx";
import { runInAction } from "mobx";

// Core
import { BlankModel } from "Core/Models/BlankModel";
import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";

// Custom
import { Server } from "Custom/Globals/AppUrls";
import { FooterNavigationLinksModel, NavigationLinkModel } from "Custom/Models";

/**
 * Singleton footer viewmodel.
 */
export class FooterViewModel extends ViewModelBase<BlankModel> {
    /**
     * The viewmmodel instance.
     */
    private static _instance: FooterViewModel;

    /**
     * Gets an instance of the viewmodel.
     *
     * If the instance is undefined, it will be created before returning to the caller.
     */
    public static get instance() {
        if (this._instance === undefined) {
            this._instance = new FooterViewModel();
            this._instance.load();
        }

        return this._instance;
    }

    /**
     * An observable collection of city navigation links.
     */
    @MobX.observable
    public CityNavigationLinks = MobX.observable<NavigationLinkModel>([]);

    /**
     * An observable collection of state navigation links.
     */
    @MobX.observable
    public StateNavigationLinks = MobX.observable<NavigationLinkModel>([]);

    /**
     * Determines if the cities should be displayed in the view.
     */
    @MobX.computed
    public get canDisplayCities(): boolean {
        return this.CityNavigationLinks.length > 0;
    }

    /**
     * Determines if the states should be displayed in the view.
     */
    @MobX.computed
    public get canDisplayStates(): boolean {
        return this.StateNavigationLinks.length > 0;
    }

    /**
     * Constructor (which is private as this is a singleton).
     */
    private constructor() {
        super(new BlankModel());
        this.setDecorators(BlankModel);
    }

    /**
     * Loads the cities and states from the server.
     */
    private load = async (): Promise<void> => {
        try {
            this.setIsLoading(true);

            const apiResult = await this.Post<FooterNavigationLinksModel>(Server.Api.Pages.NavigationLinks);

            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.CityNavigationLinks.replace(apiResult.payload.cityNavigationLinks);
                    this.StateNavigationLinks.replace(apiResult.payload.stateNavigationLinks);
                });
            } else {
                // Error. What to do?
            }
        } catch (exception) {
            // Error. What to do?
        } finally {
            this.setIsLoading(false);
        }
    };

    // #region Viewmodel Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Viewmodel Boilerplate
}
