import { useRouter, useEffect } from "../../../Custom/Globals/Base";

export function useHashLinks() {
    const { location, history } = useRouter();

    const goToHash = (hash: string) => {
        const anchorToFind = hash.replace("#", "");
        console.log("anchor", anchorToFind);
        if (anchorToFind) {
            const scrollElement = document.getElementById(anchorToFind);
            if (scrollElement) {
                scrollElement.scrollIntoView();
            }
        } else {
            window.scrollTo(0, 0);
        }
    };

    useEffect(() => {
        goToHash(location.hash);
        history.listen(h => goToHash(h.hash));
    }, []);
}
