import * as MobX from "mobx";

import { IsEmail, IsNotEmpty, IsPhoneNumber, Length, IsNumberString } from "class-validator";

import { ModelBase } from "../../Core/Models/ModelBase";

export class RealtorDetailModel extends ModelBase<RealtorDetailModel, RealtorDetailModelDTO> {
    // #region Default State

    public static readonly MODEL_DEFAULT_ID = undefined;
    public static readonly MODEL_DEFAULT_USERID = "";
    public static readonly MODEL_DEFAULT_NAME = "";
    public static readonly MODEL_DEFAULT_LOGO = "";
    public static readonly MODEL_DEFAULT_ADDRESS1 = "";
    public static readonly MODEL_DEFAULT_ADDRESS2 = "";
    public static readonly MODEL_DEFAULT_ADDRESS3 = "";
    public static readonly MODEL_DEFAULT_STATE = "";
    public static readonly MODEL_DEFAULT_ZIPCODE = "";
    public static readonly MODEL_DEFAULT_ISDELETED = false;
    public static readonly MODEL_DEFAULT_COUNTY = "";

    // #endregion Default State

    @MobX.observable
    public id: string | undefined = RealtorDetailModel.MODEL_DEFAULT_ID;

    @MobX.observable
    public userId: string = RealtorDetailModel.MODEL_DEFAULT_USERID;

    @MobX.observable
    @IsNotEmpty({ message: "Required" })
    public name: string = RealtorDetailModel.MODEL_DEFAULT_NAME;

    @MobX.observable
    public logo: string = RealtorDetailModel.MODEL_DEFAULT_LOGO;

    /**
     * Address details not used currently
     */
    @MobX.observable
    /* @IsNotEmpty({ message: "Required" }) */
    public address1: string = RealtorDetailModel.MODEL_DEFAULT_ADDRESS1;

    @MobX.observable
    public address2: string = RealtorDetailModel.MODEL_DEFAULT_ADDRESS2;

    @MobX.observable
    public address3: string = RealtorDetailModel.MODEL_DEFAULT_ADDRESS3;

    @MobX.observable
    /* @IsNotEmpty({ message: "Required" }) */
    public county: string = RealtorDetailModel.MODEL_DEFAULT_COUNTY;

    @MobX.observable
    /* @IsNotEmpty({ message: "Required" }) */
    public state: string = RealtorDetailModel.MODEL_DEFAULT_STATE;

    @MobX.observable
    /* @IsNotEmpty({ message: "Required" })
    @Length(5, 5, { message: "Invalid Zipcode" })
    @IsNumberString({ message: "Invalid Zipcode" }) */
    public zipCode: string = RealtorDetailModel.MODEL_DEFAULT_ZIPCODE;
    /**
     * Address details end
     */

    @MobX.observable
    public isDeleted: boolean = RealtorDetailModel.MODEL_DEFAULT_ISDELETED;

    @MobX.action
    public fromDto = (model: RealtorDetailModelDTO): void => {
        this.id = model.id;
        this.userId = model.userId;
        this.name = model.name;
        this.logo = model.logo;
        this.address1 = model.address1;
        this.address2 = model.address2;
        this.address3 = model.address3;
        this.state = model.state;
        this.zipCode = model.zipCode;
        this.isDeleted = model.isDeleted;
    };

    public toDto = (): RealtorDetailModelDTO => {
        return {
            id: this.id,
            userId: this.userId,
            name: this.name,
            logo: this.logo,
            address1: this.address1,
            address2: this.address2,
            address3: this.address3,
            state: this.state,
            zipCode: this.zipCode,
            isDeleted: this.isDeleted,
        };
    };

    @MobX.action
    public reset = () => {
        this.id = RealtorDetailModel.MODEL_DEFAULT_ID;
        this.userId = RealtorDetailModel.MODEL_DEFAULT_USERID;
        this.name = RealtorDetailModel.MODEL_DEFAULT_NAME;
        this.logo = RealtorDetailModel.MODEL_DEFAULT_LOGO;
        this.address1 = RealtorDetailModel.MODEL_DEFAULT_ADDRESS1;
        this.address2 = RealtorDetailModel.MODEL_DEFAULT_ADDRESS2;
        this.address3 = RealtorDetailModel.MODEL_DEFAULT_ADDRESS3;
        this.state = RealtorDetailModel.MODEL_DEFAULT_STATE;
        this.zipCode = RealtorDetailModel.MODEL_DEFAULT_ZIPCODE;
        this.isDeleted = RealtorDetailModel.MODEL_DEFAULT_ISDELETED;
    };
}

export interface RealtorDetailModelDTO {
    id?: string;
    userId: string;
    name: string;
    logo: string;
    address1: string;
    address2: string;
    address3: string;
    state: string;
    zipCode: string;
    isDeleted: boolean;
}
