// Libraries
import * as MobX from "mobx";
import { runInAction } from "mobx";

// Core
import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";

// Custom
import { Server } from "Custom/Globals/AppUrls";
import { CityModel, CityEnquiryModel, ShowIsHidden } from "Custom/Models";
import { StoresInstance } from "Custom/Stores";

/**
 * Cities viewmodel.
 */
export class CitiesViewModel extends ViewModelBase<CityModel> {
    /**
     * Constructor.
     */
    constructor() {
        super(new CityModel());
        this.setDecorators(CityModel);
    }

    /**
     * An observable collection of cities.
     */
    @MobX.observable
    public Cities = MobX.observable<CityModel>([]);

    // #region Server Api

    /**
     * Loads the cities from the server.
     */
    public load = async (): Promise<void> => {
        try {
            this.setIsLoading(true);

            const showHidden: ShowIsHidden = { ViewHidden: true };
            const apiResult = await this.Post<CityModel[]>(Server.Api.Pages.CitiesList, showHidden);

            if (apiResult.wasSuccessful) {
                runInAction(() => this.Cities.replace(apiResult.payload));
            } else {
                // Error. What to do?
                this.setIsErrored(true);
            }
        } catch (exception) {
            // Error. What to do?
            this.setIsErrored(true);
        } finally {
            this.setIsLoading(false);
        }
    };

    public loadByState = async (): Promise<void> => {
        try {
            this.setIsLoading(true);

            const cityEnquiry: CityEnquiryModel = {
                isHidden: StoresInstance.domain.CityIsHidden,
                statesId: StoresInstance.domain.CityStatesId,
            };
            const apiResult = await this.Post<CityModel[]>(Server.Api.Pages.CitiesByStatesId, cityEnquiry);

            if (apiResult.wasSuccessful) {
                runInAction(() => this.Cities.replace(apiResult.payload));
            } else {
                // Error. What to do?
                this.setIsErrored(true);
            }
        } catch (exception) {
            // Error. What to do?
            this.setIsErrored(true);
        } finally {
            this.setIsLoading(false);
        }
    };

    // #endregion Server Api

    // #region Viewmodel Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Viewmodel Boilerplate
}
