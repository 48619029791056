// Libraries
import React, { useContext } from "react";
import { useObserver } from "mobx-react-lite";
import { Link as RouterLink, useParams } from "react-router-dom";

// App
import { Stores, StoresContext, StoresInstance } from "../../Stores";
import { MapStore } from "../../Stores/Domain";
import { useRouter } from "../../../Core/Utils";

// Components
import { AddressSelectorForWidget } from "../../Components/AddressSelector";

// Styling & images
import { Link } from "@material-ui/core";
import { BtnWrapper } from "./Widget.Styles";
import { GridSpaced } from "../../StylesAppSpecific/GridHelperStyling";
import MFIheaderlogo from "../../../Content/logoForDarkBG.png";

export const HomeWidget: React.FC = () => {
    const domainStores = StoresInstance.domain;
    const mapStoresContext: MapStore = useContext<Stores>(StoresContext).domain.MapStore;
    const { history, match } = useRouter();

    //let uriStr = encodeURIComponent("from widget");

    const urlSearchParams = new URLSearchParams(window.location.search);
    let uriStr = urlSearchParams.get("referrer");
    console.log(uriStr);

    const clickStart = (destination: string) => {
        mapStoresContext.setIsAvailable(false);
        domainStores.QuoteRequestViewModel.setValue("address", "");

        if (uriStr === null || uriStr.length === 0) {
            uriStr = "unknown";
        }
        destination += "/" + uriStr;

        window.open(destination, "_blank");
    };

    return useObserver(() => (
        <>
            <GridSpaced container>
                <GridSpaced item margin={"30px auto"} additionalcss={"text-align: center;"}>
                    <img src={MFIheaderlogo} alt="My Flood Insurance" style={{ maxWidth: "200px" }} />
                </GridSpaced>
            </GridSpaced>
            <GridSpaced container>
                <GridSpaced item margin={"0px auto 30px"} additionalcss="width: 90%;">
                    <AddressSelectorForWidget />
                </GridSpaced>
            </GridSpaced>

            <GridSpaced container>
                <GridSpaced item margin={"0px auto"} additionalcss="width: 90%;">
                    {mapStoresContext.isAvailable && mapStoresContext.routeWithDetails.length > 0 ? (
                        <BtnWrapper className="selected">
                            <Link onClick={() => clickStart(mapStoresContext.routeWithDetails)} title="Click here to get your quotes">
                                Click here to get your quotes
                            </Link>
                        </BtnWrapper>
                    ) : (
                        <BtnWrapper>
                            <Link>Click here to get your quotes</Link>
                        </BtnWrapper>
                    )}
                </GridSpaced>
            </GridSpaced>
        </>
    ));
};
