import { Grid } from "@material-ui/core";
import styled from "styled-components";

export interface IComponentProps {
    padding?: string;
    margin?: string;
    fontcolor?: string;
    backgroundcolor?: string;
    additionalcss?: string;
}

export const AgentWrapTabDiv: any = styled.div<IComponentProps>`
    /* border: 10px solid #129bdb;
	border-radius: 30px; */
    text-align: left;
    /* background: #e7f6fe 0% 0% no-repeat padding-box; */
    /* color: #464545; */
    padding: ${props => (props.padding !== undefined ? props.padding : "0")};
    margin: ${props => (props.margin !== undefined ? props.margin : "0 auto")};
    width: 100%;
    @media (max-width: 767px) {
        /*width: 335px;*/
        width: 100%;
        min-width: 320px;
        padding: 10px 20px;
    }
    ${props => props.additionalcss};
`;

export const ViewPDFAnchor = styled.a`
    color: #8cd2f7;
    margin: auto 0 auto auto;

    :visited {
        color: #f2af4c;
    }
`;
