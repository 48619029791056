// Libs
import * as MobX from "mobx";

import { Role, RoleDTO } from "../../../Models/Role";

// App
import { BaseStore } from "../BaseStore";
import Moment from "moment";
import { Stores } from "Custom/Stores/index";

interface Filters {
    date: any;
    orderAsc: boolean;
    searchFilter: string;
}

export class RoleStore extends BaseStore {
    private roles = MobX.observable<Role>([]);
    @MobX.observable private isLoadingData: boolean = false;
    @MobX.observable private filters: Filters = {
        date: Moment(),
        orderAsc: true,
        searchFilter: "",
    };
    @MobX.observable private lastUpdated: any = Moment();

    public constructor() {
        super();
    }

    public Init(stores: Stores) {}

    @MobX.computed get getRoleCount(): number {
        return this.roles.length;
    }

    @MobX.action
    public setRoles(roles: RoleDTO[]) {
        this.roles.clear();
        roles.forEach((role, index) => {
            let domainModel = this.roles.find(dm => dm.id === role.id);

            if (!domainModel) {
                domainModel = new Role(role.id);

                domainModel.fromDto(role);
                this.roles.push(domainModel);
            }
        });
        this.isLoadingData = false;
        this.lastUpdated = Moment();
    }

    @MobX.action
    public clearRoles() {
        this.roles.clear();
    }

    @MobX.action
    public setIsLoadingData(loading: boolean) {
        this.isLoadingData = loading;
    }

    @MobX.computed
    public get getRoles() {
        return this.roles;
    }

    @MobX.computed
    public get getIsLoadingData() {
        return this.isLoadingData;
    }

    @MobX.computed
    public get getFilters() {
        return this.filters;
    }

    @MobX.computed
    public get getFilteredRoles() {
        let roles = this.roles.slice();
        if (this.filters.searchFilter) {
            roles = this.roles.filter(or => or.name.toLowerCase().includes(this.filters.searchFilter));
        }
        return roles;
    }
}
