// Libraries
import { useObserver } from "mobx-react-lite";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";

// Core
import { ApiResult } from "../../../Core/Models";
import { useRouter, dateTimeFormat } from "../../../Core/Utils";

// App
import UserViewModel from "Custom/ViewModels/UserViewModel";
import { UserDTO } from "Custom/Models/User";
import { ButtonLink } from "../../StylesAppSpecific/AdminStyling";
import styled from "styled-components";
import { User } from "../../Models/User";
import { DivWrapper } from "Custom/StylesAppSpecific/GridHelperStyling";
import { ProRegisterViewModel } from "Custom/ViewModels/ProRegisterViewModel";
import { StoresInstance } from "Custom/Stores";
import { DashboardHeaderContainer } from "../Dashboard";
import { ResetPasswordViewModel } from "Custom/ViewModels";
import { ContactDetail } from "Custom/Components/ContactForm";
import { ContactDetailStyle2 } from "Custom/StylesAppSpecific/ContactForms";
import { ShowPasswordContainer, ShowPasswordIcon2 } from "Custom/StylesAppSpecific/LoginStyling";
import { EditableInput } from "Core/Components/EditableInput";
import { ResetPasswordModel } from "Custom/Models";
import EyeClosed from "Content/icon-Eye-closed.svg";
import EyeOpen from "Content/icon-Eye-open.svg";
import { isNullOrUndefined } from "util";

export const AgentProfile: React.FC = () => {
    const domainStores = StoresInstance.domain;
    const { history } = useRouter();
    const [viewModel] = useState(() => new ProRegisterViewModel());
    const accountStore = domainStores.AccountStore;
    const [passwordViewModel] = useState(() => new ResetPasswordViewModel());
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    useEffect(() => {
        viewModel.contactViewModel.setValue("email", accountStore.Email);
        viewModel.contactViewModel.setValue("firstName", accountStore.FirstName);
        viewModel.contactViewModel.setValue("lastName", accountStore.LastName);
        viewModel.contactViewModel.setValue("tel", accountStore.Phone);
        viewModel.setValue("agencyName", isNullOrUndefined(accountStore.AgencyName) ? "" : accountStore.AgencyName);
    }, []);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        viewModel.contactViewModel.setValue("terms", true);
        let passwordValid = true;
        if (passwordViewModel.getValue("newPassword") || passwordViewModel.getValue("confirmPassword")) {
            passwordValid = passwordViewModel.isModelValid();
        }
        if (passwordValid && viewModel.contactViewModel.isModelValid()) {
            setIsLoading(true);
            viewModel.updateProAccountDetails(passwordViewModel.getValue<string>("newPassword")).then(apiResult => {
                if (apiResult && apiResult.wasSuccessful) {
                    history.push("/agent");
                } else {
                    setIsLoading(false);
                    if (apiResult && apiResult.errors && apiResult.errors[0] && apiResult.errors[0].message) {
                        setErrorMessage(apiResult.errors[0].message);
                    } else {
                        setErrorMessage("An unknown error occured.");
                    }
                }
            });
        }
    };

    let handleShowPassword = (e: any) => {
        setShowPassword(!showPassword);
    };

    let handleShowPassword2 = (e: any) => {
        setShowPassword2(!showPassword2);
    };

    return useObserver(() => (
        <>
            <div style={{ maxWidth: "1124px", margin: "0 auto" }}>
                <DashboardHeaderContainer>
                    <h1>
                        <span>{accountStore.FirstName},</span> amend your account details here
                    </h1>
                </DashboardHeaderContainer>
                <form onSubmit={handleSubmit}>
                    <DetailsStyles>
                        <ContactDetail
                            viewModel={viewModel.contactViewModel}
                            noBackground
                            namesLabel="What is your name?"
                            contactsLabel="What are your contact details?"
                            hideLogin
                            hideTerms
                        />
                        <ContactDetailStyle2 additionalcss="background-color: transparent;">
                            <h2>Agency name</h2>
                            <EditableInput viewModel={viewModel} fieldName="agencyName" inputProps={{ placeholder: "Agency name" }} />
                        </ContactDetailStyle2>
                        <ContactDetailStyle2 additionalcss="background-color: transparent;">
                            <h2>Set your password</h2>
                            <ShowPasswordContainer>
                                <EditableInput<ResetPasswordModel>
                                    viewModel={passwordViewModel}
                                    inputProps={{ placeholder: "New password" }}
                                    fieldName="newPassword"
                                    type={showPassword ? "text" : "password"}
                                />
                                <ShowPasswordIcon2 onClick={(e: any) => handleShowPassword(e)} title={showPassword ? "Hide password" : "Show password"}>
                                    <img src={showPassword ? EyeClosed : EyeOpen} alt="" />
                                </ShowPasswordIcon2>
                            </ShowPasswordContainer>
                            <ShowPasswordContainer>
                                <EditableInput<ResetPasswordModel>
                                    viewModel={passwordViewModel}
                                    inputProps={{ placeholder: "Confirm password" }}
                                    fieldName="confirmPassword"
                                    type={showPassword2 ? "text" : "password"}
                                />
                                <ShowPasswordIcon2 onClick={(e: any) => handleShowPassword2(e)} title={showPassword2 ? "Hide password" : "Show password"}>
                                    <img src={showPassword2 ? EyeClosed : EyeOpen} alt="" />
                                </ShowPasswordIcon2>
                            </ShowPasswordContainer>
                        </ContactDetailStyle2>
                        {errorMessage && <p style={{ color: "red", marginLeft: "40px", marginTop: "20px" }}>{errorMessage}</p>}
                        <div className="btns">
                            <Button className="save-btn" variant="contained" type="submit" disabled={isLoading}>
                                {isLoading ? "Loading..." : "Save"}
                            </Button>
                            <Button className="cancel-btn" variant="contained" onClick={() => history.goBack()}>
                                Cancel
                            </Button>
                        </div>
                    </DetailsStyles>
                </form>
            </div>
        </>
    ));
};

const DetailsStyles = styled.div`
    background-color: #3a4650;
    padding: 5px 0 40px;

    > div {
        margin-bottom: 0;
        padding-bottom: 0;

        > h2 {
            margin-bottom: 17px;
        }
    }

    > div.btns {
        padding-top: 80px;
        display: flex;
        justify-content: center;

        @media (max-width: 767px) {
            padding-top: 40px;
        }

        button {
            border-radius: 1000px;
            width: 200px;
            margin: 0 7.5px;
            padding: 14px 0 15px;
            color: white;
            font-weight: bold;
            font-size: 1.5rem;
            line-height: 100%;
        }

        button.save-btn {
            background-color: #129bdb;
        }

        button.cancel-btn {
            background-color: #333333;
        }
    }
`;
