import { action, computed, runInAction } from "mobx";

import { DateEffectiveModel } from "../Models/DateEffectiveModel";
import { FieldType } from "Core/Utils/Utils";
import { StoresInstance } from "../Globals/Base";
import { ViewModelBase } from "../../Core/ViewModels/ViewModelBase";
import { dateTimeISOString, isValidDate, lastDayOfMonth, localTime } from "../Utils/Time";
import { isEmptyOrWhitespace } from "../../Core/Utils/Utils";

export class DateEffectiveViewModel extends ViewModelBase<DateEffectiveModel> {
    private days = [] as any[];
    private years = [] as any[];

    constructor(dateEffective?: string) {
        super(new DateEffectiveModel());
        this.setDecorators(DateEffectiveModel);

        this.days = generateDays();
        this.years = generateYears();

        // If a valid effective date is provided, update the model from that.
        /*
        This stops past quotes from being re-run with some carriers (they error)
        if (!isEmptyOrWhitespace(dateEffective)) {
            const localMoment = localTime(dateEffective!);

            if (localMoment.isValid()) {
                this.model.day = localMoment.date();
                this.model.month = localMoment.month();
                this.model.year = localMoment.year();
            }
        }
        */
    }

    public resetAll = () => {
        this.model.resetAll();
    };

    public isFieldValid(fieldName: keyof FieldType<DateEffectiveModel>, value: any): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    @computed
    public get selectDays() {
        let max = 31;

        switch (this.getValue<number>("month")) {
            case 1:
                max = isLeapYear(this.getValue<number>("year")) ? 29 : 28;
                break;
            case 3:
            case 5:
            case 8:
            case 10:
                max = 30;
                break;
            default:
                break;
        }

        return this.days.filter(d => d.value <= max);
    }

    @computed
    public get selectYears() {
        return this.years;
    }

    public afterUpdate() {
        const day = this.getValue<number>("day");
        const month = this.getValue<number>("month");
        const year = this.getValue<number>("year");

        if (!isValidDate(day, month, year)) {
            this.setValue("day", lastDayOfMonth(month, year));
        }
    }

    public beforeUpdate: undefined;
}

function generateDays() {
    const days = [];

    for (let i = 1; i <= 31; i++) {
        days.push({ key: i.toString(), value: i });
    }

    return days;
}

function generateYears() {
    const years = [];

    for (let i = new Date().getFullYear(); i <= 2100; i++) {
        years.push({ key: i.toString(), value: i });
    }

    return years;
}

function isLeapYear(year: number) {
    return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
}
