import { moneyDollarFormat } from "./Money";

// Transform by insurance policy type.
export const getPolicyExtras = (key: string, value: any): string => {
    let bodyText = "";

    switch (key) {
        case "buildingCover":
            if (value > 0) {
                bodyText = `Up to ${moneyDollarFormat(value)} of building coverage.`;
            }
            break;

        case "contentsCover":
            if (value > 0) {
                bodyText = `Up to ${moneyDollarFormat(value)} of contents coverage.`;
            }
            break;

        case "additionalLivingExpenses":
            if (value > 0) {
                bodyText = `Up to ${moneyDollarFormat(value)} to cover living expenses.`;
            }
            break;

        case "otherStructuresCover":
            if (value > 0) {
                bodyText = `Up to ${moneyDollarFormat(value)} to cover other structures.`;
            }
            break;
    }

    return bodyText;
};
