// Libraries
import { timeStamp } from "console";
import { ModelBase } from "Core/Models";
import * as MobX from "mobx";

export class WidgetQuoteModel extends ModelBase<WidgetQuoteModel, WidgetQuoteModelDTO> {
    // #region Default State
    public static readonly MODEL_DEFAULT_ID = undefined;
    public static readonly MODEL_DEFAULT_ADDRESS1 = "";
    public static readonly MODEL_DEFAULT_ADDRESS2 = "";
    public static readonly MODEL_DEFAULT_CITY = "";
    public static readonly MODEL_DEFAULT_STATE = "";
    public static readonly MODEL_DEFAULT_ZIPCODE = "";
    public static readonly MODEL_DEFAULT_LATITUDE = 0;
    public static readonly MODEL_DEFAULT_LONGITUDE = 0;
    public static readonly MODEL_DEFAULT_CREATEDDATE = "";
    public static readonly MODEL_DEFAULT_BLOBID = "";
    public static readonly MODEL_DEFAULT_WIDGETSOURCE = "";
    // #endregion Default State

    @MobX.observable
    public id: string | undefined = WidgetQuoteModel.MODEL_DEFAULT_ID;

    @MobX.observable
    public address1: string = WidgetQuoteModel.MODEL_DEFAULT_ADDRESS1;

    @MobX.observable
    public address2: string = WidgetQuoteModel.MODEL_DEFAULT_ADDRESS2;

    @MobX.observable
    public city: string = WidgetQuoteModel.MODEL_DEFAULT_CITY;

    @MobX.observable
    public state: string = WidgetQuoteModel.MODEL_DEFAULT_STATE;

    @MobX.observable
    public zipcode: string = WidgetQuoteModel.MODEL_DEFAULT_ZIPCODE;

    @MobX.observable
    public latitude: number = WidgetQuoteModel.MODEL_DEFAULT_LATITUDE;

    @MobX.observable
    public longitude: number = WidgetQuoteModel.MODEL_DEFAULT_LONGITUDE;

    @MobX.observable
    public createdDate: string = WidgetQuoteModel.MODEL_DEFAULT_CREATEDDATE;

    @MobX.observable
    public blobId: string = WidgetQuoteModel.MODEL_DEFAULT_BLOBID;

    @MobX.observable
    public widgetSource: string = WidgetQuoteModel.MODEL_DEFAULT_WIDGETSOURCE;

    @MobX.action
    public fromDto = (model: WidgetQuoteModelDTO): void => {
        this.id = model.id;
        this.address1 = model.address1;
        this.address2 = model.address2;
        this.city = model.city;
        this.state = model.state;
        this.zipcode = model.zipcode;
        this.latitude = model.latitude;
        this.longitude = model.longitude;
        this.blobId = model.blobId;
        this.widgetSource = model.widgetSource;
    };

    @MobX.action
    public toDto = (): WidgetQuoteModelDTO => {
        return {
            id: this.id,
            address1: this.address1,
            address2: this.address2,
            city: this.city,
            state: this.state,
            zipcode: this.zipcode,
            latitude: this.latitude,
            longitude: this.longitude,
            createdDate: this.createdDate,
            blobId: this.blobId,
            widgetSource: this.widgetSource,
        };
    };

    @MobX.action
    public reset = () => {
        this.id = WidgetQuoteModel.MODEL_DEFAULT_ID;
        this.address1 = WidgetQuoteModel.MODEL_DEFAULT_ADDRESS1;
        this.address2 = WidgetQuoteModel.MODEL_DEFAULT_ADDRESS2;
        this.city = WidgetQuoteModel.MODEL_DEFAULT_CITY;
        this.state = WidgetQuoteModel.MODEL_DEFAULT_STATE;
        this.zipcode = WidgetQuoteModel.MODEL_DEFAULT_ZIPCODE;
        this.latitude = WidgetQuoteModel.MODEL_DEFAULT_LATITUDE;
        this.longitude = WidgetQuoteModel.MODEL_DEFAULT_LONGITUDE;
        this.blobId = WidgetQuoteModel.MODEL_DEFAULT_BLOBID;
        this.widgetSource = WidgetQuoteModel.MODEL_DEFAULT_WIDGETSOURCE;
    };
}

export interface WidgetQuoteModelDTO {
    id?: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipcode: string;
    latitude: number;
    longitude: number;
    createdDate: string;
    blobId: string;
    widgetSource: string;
}
