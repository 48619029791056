import { Grid, Theme, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import { observable, styled } from "../../Globals/Base";

import { ComponentState } from "../Forms/SelectModal";
import { EditableInput } from "../../../Core/Components/EditableInput";
import { FieldType } from "Core/Utils/Utils";
import { IViewModel } from "Core/ViewModels";
import { ObjPathProxy } from "ts-object-path";
import { RadioYesNo } from "../Forms/RadioYesNo";
import { Validation } from "Core/Components/Validation";
import { action } from "mobx";
import infoTooltip from "Content/info-tooltip.svg";
import { makeStyles } from "@material-ui/styles";
import { useMediaQuery as useMuiMediaQuery } from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import { useMediaQuery } from "Core/Utils";

interface IProps {
    question: string;
    children?: React.ReactNode;
    completed?: boolean;
    additionalcss?: string;
    image?: string;
}

export const SimpleQuestion: React.FC<IProps> = props => {
    const { question, children, completed, additionalcss, image } = props;
    const mdMatches = useMuiMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
    const mdUpMatches = useMuiMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
    const isMobile = !!useMediaQuery("(max-width: 767px)");

    return (
        <>
            <StyledGrid container mdMatches={mdMatches} mdUpMatches={mdUpMatches} completed={completed} additionalcss={additionalcss}>
                {image && !isMobile && (
                    <Grid item xs={1} className="gridImage">
                        <img src={image} alt="" />
                    </Grid>
                )}
                <Grid item xs className="gridItem bottom7">
                    <h2 className="question">{question}</h2>
                </Grid>
                <Grid item className="gridItem">
                    <div className="tooltip-div">
                        <div>{children}</div>
                    </div>
                </Grid>
            </StyledGrid>
        </>
    );
};

interface IStyledGridProps {
    mdMatches?: boolean;
    mdUpMatches?: boolean;
    completed?: boolean;
    additionalcss?: string;
}

const StyledGrid = styled(Grid)<IStyledGridProps>`
	background-color: ${({ completed }) => (completed ? "#005FAB" : "rgba(255, 255, 255, 0.1)")};
	margin: 20px 0 40px;
	border-radius: 5px;
	align-items: center;

	${({ additionalcss }) => additionalcss}

	@media (max-width: 767px) {
		margin: 10px 0;
		height: auto;
		flex-direction: column;
		align-items: flex-start;
		border-radius: 0;
		padding: 20px;
	}

	.tooltip-div {
		display: flex;

		> div {
			display: flex;

			:first-of-type {
				flex-grow: 1;
			}

			:nth-of-type(2) {
				flex-grow: 1;
				justify-content: flex-end;

				img.info-tooltip {
					padding: 5px;
				}
			}
		}

		label + .MuiInput-formControl {
			margin-top: 0;
		}
	}

	h2 {
		/* font-size: 18px; */
		font-weight: bold;
		margin: 0;
		display: flex;
		height: 100%;
		align-items: center;
		font-size: 24px;
	}

	/* ${props => (props.mdMatches ? "padding: 20px; position: relative; height: 90px;" : "")} */

	.gridItem {
		${props => (props.mdUpMatches ? "padding: 14px 20px;" : "")}
	}

	.gridImage {
		display: flex;
		justify-content: center;
		margin-right: -20px;
		padding: 19px 70px;
		height: 90px;
	}

	.question {
		/* ${props => (props.mdMatches ? "font-size: 10px;" : "")} */
		@media (max-width: 767px) {
			font-size: 10px;
		}
	}

	.bottom7 {
		${props => (props.mdMatches ? "margin-bottom: 7px;" : "")}
	}

	.info-tooltip {
		${props => (props.mdMatches ? "position: absolute; top: 5px; right: 5px;" : "")}
	}
`;
