import * as MobX from "mobx";
import { ModelBase } from "../../../Core/Models/ModelBase";

export class DeedsModel extends ModelBase<DeedsModel, DeedsModelDTO> {
    @MobX.observable
    public document_type: string = "";

    @MobX.observable
    public recording_date: string = "";

    @MobX.observable
    public original_contract_date: string = "";

    @MobX.observable
    public deed_book: string = "";

    @MobX.observable
    public deed_page: string = "";

    @MobX.observable
    public document_id: string = "";

    @MobX.observable
    public sale_price: number = 0;

    @MobX.observable
    public sale_price_description: string = "";

    @MobX.observable
    public transfer_tax: number = 0;

    @MobX.observable
    public distressed_sale: boolean = false;

    @MobX.observable
    public real_estate_owned: string = "";

    @MobX.observable
    public seller_first_name: string = "";

    @MobX.observable
    public seller_last_name: string = "";

    @MobX.observable
    public seller2_first_name: string = "";

    @MobX.observable
    public seller2_last_name: string = "";

    @MobX.observable
    public seller_address: string = "";

    @MobX.observable
    public seller_unit_number: string = "";

    @MobX.observable
    public seller_city: string = "";

    @MobX.observable
    public seller_state: string = "";

    @MobX.observable
    public seller_zip_code: string = "";

    @MobX.observable
    public seller_zip_plus_four_code: string = "";

    @MobX.observable
    public buyer_first_name: string = "";

    @MobX.observable
    public buyer_last_name: string = "";

    @MobX.observable
    public buyer2_first_name: string = "";

    @MobX.observable
    public buyer_address: string = "";

    @MobX.observable
    public buyer_unit_type: string = "";

    @MobX.observable
    public buyer_unit_number: string = "";

    @MobX.observable
    public buyer_city: string = "";

    @MobX.observable
    public buyer_state: string = "";

    @MobX.observable
    public buyer_zip_code: string = "";

    @MobX.observable
    public buyer_zip_plus_four_code: string = "";

    @MobX.observable
    public lender_name: string = "";

    @MobX.observable
    public lender_type: string = "";

    @MobX.observable
    public loan_amount: number = 0;

    @MobX.observable
    public loan_type: string = "";

    @MobX.observable
    public loan_due_date: string = "";

    @MobX.observable
    public loan_finance_type: string = "";

    @MobX.observable
    public loan_interest_rate: string = "";

    public fromDto = (model: DeedsModelDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                this[key] = model[key];
            }
        }
    };
    public toDto = (model: DeedsModel) => {};

    public resetDeeds = () => {
        this.document_type = "";
        this.recording_date = "";
        this.original_contract_date = "";
        this.deed_book = "";
        this.deed_page = "";
        this.document_id = "";
        this.sale_price = 0;
        this.sale_price_description = "";
        this.transfer_tax = 0;
        this.distressed_sale = false;
        this.real_estate_owned = "";
        this.seller_first_name = "";
        this.seller_last_name = "";
        this.seller2_first_name = "";
        this.seller2_last_name = "";
        this.seller_address = "";
        this.seller_unit_number = "";
        this.seller_city = "";
        this.seller_state = "";
        this.seller_zip_code = "";
        this.seller_zip_plus_four_code = "";
        this.buyer_first_name = "";
        this.buyer_last_name = "";
        this.buyer_last_name = "";
        this.buyer_address = "";
        this.buyer_unit_type = "";
        this.buyer_unit_number = "";
        this.buyer_city = "";
        this.buyer_state = "";
        this.buyer_zip_code = "";
        this.buyer_zip_plus_four_code = "";
        this.lender_name = "";
        this.lender_type = "";
        this.loan_amount = 0;
        this.loan_type = "";
        this.loan_due_date = "";
        this.loan_finance_type = "";
        this.loan_interest_rate = "";
    };
}

export interface DeedsModelDTO {
    document_type: string;
    recording_date: string;
    original_contract_date: string;
    deed_book: string;
    deed_page: string;
    document_id: string;
    sale_price: number;
    sale_price_description: string;
    transfer_tax: number;
    distressed_sale: boolean;
    real_estate_owned: string;
    seller_first_name: string;
    seller_last_name: string;
    seller2_first_name: string;
    seller2_last_name: string;
    seller_address: string;
    seller_unit_number: string;
    seller_city: string;
    seller_state: string;
    seller_zip_code: string;
    seller_zip_plus_four_code: string;
    buyer_first_name: string;
    buyer_last_name: string;
    buyer2_first_name: string;
    buyer_address: string;
    buyer_unit_type: string;
    buyer_unit_number: string;
    buyer_city: string;
    buyer_state: string;
    buyer_zip_code: string;
    buyer_zip_plus_four_code: string;
    lender_name: string;
    lender_type: string;
    loan_amount: number;
    loan_type: string;
    loan_due_date: string;
    loan_finance_type: string;
    loan_interest_rate: string;
}
