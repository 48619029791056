// Libraries
import clsx from "clsx";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Box, Grid, Paper, Typography, Slider, Select, MenuItem, Button } from "@material-ui/core";

// Components
import { Footer } from "../Components/Footer";
import { ApiDiagnoseQuote } from "../AdminComponents/AdminDashboard/ApiDiagnoseQuote";
import { CoverageEdit, EditBar, LocationEdit, YouYourEdit } from "Custom/Components/EditBar";
import { StoresInstance } from "../Stores";
import { WNFIPQuoteCard, NFIPQuoteCard, PCPQuoteCard, NFIPEmptyCard } from "../Components/Results/";
import { ResultQuoteModel } from "../Models/ResultQuoteModel";
import { useRouter } from "../../Core/Utils";
import { ResultNFIAgreement } from "Custom/Components/Results/ResultNFIAgreement";
import { QuoteResponseModel } from "Custom/Models";

// Styling & images
import { DivWrapper, DivWrapperBGFixed } from "../StylesAppSpecific/GridHelperStyling";
import { ApiDetailModal } from "../StylesAppSpecific/ModalStyling";
import { useAdminStyles } from "../StylesAppSpecific/Admin/AdminDashboard";
import PXFuel from "Content/pxfuel.png";
import API from "Content/API.svg";
import DownloadQuotes from "Content/downloadQuotes.svg";
import { QuoteLoading } from "../Components/IsLoading";
import { useFilterBarStyles, SelectStyles, SliderStyles, IconButton } from "../StylesAppSpecific/Quote/FilterBar";
import { useResultsStyles } from "../StylesAppSpecific/Quote/Results";
import { ReRunButton } from "Custom/AdminComponents/ReRunButton";

export const Results: React.FC = () => {
    const classes = useResultsStyles();
    const { history } = useRouter();
    const filterBarClasses = useFilterBarStyles();
    const domainStores = StoresInstance.domain;
    const quoteResponseVM = domainStores.QuoteResultViewModel;
    const [showNFIAgreement, setShowNFIAgreement] = useState(false);
    const [navLink, setNavLink] = useState("");
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [apiDetailVisible, setApiDetailVisible] = React.useState<boolean>(false);
    const adminClasses = useAdminStyles();
    const doOpenModal = () => {
        setOpenModal(true);
    };

    const doCloseModal = () => {
        setOpenModal(false);
    };

    useEffect(() => {
        // If the viewmodel is to allow navigation. set the viewmodel router.
        quoteResponseVM.setRouter(history, location);

        // With SPAs, we need to move back to the top of the page.
        window.scrollTo(0, 0);

        // Get the quotes.
        //quoteResponseVM.getQuotes();
        quoteResponseVM.getQuotesNoSR();

        // Load agency details if pro
        if (StoresInstance.domain.AccountStore.IsProAccount) {
            quoteResponseVM.getAgencyDetails();
        }

        if (StoresInstance.domain.AccountStore.isInRole("admin")) {
            if (StoresInstance.domain.AccountStore.Email == "admin@shoothill.com") {
                setApiDetailVisible(true);
            }
        }
        if (StoresInstance.domain.AccountStore.Email == "jennifer@nationalfloodinsurance.org") {
            setApiDetailVisible(true);
        }
    }, []);

    const editBar = (
        <EditBar>
            <LocationEdit editlink="/Step1" />
            <YouYourEdit editlink="/Step2" />
            <CoverageEdit editlink="/Step3" />
        </EditBar>
    );

    const renderLoadingOverlay = (): JSX.Element => {
        return quoteResponseVM.isLoadingQuotes ? <QuoteLoading /> : <React.Fragment />;
    };

    const onDeductibleFilterChange = (event: React.ChangeEvent<{}>, newValue: any) => {
        quoteResponseVM.setDeductibleFilter(newValue);
    };

    const onSortOptionChange = (event: any) => {
        quoteResponseVM.setSortOption(event.target.value);
    };

    const handleChatClick = () => {
        console.log("clciked");
    };

    const renderFilterBar = (): JSX.Element => (
        <Paper className={filterBarClasses.paper}>
            <Grid className={filterBarClasses.container} container>
                <Grid xs={12} sm={4} md={4} item>
                    <Box className={filterBarClasses.sortBox}>
                        <Typography className={clsx(filterBarClasses.title, filterBarClasses.sortTitle)}>Sort by:</Typography>
                        <SelectStyles className={filterBarClasses.select}>
                            <Select variant="outlined" value={quoteResponseVM.sortOptionSelection} onChange={onSortOptionChange}>
                                {quoteResponseVM.sortOptions.map((option: any, index: number) => (
                                    <MenuItem value={option.value}>{option.key}</MenuItem>
                                ))}
                            </Select>
                        </SelectStyles>
                    </Box>
                </Grid>
                <Grid xs={12} sm={4} md={4} item style={{ textAlign: "left" }}>
                    <Typography style={{ paddingLeft: "20px" }} className={clsx(filterBarClasses.title, filterBarClasses.sortTitle)}>
                        Flood Zone {quoteResponseVM.showFloodZone}
                    </Typography>
                </Grid>
                <Grid xs={12} sm={4} md={4} item style={{ textAlign: "right" }}>
                    {!quoteResponseVM.noQuotes && !domainStores.wrapper ? (
                        <IconButton
                            variant="contained"
                            onClick={() => {
                                quoteResponseVM.getQuoteSummaryPDF();
                            }}
                        >
                            <img src={DownloadQuotes} />
                        </IconButton>
                    ) : (
                        ""
                    )}
                    {apiDetailVisible && (
                        <React.Fragment>
                            <IconButton
                                className={clsx(adminClasses.navigationButton, adminClasses.navigationGroupOverButton)}
                                onClick={() => {
                                    doOpenModal();
                                }}
                                variant="contained"
                            >
                                <img src={API} />
                            </IconButton>
                        </React.Fragment>
                    )}
                </Grid>
            </Grid>
        </Paper>
    );

    return useObserver(() => (
        <>
            <DivWrapperBGFixed additionalcss={"background-image: url(" + PXFuel + ");"} className={classes.background}>
                {showNFIAgreement ? (
                    <ResultNFIAgreement goBack={() => setShowNFIAgreement(false)} path={navLink} resultViewModel={quoteResponseVM} />
                ) : (
                    <>
                        {editBar}
                        <div className={classes.backgroundChild}>
                            <DivWrapper margin={"0px auto"} additionalcss={"max-width: 1124px;"}>
                                {/* Loading data overlay */}
                                {renderLoadingOverlay()}

                                {/* Filter bar */}
                                {renderFilterBar()}

                                {/* NFIP quote */}
                                {quoteResponseVM.showNFIQuotes && (
                                    <Box className={classes.resultList}>
                                        <Box display="flex" alignItems="flex-end">
                                            <Typography className={classes.resultTitle} component="h1" variant="h2">
                                                National Flood Insurance Program (NFIP) quote:
                                            </Typography>
                                            {StoresInstance.domain.AccountStore.isInRole("producer") && quoteResponseVM.blobId && (
                                                <ReRunButton
                                                    margin="auto 0 14px auto"
                                                    spinRerun={quoteResponseVM.rerunViewModel?.rerunning}
                                                    onClick={() => quoteResponseVM.rerunViewModel.rerun(quoteResponseVM.blobId!)}
                                                    disabled={quoteResponseVM.rerunViewModel.reran || quoteResponseVM.rerunViewModel.rerunning}
                                                />
                                            )}
                                        </Box>
                                        {quoteResponseVM.canDisplayWNFIPQuotes &&
                                            quoteResponseVM.wnfipQuotes.map((aQuote: ResultQuoteModel) => (
                                                <WNFIPQuoteCard
                                                    key={`wnfiresult-${aQuote.id}`}
                                                    quoteDetail={aQuote}
                                                    position={1}
                                                    chatClick={handleChatClick}
                                                    callback={() => {
                                                        quoteResponseVM.selectQuote(aQuote, (path: string) => {
                                                            setNavLink(path);
                                                            setShowNFIAgreement(true);
                                                        });
                                                    }}
                                                    pdfCallBack={() => {
                                                        quoteResponseVM.selectQuoteForPDF(aQuote);
                                                    }}
                                                />
                                            ))}
                                        {quoteResponseVM.canDisplayNFIPQuotes &&
                                            !quoteResponseVM.canDisplayWNFIPQuotes &&
                                            quoteResponseVM.nfipQuotes.map((aQuote: ResultQuoteModel) => (
                                                <>
                                                    {aQuote.propertyType == "sfr" ? (
                                                        <WNFIPQuoteCard
                                                            key={`wnfiresult-${aQuote.id}`}
                                                            quoteDetail={aQuote}
                                                            position={1}
                                                            chatClick={handleChatClick}
                                                            callback={() => {
                                                                quoteResponseVM.selectQuote(aQuote, (path: string) => {
                                                                    setNavLink(path);
                                                                    setShowNFIAgreement(true);
                                                                });
                                                            }}
                                                            pdfCallBack={() => {
                                                                quoteResponseVM.selectQuoteForPDF(aQuote);
                                                            }}
                                                        />
                                                    ) : (
                                                        <NFIPQuoteCard
                                                            key={`nfiresult-${aQuote.id}`}
                                                            quoteDetail={aQuote}
                                                            position={1}
                                                            chatClick={handleChatClick}
                                                            callback={() => {
                                                                quoteResponseVM.selectQuote(aQuote, (path: string) => {
                                                                    setNavLink(path);
                                                                    setShowNFIAgreement(true);
                                                                });
                                                            }}
                                                            pdfCallBack={() => {
                                                                quoteResponseVM.selectQuoteForPDF(aQuote);
                                                            }}
                                                        />
                                                    )}
                                                </>
                                            ))}
                                    </Box>
                                )}
                                {!quoteResponseVM.showNFIQuotes && quoteResponseVM.completedResults && (
                                    <>
                                        <Box className={classes.resultList}>
                                            <Box display="flex" alignItems="flex-end">
                                                <Typography className={classes.resultTitle} component="h1" variant="h2">
                                                    National Flood Insurance Program (NFIP) quote:
                                                </Typography>
                                                {StoresInstance.domain.AccountStore.isInRole("producer") && quoteResponseVM.blobId && (
                                                    <ReRunButton
                                                        margin="auto 0 14px auto"
                                                        spinRerun={quoteResponseVM.rerunViewModel?.rerunning}
                                                        onClick={() => quoteResponseVM.rerunViewModel.rerun(quoteResponseVM.blobId!)}
                                                        disabled={quoteResponseVM.rerunViewModel.reran || quoteResponseVM.rerunViewModel.rerunning}
                                                    />
                                                )}
                                            </Box>
                                            <NFIPEmptyCard
                                                key={`nfiresult-noQuote`}
                                                position={1}
                                                callback={() => {
                                                    quoteResponseVM.selectEmptyNFIP((path: string) => {
                                                        setNavLink(path);
                                                        setShowNFIAgreement(true);
                                                    });
                                                }}
                                            />
                                        </Box>
                                    </>
                                )}
                                {/* Private quotes */}
                                {quoteResponseVM.canDisplayPrivateQuotes && (
                                    <Box className={classes.resultList}>
                                        <Typography className={classes.resultTitle} component="h1" variant="h2">
                                            Private carrier policy quotes:
                                        </Typography>
                                        {quoteResponseVM.privateQuotes.map((aQuote: ResultQuoteModel) => (
                                            <PCPQuoteCard
                                                key={`pcpresult-${aQuote.id}`}
                                                quoteDetail={aQuote}
                                                position={1}
                                                callback={() => {
                                                    quoteResponseVM.selectQuote(aQuote, (path: string) => {
                                                        setNavLink(path);
                                                        setShowNFIAgreement(true);
                                                    });
                                                }}
                                                pdfCallBack={() => {
                                                    quoteResponseVM.selectQuoteForPDF(aQuote);
                                                }}
                                                adminQuote={quoteResponseVM.rerunViewModel?.adminQuoteIds.includes(aQuote.id)}
                                            />
                                        ))}
                                    </Box>
                                )}
                                {quoteResponseVM.noQuotes && (
                                    <>
                                        <Box className={classes.resultList}>
                                            <Box display="flex" alignItems="flex-end">
                                                <Typography className={classes.resultTitle} component="h1" variant="h2">
                                                    No quotes could be found for this address at this time...
                                                    <br />
                                                    Please contact one of our flood insurance experts on (888) 900-4040:
                                                </Typography>
                                                {StoresInstance.domain.AccountStore.isInRole("producer") && quoteResponseVM.blobId && (
                                                    <ReRunButton
                                                        margin="auto 0 14px auto"
                                                        spinRerun={quoteResponseVM.rerunViewModel?.rerunning}
                                                        onClick={() => quoteResponseVM.rerunViewModel.rerun(quoteResponseVM.blobId!)}
                                                        disabled={quoteResponseVM.rerunViewModel.reran || quoteResponseVM.rerunViewModel.rerunning}
                                                    />
                                                )}
                                            </Box>
                                            <NFIPEmptyCard
                                                key={`nfiresult-noQuote`}
                                                position={1}
                                                callback={() => {
                                                    quoteResponseVM.selectEmptyNFIP((path: string) => {
                                                        setNavLink(path);
                                                        setShowNFIAgreement(true);
                                                    });
                                                }}
                                            />
                                        </Box>
                                    </>
                                )}
                            </DivWrapper>
                        </div>
                    </>
                )}
            </DivWrapperBGFixed>
            <Footer isTemp={false} />
            {apiDetailVisible && (
                <React.Fragment>
                    <ApiDetailModal open={openModal} onClose={doCloseModal}>
                        <ApiDiagnoseQuote />
                    </ApiDetailModal>
                </React.Fragment>
            )}
        </>
    ));
};
