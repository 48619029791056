// Libraries
import React, { useCallback, useEffect, useRef } from "react";
import { Typography, Paper, Button } from "@material-ui/core";
import clsx from "clsx";

// Custom
import { useAddressHeaderStyles } from "../../StylesAppSpecific/Admin/AdminDashboard";
import { Box } from "@material-ui/core";

import { ReRunButton } from "../ReRunButton";

interface IProps {
    address: string;
    rerun?: () => void;
    spinRerun?: boolean;
    disabled?: boolean;
}

export const AddressHeader: React.FC<IProps> = (props: IProps) => {
    const classes = useAddressHeaderStyles();

    return (
        <Paper className={classes.paper}>
            <Box display="flex" flexGrow={1}>
                <Typography className={classes.title}>
                    <span className={classes.titlePrefix}>Quotes for </span>
                    {props.address}
                </Typography>
            </Box>
            {props.rerun !== undefined && <ReRunButton onClick={props.rerun} disabled={props.disabled} spinRerun={props.spinRerun} />}
        </Paper>
    );
};
