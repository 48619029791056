// Libraries
import React, { useEffect, useRef } from "react";
import { useObserver } from "mobx-react-lite";

// Components
import { StoresInstance } from "Custom/Globals/Base";
import { useMediaQuery } from "Core/Utils/Media/mediaQuery";
import { PolicyHolder } from "Custom/Components/PropertyCoverage/PolicyHolder";
import { Registration } from "Custom/Components/PropertyCoverage/Registration";

// Styling & images
import { Grid } from "@material-ui/core";
import { GridSpaced, H3WithProp, TitleWrapper2 } from "Custom/StylesAppSpecific/GridHelperStyling";

/**
 * Register Owner account: used on all property types.
 */
export const OwnerRegister: React.FC = () => {
    const domainStores = StoresInstance.domain;
    const quoteRequestVM = domainStores.QuoteRequestViewModel;
    const contactRef = useRef<HTMLAnchorElement>(null);
    const isMobile = !!useMediaQuery("(max-width: 767px)");

    useEffect(() => {
        if (quoteRequestVM.scrollToBottom && contactRef.current) {
            contactRef.current.scrollIntoView();
            quoteRequestVM.scrollToBottom = false;
        }
    }, [contactRef.current]);

    const renderPolicyHolderDetails = () => {
        return (
            <GridSpaced container margin={"0px auto"} additionalcss={"width: 100%; > div {padding: 20px 20px 9px;}"}>
                <Grid item xs={12} sm={12} justify={"center"}>
                    <TitleWrapper2 padding={"0"} margin={"0"}>
                        <h2 className="questions-2 no-top-margin">
                            <span>Policy Holder</span>
                        </h2>
                    </TitleWrapper2>
                    <H3WithProp padding={"0"} margin={"0px auto 0px"}>
                        {quoteRequestVM.getValue("requestPolicyHolderFirstName") + " " + quoteRequestVM.getValue("requestPolicyHolderLastName")}
                    </H3WithProp>
                    <H3WithProp padding={"0"} margin={"0px auto 0px"}>
                        {quoteRequestVM.getValue("requestPolicyHolderEmail")}
                    </H3WithProp>
                    <H3WithProp padding={"0"} margin={"0px auto 0px"}>
                        {quoteRequestVM.getValue("requestPolicyHolderPhone")}
                    </H3WithProp>
                </Grid>
            </GridSpaced>
        );
    };

    const renderPolicyHolderDetailsFieldsTitle = () => {
        return (
            <React.Fragment>
                <a ref={contactRef} />
                <GridSpaced container margin={"0px auto"} additionalcss={"width: 100%;"}>
                    <Grid item xs={12} sm={12} justify={"center"}>
                        <TitleWrapper2 padding={"0"} margin={"0"}>
                            <h2 className="questions-2 no-top-margin">
                                <span>And finally...Your account</span>
                            </h2>
                            <h3>
                                To get your quotes and so you can retrieve them in the future, we need your details to set up your account. Please enter them
                                below and we'll get to work...
                            </h3>
                        </TitleWrapper2>
                    </Grid>
                </GridSpaced>
            </React.Fragment>
        );
    };

    const renderFields = () => {
        const isLoggedIn = StoresInstance.domain.AccountStore.IsLoggedIn;
        const isAgent = StoresInstance.domain.AccountStore.IsProAccount;

        return (
            <React.Fragment>
                {isLoggedIn && isAgent && <PolicyHolder viewModel={quoteRequestVM} />}
                {!isLoggedIn && <Registration viewModel={quoteRequestVM} />}
            </React.Fragment>
        );
    };

    return useObserver(() => (
        <>
            {domainStores.AccountStore.IsLoggedIn && !domainStores.AccountStore.IsProAccount ? (
                <>{renderPolicyHolderDetails()}</>
            ) : (
                <>
                    {renderPolicyHolderDetailsFieldsTitle()}
                    {renderFields()}
                </>
            )}
        </>
    ));
};
