import * as MobX from "mobx";

import { Contains, Equals, IsDate, IsEmail, IsFQDN, IsInt, IsNotEmpty, Length, Max, Min, ValidateIf, validate, validateOrReject } from "class-validator";

import { ModelBase } from "../../Core/Models/ModelBase";

export class DateEffectiveModel extends ModelBase<DateEffectiveModel, DateEffectiveModelDTO> {
    @MobX.observable
    public day!: number;

    @MobX.observable
    public month!: number;

    @MobX.observable
    public year!: number;

    constructor() {
        super();

        this.resetAll();
    }

    public fromDto = (model: DateEffectiveModelDTO) => {};
    public toDto = (model: DateEffectiveModel) => {};

    @MobX.action
    public resetAll = () => {
        const date = new Date();
        this.day = date.getDate();
        this.month = date.getMonth();
        this.year = date.getFullYear();
    };
}

export interface DateEffectiveModelDTO {}
