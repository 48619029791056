import React from "react";
import { WarningBannerStyles } from "./WarningBanner.styling";
import { StoresInstance } from "../../Stores/Stores";
import { useObserver } from "mobx-react-lite";
import { Typography } from "@material-ui/core";

interface IProps {
    top: number;
    children: (warningHeight: number) => React.ReactChild;
}

export const WarningBanner: React.FC<IProps> = ({ top, children }) => {
    const [height, setHeight] = React.useState<number>(0);

    return useObserver(() => (
        <>
            {StoresInstance.domain.warningBanner && (
                <WarningBannerStyles style={{ top: `${top}px` }} ref={ref => ref && setHeight(ref.clientHeight)}>
                    <Typography variant="body1">{StoresInstance.domain.warningBanner}</Typography>
                </WarningBannerStyles>
            )}
            {children(height)}
        </>
    ));
};
