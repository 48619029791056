import * as MobX from "mobx";
import { ModelBase } from "../../../Core/Models/ModelBase";

export class OwnerModel extends ModelBase<OwnerModel, OwnerModelDTO> {
    @MobX.observable
    public name: string = "";

    @MobX.observable
    public second_name: string = "";

    @MobX.observable
    public formatted_street_address: string = "";

    @MobX.observable
    public unit_type: string = "";

    @MobX.observable
    public unit_number: string = "";

    @MobX.observable
    public city: string = "";

    @MobX.observable
    public state: string = "";

    @MobX.observable
    public zip_code: string = "";

    @MobX.observable
    public zip_plus_four_code: string = "";

    @MobX.observable
    public owner_occupied: string = "";

    public fromDto = (model: OwnerModelDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                this[key] = model[key];
            }
        }
    };
    public toDto = (model: OwnerModel) => {};

    public resetOwner = () => {
        this.name = "";
        this.second_name = "";
        this.formatted_street_address = "";
        this.unit_type = "";
        this.unit_number = "";
        this.city = "";
        this.state = "";
        this.zip_code = "";
        this.zip_plus_four_code = "";
        this.owner_occupied = "";
    };
}

export interface OwnerModelDTO {
    name: string;
    second_name: string;
    formatted_street_address: string;
    unit_type: string;
    unit_number: string;
    city: string;
    state: string;
    zip_code: string;
    zip_plus_four_code: string;
    owner_occupied: string;
}
