// Libraries
import clsx from "clsx";
import { useObserver } from "mobx-react-lite";
import React, { useState, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import {
    Box,
    Button,
    Modal,
    Paper,
    Typography,
    Divider,
    Radio,
    RadioGroup,
    FormControlLabel,
    InputAdornment,
    IconButton,
    Input,
    Checkbox,
} from "@material-ui/core";

// Content
import radioNo from "Content/radioYes.svg";
import radioYes from "Content/radioYes-checked.svg";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

// Custom
import { modalStyle, carrierModalStyle } from "../../StylesAppSpecific/Agent/AgentDashboard";
import AgentInsurerStatisticsViewModel from "../../ViewModels/Agent/AgentInsurerStatisticsViewModel";
import { StoresInstance } from "../../Globals/Base";
import { NFIPartnerAgreementTerms } from "../../Components/Pro/NFIPartnerAgreementTerms";
import { ViewPDFAnchor } from "Custom/StylesAppSpecific/Agent/AgentStyling";
import { getInsurerName } from "Custom/Utils/InsurerName";

interface IProps {
    viewModel: AgentInsurerStatisticsViewModel;
}

export const CarrierSettingsModal: React.FC<IProps> = (props: IProps) => {
    /**
     * Modal position in viewport.
     */
    const getModalPositionStyle = () => {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    };

    /**
     * Styling override for radio button controls.
     *
     * @param props
     *
     * @return Radio button.
     */
    const StyledRadioButton = (props: any) => {
        return <Radio checkedIcon={<img src={radioYes} alt="Y" />} icon={<img src={radioNo} alt="N" />} {...props} />;
    };

    /**
     * Styling override for checkbox controls.
     *
     * @param props
     *
     * @return Checkbox.
     */
    const StyledCheckBoxButton = (props: any) => {
        return <Checkbox checkedIcon={<img src={radioYes} alt="Y" />} icon={<img src={radioNo} alt="N" />} {...props} />;
    };

    const [modalPositionStyle] = React.useState(getModalPositionStyle);
    const signatureRef = useRef<SignatureCanvas>() as React.RefObject<SignatureCanvas>;
    const [showPassword, setShowPassword] = useState(true);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event: any) => event.preventDefault();

    const classes = modalStyle();
    const customClasses = carrierModalStyle();

    React.useEffect(() => {
        StoresInstance.domain.AgencyDetailViewModel.load();
    }, []);

    // #region Actions

    const onModalOK = () => {
        let agreementSignature: Blob | undefined;
        if (props.viewModel.canDisplayAgreement && signatureRef.current!.toData().length > 0) {
            const dataUrl = signatureRef.current!.toDataURL();
            const charBytes = atob(dataUrl.split(",")[1]);
            const imageBytes = [];
            for (let i = 0; i < charBytes.length; i++) {
                imageBytes[i] = charBytes.charCodeAt(i);
            }
            const byteArray = new Uint8Array(imageBytes);
            agreementSignature = new Blob([byteArray], { type: "image/png" });
        }
        props.viewModel.updateSettings(agreementSignature);
    };

    const onModalCancel = () => {
        props.viewModel.cancelSettings();
    };

    const clearSignature = (): void => {
        signatureRef.current?.clear();
        props.viewModel.setConfirmSignature(false);
    };

    const onSignaturePadEnd = () => {
        props.viewModel.setConfirmSignature(true);
    };

    // #endregion Actions

    // #region Header

    const renderHeader = () => {
        return (
            <Box className={classes.header}>
                <Typography className={classes.headerTitle}>
                    {props.viewModel.canDisplayAgreementOverride
                        ? "Sign Up as a National Flood Insurance Partner Agent"
                        : `Carrier settings - ${getInsurerName(props.viewModel.getValue("insurerName"))}`}
                </Typography>
            </Box>
        );
    };

    // #endregion Header

    // #region Body

    const renderCarrierForm = () => {
        return (
            <React.Fragment>
                <Divider className={customClasses.solidDivider} />
                {props.viewModel.canDisplayLoginIdAndPasswordSettings && (
                    <Box className={customClasses.inputBoxItem}>
                        <label className={customClasses.label}>
                            Your agency id
                            <Input
                                autoComplete="new-password"
                                className={customClasses.input}
                                disableUnderline={true}
                                onChange={(event: any) => props.viewModel.setAgencyId(event.target.value)}
                                value={props.viewModel.agencyId}
                            />
                        </label>
                    </Box>
                )}
                {props.viewModel.canDisplayLoginIdAndPasswordSettings && (
                    <Box className={customClasses.inputBoxItem}>
                        <label className={customClasses.label}>
                            Your login password
                            <Input
                                autoComplete="new-password"
                                className={customClasses.input}
                                disableUnderline={true}
                                onChange={(event: any) => props.viewModel.setAgencyPassword(event.target.value)}
                                type={showPassword ? "password" : "text"}
                                value={props.viewModel.agencyPassword}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </label>
                    </Box>
                )}
                {props.viewModel.canDisplayApiKeySettings && (
                    <Box className={customClasses.inputBoxItem}>
                        <label className={customClasses.label}>
                            Your API key
                            <Input
                                autoComplete="new-password"
                                className={customClasses.input}
                                disableUnderline={true}
                                onChange={(event: any) => props.viewModel.setAgencyAPIKey(event.target.value)}
                                value={props.viewModel.agencyAPIKey}
                            />
                        </label>
                    </Box>
                )}
            </React.Fragment>
        );
    };

    const renderNFIPAgreement = () => {
        return (
            <React.Fragment>
                {props.viewModel.canDisplayAgreementOverride || <Divider className={customClasses.solidDivider} />}
                <Box className={customClasses.titleBoxItem}>
                    <Typography variant="h3" component="p" className={classes.bodyText}>
                        {props.viewModel.canDisplayAgreementOverride
                            ? "Because you selected to be an NFI Partner Agency"
                            : "Change your carrier agency to National Flood Insurance"}
                    </Typography>
                    <br />
                    {props.viewModel.canDisplayAgreementOverride || (
                        <Typography variant="h5" component="p" className={classes.bodyText}>
                            Please review and digitally sign our agency referral agreement.
                            <br />
                        </Typography>
                    )}
                    <Typography variant="h5" component="p" className={classes.bodyText}>
                        After submitting your completed form you will immediately be able to process quotes as a National Flood Insurance, LLC partner agent.
                        One of our team members will contact you if we need any further information.
                    </Typography>
                </Box>
                <Box>
                    <Typography className={customClasses.agentAgencyText}>
                        <b>Agent name: </b>
                        {StoresInstance.domain.AccountStore.FirstName} {StoresInstance.domain.AccountStore.LastName}
                    </Typography>
                    <Typography className={customClasses.agentAgencyText}>
                        <b>Agency name: </b>
                        {props.viewModel.agencyName}
                    </Typography>
                </Box>
                <Box className={customClasses.titleBoxItem} display="flex">
                    <Typography className={classes.bodyText} display="inline">
                        Agency agreement
                    </Typography>
                    <ViewPDFAnchor target="_blank" href="/nfipartner-agreement-pdf">
                        View PDF here
                    </ViewPDFAnchor>
                </Box>
                <Box className={customClasses.agreementBoxItem}>
                    <Box padding="14px 20px">
                        <NFIPartnerAgreementTerms />
                    </Box>
                </Box>
                <Box>
                    <FormControlLabel
                        className={customClasses.checkbox}
                        control={
                            <StyledCheckBoxButton
                                checked={props.viewModel.confirmAgreement}
                                onChange={() => props.viewModel.setConfirmAgreement(!props.viewModel.confirmAgreement)}
                            />
                        }
                        label="By checking this you agree to our agency agreement"
                    />
                </Box>
                <Box className={customClasses.titleBoxItem}>
                    <Typography className={classes.bodyText}>Please sign below</Typography>
                </Box>
                <Box className={customClasses.signatureBoxItem}>
                    <SignatureCanvas
                        onEnd={onSignaturePadEnd}
                        penColor="black"
                        backgroundColor="white"
                        canvasProps={{ style: { width: "100%", height: "160px", border: "5px #129BDB solid", borderRadius: "15px" } }}
                        ref={signatureRef}
                    />
                    <Typography className={classes.signatureLikeness}>I certify that this is a true likeness of my signature and is legally binding</Typography>
                    <a onClick={() => clearSignature()} style={{ cursor: "pointer", color: "#129bdb", textDecoration: "underline" }}>
                        Clear signature
                    </a>
                </Box>
            </React.Fragment>
        );
    };

    const renderBody = () => {
        return (
            <Box className={classes.body}>
                {props.viewModel.canDisplayAgreementOverride || (
                    <>
                        <Box className={customClasses.titleBoxItem}>
                            <Typography className={classes.bodyText}>To process my quotes...</Typography>
                        </Box>
                        <Box className={customClasses.radioGroupBoxItem}>
                            <RadioGroup value={props.viewModel.carrierOption} onChange={(event: any) => props.viewModel.setCarrierOption(event.target.value)}>
                                {(props.viewModel.canDisplayApiKeySettings || props.viewModel.canDisplayLoginIdAndPasswordSettings) && (
                                    <FormControlLabel
                                        className={customClasses.radio}
                                        value="option-carrieraccount"
                                        control={<StyledRadioButton />}
                                        label="Use my carrier account"
                                    />
                                )}
                                <FormControlLabel
                                    className={customClasses.radio}
                                    value="option-nfipartnergency"
                                    control={<StyledRadioButton />}
                                    label="Quote as NFI Partner Agency"
                                />
                            </RadioGroup>
                        </Box>
                    </>
                )}
                {props.viewModel.canDisplayForm && renderCarrierForm()}
                {props.viewModel.canDisplayAgreement && renderNFIPAgreement()}
            </Box>
        );
    };

    // #endregion Body

    // #region Footer

    const renderFooter = () => {
        return (
            <Box className={classes.footer}>
                <Button
                    className={clsx(classes.button, classes.okButton)}
                    disabled={!props.viewModel.canUpdateSettings}
                    onClick={onModalOK}
                    variant="contained"
                >
                    OK
                </Button>
                <Button className={clsx(classes.button, classes.cancelButton)} onClick={onModalCancel} variant="contained">
                    Cancel
                </Button>
            </Box>
        );
    };

    // #endregion Footer

    return useObserver(() => (
        <Modal open={props.viewModel.canDisplaySettings}>
            <Paper className={clsx(classes.paper, customClasses.paper)} style={modalPositionStyle}>
                {renderHeader()}
                {renderBody()}
                {renderFooter()}
            </Paper>
        </Modal>
    ));
};
