import React from "react";
import { Button, Checkbox, Typography, Box } from "@material-ui/core";
import radioYes from "../../../Content/radioYes.svg";
import radioYesChecked from "../../../Content/radioYes-checked.svg";
import { useRouter, useObserver, StoresInstance } from "../../Globals/Base";
import { RememberMeLabel } from "../../StylesAppSpecific/LoginStyling";
import { NFIPartnerAgreementViewModel } from "../../ViewModels";
import SignatureCanvas from "react-signature-canvas";
import { GridSpaced } from "Custom/StylesAppSpecific/GridHelperStyling";
import { isNullOrUndefined } from "util";
import { NFIPartnerAgreementTerms } from "./NFIPartnerAgreementTerms";
import { ViewPDFAnchor } from "Custom/StylesAppSpecific/Agent/AgentStyling";

interface IProps {
    isLoading: boolean;
    onSubmit: () => void;
    errorMessage: string;
    viewModel: NFIPartnerAgreementViewModel;
    signatureRef: React.RefObject<SignatureCanvas>;
}

export const NFIPartnerAgreement: React.FC<IProps> = ({ isLoading, onSubmit, errorMessage, viewModel, signatureRef }) => {
    const { history } = useRouter();

    const clearSignature = (): void => {
        signatureRef.current!.clear();
    };

    return useObserver(() => (
        <>
            <div className="partner-paragraph">
                <p>
                    Please review and digitally sign our agency referral agreement. After submitting your completed form you will immediately be able to process
                    quotes as a National Flood Insurance, LLC partner agent. One of our team members will contact you if we need any further information.
                </p>
            </div>
            <div className="agency-info">
                <h2>
                    Agent name: {StoresInstance.domain.AccountStore.FirstName} {StoresInstance.domain.AccountStore.LastName}
                </h2>
                <h2>Agency name: {StoresInstance.domain.AccountStore.AgencyName}</h2>
            </div>
            <Box display="flex">
                <h2 style={{ display: "inline" }}>Agency agreement</h2>
                <ViewPDFAnchor target="_blank" href="/nfipartner-agreement-pdf">
                    View PDF here
                </ViewPDFAnchor>
            </Box>
            <div className="terms-container">
                <NFIPartnerAgreementTerms />
            </div>
            <span>
                <RememberMeLabel
                    control={
                        <Checkbox
                            name="hasAgreed"
                            icon={<img src={radioYes} alt="Y" />}
                            checkedIcon={<img src={radioYesChecked} alt="N" />}
                            checked={viewModel.getValue("hasAgreed")}
                            onChange={e => {
                                viewModel.setValue("hasAgreed", e.target.checked);
                                viewModel.isFieldValid("hasAgreed", e.target.checked);
                            }}
                        />
                    }
                    label={<h2>By checking this you agree to our agency agreement</h2>}
                />
                {viewModel.getError("hasAgreed") && <p style={{ color: "red", marginTop: "10px" }}>{viewModel.getError("hasAgreed")}</p>}
            </span>
            <h2>Please sign below using your cursor:</h2>
            <SignatureCanvas
                penColor="black"
                backgroundColor="white"
                canvasProps={{ style: { width: "100%", height: "160px", border: "5px #129BDB solid", borderRadius: "15px" } }}
                ref={signatureRef}
            />
            <GridSpaced container margin={"0"} padding={"0"}>
                <GridSpaced item sm={10}>
                    <p>I certify that this is a true likeness of my signature and is legally binding</p>
                </GridSpaced>
                <GridSpaced item sm={2} margin="5px 0px 0px" additionalcss={"text-align: right;"}>
                    <a onClick={() => clearSignature()} style={{ cursor: "pointer", color: "#129bdb", textDecoration: "underline" }}>
                        Clear signature
                    </a>
                </GridSpaced>
            </GridSpaced>
            {errorMessage && <p style={{ color: "red", marginLeft: "40px", marginTop: "20px" }}>{errorMessage}</p>}
            <div className="btns">
                <Button className="save-btn" variant="contained" onClick={onSubmit} disabled={isLoading}>
                    {isLoading ? "Loading..." : "Finish"}
                </Button>
                <Button className="cancel-btn" variant="contained" onClick={() => history.goBack()}>
                    Cancel
                </Button>
            </div>
        </>
    ));
};
