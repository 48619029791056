import * as MobX from "mobx";
import { ModelBase } from "../../../Core/Models/ModelBase";
import { TypeObjectModel } from "./TypeObject";

export class StructureModel extends ModelBase<StructureModel, StructureModelDTO> {
    @MobX.observable
    public year_built: number = 0;

    @MobX.observable
    public effective_year_built: number = 0;

    @MobX.observable
    public stories: string = "";

    @MobX.observable
    public beds_count: number = 0;

    @MobX.observable
    public baths: number = 0;

    @MobX.observable
    public partial_baths_count: number = 0;

    @MobX.observable
    public units_count: number = 0;

    @MobX.observable
    public parking_type: string = "";

    @MobX.observable
    public parking_spaces_count: number = 0;

    @MobX.observable
    public pool_type: string = "";

    @MobX.observable
    public architecture_type: string = "";

    @MobX.observable
    public construction_type: string = "";

    @MobX.observable
    public exterior_wall_type: string = "";

    @MobX.observable
    public foundation_type: string = "";

    @MobX.observable
    public roof_material_type: string = "";

    @MobX.observable
    public roof_style_type: string = "";

    @MobX.observable
    public heating_type: string = "";

    @MobX.observable
    public heating_fuel_type: string = "";

    @MobX.observable
    public air_conditioning_type: string = "";

    @MobX.observable
    public fireplaces: string = "";

    @MobX.observable
    public basement_type: string = "";

    @MobX.observable
    public quality: string = "";

    @MobX.observable
    public condition: string = "";

    @MobX.observable
    public flooring_types: string[] = [];

    @MobX.observable
    public plumbing_fixtures_count: number = 0;

    @MobX.observable
    public interior_wall_type: string = "";

    @MobX.observable
    public water_type: string = "";

    @MobX.observable
    public sewer_type: string = "";

    @MobX.observable
    public total_area_sq_ft: number = 0;

    @MobX.observable
    public other_areas: TypeObjectModel[] = [];

    @MobX.observable
    public other_features: TypeObjectModel[] = [];

    @MobX.observable
    public other_improvements: TypeObjectModel[] = [];

    @MobX.observable
    public other_rooms: string[] = [];

    @MobX.observable
    public amenities: string[] = [];

    public fromDto = (model: StructureModelDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                this[key] = model[key];
            }
        }
    };
    public toDto = (model: StructureModel) => {};

    public resetStructure = () => {
        this.year_built = 0;
        this.effective_year_built = 0;
        this.stories = "";
        this.beds_count = 0;
        this.baths = 0;
        this.partial_baths_count = 0;
        this.units_count = 0;
        this.parking_type = "";
        this.parking_spaces_count = 0;
        this.pool_type = "";
        this.architecture_type = "";
        this.construction_type = "";
        this.exterior_wall_type = "";
        this.foundation_type = "";
        this.roof_material_type = "";
        this.roof_style_type = "";
        this.heating_type = "";
        this.heating_fuel_type = "";
        this.air_conditioning_type = "";
        this.fireplaces = "";
        this.basement_type = "";
        this.quality = "";
        this.condition = "";
        this.flooring_types = [];
        this.plumbing_fixtures_count = 0;
        this.interior_wall_type = "";
        this.water_type = "";
        this.sewer_type = "";
        this.total_area_sq_ft = 0;
        this.other_areas = [];
        this.other_features = [];
        this.other_improvements = [];
        this.other_rooms = [];
        this.amenities = [];
    };
}

export interface StructureModelDTO {
    year_built: number;
    effective_year_built: number;
    stories: string;
    beds_count: number;
    baths: number;
    partial_baths_count: number;
    units_count: number;
    parking_type: string;
    parking_spaces_count: number;
    pool_type: string;
    architecture_type: string;
    construction_type: string;
    exterior_wall_type: string;
    foundation_type: string;
    roof_material_type: string;
    roof_style_type: string;
    heating_type: string;
    heating_fuel_type: string;
    air_conditioning_type: string;
    firePlaces: string;
    basement_type: string;
    quality: string;
    condition: string;
    flooring_types: string[];
    plumbing_fixtures_count: number;
    interior_wall_type: string;
    water_type: string;
    sewer_type: string;
    total_area_sq_ft: number;
    other_areas: TypeObjectModel[];
    other_features: TypeObjectModel[];
    other_improvements: TypeObjectModel[];
    other_rooms: string[];
    amenities: string[];
}
