import { observable, runInAction, action, autorun, computed } from "mobx";

// Core
import { ApiResultErrorType } from "../../Core/Models";
import { AccountStatus } from "../../Core/Models/AccountStatus";
import { PostFormWithFile } from "../../Core/Utils";
import { FieldType, isEmptyOrWhitespace } from "../../Core/Utils/Utils";
import { ViewModelBase } from "../../Core/ViewModels/ViewModelBase";

// Custom
import { ContactViewModel } from "./ContactViewModel";
import { QuoteRequestModelV2 } from "../Models";
import { StoresInstance } from "../Stores";
import { Server } from "../Globals/AppUrls";

export class QuoteRequestViewModel extends ViewModelBase<QuoteRequestModelV2> {
    public scrollToBottom = false;

    public contactViewModel = new ContactViewModel();

    @observable
    public gotFields = observable<string>([]);

    @observable
    public userType = "";

    constructor() {
        super(new QuoteRequestModelV2());
        this.setDecorators(QuoteRequestModelV2);

        // For the moment, this is a necessary evil. Without it, the property options will not validate correctly.
        autorun(() => {
            const foundationType = this.isFieldValid("foundationType", this.getValue("foundationType"));
            const hasBasement = this.isFieldValid("hasBasement", this.getValue("hasBasement"));
            const isNewProperty = this.isFieldValid("isNewProperty", this.getValue("isNewProperty"));
            const ownershipType = this.isFieldValid("ownershipType", this.getValue("ownershipType"));
            const propertyOccupy = this.isFieldValid("propertyOccupy", this.getValue("propertyOccupy"));
            const insurancePolicy = this.isFieldValid("insurancePolicy", this.getValue("insurancePolicy"));
            const constructionType = this.isFieldValid("constructionType", this.getValue("constructionType"));
            const yearBuilt = this.isFieldValid("yearBuilt", this.getValue("yearBuilt"));
            const numberOfFloors = this.isFieldValid("numberOfFloors", this.getValue("numberOfFloors"));
            const buildingSqft = this.isFieldValid("buildingSqft", this.getValue("buildingSqft"));
            const isOverWater = this.isFieldValid("isOverWater", this.getValue("isOverWater"));
            const isMobileHome = this.isFieldValid("isMobileHome", this.getValue("isMobileHome"));
            const hasGarage = this.isFieldValid("hasGarage", this.getValue("hasGarage"));
        });
    }

    // #region Actions

    @action
    public setUserType = (val: string) => {
        this.userType = val;
    };

    @action
    public setCoverage = (buildingCoverage: number, contentsCoverage: number, deductible: number) => {
        this.model.buildingCoverage = buildingCoverage;
        this.model.contentsCoverage = contentsCoverage;
        this.model.deductible = deductible;
    };

    @action
    public addGotField(fieldName: string) {
        this.gotFields.push(fieldName);
    }

    public tryGetQuotes = async (): Promise<boolean | undefined> => {
        try {
            if (StoresInstance.domain.AccountStore.IsLoggedIn) {
                return true;
            }

            const userDetails = {
                firstName: this.getValue("firstName"),
                lastName: this.getValue("lastName"),
                emailAddress: this.getValue("email"),
                phoneNumber: this.getValue("phone"),
                terms: this.getValue("terms"),
                password: this.getValue("password"),
                confirmPassword: this.getValue("confirmPassword"),
            };

            const apiResult = await this.Post<AccountStatus>(Server.Api.Account.TryRegisterAndLogin, userDetails);

            if (apiResult.wasSuccessful) {
                StoresInstance.domain.AccountStore.getLoginState(apiResult.payload);

                this.setValue("password", "");
                this.setValue("confirmPassword", "");
            } else {
                if (apiResult.errors[0].type === ApiResultErrorType.Validation) {
                    return false;
                } else {
                    throw new Error("Unknown failure");
                }
            }
            return true;
        } catch (exception) {
            // Error. What to do?
            this.setIsErrored(true);
        } finally {
            this.setIsSaving(false);
            runInAction(() => {
                StoresInstance.domain.QuoteResultViewModel.canGetQuote = true;
            });
        }
    };

    /**
     * Copies quoteRequestVM.contactViewModel to contactViewModel
     * For Agent registration View
     */
    @action
    public moveContactInfo = () => {
        let quoteRequestVM = StoresInstance.domain.QuoteRequestViewModel;
        let contactVM = StoresInstance.domain.ContactViewModel;

        contactVM.setValue("firstName", quoteRequestVM.getValue("firstName"));
        contactVM.setValue("lastName", quoteRequestVM.getValue("lastName"));
        contactVM.setValue("tel", quoteRequestVM.getValue("phone"));
        contactVM.setValue("email", quoteRequestVM.getValue("email"));
        contactVM.setValue("terms", quoteRequestVM.getValue("terms"));
    };

    /**
     * Actions needed to perform Submit and get quotes
     */
    @action
    public submitActions = () => {
        const quoteRequestVM = StoresInstance.domain.QuoteRequestViewModel;
        const contactVM = StoresInstance.domain.ContactViewModel;
        const domainStores = StoresInstance.domain;

        if (!domainStores.AccountStore.IsLoggedIn) {
            contactVM.setValid("firstName", quoteRequestVM.getValue("firstName"));
            contactVM.setValid("lastName", quoteRequestVM.getValue("lastName"));
            contactVM.setValid("tel", quoteRequestVM.getValue("phone"));
            contactVM.setValid("email", quoteRequestVM.getValue("email"));
            contactVM.setValid("terms", quoteRequestVM.getValue("terms"));
        }

        const enclosureTypesArr: string[] = quoteRequestVM.getValue("enclosureTypesArr");

        quoteRequestVM.setValue("enclosureTypes", enclosureTypesArr.join());

        domainStores.StructureUpdated = true;
    };

    /**
     * Sets the isNewProperty - pass the year from estated/etc to set
     */
    @action
    public doIsNewProperty = (gotYear: number) => {
        let date = new Date();
        let currYear = date.getFullYear();
        if (gotYear < currYear) {
            this.setValue("isNewProperty", false);
        } else {
            this.setValue("isNewProperty", true);
        }
    };

    /**
     * Fixes userType is lost in when coming from Dashboard
     */
    @computed
    public get getUserType() {
        const domainStores = StoresInstance.domain;
        let userType = this.userType ? this.userType : "";
        // Logged in but userType not detected - occurs if from dashboard
        if (domainStores.AccountStore.IsLoggedIn && userType.length === 0) {
            if (domainStores.AccountStore.IsRealtorAccount) {
                userType = "realEstateAgent";
            }

            if (domainStores.AccountStore.IsProAccount) {
                userType = "insuranceAgent";
            }
        }

        return userType;
    }

    @computed
    public get isWhatUserType() {
        let retVal = "";
        let accountStore = StoresInstance.domain.AccountStore;

        if (this.userType.length == 0) {
            // if coming from dashboard or already logged in
            if (accountStore.IsLoggedIn) {
                retVal = "propertyOwner";

                if (accountStore.IsAgentAccount) {
                    retVal = "realEstateAgent";
                }

                if (accountStore.IsProAccount) {
                    retVal = "insuranceAgent";
                }
            }
        } else {
            // new account
            retVal = this.userType;
        }

        return retVal;
    }

    @computed
    public get canProceed() {
        const isLoggedIn = StoresInstance.domain.AccountStore.IsLoggedIn;

        //console.log("businessType: " + this.getValue("businessType"));
        let propertyDetailVM = StoresInstance.domain.PropertyDetailViewModel;

        // Validate property information
        const isPropertyValid = propertyDetailVM.okayToProceed;
        console.log(isPropertyValid ? "isPropertyValid true" : "isPropertyValid false");

        // Validate request owner.
        const isValidRequestOwner =
            !isEmptyOrWhitespace(this.getValue("requestOwnerFirstName")) &&
            !isEmptyOrWhitespace(this.getValue("requestOwnerLastName")) &&
            !isEmptyOrWhitespace(this.getValue("requestOwnerEmail"));
        // - !isEmptyOrWhitespace(this.getValue("requestOwnerPhone")); - not all accounts will have phone number, and this flag is only checked if already logged in

        console.log(isValidRequestOwner ? "isValidRequestOwner true" : "isValidRequestOwner false");

        // Validate policy holder.
        const isValidPolicyHolder =
            !isEmptyOrWhitespace(this.getValue("requestPolicyHolderFirstName")) &&
            !isEmptyOrWhitespace(this.getValue("requestPolicyHolderLastName")) &&
            !isEmptyOrWhitespace(this.getValue("requestPolicyHolderEmail")) &&
            !isEmptyOrWhitespace(this.getValue("requestPolicyHolderPhone"));

        console.log(isValidPolicyHolder ? "isValidPolicyHolder true" : "isValidPolicyHolder false");

        // Validate the registration form details.
        const isValidRegistration =
            this.isFieldDecoratorsValid("firstName", this.getValue("firstName")) &&
            this.isFieldDecoratorsValid("lastName", this.getValue("lastName")) &&
            this.isFieldDecoratorsValid("email", this.getValue("email")) &&
            this.isFieldDecoratorsValid("phone", this.getValue("phone")) &&
            this.isFieldDecoratorsValid("password", this.getValue("password")) &&
            this.isFieldDecoratorsValid("confirmPassword", this.getValue("confirmPassword")) &&
            this.isFieldDecoratorsValid("terms", this.getValue("terms")) &&
            this.getValue("password") === this.getValue("confirmPassword");

        console.log(isValidRegistration ? "isValidRegistration true" : "isValidRegistration false");

        // Validate the policy holder form details.
        const isValidAgentPolicyHolder =
            this.isFieldDecoratorsValid("policyHolderFirstName", this.getValue("policyHolderFirstName")) &&
            this.isFieldDecoratorsValid("policyHolderLastName", this.getValue("policyHolderLastName")) &&
            this.isFieldDecoratorsValid("policyHolderEmail", this.getValue("policyHolderEmail")) &&
            this.isFieldDecoratorsValid("policyHolderPhoneNumber", this.getValue("policyHolderPhoneNumber"));

        console.log(isValidAgentPolicyHolder ? "isValidAgentPolicyHolder true" : "isValidAgentPolicyHolder false");

        // Combine. First check we have a valid property.
        let canProceed = isPropertyValid;
        if (canProceed) {
            if (isLoggedIn) {
                // If logged in and an agent, the policy holder form must be completed.
                if (this.model.requestOwnerIsAgent) {
                    canProceed = isValidAgentPolicyHolder;
                }

                // Finally if all is good so far, check that the values that will be
                // passed to the server are valid. We should have a request owner and
                // a policy holder.
                if (canProceed) {
                    canProceed = isValidRequestOwner && (isValidPolicyHolder || !StoresInstance.domain.AccountStore.IsProAccount);
                }
            } else {
                // If not logged in, the registration form must be completed.
                canProceed = isValidRegistration;
            }
        }

        return canProceed;
    }

    @action
    public showField = (fieldName: string) => {
        let retVal = !this.gotFields.includes(fieldName);
        return retVal;
    };

    /**
     * Returns true if not valid/populated from estated
     * @param fieldName keyof FieldType<QuoteRequestModelV2>
     */
    @action
    public fieldRequired = (fieldName: keyof FieldType<QuoteRequestModelV2>) => {
        let retVal = false;
        if (!this.isFieldDecoratorsValid(fieldName, this.getValue(fieldName))) {
            retVal = true;
        }
        return retVal;
    };

    // #endregion Actions

    public uploadCertificate = async (files: FileList | null | undefined): Promise<string | undefined> => {
        if (files !== null && files !== undefined) {
            if (files.length > 1) {
                throw new Error("Too many files!");
            }
            let filename;
            for (var fileIndex = 0; fileIndex < files.length; fileIndex++) {
                const file = files[fileIndex];

                try {
                    const apiResult = await PostFormWithFile<string>(Server.Api.Upload.UploadFile, file);

                    if (apiResult.wasSuccessful) {
                        this.model.setCertificate(apiResult.payload);
                        filename = file.name;
                    } else {
                        // Error. What to do?
                        this.setIsErrored(true);
                    }
                } catch (exception) {
                    // Error. What to do?
                    this.setIsErrored(true);
                } finally {
                    this.setIsSaving(false);
                }
            }
            return filename;
        } else if (files === undefined) {
            this.model.setCertificate("");
        }
        return undefined;
    };

    @action
    public resetGotFields() {
        this.gotFields.clear();
    }

    public isFieldValid(fieldName: keyof FieldType<QuoteRequestModelV2>, value: any): boolean {
        let { isValid, errorMessage } = this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    /**
     * This validates the specified property against the class validators in the model.
     *
     * Unlike method isFieldValid, this method will not attempt to call any actions.
     *
     * @param fieldName The name of the property to validate.
     * @param value The value of the property.
     *
     * @return true if the property is valid, otherwise false.
     */
    public isFieldDecoratorsValid(fieldName: keyof FieldType<QuoteRequestModelV2>, value: any): boolean {
        let { isValid } = this.validateDecorators(fieldName);
        console.log(fieldName + "  is valid " + isValid);
        return isValid;
    }

    public afterUpdate(fieldName: keyof FieldType<QuoteRequestModelV2>, value: any) {
        if (fieldName === "foundationType") {
            this.setValue("hasCrawlSpace", value === "crawlspace" ? "true" : "false");
            this.setValue("hasBasement", this.getValue("foundationType") === "basement" ? "true" : "false");
        } else if (fieldName === "hasBasement") {
            if (value === "false") {
                runInAction(() => {
                    this.model.enclosureTypesArr.replace([]);
                });
                this.setValue("enclosureTypes", "");
            }
        }
    }

    public beforeUpdate: undefined;
}
