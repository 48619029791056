import React, { useEffect, useState } from "react";
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Theme,
    Typography,
} from "@material-ui/core";
import { Centered, TextFieldMemo } from "Core/Components/General";
import styled from "styled-components";
import { EditableInput } from "../../Core/Components/EditableInput";
import { RegisterUserViewModel } from "../ViewModels/RegisterUserViewModel";
import { nameofFactory } from "../../Core/Utils/Utils";
import { RegisterUserModel } from "../Models/RegisterUserModel";
import Radio from "@material-ui/core/Radio";
import { KeyValuePair } from "../../Core/Models/KeyValuePair";
import { useObservable } from "mobx-react-lite";
import { Footer } from "Custom/Components/Footer";
import { Terms } from "Custom/Components/Terms";
import { DivWrapper, GridSpaced, TitleWrapper, DivWrapperBGFixed } from "Custom/StylesAppSpecific/GridHelperStyling";
import PXFuel from "Content/quoteBG.jpg";
import { useMediaQuery } from "../../Core/Utils/Media/mediaQuery";
import iconProRegister from "Content/icon-proRegister.svg";
import { HdrImgDisc, BulletedListStyling } from "Custom/StylesAppSpecific/PageStyling";
import { useObserver } from "../Globals/Base";
import { ContactDetailStyle2 } from "Custom/StylesAppSpecific/ContactForms";
import { ContactDetail, AgencyDetails } from "Custom/Components/ContactForm";
import { ProRegisterViewModel } from "../ViewModels/ProRegisterViewModel";
import { AgencyDetailViewModel } from "../ViewModels/Agent/AgencyDetailViewModel";
import { ProRegisterModel } from "../Models/ProRegisterModel";
import { RememberMeLabel, ShowPasswordContainer, ShowPasswordIcon2 } from "Custom/StylesAppSpecific/LoginStyling";
import radioYes from "Content/radioYes.svg";
import radioYesChecked from "Content/radioYes-checked.svg";
import { useRouter } from "../../Core/Utils/Hooks/UseReactRouter";
import ProBackground from "Content/ProBackground.png";
import EyeClosed from "Content/icon-Eye-closed.svg";
import EyeOpen from "Content/icon-Eye-open.svg";
import { RecaptchaContainer } from "Custom/StylesAppSpecific/ContactForms";
import ReCAPTCHA from "react-google-recaptcha";
import { StoresInstance } from "../Stores";

const Main = styled.main`
    width: 600px;
    display: block;
    margin-left: auto;
    margin-right: auto;
`;

const Container = styled.div``;

const DetailsStyles = styled.div`
    /* background-color: #3a4650; */
    padding: 5px 0 40px;

    > div {
        margin-bottom: 0;
        padding-bottom: 0;

        > h2 {
            margin-bottom: 17px;
        }
    }

    > div.btns {
        padding-top: 80px;
        display: flex;
        justify-content: center;

        button {
            border-radius: 1000px;
            width: 200px;
            margin: 0 7.5px;
            padding: 14px 0 15px;
            color: white;
            font-weight: bold;
            font-size: 1.5rem;
            line-height: 100%;
        }

        button.save-btn {
            background-color: #129bdb;

            &.Mui-disabled {
                background-color: #129bdb;
                opacity: 0.4;

                span.MuiButton-label {
                    color: #ffffff;
                }
            }
        }

        button.cancel-btn {
            background-color: #333333;
        }
    }

    div.terms-container {
        flex-basis: 100%;
        overflow-y: auto;

        > div {
            height: 240px;
        }

        p,
        h1,
        h2,
        li {
            color: black;
        }

        p {
            margin: 0 0 14px;
        }

        ol li {
            margin-bottom: 15px;
        }
    }

    > div > span {
        > label {
            padding: 0 20px;
            margin-top: 30px !important;

            h2 {
                margin-bottom: 0;
                padding-left: 20px;
            }
        }

        > p {
            margin-top: 0;
            padding-left: 90px;
        }
    }
`;

let viewModel = new RegisterUserViewModel();
const nameof = nameofFactory<RegisterUserModel>();

export const RegisterPage: React.FC = () => {
    const domainStores = StoresInstance.domain;
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const isMobile = !!useMediaQuery("(max-width: 767px)");
    const [viewModel] = useState(() => new ProRegisterViewModel());
    const { history } = useRouter();
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const contactVM = domainStores.ContactViewModel;

    const handleSubmit = async (e: any) => {
        viewModel.setValue("agencyName", viewModel.agencyDetails.getValue("name"));
        viewModel.agencyDetails.setValue("userId", null);

        e.preventDefault();
        //isValid will check all fields to make sure they are in a valid state.
        if (viewModel.checkModelValidity()) {
            const result = await viewModel.registerPro();
            if (result.wasSuccessful) {
                //history.push(`/dashboard`);
                history.push("/carrier-settings");
            } else if (result.errors && result.errors[0] && result.errors[0].message) {
                setErrorMessage(result.errors[0].message);
            } else {
                setErrorMessage("An unknown error occured.");
            }
        }
    };

    useEffect(() => {
        // Copy contact to QuoteRequest
        viewModel.fillContactInfo();
    }, []);

    let handleShowPassword = (e: any) => {
        setShowPassword(!showPassword);
    };

    let handleShowPassword2 = (e: any) => {
        setShowPassword2(!showPassword2);
    };

    const onChange = (value: string | null) => {
        viewModel.setCaptchaToken(value || "");
        if (value !== null && value.length > 0) {
            viewModel.setValue("passCaptcha", true);
        } else {
            viewModel.setValue("passCaptcha", false);
        }
    };

    const canSubmit = (canRegister: boolean): boolean => {
        return canRegister && !isLoading;
    };

    const contentHdr = (
        <>
            <GridSpaced container margin={"0px auto"} additionalcss={"max-width: 688px; width: 100%;"}>
                <GridSpaced item xs={12} sm={12} additionalcss={"text-align: center;"}>
                    <HdrImgDisc src={iconProRegister} alt={""} />
                </GridSpaced>
            </GridSpaced>

            <GridSpaced container margin={"0px auto"} additionalcss={"width: 100%;"}>
                <Grid item xs={12} sm={12} justify={"center"}>
                    <TitleWrapper padding={"0"} margin={"0"}>
                        <h1>Sign me up for a My Flood Insurance Pro Agent account</h1>
                    </TitleWrapper>
                </Grid>
            </GridSpaced>

            <GridSpaced container margin={"0px auto"} additionalcss={"width: 100%; max-width: 1040px;"}>
                <GridSpaced item xs={12}>
                    <p style={{ fontWeight: 500, fontSize: "18px" }}>
                        Fill out the information below to sign-up for a My Flood Insurance Pro account. After registering you will be able to:
                    </p>
                    <BulletedListStyling>
                        <li>Quote and write policies using your existing agency contracts</li>
                        <li>
                            Process quotes through a National Flood Insurance, LLC partnership
                            <ul>
                                <li>We write it for you, but you get paid</li>
                            </ul>
                        </li>
                        <li>Quote the best flood insurance options available for your clients</li>
                    </BulletedListStyling>
                </GridSpaced>
            </GridSpaced>
        </>
    );

    return useObserver(() => (
        <>
            <DivWrapperBGFixed
                additionalcss={
                    "background: linear-gradient(to top, rgb(20, 32, 41) 10%, rgb(0, 0, 0, 0.0)), url(" + ProBackground + ") center center / cover no-repeat;"
                }
            >
                <div style={{ margin: "0 20px" }}>
                    <DivWrapper
                        margin={"0px auto"}
                        additionalcss={isMobile ? "max-width: 1124px; padding-bottom: 80px;" : "max-width: 1124px; padding-bottom: 100px;"}
                    >
                        <Container>
                            {contentHdr}
                            <form onSubmit={handleSubmit}>
                                <DetailsStyles>
                                    <AgencyDetails viewModel={viewModel.agencyDetails} noBackground hideErrorBorder />
                                    <ContactDetail
                                        viewModel={viewModel.contactViewModel}
                                        noBackground
                                        namesLabel="What is your name?"
                                        contactsLabel="What are your contact details?"
                                        hideLogin
                                        hideTerms
                                        hideErrorBorder
                                    />
                                    <ContactDetailStyle2 additionalcss="background-color: transparent;">
                                        <h2>Set your password</h2>
                                        <p className="pwd-hint">
                                            Password must contain at least 8 characters and include upper & lower case letters and at least one digit.
                                        </p>
                                        <ShowPasswordContainer>
                                            <EditableInput
                                                viewModel={viewModel}
                                                inputProps={{ placeholder: "New password", autoComplete: "new-password" }}
                                                fieldName="password"
                                                type={showPassword ? "text" : "password"}
                                            />
                                            <ShowPasswordIcon2
                                                onClick={(e: any) => handleShowPassword(e)}
                                                title={showPassword ? "Hide password" : "Show password"}
                                            >
                                                <img src={showPassword ? EyeClosed : EyeOpen} alt="" />
                                            </ShowPasswordIcon2>
                                        </ShowPasswordContainer>
                                        <ShowPasswordContainer>
                                            <EditableInput
                                                viewModel={viewModel}
                                                inputProps={{ placeholder: "Confirm password", autoComplete: "new-password" }}
                                                fieldName="confirmPassword"
                                                type={showPassword2 ? "text" : "password"}
                                            />
                                            <ShowPasswordIcon2
                                                onClick={(e: any) => handleShowPassword2(e)}
                                                title={showPassword2 ? "Hide password" : "Show password"}
                                            >
                                                <img src={showPassword2 ? EyeClosed : EyeOpen} alt="" />
                                            </ShowPasswordIcon2>
                                        </ShowPasswordContainer>
                                    </ContactDetailStyle2>
                                    <ContactDetailStyle2 additionalcss="background-color: transparent;">
                                        <h2>Terms of Use</h2>
                                        <div className="terms-container">
                                            <div>
                                                <Terms />
                                            </div>
                                        </div>
                                        <span>
                                            <RememberMeLabel
                                                control={
                                                    <Checkbox
                                                        name="hasAcceptedTerms"
                                                        icon={<img src={radioYes} alt="Y" />}
                                                        checkedIcon={<img src={radioYesChecked} alt="N" />}
                                                        checked={viewModel.getValue("hasAcceptedTerms")}
                                                        onChange={e => {
                                                            viewModel.setValue("hasAcceptedTerms", e.target.checked);
                                                            viewModel.isFieldValid("hasAcceptedTerms", e.target.checked);
                                                        }}
                                                    />
                                                }
                                                label={<h2>By checking this you agree to the terms and conditions</h2>}
                                            />
                                            {viewModel.getError("hasAcceptedTerms") && (
                                                <p style={{ color: "red", marginTop: "10px" }}>{viewModel.getError("hasAcceptedTerms")}</p>
                                            )}
                                        </span>
                                    </ContactDetailStyle2>
                                    <div style={{ marginLeft: "20px", paddingTop: "10px", minHeight: "83px" }}>
                                        <RecaptchaContainer>
                                            <ReCAPTCHA sitekey="6LfxGfcUAAAAAFnE3EplKmp5SQxfNkQbxfaobwos" onChange={onChange} />
                                            {viewModel.getError("passCaptcha") !== "" && <span>{viewModel.getError("passCaptcha")}</span>}
                                        </RecaptchaContainer>
                                    </div>
                                    {errorMessage && <p style={{ color: "red", marginLeft: "40px", marginTop: "20px" }}>{errorMessage}</p>}
                                    <div className="btns">
                                        <Button className="save-btn" variant="contained" type="submit" disabled={!canSubmit(viewModel.canRegisterPro)}>
                                            {isLoading ? "Loading..." : "Sign up"}
                                        </Button>
                                        <Button className="cancel-btn" variant="contained" onClick={() => history.goBack()}>
                                            Cancel
                                        </Button>
                                    </div>
                                </DetailsStyles>
                            </form>
                        </Container>
                    </DivWrapper>
                </div>
            </DivWrapperBGFixed>
            <Footer isTemp={false} />
        </>
    ));
};
