// Libraries
import React, { useEffect, useState, useRef } from "react";
import { useObserver } from "mobx-react-lite";
import { Grid } from "@material-ui/core";

// Content
import iconAddSomeDetail from "Content/icon-AddSomeDetail.svg";
import PXFuel from "Content/quoteBG.jpg";

// Core
import { useRouter } from "../../Core/Utils";
import { useMediaQuery } from "../../Core/Utils/Media/mediaQuery";

// Custom
import { StoresInstance } from "../Stores";
import { Footer } from "../Components/Footer";
import { PropertyDetails, AdditionalPropertyInfo } from "../Components/PropertyDetails";
import { CoverageEdit, EditBar, LocationEdit } from "../Components/EditBar";
import { QuoteLoading } from "../Components/IsLoading";
import { DivWrapper, GridSpaced, TitleWrapper, TitleWrapper2, H3WithProp, DivWrapperBGFixed } from "../StylesAppSpecific/GridHelperStyling";
import { Container, SubmitButton, AgencyButton } from "../StylesAppSpecific/Coverage/CoverageDetails";
import { HdrImgDisc } from "../StylesAppSpecific/PageStyling";
import { PolicyHolder } from "../Components/PropertyCoverage/PolicyHolder";
import { Registration } from "../Components/PropertyCoverage/Registration";

export const PropertyCoverageDetails: React.FC = () => {
    const domainStores = StoresInstance.domain;
    const quoteRequestVM = domainStores.QuoteRequestViewModel;
    const structurePopulated: boolean = domainStores.EstatedHasStructure;
    const structureUpdated: boolean = domainStores.StructureUpdated;
    const requestAddress: string = quoteRequestVM.getValue("address");
    const requestPopulated: boolean = requestAddress.length > 0 ? true : false;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { history } = useRouter();
    const isMobile = !!useMediaQuery("(max-width: 767px)");
    const contactRef = useRef<HTMLAnchorElement>(null);

    if (!requestPopulated) {
        history.push("./");
    }

    useEffect(() => {
        if (quoteRequestVM.scrollToBottom && contactRef.current) {
            contactRef.current.scrollIntoView();
            quoteRequestVM.scrollToBottom = false;
        }
    }, [contactRef.current]);

    useEffect(() => {
        if (!isLoading && requestPopulated) {
            quoteRequestVM.isModelValid();
        }
    }, [isLoading, requestPopulated]);

    const editBar = (
        <EditBar>
            <LocationEdit />
            <CoverageEdit />
        </EditBar>
    );

    const contentHdr = (
        <>
            <GridSpaced container margin={"0px auto"} additionalcss={"max-width: 688px; width: 100%;"}>
                <GridSpaced item xs={12} sm={12} additionalcss={"text-align: center;"}>
                    <HdrImgDisc src={iconAddSomeDetail} alt={""} />
                </GridSpaced>
            </GridSpaced>

            <GridSpaced container margin={"0px auto"} additionalcss={"width: 100%;"}>
                <Grid item xs={12} sm={12} justify={"center"}>
                    <TitleWrapper padding={"0"} margin={"0"}>
                        <h1>
                            <span>Step 3:</span> Add some detail about you and your property
                        </h1>
                    </TitleWrapper>
                </Grid>
            </GridSpaced>
        </>
    );

    const goRegisterPro = () => {
        quoteRequestVM.moveContactInfo();
        history.push("/register");
    };

    const doSubmit = () => {
        window.scrollTo(0, 0);
        setIsLoading(true);
        quoteRequestVM.submitActions();
        quoteRequestVM.tryGetQuotes().then(success => success !== undefined && history.push(success ? "/availablepolicies" : "/emailexists"));
    };

    const renderPolicyHolderDetails = () => {
        return (
            <GridSpaced container margin={"0px auto"} additionalcss={"width: 100%;"}>
                <Grid item xs={12} sm={12} justify={"center"}>
                    <TitleWrapper2 padding={"0"} margin={"0"}>
                        <h2 className="questions-2 no-top-margin">
                            <span>Policy Holder</span>
                        </h2>
                    </TitleWrapper2>
                    <H3WithProp padding={"0"} margin={"0px auto 0px"}>
                        {quoteRequestVM.getValue("requestPolicyHolderFirstName") + " " + quoteRequestVM.getValue("requestPolicyHolderLastName")}
                    </H3WithProp>
                    <H3WithProp padding={"0"} margin={"0px auto 0px"}>
                        {quoteRequestVM.getValue("requestPolicyHolderEmail")}
                    </H3WithProp>
                    <H3WithProp padding={"0"} margin={"0px auto 0px"}>
                        {quoteRequestVM.getValue("requestPolicyHolderPhone")}
                    </H3WithProp>
                </Grid>
            </GridSpaced>
        );
    };

    const renderPolicyHolderDetailsFieldsTitle = () => {
        return StoresInstance.domain.AccountStore.IsProAccount ? (
            <React.Fragment>
                <a ref={contactRef} />
                <GridSpaced container margin={"0px auto"} additionalcss={"width: 100%;"}>
                    <Grid item xs={12} sm={12} justify={"center"}>
                        <TitleWrapper2 padding={"0"} margin={"0"}>
                            <h2 className="questions-2 no-top-margin">
                                <span>The policyholder</span>
                            </h2>
                            <h3>Please enter the name and contact details of who this policy will be for</h3>
                        </TitleWrapper2>
                    </Grid>
                </GridSpaced>
            </React.Fragment>
        ) : (
            <React.Fragment>
                <a ref={contactRef} />
                <GridSpaced container margin={"0px auto"} additionalcss={"width: 100%;"}>
                    <Grid item xs={12} sm={12} justify={"center"}>
                        <TitleWrapper2 padding={"0"} margin={"0"}>
                            <h2 className="questions-2 no-top-margin">
                                <span>...and finally</span>
                            </h2>
                            <h3>To receive your quotes and save them for future access, set up an account with us...</h3>
                        </TitleWrapper2>
                    </Grid>
                </GridSpaced>
            </React.Fragment>
        );
    };

    const renderFields = () => {
        const isLoggedIn = StoresInstance.domain.AccountStore.IsLoggedIn;
        const isAgent = StoresInstance.domain.AccountStore.IsProAccount;

        return (
            <React.Fragment>
                {isLoggedIn && isAgent && <PolicyHolder viewModel={quoteRequestVM} />}
                {!isLoggedIn && <Registration viewModel={quoteRequestVM} />}
            </React.Fragment>
        );
    };

    const renderButtons = () => {
        return !StoresInstance.domain.AccountStore.IsProAccount ? (
            <GridSpaced container>
                <GridSpaced item xs={12} sm={6}>
                    <SubmitBtn doSubmit={() => doSubmit()} />
                </GridSpaced>
                <GridSpaced item xs={12} sm={6}>
                    <AgencyRegBtn goRegisterPro={() => goRegisterPro()} />
                </GridSpaced>
            </GridSpaced>
        ) : (
            <SubmitBtn doSubmit={() => doSubmit()} />
        );
    };

    const contentBody = () => {
        return (
            <>
                {editBar}
                <div style={{ margin: "0 20px" }}>
                    <DivWrapper
                        margin={"0px auto"}
                        additionalcss={isMobile ? "max-width: 1124px; padding-bottom: 80px;" : "max-width: 1124px; padding-bottom: 100px;"}
                    >
                        <Container>
                            {contentHdr}
                            {structurePopulated && !structureUpdated && (
                                <>
                                    <TitleWrapper padding={"0"} margin={"0"}>
                                        <h2>Here is what we have found out about your property</h2>
                                    </TitleWrapper>
                                    {/* <PropertyDetailsCompleted /> */}
                                </>
                            )}
                            {!structurePopulated && structureUpdated && (
                                <>
                                    <TitleWrapper padding={"0"} margin={"0"}>
                                        <h2>Property details</h2>
                                    </TitleWrapper>
                                    {/* <PropertyDetailsCompleted /> */}
                                </>
                            )}
                            {!structurePopulated && !structureUpdated && (
                                <>
                                    <TitleWrapper padding={"0"} margin={"0 auto 74px"} additionalcss={"max-width: 798px;"}>
                                        <h2>
                                            We were unable to find your property information, please answer the questions below to make sure we get you the best
                                            possible quote
                                        </h2>
                                    </TitleWrapper>
                                </>
                            )}
                            <PropertyDetails />
                            <GridSpaced container margin={"0px auto"} additionalcss={"width: 100%;"}>
                                <Grid item xs={12} sm={12} justify={"center"}>
                                    <TitleWrapper2 padding={"0"} margin={"0"}>
                                        <h2 className="questions-2">
                                            <span>We just need to know{isMobile ? <br /> : " "}a little bit more from you</span>
                                        </h2>
                                        <h3>to make sure we can get{isMobile ? <br /> : " "}the best possible price</h3>
                                    </TitleWrapper2>
                                </Grid>
                            </GridSpaced>
                            <AdditionalPropertyInfo />

                            {domainStores.AccountStore.IsLoggedIn && !domainStores.AccountStore.IsProAccount ? (
                                <React.Fragment>{renderPolicyHolderDetails()}</React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {renderPolicyHolderDetailsFieldsTitle()}
                                    {renderFields()}
                                </React.Fragment>
                            )}
                            {renderButtons()}
                        </Container>
                    </DivWrapper>
                </div>
            </>
        );
    };

    return useObserver(() => (
        <>
            <DivWrapperBGFixed additionalcss={"background-image: url(" + PXFuel + ");"}>
                {requestPopulated && !isLoading && contentBody()}
                {requestPopulated && isLoading && (
                    <>
                        <QuoteLoading />
                    </>
                )}
                {!requestPopulated && !isLoading && <>ToDo</>}
            </DivWrapperBGFixed>
            <Footer isTemp={false} />
        </>
    ));
};

interface ISubmitProps {
    doSubmit: () => void;
}

const SubmitBtn: React.FC<ISubmitProps> = ({ doSubmit }) => {
    return useObserver(() => (
        <SubmitButton
            variant="contained"
            onClick={() => doSubmit()}
            disabled={!StoresInstance.domain.QuoteRequestViewModel.canProceed}
            style={{ marginBottom: "0px" }}
        >
            <p>Click here to get your quotes</p>
        </SubmitButton>
    ));
};

interface IAgentRegProps {
    goRegisterPro: () => void;
}

const AgencyRegBtn: React.FC<IAgentRegProps> = ({ goRegisterPro }) => {
    return useObserver(() => (
        <AgencyButton variant="contained" onClick={() => goRegisterPro()} style={{ marginBottom: "0px" }}>
            <p>
                Are you an Insurance Agent? <span>Continue with a Pro account</span>
            </p>
        </AgencyButton>
    ));
};
