// Libraries
import React, { useEffect } from "react";
import { useObserver } from "mobx-react-lite";

// Components
import { StoresInstance, useState } from "Custom/Globals/Base";
import { isNullOrUndefined } from "util";
import { isNullOrEmpty } from "Core/Utils/Utils";
import { useMediaQuery } from "Core/Utils/Media/mediaQuery";
import { QuoteRequestModelV2 } from "Custom/Models";

// Styling & images
import {} from "./Steps.Styling";
import { SimpleQuestion } from "./SimpleQuestion";
import { LargeMultichoice, LargeMultichoiceOption, RadioYesNo, CollapsableLargeMultichoice } from "../Forms";
import iconGenericHouse from "Content/icon-genericHouse.svg";
import iconOwnAndRent from "Content/icon-ownAndRent.svg";
import iconRent from "Content/icon-rentFromSomeone.svg";
import iconPolicyYes from "Content/icon-policyYes.svg";
import iconPolicyNo from "Content/icon-policyNo.svg";
import iconUploadCertificate from "Content/icon-uploadCertificate.svg";
import iconUploadedCertificate from "Content/icon-uploadedCertificate.svg";
import iconOwnAndOccupy from "Content/icon-ownAndOccupy.svg";
import iconPolicyExpired from "Content/icon-policyExpired.svg";
import iconExistingProperty from "Content/icon-existingProperty.svg";
import iconNewProperty from "Content/icon-newProperty.svg";

const viewModel = StoresInstance.domain.QuoteRequestViewModel;

/**
 * CommonOptions: used on all property types.
 */
export const CommonOtions: React.FC = () => {
    const isMobile = !!useMediaQuery("(max-width: 767px)");
    const showField = (fieldName: string) => {
        const retVal = StoresInstance.domain.EstatedHasStructure && !viewModel.gotFields.includes(fieldName);

        return retVal;
    };
    return useObserver(() => (
        <>
            {/*
            <CollapsableLargeMultichoice
                viewModel={viewModel}
                fieldName="isNewProperty"
                question="Is this property:"
                disableCollapse={isNullOrUndefined(viewModel.model.isNewProperty)}
                value={viewModel.model.isNewProperty}
            >
                {props => (
                    <>
                        <LargeMultichoiceOption label="A new construction" value="true" image={iconNewProperty} {...props} />
                        <LargeMultichoiceOption label="An existing property" value="false" image={iconExistingProperty} {...props} />
                    </>
                )}
            </CollapsableLargeMultichoice>
            */}

            <CollapsableLargeMultichoice
                viewModel={viewModel}
                fieldName="ownershipType"
                question="Please select the option that applies to you:"
                disableCollapse={viewModel.model.ownershipType === ""}
                value={viewModel.model.ownershipType}
            >
                {props => (
                    <>
                        <LargeMultichoiceOption image={iconOwnAndOccupy} label="I own and occupy this property" value="ownAndOccupy" {...props} />
                        <LargeMultichoiceOption
                            image={iconOwnAndRent}
                            label="I own this property and rent it out to someone else"
                            value="ownAndRent"
                            {...props}
                        />
                        <LargeMultichoiceOption image={iconRent} label="I rent this property from someone else" value="rent" {...props} />
                    </>
                )}
            </CollapsableLargeMultichoice>

            <CollapsableLargeMultichoice
                viewModel={viewModel}
                fieldName="propertyOccupy"
                question="For how much of the next year will you occupy this property?"
                disableCollapse={viewModel.model.propertyOccupy === 0 || isNullOrUndefined(viewModel.model.propertyOccupy)}
                value={isNullOrUndefined(viewModel.model.propertyOccupy) ? "" : viewModel.model.propertyOccupy.toString()}
                className="noThumbs"
            >
                {props => (
                    <>
                        <LargeMultichoiceOption label="80-100%" value={100} bold {...props} />
                        <LargeMultichoiceOption label="51-79%" value={79} bold {...props} />
                        <LargeMultichoiceOption label="0-50%" value={50} bold {...props} />
                    </>
                )}
            </CollapsableLargeMultichoice>

            <CollapsableLargeMultichoice
                viewModel={viewModel}
                fieldName="insurancePolicy"
                question="Do you currently have a flood insurance policy?"
                disableCollapse={viewModel.model.insurancePolicy === ""}
                value={viewModel.model.insurancePolicy}
            >
                {props => (
                    <>
                        <LargeMultichoiceOption label="Yes" value="yes" image={iconPolicyYes} imgMargin="0 14px" {...props} />
                        <LargeMultichoiceOption label="No" value="no" image={iconPolicyNo} imgMargin="0 14px" {...props} />
                        <LargeMultichoiceOption label="Expired" value="expired" image={iconPolicyExpired} imgMargin="0 14px" {...props} />
                    </>
                )}
            </CollapsableLargeMultichoice>

            <LargeMultichoice viewModel={viewModel} fieldName="elevationCertificate" question="Elevation Certificate">
                {props => (
                    <>
                        <LargeMultichoiceOption
                            label={
                                isMobile
                                    ? "If you have an Elevation Certificate, please click below to upload it"
                                    : "If you have an Elevation Certificate, please upload it"
                            }
                            imgMargin={viewModel.getValue<File>("elevationCertificate") === undefined ? "0 10px" : "0 14px"}
                            uploader
                            buttonText="Upload certificate"
                            image={viewModel.getValue<File>("elevationCertificate") === undefined ? iconUploadCertificate : iconUploadedCertificate}
                            {...props}
                            onClick={(files: FileList) => {
                                viewModel.uploadCertificate(files).then(filename => {
                                    props.onClick(filename);
                                });
                            }}
                        />
                    </>
                )}
            </LargeMultichoice>
        </>
    ));
};
