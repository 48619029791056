import React, { useState, useContext, useEffect } from "react";
import { Box } from "@material-ui/core";

interface Props {
    iff: boolean;
    renderToDom?: boolean;
}
export const Show: React.FC<Props> = props => {
    if (props.iff) {
        return <>{props.children}</>;
    } else if (props.renderToDom) {
        return (
            <Box position="absolute" width="0" height="0" display="none">
                {props.children}
            </Box>
        );
    }
    return <></>;
};
Show.defaultProps = {
    iff: false,
    renderToDom: false,
};
