import { makeStyles } from "@material-ui/core";

export interface IComponentProps {
    padding?: string;
    margin?: string;
    fontcolor?: string;
    backgroundcolor?: string;
    additionalcss?: string;
}

export const useResultStyles = makeStyles(theme => ({
    paper: {
        borderColor: "#129BDB",
        borderRadius: "15px",
        borderStyle: "solid",
        borderWidth: "5px",
        marginBottom: "5px",
        [theme.breakpoints.down("xs")]: {
            marginBottom: "40px",
        },
    },
    selectedPaper: {
        borderColor: "#12DB4E",
        borderRadius: "15px",
        borderStyle: "solid",
        borderWidth: "5px",
        marginBottom: "5px",
        [theme.breakpoints.down("xs")]: {
            marginBottom: "40px",
        },
    },
    adminPaper: {
        borderColor: "#F2AF4C",
    },
    header: {
        alignItems: "center",
        borderTopLeftRadius: "15px",
        borderTopRightRadius: "15px",
        padding: "10px 15px",
        [theme.breakpoints.down("xs")]: {
            justifyContent: "center",
        },
    },
    headerDetailsGridItem: {
        paddingLeft: "25px",
        marginLeft: "25px",
        borderLeft: "solid 1px #129BDB",
        [theme.breakpoints.down("xs")]: {
            maxWidth: "190px",
            paddingLeft: "5px",
        },
    },
    headerNFIPDetailsGridItem: {
        paddingLeft: "50px",
        [theme.breakpoints.down("xs")]: {
            maxWidth: "190px",
        },
    },
    headerDetailsGridContainer: {
        alignItems: "baseline",
        marginLeft: "25px",
        [theme.breakpoints.down("xs")]: {
            marginLeft: "10px",
        },
    },
    headerGridItem: {
        paddingLeft: "15px",
        paddingRight: "15px",
        [theme.breakpoints.down("md")]: {
            paddingLeft: "0",
            paddingRight: "0",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "10px",
        },
    },
    insurerText: {
        fontSize: "14px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "10px",
        },
    },
    premiumText: {
        fontSize: "21px",
        fontWeight: "bold",
        lineHeight: "1.2",
        [theme.breakpoints.down("xs")]: {
            fontSize: "14px",
        },
    },
    deductibleText: {
        color: "#129BDB",
        fontSize: "14px",
        fontWeight: "bold",
        letterSpacing: "-0.35px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "10px",
        },
    },
    deductibleContainer: {
        borderTop: "solid 1px #129bdb",
        margin: "0px 20px",
        padding: "10px 0px",
    },
    deductiblePCPContainer: {
        margin: "0px 20px",
        padding: "10px 0px",
    },
    adminDeductible: {
        color: "#F2AF4C",
    },
    body: {
        backgroundColor: "#E7F6FE",
        borderBottomLeftRadius: "15px",
        borderBottomRightRadius: "15px",
        padding: "10px 5px 15px",
    },
    bodyTitle: {
        fontSize: "18px",
        fontWeight: "bold",
        [theme.breakpoints.down("xs")]: {
            fontSize: "10px",
        },
    },
    bodyItemText: {
        fontSize: "12px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "10px",
        },
    },
    bodyGridItem: {
        paddingLeft: "15px",
        paddingRight: "15px",
        margin: "5px 0",
    },
    bodyItemList: {
        marginTop: "-12px",
        paddingLeft: "24px",
    },
    imageGridItem: {
        display: "flex",
        flex: "1",
        justifyContent: "center",
        marginLeft: "40px",
        [theme.breakpoints.down("xs")]: {
            maxWidth: "90px",
            marginLeft: "0",
        },
    },
    selectButtonGridItem: {
        display: "flex",
        flex: "1",
        justifyContent: "flex-end",
        [theme.breakpoints.down("xs")]: {
            justifyContent: "center",
            height: "15px",
        },
    },
    iconButton: {
        height: "64px",
        margin: "0px 50px 0px 0px",
        padding: "0",
        width: "64px",
        backgroundColor: "unset",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "unset",
            boxShadow: "none",
        },
    },
    selectButton: {
        backgroundColor: "#129BDB",
        borderRadius: "50%",
        color: "white",
        fontSize: "12px",
        fontWeight: "bold",
        height: "64px",
        margin: "0",
        padding: "0",
        width: "64px",
        "&:hover": {
            backgroundColor: "#0F89C2",
        },
    },
    selectedButton: {
        backgroundColor: "#12DB4E",
        borderRadius: "50%",
        color: "white",
        fontSize: "12px",
        fontWeight: "bold",
        height: "64px",
        margin: "0",
        padding: "0",
        width: "64px",
        "&:hover": {
            backgroundColor: "#0F89C2",
        },
    },
}));

export const usePCPCardStyles = makeStyles(theme => ({
    body: {
        backgroundColor: "#E7F6FE",
    },
    adminBody: {
        backgroundColor: "#FFF0E1",
    },
}));

export const useNFIPCardStyles = makeStyles(theme => ({
    body: {
        backgroundColor: "#F1F1F1",
    },
    bodyGridItemTitle: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            "& img": {
                maxWidth: "22px",
            },
        },
    },
    bodyTitle: {
        marginLeft: "15px",
    },
}));
