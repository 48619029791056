import { LargeMultichoice, LargeMultichoiceOption, CollapsableLargeMultichoice } from "../Forms";
import { StoresInstance, styled, useState } from "../../Globals/Base";

import { ContactDetailStyle } from "Custom/StylesAppSpecific/FormStyling";
import { DateEffectiveModel } from "../../Models/DateEffectiveModel";
import { DateEffectiveViewModel } from "../../ViewModels/DateEffectiveViewModel";
import { EditableInput } from "Core/Components/EditableInput";
import React, { useEffect } from "react";
import calendar from "Content/calendar.svg";
import iconOwnAndOccupy from "Content/icon-ownAndOccupy.svg";
import iconPolicyExpired from "Content/icon-policyExpired.svg";
import { makeStyles } from "@material-ui/styles";
import { useMediaQuery } from "../../../Core/Utils/Media/mediaQuery";
import { useObserver } from "mobx-react-lite";
import foundationBasement from "Content/foundationBasement.svg";
import foundationCrawlspace from "Content/foundationCrawlspace.svg";
import foundationPiers from "Content/foundationPiers.svg";
import foundationSlab from "Content/foundationSlab.svg";
import { isNullOrEmpty } from "Core/Utils/Utils";
import iconGenericHouse from "Content/icon-genericHouse.svg";
import iconOwnAndRent from "Content/icon-ownAndRent.svg";
import iconRent from "Content/icon-rentFromSomeone.svg";
import iconPolicyYes from "Content/icon-policyYes.svg";
import iconPolicyNo from "Content/icon-policyNo.svg";
import iconUploadCertificate from "Content/icon-uploadCertificate.svg";
import iconUploadedCertificate from "Content/icon-uploadedCertificate.svg";
import {
    YearBuilt,
    NumberOfFloors,
    BuildingSqft,
    OverWater,
    MobileHome,
    HasGarage,
    ConstructionType,
    FoundationType,
    Basement,
    NewProperty,
    ContactDetailsContainerTransparent,
    SelectWidth,
} from ".";
import { isNullOrUndefined } from "util";
import moment from "moment";

const viewModel = StoresInstance.domain.QuoteRequestViewModel;

export const AdditionalPropertyInfo: React.FC = () => {
    const isMobile = !!useMediaQuery("(max-width: 767px)");

    const showField = (fieldName: string) => {
        const retVal = StoresInstance.domain.EstatedHasStructure && !viewModel.gotFields.includes(fieldName);

        return retVal;
    };

    return useObserver(() => (
        <>
            <Container>
                {showField("yearBuilt") && <YearBuilt viewModel={viewModel} />}
                {showField("numberOfFloors") && <NumberOfFloors viewModel={viewModel} />}
                {showField("buildingSqft") && <BuildingSqft viewModel={viewModel} />}
                {showField("isOverWater") && <OverWater viewModel={viewModel} />}
                {showField("isMobileHome") && <MobileHome viewModel={viewModel} />}
                {showField("hasGarage") && <HasGarage viewModel={viewModel} />}

                {showField("constructionType") && (
                    <ConstructionType
                        viewModel={viewModel}
                        disableCollapse={viewModel.model.constructionType === ""}
                        value={viewModel.model.constructionType}
                    />
                )}
                {showField("foundationType") && (
                    <FoundationType viewModel={viewModel} disableCollapse={viewModel.model.foundationType === ""} value={viewModel.model.foundationType} />
                )}

                {showField("hasBasement") && <Basement viewModel={viewModel} />}

                {StoresInstance.domain.EstatedHasStructure && <NewProperty viewModel={viewModel} />}

                <CollapsableLargeMultichoice
                    viewModel={viewModel}
                    fieldName="ownershipType"
                    question="Please select the option that applies to you:"
                    disableCollapse={viewModel.model.ownershipType === ""}
                    image={iconGenericHouse}
                    value={viewModel.model.ownershipType}
                >
                    {props => (
                        <>
                            <LargeMultichoiceOption image={iconOwnAndOccupy} label="I own and occupy this property" value="ownAndOccupy" {...props} />
                            <LargeMultichoiceOption
                                image={iconOwnAndRent}
                                label="I own this property and rent it out to someone else"
                                value="ownAndRent"
                                {...props}
                            />
                            <LargeMultichoiceOption image={iconRent} label="I rent this property from someone else" value="rent" {...props} />
                        </>
                    )}
                </CollapsableLargeMultichoice>

                <CollapsableLargeMultichoice
                    viewModel={viewModel}
                    fieldName="propertyOccupy"
                    question="For how much of the next year will you occupy this property?"
                    disableCollapse={viewModel.model.propertyOccupy === 0 || isNullOrUndefined(viewModel.model.propertyOccupy)}
                    image={iconGenericHouse}
                    value={isNullOrUndefined(viewModel.model.propertyOccupy) ? "" : viewModel.model.propertyOccupy.toString()}
                >
                    {props => (
                        <>
                            <LargeMultichoiceOption label="80-100%" value={100} bold {...props} />
                            <LargeMultichoiceOption label="51-79%" value={79} bold {...props} />
                            <LargeMultichoiceOption label="0-50%" value={50} bold {...props} />
                        </>
                    )}
                </CollapsableLargeMultichoice>

                <CollapsableLargeMultichoice
                    viewModel={viewModel}
                    fieldName="insurancePolicy"
                    question="Do you currently have a flood insurance policy?"
                    disableCollapse={viewModel.model.insurancePolicy === ""}
                    image={iconGenericHouse}
                    value={viewModel.model.insurancePolicy}
                >
                    {props => (
                        <>
                            <LargeMultichoiceOption label="Yes" value="yes" image={iconPolicyYes} imgMargin="0 14px" {...props} />
                            <LargeMultichoiceOption label="No" value="no" image={iconPolicyNo} imgMargin="0 14px" {...props} />
                            <LargeMultichoiceOption label="Expired" value="expired" image={iconPolicyExpired} imgMargin="0 14px" {...props} />
                        </>
                    )}
                </CollapsableLargeMultichoice>

                <LargeMultichoice viewModel={viewModel} fieldName="elevationCertificate" question="Elevation Certificate">
                    {props => (
                        <>
                            <LargeMultichoiceOption
                                label={
                                    isMobile
                                        ? "If you have an Elevation Certificate, please click below to upload it"
                                        : "If you have an Elevation Certificate, please upload it"
                                }
                                imgMargin={viewModel.getValue<File>("elevationCertificate") === undefined ? "0 10px" : "0 14px"}
                                uploader
                                buttonText="Upload certificate"
                                image={viewModel.getValue<File>("elevationCertificate") === undefined ? iconUploadCertificate : iconUploadedCertificate}
                                {...props}
                                onClick={(files: FileList) => {
                                    viewModel.uploadCertificate(files).then(filename => {
                                        props.onClick(filename);
                                    });
                                }}
                            />
                        </>
                    )}
                </LargeMultichoice>
            </Container>
        </>
    ));
};

const Container = styled.div`
    .date-selects {
        width: auto !important;
        background: none !important;
        border: none !important;
        padding: 0 !important;

        > div:first-of-type {
            margin: auto 60px auto 0;

            @media (max-width: 767px) {
                margin-right: 5px;

                img {
                    width: 32px;
                }
            }
        }
    }

    .MuiBox-root {
        padding: 0;
    }

    .MuiSelect-select.MuiSelect-select {
        padding: 14px 37px 13px 20px;

        @media (max-width: 767px) {
            padding: 8px 25px 8px 7px;
        }
    }

    svg.MuiSelect-icon {
        margin: 0 20px 0 8px;
        top: auto;

        @media (max-width: 767px) {
            margin: 0 10px;
        }
    }

    .MuiInput-underline:before,
    .MuiInput-underline:after {
        content: none !important;
    }

    .MuiInputBase-root {
        margin: 0;
    }

    .MuiInputBase-input {
        font-weight: bold;
    }
`;
