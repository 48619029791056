// Libraries.
import { computed, action, observable, runInAction } from "mobx";

// Core
import { FieldType } from "Core/Utils/Utils";
import { useRouter } from "Core/Utils";
import { ViewModelBase } from "../../Core/ViewModels/ViewModelBase";
import { ApiResult } from "Core/Models";

// Models & Stores
import { StoresInstance } from "../Stores";
import { Server } from "../Globals/AppUrls";
import { FloodDataResponseModel, FloodDataResponseModelDTO } from "../Models/";
//import { stubFalse } from "lodash";

export class FloodDataViewModel extends ViewModelBase<FloodDataResponseModel> {
    constructor() {
        super(new FloodDataResponseModel());
        this.setDecorators(FloodDataResponseModel);
    }

    public isFieldValid(fieldName: keyof FieldType<FloodDataResponseModel>, value: any, showErrors: boolean = true): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        if (showErrors) {
            this.setError(fieldName, errorMessage);
            this.setValid(fieldName, isValid);
        }

        return isValid;
    }

    /**
     * Fema data - simplified
     */
    public async getFloodData() {
        let domainStores = StoresInstance.domain;
        let quoteRequestVM = domainStores.QuoteRequestViewModel;

        const floodDataRequest = {
            latitude: quoteRequestVM.getValue("latitude"),
            longitude: quoteRequestVM.getValue("longitude"),
            incBFE: true,
            getLoma: false,
        };
        let apiResult = await this.Post<FloodDataResponseModelDTO>(Server.Api.Property.GetFloodDetails, floodDataRequest);

        if (apiResult.wasSuccessful) {
            console.log("Payload Success: Fema returned stuff");
            this.model.fromDto(apiResult.payload);

            quoteRequestVM.setValue("fldZone", apiResult.payload.fldZone);
            quoteRequestVM.setValue("communityNumber", apiResult.payload.communityNumber);
            quoteRequestVM.setValue("mapPanel", apiResult.payload.mapPanel);
            quoteRequestVM.setValue("mapSuffix", apiResult.payload.mapSuffix);
        } else {
            console.log("Payload Errors: " + apiResult.errors);
        }
        return apiResult;
    }

    public afterUpdate: any;
    public beforeUpdate: undefined;
}
