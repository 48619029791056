// Libraries.
import { computed, action, observable } from "mobx";

// Core
import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";

// Custom
import { StoresInstance } from "../Globals/Base";
import { CoverageModel } from "../Models/CoverageModel";
import { moneyDollarFormat } from "../Utils/Money";

export class CoverageViewModel extends ViewModelBase<CoverageModel> {
    // #region Building and Property Helper
    @computed
    public get propertyType() {
        // Get Property Type from QuoteRequest
        let quoteRequestVM = StoresInstance.domain.QuoteRequestViewModel;
        let retVal = "";

        if (!quoteRequestVM.getValue<string>("chosenPropertyType")) {
            // set to Single Family Res if old data so it behaves like before but with new coverage options
            retVal = "sfr";
        } else {
            retVal = quoteRequestVM.getValue<string>("chosenPropertyType");
        }

        return retVal;
    }

    @computed
    public get useAlternateDeductible() {
        let retVal = false;

        if (this.propertyType == "commercial" || this.propertyType == "rcbap") {
            retVal = true;
        }

        return retVal;
    }

    // #region Deductible

    @computed
    public get formattedDeductible() {
        //let quoteRequestVM = StoresInstance.domain.QuoteRequestViewModel;
        let deductible = this.model.deductible;
        if (this.useAlternateDeductible) {
            deductible = this.model.deductibleAlternate;
        }
        return moneyDollarFormat(deductible);
    }

    public get deductibleOptionMin() {
        return this.model.deductibleOptionMin;
    }

    public get deductibleOptionMax() {
        return this.model.deductibleOptionMax;
    }

    @computed
    public get formattedAlternateDeductible() {
        return moneyDollarFormat(this.model.deductibleAlternate);
    }

    public get deductibleAlternateOptionMin() {
        return this.model.deductibleAlternateOptionMin;
    }

    public get deductibleAlternateOptionMax() {
        return this.model.deductibleAlternateOptionMax;
    }

    @computed
    public get minDeductible() {
        let retVal = 0;
        if (this.useAlternateDeductible) {
            retVal = this.deductibleAlternateOptionMin;
        } else {
            retVal = this.deductibleOptionMin;
        }
        return retVal;
    }

    @computed
    public get maxDeductible() {
        let retVal = 0;
        if (this.useAlternateDeductible) {
            retVal = this.deductibleAlternateOptionMax;
        } else {
            retVal = this.deductibleOptionMax;
        }
        return retVal;
    }
    // #endregion Deductible

    // #region Building Coverage

    @computed
    public get formattedBuildingCoverage() {
        return moneyDollarFormat(this.model.buildingCoverage);
    }

    public get buildingCoverageMarks() {
        return this.model.buildingCoverageMarks;
    }

    @computed
    public get minBuildingCoverage() {
        let retVal = 0;
        // Get Coverage from Array
        let newVal = CoverageModel.MODEL_DEFAULT_BUILDINGCOVERAGE_ARR.find(cover => cover.key === this.propertyType)?.min;
        if (newVal !== undefined) {
            retVal = newVal;
        }
        return retVal;
    }

    @computed
    public get maxBuildingCoverage() {
        let retVal = 0;
        // Get Coverage from Array
        let newVal = CoverageModel.MODEL_DEFAULT_BUILDINGCOVERAGE_ARR.find(cover => cover.key === this.propertyType)?.max;
        if (newVal !== undefined) {
            retVal = newVal;
        }
        return retVal;
    }

    @computed
    public get maxBuildingCoverageStr() {
        let retVal = "0";
        // Get Coverage from Array
        let newVal = CoverageModel.MODEL_DEFAULT_BUILDINGCOVERAGE_ARR.find(cover => cover.key === this.propertyType)?.maxstr;
        if (newVal !== undefined) {
            retVal = newVal;
        }
        return retVal;
    }

    // #endregion Building Coverage

    // #region Contents Coverage
    @computed
    public get formattedContentsCoverage() {
        return moneyDollarFormat(this.model.contentsCoverage);
    }

    public get contentsCoverageMarks() {
        return this.model.contentsCoverageMarks;
    }

    @computed
    public get maxContentsCoverage() {
        let retVal = 0;
        // Get Coverage from Array
        let newVal = CoverageModel.MODEL_DEFAULT_CONTENTSCOVERAGE_ARR.find(cover => cover.key === this.propertyType)?.max;
        if (newVal !== undefined) {
            retVal = newVal;
        }
        return retVal;
    }

    @computed
    public get maxContentsCoverageStr() {
        let retVal = "0";
        // Get Coverage from Array
        let newVal = CoverageModel.MODEL_DEFAULT_CONTENTSCOVERAGE_ARR.find(cover => cover.key === this.propertyType)?.maxstr;
        if (newVal !== undefined) {
            retVal = newVal;
        }
        return retVal;
    }

    // #endregion Contents Coverage

    constructor() {
        super(new CoverageModel());
        this.setDecorators(CoverageModel);
    }

    public saveToQuoteRequest(): void {
        let quoteRequestVM = StoresInstance.domain.QuoteRequestViewModel;
        let deductible = this.model.deductible;
        if (this.useAlternateDeductible) {
            deductible = this.model.deductibleAlternate;
        }
        let buildingCoverage = quoteRequestVM.getValue<number>("buildingCoverage");
        if (this.buildingCoverChanged) {
            buildingCoverage = this.model.buildingCoverage;
        }
        let contentsCoverage = quoteRequestVM.getValue<number>("contentsCoverage");
        if (this.contentsCoverChanged) {
            contentsCoverage = this.model.contentsCoverage;
        }

        quoteRequestVM.setValue("buildingCoverage", buildingCoverage);
        quoteRequestVM.setValue("contentsCoverage", contentsCoverage);
        quoteRequestVM.setValue("deductible", deductible);
    }

    @observable
    public buildingCoverChanged = false;

    @action
    public setBuildingCoverage = (value: number) => {
        this.model.setValue("buildingCoverage", value);
    };

    @action
    public setBuildingCoverageFormatted = (value: string) => {
        this.model.setValue("buildingCoverageFormatted", value);
    };

    @action
    public setContentsCoverage = (value: number) => {
        this.model.setValue("contentsCoverage", value);
    };

    @action
    public setContentsCoverageFormatted = (value: string) => {
        this.model.setValue("contentsCoverageFormatted", value);
    };

    /**
     * Set and format buildingCoverage and buildingCoverageFormatted values on blur.
     * @param value The value in the input.
     */
    public buildingCoverageOnBlur = (value: string) => {
        // String version of the value with no commas, only numbers. E.g. "750000".
        let strippedValue: string = value.split(",").join("");

        // Incase the user enters only commas.
        if (strippedValue === "") {
            strippedValue = "0";
        }

        // String version of the value, formatted with commas. E.g. "750,000".
        let formattedValue: string = parseInt(strippedValue).toLocaleString();

        // Number version of the value. E.g. 750000.
        let actualValue: number = parseInt(strippedValue);

        // Minimum and maximum values now in an array in the Model as they change subject to Property type chosen
        if (actualValue < this.minBuildingCoverage) {
            this.setBuildingCoverage(this.minBuildingCoverage);
            this.setBuildingCoverageFormatted(this.minBuildingCoverage.toLocaleString());
            //console.log("Min val triggererd: " + actualValue + " < " + this.minBuildingCoverage);
        } else if (actualValue > this.maxBuildingCoverage) {
            this.setBuildingCoverage(this.maxBuildingCoverage);
            this.setBuildingCoverageFormatted(this.maxBuildingCoverageStr);
            //console.log("Max val triggererd: " + actualValue + " > " + this.maxBuildingCoverage);
        } else {
            this.setBuildingCoverage(actualValue);
            this.setBuildingCoverageFormatted(formattedValue);
        }

        this.buildingCoverChanged = true;
    };

    /**
     * Set the buildingCoverageFormatted value as the user types.
     * @param value The value in the input.
     */
    public buildingCoverageOnChange = (value: string) => {
        // Numbers and commas only.
        let regex = /^[0-9,]+$/;

        // Allow the user to enter nothing.
        if (value === "") {
            this.setBuildingCoverageFormatted(value);
        }

        // Only set the value if it only contains numbers and/or commas.
        if (regex.test(value)) {
            this.setBuildingCoverageFormatted(value);

            this.buildingCoverChanged = true;
        }
    };

    @observable
    public contentsCoverChanged = false;

    /**
     * Set and format contentsCoverage and contentsCoverageFormatted values on blur.
     * @param value The value in the input.
     */
    public contentsCoverageOnBlur = (value: string) => {
        // String version of the value with no commas, only numbers. E.g. "750000".
        let strippedValue: string = value.split(",").join("");

        // Incase the user enters only commas.
        if (strippedValue === "") {
            strippedValue = "0";
        }

        // String version of the value, formatted with commas. E.g. "750,000".
        let formattedValue: string = parseInt(strippedValue).toLocaleString();

        // Number version of the value. E.g. 750000.
        let actualValue: number = parseInt(strippedValue);

        // Minimum and maximum values now in an array in the Model as they change subject to Property type chosen
        if (actualValue < 0) {
            this.setContentsCoverage(0);
            this.setContentsCoverageFormatted((0).toLocaleString());
        } else if (actualValue > this.maxContentsCoverage) {
            this.setContentsCoverage(this.maxContentsCoverage);
            this.setContentsCoverageFormatted(this.maxContentsCoverageStr);
        } else {
            this.setContentsCoverage(actualValue);
            this.setContentsCoverageFormatted(formattedValue);
        }

        this.contentsCoverChanged = true;
    };

    /**
     * Set the contentsCoverageFormatted value as the user types.
     * @param value The value in the input.
     */
    public contentsCoverageOnChange = (value: string) => {
        // Numbers and commas only.
        let regex = /^[0-9,]+$/;

        // Allow the user to enter nothing.
        if (value === "") {
            this.setContentsCoverageFormatted(value);
        }

        // Only set the value if it only contains numbers and/or commas.
        if (regex.test(value)) {
            this.setContentsCoverageFormatted(value);
            this.contentsCoverChanged = true;
        }
    };

    @action
    public resetFlags = () => {
        this.contentsCoverChanged = false;
        this.buildingCoverChanged = false;
    }

    public resetAll = () => {
        this.model.resetAll();
    };

    public isFieldValid(fieldName: keyof FieldType<CoverageModel>, value: any): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
