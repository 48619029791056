import React, { useState, useRef } from "react";
import { useMediaQuery } from "../../Core/Utils/Media/mediaQuery";
import { useRouter, useObserver, StoresInstance } from "../Globals/Base";
import { GridSpaced, TitleWrapper, DivWrapper, DivWrapperBGFixed } from "../StylesAppSpecific/GridHelperStyling";
import { HdrImgDisc } from "../StylesAppSpecific/PageStyling";
import { Grid, Button, Checkbox } from "@material-ui/core";
import iconProRegister from "../../Content/icon-proRegister.svg";
import { Container, PartnerContainer } from "../StylesAppSpecific/CarrierSettingsStyling";
import PXFuel from "../../Content/quoteBG.jpg";
import { Footer } from "../Components/Footer/Footer";
import { CarrierSetting } from "../Components/Pro/CarrierSetting";
import { RememberMeLabel } from "Custom/StylesAppSpecific/LoginStyling";
import { NFIPartnerAgreementViewModel } from "Custom/ViewModels";
import SignatureCanvas from "react-signature-canvas";
import ProBackground from "Content/ProBackground.png";
import { AgencyAPIValidationLoading } from "Custom/Components/IsLoading";
import { Redirect } from "react-router-dom";
import { NFIPartnerAgreement } from "../Components/Pro/NFIPartnerAgreement";

export const NFIPartnerAgreementPage: React.FC = () => {
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const isMobile = !!useMediaQuery("(max-width: 767px)");
    const { history } = useRouter();
    const [viewModel] = useState(() => new NFIPartnerAgreementViewModel());
    const signatureRef = useRef<SignatureCanvas>() as React.RefObject<SignatureCanvas>;

    const handleSubmit = async () => {
        if (viewModel.isModelValid()) {
            if (signatureRef.current!.toData().length <= 0) {
                setErrorMessage("You must sign this agreement");
                return;
            }

            const dataUrl = signatureRef.current!.toDataURL();

            setIsLoading(true);

            const result = await StoresInstance.domain.CarrierSettingsViewModel!.upsert();

            if (result.wasSuccessful) {
                const charBytes = atob(dataUrl.split(",")[1]);
                const imageBytes = [];
                for (let i = 0; i < charBytes.length; i++) {
                    imageBytes[i] = charBytes.charCodeAt(i);
                }
                const byteArray = new Uint8Array(imageBytes);
                const imageBlob = new Blob([byteArray], { type: "image/png" });

                const result = await viewModel.accept(imageBlob);
                setIsLoading(false);
                if (result && result.wasSuccessful) {
                    StoresInstance.domain.AccountStore.setHasAcceptedAgencyAgreement(true);
                    history.push(`/agent`);
                } else if (result.errors && result.errors[0] && result.errors[0].message) {
                    setErrorMessage(result.errors[0].message);
                } else {
                    setErrorMessage("An unknown error occured.");
                }
            } else if (result.errors && result.errors[0] && result.errors[0].message) {
                setIsLoading(false);
                setErrorMessage(result.errors[0].message);
            } else {
                setIsLoading(false);
                setErrorMessage("An unknown error occured.");
            }
        }
        //isValid will check all fields to make sure they are in a valid state.
        /* if (viewModel.checkModelValidity()) {
			const result = await viewModel.upsert();
			if (result.wasSuccessful) {
				history.push(`/dashboard`);
			} else if (result.errors && result.errors[0] && result.errors[0].message) {
				setErrorMessage(result.errors[0].message);
			} else {
				setErrorMessage("An unknown error occured.");
			}
		} */
    };

    const contentHdr = (
        <>
            <GridSpaced container margin={"0px auto"} additionalcss={"max-width: 688px; width: 100%;"}>
                <GridSpaced item xs={12} sm={12} additionalcss={"text-align: center;"}>
                    <HdrImgDisc src={iconProRegister} alt={""} />
                </GridSpaced>
            </GridSpaced>

            <GridSpaced container margin={"0px auto"} additionalcss={"width: 100%;"}>
                <Grid item xs={12} sm={12} justify={"center"}>
                    <TitleWrapper padding={"0"} margin={"0"}>
                        <h1>Because you selected to be an NFI Partner Agent</h1>
                    </TitleWrapper>
                </Grid>
            </GridSpaced>
        </>
    );

    if (StoresInstance.domain.CarrierSettingsViewModel === undefined) {
        return <Redirect to="/carrier-settings" />;
    }

    return useObserver(() => (
        <>
            <DivWrapperBGFixed
                additionalcss={
                    "background: linear-gradient(to top, rgb(20, 32, 41) 10%, rgb(0, 0, 0, 0.0)), url(" + ProBackground + ") center center / cover no-repeat;"
                }
            >
                {
                    /* isLoading ? (
					<AgencyAPIValidationLoading />
				) : ( */
                    <div style={{ margin: "0 20px" }}>
                        <DivWrapper
                            margin={"0px auto"}
                            additionalcss={isMobile ? "max-width: 1124px; padding-bottom: 80px;" : "max-width: 1124px; padding-bottom: 100px;"}
                        >
                            <PartnerContainer>
                                {contentHdr}
                                <h2 style={{ fontSize: "24px", margin: "0px" }}>Please review and digitally sign our agency referral agreement.</h2>
                                <NFIPartnerAgreement
                                    errorMessage={errorMessage}
                                    isLoading={isLoading}
                                    onSubmit={handleSubmit}
                                    signatureRef={signatureRef}
                                    viewModel={viewModel}
                                />
                            </PartnerContainer>
                        </DivWrapper>
                    </div>
                    /* ) */
                }
            </DivWrapperBGFixed>
            {/* isLoading ||  */ <Footer isTemp={false} />}
        </>
    ));
};
