// Libraries
import clsx from "clsx";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Grid, Typography, Paper } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

// Content
import Puddles from "Content/homeBG2.png";
import nfiLogo from "Content/national-flood-insurance.png";

// Custom
import { useRouter } from "../../Globals/Base";
import { Footer } from "../../Components/Footer/Footer";
import { useAdminStyles, usePolicyStyles } from "../../StylesAppSpecific/Admin/AdminDashboard";
import { DivWrapper, DivWrapperBGFixed } from "../../StylesAppSpecific/GridHelperStyling";
import { getInsurerLogo } from "../../Utils/InsurerLogo";
import AdminUserReportQuoteViewModel from "../../ViewModels/Admin/AdminUserReportQuoteViewModel";
import { isEmptyOrWhitespace } from "../../../Core/Utils/Utils";
import { getPolicyExtras } from "../../Utils/PolicyExtras";

export const AdminUserReportQuote: React.FC = () => {
    const adminClasses = useAdminStyles();
    const policyClasses = usePolicyStyles();
    const { history, location, match } = useRouter();
    const { id, quoteId } = match.params as any;
    const [viewModel] = useState(() => new AdminUserReportQuoteViewModel(id, quoteId));
    const isSelectedQuote = !isEmptyOrWhitespace(viewModel.policyInternalQuoteNumber);

    useEffect(() => {
        // If the viewmodel is to allow navigation. set the viewmodel router.
        viewModel.setRouter(history, location);

        // With SPAs, we need to move back to the top of the page.
        window.scrollTo(0, 0);
    }, []);

    const headerText = isSelectedQuote ? `${viewModel.policyQuoteNumber} (${viewModel.policyInternalQuoteNumber})` : viewModel.policyQuoteNumber;

    // #region Policy Header

    const renderPolicyHeaderTitle = () => {
        return (
            <React.Fragment>
                <Grid className={clsx(policyClasses.gridItem, policyClasses.gridItemFlex)} item>
                    <Typography className={policyClasses.h1} component="h1" variant="h2">
                        You have selected the following policy: <span className={policyClasses.headerSummaryQuoteNumber}>{headerText}</span>
                    </Typography>
                </Grid>
            </React.Fragment>
        );
    };

    const renderPolicyHeaderSummary = () => {
        return (
            <React.Fragment>
                <Grid className={clsx(policyClasses.gridItem, policyClasses.gridItemFlex)} item>
                    <Box className={policyClasses.headerSummary}>
                        <Box className={policyClasses.headerSummaryImage}>
                            <img alt={viewModel.policyName} src={getInsurerLogo(viewModel.policyName)} />
                        </Box>
                        <Divider className={policyClasses.verticalSolidDivider} orientation="vertical" />
                        <Box className={policyClasses.headerSummaryInformation}>
                            <Grid className={policyClasses.headerSummaryInformationContainer} container>
                                <Grid xs={12} item>
                                    <Typography className={policyClasses.headerSummaryInsurer}>{viewModel.policyName}</Typography>
                                </Grid>
                            </Grid>
                            <Grid className={policyClasses.headerSummaryInformationContainer} container>
                                <Grid xs={12} md={6} item>
                                    <Typography className={policyClasses.headerSummaryPremium}>Premium: {viewModel.policyPremium}</Typography>
                                </Grid>
                                <Grid xs={12} md={6} item>
                                    <Typography className={policyClasses.headerSummaryDeductible}>Deductible: {viewModel.policyDeductible}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </React.Fragment>
        );
    };

    const renderPolicyExtras = () => {
        if (!!viewModel.quote) {
            return Object.keys(viewModel.quote).map(key => {
                if (!!viewModel.quote && viewModel.quote.hasOwnProperty(key)) {
                    const value = viewModel.quote[key];
                    let bodyText = getPolicyExtras(key, value);

                    if (!isEmptyOrWhitespace(bodyText)) {
                        return (
                            <Grid className={policyClasses.headerPrivateQuoteDetailsItem} item xs={12} sm={6}>
                                <Typography className={policyClasses.headerDetailsItemText}>{bodyText}</Typography>
                            </Grid>
                        );
                    } else {
                        return <React.Fragment />;
                    }
                } else {
                    return <React.Fragment />;
                }
            });
        } else {
            return <React.Fragment />;
        }
    };

    const renderPrivateQuoteDetails = () => {
        return (
            <Box>
                <Box className={clsx(policyClasses.headerDetailsTitleBox, policyClasses.headerPrivateQuoteDetailsTitleBox)}>
                    <Typography className={policyClasses.headerDetailsTitle}>Policy extras</Typography>
                </Box>
                <Grid className={policyClasses.headerPrivateQuoteDetailsTitleContainer} container>
                    {renderPolicyExtras()}
                </Grid>
            </Box>
        );
    };

    const renderNFIPQuoteDetails = () => {
        return (
            <Box>
                <Box className={policyClasses.headerDetailsTitleBox}>
                    <img alt={viewModel.policyName} src={nfiLogo} />
                    <Typography className={policyClasses.headerDetailsTitle}>NFIP policy</Typography>
                </Box>
                <Box>
                    <Typography className={policyClasses.headerDetailsItemText}>
                        The National Flood Insurance Program (NFIP) is administered by the Federal Emergency Management Administration (FEMA). Flood insurance
                        offered through the NFIP is based on set rates that do not vary from carrier to carrier. The NFIP has maximum residential coverage
                        limits of $250,000 for building and $100,000 for contents.
                    </Typography>
                </Box>
            </Box>
        );
    };

    const renderPolicyHeaderDetails = () => {
        return (
            <React.Fragment>
                <Grid className={clsx(policyClasses.gridItem, policyClasses.gridItemFlex)} item>
                    {viewModel.isNFIPQuote ? renderNFIPQuoteDetails() : renderPrivateQuoteDetails()}
                </Grid>
            </React.Fragment>
        );
    };

    // #endregion Policy Header

    // #region Policy Body

    const renderPolicyBodyPolicyHolder = () => {
        return (
            <React.Fragment>
                <Grid className={clsx(policyClasses.gridItem, policyClasses.gridItemFlex)} item>
                    <Typography className={clsx(policyClasses.h3, policyClasses.title)} component="h3" variant="h2">
                        Policy holder
                    </Typography>
                    <Grid container>
                        <Typography className={clsx(policyClasses.paragraphNormal, policyClasses.contactParagraph)}>{viewModel.policyHolderName}</Typography>
                        <Typography className={clsx(policyClasses.paragraphNormal, policyClasses.contactParagraph)}>
                            <b>Phone: </b> {viewModel.policyHolderPhoneNumber}
                        </Typography>
                        <Typography className={clsx(policyClasses.paragraphNormal, policyClasses.contactParagraph)}>
                            <b>Email: </b> {viewModel.policyHolderEmailAddress}
                        </Typography>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    };

    const renderPolicyBodyPropertyToBeInsured = () => {
        return (
            <React.Fragment>
                <Grid className={clsx(policyClasses.gridItem, policyClasses.gridItemFlex)} item>
                    <Typography className={clsx(policyClasses.h3, policyClasses.title)} component="h3" variant="h2">
                        Property to be insured
                    </Typography>
                    <Typography className={clsx(policyClasses.paragraphNormal, policyClasses.addressParagraph)}>{viewModel.propertyAddress}</Typography>
                    <Grid className={policyClasses.gridContainerRowSpacing} container>
                        <Grid className={policyClasses.labelGridItem} item>
                            <Typography className={policyClasses.label}>What year was the property built?</Typography>
                        </Grid>
                        <Grid className={clsx(policyClasses.paragraphGridItem, policyClasses.paragraphGridItemWide)} item>
                            <Typography className={policyClasses.paragraphNormal}>{viewModel.propertyConstructionYear}</Typography>
                        </Grid>
                    </Grid>
                    <Grid className={policyClasses.gridContainerRowSpacing} container>
                        <Grid className={policyClasses.labelGridItem} item>
                            <Typography className={policyClasses.label}>How many stories does the property have?</Typography>
                        </Grid>
                        <Grid className={clsx(policyClasses.paragraphGridItem, policyClasses.paragraphGridItemWide)} item>
                            <Typography className={policyClasses.paragraphNormal}>{viewModel.propertyStories}</Typography>
                        </Grid>
                    </Grid>
                    <Grid className={policyClasses.gridContainerRowSpacing} container>
                        <Grid className={policyClasses.labelGridItem} item>
                            <Typography className={policyClasses.label}>What square footage is the property?</Typography>
                        </Grid>
                        <Grid className={clsx(policyClasses.paragraphGridItem, policyClasses.paragraphGridItemWide)} item>
                            <Typography className={policyClasses.paragraphNormal}>{viewModel.propertySize}</Typography>
                        </Grid>
                    </Grid>
                    <Grid className={policyClasses.gridContainerRowSpacing} container>
                        <Grid className={policyClasses.labelGridItem} item>
                            <Typography className={policyClasses.label}>Is the property partially or completely over water?</Typography>
                        </Grid>
                        <Grid className={clsx(policyClasses.paragraphGridItem, policyClasses.paragraphGridItemWide)} item>
                            <Typography className={policyClasses.paragraphNormal}>{viewModel.propertyIsOverWater}</Typography>
                        </Grid>
                    </Grid>
                    <Grid className={policyClasses.gridContainerRowSpacing} container>
                        <Grid className={policyClasses.labelGridItem} item>
                            <Typography className={policyClasses.label}>Is the property a mobile or manufactured home?</Typography>
                        </Grid>
                        <Grid className={clsx(policyClasses.paragraphGridItem, policyClasses.paragraphGridItemWide)} item>
                            <Typography className={policyClasses.paragraphNormal}>{viewModel.propertyIsMobileHome}</Typography>
                        </Grid>
                    </Grid>
                    <Grid className={policyClasses.gridContainerRowSpacing} container>
                        <Grid className={policyClasses.labelGridItem} item>
                            <Typography className={policyClasses.label}>Does the property have a garage?</Typography>
                        </Grid>
                        <Grid className={clsx(policyClasses.paragraphGridItem, policyClasses.paragraphGridItemWide)} item>
                            <Typography className={policyClasses.paragraphNormal}>{viewModel.propertyHasGarage}</Typography>
                        </Grid>
                    </Grid>
                    <Grid className={policyClasses.gridContainerRowSpacing} container>
                        <Grid className={policyClasses.labelGridItem} item>
                            <Typography className={policyClasses.label}>What is the property constructed with?</Typography>
                        </Grid>
                        <Grid className={clsx(policyClasses.paragraphGridItem, policyClasses.paragraphGridItemWide)} item>
                            <Typography className={policyClasses.paragraphNormal}>{viewModel.propertyConstructionType}</Typography>
                        </Grid>
                    </Grid>
                    <Grid className={policyClasses.gridContainerRowSpacing} container>
                        <Grid className={policyClasses.labelGridItem} item>
                            <Typography className={policyClasses.label}>What foundation type is used on the property?</Typography>
                        </Grid>
                        <Grid className={clsx(policyClasses.paragraphGridItem, policyClasses.paragraphGridItemWide)} item>
                            <Typography className={policyClasses.paragraphNormal}>{viewModel.propertyFoundationType}</Typography>
                        </Grid>
                    </Grid>
                    <Grid className={policyClasses.gridContainerRowSpacing} container>
                        <Grid className={policyClasses.labelGridItem} item>
                            <Typography className={policyClasses.label}>Does this property have an enclosure / basement?</Typography>
                        </Grid>
                        <Grid className={clsx(policyClasses.paragraphGridItem, policyClasses.paragraphGridItemWide)} item>
                            <Typography className={policyClasses.paragraphNormal}>{viewModel.propertyHasEnclosure}</Typography>
                        </Grid>
                    </Grid>
                    <Grid className={policyClasses.gridContainerRowSpacing} container>
                        <Grid className={policyClasses.labelGridItem} item>
                            <Typography className={policyClasses.label}>This property is:</Typography>
                        </Grid>
                        <Grid className={clsx(policyClasses.paragraphGridItem, policyClasses.paragraphGridItemWide)} item>
                            <Typography className={policyClasses.paragraphNormal}>{viewModel.propertyNewProperty}</Typography>
                        </Grid>
                    </Grid>
                    <Grid className={policyClasses.gridContainerRowSpacing} container>
                        <Grid className={policyClasses.labelGridItem} item>
                            <Typography className={policyClasses.label}>When do you want the policy coverage to start from?</Typography>
                        </Grid>
                        <Grid className={clsx(policyClasses.paragraphGridItem, policyClasses.paragraphGridItemWide)} item>
                            <Typography className={policyClasses.paragraphNormal}>{viewModel.propertyPolicyEffectiveDate}</Typography>
                        </Grid>
                    </Grid>
                    <Grid className={policyClasses.gridContainerRowSpacing} container>
                        <Grid className={policyClasses.labelGridItem} item>
                            <Typography className={policyClasses.label}>I own and occupy this property</Typography>
                        </Grid>
                        <Grid className={clsx(policyClasses.paragraphGridItem, policyClasses.paragraphGridItemWide)} item>
                            <Typography className={policyClasses.paragraphNormal}>{viewModel.propertyOwnershipType}</Typography>
                        </Grid>
                    </Grid>
                    <Grid className={policyClasses.gridContainerRowSpacing} container>
                        <Grid className={policyClasses.labelGridItem} item>
                            <Typography className={policyClasses.label}>For how much of the next year will you occupy this property?</Typography>
                        </Grid>
                        <Grid className={clsx(policyClasses.paragraphGridItem, policyClasses.paragraphGridItemWide)} item>
                            <Typography className={policyClasses.paragraphNormal}>{viewModel.propertyOccupy}</Typography>
                        </Grid>
                    </Grid>
                    <Grid className={policyClasses.gridContainerRowSpacing} container>
                        <Grid className={policyClasses.labelGridItem} item>
                            <Typography className={policyClasses.label}>Do you currently have a flood insurance policy?</Typography>
                        </Grid>
                        <Grid className={clsx(policyClasses.paragraphGridItem, policyClasses.paragraphGridItemWide)} item>
                            <Typography className={policyClasses.paragraphNormal}>{viewModel.propertyInsurancePolicy}</Typography>
                        </Grid>
                    </Grid>
                    <Grid className={policyClasses.gridContainerRowSpacing} container>
                        <Grid className={policyClasses.labelGridItem} item>
                            <Typography className={policyClasses.label}>Elevation certificate</Typography>
                        </Grid>
                        <Grid className={clsx(policyClasses.paragraphGridItem, policyClasses.paragraphGridItemWide)} item>
                            <Typography className={policyClasses.paragraphNormal}>{viewModel.propertyElevationCertificate}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    };

    const renderPolicyBodyPropertyCoverages = () => {
        return (
            <React.Fragment>
                <Grid className={clsx(policyClasses.gridItem, policyClasses.gridItemFlex)} item>
                    <Typography className={clsx(policyClasses.h3, policyClasses.title)} component="h3" variant="h2">
                        Property coverages
                    </Typography>
                    <Grid className={policyClasses.gridContainerRowSpacing} container>
                        <Grid className={policyClasses.labelGridItem} item>
                            <Typography className={policyClasses.label}>Building coverage:</Typography>
                        </Grid>
                        <Grid className={policyClasses.paragraphGridItem} item>
                            <Typography className={policyClasses.paragraph}>$</Typography>
                            <Typography className={policyClasses.paragraph}>{viewModel.buildingCoverage}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid className={policyClasses.labelGridItem} item>
                            <Typography className={policyClasses.label}>Contents coverage:</Typography>
                        </Grid>
                        <Grid className={policyClasses.paragraphGridItem} item>
                            <Typography className={policyClasses.paragraph}>$</Typography>
                            <Typography className={policyClasses.paragraph}>{viewModel.contentCoverage}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    };

    const renderPolicyBodyDeductibles = () => {
        return (
            <React.Fragment>
                <Grid className={clsx(policyClasses.gridItem, policyClasses.gridItemFlex)} item>
                    <Typography className={clsx(policyClasses.h3, policyClasses.title)} component="h3" variant="h2">
                        Deductibles
                    </Typography>
                    <Grid container>
                        <Grid className={policyClasses.labelGridItem} item>
                            <Typography className={clsx(policyClasses.label, policyClasses.deductibleColor)}>Deductible:</Typography>
                        </Grid>
                        <Grid className={policyClasses.paragraphGridItem} item>
                            <Typography className={clsx(policyClasses.paragraph, policyClasses.deductibleColor)}>$</Typography>
                            <Typography className={clsx(policyClasses.paragraph, policyClasses.deductibleColor)}>{viewModel.deductible}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    };

    const renderPolicyBodyPremiumBreakdown = () => {
        return (
            <React.Fragment>
                <Grid className={clsx(policyClasses.gridItem, policyClasses.gridItemFlex)} item>
                    <Typography className={clsx(policyClasses.h3, policyClasses.title)} component="h3" variant="h2">
                        Premium breakdown
                    </Typography>
                    <Grid className={policyClasses.gridContainerRowSpacing} container>
                        <Grid className={policyClasses.labelGridItem} item>
                            <Typography className={policyClasses.label}>Building/Contents premium:</Typography>
                        </Grid>
                        <Grid className={policyClasses.paragraphGridItem} item>
                            <Typography className={policyClasses.paragraph}>$</Typography>
                            <Typography className={policyClasses.paragraph}>{viewModel.premium}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid className={policyClasses.labelGridItem} item>
                            <Typography className={policyClasses.label}>Taxes & Fees:</Typography>
                        </Grid>
                        <Grid className={policyClasses.paragraphGridItem} item>
                            <Typography className={policyClasses.paragraph}>$</Typography>
                            <Typography className={policyClasses.paragraph}>{viewModel.taxesAndFees}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    };

    const renderPolicyBodyAnnualPremium = () => {
        return (
            <React.Fragment>
                <Grid className={clsx(policyClasses.totalGridItem, policyClasses.gridItemFlex)} item>
                    <Grid container>
                        <Grid className={policyClasses.labelGridItem} item>
                            <Typography className={policyClasses.h3} component="h3" variant="h2">
                                Total annual premium:
                            </Typography>
                        </Grid>
                        <Grid className={policyClasses.paragraphGridItem} item>
                            <Typography className={policyClasses.h3} component="h3" variant="h2">
                                $
                            </Typography>
                            <Typography className={policyClasses.h3} component="h3" variant="h2">
                                {viewModel.total}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    };

    // #endregion Policy Body

    // #region Policy Parts

    const renderPolicyHeader = () => {
        return (
            <React.Fragment>
                <Grid xs={12} item>
                    <Grid container>
                        <Grid xs={12} item>
                            <Grid container>{renderPolicyHeaderTitle()}</Grid>
                            <Divider className={policyClasses.solidDivider} />
                            <Grid container>{renderPolicyHeaderSummary()}</Grid>
                            <Divider className={policyClasses.dottedDivider} />
                            <Grid container>{renderPolicyHeaderDetails()}</Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    };

    const renderPolicyBody = () => {
        return (
            <React.Fragment>
                <Grid xs={12} item>
                    <Grid container>
                        <Grid xs={12} item>
                            <Grid container>{renderPolicyBodyPolicyHolder()}</Grid>
                            <Divider className={policyClasses.dottedDivider} />
                            <Grid container>{renderPolicyBodyPropertyToBeInsured()}</Grid>
                            <Divider className={policyClasses.dottedDivider} />
                            <Grid container>{renderPolicyBodyPropertyCoverages()}</Grid>
                            <Divider className={policyClasses.dottedDivider} />
                            <Grid container>{renderPolicyBodyDeductibles()}</Grid>
                            <Divider className={policyClasses.dottedDivider} />
                            <Grid container>{renderPolicyBodyPremiumBreakdown()}</Grid>
                            <Divider className={policyClasses.solidDivider} />
                            <Grid container>{renderPolicyBodyAnnualPremium()}</Grid>
                            <Divider className={policyClasses.solidDivider} />
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    };

    // #endregion Policy Parts

    // #region Page Parts

    const renderBackButtons = () => {
        return (
            <React.Fragment>
                <Grid xs={12} item>
                    <Button
                        className={clsx(adminClasses.navigationButton, adminClasses.navigationGroupUnderButton)}
                        onClick={viewModel.backToDashboard}
                        startIcon={<ChevronLeftIcon />}
                        variant="contained"
                    >
                        Back to reports
                    </Button>
                    <Button
                        className={clsx(adminClasses.navigationButton, adminClasses.navigationGroupMiddleUnderButton)}
                        onClick={viewModel.backToUserReport}
                        startIcon={<ChevronLeftIcon />}
                        variant="contained"
                    >
                        {viewModel.name}
                    </Button>
                    <Button
                        className={clsx(adminClasses.navigationButton, adminClasses.navigationGroupOverButton)}
                        onClick={viewModel.backToQuotes}
                        startIcon={<ChevronLeftIcon />}
                        variant="contained"
                    >
                        Back to your quotes
                    </Button>
                </Grid>
            </React.Fragment>
        );
    };

    const renderPolicy = () => {
        return (
            <React.Fragment>
                <Grid xs={12} item>
                    <Paper className={policyClasses.paper}>
                        <Grid className={policyClasses.headerAndBody} container>
                            <Grid className={policyClasses.header} xs={12} item>
                                {renderPolicyHeader()}
                            </Grid>
                            <Grid className={policyClasses.body} xs={12} item>
                                {renderPolicyBody()}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </React.Fragment>
        );
    };

    // #endregion Page Parts

    return useObserver(() => (
        <React.Fragment>
            <DivWrapperBGFixed additionalcss={"background-image: url(" + Puddles + ");"} className={adminClasses.root}>
                <Box>
                    <Box>
                        <Grid container spacing={5}>
                            {renderBackButtons()}
                            {renderPolicy()}
                        </Grid>
                    </Box>
                </Box>
            </DivWrapperBGFixed>
            <Footer isTemp={false} />
        </React.Fragment>
    ));
};
