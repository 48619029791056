import React from "react";
import { useObserver } from "mobx-react-lite";
import AdminDashboardViewModel from "../../ViewModels/Admin/AdminDashboardViewModel";
import { BindingRestrictionsStyling } from "./BindingRestrictions.styling";
import { Button, Checkbox, FormControlLabel, TextField, Typography } from "@material-ui/core";
import radioYes from "Content/radioYes.svg";
import radioYesChecked from "Content/radioYes-checked.svg";

interface IProps {
    viewModel: AdminDashboardViewModel;
}

export const BindingRestrictions: React.FC<IProps> = ({ viewModel }) => {
    return useObserver(() => (
        <BindingRestrictionsStyling>
            <Typography variant="h2" component="p">
                Binding restrictions
            </Typography>
            <Typography variant="body2">Please add the binding restriction message in the text box below</Typography>
            <div>
                <TextField variant="outlined" value={viewModel.warningMessage} onChange={e => viewModel.setWarningMessage(e.target.value)} />
                <Button variant="contained" onClick={viewModel.saveWarningMessage} disabled={!viewModel.warningMessageDirty}>
                    Save
                </Button>
            </div>
            <FormControlLabel
                label="Show on message on website"
                control={
                    <Checkbox
                        icon={<img src={radioYes} alt="Y" />}
                        checkedIcon={<img src={radioYesChecked} alt="N" />}
                        checked={viewModel.showWarningBanner}
                        onChange={viewModel.saveShowWarningBanner}
                        disabled={viewModel.warningMessageDirty}
                    />
                }
            />
            {viewModel.warningMessageError && <p style={{ color: "red" }}>{viewModel.warningMessageError}</p>}
        </BindingRestrictionsStyling>
    ));
};
