// Libraries
import { action, computed, observable, runInAction } from "mobx";

// Core
import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";

// Custom
import { StoresInstance } from "../../Globals/Base";
import { RealtorDetailModel, RealtorDetailModelDTO } from "../../Models/RealtorDetailModel";
import { Server } from "Custom/Globals/AppUrls";

export class RealtorDetailViewModel extends ViewModelBase<RealtorDetailModel> {
    constructor() {
        super(new RealtorDetailModel());
        this.setDecorators(RealtorDetailModel);
    }

    public ResponseModel = new RealtorDetailModel();

    public load = async (): Promise<void> => {
        try {
            this.setIsLoading(true);

            const apiResult = await this.Post<RealtorDetailModelDTO>(Server.Api.Realtor.GetRealtorDetail);

            if (apiResult.wasSuccessful) {
                this.model.fromDto(apiResult.payload);
            } else {
                this.setIsErrored(true);
            }
        } catch (exception) {
            this.setIsErrored(true);
        } finally {
            this.setIsLoading(false);
        }
    };

    public UpsertRealtorDetailAsync = async (): Promise<boolean> => {
        let apiResult = await this.Post<RealtorDetailModel>(Server.Api.Realtor.UpsertRealtorDetail, this.getModel);
        runInAction(() => {
            if (apiResult.wasSuccessful) {
                this.ResponseModel = new RealtorDetailModel();
                this.ResponseModel.fromDto(apiResult.payload);
            }
        });

        return apiResult.wasSuccessful;
    };

    @action
    public setFromLogin(name: string, logo: string) {
        this.setValue("name", name);
        this.setValue("logo", logo);
    }

    @observable
    public uploadedLogo: boolean = false;

    @observable
    public currentLogo: string = "";

    @observable
    public uploadImgData: null | string = null;

    @action
    public setUploadLogo(files: FileList | null) {
        if (!files) {
            this.uploadImgData = null;
            this.setValue("logo", "");
            return;
        }

        this.uploadImgData = URL.createObjectURL(files[0]);

        // If its >~3.5MB (when converted bo base64) don't bother persisting - ~5MB limit
        if (files[0].size <= 2446677) {
            const b64FileReader = new FileReader();

            b64FileReader.addEventListener("load", e => {
                this.setValue("logo", e.target!.result);
            });

            b64FileReader.readAsDataURL(files[0]);
            this.uploadedLogo = true;
        } else {
            this.setValue("logo", "");
        }
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<RealtorDetailModel>, value: any, showErrors: boolean = true): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }
}
