// Libraries
import React, { useEffect } from "react";
import { useObserver } from "../../Globals/Base";

// Styling & images
import { StyledDiv, ContactDetailStyle2, UploaderWrapper } from "../../StylesAppSpecific/ContactForms";

// App
import { RealtorDetailViewModel } from "../../ViewModels/Realtor/RealtorDetailViewModel";
import { RealtorDetailModel } from "../../Models/RealtorDetailModel";

// Components
import { EditableInput } from "Core/Components/EditableInput";

interface IProps {
    viewModel: RealtorDetailViewModel;
    noBackground?: boolean;
    hideErrorBorder?: boolean;
    isEditor: boolean;
}

export const RealtorDetails: React.FC<IProps> = props => {
    const realtorDetailViewModel = props.viewModel;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return useObserver(() => (
        <>
            <h2 className="contact">Business name</h2>
            <StyledDiv className="input-wrapper" isValid={true}>
                <EditableInput<RealtorDetailModel>
                    viewModel={realtorDetailViewModel}
                    type="text"
                    fieldName="name"
                    fullwidth
                    inputProps={{ placeholder: "Business name", autoComplete: "new-password" }}
                />
            </StyledDiv>
            {props.isEditor ? (
                <>
                    <h2 className="contact" style={{ marginTop: "20px" }}>
                        Realtor logo
                    </h2>
                </>
            ) : (
                <>
                    <h2 className="contact">Realtor logo</h2>
                </>
            )}
            <UploaderWrapper className="upload-wrapper">
                {realtorDetailViewModel.uploadedLogo || realtorDetailViewModel.getValue("logo") ? (
                    <>
                        <img src={realtorDetailViewModel.getValue("logo")} />
                        <p className="upload-placeholder">Please click or drag your logo here to update</p>
                    </>
                ) : (
                    <>
                        <p className="upload-placeholder">Please click or drag your logo on here to add</p>
                    </>
                )}
                <input type="file" accept="image/*" onChange={e => realtorDetailViewModel.setUploadLogo(e.target.files)} />
            </UploaderWrapper>
            <div className="contact-bottom" />
        </>
    ));
};
