export const Client = {
    Root: "/",
};

export const Server = {
    Api: {
        Account: {
            Login: "./api/account/login",
            Logout: "./api/account/logout",
            ResetPassword: {
                Reset: "./api/account/resetpassword",
                VerifyToken: "./api/account/resetpasswordtoken",
            },
            SetPassword: "./api/account/setpassword",
            ForgotPassword: "./api/account/forgotpassword",
            TryRegisterAndLogin: "api/account/tryregisterandlogin",
            ProRegister: "api/account/registerpro",
            RealtorRegister: "./api/account/registerrealtor",
            UpsertUser: "./api/user/Upsert",
            UpsertDetails: "./api/account/upsertdetails",
            UpsertDetailsWithRealtor: "./api/account/upsertdetailswithrealtor",
            UpdateProAccountDetails: "./api/account/updateproaccountdetails",
        },
        Admin: {
            GetAdminQuotes: "./api/admin/getadminquotes",
            GetStatsAndLatestUserQuotes: "./api/admin/getstatsandlatestuserquotes",
            GetUserAndQuotes: "./api/admin/getuserandquotes",
            GetQuotesByWidgetId: "./api/admin/getquotesbywidgetid",
            GetUserAndQuoteResult: "./api/admin/getuserandquoteresult",
            ReRunQuote: "./api/admin/rerunquote",
        },
        Agent: {
            GetLatestUserQuotes: "./api/agent/getagentlatestuserquotes",
            GetUserAndQuotes: "./api/agent/getagentuserandquotes",
            DeletePropertyQuotes: "api/agent/DeleteUserPropertyQuotes",
            DeletePropertyQuote: "api/agent/DeleteUserPropertyQuote",
            GetUserAndQuoteResult: "./api/agent/getuserandquoteresult",
            GetUserAgencyDetails: "./api/agency/getuseragencydetails",
            UpdateUserAgencyDetail: "./api/agency/updateuseragencydetail",
        },
        Agency: {
            GetAgencyDetail: "./api/agency/getagencydetail",
            GetAgreementPDFUrl: "./api/agency/getagreementpdfurl",
        },
        Contact: {
            SetContact: "./api/contact/upsertcontact",
            UpsertContactUs: "./api/contact/upsertcontactus",
            GetContact: "./api/user/getuserbyidforcontact",
        },
        Pro: {
            AgencyUpsert: "./api/agency/updateuseragencydetails",
            UploadSignature: "./api/agency/uploadsignature",
            ValidateAgencyDetails: "./api/agency/validateagencydetails",
        },
        Realtor: {
            GetRealtorDetail: "./api/realtor/getrealtordetail",
            UpsertRealtorDetail: "./api/realtor/UpsertRealtorDetail",
        },
        Property: {
            GetPropertyDetails: "./api/property/getpropertydetails",
            GetFloodDetails: "./api/flooddata/GetFemaData",
            SetPropertyQuote: "./api/propertyquote/updatepropertyquote",
        },
        Dashboard: {
            GetLastUserPropertyQuotes: "api/dashboard/getlastuserpropertyquotes",
            GetUserPropertyQuoteById: "api/dashboard/GetPropertyQuoteById",
            DeletePropertyQuote: "api/propertyquote/DeleteUserPropertyQuote",
        },
        Quotes: {
            GetQuotes: "api/quote/getquotes",
            GetQuotePDF: "api/quote/GetQuotePDF",
            GetQuoteSummaryPDF: "api/quote/MFIquotesummary",
            EmailInfo: "api/quote/EmailQuote",
            EmailQuotes: "api/quote/EmailQuotesFromBlob",
        },
        Pages: {
            StatesList: "./api/pages/getallstates",
            StateItem: "./api/pages/getstate",
            SetState: "./api/pages/upsertstate",
            CitiesList: "./api/pages/getallcities",
            CitiesByStatesId: "./api/pages/getallbystatesid",
            CityItem: "./api/pages/getcity",
            SetCity: "./api/pages/upsertcity",
            NavigationLinks: "./api/pages/getnavigationlinks",
        },
        LiteFormEmail: {
            PostLiteFormAsync: "/api/LiteForm/PostLiteFormAsync",
        },
        Upload: {
            UploadFile: "/api/upload/uploadfile",
        },
        WarningBanner: {
            GetWarningBanner: "/api/warningbanner/getwarningbanner",
            UpsertWarningBanner: "/api/warningbanner/upsertwarningbanner",
        },
        Widgets: {
            GetAllWidgets: "/api/widget/getallwidgets",
            GetWidgetById: "/api/widget/getwidgetbyid",
            UpsertWidget: "/api/widget/upsertwidget",
        },
    },
};

export const Admin = {
    Root: "/admin",
    Users: {
        GetAll: "./api/user/getall",
        GetRoles: "./api/user/GetUserRoles",
        ResetPassword: "./api/user/resetpassword",
    },
    Roles: {
        GetAll: "./api/roles/getall",
        UpsertRole: "./api/roles/upsert",
        GetAllAccessLevelOptions: "./api/roles/getallaccessleveloptions",
    },
};
