// Libraries
import React, { useContext } from "react";
import { Switch, Route } from "react-router-dom";

// Core
import { useRouter } from "Core/Utils/Hooks/UseReactRouter";

// Custom
import { Stores, StoresContext } from "./Custom/Stores";
import { AgentDashboard } from "./Custom/Views/Agent/AgentDashboard";
import { AgentProfile } from "./Custom/Views/Agent/AgentProfile";
import { AgentReports } from "./Custom/Views/Agent/AgentReports";
import { AgentMyQuotes } from "./Custom/Views/Agent/AgentMyQuotes";

export const AgentRoutes: React.FC = () => {
    const { match } = useRouter();
    const store = useContext<Stores>(StoresContext);

    return (
        <Switch>
            {/* Agent: Home Page and Dashboard */}
            <Route
                exact
                path={`${match.url}/`}
                component={AgentDashboard}
                /* isAllowed={() => {
				return store.domain.AccountStore.IsLoggedIn && store.domain.AccountStore.IsProAccount;
			}} */
            />

            {/* Agent: Reports */}
            <Route
                exact
                path={`${match.url}/report`}
                component={AgentReports}
                //isAllowed={() => {
                //	return store.domain.AccountStore.IsLoggedIn && store.domain.AccountStore.IsProAccount;
                //}
            />

            {/* Agent: Profile */}
            <Route
                exact
                path={`${match.url}/profile`}
                component={AgentProfile}
                //isAllowed={() => {
                //	return store.domain.AccountStore.IsLoggedIn && store.domain.AccountStore.IsProAccount;
                //}
            />

            {/* Agent: MyQuotes */}
            <Route
                exact
                path={`${match.url}/myquotes`}
                component={AgentMyQuotes}
                //isAllowed={() => {
                //	return store.domain.AccountStore.IsLoggedIn && store.domain.AccountStore.IsProAccount;
                //}
            />
        </Switch>
    );
};
