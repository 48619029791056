import { ResetPasswordModel, ResetPasswordModelDTO } from "../Models";
import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";

export class PCDPasswordViewModel extends ViewModelBase<ResetPasswordModel> {
    constructor() {
        super(new ResetPasswordModel());
        this.setDecorators(ResetPasswordModel);
    }

    public get isConfirmPasswordValid(): string {
        if (this.getValue("newPassword") !== this.getValue("confirmPassword") && this.getValue("newPassword") !== "") return "Passwords must match";
        return "";
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<ResetPasswordModel>, value: any, showErrors: boolean = true): boolean {
        let { isValid, errorMessage } = this.validateDecorators(fieldName);

        if (isValid && fieldName === "confirmPassword") {
            errorMessage = this.isConfirmPasswordValid;
            isValid = errorMessage === "";
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }
}
