import React, { useEffect, useState } from "react";
import QuoteBackground from "Content/quoteBG.jpg";
import { DivWrapper, DivWrapperBGFixed } from "Custom/StylesAppSpecific/GridHelperStyling";
import { ContactDetailStyle2 } from "Custom/StylesAppSpecific/ContactForms";
import { DashboardContentContainer, DashboardHeaderContainer } from "./Dashboard";
import { StoresInstance } from "../Stores/Stores";
import { ContactForm } from "../Components/ContactForm/Contact";
import { ContactDetail, RealtorDetails } from "../Components/ContactForm";
import { ResetPasswordViewModel } from "../ViewModels/ResetPasswordViewModel";
import { EditableInput } from "Core/Components/EditableInput";
import { ResetPasswordModel } from "Custom/Models";
import { styled, useRouter } from "../Globals/Base";
import { Button } from "@material-ui/core";
import { ShowPasswordIcon2, ShowPasswordContainer } from "Custom/StylesAppSpecific/LoginStyling";
import { DetailsStyles } from "Custom/StylesAppSpecific/EditDashboardStyling";
import EyeClosed from "Content/icon-Eye-closed.svg";
import EyeOpen from "Content/icon-Eye-open.svg";

export const EditAccountDetails: React.FC = () => {
    const domainStores = StoresInstance.domain;
    const contactViewModel = domainStores.ContactViewModel;
    const accountStore = domainStores.AccountStore;
    const [passwordViewModel] = useState(() => new ResetPasswordViewModel());
    const { history } = useRouter();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const realtorDetailVM = domainStores.RealtorDetailViewModel;

    useEffect(() => {
        contactViewModel.setValue("email", accountStore.Email);
        contactViewModel.setValue("firstName", accountStore.FirstName);
        contactViewModel.setValue("lastName", accountStore.LastName);
        contactViewModel.setValue("tel", accountStore.Phone);

        if (domainStores.AccountStore.IsRealtorAccount) {
            let name = accountStore.RealtorName ? accountStore.RealtorName : "";
            let logo = accountStore.RealtorLogo ? accountStore.RealtorLogo : "";
            let _ = realtorDetailVM.setFromLogin(name, logo);
        }
    }, []);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        contactViewModel.setValue("terms", true);
        let passwordValid = true;
        if (passwordViewModel.getValue("newPassword") || passwordViewModel.getValue("confirmPassword")) {
            passwordValid = passwordViewModel.isModelValid();
        }
        if (passwordValid && contactViewModel.isModelValid()) {
            setIsLoading(true);
            contactViewModel.UpsertAccountDetails(passwordViewModel.getValue("newPassword")).then(apiResult => {
                if (apiResult && apiResult.wasSuccessful) {
                    history.push("/dashboard");
                } else {
                    setIsLoading(false);
                    if (apiResult && apiResult.errors && apiResult.errors[0] && apiResult.errors[0].message) {
                        setErrorMessage(apiResult.errors[0].message);
                    } else {
                        setErrorMessage("An unknown error occured.");
                    }
                }
            });
        }
    };

    const handleRealtorSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        contactViewModel.setValue("terms", true);
        let passwordValid = true;
        if (passwordViewModel.getValue("newPassword") || passwordViewModel.getValue("confirmPassword")) {
            passwordValid = passwordViewModel.isModelValid();
        }
        if (passwordValid && contactViewModel.isModelValid()) {
            setIsLoading(true);
            let pwd = passwordViewModel.getValue<string>("newPassword");
            let realtorName = realtorDetailVM.getValue<string>("name");
            let realtorLogo = realtorDetailVM.getValue<string>("logo");
            // update current logged in values
            domainStores.AccountStore.RealtorName = realtorName;
            domainStores.AccountStore.RealtorLogo = realtorLogo;
            contactViewModel.UpsertRealtorAccountDetails(pwd, realtorName, realtorLogo).then(apiResult => {
                if (apiResult && apiResult.wasSuccessful) {
                    history.push("/dashboard");
                } else {
                    setIsLoading(false);
                    if (apiResult && apiResult.errors && apiResult.errors[0] && apiResult.errors[0].message) {
                        setErrorMessage(apiResult.errors[0].message);
                    } else {
                        setErrorMessage("An unknown error occured.");
                    }
                }
            });
        }
    };

    let handleShowPassword = (e: any) => {
        setShowPassword(!showPassword);
    };

    let handleShowPassword2 = (e: any) => {
        setShowPassword2(!showPassword2);
    };

    return (
        <>
            <DivWrapperBGFixed additionalcss={"background-image: url(" + QuoteBackground + ");"}>
                <DashboardContentContainer>
                    <DashboardHeaderContainer>
                        <h1>
                            <span>{accountStore.FirstName},</span> amend your account details here
                        </h1>
                    </DashboardHeaderContainer>
                    <form onSubmit={domainStores.AccountStore.IsRealtorAccount ? handleRealtorSubmit : handleSubmit}>
                        <DetailsStyles>
                            <ContactDetail namesLabel="Policy holder name" contactsLabel="Contact details" noBackground hideLogin hideTerms />
                            <ContactDetailStyle2 className="edit-contact" additionalcss="background-color: transparent;">
                                {domainStores.AccountStore.IsRealtorAccount && (
                                    <>
                                        <RealtorDetails viewModel={realtorDetailVM} isEditor={true} />
                                    </>
                                )}
                                <h2>Password</h2>
                                <ShowPasswordContainer>
                                    <EditableInput<ResetPasswordModel>
                                        viewModel={passwordViewModel}
                                        inputProps={{ placeholder: "New password" }}
                                        fieldName="newPassword"
                                        type={showPassword ? "text" : "password"}
                                    />
                                    <ShowPasswordIcon2 onClick={(e: any) => handleShowPassword(e)} title={showPassword ? "Hide password" : "Show password"}>
                                        <img src={showPassword ? EyeClosed : EyeOpen} alt="" />
                                    </ShowPasswordIcon2>
                                </ShowPasswordContainer>
                                <ShowPasswordContainer>
                                    <EditableInput<ResetPasswordModel>
                                        viewModel={passwordViewModel}
                                        inputProps={{ placeholder: "Confirm password" }}
                                        fieldName="confirmPassword"
                                        type={showPassword2 ? "text" : "password"}
                                    />
                                    <ShowPasswordIcon2 onClick={(e: any) => handleShowPassword2(e)} title={showPassword2 ? "Hide password" : "Show password"}>
                                        <img src={showPassword2 ? EyeClosed : EyeOpen} alt="" />
                                    </ShowPasswordIcon2>
                                </ShowPasswordContainer>
                            </ContactDetailStyle2>
                            {errorMessage && <p style={{ color: "red", marginLeft: "40px", marginTop: "20px" }}>{errorMessage}</p>}
                            <div className="btns">
                                <Button className="save-btn" variant="contained" type="submit" disabled={isLoading}>
                                    {isLoading ? "Saving..." : "Save"}
                                </Button>
                                <Button className="cancel-btn" variant="contained" onClick={() => history.push("./dashboard")}>
                                    Cancel
                                </Button>
                            </div>
                        </DetailsStyles>
                    </form>
                </DashboardContentContainer>
            </DivWrapperBGFixed>
        </>
    );
};
