// Libraries
import * as MobX from "mobx";

// Core
import { FieldType, isEmptyOrWhitespace } from "Core/Utils/Utils";
import { PostFormWithFile } from "Core/Utils";
import { ViewModelBase } from "Core/ViewModels";

// Custom
import { Server } from "Custom/Globals/AppUrls";
import { WidgetModel, WidgetModelDTO } from "Custom/Models";

/**
 * Edit state viewmodel.
 */
export default class EditWidgetViewModel extends ViewModelBase<WidgetModel> {
    @MobX.observable
    public id: string = "";

    /**
     * Constructor
     *
     */
    constructor(id: string) {
        super(new WidgetModel());
        this.setDecorators(WidgetModel);

        if (!isEmptyOrWhitespace(id)) {
            this.id = id;

            this.load(id);
        }
    }

    @MobX.observable
    public wasSuccessful = false;

    // #region Actions
    @MobX.action
    public setWasSuccessful = (val: boolean) => {
        this.wasSuccessful = val;
    };

    /**
     * Cancels any changes and navigates to the admin page.
     */
    public cancel = (): void => {
        this.history.push("/admin/editwidget");
    };
    // #endregion Actions

    // #region Server Api

    /**
     * Loads the state from the server.
     */
    public load = async (id: string): Promise<void> => {
        try {
            this.setIsLoading(true);

            const apiResult = await this.Post<WidgetModelDTO>(Server.Api.Widgets.GetWidgetById, { id });

            if (apiResult.wasSuccessful) {
                this.model.fromDto(apiResult.payload);
            } else {
                // Error. What to do?
                this.setIsErrored(true);
            }
        } catch (exception) {
            // Error. What to do?
            this.setIsErrored(true);
        } finally {
            this.setIsLoading(false);
        }
    };

    /**
     * Saves the state to the server.
     */
    public save = async (): Promise<void> => {
        this.setWasSuccessful(false);
        try {
            this.setIsSaving(true);

            const apiResult = await this.Post<WidgetModelDTO>(Server.Api.Widgets.UpsertWidget, this.model.toDto());

            if (apiResult.wasSuccessful) {
                // Success. What to do? Stay on page or navigate away?
                this.model.fromDto(apiResult.payload);
                this.setWasSuccessful(true);
                this.history.push("/admin/editwidget");
            } else {
                // Error. What to do?
                this.setIsErrored(true);
            }
        } catch (exception) {
            // Error. What to do?
            this.setIsErrored(true);
        } finally {
            this.setIsSaving(false);
        }
    };
    // #endregion Server Api

    // #region Viewmodel Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Viewmodel Boilerplate
}
