import styled from "styled-components";

export const WarningBannerStyles = styled.div`
    background-color: #ff5454;
    padding: 7px;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1000;

    p {
        margin: 0;
        text-align: center;
    }
`;
