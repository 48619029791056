import { KeyValuePair } from "../../Core/Models/KeyValuePair";

/**
 * Returns a Key/Value array ...
 * value: FL
 * key: Florida
 */
export const statesArrShortcode = () => {
    let statesArr: KeyValuePair[] = [
        { value: "AL", key: "Alabama" },
        { value: "AK", key: "Alaska" },
        { value: "AS", key: "American Samoa" },
        { value: "AZ", key: "Arizona" },
        { value: "AR", key: "Arkansas" },
        { value: "CA", key: "California" },
        { value: "CO", key: "Colorado" },
        { value: "CT", key: "Connecticut" },
        { value: "DE", key: "Delaware" },
        { value: "DC", key: "District Of Columbia" },
        { value: "FM", key: "Federated States Of Micronesia" },
        { value: "FL", key: "Florida" },
        { value: "GA", key: "Georgia" },
        { value: "GU", key: "Guam" },
        { value: "HI", key: "Hawaii" },
        { value: "ID", key: "Idaho" },
        { value: "IL", key: "Illinois" },
        { value: "IN", key: "Indiana" },
        { value: "IA", key: "Iowa" },
        { value: "KS", key: "Kansas" },
        { value: "KY", key: "Kentucky" },
        { value: "LA", key: "Louisiana" },
        { value: "ME", key: "Maine" },
        { value: "MH", key: "Marshall Islands" },
        { value: "MD", key: "Maryland" },
        { value: "MA", key: "Massachusetts" },
        { value: "MI", key: "Michigan" },
        { value: "MN", key: "Minnesota" },
        { value: "MS", key: "Mississippi" },
        { value: "MO", key: "Missouri" },
        { value: "MT", key: "Montana" },
        { value: "NE", key: "Nebraska" },
        { value: "NV", key: "Nevada" },
        { value: "NH", key: "New Hampshire" },
        { value: "NJ", key: "New Jersey" },
        { value: "NM", key: "New Mexico" },
        { value: "NY", key: "New York" },
        { value: "NC", key: "North Carolina" },
        { value: "ND", key: "North Dakota" },
        { value: "MP", key: "Northern Mariana Islands" },
        { value: "OH", key: "Ohio" },
        { value: "OK", key: "Oklahoma" },
        { value: "OR", key: "Oregon" },
        { value: "PW", key: "Palau" },
        { value: "PA", key: "Pennsylvania" },
        { value: "PR", key: "Puerto Rico" },
        { value: "RI", key: "Rhode Island" },
        { value: "SC", key: "South Carolina" },
        { value: "SD", key: "South Dakota" },
        { value: "TN", key: "Tennessee" },
        { value: "TX", key: "Texas" },
        { value: "UT", key: "Utah" },
        { value: "VT", key: "Vermont" },
        { value: "VI", key: "Virgin Islands" },
        { value: "VA", key: "Virginia" },
        { value: "WA", key: "Washington" },
        { value: "WV", key: "West Virginia" },
        { value: "WI", key: "Wisconsin" },
        { value: "WY", key: "Wyoming" },
    ];

    return statesArr;
};

/**
 * Returns a Key/Value array ...
 * value: Florida
 * key: Florida
 */
export const statesArrState = () => {
    let statesArr: KeyValuePair[] = [
        { value: "Alabama", key: "Alabama" },
        { value: "Alaska", key: "Alaska" },
        { value: "American Samoa", key: "American Samoa" },
        { value: "Arizona", key: "Arizona" },
        { value: "Arkansas", key: "Arkansas" },
        { value: "California", key: "California" },
        { value: "Colorado", key: "Colorado" },
        { value: "Connecticut", key: "Connecticut" },
        { value: "Delaware", key: "Delaware" },
        { value: "District Of Columbia", key: "District Of Columbia" },
        { value: "Federated States Of Micronesia", key: "Federated States Of Micronesia" },
        { value: "Florida", key: "Florida" },
        { value: "Georgia", key: "Georgia" },
        { value: "Guam", key: "Guam" },
        { value: "Hawaii", key: "Hawaii" },
        { value: "Idaho", key: "Idaho" },
        { value: "Illinois", key: "Illinois" },
        { value: "Indiana", key: "Indiana" },
        { value: "Iowa", key: "Iowa" },
        { value: "Kansas", key: "Kansas" },
        { value: "Kentucky", key: "Kentucky" },
        { value: "Louisiana", key: "Louisiana" },
        { value: "Maine", key: "Maine" },
        { value: "Marshall Islands", key: "Marshall Islands" },
        { value: "Maryland", key: "Maryland" },
        { value: "Massachusetts", key: "Massachusetts" },
        { value: "Michigan", key: "Michigan" },
        { value: "Minnesota", key: "Minnesota" },
        { value: "Mississippi", key: "Mississippi" },
        { value: "Missouri", key: "Missouri" },
        { value: "Montana", key: "Montana" },
        { value: "Nebraska", key: "Nebraska" },
        { value: "Nevada", key: "Nevada" },
        { value: "New Hampshire", key: "New Hampshire" },
        { value: "New Jersey", key: "New Jersey" },
        { value: "New Mexico", key: "New Mexico" },
        { value: "New York", key: "New York" },
        { value: "North Carolina", key: "North Carolina" },
        { value: "North Dakota", key: "North Dakota" },
        { value: "Northern Mariana Islands", key: "Northern Mariana Islands" },
        { value: "Ohio", key: "Ohio" },
        { value: "Oklahoma", key: "Oklahoma" },
        { value: "Oregon", key: "Oregon" },
        { value: "Palau", key: "Palau" },
        { value: "Pennsylvania", key: "Pennsylvania" },
        { value: "Puerto Rico", key: "Puerto Rico" },
        { value: "Rhode Island", key: "Rhode Island" },
        { value: "South Carolina", key: "South Carolina" },
        { value: "South Dakota", key: "South Dakota" },
        { value: "Tennessee", key: "Tennessee" },
        { value: "Texas", key: "Texas" },
        { value: "Utah", key: "Utah" },
        { value: "Vermont", key: "Vermont" },
        { value: "Virgin Islands", key: "Virgin Islands" },
        { value: "Virginia", key: "Virginia" },
        { value: "Washington", key: "Washington" },
        { value: "West Virginia", key: "West Virginia" },
        { value: "Wisconsin", key: "Wisconsin" },
        { value: "Wyoming", key: "Wyoming" },
    ];

    return statesArr;
};
