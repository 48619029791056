import { EditableInput } from "../../../Core/Components/EditableInput";
import { FieldType } from "../../../Core/Utils/Utils";
import { IViewModel } from "../../../Core/ViewModels";
import React from "react";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import radioNo from "Content/radioNo.svg";
import radioNoChecked from "Content/radioNo-checked.svg";
import radioYes from "Content/radioYes.svg";
import radioYesChecked from "Content/radioYes-checked.svg";
import { styled } from "../../Globals/Base";
import { useImagePreloader } from "../../../Core/Utils/Hooks/UseImagePreloader";
import { useMediaQuery as useMuiMediaQuery } from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import { isNullOrUndefined } from "util";

interface IProps<T> {
    viewModel: IViewModel<T>;
    fieldName: keyof FieldType<T>;
    disabled?: boolean;
    className?: string;
}

export function RadioYesNo<T>(props: IProps<T>) {
    useImagePreloader([radioNo, radioNoChecked, radioYes, radioYesChecked]);
    const mdMatches = useMuiMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
    const { viewModel, fieldName, disabled } = props;

    const getClassName = () => {
        let retVal = "";
        if (!isNullOrUndefined(props.className)) {
            retVal = props.className;
        }
        return retVal;
    };

    return useObserver(() => (
        <Container mdMatches={mdMatches} className={"" + getClassName()}>
            <EditableInput
                type="radio"
                fieldName={fieldName}
                viewModel={viewModel}
                validateOnBlur={false}
                validateOnStart
                selectItems={[
                    {
                        key: "Yes",
                        value: "true",
                        radioIcon: <img src={radioYes} alt="" />,
                        radioIconChecked: <img src={radioYesChecked} alt="" />,
                        disabled,
                    },
                    {
                        key: "No",
                        value: "false",
                        radioIcon: <img src={radioNo} alt="" />,
                        radioIconChecked: <img src={radioNoChecked} alt="" />,
                        disabled,
                    },
                ]}
            />
        </Container>
    ));
}

interface IContainerProps {
    mdMatches?: boolean;
}

const Container = styled.div<IContainerProps>`
    padding: 10px 0;

    @media (max-width: 767px) {
        padding: 0;
    }

    .MuiFormGroup-root {
        width: 220px;
        position: relative;

        label {
            margin-left: -9px;
            margin-right: 0;

            :nth-of-type(2) {
                left: calc(50% + 24px);
                position: absolute;
            }
        }
    }

    p.Mui-error {
        margin-top: 10px;
        color: #f44336 !important;
        margin-left: 7px;
        margin-right: 7px;

        @media (max-width: 767px) {
            margin-left: 0;
            margin-right: 0;
            margin-top: 7px;
            font-size: 10px;
        }
    }

    .MuiRadio-root {
        padding: 0 9px;
    }

    .Mui-checked + span {
        font-weight: bold;
    }

    .MuiFormControlLabel-root {
        > span:nth-of-type(2) {
            opacity: 0.5;
        }

        :first-of-type {
            .Mui-checked + span {
                color: #12db4e;
                opacity: 1;
            }
        }

        :nth-of-type(2) {
            .Mui-checked + span {
                color: #ff2e2e;
                opacity: 1;
            }
        }
    }

    .MuiFormControlLabel-label {
        font-size: 18px;
    }

    .MuiIconButton-label > img {
        ${props => (props.mdMatches ? "width: 32px;" : "")}
    }

    span {
        ${props => (props.mdMatches ? "font-size: 10px !important" : "")}
    }

    span.Mui-disabled:not(.Mui-checked) {
        opacity: 0.5;
    }
`;
