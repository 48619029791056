// Libraries
import * as MobX from "mobx";

// Core
import { ModelBase } from "../../Core/Models/ModelBase";

export class AdminStatsModel extends ModelBase<AdminStatsModel, AdminStatsModelDTO> {
    // #region Overall Statistics

    @MobX.observable
    public numberOfQuotesProduced = 0;

    @MobX.observable
    public numberOfQuotesSelected = 0;

    @MobX.observable
    public numberOfRegsisteredUsers = 0;

    // #endregion Overall Statistics

    // #region Insurer Statistics

    @MobX.observable
    public insurerStatistics = MobX.observable.map<string, [number, number]>([]);

    // #endregion Insurer Statistics

    @MobX.action
    public fromDto = (model: AdminStatsModelDTO): void => {
        this.numberOfQuotesProduced = model.countQuotesProduced;
        this.numberOfQuotesSelected = model.countQuotesSelected;
        this.numberOfRegsisteredUsers = model.countRegisteredUsers;

        this.insurerStatistics.set("AON Edge", [model.countQuotesAONEdge, model.totalQuotesAONEdge]);
        this.insurerStatistics.set("Argenia", [model.countQuotesArgenia, model.totalQuotesArgenia]);
        this.insurerStatistics.set("Flood Risk Solutions", [model.countQuotesFRS, model.totalQuotesFRS]);
        this.insurerStatistics.set("Sterling", [model.countQuotesSterling, model.totalQuotesSterling]);
        this.insurerStatistics.set("Wright NFIP", [model.countQuotesWrightNFIP, model.totalQuotesWrightNFIP]);
        this.insurerStatistics.set("Hiscox FloodPlus", [model.countQuotesHiscoxFloodPlus, model.totalQuotesHiscoxFloodPlus]);
        this.insurerStatistics.set("NFIP", [model.countQuotesNFIP, model.totalQuotesNFIP]);
        this.insurerStatistics.set("Neptune", [model.countQuotesNeptune, model.totalQuotesNeptune]);
        this.insurerStatistics.set("Selective", [model.countQuotesSelective, model.totalQuotesSelective]);
        this.insurerStatistics.set("Wright Private Flood", [model.countQuotesWrightPrivateFlood, model.totalQuotesWrightPrivateFlood]);
        //this.insurerStatistics.set("test", [0, 0]);
    };

    public toDto = (): AdminStatsModelDTO => {
        throw "toDto is not supported";
    };
}

export interface AdminStatsModelDTO {
    countQuotesProduced: number;
    countQuotesSelected: number;
    countRegisteredUsers: number;
    countQuotesAONEdge: number;
    countQuotesArgenia: number;
    countQuotesFRS: number;
    countQuotesSterling: number;
    countQuotesWrightNFIP: number;
    countQuotesHiscoxFloodPlus: number;
    countQuotesSelective: number;
    countQuotesWrightPrivateFlood: number;
    countQuotesNFIP: number;
    countQuotesNeptune: number;
    totalQuotesProduced: number;
    totalQuotesSelected: number;
    totalRegisteredUsers: number;
    totalQuotesAONEdge: number;
    totalQuotesArgenia: number;
    totalQuotesFRS: number;
    totalQuotesSterling: number;
    totalQuotesWrightNFIP: number;
    totalQuotesHiscoxFloodPlus: number;
    totalQuotesSelective: number;
    totalQuotesWrightPrivateFlood: number;
    totalQuotesNFIP: number;
    totalQuotesNeptune: number;
}
