// Libraries
import styled from "styled-components";

// Custom
import { isNullOrUndefined } from "util";

export const PolicyHolderRegistrationContainer = styled.div``;

export const PolicyHolderRegistrationItem = styled.div`
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    margin: 0 auto 30px;
    padding: 15px 40px 30px 40px;
    width: 100%;

    @media (max-width: 767px) {
        margin: 0 auto 10px;
        padding: 10px 20px;
    }
`;

export const PolicyHolderHaveAccount = styled.p`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;

    > a {
        color: #f2af4c;
    }

    @media (max-width: 767px) {
        font-size: 10px;
    }
`;

export const PolicyHolderTermsAndConditions = styled.div`
    display: flex;
    justify-content: center;
    padding: 0 40px;

    > label {
        margin-right: 0;
    }

    div {
        font-size: 21px;
        font-weight: bold;

        > a {
            color: #129bdb;
        }
    }

    @media (max-width: 767px) {
        padding: 0 20px;

        div {
            font-size: 12px;
        }
    }
`;

export const PolicyHolderRegistrationTitle = styled.h2`
    @media (max-width: 767px) {
        font-size: 10px;
        margin-bottom: 0;
    }
`;

export const PolicyHolderRegistrationSubTitle = styled.h3`
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 0;

    @media (max-width: 767px) {
        font-size: 10px;
    }
`;

export const PolicyHolderRegistrationInputRow = styled.div`
    display: flex;
    margin-left: -20px;
    margin-right: -20px;

    @media (max-width: 767px) {
        flex-direction: column;
    }
`;

interface IPolicyHolderRegistrationInputProps {
    isValid?: boolean;
}

const backgroundColor = "#FFF";
const inValidBorderColor = "#F44336";
const inValidBorderFocusColor = "#D83B2F";
const placeholderColor = "#93A0AB";
const validBorderColor = "#F2AF4C";
const validBorderFocusColor = "#D99D44";

export const PolicyHolderRegistrationInput = styled.div<IPolicyHolderRegistrationInputProps>`
    flex: 1;
    margin: 10px 20px;

    > div.MuiBox-root {
        > div.MuiTextField-root {
            > div.MuiInputBase-root {
                background-color: ${backgroundColor};
                border: 7px solid ${validBorderColor};
                border-color: ${({ isValid }) => (isValid || isNullOrUndefined(isValid) ? validBorderColor : inValidBorderColor)};
                border-radius: 15px;

                > input {
                    padding: 15px 20px;

                    &::placeholder {
                        color: ${placeholderColor};
                        font-style: italic;
                    }
                }

                &:hover {
                    border-color: ${({ isValid }) => (isValid || isNullOrUndefined(isValid) ? validBorderFocusColor : inValidBorderFocusColor)};
                }
            }

            > div.MuiInputBase-root.Mui-focused {
                border-color: ${({ isValid }) => (isValid || isNullOrUndefined(isValid) ? validBorderFocusColor : inValidBorderFocusColor)};
            }
        }
    }

    @media (max-width: 767px) {
        > div.MuiBox-root {
            > div.MuiTextField-root {
                > div.MuiInputBase-root {
                    border-width: 7px;
                    font-size: 12px;

                    > input {
                        padding: 10px 20px;
                    }
                }

                > p {
                    font-size: 10px;
                }
            }
        }
    }
`;

export const PolicyHolderRegistrationAdornedInput = styled(PolicyHolderRegistrationInput)`
    position: relative;

    > div.MuiBox-root {
        > div.MuiTextField-root {
            > div.MuiInputBase-root {
                > input {
                    padding-right: 55px;
                }
            }
        }
    }

    @media (max-width: 767px) {
        > div.MuiBox-root {
            > div.MuiTextField-root {
                > div.MuiInputBase-root {
                    > input {
                        padding-right: 50px;
                    }
                }
            }
        }
    }
`;

export const Adornment = styled.div`
    margin-right: 7px;
    margin-top: 7px;
    position: absolute;
    right: 0;
    top: 0;

    @media (max-width: 767px) {
        > button {
            padding: 5px;
        }
    }
`;
