// Libraries
import React, { useState, useContext, useRef, useEffect } from "react";
import { useMediaQuery } from "../../../Core/Utils/Media";

// Styling & images
import { USPHolder, USPWrapper } from "../../StylesAppSpecific/PageStyling";
import { GridSpaced } from "../../StylesAppSpecific/GridHelperStyling";

import EnterYourLocation from "Content/1EnterYourLocation.svg";
import LevelOfCoverage from "Content/2SelectCoverage.svg";
import AddSomeDetail from "Content/3AddSomeDetail.svg";

import MobileAddSomeDetail from "Content/MobileAddSomeDetail.svg";
import MobileSelectCoverage from "Content/MobileSelectCoverage.svg";
import MobileEnterYourLocation from "Content/MobileEnterYourLocation.svg";

export const HomeUSP: React.FC = () => {
    let isMobile: boolean;
    isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;

    return (
        <>
            {!isMobile && (
                <>
                    <GridSpaced container margin={"0px 0px 26px"} additionalcss={"max-width: 561px; width: 100%;"}>
                        <GridSpaced item sm={4} additionalcss={"text-align: left;"}>
                            <img src={EnterYourLocation} alt="Enter the location of your property" />
                        </GridSpaced>
                        <GridSpaced item sm={4} additionalcss={"text-align: left;"}>
                            <img src={LevelOfCoverage} alt={"Select the level of coverage"} />
                        </GridSpaced>
                        <GridSpaced item sm={4} additionalcss={"text-align: left;"}>
                            <img src={AddSomeDetail} alt={"Add some detail about your property"} />
                        </GridSpaced>
                    </GridSpaced>
                </>
            )}
            {isMobile && (
                <>
                    <USPWrapper>
                        <USPHolder>
                            <img src={MobileEnterYourLocation} alt="Enter the location of your property" />
                            <div>
                                <h2>Step 1.</h2>
                                <p>
                                    Enter the
                                    <br />
                                    location of
                                    <br />
                                    your property
                                </p>
                            </div>
                        </USPHolder>
                        <USPHolder>
                            <img src={MobileSelectCoverage} alt={"Add some detail about your property"} />
                            <div>
                                <h2>Step 2.</h2>
                                <p>
                                    Add some
                                    <br />
                                    detail about
                                    <br />
                                    your property
                                </p>
                            </div>
                        </USPHolder>
                        <USPHolder>
                            <img src={MobileAddSomeDetail} alt={"Select the level of coverage"} />
                            <div>
                                <h2>Step 3.</h2>
                                <p>
                                    Select the
                                    <br />
                                    level of
                                    <br />
                                    coverage
                                </p>
                            </div>
                        </USPHolder>
                    </USPWrapper>
                </>
            )}
        </>
    );
};
