// Libraries
import clsx from "clsx";
import React from "react";
import { Typography, Paper, Box, Select, MenuItem } from "@material-ui/core";

// Custom
import { useInsurerStatisticsHeaderStyles, SelectStyles, useFilterAndSortBarStyles } from "../../StylesAppSpecific/Admin/AdminDashboard";

interface IProps {
    deductible: string;
    sortOptionSelection: number;
    sortOptions: any[];
    setSortOption: (value: number) => void;
}

export const FilterAndSortBar: React.FC<IProps> = (props: IProps) => {
    const classes = useFilterAndSortBarStyles();

    const onSortOptionChange = (event: any) => {
        props.setSortOption(event.target.value);
    };

    return (
        <Paper className={classes.paper}>
            <Box>
                <Typography className={classes.title}>
                    <b>Deductible: </b>
                    {props.deductible}
                </Typography>
            </Box>
            <Box className={classes.sortBox}>
                <Typography className={clsx(classes.title, classes.sortTitle)}>Sort by:</Typography>
                <SelectStyles className={classes.select}>
                    <Select variant="outlined" value={props.sortOptionSelection} onChange={onSortOptionChange}>
                        {props.sortOptions.map((option: any, index: number) => (
                            <MenuItem value={option.value}>{option.key}</MenuItem>
                        ))}
                    </Select>
                </SelectStyles>
            </Box>
        </Paper>
    );
};
