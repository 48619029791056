import { FieldType } from "../../../Core/Utils/Utils";
import { IViewModel } from "../../../Core/ViewModels/IViewModel";
import { KeyValuePair } from "../../../Core/Models/KeyValuePair";
import React from "react";
import radioYes from "../../../Content/radioYes.svg";
import radioYesChecked from "../../../Content/radioYes-checked.svg";
import { styled } from "../../Globals/Base";

export interface IModalOptionProps {
    onClick: (value: any) => void;
    selected: any;
}

interface IProps extends IModalOptionProps {
    value: any;
    image: string;
    label?: string;
    labelMap?: any;
}

export const ModalOption: React.FC<IProps> = props => {
    const { onClick, value, image, label, selected, labelMap } = props;

    const handleClick = () => {
        onClick(value);
    };

    return (
        <>
            <Container role="button" tabIndex={0} onClick={handleClick}>
                <img src={image} alt="" />
                <p>{label === undefined ? (labelMap ? labelMap[value] : label) : label}</p>
                <img src={selected === value ? radioYesChecked : radioYes} alt={selected === value ? "✓" : ""} />
            </Container>
        </>
    );
};

const Container = styled.div`
    padding: 20px 20px 20px 20px;
    background-color: rgba(255, 255, 255, 0.3);
    display: flex;
    margin: 1px 0;
    align-items: center;
    width: 100%;
    @media (min-width: 768px) {
        width: 600px;
    }
    cursor: pointer;

    p {
        font-size: 14px;
        margin: 0;
    }

    img:nth-of-type(1) {
        margin-right: 10px;
        max-height: 46px;
    }

    img:nth-of-type(2) {
        margin-left: auto;
    }
`;

export const ModalOptionRow = styled.div`
    display: flex;
    justify-content: center;

    @media (max-width: 767px) {
        flex-direction: column;
    }
`;
