import { observable, styled, useState } from "../../Globals/Base";

import { ContactDetailStyle } from "Custom/StylesAppSpecific/FormStyling";
import { FieldType } from "Core/Utils/Utils";
import { IObservableArray } from "mobx";
import { IOptionProps } from ".";

import { IViewModel } from "../../../Core/ViewModels/IViewModel";
import React, { useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { ButtonLink } from "../../StylesAppSpecific/AdminStyling";
import { Grid } from "@material-ui/core";
import { IModel } from "Core/Models";
import { ViewModelBase } from "Core/ViewModels";
import { useMediaQuery } from "../../../Core/Utils/Media/mediaQuery";
import { isNullOrUndefined } from "util";

interface IProps<T extends IModel<T>> {
    viewModel: IViewModel<T> & ViewModelBase<T>;
    fieldName: keyof FieldType<T>;
    question: string;
    children: (props: IOptionProps) => React.ReactNode;
    multiselect?: boolean;
    disableCollapse?: boolean;
    image?: string;
    value?: any;
    className?: string;
}

export function CollapsableLargeMultichoice<T extends IModel<T>>(props: IProps<T>) {
    const { viewModel, fieldName, question, children, multiselect, disableCollapse, image, value } = props;
    const [collapsed, setCollapsed] = useState(!disableCollapse);
    const [childNode, setChildNode] = useState<React.ReactNode | null>(null);
    const isMobile = !!useMediaQuery("(max-width: 767px)");

    useEffect(() => getChildren(), []);

    // JC: So the list collapses when the value changes.
    useEffect(() => {
        if (!isNullOrUndefined(value) && value != "") {
            setCollapsed(true);
        }
    }, [value]);

    const onChange = (value: any) => {
        if (!disableCollapse) {
            setCollapsed(true);
        }
        if (multiselect) {
            const items = viewModel.getValue<IObservableArray<string>>(fieldName).toJS();
            if (items.includes(value)) {
                items.splice(items.indexOf(value), 1);
            } else {
                items.push(value);
            }
            value = observable<string>(items);
        }
        viewModel.setValue(fieldName, value);
        /* viewModel.isModelValid(); */
        getChildren();
    };

    const getChildren = () => {
        setChildNode(children({ onClick: onChange, selected: viewModel.getValue(fieldName) }));
    };

    const getClassName = () => {
        let retVal = "";
        if (!isNullOrUndefined(props.className)) {
            retVal = props.className;
        }
        return retVal;
    };

    return useObserver(() => (
        <Container className={"" + getClassName()}>
            <HeadingBox>
                <span>
                    <Grid container>
                        {image && !isMobile && (
                            <Grid item xs={1} className="gridImage">
                                <img src={image} alt="" />
                            </Grid>
                        )}
                        <Grid item xs className="gridItem">
                            <h2>{question}</h2>
                            <p className="error">{viewModel.getError(fieldName)}</p>
                        </Grid>
                        {disableCollapse || (
                            <Grid item className="gridItem">
                                <ButtonLink
                                    onClick={() => setCollapsed(false)}
                                    color="secondary"
                                    disableElevation
                                    disableFocusRipple
                                    disableRipple
                                    disableTouchRipple
                                >
                                    Change
                                </ButtonLink>
                            </Grid>
                        )}
                    </Grid>
                </span>
            </HeadingBox>
            <CollapseDiv collapsed={collapsed} childCount={childNode ? React.Children.count((childNode as any).props.children) : 0}>
                {childNode}
            </CollapseDiv>
        </Container>
    ));
}

const HeadingBox = styled(ContactDetailStyle)`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0 !important;
    padding: 0;
    align-items: center;

    > span,
    > span > div {
        height: 100%;
    }

    .gridItem {
        padding: 14px 20px;
        display: flex;

        @media (max-width: 767px) {
            padding: 0;
            flex-direction: column;
        }
    }

    .gridImage {
        display: flex;
        justify-content: center;
        margin-right: -20px;
        padding: 19px 70px;
        height: 90px;
    }

    h2 {
        margin: 0;
        height: 100%;
        display: flex;
        align-items: center;

        @media (max-width: 767px) {
            font-size: 10px;
        }
    }

    .error {
        white-space: nowrap;
        margin: auto 7px;

        @media (max-width: 767px) {
            margin: auto 0;
        }
    }
`;

const Container = styled.div`
    > div:last-of-type {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    margin-bottom: 40px;

    @media (max-width: 767px) {
        margin-bottom: 10px;
    }
`;

interface ICollapseDivProps {
    childCount: number;
    collapsed?: boolean;
}

const CollapseDiv = styled.div<ICollapseDivProps>`
    transition: height 0.5s ease-out;
    overflow-y: hidden;
    height: ${({ childCount, collapsed }) => (collapsed ? 78 : childCount * 78)}px;
`;
