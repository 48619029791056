import { StoresInstance } from "../../Globals/Base";

import React from "react";
import { useObserver } from "mobx-react-lite";
import { YearBuilt, NumberOfFloors, BuildingSqft, OverWater, MobileHome, HasGarage, ConstructionType, FoundationType, Basement, NewProperty } from ".";

export const PropertyDetails: React.FC = () => {
    const domainStores = StoresInstance.domain;
    const viewModel = domainStores.QuoteRequestViewModel;
    const noDetails = !domainStores.EstatedHasStructure;

    const showField = (fieldName: string) => {
        return noDetails! || viewModel.gotFields.includes(fieldName);
    };

    return useObserver(() => (
        <>
            {showField("yearBuilt") && <YearBuilt viewModel={viewModel} />}
            {showField("numberOfFloors") && <NumberOfFloors viewModel={viewModel} />}
            {showField("buildingSqft") && <BuildingSqft viewModel={viewModel} />}
            {showField("isOverWater") && <OverWater viewModel={viewModel} />}
            {showField("isMobileHome") && <MobileHome viewModel={viewModel} />}
            {showField("hasGarage") && <HasGarage viewModel={viewModel} />}
            {showField("constructionType") && (
                <ConstructionType viewModel={viewModel} disableCollapse={viewModel.model.constructionType === ""} value={viewModel.model.constructionType} />
            )}
            {showField("foundationType") && (
                <FoundationType viewModel={viewModel} disableCollapse={viewModel.model.foundationType === ""} value={viewModel.model.foundationType} />
            )}
            {showField("hasBasement") && <Basement viewModel={viewModel} />}

            {noDetails && <NewProperty viewModel={viewModel} />}
        </>
    ));
};
