// Libraries
import * as MobX from "mobx";

// Core
import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";

// Custom
import { AgencyDetailModel, AgencyDetailModelDTO } from "../../Models/AgencyDetailModel";
import { Server } from "Custom/Globals/AppUrls";

export class AgencyDetailViewModel extends ViewModelBase<AgencyDetailModel> {
    constructor() {
        super(new AgencyDetailModel());
        this.setDecorators(AgencyDetailModel);
    }

    public load = async (): Promise<void> => {
        try {
            this.setIsLoading(true);

            const apiResult = await this.Post<AgencyDetailModelDTO>(Server.Api.Agency.GetAgencyDetail);

            if (apiResult.wasSuccessful) {
                this.model.fromDto(apiResult.payload);
            } else {
                this.setIsErrored(true);
            }
        } catch (exception) {
            this.setIsErrored(true);
        } finally {
            this.setIsLoading(false);
        }
    };

    public getAgreementPdfUrl = async () => {
        return this.Post<string>(Server.Api.Agency.GetAgreementPDFUrl);
    };

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<AgencyDetailModel>, value: any, showErrors: boolean = true): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }
}
