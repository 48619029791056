import { observable } from "../Globals/Base";
import { ModelBase } from "../../Core/Models";
import { IsNotEmpty } from "class-validator";

export class CarrierSettingModel extends ModelBase<CarrierSettingModel, CarrierSettingModel> {
    @observable
    @IsNotEmpty({ message: "Agency Id is required" })
    public apiAgentLogin = "";

    @observable
    @IsNotEmpty({ message: "Password is required" })
    public apiAgentPassword = "";

    @observable
    public agencyApiKey = "";

    fromDto(model: CarrierSettingModel) {}
    toDto(model: CarrierSettingModel) {}
}
