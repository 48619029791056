// Libraries
import { useObserver } from "mobx-react-lite";
import MaterialTable from "material-table";
import React, { useEffect } from "react";
import { Button, Grid, Typography } from "@material-ui/core";

// Core
import { useRouter } from "../../../Core/Utils";

// Custom
import { StoresInstance } from "../../Stores";
import { useListStyles } from "./ListStyling";
import { easternStandardTime, dateTimeFormat } from "../../Utils/Time";

export const Cities: React.FC = () => {
    const classes = useListStyles();
    const { history } = useRouter();
    const domainStores = StoresInstance.domain;
    const cityViewModel = domainStores.CitiesViewModel;

    useEffect(() => {
        domainStores.CitiesViewModel.load();
    }, []);

    const handleRowClick = (e: any, rowData: any) => {
        history.push("/editcity/" + rowData.permaLink);
    };

    const handleNewClick = () => {
        history.push(`/editcity/0`);
    };

    return useObserver(() => (
        <Grid container spacing={3}>
            <Grid className={classes.title} item xs={12} sm={6}>
                <Typography variant="h1" component="h3">
                    Cities
                </Typography>
            </Grid>
            <Grid className={classes.addItem} item xs={12} sm={6}>
                <Button className={classes.button} color="secondary" onClick={handleNewClick} type="button" variant="contained">
                    New city
                </Button>
            </Grid>
            <Grid item xs={12}>
                <MaterialTable
                    columns={[
                        {
                            title: "Title",
                            field: "title",
                        },
                        {
                            title: "Created on (ET)",
                            field: "createdDate",
                            render: rowData => easternStandardTime(rowData.createdDate).format(dateTimeFormat),
                            customFilterAndSearch: (value, rowData) => {
                                return (
                                    easternStandardTime(rowData.createdDate)
                                        .format(dateTimeFormat)
                                        .indexOf(value) != -1
                                );
                            },
                        },
                        {
                            title: "Updated on (ET)",
                            field: "updatedDate",
                            render: rowData => easternStandardTime(rowData.updatedDate).format(dateTimeFormat),
                            customFilterAndSearch: (value, rowData) => {
                                return (
                                    easternStandardTime(rowData.updatedDate)
                                        .format(dateTimeFormat)
                                        .indexOf(value) != -1
                                );
                            },
                        },
                        {
                            title: "Published",
                            field: "isHidden",
                            render: rowData => (rowData.isHidden ? "No" : "Yes"),
                            customFilterAndSearch: (value, rowData) => {
                                return (rowData.isHidden ? "No" : "Yes").indexOf(value) != -1;
                            },
                        },
                    ]}
                    options={{
                        pageSize: 30,
                        pageSizeOptions: [10, 30, 60],
                        emptyRowsWhenPaging: false,
                    }}
                    data={cityViewModel.Cities}
                    onRowClick={handleRowClick}
                    title=""
                />
            </Grid>
        </Grid>
    ));
};
