/*global google*/
import React, { useEffect, useContext, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { useMediaQuery } from "@material-ui/core";
import { GeocodeResult } from "../../Models/GeocodeResult";
import { useRouter } from "../../../Core/Utils";
import { useObserver } from "mobx-react-lite";
import { StoresInstance } from "../../Stores";

// Styling & images
import { AddressInputHome } from "../../StylesAppSpecific/FormStyling";
import { Button } from "@material-ui/core";
import { runInAction } from "mobx";
import { CoverageModel, PropertyQuoteModel } from "../../Models";
import { googlePlaceResultToGeocodeResult } from "Core/Utils/Utils";

export interface IAddressSelectorHomeProps {
    gotoNext?: boolean | undefined;
    pageSource?: string;
    promptText?: string;
    callback?: () => void;
    extraFocusScroll?: number;
}

/**
 * AddressSelectorHome: desktop home design v2-3
 */
export const AddressSelectorHome: React.FC<IAddressSelectorHomeProps> = props => {
    const { history } = useRouter();
    const domainStores = StoresInstance.domain;
    const { gotoNext, callback, pageSource, promptText } = props;

    let prompt: string = "Enter your address to <span>get your quotes</span>";
    if (props.promptText != undefined || props.promptText != null) {
        if (props.promptText.length > 0) {
            prompt = props.promptText;
        }
    }

    const [isSubmitable, setIsSubmitable] = useState<boolean>(false);

    let searchLocationAutoComplete: google.maps.places.Autocomplete;
    let searchLocationInput: HTMLInputElement;
    let searchEventListener: google.maps.MapsEventListener;

    let inputReference: React.MutableRefObject<any> = useRef(null);

    const setLocation: any = (result: GeocodeResult) => {
        // reset and allow Estated API to pull fresh stuff
        domainStores.EstatedHasLoaded = false;
        runInAction(() => {
            domainStores.EstatedHasStructure = false;
        });
        domainStores.QuoteRequestViewModel.setValue("buildingCoverage", CoverageModel.MODEL_DEFAULT_BUILDINGCOVERAGE);
        domainStores.QuoteRequestViewModel.setValue("contentsCoverage", CoverageModel.MODEL_DEFAULT_CONTENTSCOVERAGE);
        domainStores.QuoteRequestViewModel.setValue("deductible", CoverageModel.MODEL_DEFAULT_DEDUCTIBLEVALUE);
        domainStores.QuoteRequestViewModel.setValue("address", result.placeName);
        domainStores.QuoteRequestViewModel.setValue("city", result.city);
        domainStores.QuoteRequestViewModel.setValue("county", result.county);
        domainStores.QuoteRequestViewModel.setValue("state", result.region);
        domainStores.QuoteRequestViewModel.setValue("zipcode", result.zipcode);
        domainStores.QuoteRequestViewModel.setValue("latitude", result.center[1].toString());
        domainStores.QuoteRequestViewModel.setValue("longitude", result.center[0].toString());
        domainStores.QuoteRequestViewModel.model.resetDetails();
        domainStores.QuoteRequestViewModel.resetGotFields();

        domainStores.EstatedHasStructure = false;
        domainStores.EstatedHasLoaded = false;

        callback && callback();

        // if (pageSource !== undefined && pageSource.length > 1) {
        // 	mapStoreContext.SetPageSource(pageSource);
        // }

        // Redirect on address click
        if (props.gotoNext !== undefined && props.gotoNext) {
            history.push("/step1");
        }

        setIsSubmitable(true);
    };

    useEffect(() => {
        if (inputReference.current != null) {
            searchLocationInput = inputReference.current!;

            searchLocationAutoComplete = new google.maps.places.Autocomplete(searchLocationInput, {
                types: ["address"],
                componentRestrictions: { country: "us" },
            });

            searchEventListener = google.maps.event.addListener(searchLocationAutoComplete, "place_changed", () => {
                domainStores.MapStore.ResetLocation();
                const location: google.maps.places.PlaceResult = searchLocationAutoComplete!.getPlace();
                if (location && location.geometry && location.formatted_address && location.types) {
                    const result = googlePlaceResultToGeocodeResult(location);
                    setLocation(result);
                }
            });
        }

        return () => {
            google.maps.event.removeListener(searchEventListener!);
        };
    }, []);

    let isMobile: boolean = useMediaQuery("screen and (max-width: 767px)") ? true : false;

    return useObserver(() => {
        return (
            <>
                <AddressInputHome>
                    <input
                        ref={inputReference}
                        onKeyPress={(e: any) => {
                            if (e.keyCode === 13 || e.charCode === 13 || e.which === 13) {
                                e.preventDefault();
                                return false;
                            }
                        }}
                        onChange={(e: any) => {
                            domainStores.QuoteRequestViewModel.setValue("address", e.target.value);
                        }}
                        onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                            isMobile &&
                                window.scrollTo({
                                    top: e.currentTarget.offsetTop - 90 - (props.extraFocusScroll || 0),
                                    left: 0,
                                    behavior: "smooth",
                                });
                        }}
                        autoFocus={true}
                        placeholder={"Please enter your address here..."}
                        value={domainStores.QuoteRequestViewModel.getValue("address") as string}
                    />
                </AddressInputHome>
            </>
        );
    });
};
