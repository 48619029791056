import { ComponentState, ISelectModalProps } from "../Forms/SelectModal";
import { Grid, Theme } from "@material-ui/core";
import { styled, useObserver, useState } from "../../Globals/Base";

import { FieldType } from "../../../Core/Utils/Utils";
import { IViewModel } from "../../../Core/ViewModels/IViewModel";
import { KeyValuePair } from "../../../Core/Models/KeyValuePair";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useMediaQuery as useMuiMediaQuery } from "@material-ui/core";

interface IProps<T> {
    viewModel: IViewModel<T>;
    fieldName: keyof FieldType<T>;
    valueMap: any;
    children?: (obj: [ISelectModalProps<T>, any]) => React.ReactNode;
}

export function MultiChoice<T>(props: IProps<T>) {
    const { viewModel, fieldName, valueMap, children } = props;
    const [openModal, setOpenModal] = useState(false);
    const mdMatches = useMuiMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

    return useObserver(() => (
        <>
            <ContainerDiv mdMatches={mdMatches}>
                {valueMap[viewModel.getValue<any>(fieldName)]}
                <p className="change-modal" role="button" tabIndex={0} onClick={() => setOpenModal(true)}>
                    Change
                </p>
            </ContainerDiv>

            {children && children([{ fieldName, modalState: [openModal, setOpenModal], viewModel }, valueMap])}
        </>
    ));
}

interface IContainerProps {
    mdMatches?: boolean;
}

const ContainerDiv = styled.div<IContainerProps>`
    display: inline;
    margin-left: 10px;

    .change-modal {
        font-size: 12px;
        text-decoration: underline;
        color: #f2af4c;
        font-weight: normal;
        cursor: pointer;
        display: inline;
        margin-left: 10px;
    }

    > div > p {
        ${props => (props.mdMatches ? "font-size: 16px;" : "")}
    }

    .changeBtnItem {
        ${props => (props.mdMatches ? "align-self: center; text-align: right; position: relative; right: -10px;" : "")}
    }

    ${props => (props.mdMatches ? "padding-botttom: 7px;" : "")}
`;
