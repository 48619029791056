import styled from "styled-components";

export const LoadingH1: any = styled.h1`
    text-align: center;
    font-weight: Bold;
    font-size: 18px;
    font-family: "Montserrat";
    letter-spacing: -0.45px;
    color: #8cd2f7;
    margin: 0px;
    @media (min-width: 768px) {
        font-size: 32px;
        letter-spacing: -0.8px;
    }
`;

export const LoadingH2: any = styled.h2`
    text-align: center;
    font-weight: Bold;
    font-size: 12px;
    font-family: "Montserrat";
    letter-spacing: -0.3px;
    color: #ffffff;
    margin: 0px;
    @media (min-width: 768px) {
        font-size: 24px;
        letter-spacing: 0;
    }
`;

export const LoadingImg: any = styled.img`
    max-width: 144px;
    width: 100%;
    height: auto;
    margin: 0px auto 10px;
`;
