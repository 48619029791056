import { Grid, ExpansionPanel } from "@material-ui/core";
import styled from "styled-components";

export const QandAContainer: any = styled.div`
    color: #ffffff;
    font-weight: 100;
    font-size: 14px;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    letter-spacing: -0.35px;

    h2 {
        font-weight: 700;
        font-size: 36px;
        font-family: "Montserrat", Arial, Helvetica, sans-serif;
        letter-spacing: -1.8px;
        text-align: center;
        margin: 52px 0px 0px;
    }

    p {
        margin: 27px 0px 0px;
    }

    @media (max-width: 767px) {
        max-width: 335px;
        margin: 0px auto;

        h2 {
            font-size: 18px;
            text-align: left;
            margin: 0px;
            letter-spacing: -0.9px;
        }

        p {
            margin: 12px 0px 0px;
            font-size: 12px;
        }
    }
`;

export const ExpandDetailContainer: any = styled(ExpansionPanel)`
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
    min-height: 34px;
    font-weight: 700;
    font-size: 18px;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    margin: 10px 0px;

    .MuiExpansionPanelSummary-root {
        padding: 0px 19px;
        min-height: 34px;
    }

    .MuiIconButton-root {
        color: #ffffff;
        padding: 0px;
    }

    @media (max-width: 767px) {
        max-width: 335px;
        margin: 10px auto !important;

        font-size: 10px;

        .MuiExpansionPanelSummary-root {
        }

        .MuiIconButton-root {
        }
    }
`;
