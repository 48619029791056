// Libraries
import * as MobX from "mobx";

// Core
import { FieldType, isEmptyOrWhitespace } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";
import { BlankModel } from "../../../Core/Models/BlankModel";

// Custom
import { Server } from "../../Globals/AppUrls";
import { AgentUserAndQuotesModel, AgentUserAndQuotesModelDTO } from "../../Models/AgentUserAndQuotesModel";
import { UserQuoteModel } from "../../Models/UserQuoteModel";
import { GenericId } from "Custom/Models";

export default class AgentUserReportViewModel extends ViewModelBase<AgentUserAndQuotesModel> {
    @MobX.observable
    public id: string = "";

    // #region User

    @MobX.computed
    public get name() {
        return `${this.model.firstName} ${this.model.lastName}`;
    }

    @MobX.computed
    public get emailAddress() {
        return this.model.userName;
    }

    // #endregion User

    // #region Quote Results

    @MobX.computed
    public get userQuotes() {
        return this.model.userQuotes.slice();
    }

    // #endregion Quote Results

    /**
     * Constructor
     *
     * @param id The quote result to load (if defined).
     */
    constructor(id: string) {
        super(new AgentUserAndQuotesModel());
        this.setDecorators(AgentUserAndQuotesModel);

        if (!isEmptyOrWhitespace(id)) {
            this.id = id;

            this.load(id);
        }
    }

    // #region Navigation

    public selectQuoteResult = (data: UserQuoteModel) => {
        if (!isEmptyOrWhitespace(data.blobId)) {
            this.history.push(`/agentuserreportquotes/${data.blobId}`);
        }
    };

    public backToDashboard = () => {
        this.history.push("/agent/report");
    };

    // #endregion Navigation

    // #region Server Api

    /**
     * Loads the specified quote result from the server.
     *
     * @param id The unique identifier of the user for the quote result to load.
     */
    public load = async (id: string): Promise<void> => {
        try {
            this.setIsLoading(true);

            const apiResult = await this.Post<AgentUserAndQuotesModelDTO>(Server.Api.Agent.GetUserAndQuotes, { id });

            if (apiResult.wasSuccessful) {
                this.model.fromDto(apiResult.payload);
            } else {
                this.setIsErrored(true);
            }
        } catch (exception) {
            this.setIsErrored(true);
        } finally {
            this.setIsLoading(false);
        }
    };

    public deletePropertyQuote = async (propertyQuoteId: string): Promise<void> => {
        try {
            this.setIsSaving(true);

            const requestId: GenericId = {
                id: propertyQuoteId,
            };

            const apiResult = await this.Post(Server.Api.Agent.DeletePropertyQuote, requestId);

            if (apiResult.wasSuccessful) {
                MobX.runInAction(() => {
                    if (apiResult.payload) {
                        var userQuote = this.model.userQuotes.find(uq => uq.id === propertyQuoteId);

                        if (!!userQuote) {
                            this.model.userQuotes.remove(userQuote);
                        }
                    }
                });
            }
        } catch (exception) {
            this.setIsErrored(true);
        } finally {
            this.setIsSaving(false);
        }
    };

    // #endregion Server Api

    // #region Viewmodel Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Viewmodel Boilerplate
}
