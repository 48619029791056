// Libraries
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Box, Grid, Button } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

// Content
import Puddles from "Content/homeBG2.png";

// Custom
import { UsersTable } from "../../AdminComponents/AdminDashboard/UsersTable";
import { Footer } from "../../Components/Footer/Footer";
import { useRouter } from "../../Globals/Base";
import { useAdminStyles, useAdminUserReportStyles } from "../../StylesAppSpecific/Admin/AdminDashboard";
import { DivWrapper, DivWrapperBGFixed } from "../../StylesAppSpecific/GridHelperStyling";
import { easternStandardTime, dateTimeFormat, diffDates } from "../../Utils/Time";
import WidgetQuotesViewModel from "../../ViewModels/Admin/WidgetQuotesViewModel";

export const AdminWidgetReport: React.FC = () => {
    const adminClasses = useAdminStyles();
    const classes = useAdminUserReportStyles();
    const { history, location, match } = useRouter();
    const { id } = match.params as any;
    const [viewModel] = useState(() => new WidgetQuotesViewModel(id));

    useEffect(() => {
        // If the viewmodel is to allow navigation. set the viewmodel router.
        viewModel.setRouter(history, location);

        // With SPAs, we need to move back to the top of the page.
        window.scrollTo(0, 0);
    }, []);

    const renderBackButton = () => {
        return (
            <React.Fragment>
                <Grid xs={12} item>
                    <Button className={adminClasses.navigationButton} onClick={viewModel.backToDashboard} startIcon={<ChevronLeftIcon />} variant="contained">
                        Back to widgets
                    </Button>
                </Grid>
            </React.Fragment>
        );
    };

    const renderReportsTableTitle = () => {
        return (
            <div className={classes.tableTitle}>
                <span className={classes.tableTitlePrefix}>Quote results</span>
                {/* viewModel.name */}
            </div>
        );
    };

    const renderReportsTableAndFilterBar = () => {
        return (
            <React.Fragment>
                <Grid xs={12} item>
                    <UsersTable
                        data={viewModel.WidgetQuotes}
                        columns={[
                            {
                                title: "Property address",
                                field: "address1",
                            },
                            {
                                title: "Latest quote date (ET)",
                                field: "createdDate",
                                render: (rowData: any) => {
                                    return easternStandardTime(rowData.createdDate).format(dateTimeFormat);
                                },
                                customFilterAndSearch: (value: any, rowData: any) => {
                                    return (
                                        easternStandardTime(rowData.createdDate)
                                            .format(dateTimeFormat)
                                            .indexOf(value) != -1
                                    );
                                },
                                customSort: (lhsValue: any, rhsValue: any) => {
                                    return diffDates(lhsValue.createdDate, rhsValue.createdDate);
                                },
                                defaultSort: "desc",
                            },
                            {
                                title: "Quote source",
                                field: "widgetSource",
                            },
                        ]}
                        onSelect={viewModel.selectQuoteResult}
                        title={renderReportsTableTitle()}
                    />
                </Grid>
            </React.Fragment>
        );
    };

    return useObserver(() => (
        <React.Fragment>
            <DivWrapperBGFixed additionalcss={"background-image: url(" + Puddles + ");"} className={adminClasses.root}>
                <Box>
                    <Box>
                        <Grid container spacing={5}>
                            {renderBackButton()}
                            {renderReportsTableAndFilterBar()}
                            <div style={{ display: "none" }}>{viewModel.WidgetQuotes.length}</div>
                        </Grid>
                    </Box>
                </Box>
            </DivWrapperBGFixed>
            <Footer isTemp={false} />
        </React.Fragment>
    ));
};
