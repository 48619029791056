import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { TestModel } from "../Models/TestModel";
import { FieldType } from "../Utils/Utils";
import { LoginModel } from "../../Custom/Models";

//extend viewmodel base and passing your model as the generic type
export class TestViewModel extends ViewModelBase<TestModel> {
    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new TestModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(TestModel);
    }

    public TestBearer = () => {
        this.Get("api/example/testbearer");
    };

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<TestModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    //public afterUpdate: undefined;
    //public beforeUpdate: undefined;
    afterUpdate(fieldName: keyof FieldType<TestModel>, value: any): void {
        //Do something like call an api or whatever
        //Can use this instead of mobx reaction
        return;
    }
    beforeUpdate(fieldName: keyof FieldType<TestModel>, value: any): any {
        //Do something and return the new value if you want or just use it to perform another action
        if (value === "something" && fieldName === "firstName") {
            value = "boo";
        }
        return value;
    }
}
