/**
 * Name to quoteName for Hiscox
 */
export const resultName = (name: string, quoteMisc: string): string => {
    let retVal: string = name;
    if (name === "Hiscox FloodPlus") {
        switch (quoteMisc) {
            case "1":
                retVal = "Hiscox FloodPlus Full Value Policy";
                break;

            case "2":
                retVal = "Hiscox FloodPlus Option 2";
                break;

            case "3":
                retVal = "Hiscox FloodPlus Option 3";
                break;

            default:
                retVal = name;
        }
    }

    return retVal;
};

/**
 *
 */
export const policyExtras = (quoteMisc: string): string => {
    let retVal: string = name;
    switch (quoteMisc) {
        case "1":
            retVal = "Hiscox FloodPlus Full Value Policy (Includes Building, Contents, Other Structures & Additional Living Expense)";
            break;

        case "2":
            retVal = "Hiscox FloodPlus Option 2 (Building Coverage only, $250k maximum limit)";
            break;

        case "3":
            retVal = "Hiscox FloodPlus Option 3 (Building Coverage $250k maximum limit & Contents Coverage $100k maximum limit)";
            break;

        default:
            retVal = name;
    }

    return retVal;
};
