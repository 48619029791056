// Libraries
import * as MobX from "mobx";
import { runInAction } from "mobx";

// Core
import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";

// Custom
import { Server } from "Custom/Globals/AppUrls";
import { WidgetModel } from "Custom/Models";

/**
 * Widget viewmodel.
 */
export class WidgetViewModel extends ViewModelBase<WidgetModel> {
    /**
     * Constructor.
     */
    constructor() {
        super(new WidgetModel());
        this.setDecorators(WidgetModel);
    }

    /**
     * An observable collection of widgets.
     */
    @MobX.observable
    public Widgets = MobX.observable<WidgetModel>([]);

    // #region Server Api

    /**
     * Loads the widgets from the server.
     */
    public load = async (): Promise<void> => {
        try {
            this.setIsLoading(true);

            const apiResult = await this.Post<WidgetModel[]>(Server.Api.Widgets.GetAllWidgets);

            if (apiResult.wasSuccessful) {
                runInAction(() => this.Widgets.replace(apiResult.payload));
            } else {
                // Error. What to do?
                this.setIsErrored(true);
            }
        } catch (exception) {
            // Error. What to do?
            this.setIsErrored(true);
        } finally {
            this.setIsLoading(false);
        }
    };

    // #endregion Server Api

    // #region Viewmodel Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Viewmodel Boilerplate
}
