import styled from "styled-components";

export const DetailsStyles: any = styled.div`
    background-color: #3a4650;
    padding: 5px 0 40px;

    > div {
        margin-bottom: 0;
        padding-bottom: 0;

        > h2 {
            margin-bottom: 17px;
        }
    }

    > div.btns {
        padding-top: 80px;
        display: flex;
        justify-content: center;

        button {
            border-radius: 1000px;
            width: 200px;
            margin: 0 7.5px;
            padding: 14px 0 15px;
            color: white;
            font-weight: bold;
            font-size: 1.5rem;
            line-height: 100%;
        }

        button.save-btn {
            background-color: #129bdb;
        }

        button.cancel-btn {
            background-color: #333333;
        }
    }
`;