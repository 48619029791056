import { LocationEditContainer } from "./Styles";
import { EditPreviousOptions } from ".";
import React from "react";
import iconAddSomeDetail from "Content/icon-AddSomeDetail.svg";
import { StoresInstance } from "../../Stores";
import { USDFormat } from "../../../Core/Utils/Utils";

export interface IYouYourEdit {
    editlink?: string;
}

export const YouYourEdit: React.FC<IYouYourEdit> = props => {
    const domainStores = StoresInstance.domain;
    const quoteRequestVM = domainStores.QuoteRequestViewModel;

    const editLink = () => {
        if (props.editlink) {
            return props.editlink;
        } else {
            //return "/coveragedetails";
            return "/Step2";
        }
    };

    return (
        <>
            <EditPreviousOptions image={iconAddSomeDetail} editLink={editLink()}>
                <LocationEditContainer>You and your property details</LocationEditContainer>
            </EditPreviousOptions>
        </>
    );
};
