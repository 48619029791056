import * as MobX from "mobx";

import {
    Contains,
    Equals,
    IsDate,
    IsEmail,
    IsFQDN,
    IsInt,
    IsNotEmpty,
    IsPhoneNumber,
    Length,
    Matches,
    Max,
    Min,
    ValidateIf,
    validate,
    validateOrReject,
} from "class-validator";

import { ModelBase } from "../../Core/Models/ModelBase";
import { StoresInstance } from "../Stores";
import { isEmptyOrWhitespace } from "../../Core/Utils/Utils";

export class QuoteRequestModelV2 extends ModelBase<QuoteRequestModelV2, QuoteRequestModelV2DTO> {
    // #region User

    @MobX.computed
    public get requestOwnerFirstName() {
        return StoresInstance.domain.AccountStore.FirstName;
    }

    @MobX.computed
    public get requestOwnerLastName() {
        return StoresInstance.domain.AccountStore.LastName;
    }

    @MobX.computed
    public get requestOwnerEmail() {
        return StoresInstance.domain.AccountStore.Email;
    }

    @MobX.computed
    public get requestOwnerPhone() {
        return StoresInstance.domain.AccountStore.Phone;
    }

    @MobX.computed
    public get requestOwnerIsAgent() {
        return StoresInstance.domain.AccountStore.IsProAccount;
    }

    // #endregion User

    // #region Policy Holder

    @MobX.computed
    public get requestPolicyHolderFirstName() {
        let accountStore = StoresInstance.domain.AccountStore;
        return accountStore.IsProAccount || accountStore.IsRealtorAccount ? this.policyHolderFirstName : accountStore.FirstName;
    }

    @MobX.computed
    public get requestPolicyHolderLastName() {
        let accountStore = StoresInstance.domain.AccountStore;
        return accountStore.IsProAccount || accountStore.IsRealtorAccount ? this.policyHolderLastName : accountStore.LastName;
    }

    @MobX.computed
    public get requestPolicyHolderEmail() {
        let accountStore = StoresInstance.domain.AccountStore;
        return accountStore.IsProAccount || accountStore.IsRealtorAccount ? this.policyHolderEmail : accountStore.Email;
    }

    @MobX.computed
    public get requestPolicyHolderPhone() {
        let accountStore = StoresInstance.domain.AccountStore;
        return accountStore.IsProAccount || accountStore.IsRealtorAccount ? this.policyHolderPhoneNumber : accountStore.Phone;
    }

    // #endregion Policy Holder

    // #region User (Data Input Only)

    // The user is the person responsible for generating the quote.
    // If the user is an agent, they are likely to be generating the
    // quote on behalf of a client who owns the property.
    @MobX.observable
    @IsNotEmpty({ message: "You must enter your first name" })
    public firstName: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "You must enter your last name" })
    public lastName: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Email address is required" })
    @IsEmail({}, { message: "Email address is not correct" })
    public email: string = "";

    @MobX.observable
    @IsPhoneNumber("US", { message: "Invalid phone number" })
    public phone: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Password is required" })
    //@Matches(new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/))
    public password: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Password is required" })
    //@Matches(new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/))
    public confirmPassword: string = "";

    @MobX.observable
    @Equals(true, { message: "Required" })
    public terms: boolean = false;

    // #rendregion  User (Data Input Only)

    // #region Policy Holder (Data Input Only)

    // The policy holder owns the property for awhich a quote is
    // being generated. If the user is an agent, the policy holder
    // will be a client.
    @MobX.observable
    @ValidateIf(o => o.requestOwnerIsAgent)
    @IsNotEmpty({ message: "You must enter your first name" })
    public policyHolderFirstName = "";

    @MobX.observable
    @IsNotEmpty({ message: "You must enter your last name" })
    @ValidateIf(o => o.requestOwnerIsAgent)
    public policyHolderLastName = "";

    @MobX.observable
    @IsNotEmpty({ message: "Email address is required" })
    @IsEmail({}, { message: "Email address is not correct" })
    @ValidateIf(o => o.requestOwnerIsAgent)
    public policyHolderEmail = "";

    @MobX.observable
    @IsPhoneNumber("US", { message: "Invalid phone number" })
    @ValidateIf(o => o.requestOwnerIsAgent)
    public policyHolderPhoneNumber = "";

    // #endregion Policy Holder (Data Input Only)

    // #region Property Address

    // The address of the property for which a quote is being generated.
    // At the moment, the address of the property and the address of the
    // policy holder are the same.
    @MobX.observable
    public address: string = "";

    @MobX.observable
    public city: string = "";

    @MobX.observable
    public county: string = "";

    @MobX.observable
    public state: string = "";

    @MobX.observable
    public zipcode: string = "";

    // #region Property Address

    @MobX.observable
    public isAgentGenerated = false;

    @MobX.observable
    public buildingCoverage: number = 75000;

    @MobX.observable
    public contentsCoverage: number = 25000;

    @MobX.observable
    public deductible: number = 1000;

    @MobX.observable
    @IsNotEmpty({ message: "You must select an option" })
    public insurancePolicy: string = "";

    @MobX.observable
    public dateEffective: string | null = null;

    @MobX.observable
    @IsNotEmpty({ message: "You must enter a year" })
    @Min(1000, { message: "Must be a valid year" })
    @Max(9999, { message: "Must be a valid year" })
    public yearBuilt: number | string = "";

    @MobX.observable
    @IsNotEmpty({ message: "You must select a value" })
    public numberOfFloors: number | string = "";

    @MobX.observable
    @IsNotEmpty({ message: "You must enter a value" })
    public buildingSqft: number | null = null;

    @MobX.observable
    @IsNotEmpty({ message: "You must choose a response" })
    public isOverWater?: "true" | "false";

    @MobX.observable
    @IsNotEmpty({ message: "You must choose a response" })
    public isMobileHome?: "true" | "false";

    @MobX.observable
    @IsNotEmpty({ message: "You must choose a response" })
    public hasGarage?: "true" | "false";

    @MobX.observable
    @IsNotEmpty({ message: "You must select an option" })
    public constructionType: string = "";

    @MobX.observable
    public buildingType: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "You must select an option" })
    public foundationType: string = "";

    @MobX.observable
    public hasCrawlSpace?: "true" | "false" = "false";

    @MobX.observable
    @IsNotEmpty({ message: "You must choose a response" })
    public hasBasement: "" | "true" | "false" = "";

    @MobX.observable
    public enclosureTypesArr = MobX.observable<string>([]);

    @MobX.observable
    public enclosureTypes: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "You must choose a response" })
    public isNewProperty?: "true" | "false";

    @MobX.observable
    @IsNotEmpty({ message: "You must select an option" })
    public ownershipType: string = "";

    @MobX.observable
    public occupationType: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "You must select an option" })
    public propertyOccupy?: number;

    @MobX.observable
    public insurancePolicyType: string = "";

    @MobX.observable
    public elevationCertificateUrl: string = "";

    @MobX.observable
    public latitude: string = "";

    @MobX.observable
    public longitude: string = "";

    @MobX.observable
    public elevationCertificate?: File;

    //FEMA Data
    @MobX.observable
    public fldZone?: string = "";

    @MobX.observable
    public communityNumber?: string = "";

    @MobX.observable
    public mapPanel?: string = "";

    @MobX.observable
    public mapSuffix?: string = "";

    //Property Type (from Step1)
    @MobX.observable
    public chosenPropertyType?: string;

    //Mobile/Manufactured home specific
    @MobX.observable
    public length?: number;

    @MobX.observable
    public width?: number;

    @MobX.observable
    @IsNotEmpty({ message: "Make is required" })
    public make?: string = "";

    @MobX.observable
    public model?: string = "";

    @MobX.observable
    public serialNumber?: string = "";

    @MobX.observable
    public anchorType?: string = "";

    //Condominium
    @MobX.observable
    public floorLocated?: number;

    @MobX.observable
    public unitsInBuilding?: number;

    @MobX.observable
    public hasElevator?: "true" | "false";

    //Commercial
    @MobX.observable
    public businessType?: string = "";

    //RCBAP
    @MobX.observable
    public totalBuildingValue?: number = 0;

    // #region Widget source
    @MobX.observable
    public widgetSource: string = "";
    // //#endregion Widget source

    public fromDto = (model: QuoteRequestModelV2DTO) => {};

    public toDto = () => {
        return {
            firstName: this.requestOwnerFirstName,
            lastName: this.requestOwnerLastName,
            email: this.requestOwnerEmail,
            phone: this.requestOwnerPhone,

            policyHolderFirstName: this.requestPolicyHolderFirstName,
            policyHolderLastName: this.requestPolicyHolderLastName,
            policyHolderPhoneNumber: this.requestPolicyHolderPhone,
            policyHolderEmail: this.requestPolicyHolderEmail,

            terms: this.terms,
            isAgentGenerated: this.requestOwnerIsAgent,

            address: this.address,
            city: this.city,
            county: this.county,
            state: this.state,
            zipCode: this.zipcode,

            buildingCoverage: this.buildingCoverage,
            contentsCoverage: this.contentsCoverage,
            deductible: this.deductible,
            dateEffective: this.dateEffective,

            yearBuilt: this.yearBuilt,
            numberOfFloors: this.numberOfFloors,
            buildingSqft: this.buildingSqft,
            isOverWater: this.isOverWater,
            isMobileHome: this.isMobileHome,
            hasGarage: this.hasGarage,
            constructionType: this.constructionType,
            buildingType: this.buildingType,
            foundationType: this.foundationType,
            hasCrawlSpace: this.hasCrawlSpace,
            hasBasement: this.hasBasement,
            enclosureTypesArr: this.enclosureTypesArr,
            enclosureTypes: this.enclosureTypes,
            isNewProperty: this.isNewProperty,
            ownershipType: this.ownershipType,
            occupationType: this.occupationType,
            propertyOccupy: this.propertyOccupy,
            insurancePolicyType: this.insurancePolicyType,
            elevationCertificateUrl: this.elevationCertificateUrl,
            insurancePolicy: this.insurancePolicy,
            elevationCertificate: this.elevationCertificate,
            latitude: this.latitude,
            longitude: this.longitude,

            //Feme data
            fldZone: this.fldZone,
            communityNumber: this.communityNumber,
            mapPanel: this.mapPanel,
            mapSuffix: this.mapSuffix,

            //Property Type
            chosenPropertyType: this.chosenPropertyType,

            //Mobile/Manufactured home specific
            length: this.length,
            width: this.width,
            make: this.make,
            model: this.model,
            serialNumber: this.serialNumber,
            anchorType: this.anchorType,

            //Condominium
            floorLocated: this.floorLocated,
            unitsInBuilding: this.unitsInBuilding,
            hasElevator: this.hasElevator,

            //Commercial
            businessType: this.businessType,

            //RCBAP
            totalBuildingValue: this.totalBuildingValue,

            widgetSource: this.widgetSource,
        };
    };

    @MobX.action
    public setCertificate = (file: string): void => {
        this.elevationCertificateUrl = file;
    };

    @MobX.action
    public resetDetails = () => {
        this.yearBuilt = "";
        this.numberOfFloors = "";
        this.buildingSqft = null;
        this.isOverWater = undefined;
        this.isMobileHome = undefined;
        this.hasGarage = undefined;
        this.constructionType = "";
        this.buildingType = "";
        this.foundationType = "";
        this.hasCrawlSpace = "false";
        this.hasBasement = "";
        this.enclosureTypesArr = MobX.observable<string>([]);
        this.enclosureTypes = "";
        this.isNewProperty = undefined;
        this.dateEffective = "";
        this.ownershipType = "";
        this.occupationType = "";
        this.propertyOccupy = undefined;
        this.insurancePolicyType = "";
        this.elevationCertificateUrl = "";
        this.insurancePolicy = "";
        this.elevationCertificate = undefined;
        this.isAgentGenerated = false;
        this.policyHolderFirstName = "";
        this.policyHolderLastName = "";
        this.policyHolderPhoneNumber = "";
        this.policyHolderEmail = "";
        //Fema Data
        this.fldZone = "";
        this.communityNumber = "";
        this.mapPanel = "";
        this.mapSuffix = "";
        //Property Type from Step1
        this.chosenPropertyType = "";
        //Mobile/Manufactured home specific
        this.length = 0;
        this.width = 0;
        this.make = "";
        this.model = "";
        this.serialNumber = "";
        this.anchorType = "";
        //Condominium
        this.floorLocated = undefined;
        this.unitsInBuilding = undefined;
        this.hasElevator = undefined;
        //Commercial
        this.businessType = "";
        //RCBAP
        this.totalBuildingValue = 0;
    };

    @MobX.action
    public reset = () => {
        this.firstName = "";
        this.lastName = "";
        this.address = "";
        this.city = "";
        this.county = "";
        this.state = "";
        this.zipcode = "";
        this.buildingCoverage = 75000;
        this.contentsCoverage = 25000;
        this.deductible = 1000;
        this.latitude = "";
        this.longitude = "";
        this.email = "";
        this.phone = "";
        this.terms = false;
        this.widgetSource = "";
        this.resetDetails();
    };

    @MobX.computed
    public get isValidRequestOwner() {
        return isEmptyOrWhitespace(this.requestOwnerFirstName) && isEmptyOrWhitespace(this.requestOwnerLastName);
    }

    @MobX.computed
    public get isValidRequestPolicyHolder() {
        return false;
    }
}

export interface QuoteRequestModelV2DTO {
    id: string;
    quoteRequestId: number;

    firstName: string;
    lastName: string;
    email: string;
    phone: string;

    policyHolderFirstName: string;
    policyHolderLastName: string;
    policyHolderPhoneNumber: string;
    policyHolderEmail: string;

    terms: boolean;
    isAgentGenerated: boolean;

    address: string;
    city: string;
    county: string;
    state: string;
    zipcode: string;

    buildingCoverage: number;
    contentsCoverage: number;
    deductible: number;
    dateEffective: string;

    yearBuilt: number;
    numberOfFloors: number;
    buildingSqft: number;
    isOverWater?: "true" | "false";
    isMobileHome?: "true" | "false";
    hasGarage?: "true" | "false";
    constructionType: string;
    buildingType: string;
    foundationType: string;
    hasCrawlSpace?: "true" | "false";
    hasBasement: "" | "true" | "false";
    enclosureTypesArr: [];
    enclosureTypes: string;
    isNewProperty?: "true" | "false";
    ownershipType: string;
    occupationType: string;
    propertyOccupy?: number;
    insurancePolicyType: string;
    elevationCertificateUrl: string;
    insurancePolicy: string;
    elevationCertificate: File;
    latitude: string;
    longitude: string;

    //Fema data
    fldZone: string;
    communityNumber: string;
    mapPanel: string;
    mapSuffix: string;

    //Property Type from Step1
    chosenPropertyType?: string;

    //Mobile/Manufactured home specific
    length?: number;
    width?: number;
    make?: string;
    model?: string;
    serialNumber?: string;
    anchorType?: string;
    //Condominium
    floorLocated?: number;
    unitsInBuilding?: number;
    hasElevator?: "true" | "false";
    //Commercial
    businessType?: string;
    //RCBAP
    totalBuildingValue?: number;

    widgetSource: string;
}
