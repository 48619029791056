/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect } from "react";
import MFIheaderlogo from "Content/logoForDarkBG.png";
import TakeMeHome from "Content/Group1985.png";
import bgimage from "Content/500bg.jpg";
import { Box } from "@material-ui/core";
import styled from "styled-components";
import { useMediaQuery } from "../../Core/Utils/Media";
import * as GoogleAnalytics from "../../Core/Utils/App/GoogleAnalyticsUtils";

const OuterContainer = styled(Box)`
    height: 90vh;
    background-image: url(${bgimage});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;
const Container = styled(Box)`
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.5);
`;

type Props = {
    error?: string;
};
export const ErrorView: React.FC<Props> = (props: Props) => {
    let isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;

    useEffect(() => {
        GoogleAnalytics.sendException(props.error!, true);
    }, []);
    return (
        <OuterContainer>
            <Container>
                {!isMobile && (
                    <Box>
                        <img src={MFIheaderlogo} alt={"My Flood Insurance"} />
                    </Box>
                )}
                <Box textAlign={"center"}>
                    <h1>We are currently performing maintenance.</h1>
                    <h2>We will be up and running again shortly</h2>
                </Box>
                <Box>
                    <a href={"/"}>
                        <img style={{ cursor: "pointer" }} src={TakeMeHome} alt={"Take me home"} />
                    </a>
                </Box>
            </Container>
        </OuterContainer>
    );
};
ErrorView.defaultProps = {
    error: "Unknown error",
};
