import * as MobX from "mobx";

import { IsNotEmpty } from "class-validator";
// Libs
import { ModelBase } from "../../Core/Models/ModelBase";

// App

export class AccessLevel extends ModelBase<AccessLevel, AccessLevelDTO> {
    @MobX.observable
    public value: string = "";
    @MobX.observable
    public name: string = "";

    @MobX.action
    public fromDto = (model: AccessLevelDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                this[key] = model[key];
            }
        }
    };

    public toDto = (model: AccessLevel) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                model[key] = this[key];
            }
        }
    };
}

export interface AccessLevelDTO {
    value: string;
    name: string;
}
