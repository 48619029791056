// Libraries
import React from "react";
import MaterialTable, { Column } from "material-table";
import { Box } from "@material-ui/core";

// Custom
import { useAdminDashboardTableStyles } from "../../StylesAppSpecific/Admin/AdminDashboard";

interface IProps {
    columns: Column<any>[];
    data: any[];
    onSelect: (data: any) => void;
    title: string | JSX.Element;
}

export const UsersTable: React.FC<IProps> = (props: IProps) => {
    const classes = useAdminDashboardTableStyles();

    const handleRowClick = (e: any, rowData: any) => {
        props.onSelect(rowData);
    };

    return (
        <Box className={classes.root}>
            <MaterialTable
                columns={props.columns}
                options={{
                    pageSize: 30,
                    pageSizeOptions: [10, 30, 60],
                    emptyRowsWhenPaging: false,
                }}
                data={props.data}
                onRowClick={handleRowClick}
                title={props.title}
            />
        </Box>
    );
};
