import React, { useState, useContext, useEffect } from "react";
import { Fade, Box, Avatar, Typography, Button, Paper, FormControl, Grid, Input, TextField, FormControlLabel } from "@material-ui/core";
import { useRouter } from "Core/Utils";
import { StoresInstance } from "Custom/Stores";
import { EditableInput } from "Core/Components/EditableInput";
import { TestViewModel } from "../ViewModels/TestViewModel";
import { autorun } from "mobx";
import { Validation } from "../Components/Validation";
import { TestModel } from "../Models/TestModel";
import styled from "styled-components";
import { KeyValuePair } from "../Models/KeyValuePair";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import ReactJson from "react-json-view";

let viewModel = new TestViewModel();

const domainStores = StoresInstance.domain;

export const TestStyle = styled(EditableInput)<any>``;

export const TestPage: React.FunctionComponent = () => {
    const { history, location, match } = useRouter();
    const [errorMessage, setErrorMessage] = useState("");
    const [validMessage, setValidMessage] = useState("");
    const proxy = viewModel.getContext();
    const [bind, { text, password }] = Validation<TestModel>(viewModel);
    const [json, setJson] = useState("{}");

    //useEffect below only gets run on initial render
    useEffect(() => {
        // *optional* passing the router into the viewmodel so we can navigate from there
        console.log("First render");
        viewModel.setRouter(history, location);
        autorun(() => {
            //let a = viewModel.getModel.firstName;
            let p = JSON.stringify(viewModel.getModel, null, 2);
            setJson(p);
        });

        //This gets run when the page is exited
        return () => {
            console.log("Unmounting");
        };
    }, []);

    //isValid will check all fields to make sure they are in a valid state.
    const doSubmit = async (e: any) => {
        e.preventDefault();
        if (viewModel.isModelValid()) {
            //Do stuff here
            setErrorMessage("");
            setValidMessage("Form is valid");
        } else {
            setValidMessage("");
            setErrorMessage("Form is not valid");
        }
    };

    console.log("Rendered");

    return (
        <Fade in timeout={1000}>
            <Paper>
                <Box padding="5px">
                    <form onSubmit={doSubmit}>
                        {errorMessage !== "" && (
                            <Typography variant="h2" style={{ color: "red" }}>
                                {errorMessage}
                            </Typography>
                        )}
                        {validMessage !== "" && (
                            <Typography variant="h2" style={{ color: "green" }}>
                                {validMessage}
                            </Typography>
                        )}
                        {/*Here we are using the *optional* generic type of the model to give us intellisense on the fieldname*/}
                        <Grid container>
                            <Grid item md={6}>
                                <Box width="90%" height="800px">
                                    <EditableInput<TestModel>
                                        type="text"
                                        label="First Name"
                                        validateOnBlur={true}
                                        viewModel={viewModel}
                                        fieldName={bind.firstName}
                                    />
                                    <TestStyle type="text" label="Last Name" validateOnBlur={true} viewModel={viewModel} fieldName={"lastName"} />
                                    <Grid item xs={6}>
                                        <EditableInput
                                            type="select"
                                            label="Age"
                                            viewModel={viewModel}
                                            fieldName={"age"}
                                            selectItems={
                                                [
                                                    { value: 10, key: "Ten" },
                                                    { value: 20, key: "Twenty" },
                                                    { value: 30, key: "Thirty" },
                                                ] as KeyValuePair[]
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <EditableInput type="number" label="Age" viewModel={viewModel} fieldName="age" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="standard-basic"
                                            label={"Username"}
                                            {...text(bind.userName, { validateOnBlur: true })}
                                            helperText={viewModel.getError(bind.userName)}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="standard-basic"
                                            label={"Address Line 1"}
                                            {...text(bind.address.addressLine1, { validateOnBlur: true })}
                                            helperText={viewModel.getError(bind.address.addressLine1)}
                                        />
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item md={6}>
                                {/*<textarea style={{ height: "100%", width: "100%" }} defaultValue={json} />*/}
                                <ReactJson style={{ fontSize: "12px" }} src={JSON.parse(json)} />
                            </Grid>
                        </Grid>
                        <Button type="submit" fullWidth variant="contained" color="primary">
                            {viewModel.IsLoading ? "Please Wait..." : "Sign In"}
                        </Button>
                    </form>
                </Box>
                <Button color="inherit" onClick={viewModel.TestBearer}>
                    Test Page
                </Button>
            </Paper>
        </Fade>
    );
};
