// Libraries
import React from "react";
import { useMediaQuery } from "Core/Utils/Media";

// Styling & images
import { GridSpaced, TitleWrapper } from "../../StylesAppSpecific/GridHelperStyling";
import { Grid } from "@material-ui/core";
import { ExpandDetailContainer, QandAContainer } from "./QandAStyling";
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from "@material-ui/core/";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styled from "styled-components";

const StyledDetails = styled(ExpansionPanelDetails)`
    border-top: 1px solid #24313c;
    font-weight: 500;
`;

export const QandAFAQ: React.FC = () => {
    let isMobile: boolean;
    isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;

    return (
        <>
            <GridSpaced container padding={!isMobile ? "0px" : "15px 0px 0px"} margin={"0px auto"} additionalcss={"max-width: 1107px; width: 100%;"}>
                <Grid item xs={12} sm={12}>
                    <QandAContainer>
                        <h2>Frequently asked questions</h2>
                    </QandAContainer>
                </Grid>
            </GridSpaced>

            <GridSpaced container padding={"0px"} margin={"0px auto"} additionalcss={"max-width: 1107px; width: 100%;"}>
                <Grid item xs={12} sm={12}>
                    <ExpandDetailContainer>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            What does flood insurance cover?
                        </ExpansionPanelSummary>
                        <StyledDetails>
                            <p>Flood insurance covers physical damage due to rising water. It covers building and contents up to your policy limits.</p>
                        </StyledDetails>
                    </ExpandDetailContainer>

                    <ExpandDetailContainer>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            Does my home insurance policy cover flooding?
                        </ExpansionPanelSummary>
                        <StyledDetails>
                            <p>No, a typical home insurance policy does not cover flooding damage.</p>
                        </StyledDetails>
                    </ExpandDetailContainer>

                    <ExpandDetailContainer>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            Do I need flood insurance?
                        </ExpansionPanelSummary>
                        <StyledDetails>
                            <p>
                                With more than 25% of flood insurance claims coming from FEMA designated low-risk areas, it is highly recommended that everyone
                                carry this valuable coverage.
                            </p>
                        </StyledDetails>
                    </ExpandDetailContainer>

                    <ExpandDetailContainer>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            If I’m affected by a flood won’t FEMA assistance cover the damages?
                        </ExpansionPanelSummary>
                        <StyledDetails>
                            <p>
                                Not necessarily. For FEMA assistance to be made available the president must declare a disaster. This assistance is also a loan
                                that must be repaid with interest.
                            </p>
                        </StyledDetails>
                    </ExpandDetailContainer>

                    <ExpandDetailContainer>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            My area has never flooded, why should I buy a flood insurance policy?
                        </ExpansionPanelSummary>
                        <StyledDetails>
                            <p>
                                The flooding risk of an area is ever-changing and is impacted by several factors including land development and climate change.
                                The average flood loss is approximately $30,000 while the average flood insurance policy is a very small fraction of this.
                            </p>
                        </StyledDetails>
                    </ExpandDetailContainer>

                    <ExpandDetailContainer>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            How much will flood insurance cost?
                        </ExpansionPanelSummary>
                        <StyledDetails>
                            <p>
                                Your policy cost depends on your property’s flood risk, the amount of coverage desired, and the location of the property. We can
                                help you build a flood insurance package to meet your needs and budget.
                            </p>
                        </StyledDetails>
                    </ExpandDetailContainer>
                </Grid>
            </GridSpaced>
        </>
    );
};
