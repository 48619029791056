// Libraries
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Box, Grid, Button } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

// Content
import ProBackground from "Content/ProBackground.png";
import BinIcon from "Content/bin.svg";
import Warning from "Content/warning.svg";

// Custom
import { UsersTable } from "../../AdminComponents/AdminDashboard/UsersTable";
import { Footer } from "../../Components/Footer/Footer";
import { useRouter } from "../../Globals/Base";
import { useAdminStyles, useAdminUserReportStyles } from "../../StylesAppSpecific/Admin/AdminDashboard";
import { DivWrapper, DivWrapperBGFixed } from "../../StylesAppSpecific/GridHelperStyling";
import { easternStandardTime, dateTimeFormat, diffDates } from "../../Utils/Time";
import AgentUserReportViewModel from "../../ViewModels/Agent/AgentUserReportViewModel";
import { TableContainer, DeleteButton } from "../../StylesAppSpecific/AgentReportStyling";
import { WarningModal, OkButton, CancelButton } from "../../StylesAppSpecific/ModalStyling";

export const AgentUserReport: React.FC = () => {
    const adminClasses = useAdminStyles();
    const classes = useAdminUserReportStyles();
    const { history, location, match } = useRouter();
    const { id } = match.params as any;
    const [viewModel] = useState(() => new AgentUserReportViewModel(id));
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [quoteId, setQuoteId] = React.useState<string>("");

    useEffect(() => {
        // If the viewmodel is to allow navigation. set the viewmodel router.
        viewModel.setRouter(history, location);

        // With SPAs, we need to move back to the top of the page.
        window.scrollTo(0, 0);
    }, []);

    const doDelete = () => {
        viewModel.deletePropertyQuote(quoteId).then(() => {
            setQuoteId("");
        });
        setOpenModal(false);
    };

    const openDeleteModal = (quoteId: string) => {
        setQuoteId(quoteId);
        setOpenModal(true);
    };

    const closeDeleteModal = () => {
        setOpenModal(false);
        setQuoteId("");
    };

    const deleteModal = (
        <>
            <div className="header">Warning</div>
            <div className="body">
                <img src={Warning} alt="Warning - Are you sure you want to delete this?" />
                <p>Are you sure you want to delete this?</p>
            </div>
            <div className="footer">
                <OkButton
                    onClick={() => {
                        doDelete();
                    }}
                >
                    OK
                </OkButton>
                <CancelButton
                    onClick={() => {
                        closeDeleteModal();
                    }}
                >
                    Cancel
                </CancelButton>
            </div>
        </>
    );

    const renderBackButton = () => {
        return (
            <React.Fragment>
                <Grid xs={12} item style={{ marginBottom: "40px" }}>
                    <Button className={adminClasses.navigationButton} onClick={viewModel.backToDashboard} startIcon={<ChevronLeftIcon />} variant="contained">
                        Back to reports
                    </Button>
                </Grid>
            </React.Fragment>
        );
    };

    const renderReportsTableTitle = () => {
        return (
            <div className={classes.tableTitle}>
                <span className={classes.tableTitlePrefix}>Quote results for </span>
                {viewModel.name} ({viewModel.emailAddress})
            </div>
        );
    };

    const renderDeleteButton = (rowData: any) => {
        return (
            <>
                <DeleteButton
                    onClick={(event: any) => {
                        event.preventDefault();
                        event.stopPropagation();

                        openDeleteModal(rowData.id);
                    }}
                >
                    <img src={BinIcon} alt="Delete"></img>
                </DeleteButton>
            </>
        );
    };

    const renderReportsTableAndFilterBar = () => {
        return (
            <React.Fragment>
                <Grid xs={12} item>
                    <TableContainer>
                        <UsersTable
                            data={viewModel.userQuotes}
                            columns={[
                                {
                                    title: "Property address",
                                    field: "address1",
                                },
                                {
                                    title: "Latest quote date (ET)",
                                    field: "createdDate",
                                    render: (rowData: any) => {
                                        return easternStandardTime(rowData.createdDate).format(dateTimeFormat);
                                    },
                                    customFilterAndSearch: (value: any, rowData: any) => {
                                        return (
                                            easternStandardTime(rowData.createdDate)
                                                .format(dateTimeFormat)
                                                .indexOf(value) != -1
                                        );
                                    },
                                    customSort: (lhsValue: any, rhsValue: any) => {
                                        return diffDates(lhsValue.createdDate, rhsValue.createdDate);
                                    },
                                },
                                {
                                    title: "",
                                    field: "",
                                    render: (rowData: any) => {
                                        return renderDeleteButton(rowData);
                                    },
                                },
                            ]}
                            onSelect={viewModel.selectQuoteResult}
                            title={renderReportsTableTitle()}
                        />
                    </TableContainer>
                </Grid>
            </React.Fragment>
        );
    };

    return useObserver(() => (
        <>
            <DivWrapperBGFixed additionalcss={"background: url(" + ProBackground + ") center center / cover no-repeat;"}>
                <DivWrapper padding="40px 20px 40px 20px" backgroundcolor={"rgba(27, 43, 55, 0.88)"}>
                    <div style={{ maxWidth: "1124px", margin: "0 auto" }}>
                        {renderBackButton()}
                        {renderReportsTableAndFilterBar()}
                    </div>
                </DivWrapper>
            </DivWrapperBGFixed>
            <WarningModal open={openModal} onClose={closeDeleteModal}>
                {deleteModal}
            </WarningModal>
            <Footer isTemp={false} />
        </>
    ));
};
