import { action, computed } from "mobx";
import { FieldType, isEmptyOrWhitespace } from "Core/Utils/Utils";
import { LoginModel } from "../Models";
import { AccountStatus, ApiResult } from "Core/Models";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { TestModel } from "../../Core/Models/TestModel";

export class LoginViewModel extends ViewModelBase<LoginModel> {
    constructor() {
        super(new LoginModel());
        //EN - Havent figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(LoginModel);
        //(window as any).model = this.model;
        //(window as any).proxy = proxy;
    }

    public login = async (): Promise<ApiResult<AccountStatus>> => {
        let apiResult = await this.Post<AccountStatus>("/api/account/login", this.getModel);
        return apiResult;
    };

    public isFieldValid(fieldName: "emailAddress" | "password", value: any): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    //public afterUpdate: undefined;
    afterUpdate(fieldName: keyof FieldType<LoginModel>, value: any): void {
        //Do something like call an api or whatever/
        //Can use this instead of mobx reaction
        return;
    }
    //public beforeUpdate: undefined;
    beforeUpdate(fieldName: keyof FieldType<LoginModel>, value: any): any {
        //Do something and return the new value if you want or just use it to perform another action
        if (value === "something" && fieldName === "emailAddress") {
            value = "boo";
        }
        return value;
    }

    @computed
    public get isEmailAddressValid(): string {
        let retval = "";
        if (isEmptyOrWhitespace(this.getValue<string>("emailAddress"))) return "Email address is required";
        if (this.getValue<string>("emailAddress").length < 4) return "Email address must be greater than 4 characters";

        return retval;
    }

    @computed
    public get isPasswordValid(): string {
        let retval = "";
        if (isEmptyOrWhitespace(this.getValue("password") as string)) return "Password is required";
        if (this.getValue<string>("password").length < 4) return "Password must be greater than 4 characters";

        return retval;
    }
}
