// Libraries
import clsx from "clsx";
import { React, useRouter, useObserver, useState, useEffect } from "../../Globals/Base";
import { FormEvent } from "react";
import { Button, Checkbox, FormControlLabel, Grid, Paper, Typography } from "@material-ui/core";

// Core
import { EditableInput } from "../../../Core/Components/EditableInput";

// Content
import Puddles from "Content/homeBG2.png";

// Custom
import { Footer } from "../../Components/Footer";
import { DivWrapper, DivWrapperBGFixed } from "../../StylesAppSpecific/GridHelperStyling";
import EditWidgetViewModel from "../../ViewModels/Admin/EditWidgetViewModel";
import { useEditStyles } from "./EditStyling";
import { isNullOrEmpty } from "Core/Utils/Utils";

export const WidgetEdit: React.FC = () => {
    const classes = useEditStyles();
    const { history, location, match } = useRouter();
    const { id } = match.params as any;
    const [viewModel] = useState(() => new EditWidgetViewModel(id));
    const [showTag, setShowTag] = useState<boolean>(false);

    // If the viewmodel is to allow navigation. set the viewmodel router.
    useEffect(() => {
        if (!isNullOrEmpty(id)) {
            viewModel.load(id);

            if (id != "0") {
                setShowTag(true);
            }
        }
        viewModel.setRouter(history, location);
    }, []);

    const doSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        viewModel.save();
        if (!isNullOrEmpty(viewModel.getValue("id"))) {
            setShowTag(true);
        }
    };

    const cancel = () => {
        history.push("/admin/editwidget");
    };

    return useObserver(() => (
        <form onSubmit={doSubmit}>
            <DivWrapperBGFixed additionalcss={"background-image: url(" + Puddles + ");"}>
                <DivWrapper className={classes.paperWrapper}>
                    <Paper className={classes.paper}>
                        <Grid container spacing={3}>
                            <Grid item sm={12}>
                                <EditableInput
                                    type="text"
                                    label="Referrer name"
                                    validateOnBlur={true}
                                    viewModel={viewModel}
                                    fieldName="referrerUri"
                                    fullwidth
                                />
                            </Grid>
                            <Grid className={clsx(classes.buttonWrapper, classes.submitButtonWrapper)} item xs={12} sm={6}>
                                <Button className={classes.button} color="primary" type="submit" variant="contained">
                                    Save
                                </Button>
                            </Grid>
                            <Grid className={clsx(classes.buttonWrapper, classes.cancelButtonWrapper)} item xs={12} sm={6}>
                                <Button className={classes.button} color="secondary" onClick={cancel} type="button" variant="contained">
                                    Cancel
                                </Button>
                            </Grid>
                            {showTag === true ? (
                                <>
                                    <Grid item sm={12}>
                                        Here is your widget embed code...
                                    </Grid>
                                    <Grid item sm={12} style={{ textAlign: "center" }}>
                                        {`<iframe src="https://myfloodinsurance.com/widget?referrer=${id}" width="1024px" height="300px"></iframe>`}
                                    </Grid>
                                    <Grid item sm={12} style={{ textAlign: "center" }}>
                                        Copy all of the above and paste it into your pages html.
                                    </Grid>
                                </>
                            ) : (
                                <></>
                            )}
                        </Grid>
                    </Paper>
                </DivWrapper>
            </DivWrapperBGFixed>
            <Footer isTemp={false} />
            <div style={{ display: "none" }}>{viewModel.getValue("id")}</div>
        </form>
    ));
};
