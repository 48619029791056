import React from "react";
import { StoresInstance, Stores, StoresContext } from "../../Custom/Stores";
import { useRouter } from "../../Core/Utils";

export const Wrapper: React.FC = () => {
    const domainStores = StoresInstance.domain;
    const { history } = useRouter();

    domainStores.setWrapper(true);

    history.push("./");

    return <></>
};
