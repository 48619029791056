import { Link } from "react-router-dom";
import React from "react";
import iconEdit from "../../../Content/edit.svg";
import iconEditMobile from "../../../Content/editMobile.svg";
import { styled } from "../../Globals/Base";
import { useMediaQuery } from "Core/Utils";

interface IProps {
    image: string;
    editLink: string;
    heightOverride?: number;
}

export const EditPreviousOptions: React.FC<IProps> = props => {
    const { image, editLink, children, heightOverride } = props;

    const isMobile = !!useMediaQuery("screen and (max-width: 767px)");

    return (
        <>
            <Container additionalcss={heightOverride === undefined ? undefined : `height: ${heightOverride}px !important;`}>
                {isMobile || (
                    <div className="image">
                        <img src={image} alt="" />
                    </div>
                )}
                <div className="contents">{children}</div>
                <Link to={editLink}>
                    <img className="edit" src={isMobile ? iconEditMobile : iconEdit} alt="" />
                </Link>
            </Container>
        </>
    );
};

interface IContainerProps {
    additionalcss?: string;
}

const Container = styled.div<IContainerProps>`
    background-color: rgba(255, 255, 255, 0.2);
    padding: 0 20px;
    margin: 0 1px;
    height: 100%;
    display: flex;
    width: 374px;

    @media (max-width: 767px) {
        width: 100%;
        background-color: #337fbc;
    }

    div.image {
        padding: 5px;
        height: 100%;

        img {
            height: 100%;
            padding: 0;
        }
    }

    a {
        display: contents;
    }

    div.contents {
        padding: 0 15px;
        flex-grow: 1;
        display: flex;
        align-items: center;
    }

    img {
        padding: 0 5px;
        max-width: 48px;
    }

    ${({ additionalcss }) => additionalcss}
`;
