// Libraries
import React from "react";
import { useObserver } from "mobx-react-lite";

// Components
import { StoresInstance, useState } from "Custom/Globals/Base";
import { useMediaQuery } from "Core/Utils/Media/mediaQuery";
import { ContactDetail, RealtorDetails } from "Custom/Components/ContactForm";
import { RealtorRegisterViewModel } from "Custom/ViewModels/RealtorRegisterViewModel";
import { EditableInput } from "Core/Components/EditableInput";
import { PolicyHolder } from "Custom/Components/PropertyCoverage/PolicyHolder";

// Styling & images
import { Checkbox, Grid } from "@material-ui/core";
import { ContactDetailStyle2, PasswordTermsMobileStyle, RealtorRegisterMobileStyle, TermsContainer } from "Custom/StylesAppSpecific/ContactForms";
import { RememberMeLabel, ShowPasswordContainer, ShowPasswordIcon2 } from "Custom/StylesAppSpecific/LoginStyling";
import { GridSpaced, TitleWrapper2 } from "Custom/StylesAppSpecific/GridHelperStyling";
import EyeClosed from "Content/icon-Eye-closed.svg";
import EyeOpen from "Content/icon-Eye-open.svg";
import radioYes from "Content/radioYes.svg";
import radioYesChecked from "Content/radioYes-checked.svg";

interface IProps {
    viewModel: RealtorRegisterViewModel;
}

/**
 * Register Realtor account: used on all property types.
 */
export const RealtorRegister: React.FC<IProps> = ({ viewModel }) => {
    const domainStores = StoresInstance.domain;
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    //const [viewModel] = useState(() => new ProRegisterViewModel());
    const isMobile = !!useMediaQuery("(max-width: 767px)");

    const contactViewModel = domainStores.ContactViewModel;

    let handleShowPassword = (e: any) => {
        setShowPassword(!showPassword);
    };

    let handleShowPassword2 = (e: any) => {
        setShowPassword2(!showPassword2);
    };

    const renderPasswordAndTerms = () => (
        <>
            <ContactDetailStyle2 className="pro-register-step" additionalcss="background-color: transparent;">
                <h2>Set your password</h2>
                <p className="pwd-hint">Password must contain at least 8 characters and include upper & lower case letters and at least one digit.</p>
                <ShowPasswordContainer className="input-wrapper">
                    <EditableInput
                        viewModel={viewModel}
                        inputProps={{ placeholder: "New password", autoComplete: "new-password" }}
                        fieldName="password"
                        type={showPassword ? "text" : "password"}
                    />
                    <ShowPasswordIcon2 onClick={(e: any) => handleShowPassword(e)} title={showPassword ? "Hide password" : "Show password"}>
                        <img src={showPassword ? EyeClosed : EyeOpen} alt="" />
                    </ShowPasswordIcon2>
                </ShowPasswordContainer>
                <ShowPasswordContainer className="input-wrapper">
                    <EditableInput
                        viewModel={viewModel}
                        inputProps={{ placeholder: "Confirm password", autoComplete: "new-password" }}
                        fieldName="confirmPassword"
                        type={showPassword2 ? "text" : "password"}
                    />
                    <ShowPasswordIcon2 onClick={(e: any) => handleShowPassword2(e)} title={showPassword2 ? "Hide password" : "Show password"}>
                        <img src={showPassword2 ? EyeClosed : EyeOpen} alt="" />
                    </ShowPasswordIcon2>
                </ShowPasswordContainer>
            </ContactDetailStyle2>
            <div className="pro-register-step alignleft">
                <TermsContainer className="terms-container" additionalcss="justify-content: left;">
                    <Checkbox
                        name="hasAcceptedTerms"
                        icon={<img src={radioYes} alt="Y" />}
                        checkedIcon={<img src={radioYesChecked} alt="N" />}
                        checked={viewModel.getValue("hasAcceptedTerms")}
                        onChange={e => {
                            viewModel.setValue("hasAcceptedTerms", e.target.checked);
                            viewModel.isFieldValid("hasAcceptedTerms", e.target.checked);
                        }}
                    />

                    <label>
                        I accept Aurora Insurance Technologies, Inc.{" "}
                        <a href="/terms" target="_blank">
                            Terms of use
                        </a>
                    </label>
                </TermsContainer>
                {viewModel.getError("hasAcceptedTerms") && <p style={{ color: "red", marginTop: "10px" }}>{viewModel.getError("hasAcceptedTerms")}</p>}
            </div>
        </>
    );

    const renderRealtor = () => (
        <ContactDetailStyle2 className="pro-register-step" additionalcss="background-color: transparent;">
            <RealtorDetails viewModel={viewModel.agencyDetails} isEditor={false} noBackground hideErrorBorder />
        </ContactDetailStyle2>
    );

    const registerView = () => {
        return (
            <>
                <h2 className="questions-2 no-top-margin register-finally">
                    <span>And finally...Your account</span>
                </h2>
                <h3 className="register-paragraph">
                    To get your quotes and so you can retrieve them in the future, we need your details to set up your account. Please enter them below and
                    we'll get to work...
                </h3>
                <ContactDetail
                    viewModel={viewModel.contactViewModel}
                    noBackground
                    namesLabel="What is your name?"
                    contactsLabel="What are your contact details?"
                    hideErrorBorder
                    className="pro-register-step"
                    hideTerms={true}
                />
                {isMobile ? <PasswordTermsMobileStyle>{renderPasswordAndTerms()}</PasswordTermsMobileStyle> : renderPasswordAndTerms()}
                <h2 className="questions-2 no-top-margin register-finally">
                    <span>Realtor account setup</span>
                </h2>
                <h3 className="register-paragraph realtor">
                    Before we get your quote we need to take some further details to set up your
                    <br />
                    realtor account.
                </h3>
                {isMobile ? <RealtorRegisterMobileStyle>{renderRealtor()}</RealtorRegisterMobileStyle> : renderRealtor()}
            </>
        );
    };

    return useObserver(() => <>{registerView()}</>);
};
