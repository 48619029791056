import { action, computed } from "mobx";

import { ContactViewModel } from "./ContactViewModel";
import { RealtorRegisterModel } from "../Models/RealtorRegisterModel";
import { FieldType } from "../../Core/Utils/Utils";
import { AccountStatus } from "../../Core/Models/AccountStatus";
import { AppUrls } from "../Globals";
import { StoresInstance } from "../Globals/Base";
import { ViewModelBase } from "../../Core/ViewModels/ViewModelBase";

import { RealtorDetailViewModel } from "./Realtor/RealtorDetailViewModel";
import { isEmptyOrWhitespace } from "../../Core/Utils/Utils";
import { IsPhoneNumber } from "class-validator";

export class RealtorRegisterViewModel extends ViewModelBase<RealtorRegisterModel> {
    public contactViewModel = new ContactViewModel();
    public captchaToken = "";

    private realtorViewModel = new RealtorDetailViewModel();

    constructor() {
        super(new RealtorRegisterModel());
        this.setDecorators(RealtorRegisterModel);

        this.contactViewModel.setValue("terms", true);
    }

    public async registerRealtor() {
        const response = await this.Post<AccountStatus>(`${AppUrls.Server.Api.Account.RealtorRegister}?token=${this.captchaToken}`, {
            ...this.model,
            ...this.contactViewModel.model,
            realtorDetails: this.realtorViewModel.model,
            emailAddress: this.contactViewModel.model.email,
            phoneNumber: this.contactViewModel.model.tel,
            postCode: "",
        });
        if (response && response.wasSuccessful) {
            StoresInstance.domain.AccountStore.getLoginState(response.payload);
        }
        return response;
    }

    @computed
    public get canRegisterRealtor(): boolean {
        // Check validity of Agency ViewModel.
        const isRealtorNameValid = !isEmptyOrWhitespace(this.realtorViewModel.model.name);
        const isAddress1Valid = !isEmptyOrWhitespace(this.realtorViewModel.model.address1);
        const isCountyValid = !isEmptyOrWhitespace(this.realtorViewModel.model.county);
        const isStateValid = !isEmptyOrWhitespace(this.realtorViewModel.model.state);
        const isZipCodeValid =
            !isEmptyOrWhitespace(this.realtorViewModel.model.zipCode) &&
            this.realtorViewModel.model.zipCode.length == 5 &&
            parseInt(this.realtorViewModel.model.zipCode) !== NaN;

        const isRealtorModelValid = isRealtorNameValid && isAddress1Valid && isCountyValid && isStateValid && isZipCodeValid;

        // Check validity of Contact viewModel.
        // Rules:
        const isFirstNameValid = !isEmptyOrWhitespace(this.contactViewModel.model.firstName);
        const isLastNameValid = !isEmptyOrWhitespace(this.contactViewModel.model.lastName);
        const isPhoneNumberValid = !isEmptyOrWhitespace(this.contactViewModel.model.tel);
        const isEmailValid = !isEmptyOrWhitespace(this.contactViewModel.model.email);

        const isContactModelValid = isFirstNameValid && isLastNameValid && isPhoneNumberValid && isEmailValid;

        // Check validity of this ViewModal.
        // Rules:
        const isPasswordValid = !isEmptyOrWhitespace(this.model.password);
        const isConfirmPassword = this.model.confirmPassword === this.model.password;
        const isTermsAndConditionsValid = this.model.hasAcceptedTerms;
        //const isRecaptchaValid = this.model.passCaptcha;

        const isModelValid = isPasswordValid && isConfirmPassword && isTermsAndConditionsValid;
        // && isRecaptchaValid;

        // All models must be valid.
        return isRealtorModelValid && isContactModelValid && isModelValid;
    }

    public isFieldValid(fieldName: keyof FieldType<RealtorRegisterModel>, value: any): boolean {
        let { isValid, errorMessage } = this.validateDecorators(fieldName as any);

        if (isValid && fieldName === "confirmPassword") {
            errorMessage = this.isConfirmPasswordValid;
            isValid = errorMessage === "";
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public get isConfirmPasswordValid(): string {
        if (this.getValue("password") !== this.getValue("confirmPassword") && this.getValue("password") !== "") return "Passwords must match";
        return "";
    }

    public checkModelValidity() {
        const me = this.isModelValid();
        const sub = this.contactViewModel.isModelValid();
        const sub2 = this.realtorViewModel.isModelValid();

        return me && sub && sub2;
    }

    public get agencyDetails() {
        return this.realtorViewModel;
    }

    /**
     * Populates ContactModel from the AccountStore
     */
    @action
    public fillContactInfo = () => {
        let domainStores = StoresInstance.domain;
        this.contactViewModel.model.tel = domainStores.AccountStore.Phone;
        this.contactViewModel.model.firstName = domainStores.AccountStore.FirstName;
        this.contactViewModel.model.lastName = domainStores.AccountStore.LastName;
        this.contactViewModel.model.email = domainStores.AccountStore.Email;
    };

    @action
    public setCaptchaToken(token: string) {
        this.captchaToken = token;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
