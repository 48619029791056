import styled from "styled-components";
import { ContactDetailStyle } from "./FormStyling";
import { FormControlLabel, Box } from "@material-ui/core";
import { isNullOrUndefined } from "util";

interface IContactDetailStyleProps {
    additionalcss?: string;
}

interface IStyledDivProps {
    isValid?: boolean;
    isPlaceholder?: boolean;
    additionalcss?: string;
}

export const StyledDiv = styled.div<IStyledDivProps>`
    border-color: ${({ isValid }) => (isValid || isNullOrUndefined(isValid) ? "#f2af4c !important" : "#f44336 !important")};

    .MuiInput-input {
        color: ${({ isPlaceholder }) => (isPlaceholder ? "#bbbbbb" : "rgba(0, 0, 0, 0.87)")};
        font-style: ${({ isPlaceholder }) => (isPlaceholder ? "italic" : "normal")};
    }

    > div {
        width: 100%;
    }

    ${({ additionalcss }) => additionalcss}
`;

export const ContactDetailStyle2: any = styled(ContactDetailStyle)<IContactDetailStyleProps>`
    display: flex;
    flex-wrap: wrap;

    > h2 {
        margin-bottom: 10px;
    }

    > p {
        font-size: 18px;
        font-weight: bold;
        width: 100%;
        margin: 0 20px 34px 20px;

        > a {
            color: #f2af4c;
            text-decoration: underline;
        }
    }

    @media (min-width: 768px) {
        padding-left: 20px;
        padding-right: 20px;

        > h2 {
            margin-left: 20px;
            margin-right: 20px;
        }

        > div {
            width: auto !important;
            flex-basis: calc(50% - 40px);
            margin: 0 20px;
        }

        .edit-contact h2.contact.ap {
            margin-top: 20px !important;
            color: purple;
        }
    }

    > p.pwd-hint {
        font-size: 14px;
        font-weight: 100;
    }

    @media (max-width: 767px) {
        input,
        select,
        .MuiSelect-select {
            font-size: calc(12px + (21 - 12) * ((100vw - 350px) / (1366 - 350))) !important;
        }

        > div:not(:last-of-type) {
            padding: 10px 20px;
        }

        > div:first-of-type {
            margin-bottom: 20px;
        }
    }

    .stateslist {
        position: relative;
    }

    .stateslist,
    .stateslist div:not(.MuiAutocomplete-endAdornment) {
        width: 100%;
        label + .MuiInput-formControl {
            margin-top: 0px;
        }
    }

    ${({ additionalcss }) => additionalcss}
`;

export const ContactDetailsMobileStyle = styled.div`
    background-color: #3a4650;
    padding: 20px 20px 5px;

    .pro-register-step {
        padding-top: 0 !important;
        margin-bottom: 0;

        .input-wrapper {
            padding: 8px 10px !important;
            margin-right: 0 !important;
            margin-bottom: 15px !important;
        }

        h2.contact {
            font-size: 14px;

            &.details {
                font-size: 10px;
            }
        }

        > p {
            font-size: 10px;
            margin-bottom: 10px;
            margin-right: 0;
        }
    }
`;

export const PasswordTermsMobileStyle = styled.div`
    background-color: #3a4650;
    padding: 0 20px 20px;
    margin-bottom: 5px;

    p.pwd-hint {
        font-size: 10px;
        margin-right: 0;
        font-weight: bold;
        margin-bottom: 15px;
    }

    .pro-register-step {
        padding: 0 !important;

        .input-wrapper {
            padding: 8px 10px !important;
            margin-right: 0 !important;
            margin-bottom: 15px !important;
        }

        .terms-container {
            margin-right: 0 !important;
            margin-bottom: 0 !important;

            > span {
                padding-top: 0;
                padding-left: 0;
                padding-bottom: 0;
            }

            label {
                font-size: 10px;
                font-weight: bold;
            }
        }
    }
`;

export const RealtorRegisterMobileStyle = styled.div`
    background-color: #3a4650;
    padding: 20px 20px 12px;

    .pro-register-step {
        padding: 0 !important;

        .input-wrapper {
            padding: 8px 10px !important;
            margin-right: 0 !important;
            margin-bottom: 15px !important;
        }

        .upload-wrapper {
            padding: 0;
            height: 78px;
            border-width: 3px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0 !important;
        }
    }
`;

export const TermsLabel: any = styled(FormControlLabel)`
    margin-top: 11px !important;

    span {
        font-weight: bold !important;
        font-size: 0.875rem;
    }

    .MuiCheckbox-root {
        color: #12db4e;
    }
`;

export const TermsContainer: any = styled.div<IContactDetailStyleProps>`
    display: flex;
    align-items: center;
    justify-content: center;

    label {
        font-size: 21px;
        margin-left: 5px;
        a {
            color: #129bdb;
        }
    }
    ${({ additionalcss }) => additionalcss}
`;

export const RecaptchaContainer = styled(Box)`
    margin-top: 20px;
    margin-left: 20px;

    > div {
        margin-bottom: 5px;
    }

    > span {
        color: red;
        margin-left: 27px;
        font-style: italic;
        font-size: 14px;
    }

    @media (max-width: 767px) {
        margin-left: 0;
        position: absolute;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

export const UploaderWrapper = styled.div<IStyledDivProps>`
    flex-basis: 100% !important;
    background-color: transparent !important;
    border: 5px #f2af4c dashed !important;
    position: relative;
    flex-direction: column;

    img {
        margin: auto;
        height: 112px;

        @media (max-width: 767px) {
            height: 72px;
        }
    }

    input {
        opacity: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
    }

    input,
    input::-webkit-file-upload-button {
        cursor: pointer;
    }

    .upload-placeholder {
        color: #93a0ab;
        font-size: 23px;
        width: 100%;
        text-align: center;

        @media (max-width: 767px) {
            font-size: 12px;
            font-style: italic;
        }
    }
`;
