import React, { useState } from "react";
import { CoverageEdit, EditBar, LocationEdit, YouYourEdit } from "../Components/EditBar";
import { DivWrapper, GridSpaced, TitleWrapper, DivWrapperBGFixed } from "../StylesAppSpecific/GridHelperStyling";
import { EmailExistsContainer } from "../StylesAppSpecific/Coverage/CoverageDetails";
import iconAddSomeDetail from "../../Content/icon-AddSomeDetail.svg";
import { HdrImgDisc } from "../StylesAppSpecific/PageStyling";
import { LoginModal } from "../Components/LoginModal";
import { Grid } from "@material-ui/core";
import { StoresInstance } from "Custom/Stores";
import PXFuel from "Content/quoteBG.jpg";
import { Footer } from "Custom/Components/Footer";
import { Redirect } from "react-router-dom";
import { useMediaQuery } from "../../Core/Utils/Media/mediaQuery";
import { useRouter } from "../../Core/Utils/Hooks/UseReactRouter";
import { Button } from "@material-ui/core";
import { runInAction } from "mobx";
import { LoginViewModel } from "../ViewModels/LoginViewModel";
import { useEffect } from "../Globals/Base";

export const QuoteRequestEmailExists: React.FC = () => {
    const domainStores = StoresInstance.domain;
    const quoteRequestVM = domainStores.QuoteRequestViewModel;
    const [showLogin, setShowLogin] = useState(false);
    const requestAddress: string = quoteRequestVM.getValue("address");
    const requestEmail: string = quoteRequestVM.getValue("email");
    const requestPopulated: boolean = requestAddress.length > 0 ? true : false;
    const emailPopulated: boolean = !!requestEmail && requestEmail.length > 0;
    const isMobile = !!useMediaQuery("(max-width: 767px)");
    const { history } = useRouter();
    const [loginViewModel] = useState(() => new LoginViewModel());

    useEffect(() => {
        loginViewModel.setValue("emailAddress", requestEmail);
    }, []);

    const goBack = () => {
        runInAction(() => {
            StoresInstance.domain.QuoteResultViewModel.canGetQuote = false;
            quoteRequestVM.contactViewModel.setValue("email", "");
        });
        quoteRequestVM.scrollToBottom = true;
        //history.push("/coveragedetails");
        history.push("/Step2");
    };

    const editBar = (
        <EditBar>
            <LocationEdit />
            <CoverageEdit />
            <YouYourEdit />
        </EditBar>
    );

    const contentHdr = (
        <>
            <GridSpaced container margin={"0px auto"} additionalcss={"max-width: 688px; width: 100%;"}>
                <GridSpaced item xs={12} sm={12} additionalcss={"text-align: center;"}>
                    <HdrImgDisc src={iconAddSomeDetail} alt={""} />
                </GridSpaced>
            </GridSpaced>

            <GridSpaced container margin={"0px auto"} additionalcss={"width: 100%;"}>
                <Grid item xs={12} sm={12} justify={"center"}>
                    <TitleWrapper padding={"0"} margin={"0"}>
                        <h1>Unfortunately the email you wish to register with already exists.</h1>
                    </TitleWrapper>
                </Grid>
            </GridSpaced>

            <TitleWrapper padding={"0"} margin={"0"}>
                <h2 style={isMobile ? {} : { fontSize: "23.3px" }}>
                    Please register with a different email address or alternatively log in to your existing account
                </h2>
            </TitleWrapper>
        </>
    );

    const contentBody = () => (
        <>
            {editBar}
            <div style={{ margin: "0 20px" }}>
                <DivWrapper margin={"0px auto"} additionalcss={"max-width: 1124px; padding-bottom: 100px;"}>
                    <EmailExistsContainer>
                        {contentHdr}
                        <div className="emailexists-main">
                            <div className="emailexists-btn emailexists-go-back-btn">
                                <Button variant="contained" onClick={goBack}>
                                    Go back and change email address
                                </Button>
                            </div>
                            <div className="emailexists-or">
                                <div className="emailexists-line" />
                                <div className="emailexists-or-txt">OR</div>
                                <div className="emailexists-line" />
                            </div>
                            <div className="emailexists-btn emailexists-login-btn">
                                <Button variant="contained" onClick={() => setShowLogin(true)}>
                                    Log in
                                </Button>
                            </div>
                        </div>
                    </EmailExistsContainer>
                </DivWrapper>
            </div>
        </>
    );

    return (
        <>
            <DivWrapperBGFixed additionalcss={"background-image: url(" + PXFuel + ");"}>
                {requestPopulated && emailPopulated ? contentBody() : <Redirect to="/" />}
            </DivWrapperBGFixed>
            <Footer isTemp={false} />
            <LoginModal open={showLogin} onClose={() => setShowLogin(false)} onSuccess={() => history.push("/availablepolicies")} viewModel={loginViewModel} />
        </>
    );
};
