// Libraries
import clsx from "clsx";
import React, { JSXElementConstructor } from "react";
import { Button, Divider, Paper, Grid, Typography, useMediaQuery as useMuiMediaQuery, Theme } from "@material-ui/core";

// Content

// Components
import { ResultQuoteModel } from "../../Models/";
import { StoresInstance } from "../../Stores";

// Styling & images
import { usePCPCardStyles, useResultStyles } from "./ResultPropsStyling";
import { isEmptyOrWhitespace } from "../../../Core/Utils/Utils";
import { getInsurerLogo } from "../../Utils/InsurerLogo";
import { moneyDollarFormat } from "../../Utils/Money";
import downloadPDF from "Content/pdfDLIcon.svg";
import chatIcon from "Content/chatIcon.svg";

interface IProp {
    position: number;
    callback: (chosenVal: number) => void;
    pdfCallBack: (chosenVal: number) => void;
    quoteDetail: ResultQuoteModel;
    adminQuote?: boolean;
}

declare var purechatApi: any;

export const PCPQuoteCard: React.FC<IProp> = props => {
    const classes = useResultStyles();
    const customClasses = usePCPCardStyles();
    const xsOrUnder = useMuiMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
    const isSelectedQuote = !isEmptyOrWhitespace(props.quoteDetail.internalQuoteNumber);
    const selectButtonText = isSelectedQuote ? "Selected" : "View";
    const domainStores = StoresInstance.domain;

    const getExtras = () => {
        switch (props.quoteDetail.name) {
            case "Sterling":
                return (
                    <>
                        <Grid container>
                            <Grid className={classes.bodyGridItem} item xs={12}>
                                <Typography className={classes.bodyTitle}>Policy extras</Typography>
                            </Grid>
                        </Grid>
                        <Grid container>{getSterlingExtras()}</Grid>
                    </>
                );
            case "Hiscox FloodPlus":
                return getHiscoxExtras();
            case "Zurich":
                return (
                    <>
                        <Grid container>
                            <Grid className={classes.bodyGridItem} item xs={12}>
                                <Typography className={classes.bodyTitle}>Policy extras</Typography>
                            </Grid>
                        </Grid>
                        <Grid container>{getZurichExtras()}</Grid>
                    </>
                );
            case "AON Edge":
                return (
                    <>
                        <Grid container>
                            <Grid className={classes.bodyGridItem} item xs={12}>
                                <Typography className={classes.bodyTitle}>Policy extras</Typography>
                            </Grid>
                        </Grid>
                        <Grid container>{getAONEdgeExtras()}</Grid>
                    </>
                );
            default:
                return (
                    <>
                        <Grid container>
                            <Grid className={classes.bodyGridItem} item xs={12}>
                                <Typography className={classes.bodyTitle}>Policy extras</Typography>
                            </Grid>
                        </Grid>
                        <Grid container>{getDefaultExtras()}</Grid>
                    </>
                );
        }
    };

    const getTitle = () => {
        console.log(props.quoteDetail.name);
        switch (props.quoteDetail.name) {
            case "Hiscox FloodPlus":
                return hiscoxTitle();
            case "Zurich":
                return getZurichTitle();
            case "Wright Private Flood":
                return props.quoteDetail.quoteName;
            case "Wright PrivateFlood":
                return props.quoteDetail.quoteName;
            default:
                return props.quoteDetail.name;
        }
    };

    const getPolicyExtras = (): JSX.Element[] => {
        return Object.keys(props.quoteDetail).map(key => {
            if (props.quoteDetail.hasOwnProperty(key)) {
                const value = props.quoteDetail[key];
                let bodyText = "";

                switch (key) {
                    case "buildingCover":
                        if (value > 0) {
                            bodyText = `Up to ${moneyDollarFormat(value)} of building coverage.`;
                        }
                        break;

                    case "contentsCover":
                        if (value > 0) {
                            bodyText = `Up to ${moneyDollarFormat(value)} of contents coverage.`;
                        }
                        break;

                    case "additionalLivingExpenses":
                        if (value > 0) {
                            bodyText = `Up to ${moneyDollarFormat(value)} to cover living expenses.`;
                        }
                        break;

                    case "otherStructuresCover":
                        if (value > 0) {
                            bodyText = `Up to ${moneyDollarFormat(value)} to cover other structures.`;
                        }
                        break;

                    case "lossOfUse":
                        if (value > 0) {
                            bodyText = `Up to ${moneyDollarFormat(value)} to cover loss of use.`;
                        }
                }

                if (!isEmptyOrWhitespace(bodyText)) {
                    return (
                        <Grid className={classes.bodyGridItem} item xs={12} sm={6}>
                            <Typography variant="body2">{bodyText}</Typography>
                        </Grid>
                    );
                } else {
                    return <React.Fragment />;
                }
            } else {
                return <React.Fragment />;
            }
        });
    };

    const hiscoxTitle = () => {
        switch (props.quoteDetail.quoteMisc) {
            case "1":
                return <>Hiscox FloodPlus Full Value Policy (Includes Building, Contents, Other Structures & Additional Living Expense)</>;
            case "2":
                return <>Hiscox FloodPlus Option 2 (Building Coverage only, $250k maximum limit)</>;
            case "3":
                return <>Hiscox FloodPlus Option 3 (Building Coverage $250k maximum limit & Contents Coverage $100k maximum limit)</>;
            default:
                return <>Hiscox FloodPlus Option {props.quoteDetail.quoteMisc}</>;
        }
    };

    const getHiscoxExtras = () => {
        switch (props.quoteDetail.quoteMisc) {
            case "1":
                return (
                    <>
                        {/* <Grid container>{getPolicyExtras()}</Grid> */}
                        <Grid container>
                            <Grid className={classes.bodyGridItem} item xs={12}>
                                <Typography className={classes.bodyTitle}>Policy extras</Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid className={classes.bodyGridItem} item xs={12}>
                                <Typography variant="body2">Replacement Cost Coverage for Contents available</Typography>
                                <Typography variant="body2">
                                    Replacement Cost Coverage for Building requires that the structure be insured for at least 80% of the full replacement value
                                    at the time of loss Other Structures provides coverage for detached building such as sheds and guest houses
                                </Typography>
                                <Typography variant="body2">
                                    Additional Living Expenses provides funds for you to stay elsewhere in the event that a covered loss renders your property
                                    uninhabitable
                                </Typography>
                            </Grid>
                        </Grid>
                    </>
                );
            case "2":
                return (
                    <>
                        {/* <Grid container>{getPolicyExtras()}</Grid> */}
                        <Grid container>
                            <Grid className={classes.bodyGridItem} item xs={12}>
                                <Typography className={classes.bodyTitle}>Policy extras</Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid className={classes.bodyGridItem} item xs={12}>
                                <Typography variant="body2">This policy provides no coverage for personal property (Contents)</Typography>
                            </Grid>
                        </Grid>
                    </>
                );
            case "3":
                return (
                    <>
                        {/* <Grid container>{getPolicyExtras()}</Grid> */}
                        <Grid container>
                            <Grid className={classes.bodyGridItem} item xs={12}>
                                <Typography className={classes.bodyTitle}>Policy extras</Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid className={classes.bodyGridItem} item xs={12}>
                                <Typography variant="body2">Replacement Cost Coverage for Contents available</Typography>
                            </Grid>
                        </Grid>
                    </>
                );
            default:
                return (
                    <>
                        <Grid container>{getPolicyExtras()}</Grid>
                    </>
                );
        }
    };

    const getSterlingExtras = () => {
        return (
            <>
                <Grid className={classes.bodyGridItem} item xs={12}>
                    <Typography variant="body2">Replacement Cost Coverage for Contents included</Typography>
                    <Typography variant="body2">
                        Loss of Use Coverage (for living expenses incurred if your property is uninhabitable after a loss) available
                    </Typography>
                    <Typography variant="body2">Pool Cleanup Coverage and other endorsements available</Typography>
                </Grid>
            </>
        );
    };

    const getZurichTitle = () => {
        return <>{props.quoteDetail.quoteName}</>;
    };

    const getZurichExtras = () => {
        return (
            <>
                <Grid className={classes.bodyGridItem} item xs={12}>
                    <Typography variant="body2">
                        Included:
                        <br /> Replacement Cost Coverage for Contents, Ordinance and Law Coverage (for additional expenses incurred in meeting building codes or
                        ordinances at the time of a loss)
                    </Typography>
                    <Typography variant="body2">
                        Available:
                        <br /> Other Structures Coverage (for buildings detached from the main structure), Loss of Use Coverage (for living expenses incurred if
                        your property is uninhabitable after a covered loss), Resiliency Coverage (for upgrading the flood-resistant building materials at the
                        time of a loss)
                    </Typography>
                </Grid>
            </>
        );
    };

    const getAONEdgeExtras = () => {
        return (
            <>
                <Grid className={classes.bodyGridItem} item xs={12}>
                    <Typography variant="body2">Included: Replacement Cost Coverage for Contents, Catastrophic Ground Cover Collapse</Typography>
                    <Typography variant="body2">
                        Available: Additional Living Expenses (for costs incurred if your property is uninhabitable after a loss), Pool Clean-up, and Food
                        Spoilage Coverage
                    </Typography>
                </Grid>
            </>
        );
    };

    const getDefaultExtras = () => {
        return (
            <>
                <Grid className={classes.bodyGridItem} item xs={12}>
                    <Typography variant="body2">Replacement Cost Coverage for Contents available</Typography>
                    <Typography variant="body2">Additional Living Expenses available</Typography>
                </Grid>
            </>
        );
    };

    return (
        <Paper
            className={clsx(isSelectedQuote ? classes.selectedPaper : classes.paper, { [classes.adminPaper]: props.adminQuote })}
            key={props.position + "-" + props.quoteDetail.quoteNumber}
        >
            <Grid container>
                <Grid className={classes.header} container>
                    <Grid className={classes.imageGridItem} item xs={2} md={2}>
                        <img alt={props.quoteDetail.name} src={getInsurerLogo(props.quoteDetail.name)} />
                    </Grid>
                    <Grid className={classes.headerDetailsGridItem} item xs={6} md={5}>
                        <Typography className={classes.insurerText}>{getTitle()}</Typography>
                        <Typography className={classes.premiumText}>Premium: {moneyDollarFormat(props.quoteDetail.total, true)}</Typography>
                        {/*                             
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography className={clsx(classes.deductibleText, { [classes.adminDeductible]: props.adminQuote })}>
                                    Deductible: {moneyDollarFormat(props.quoteDetail.deductible, true)}
                                </Typography>
                            </Grid>
                        </Grid> 
                        */}
                    </Grid>
                    {!xsOrUnder && (
                        <Grid className={classes.selectButtonGridItem} item>
                            <Button className={classes.iconButton} variant="contained" onClick={() => purechatApi.set("chatbox.expanded", true)}>
                                <img src={chatIcon} />
                            </Button>
                            {!domainStores.wrapper && (
                                <Button className={classes.iconButton} variant="contained" onClick={() => props.pdfCallBack(props.position)}>
                                    <img src={downloadPDF} />
                                </Button>
                            )}
                            <Button
                                className={isSelectedQuote ? classes.selectedButton : classes.selectButton}
                                variant="contained"
                                onClick={() => props.callback(props.position)}
                            >
                                {selectButtonText}
                            </Button>
                        </Grid>
                    )}
                </Grid>
                <Grid className={clsx(classes.body, { [customClasses.body]: !props.adminQuote, [customClasses.adminBody]: props.adminQuote })} container>
                    {/* <Grid container>{getPolicyExtras()}</Grid> */}
                    <Grid container className={classes.deductiblePCPContainer}>
                        <Grid item sm={3}>
                            <Typography className={classes.deductibleText}>
                                Buildings coverage: {moneyDollarFormat(props.quoteDetail.buildingCover, true)}
                            </Typography>
                        </Grid>
                        <Grid item sm={3}>
                            <Typography className={classes.deductibleText}>
                                Contents coverage: {moneyDollarFormat(props.quoteDetail.contentsCover, true)}
                            </Typography>
                        </Grid>
                        <Grid item sm={3}>
                            <Typography className={classes.deductibleText}>Deductible: {moneyDollarFormat(props.quoteDetail.deductible, true)}</Typography>
                        </Grid>
                        <Grid item sm={3}></Grid>
                    </Grid>
                    {getExtras()}
                    {xsOrUnder && (
                        <Grid container>
                            <Grid className={classes.selectButtonGridItem} item>
                                <Button
                                    className={isSelectedQuote ? classes.selectedButton : classes.selectButton}
                                    variant="contained"
                                    onClick={() => props.callback(props.position)}
                                >
                                    {selectButtonText}
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Paper>
    );
};
