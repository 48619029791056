// Libraries
import * as MobX from "mobx";

// Core
import { FieldType, isEmptyOrWhitespace } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";

// Custom
import { Server } from "../../Globals/AppUrls";
import { ResultQuoteModel } from "../../Models";
import { AgentUserAndQuoteResultModel, AgentUserAndQuoteResultModelDTO } from "../../Models/AgentUserAndQuoteResultModel";
import { moneyDollarFormat } from "../../Utils/Money";
import AgentUserAndQuoteResultStore from "../../Stores/Domain/AgentUserAndQuoteResultStore";
import { AppUrls } from "Custom/Globals";

export default class AgentUserReportQuotesViewModel extends ViewModelBase<AgentUserAndQuoteResultModel> {
    // #region Sorting

    public sortOptions: any[] = [
        { key: "Premium low to high", value: 0 },
        { key: "Premium high to low", value: 1 },
        { key: "Deductible low to high", value: 2 },
        { key: "Deductible high to low", value: 3 },
    ];

    @MobX.observable
    public sortOptionSelection: number = 0;

    @MobX.action
    public setSortOption = (value: number) => {
        this.sortOptionSelection = value;
    };

    // #endregion Sorting

    @MobX.observable
    public blobId: string = "";

    @MobX.computed
    public get name() {
        return `${AgentUserAndQuoteResultStore.instance.model.firstName} ${AgentUserAndQuoteResultStore.instance.model.lastName}`;
    }

    @MobX.computed
    public get address() {
        return AgentUserAndQuoteResultStore.instance.model.request.address;
    }

    @MobX.computed
    public get deductible() {
        return moneyDollarFormat(AgentUserAndQuoteResultStore.instance.model.deductible);
    }

    // #region Displayable Quotes

    @MobX.computed
    public get nfipQuotes() {
        return this.returnNFIPQuotes;
    }

    @MobX.computed
    public get canDisplayNFIPQuotes(): boolean {
        return this.nfipQuotes.length > 0;
    }

    @MobX.computed
    public get privateQuotes() {
        return this.filterAndSort(this.returnPrivateQuotes, this.sortOptionSelection);
    }

    @MobX.computed
    public get canDisplayPrivateQuotes(): boolean {
        return this.privateQuotes.length > 0;
    }

    // #endregion Displayable Quotes

    /**
     * Constructor
     *
     * @param blobId The quote result to load (if defined).
     */
    constructor(blobId: string) {
        super(new AgentUserAndQuoteResultModel());
        this.setDecorators(AgentUserAndQuoteResultModel);
        this.blobId = blobId;

        if (!isEmptyOrWhitespace(blobId) && blobId !== "null") {
            // Only load the quote results if the blob id has changed.
            if (blobId !== AgentUserAndQuoteResultStore.instance.model.blobId) {
                AgentUserAndQuoteResultStore.instance.model.reset();
                this.load(blobId);
            }
        } else {
            AgentUserAndQuoteResultStore.instance.model.reset();
        }
    }

    @MobX.computed
    private get returnNFIPQuotes() {
        let quotesArr: ResultQuoteModel[] = [];

        quotesArr.push.apply(quotesArr, AgentUserAndQuoteResultStore.instance.model.wrightResult.quotes);

        return quotesArr;
    }

    @MobX.computed
    private get returnPrivateQuotes() {
        let quotesArr: ResultQuoteModel[] = [];

        quotesArr.push.apply(quotesArr, AgentUserAndQuoteResultStore.instance.model.aonEdgeResult.quotes);
        quotesArr.push.apply(quotesArr, AgentUserAndQuoteResultStore.instance.model.argeniaResult.quotes);
        quotesArr.push.apply(quotesArr, AgentUserAndQuoteResultStore.instance.model.fRSResult.quotes);
        quotesArr.push.apply(quotesArr, AgentUserAndQuoteResultStore.instance.model.neptuneResult.quotes);
        quotesArr.push.apply(quotesArr, AgentUserAndQuoteResultStore.instance.model.sterlingResult.quotes);
        quotesArr.push.apply(quotesArr, AgentUserAndQuoteResultStore.instance.model.selectiveResult.quotes);
        quotesArr.push.apply(quotesArr, AgentUserAndQuoteResultStore.instance.model.hiscoxFloodPlusResult.quotes);
        quotesArr.push.apply(quotesArr, AgentUserAndQuoteResultStore.instance.model.zurichResult.quotes);
        quotesArr.push.apply(quotesArr, AgentUserAndQuoteResultStore.instance.model.wrightPrivateFloodResult.quotes);

        return quotesArr;
    }

    private filterAndSort = (quotes: ResultQuoteModel[], sortOptionSelection: number): ResultQuoteModel[] => {
        // Filter zero premiums.
        var filteredQuotes = quotes.filter(q => q.total !== 0);

        // Sort by option.
        switch (sortOptionSelection) {
            case 0:
                filteredQuotes.sort((qlhs, qrhs) => qlhs.total - qrhs.total);
                break;

            case 1:
                filteredQuotes.sort((qlhs, qrhs) => qrhs.total - qlhs.total);
                break;

            case 2:
                filteredQuotes.sort((qlhs, qrhs) => qlhs.deductible - qrhs.deductible);
                break;

            case 3:
                filteredQuotes.sort((qlhs, qrhs) => qrhs.deductible - qlhs.deductible);
                break;
        }

        return filteredQuotes;
    };

    // #region Navigation

    public backToDashboard = () => {
        this.history.push("/agent/report");
    };

    public backToUserReport = () => {
        this.history.push(`/agentuserreport/${AgentUserAndQuoteResultStore.instance.model.id}`);
    };

    public selectQuote = (quoteId: string) => {
        this.history.push(`/agentuserreportquote/${this.blobId}/${quoteId}`);
    };

    public selectQuoteForPDF = (quote: ResultQuoteModel): void => {
        const params = new URLSearchParams({
            blobId: this.blobId!,
            quoteId: quote.id,
            tz: new Date().getTimezoneOffset().toString(),
        });

        window.open(`${AppUrls.Server.Api.Quotes.GetQuotePDF}?${params.toString()}`, "_blank");
    };

    // #endregion Navigation

    // #region Server Api

    /**
     * Loads the specified quotes from the server.
     *
     * @param id The unique identifier of the quote result to load.
     */
    public load = async (id: string): Promise<void> => {
        try {
            this.setIsLoading(true);

            const apiResult = await this.Post<AgentUserAndQuoteResultModelDTO>(Server.Api.Agent.GetUserAndQuoteResult, { id });

            if (apiResult.wasSuccessful) {
                AgentUserAndQuoteResultStore.instance.model.fromDto(apiResult.payload);
            } else {
                this.setIsErrored(true);
            }
        } catch (exception) {
            this.setIsErrored(true);
        } finally {
            this.setIsLoading(false);
        }
    };

    // #endregion Server Api

    // #region Viewmodel Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Viewmodel Boilerplate
}
