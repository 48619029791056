// Libraries
import { runInAction } from "mobx";
import React, { useRef, useEffect, useState } from "react";
import { Box, Button, Typography, Paper } from "@material-ui/core";

// Core
import { googlePlaceResultToGeocodeResult } from "../../../Core/Utils/Utils";

// Custom
import { useRouter, useObserver } from "../../Globals/Base";
import { CoverageModel } from "../../Models";
import { GeocodeResult } from "../../Models/GeocodeResult";
import { StoresInstance } from "../../Stores";
import { startNewQuoteCardStyles } from "../../StylesAppSpecific/Agent/AgentDashboard";

export const StartNewQuoteCard: React.FC = () => {
    const classes = startNewQuoteCardStyles();
    const { history } = useRouter();
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const inputReference: React.MutableRefObject<any> = useRef(null);

    let searchLocationAutoComplete: google.maps.places.Autocomplete;
    let searchLocationInput: HTMLInputElement;
    let searchEventListener: google.maps.MapsEventListener;

    const setAddress = (e: any) => {
        setCanSubmit(false);
        StoresInstance.domain.QuoteRequestViewModel.setValue("address", e.target.value);
    };

    const getAddress = (): string => {
        return StoresInstance.domain.QuoteRequestViewModel.getValue<string>("address");
    };

    const onInputKeyPress = (e: any) => {
        if (e.keyCode === 13 || e.charCode === 13 || e.which === 13) {
            e.preventDefault();
            return false;
        }
    };

    const setLocation = (result: GeocodeResult) => {
        runInAction(() => {
            StoresInstance.domain.QuoteRequestViewModel.setValue("buildingCoverage", CoverageModel.MODEL_DEFAULT_BUILDINGCOVERAGE);
            StoresInstance.domain.QuoteRequestViewModel.setValue("contentsCoverage", CoverageModel.MODEL_DEFAULT_CONTENTSCOVERAGE);
            StoresInstance.domain.QuoteRequestViewModel.setValue("deductible", CoverageModel.MODEL_DEFAULT_DEDUCTIBLEVALUE);
            StoresInstance.domain.QuoteRequestViewModel.setValue("address", result.placeName);
            StoresInstance.domain.QuoteRequestViewModel.setValue("city", result.city);
            StoresInstance.domain.QuoteRequestViewModel.setValue("county", result.county);
            StoresInstance.domain.QuoteRequestViewModel.setValue("state", result.region);
            StoresInstance.domain.QuoteRequestViewModel.setValue("zipcode", result.zipcode);
            StoresInstance.domain.QuoteRequestViewModel.setValue("latitude", result.center[1].toString());
            StoresInstance.domain.QuoteRequestViewModel.setValue("longitude", result.center[0].toString());
            StoresInstance.domain.QuoteRequestViewModel.model.resetDetails();
            StoresInstance.domain.QuoteRequestViewModel.resetGotFields();

            StoresInstance.domain.EstatedHasStructure = false;
            StoresInstance.domain.EstatedHasLoaded = false;
        });

        setCanSubmit(true);
    };

    const resetLocation = () => {
        runInAction(() => {
            StoresInstance.domain.QuoteRequestViewModel.setValue("buildingCoverage", CoverageModel.MODEL_DEFAULT_BUILDINGCOVERAGE);
            StoresInstance.domain.QuoteRequestViewModel.setValue("contentsCoverage", CoverageModel.MODEL_DEFAULT_CONTENTSCOVERAGE);
            StoresInstance.domain.QuoteRequestViewModel.setValue("deductible", CoverageModel.MODEL_DEFAULT_DEDUCTIBLEVALUE);
            StoresInstance.domain.QuoteRequestViewModel.setValue("address", "");
            StoresInstance.domain.QuoteRequestViewModel.setValue("city", "");
            StoresInstance.domain.QuoteRequestViewModel.setValue("county", "");
            StoresInstance.domain.QuoteRequestViewModel.setValue("state", "");
            StoresInstance.domain.QuoteRequestViewModel.setValue("zipcode", "");
            StoresInstance.domain.QuoteRequestViewModel.setValue("latitude", "");
            StoresInstance.domain.QuoteRequestViewModel.setValue("longitude", "");

            StoresInstance.domain.EstatedHasStructure = false;
            StoresInstance.domain.EstatedHasLoaded = false;
        });

        setCanSubmit(false);
    };

    const submit = () => {
        //history.push("/coverage");
        history.push("/step1");
    };

    useEffect(() => {
        resetLocation();

        if (inputReference.current != null) {
            searchLocationInput = inputReference.current!;

            searchLocationAutoComplete = new google.maps.places.Autocomplete(searchLocationInput, {
                types: ["address"],
                componentRestrictions: { country: "us" },
            });

            searchEventListener = google.maps.event.addListener(searchLocationAutoComplete, "place_changed", () => {
                StoresInstance.domain.MapStore.ResetLocation();

                const location: google.maps.places.PlaceResult = searchLocationAutoComplete!.getPlace();

                if (location && location.geometry && location.formatted_address && location.types) {
                    const result = googlePlaceResultToGeocodeResult(location);
                    setLocation(result);
                }
            });
        }

        return () => {
            google.maps.event.removeListener(searchEventListener!);
        };
    }, []);

    return useObserver(() => {
        return (
            <Paper className={classes.paper}>
                <Box className={classes.instructionContainer}>
                    <Typography className={classes.firstInstruction}>To start a new quote</Typography>
                    <Typography className={classes.secondInstruction}>enter an address</Typography>
                    <input
                        autoFocus={true}
                        className={classes.input}
                        onChange={setAddress}
                        onKeyPress={onInputKeyPress}
                        placeholder={"Please enter your address here..."}
                        ref={inputReference}
                        value={getAddress()}
                    />
                </Box>
                <Box className={classes.buyButtonBoxItem}>
                    <Button className={classes.buyButton} disabled={!canSubmit} onClick={submit}>
                        Start
                    </Button>
                </Box>
            </Paper>
        );
    });
};
