import * as MobX from "mobx";
import { ModelBase } from "../../Core/Models/ModelBase";

export class ResultQuoteModel extends ModelBase<ResultQuoteModel, ResultQuoteModelDTO> {
    /**
     * Internal unique identifier for the quote.
     */
    public id: string = "";

    /**
     * Name of the insurer.
     */
    @MobX.observable
    public name: string = "";

    /**
     * The insurers quote number.
     */
    @MobX.observable
    public quoteNumber: string = "";

    /**
     * The MFI internal quote number.
     */
    @MobX.observable
    public internalQuoteNumber: string = "";

    /**
     * Building cover (dollars).
     */
    @MobX.observable
    public buildingCover: number = 0;

    /**
     * Contents cover (dollars).
     */
    @MobX.observable
    public contentsCover: number = 0;

    @MobX.observable
    public buildingCoverWasAmended: boolean = false;

    @MobX.observable
    public contentsCoverWasAmended: boolean = false;

    @MobX.observable
    public lossOfUse: number = 0;

    @MobX.observable
    public otherStructuresCover: number = 0;

    @MobX.observable
    public otherStructuresCoverWasAmended: boolean = false;

    @MobX.observable
    public additionalLivingExpenses: number = 0;

    @MobX.observable
    public additionalLivingExpensesWasAmended: boolean = false;

    @MobX.observable
    public deductible: number = 0;

    @MobX.observable
    public contentDeductible: number = 0;

    @MobX.observable
    public deductibleWasAmended: boolean = false;

    @MobX.observable
    public premium: number = 0;

    @MobX.observable
    public fees: number = 0;

    @MobX.observable
    public taxes: number = 0;

    @MobX.observable
    public total: number = 0;

    @MobX.observable
    public propertyType: string = "";

    @MobX.observable
    public quoteName: string = "";

    @MobX.observable
    public quoteInstruction: string = "";

    @MobX.observable
    public quoteMisc: string = "";

    public fromDto = (model: ResultQuoteModelDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                this[key] = model[key];
            }
        }
    };
    public toDto = (model: ResultQuoteModel) => {};
}

export interface ResultQuoteModelDTO {
    id: string;
    name: string;
    quoteNumber: string;
    internalQuoteNumber: string;
    buildingCover: number;
    buildingCoverWasAmended: boolean;
    contentsCover: number;
    contentsCoverWasAmended: boolean;
    lossOfUse: number;
    otherStructuresCover: number;
    otherStructuresCoverWasAmended: boolean;
    additionalLivingExpenses: number;
    additionalLivingExpensesWasAmended: boolean;
    contentDeductible: number;
    deductible: number;
    deductibleWasAmended: boolean;
    premium: number;
    fees: number;
    taxes: number;
    total: number;
    propertyType: string;
    quoteName: string;
    quoteInstruction: string;
    quoteMisc: string;
}
