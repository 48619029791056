// Libraries
import * as MobX from "mobx";

// Core
import { ModelBase } from "../../Core/Models/ModelBase";
import { ResultQuoteModel } from "./ResultQuoteModel";

export class AgentUserAndQuoteModel extends ModelBase<AgentUserAndQuoteModel, AgentUserAndQuoteModelDTO> {
    // #region User

    @MobX.observable
    public id: string = "";

    @MobX.observable
    public firstName: string = "";

    @MobX.observable
    public lastName: string = "";

    @MobX.observable
    public userName: string = "";

    @MobX.observable
    public phoneNumber: string = "";

    // #endregion User

    // #region Quote

    @MobX.observable
    public quoteNumber: string = "";

    @MobX.observable
    public userQuote = new ResultQuoteModel();

    // #endregion Quote

    @MobX.action
    public fromDto = (model: AgentUserAndQuoteModelDTO): void => {
        this.id = model.id;
        this.firstName = model.firstName;
        this.lastName = model.lastName;
        this.userName = model.userName;
        this.phoneNumber = model.phoneNumber;
        this.quoteNumber = model.quoteNumber;
        this.userQuote = model.userQuote;
    };

    public toDto = (): AgentUserAndQuoteModelDTO => {
        throw "toDto is not supported";
    };
}

export interface AgentUserAndQuoteModelDTO {
    id: string;
    firstName: string;
    lastName: string;
    userName: string;
    phoneNumber: string;
    quoteNumber: string;
    userQuote: ResultQuoteModel;
}
