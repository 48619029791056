import * as MobX from "mobx";

import { ModelBase } from "../../Core/Models/ModelBase";
import { ResultQuoteModel } from ".";

export class PropertyQuoteModel extends ModelBase<PropertyQuoteModel, PropertyQuoteModelDTO> {
    @MobX.observable
    public id: string | undefined = undefined;

    @MobX.observable
    public userId: string = "";

    @MobX.observable
    public propertyId: string = "";

    @MobX.observable
    public address1: string = "";

    @MobX.observable
    public address2: string = "";

    @MobX.observable
    public city: string = "";

    @MobX.observable
    public state: string = "";

    @MobX.observable
    public zipCode: string = "";

    @MobX.observable
    public buildingCoverage: number = 0;

    @MobX.observable
    public contentsCoverage: number = 0;

    @MobX.observable
    public isOverWater: boolean = false;

    @MobX.observable
    public isMobileHome: boolean = false;

    @MobX.observable
    public constructionTypeId: string = "";

    @MobX.observable
    public constructionTypeIdString: string = "";

    @MobX.observable
    public constructionYear: number = 0;

    @MobX.observable
    public noOfStories: number = 0;

    @MobX.observable
    public squareFootage: number = 0;

    @MobX.observable
    public foundationTypeId: string = "";

    @MobX.observable
    public foundationTypeIdString: string = "";

    @MobX.observable
    public hasGarage: boolean = false;

    @MobX.observable
    public hasCrawlSpace: boolean = false;

    @MobX.observable
    public hasEnclosure: boolean = false;

    @MobX.observable
    public ownershipType: number = 0;

    @MobX.observable
    public occupationType: number = 0;

    @MobX.observable
    public enclosureTypes: string = "";

    @MobX.observable
    public propertyType: number = 0;

    @MobX.observable
    public insurancePolicyType: number = 0;

    @MobX.observable
    public elevationCertificateUrl: string = "";

    @MobX.observable
    public createdDate: string = "";

    @MobX.observable
    public floodZone: string = "";

    @MobX.observable
    public insurerId: string = "";

    @MobX.observable
    public insurerIdString: string = "";

    @MobX.observable
    public buildingCover: number = 0;

    @MobX.observable
    public contentsCover: number = 0;

    @MobX.observable
    public lossOfUse: number = 0;

    @MobX.observable
    public otherStructuresCover: number = 0;

    @MobX.observable
    public additionalLivingExpenses: number = 0;

    @MobX.observable
    public deductible: number = 0;

    @MobX.observable
    public premium: number = 0;

    @MobX.observable
    public fees: number = 0;

    @MobX.observable
    public taxes: number = 0;

    @MobX.observable
    public total: number = 0;

    @MobX.observable
    public quoteDate: string = "";

    @MobX.observable
    public quoteNumber: string = "";

    @MobX.observable
    public isNewProperty?: boolean;

    @MobX.observable
    public propertyOccupy?: number;

    @MobX.observable
    public isAgentGenerated: boolean = false;

    @MobX.observable
    public internalQuoteNumber: string = "";

    @MobX.observable
    public policyEffectiveDate: string = "";

    @MobX.observable
    public policyHolderEmail: string = "";

    @MobX.observable
    public policyHolderFirstName: string = "";

    @MobX.observable
    public policyHolderLastName: string = "";

    @MobX.observable
    public policyHolderPhoneNumber: string = "";

    @MobX.observable
    public blobId: string = "";

    @MobX.action
    public fromDto = (model: PropertyQuoteModelDTO): void => {
        this.id = model.id;
        this.userId = model.userId;
        this.propertyId = model.propertyId;
        this.address1 = model.address1;
        this.address2 = model.address2;
        this.city = model.city;
        this.state = model.state;
        this.zipCode = model.zipCode;
        this.buildingCoverage = model.buildingCoverage;
        this.contentsCoverage = model.contentsCoverage;
        this.isOverWater = model.isOverWater;
        this.isMobileHome = model.isMobileHome;
        this.constructionTypeId = model.constructionTypeId;
        this.constructionTypeIdString = model.constructionTypeIdString;
        this.constructionYear = model.constructionYear;
        this.noOfStories = model.noOfStories;
        this.squareFootage = model.squareFootage;
        this.foundationTypeId = model.foundationTypeId;
        this.foundationTypeIdString = model.foundationTypeIdString;
        this.hasGarage = model.hasGarage;
        this.hasCrawlSpace = model.hasCrawlSpace;
        this.hasEnclosure = model.hasEnclosure;
        this.ownershipType = model.ownershipType;
        this.occupationType = model.occupationType;
        this.enclosureTypes = model.enclosureTypes;
        this.propertyType = model.propertyType;
        this.insurancePolicyType = model.insurancePolicyType;
        this.elevationCertificateUrl = model.elevationCertificateUrl;
        this.createdDate = model.createdDate;
        this.floodZone = model.floodZone;
        this.insurerId = model.insurerId;
        this.insurerIdString = model.insurerIdString;
        this.buildingCover = model.buildingCover;
        this.contentsCover = model.contentsCover;
        this.lossOfUse = model.lossOfUse;
        this.otherStructuresCover = model.otherStructuresCover;
        this.additionalLivingExpenses = model.additionalLivingExpenses;
        this.deductible = model.deductible;
        this.premium = model.premium;
        this.fees = model.fees;
        this.taxes = model.taxes;
        this.total = model.total;
        this.quoteDate = model.quoteDate;
        this.quoteNumber = model.quoteNumber;
        this.isNewProperty = model.isNewProperty;
        this.propertyOccupy = model.propertyOccupy;

        this.isAgentGenerated = model.isAgentGenerated;
        this.internalQuoteNumber = model.internalQuoteNumber;
        this.policyEffectiveDate = model.policyEffectiveDate;
        this.policyHolderEmail = model.policyHolderEmail;
        this.policyHolderFirstName = model.policyHolderFirstName;
        this.policyHolderLastName = model.policyHolderLastName;
        this.policyHolderPhoneNumber = model.policyHolderPhoneNumber;
        this.blobId = model.blobId;
    };

    public toDto = (): PropertyQuoteModelDTO => {
        return {
            id: this.id,
            userId: this.userId,
            propertyId: this.propertyId,
            address1: this.address1,
            address2: this.address2,
            city: this.city,
            state: this.state,
            zipCode: this.zipCode,
            buildingCoverage: this.buildingCoverage,
            contentsCoverage: this.contentsCoverage,
            isOverWater: this.isOverWater,
            isMobileHome: this.isMobileHome,
            constructionTypeId: this.constructionTypeId,
            constructionTypeIdString: this.constructionTypeIdString,
            constructionYear: this.constructionYear,
            noOfStories: this.noOfStories,
            squareFootage: this.squareFootage,
            foundationTypeId: this.foundationTypeId,
            foundationTypeIdString: this.foundationTypeIdString,
            hasGarage: this.hasGarage,
            hasCrawlSpace: this.hasCrawlSpace,
            hasEnclosure: this.hasEnclosure,
            ownershipType: this.ownershipType,
            occupationType: this.occupationType,
            enclosureTypes: this.enclosureTypes,
            propertyType: this.propertyType,
            insurancePolicyType: this.insurancePolicyType,
            elevationCertificateUrl: this.elevationCertificateUrl,
            createdDate: this.createdDate,
            floodZone: this.floodZone,
            insurerId: this.insurerId,
            insurerIdString: this.insurerIdString,
            buildingCover: this.buildingCover,
            contentsCover: this.contentsCover,
            lossOfUse: this.lossOfUse,
            otherStructuresCover: this.otherStructuresCover,
            additionalLivingExpenses: this.additionalLivingExpenses,
            deductible: this.deductible,
            premium: this.premium,
            fees: this.fees,
            taxes: this.taxes,
            total: this.total,
            quoteDate: this.quoteDate,
            quoteNumber: this.quoteNumber,
            isNewProperty: this.isNewProperty,
            propertyOccupy: this.propertyOccupy,

            isAgentGenerated: this.isAgentGenerated,
            internalQuoteNumber: this.internalQuoteNumber,
            policyEffectiveDate: this.policyEffectiveDate,
            policyHolderEmail: this.policyHolderEmail,
            policyHolderFirstName: this.policyHolderFirstName,
            policyHolderLastName: this.policyHolderLastName,
            policyHolderPhoneNumber: this.policyHolderPhoneNumber,
            blobId: this.blobId,
        };
    };

    @MobX.action
    public reset = () => {
        this.id = undefined;
        this.userId = "";
        this.address1 = "";
        this.address2 = "";
        this.city = "";
        this.state = "";
        this.zipCode = "";
        this.propertyId = "";
        this.buildingCoverage = 0;
        this.contentsCoverage = 0;
        this.isOverWater = false;
        this.isMobileHome = false;
        this.constructionTypeId = "";
        this.constructionTypeIdString = "";
        this.constructionYear = 0;
        this.noOfStories = 0;
        this.squareFootage = 0;
        this.foundationTypeId = "";
        this.foundationTypeIdString = "";
        this.hasGarage = false;
        this.hasCrawlSpace = false;
        this.hasEnclosure = false;
        this.ownershipType = 0;
        this.occupationType = 0;
        this.enclosureTypes = "";
        this.propertyType = 0;
        this.insurancePolicyType = 0;
        this.elevationCertificateUrl = "";
        this.createdDate = "";
        this.floodZone = "";
        this.insurerId = "";
        this.insurerIdString = "";
        this.buildingCover = 0;
        this.contentsCover = 0;
        this.lossOfUse = 0;
        this.otherStructuresCover = 0;
        this.additionalLivingExpenses = 0;
        this.deductible = 0;
        this.premium = 0;
        this.fees = 0;
        this.taxes = 0;
        this.total = 0;
        this.quoteDate = "";
        this.quoteNumber = "";
        this.isNewProperty = false;
        this.propertyOccupy = 0;

        this.internalQuoteNumber = "";
        this.isAgentGenerated = false;
        this.policyEffectiveDate = "";
        this.policyHolderEmail = "";
        this.policyHolderFirstName = "";
        this.policyHolderLastName = "";
        this.policyHolderPhoneNumber = "";
        this.blobId = "";
    };

    @MobX.action
    public setFromResultQuoteModel = (quote: ResultQuoteModel, policyEffectiveDate: string): void => {
        this.insurerIdString = quote.name;
        this.buildingCover = quote.buildingCover;
        this.contentsCover = quote.contentsCover;
        this.lossOfUse = quote.lossOfUse;
        this.otherStructuresCover = quote.otherStructuresCover;
        this.additionalLivingExpenses = quote.additionalLivingExpenses;
        this.deductible = quote.deductible;
        this.premium = quote.premium;
        this.fees = quote.fees;
        this.taxes = quote.taxes;
        this.total = quote.total;
        this.quoteDate = policyEffectiveDate;
        this.quoteNumber = quote.quoteNumber;
    };
}

export interface PropertyQuoteModelDTO {
    id?: string;
    userId: string;
    propertyId: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;
    buildingCoverage: number;
    contentsCoverage: number;
    isOverWater: boolean;
    isMobileHome: boolean;
    constructionTypeId: string;
    constructionTypeIdString: string;
    constructionYear: number;
    noOfStories: number;
    squareFootage: number;
    foundationTypeId: string;
    foundationTypeIdString: string;
    hasGarage: boolean;
    hasCrawlSpace: boolean;
    hasEnclosure: boolean;
    ownershipType: number;
    occupationType: number;
    enclosureTypes: string;
    propertyType: number;
    insurancePolicyType: number;
    elevationCertificateUrl: string;
    createdDate: string;
    floodZone: string;
    insurerId: string;
    insurerIdString: string;
    buildingCover: number;
    contentsCover: number;
    lossOfUse: number;
    otherStructuresCover: number;
    additionalLivingExpenses: number;
    deductible: number;
    premium: number;
    fees: number;
    taxes: number;
    total: number;
    quoteDate: string;
    quoteNumber: string;
    isNewProperty: boolean | undefined;
    propertyOccupy: number | undefined;
    isAgentGenerated: boolean;
    internalQuoteNumber: string;
    policyEffectiveDate: string;
    policyHolderEmail: string;
    policyHolderFirstName: string;
    policyHolderLastName: string;
    policyHolderPhoneNumber: string;
    blobId: string;
}
