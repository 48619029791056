import { React, styled, useRouter, useObserver, useState, useEffect, StoresInstance } from "../../Globals/Base";
import * as Mui from "@material-ui/core";
import UserViewModel from "../../../Custom/ViewModels/UserViewModel";
import { EditableInput } from "../../../Core/Components/EditableInput";
import { User } from "../../Models/User";
import { KeyValuePair } from "Core/Models/KeyValuePair";
import { Role } from "Custom/Models";
import RoleViewModel from "Custom/ViewModels/RoleViewModel";
import { DivWrapper, DivWrapperBGFixed } from "Custom/StylesAppSpecific/GridHelperStyling";
import { Footer } from "Custom/Components/Footer";
import Puddles from "Content/homeBG2.png";
import { UserForm } from "../../AdminComponents/UserForm";
import { AdminTabs } from "Custom/AdminComponents/AdminTabs";

const domainStores = StoresInstance.domain;
export const Container = styled(Mui.Box)``;
export const DetailContainer = styled(Mui.Box)`
    position: relative;
    background-color: white;
`;
const userViewModel = new UserViewModel();

export const UserDetails: React.FC = () => {
    const { history, match } = useRouter();
    const [newUser, setNewUser] = useState(false);

    let { id } = match.params as any;

    React.useEffect(() => {
        (async () => {
            let currentUser = new User("");
            if (id === "0") {
                setNewUser(true);
            } else {
                const existingUser = userViewModel.getUser(id);
                if (existingUser == null) {
                    await userViewModel.loadUsersAsync();
                }
                currentUser = userViewModel.getUser(id) || currentUser;
            }

            userViewModel.setUser(currentUser, newUser);
        })();
    }, [newUser]);

    React.useEffect(() => {
        window.scrollTo(0, 0);
        if (history.location.pathname === "/userlist/user/0") {
            setNewUser(true);
        }
    }, [history.location.pathname]);

    return useObserver(() => (
        <>
            <AdminTabs value={1} />
            <DivWrapperBGFixed additionalcss={"background-image: url(" + Puddles + ");"}>
                <DivWrapper backgroundcolor={"rgba(27, 43, 55, 0.75)"} additionalcss="padding: 0 120px 100px;">
                    <Mui.Box padding="32px 0" display="flex">
                        <Mui.Box flexGrow={1}>
                            <Mui.Typography variant="h1" style={{ color: "white" }}>
                                {newUser ? "Add new user" : "User details"}
                            </Mui.Typography>
                        </Mui.Box>
                    </Mui.Box>
                    <UserForm viewModel={userViewModel} newUser={newUser} />
                </DivWrapper>
            </DivWrapperBGFixed>
            <Footer isTemp={false} />
        </>
    ));
};

interface DetailPanelProps {
    newUser: boolean;
    id: string;
}
export const DetailPanel: React.FC<DetailPanelProps> = props => {
    let user: User = new User("");

    //const [editable, seteditable] = useState(false);
    const [loader, setLoader] = useState(false);
    const { history } = useRouter();
    const [errorMessage, setErrorMessage] = useState("");
    const [roleViewModel] = useState(() => new RoleViewModel());

    const getUserRoles = async () => {
        const rolesResponse = await roleViewModel.loadRolesAsync();

        if (rolesResponse.wasSuccessful) {
            const response = await userViewModel.getUserRoles();

            if (!response.wasSuccessful) {
                if (response.errors) {
                    setErrorMessage(response.errors[0].message);
                } else {
                    setErrorMessage("There was a problem getting this user's roles.");
                }
            }
        } else {
            if (rolesResponse.errors) {
                setErrorMessage(rolesResponse.errors[0].message);
            } else {
                setErrorMessage("There was a problem getting the roles.");
            }
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        if (props.id !== "0") {
            let currentUser = userViewModel.getUser(props.id);
            if (currentUser) {
                userViewModel.setUser(currentUser, false);
                getUserRoles();
            } else {
                userViewModel.loadUsersAsync().then(() => {
                    let currentUser = userViewModel.getUser(props.id);
                    if (currentUser) {
                        userViewModel.setUser(currentUser, false);
                        getUserRoles();
                    }
                });
            }
        } else {
            userViewModel.setUser(user, true);
        }
    }, []);

    const doSubmit = (e: any) => {
        e.preventDefault();
        if (!userViewModel.isModelValid()) {
            setErrorMessage("Problem");
        } else {
            setErrorMessage("");
            setLoader(true);
            userViewModel.postUserDetailAsync().then((data: User) => {
                setLoader(false);
                domainStores.ShowSnackBar("User saved successfully");
                history.push("/admin");
            });
        }
    };

    return useObserver(() => (
        <form onSubmit={doSubmit} id="detailsPanelForm">
            <DetailContainer>
                {errorMessage !== "" && (
                    <Mui.Typography variant="caption" style={{ color: "red" }}>
                        {errorMessage}
                    </Mui.Typography>
                )}
                <Mui.Box display="flex" textAlign="right">
                    <Mui.Box flexGrow={1}>
                        <Mui.Box display="flex">
                            <Mui.Box justifyContent="flex-end" flexGrow={1} paddingRight="20px">
                                <Mui.Button id="save" variant="contained" color="primary" type="submit" disabled={loader}>
                                    {!loader ? "Save" : "Saving"}
                                </Mui.Button>
                                <span style={{ paddingRight: "20px" }} />
                                <Mui.Button
                                    id="cancel"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                        history.push("/admin");
                                    }}
                                >
                                    Cancel
                                </Mui.Button>
                            </Mui.Box>
                        </Mui.Box>
                    </Mui.Box>
                </Mui.Box>

                <>
                    <Mui.Grid container>
                        <Mui.Grid item md={4}>
                            <Mui.FormControl>
                                <EditableInput viewModel={userViewModel} label="Username" fieldName="email" />
                            </Mui.FormControl>
                        </Mui.Grid>
                    </Mui.Grid>
                </>
                <Mui.Grid container>
                    <Mui.Grid md={4} item>
                        <Mui.FormControl>
                            <EditableInput viewModel={userViewModel} label="First name" fieldName="firstName" />
                        </Mui.FormControl>
                    </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid container>
                    <Mui.Grid md={4} item>
                        <Mui.FormControl>
                            <EditableInput viewModel={userViewModel} label="Last name" fieldName="lastName" />
                        </Mui.FormControl>
                    </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid container>
                    <Mui.Grid md={4} item>
                        <Mui.FormControl>
                            <EditableInput viewModel={userViewModel} type="password" label="Password" fieldName="password" />
                        </Mui.FormControl>
                    </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid container>
                    <Mui.Grid md={4} item>
                        <Mui.FormControl>
                            <EditableInput
                                viewModel={userViewModel}
                                type="multiselect"
                                label="Roles"
                                fieldName="userRoles"
                                selectItems={domainStores.RoleStore.getRoles.map(role => ({ value: role, key: role.name })) as KeyValuePair[]}
                                inputProps={{
                                    renderValue: (selected: Role[]) => (
                                        <div>
                                            {selected.map(value => (
                                                <Mui.Chip key={value.id} label={value.name} />
                                            ))}
                                        </div>
                                    ),
                                }}
                            />
                        </Mui.FormControl>
                    </Mui.Grid>
                </Mui.Grid>
            </DetailContainer>
        </form>
    ));
};
