import { Grid } from "@material-ui/core";
import styled from "styled-components";

export interface IComponentProps {
    padding?: string;
    margin?: string;
    fontcolor?: string;
    backgroundcolor?: string;
    additionalcss?: string;
}

export const SEOStateContainer: any = styled.div<IComponentProps>`
    /*max-width: 1107px;*/
    /*width: 100%;*/
    background-color: ${props => (props.backgroundcolor !== undefined ? props.backgroundcolor : "unset")};
    margin: 0px auto;
    padding-bottom: 50px;
    padding-top: 1px;

    ul {
        padding-left: 20px;

        li {
            font-weight: bold;
            font-size: 18px;
            padding-bottom: 20px;
        }
    }

    h2 {
        text-align: center;
        font-size: 36px;
        letter-spacing: -1.8px;
    }

    p {
        font-size: 14px;
        font-weight: 100;
        letter-spacing: -0.35px;
        line-height: 24px;
        margin-bottom: 30px;
    }

    ${props => props.additionalcss};
`;
