import { Box, Button, Fade } from "@material-ui/core";
import React from "react";
import { styled, useObserver } from "../Globals/Base";

// Components
import { Footer } from "../Components/Footer";

import { useRouter } from "Core/Utils";
import { DivWrapper, GridSpaced, DialogueWrapper, DivWrapperBGFixed } from "../StylesAppSpecific/GridHelperStyling";
import Puddles from "Content/homeBG2.png";
import MFIheaderlogo from "Content/logoForDarkBG.png";
import {
    LoginBox,
    SignInButton,
    LoginForm,
    HeaderLogo,
    ForgotPasswordButton,
    HomeButton,
    ForgotPasswordSuccessStyles,
} from "Custom/StylesAppSpecific/LoginStyling";
import { Centered } from "Core/Components/Centered";

export const ForgotPasswordSuccessPage: React.FunctionComponent = () => {
    const { history } = useRouter();

    return useObserver(() => (
        <>
            <DivWrapperBGFixed additionalcss={"background-image: url(" + Puddles + ");"}>
                <DivWrapper backgroundcolor={"rgba(27, 43, 55, 0.75)"}>
                    <Centered>
                        <Box textAlign="center">
                            <HeaderLogo src={MFIheaderlogo} alt="My Flood Insurance" />
                        </Box>
                        <Fade in timeout={1000}>
                            <ForgotPasswordSuccessStyles>
                                <h1>
                                    Thank you, please check your inbox for an email from us
                                    <br />
                                    with your password reset link
                                </h1>
                                <HomeButton onClick={() => history.push(`/`)}>Home</HomeButton>
                            </ForgotPasswordSuccessStyles>
                        </Fade>
                    </Centered>
                </DivWrapper>
            </DivWrapperBGFixed>
            <Footer isTemp={false} />
        </>
    ));
};
