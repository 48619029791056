import aonedgeLogo from "Content/aonedge-logo.png";
import argeniaLogo from "Content/argenia-logo.png";
import assurantLogo from "Content/assurant-logo.png";
import hiscoxWright from "Content/HiscoxWrightLogo.png";
import mfiProUserLogo from "Content/mfi-prouser-logo.svg";
import floodrisksolutionsLogo from "Content/floodrisksolutions-logo.png";
import sterlingLogo from "Content/sterlingLogo.png";
import wrightLogo from "Content/wright-logo.png";
import wrightNFIPLogo from "Content/wrightnfip-logo.png";
import zurichLogo from "Content/zurichWrightLogo.png";
import neptuneLogo from "Content/neptuneLogo.png";
import nfiLogo from "Content/national-flood-insurance.png";

export const getInsurerLogo = (insurerName: string): string => {
    let logo = "";

    switch (insurerName) {
        case "Sterling":
            logo = sterlingLogo;
            break;

        case "AON Edge":
            logo = aonedgeLogo;
            break;

        case "Argenia":
            logo = argeniaLogo;
            break;

        case "Wright NFIP":
            logo = wrightNFIPLogo;
            break;

        case "Wright PFA Plus":
            logo = wrightLogo;
            break;

        case "Wright PrivateFlood":
            logo = wrightLogo;
            break;

        case "Wright Private Flood":
            logo = wrightLogo;
            break;

        case "Hiscox FloodPlus":
            logo = hiscoxWright;
            break;

        case "Flood Risk Solutions":
            logo = floodrisksolutionsLogo;
            break;

        case "Zurich":
            logo = zurichLogo;
            break;

        case "MFI Pro User":
            logo = mfiProUserLogo;
            break;

        case "Neptune":
            logo = neptuneLogo;
            break;

        case "NFIP":
            logo = nfiLogo;
            break;

        default:
            logo = nfiLogo;
            break;
    }

    return logo;
};
