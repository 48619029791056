// Libraries
import * as MobX from "mobx";

// Core
import { FieldType, isEmptyOrWhitespace } from "Core/Utils/Utils";
import { PostFormWithFile } from "Core/Utils";
import { ViewModelBase } from "Core/ViewModels";

// Custom
import { Server } from "Custom/Globals/AppUrls";
import { CityModel, CityModelDTO, PageEnquiryModel } from "Custom/Models";

/**
 * Edit city viewmodel.
 */
export default class EditCityViewModel extends ViewModelBase<CityModel> {
    /**
     * Constructor
     *
     * @param link The city to load (if defined).
     */
    constructor(link: string) {
        super(new CityModel());

        this.setDecorators(CityModel);

        // Load city if a link is available.
        if (!isEmptyOrWhitespace(link) && link !== "0") {
            this.load(link);
        }
    }

    /**
     * Returns if the page has been published.
     */
    @MobX.computed
    public get isPublished(): boolean {
        return !this.model.isHidden;
    }

    /**
     * Returns a shortened version of the image url.
     */
    @MobX.computed
    public get image(): string {
        return isEmptyOrWhitespace(this.model.image)
            ? ""
            : this.model.image.lastIndexOf("/") > -1
            ? this.model.image.substring(this.model.image.lastIndexOf("/") + 1)
            : this.model.image.lastIndexOf("\\") > -1
            ? this.model.image.substring(this.model.image.lastIndexOf("\\") + 1)
            : this.model.image;
    }

    // #region Actions

    /**
     * Cancels any changes and navigates to the admin page.
     */
    public cancel = (): void => {
        this.history.push("/admin/editcity");
    };

    @MobX.action
    public setPermLink = () => {
        let newLink = "";
        if (this.getValue<string>("permaLink").length == 0){
            newLink = this.getValue<string>("title").replace(/^\s+|\s+$/g, '');
        } else {
            newLink = this.getValue<string>("permaLink").replace(/^\s+|\s+$/g, '');
        }
        newLink = newLink.toLocaleLowerCase();
        newLink = newLink.replace(/[^a-z0-9 -]/g, "");
        newLink = newLink.replace(/\s+/g, "-");
        newLink = newLink.replace(/-+/g, "-");
        this.setValue("permaLink", newLink);
    }

    /**
     * Sets the page body in the model.
     */
    public setPageBody = (pageBody: string): void => {
        this.model.setPageBody(pageBody);
    };

    /**
     * Set is hidden in the model.
     */
    public setIsPublished = (isPublished: boolean): void => {
        this.model.setIsHidden(!isPublished);
    };

    // #endregion Actions

    // #region Server Api

    /**
     * Loads the city from the server.
     */
    public load = async (link: string): Promise<void> => {
        try {
            this.setIsLoading(true);

            const enquiryModel: PageEnquiryModel = { viewHidden: true, permaLink: link };
            const apiResult = await this.Post<CityModelDTO>(Server.Api.Pages.CityItem, enquiryModel);

            if (apiResult.wasSuccessful) {
                this.model.fromDto(apiResult.payload);
            } else {
                // Error. What to do?
                this.setIsErrored(true);
            }
        } catch (exception) {
            // Error. What to do?
            this.setIsErrored(true);
        } finally {
            this.setIsLoading(false);
        }
    };

    /**
     * Saves the city to the server.
     */
    public save = async (): Promise<void> => {
        try {
            this.setIsSaving(true);

            const apiResult = await this.Post<CityModelDTO>(Server.Api.Pages.SetCity, this.model.toDto());

            if (apiResult.wasSuccessful) {
                // Success. What to do? Stay on page or navigate away?
                this.model.fromDto(apiResult.payload);
                this.history.push("/admin/editcity");
            } else {
                // Error. What to do?
                this.setIsErrored(true);
            }
        } catch (exception) {
            // Error. What to do?
            this.setIsErrored(true);
        } finally {
            this.setIsSaving(false);
        }
    };

    public uploadImage = async (files: FileList | null): Promise<void> => {
        if (files !== null) {
            for (var fileIndex = 0; fileIndex < files.length; fileIndex++) {
                const file = files[fileIndex];

                try {
                    const apiResult = await PostFormWithFile<string>(Server.Api.Upload.UploadFile, file);

                    if (apiResult.wasSuccessful) {
                        this.model.setImage(apiResult.payload);
                    } else {
                        // Error. What to do?
                        this.setIsErrored(true);
                    }
                } catch (exception) {
                    // Error. What to do?
                    this.setIsErrored(true);
                } finally {
                    this.setIsSaving(false);
                }
            }
        }
    };

    // #endregion Server Api

    // #region Viewmodel Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Viewmodel Boilerplate
}
