// Libraries
import clsx from "clsx";
import React from "react";
import { Button, Divider, Paper, Grid, Typography, useMediaQuery as useMuiMediaQuery, Theme } from "@material-ui/core";

// Content

// Components
import { ResultQuoteModel } from "../../Models/";
import { StoresInstance } from "../../Stores";

// Styling & images
import { useNFIPCardStyles, useResultStyles } from "./ResultPropsStyling";
import { moneyDollarFormat } from "../../Utils/Money";
import { isEmptyOrWhitespace } from "../../../Core/Utils/Utils";
import assurantLogo from "Content/assurant-logo.png";
import nfiLogo from "Content/national-flood-insurance.png";
import wrightNFIPLogo from "Content/WrightNFIP.png";
import downloadPDF from "Content/pdfDLIcon.svg";
import chatIcon from "Content/chatIcon.svg";

interface IProp {
    position: number;
    callback: (chosenVal: number) => void;
    pdfCallBack: (chosenVal: number) => void;
    chatClick: (chatClick: boolean) => void;
    quoteDetail: ResultQuoteModel;
}

declare var purechatApi: any;

export const WNFIPQuoteCard: React.FC<IProp> = props => {
    const classes = useResultStyles();
    const customClasses = useNFIPCardStyles();
    const xsOrUnder = useMuiMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
    const isSelectedQuote = !isEmptyOrWhitespace(props.quoteDetail.internalQuoteNumber);
    const domainStores = StoresInstance.domain;

    const selectButtonText = isSelectedQuote ? "Selected" : "View";

    return (
        <Paper className={isSelectedQuote ? classes.selectedPaper : classes.paper}>
            <Grid container>
                <Grid className={classes.header} container>
                    <Grid className={classes.imageGridItem} item xs={2} md={2}>
                        <img alt={props.quoteDetail.name} src={wrightNFIPLogo} />
                    </Grid>
                    <Grid className={classes.headerNFIPDetailsGridItem} item xs={6} md={5}>
                        <Typography className={classes.insurerText}>Wright NFIP</Typography>
                        <Typography className={classes.premiumText}>Premium: {moneyDollarFormat(props.quoteDetail.total, true)}</Typography>
                    </Grid>
                    {!xsOrUnder && (
                        <Grid className={classes.selectButtonGridItem} item>
                            <Button className={classes.iconButton} variant="contained" onClick={() => purechatApi.set("chatbox.expanded", true)}>
                                <img src={chatIcon} />
                            </Button>
                            {!domainStores.wrapper && (
                                <Button className={classes.iconButton} variant="contained" onClick={() => props.pdfCallBack(props.position)}>
                                    <img src={downloadPDF} />
                                </Button>
                            )}
                            <Button
                                className={isSelectedQuote ? classes.selectedButton : classes.selectButton}
                                variant="contained"
                                onClick={() => props.callback(props.position)}
                            >
                                {selectButtonText}
                            </Button>
                        </Grid>
                    )}
                </Grid>
                <Grid container className={classes.deductibleContainer}>
                    <Grid item sm={3}>
                        <Typography className={classes.deductibleText}>
                            Buildings coverage: {moneyDollarFormat(props.quoteDetail.buildingCover, true)}
                        </Typography>
                    </Grid>
                    <Grid item sm={3}>
                        <Typography className={classes.deductibleText}>
                            Contents coverage: {moneyDollarFormat(props.quoteDetail.contentsCover, true)}
                        </Typography>
                    </Grid>
                    <Grid item sm={3}>
                        <Typography className={classes.deductibleText}>
                            Buildings deductible: {moneyDollarFormat(props.quoteDetail.deductible, true)}
                        </Typography>
                    </Grid>
                    <Grid item sm={3}>
                        <Typography className={classes.deductibleText}>
                            Contents deductible: {moneyDollarFormat(props.quoteDetail.contentDeductible, true)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid className={clsx(classes.body, customClasses.body)} container>
                    <Grid container>
                        <Grid className={clsx(classes.bodyGridItem, customClasses.bodyGridItemTitle)} item xs={12}>
                            <img alt={props.quoteDetail.name} src={nfiLogo} />
                            <Typography className={clsx(classes.bodyTitle, customClasses.bodyTitle)}>NFIP policy</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid className={classes.bodyGridItem} item xs={12}>
                            <Typography className={classes.bodyItemText}>
                                The National Flood Insurance Program (NFIP) is administered by the Federal Emergency Management Administration (FEMA). Flood
                                insurance offered through the NFIP is based on set rates that do not vary from carrier to carrier. The NFIP has maximum
                                residential coverage limits of $250,000 for building and $100,000 for contents.
                            </Typography>
                        </Grid>
                        {xsOrUnder && (
                            <Grid container>
                                <Grid className={classes.selectButtonGridItem} item>
                                    <Button
                                        className={isSelectedQuote ? classes.selectedButton : classes.selectButton}
                                        variant="contained"
                                        onClick={() => props.callback(props.position)}
                                    >
                                        {selectButtonText}
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};
