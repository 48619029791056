// Libraries
import React from "react";
import { Typography, Paper } from "@material-ui/core";

// Custom
import { useInsurerStatisticsHeaderStyles } from "../../StylesAppSpecific/Admin/AdminDashboard";

export const InsurerHeader: React.FC = () => {
    const classes = useInsurerStatisticsHeaderStyles();

    return (
        <Paper className={classes.paper}>
            <Typography className={classes.title}>Carriers (total quotes)</Typography>
        </Paper>
    );
};
