// Libraries
import React, { useEffect } from "react";
import { useObserver } from "mobx-react-lite";

// Components
import { StoresInstance } from "Custom/Globals/Base";

// Styling & images
import greenTick from "Content/greenTick.svg";
import redCross from "Content/redCross.svg";
import { green } from "@material-ui/core/colors";

interface IProps {
    thisResponse: string;
    thisModal: (thisModal: string) => void;
}

export const Responses: React.FC<IProps> = props => {
    const domainStores = StoresInstance.domain;
    const viewModel = domainStores.QuoteRequestViewModel;
    const propertyDetailVM = domainStores.PropertyDetailViewModel;

    const handleClick = (val: string) => {
        console.log("Responses:val=" + val);

        switch (val) {
            case "NumberOfFloors":
                propertyDetailVM.setModalTitle("Number of stories");
                break;

            case "YearBuilt":
                propertyDetailVM.setModalTitle("Year built");
                break;

            case "OverWater":
                propertyDetailVM.setModalTitle("Over water?");
                break;

            case "BuildingSqFt":
                propertyDetailVM.setModalTitle("Square footage");
                break;

            case "HasGarage":
                propertyDetailVM.setModalTitle("Garage?");
                break;

            case "ConstructionType":
                propertyDetailVM.setModalTitle("Construction type");
                break;

            case "FoundationType":
                propertyDetailVM.setModalTitle("Foundation type");
                break;

            default:
                propertyDetailVM.setModalTitle("");
                break;
        }
        props.thisModal(val);
        return null;
    };

    const yearBuilt = () => {
        let retIco = viewModel.isFieldValid("yearBuilt", "yearBuilt") ? greenTick : redCross;
        return (
            <>
                <div className="response yearbuilt">
                    <img src={retIco} />
                    was built in {viewModel.getValue("yearBuilt")} <span onClick={() => handleClick(props.thisResponse)}>Change</span>
                </div>
            </>
        );
    };

    const numberOfFloors = () => {
        let retIco = viewModel.isFieldValid("numberOfFloors", "numberOfFloors") ? greenTick : redCross;
        return (
            <>
                <div className="response numberofstories">
                    <img src={retIco} />
                    has {viewModel.getValue("numberOfFloors")} stories <span onClick={() => handleClick(props.thisResponse)}>Change</span>
                </div>
            </>
        );
    };

    const buildingSqft = () => {
        let retIco = viewModel.isFieldValid("buildingSqft", "buildingSqft") ? greenTick : redCross;
        return (
            <>
                <div className="response buildingsqft">
                    <img src={retIco} />
                    is {viewModel.getValue("buildingSqft")} sq ft <span onClick={() => handleClick(props.thisResponse)}>Change</span>
                </div>
            </>
        );
    };

    const hasGarage = () => {
        let retVal = "";
        let retIco = redCross;
        switch (viewModel.getValue("hasGarage")) {
            case "true":
                retVal = "has an attached garage";
                retIco = greenTick;
                break;

            case "false":
                retVal = "no attached garage";
                retIco = greenTick;
                break;

            default:
                retVal = "is there a garage?";
                break;
        }
        return (
            <>
                <div className="response hasgarage">
                    <img src={retIco} />
                    {retVal} <span onClick={() => handleClick(props.thisResponse)}>Change</span>
                </div>
            </>
        );
    };

    const overWater = () => {
        let retVal = "";
        let retIco = redCross;
        switch (viewModel.getValue("isOverWater")) {
            case "true":
                retVal = "is partially / completely over water";
                retIco = greenTick;
                break;

            case "false":
                retVal = "is not partially / completely over water";
                retIco = greenTick;
                break;

            default:
                retVal = "is the property over water?";
                break;
        }
        return (
            <>
                <div className="response overwater">
                    <img src={retIco} />
                    {retVal} <span onClick={() => handleClick(props.thisResponse)}>Change</span>
                </div>
            </>
        );
    };

    const constructionType = () => {
        let retVal = "";
        let retIco = redCross;
        switch (viewModel.getValue<string>("constructionType")) {
            case "masonry":
                retVal = "Masonry / Concrete...";
                retIco = greenTick;
                break;

            case "frame":
                retVal = "Frame / Wood / Siding";
                retIco = greenTick;
                break;

            case "brickVeneer":
                retVal = "Brick Veneer";
                retIco = greenTick;
                break;

            case "log":
                retVal = "Log";
                retIco = greenTick;
                break;

            case "steel":
                retVal = "Steel";
                retIco = greenTick;
                break;

            case "masonry":
                retVal = "Masonry / Concrete / Block / Solid Brick";
                retIco = greenTick;
                break;

            default:
                retVal = "Other";
                break;
        }

        return (
            <>
                <div className="response constructiontype">
                    <img src={retIco} />
                    is constructed with: {retVal} <span onClick={() => handleClick(props.thisResponse)}>Change</span>
                </div>
            </>
        );
    };

    const foundationType = () => {
        let retVal = "";
        let retIco = redCross;
        switch (viewModel.getValue<string>("foundationType")) {
            case "slab":
                retVal = "Slab on grade";
                retIco = greenTick;
                break;

            case "basement":
                retVal = "Raised floor with basement";
                retIco = greenTick;
                break;

            case "crawlspace":
                retVal = "Raised floor with crawlspace";
                retIco = greenTick;
                break;

            case "piers":
                retVal = "Elevated on piers";
                retIco = greenTick;
                break;

            default:
                retVal = "Other";
                break;
        }
        return (
            <>
                <div className="response foundationtype">
                    <img src={retIco} />
                    its foundations are: {retVal} <span onClick={() => handleClick(props.thisResponse)}>Change</span>
                </div>
            </>
        );
    };

    const renderResponse = (val: string) => {
        switch (val) {
            case "YearBuilt":
                return yearBuilt();
                break;

            case "NumberOfFloors":
                return numberOfFloors();
                break;

            case "BuildingSqFt":
                return buildingSqft();
                break;

            case "HasGarage":
                return hasGarage();
                break;

            case "OverWater":
                return overWater();
                break;

            case "ConstructionType":
                return constructionType();
                break;

            case "FoundationType":
                return foundationType();
                break;
        }
    };

    return useObserver(() => <>{renderResponse(props.thisResponse)}</>);
};
