// Libraries
import React, { useContext } from "react";
import { useMediaQuery } from "Core/Utils/Media";
import { Link } from "@material-ui/core";

// Components
import { MetaHeader } from "../Components/SEOComponents";
import { SEOHeader } from "../Models";
import { Footer } from "../Components/Footer";
import { useRouter } from "../Globals/Base";

// Styling & images
import { DivWrapper, GridSpaced, DialogueWrapper } from "../StylesAppSpecific/GridHelperStyling";
import { PartnerDiv, PartnerItem, PartnerImageContainer } from "../StylesAppSpecific/PartnersStyling";
import PartnersBackground from "Content/PartnersBackground.jpg";

import aonedgelogo from "Content/partners/aonedgelogo.png";
import floodRiskSolutions from "Content/partners/floodRiskSolutions.png";
import nfilogo from "Content/partners/national-flood-insurance-logo.png";
import nfiplogo from "Content/partners/national-flood-insurance-program.png";
import sterling from "Content/partners/sterling.png";
import wright from "Content/partners/wright.png";
import wrightNFIP from "Content/partners/wrightNFIP.png";
import argenialogo from "Content/argenia-logo.png";
import hiscoxwright from "Content/partners/HiscoxWrightLogo-1.png";
import zurichwright from "Content/partners/ZurichWrightLogo.png";

export const Partners: React.FC = () => {
    let isMobile: boolean;
    window.scrollTo(0, 0);
    isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;
    const { history } = useRouter();

    let metaData: SEOHeader = {
        title: "My Flood Insurance Partners - Private Flood and NFIP Options",
        description:
            "We understand the importance of having options for flood insurance. We offer coverage through the NFIP and several private flood carriers.",
    };

    const contentHdr = (
        <>
            <GridSpaced
                container
                justify={"center"}
                margin={"0px auto"}
                padding={isMobile ? "14px 0px" : "30px 0px"}
                additionalcss={isMobile ? "max-width: 300px; width: 100%; " : "max-width: 1000px; width: 100%;"}
            >
                <GridSpaced item xs={12} sm={12}>
                    <h1>Partners we work with to bring you the best possible quotes</h1>
                </GridSpaced>
            </GridSpaced>
            <GridSpaced
                container
                justify={"center"}
                margin={"0px auto"}
                additionalcss={isMobile ? "max-width: 335px; width: 100%;" : "max-width: 1110px; width: 100%;"}
            >
                <GridSpaced item xs={12} sm={12}>
                    <p>
                        We understand the importance of having more than one option for flood insurance and we strive to provide you with the best choices
                        available. We offer coverage through the National Flood Insurance Program as well as several A+ rated private flood carriers. Keep
                        reading to learn more about the companies we work with.
                    </p>
                </GridSpaced>
            </GridSpaced>
        </>
    );

    const nfi = (
        <>
            <GridSpaced
                container
                justify={"center"}
                margin={"30px auto 0px"}
                additionalcss={isMobile ? "max-width: 335px; width: 100%;" : "max-width: 1124px; width: 100%;"}
            >
                <PartnerImageContainer item xs={12} sm={2}>
                    <img src={nfilogo} alt="National Flood Insurance" />
                </PartnerImageContainer>
                <PartnerItem item xs={12} sm={10} margin={isMobile ? "0px" : "0px 0px 0px 40px"}>
                    <h3>National Flood Insurance, LLC</h3>
                    <p>
                        National Flood Insurance, LLC, is one of the nation's leading flood insurance agencies. As a flood-only agency, we provide the
                        experience and expertise you need to find a flood insurance policy that best fits your property and budget. All quotes through My Flood
                        Insurance are sent to a flood expert at National Flood Insurance, LLC for processing.
                    </p>
                </PartnerItem>
            </GridSpaced>
            <GridSpaced
                container
                justify={"center"}
                margin={"30px auto 0px"}
                additionalcss={isMobile ? "max-width: 335px; width: 100%;" : "max-width: 1124px; width: 100%;"}
            >
                <PartnerImageContainer item xs={12} sm={2}>
                    <img src={nfiplogo} alt="National Flood Insurance" style={{ width: "70%", height: "auto" }} />
                </PartnerImageContainer>
                <PartnerItem item xs={12} sm={10} margin={isMobile ? "0px" : "0px 0px 0px 40px"}>
                    <h3>FEMA’s National Flood Insurance Program (NFIP)</h3>
                    <p>
                        The National Flood Insurance Program (NFIP) is administered by The Federal Emergency Management Agency (FEMA) (a government backed
                        program). The NFIP’s goal is to reduce the impact of flooding on private and commercial buildings by providing flood insurance to
                        participating communities nationwide.
                    </p>
                </PartnerItem>
            </GridSpaced>
            <GridSpaced
                container
                justify={"center"}
                margin={"30px auto 0px"}
                additionalcss={isMobile ? "max-width: 335px; width: 100%;" : "max-width: 1124px; width: 100%;"}
            >
                <PartnerImageContainer item xs={12} sm={2}>
                    <img src={wrightNFIP} alt="National Flood Insurance" />
                </PartnerImageContainer>
                <PartnerItem item xs={12} sm={10} margin={isMobile ? "0px" : "0px 0px 0px 40px"}>
                    <h3>Our NFIP Partner, Wright Flood</h3>
                    <p>
                        Wright Flood has been writing federal flood insurance for 30+ years and is the largest provider of NFIP policies in the United States.
                        Wright Flood prides itself on excellent customer service and claims handling as well, as the flood expertise of its team.
                    </p>
                    <Link href={"https://www.wrightflood.net/login"} title="Make claim" target="_blank">
                        Make claim
                    </Link>
                </PartnerItem>
            </GridSpaced>
        </>
    );

    const nfip = <></>;

    const privateCarriers = (
        <>
            <GridSpaced
                container
                justify={"center"}
                margin={isMobile ? "0px auto" : "0px auto 30px"}
                padding="30px 0px 0px"
                additionalcss={isMobile ? "max-width: 335px; width: 100%;" : "max-width: 1124px; width: 100%;"}
            >
                <GridSpaced item xs={12} sm={12} additionalcss={"text-align: center;"}>
                    <h2>Private carriers we work with</h2>
                </GridSpaced>
            </GridSpaced>
            {/*             
            <GridSpaced
                container
                justify={"center"}
                margin={"0px auto 40px"}
                padding="30px 0px 0px"
                additionalcss={isMobile ? "max-width: 335px; width: 100%;" : "max-width: 1124px; width: 100%;"}
            >
                <PartnerImageContainer item xs={12} sm={2}>
                    <img src={argenialogo} alt="Argenia" />
                </PartnerImageContainer>
                <PartnerItem item xs={12} sm={10} margin={isMobile ? "0px" : "0px 0px 0px 40px"}>
                    <h3>Argenia</h3>
                    <p>
                        Since 1976 Argenia has been helping insurance agents connect their customers with quality flood insurance packages. Currently available
                        in 48 states, we are one of the fastest growing alternatives to the NFIP for flood insurance products.
                    </p>
                    <Link href={"https://online.argenia.com/claims/default.asp"} title="Make claim" target="_blank">
                        Make claim
                    </Link>
                </PartnerItem>
            </GridSpaced> 
            */}
            <GridSpaced
                container
                justify={"center"}
                margin={"0px auto 40px"}
                additionalcss={isMobile ? "max-width: 335px; width: 100%;" : "max-width: 1124px; width: 100%;"}
            >
                <PartnerImageContainer item xs={12} sm={2}>
                    <img src={aonedgelogo} alt="AON Edge" />
                </PartnerImageContainer>
                <PartnerItem item xs={12} sm={10} margin={isMobile ? "0px" : "0px 0px 0px 40px"}>
                    <h3>AON Edge</h3>
                    <p>
                        Aon Edge is a division of Aon. Our colleagues help deliver superior policy administration, claims processing, customer service and
                        agency support for our clients. We are the leading provider of customizable private flood insurance, dedicated to making flood insurance
                        accessible to all insurance agents and property owners.“
                    </p>
                    <Link href={"https://www.aonedge.com/Claims#"} title="Make claim" target="_blank">
                        Make claim
                    </Link>
                </PartnerItem>
            </GridSpaced>
            <GridSpaced
                container
                justify={"center"}
                margin={"0px auto 40px"}
                additionalcss={isMobile ? "max-width: 335px; width: 100%;" : "max-width: 1124px; width: 100%;"}
            >
                <PartnerImageContainer item xs={12} sm={2}>
                    <img src={floodRiskSolutions} alt="Flood Risk Solutions" />
                </PartnerImageContainer>
                <PartnerItem item xs={12} sm={10} margin={isMobile ? "0px" : "0px 0px 0px 40px"}>
                    <h3>Flood Risk Solutions</h3>
                    <p>
                        Flood Risk Solutions offers home and business owners access to multiple flood insurance options. Using advanced flood modeling
                        technology, our system quotes a unique flood insurance package for each property through multiple flood insurance underwriting
                        companies.
                    </p>
                    <Link href={"https://www.floodsol.com/"} title="Make claim" target="_blank">
                        Make claim
                    </Link>
                </PartnerItem>
            </GridSpaced>
            <GridSpaced
                container
                justify={"center"}
                margin={"0px auto 40px"}
                additionalcss={isMobile ? "max-width: 335px; width: 100%;" : "max-width: 1124px; width: 100%;"}
            >
                <PartnerImageContainer item xs={12} sm={2}>
                    <img src={sterling} alt="Sterling Surplus" />
                </PartnerImageContainer>
                <PartnerItem item xs={12} sm={10} margin={isMobile ? "0px" : "0px 0px 0px 40px"}>
                    <h3>Sterling Surplus</h3>
                    <p>
                        Sterling Surplus Underwriters has been the industry leader for Private Flood placement since 2008. Dedicated to solving problems for
                        flood related issues Sterling Surplus Underwriters creatively packaged a program offering bank accepted coverage for risks throughout
                        the Atlantic and Gulf Coasts. We’re relentless in providing our customers with a first class experience by offering flood solutions
                        through multiple markets managed by our dedicated staff of Flood Underwriters and Claim Professionals.
                    </p>
                    <Link href={"https://backoffice.sterlingsu.com/submitclaim"} title="Make claim" target="_blank">
                        Make claim
                    </Link>
                </PartnerItem>
            </GridSpaced>
            <GridSpaced
                container
                justify={"center"}
                margin={"0px auto 40px"}
                additionalcss={isMobile ? "max-width: 335px; width: 100%;" : "max-width: 1124px; width: 100%;"}
            >
                <PartnerImageContainer item xs={12} sm={2}>
                    <img src={wright} alt="Hiscox FloodPlus (non-admitted)" />
                </PartnerImageContainer>
                <PartnerItem item xs={12} sm={10} margin={isMobile ? "0px" : "0px 0px 0px 40px"}>
                    <h3>Hiscox FloodPlus (non-admitted)</h3>
                    <p>
                        Available exclusively for the US market, Hiscox FloodPlus builds on Hiscox’s decades of experience in protecting homeowners from the
                        devastating effects of flood. With a wider scope of cover and a choice of higher limits, Hiscox FloodPlus offers more comprehensive
                        protection than alternative flood products currently available. Hiscox can trace its Lloyd’s roots back to 1901 and underwrites through
                        Syndicate 33, one of the oldest and most respected syndicates in Lloyd’s. All syndicates benefit from the security of Lloyd’s high
                        quality A+ financial rating (S&P), as well as its Central Fund.
                    </p>
                    <Link href={"https://www.wrightflood.net/login"} title="Make claim" target="_blank">
                        Make claim
                    </Link>
                </PartnerItem>
            </GridSpaced>
            <GridSpaced
                container
                justify={"center"}
                margin={"0px auto 40px"}
                additionalcss={isMobile ? "max-width: 335px; width: 100%;" : "max-width: 1124px; width: 100%;"}
            >
                <PartnerImageContainer item xs={12} sm={2}>
                    <img src={wright} alt="Wright Flood Residential Private Flood Program (admitted)" />
                </PartnerImageContainer>
                <PartnerItem item xs={12} sm={10} margin={isMobile ? "0px" : "0px 0px 0px 40px"}>
                    <h3>Wright Flood Residential Private Flood Program (admitted)</h3>
                    <p>
                        Wright Flood Residential Private Flood Insurance combines all the financial security offered by financially secure AM Best A- (Excellent) VII or better rated carriers with Wright’s more than 30 years’ flood claims service and experience. Together, they deliver the first personal and customizable, private flood insurance that is admitted and approved by your state’s Department of Insurance.
                    </p>
                    <Link href={"https://www.wrightflood.net/login"} title="Make claim" target="_blank">
                        Make claim
                    </Link>
                </PartnerItem>
            </GridSpaced>
            <GridSpaced
                container
                justify={"center"}
                margin={"0px auto"}
                additionalcss={isMobile ? "max-width: 335px; width: 100%;" : "max-width: 1124px; width: 100%;"}
            >
                <PartnerImageContainer item xs={12} sm={2}>
                    <img src={wright} alt="Wright Flood Residential Private Flood Program (non-admitted)" />
                </PartnerImageContainer>
                <PartnerItem item xs={12} sm={10} margin={isMobile ? "0px" : "0px 0px 0px 40px"}>
                    <h3>Wright Flood Residential Private Flood Program (non-admitted)</h3>
                    <p>
                        Wright Flood Residential Private Flood Program combines all the financial security offered by a financially secure AM Best A+ (Superior) XV rated carrier with Wright’s more than 30 years’ flood claims service and experience. Together, they deliver the first personal and customizable, private flood insurance that provides the flexibility and security needed by today’s sophisticated property owners to protect their most valuable asset.
                    </p>
                    <Link href={"https://www.wrightflood.net/login"} title="Make claim" target="_blank">
                        Make claim
                    </Link>
                </PartnerItem>
            </GridSpaced>
        </>
    );

    return (
        <>
            <MetaHeader Model={metaData} />
            <PartnerDiv additionalcss={"background-image: url(" + PartnersBackground + ");"}>
                {contentHdr}
                {nfi}
                {nfip}
            </PartnerDiv>
            <PartnerDiv backgroundcolor={"rgba(27, 43, 55, 0.75)"} additionalcss={"min-height: 100%; padding-bottom: 200px;"}>
                {privateCarriers}
            </PartnerDiv>
            <Footer isTemp={false} />
        </>
    );
};
