// Libraries
import React, { useState, useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { useMediaQuery } from "Core/Utils/Media";

// Models and ViewModels
import { CoverageViewModel, DateEffectiveViewModel } from "Custom/ViewModels";
import { CoverageModel, DateEffectiveModel } from "Custom/Models";
import { ProRegisterViewModel } from "../ViewModels/ProRegisterViewModel";
import { RealtorRegisterViewModel } from "../ViewModels/RealtorRegisterViewModel";

// Components
import { EditableInput } from "Core/Components/EditableInput";
import { Footer } from "../Components/Footer";
import { StoresInstance } from "../Stores";
import { useRouter } from "Core/Utils";
import { QuoteLoading } from "../Components/IsLoading";
import { EditBar, LocationEdit, YouYourEdit } from "../Components/EditBar";
import { CoverageLevels } from "Custom/Components/Coverage";
import { dateTimeISOString } from "Custom/Utils/Time";
import { ProAgentRegister } from "Custom/Components/StepsRegister/ProAgentRegister";
import { OwnerRegister } from "Custom/Components/StepsRegister/OwnerRegister";
import { RealtorRegister } from "Custom/Components/StepsRegister/RealtorRegister";
import { PolicyHolder } from "Custom/Components/StepsRegister/PolicyHolder";

// Styling & images
import { DivWrapperBGFixed } from "../StylesAppSpecific/GridHelperStyling";
import { ConfirmDetailsPanel, PlaceHolder, GetQuotesButton } from "Custom/StylesAppSpecific/StepsStyling";
import { ContactDetailStyle } from "Custom/StylesAppSpecific/FormStyling";
import { ContactDetailsContainerTransparent, SelectWidth } from "Custom/Components/PropertyDetails";
import { DatePickerContainer } from "Custom/Components/PropertySteps/Steps.Styling";
import PXFuel from "Content/quoteBG.jpg";
import Commercial from "Content/commercial.svg";
import Condo from "Content/condo.svg";
import Mobile from "Content/mobile.svg";
import SFRes from "Content/SFRes.svg";
import calendar from "Content/calendar.svg";

/**
 * Step 2: Confirm these details about property
 */
export const Step3Coverage: React.FC = () => {
    const domainStores = StoresInstance.domain;
    const quoteRequestVM = domainStores.QuoteRequestViewModel;
    const propertyDetailVM = domainStores.PropertyDetailViewModel;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { history } = useRouter();
    const [errorMessage, setErrorMessage] = useState("");

    //window.scrollTo(0, 0);

    let isMobile: boolean = useMediaQuery("screen and (max-width: 767px)") ? true : false;

    const [viewModel] = useState(() => new CoverageViewModel());
    const [dateEffectiveViewModel] = useState(() => new DateEffectiveViewModel(quoteRequestVM.getValue("dateEffective")));
    const [proRegisterViewModel] = useState(() => new ProRegisterViewModel());
    const [realtorRegisterViewModel] = useState(() => new RealtorRegisterViewModel());

    const requestAddress: string = quoteRequestVM.getValue("address");
    const requestPopulated: boolean = requestAddress.length > 0 ? true : false;

    // Set the deductibleOption.
    // Use the value from quoteRequestVM.deductible to get the key for viewModel.deductibleOption
    // Can't just set viewModel.deductible as it is computed, and viewModel.deductibleOption is the key, not the value.
    for (let i = 0; i < CoverageModel.deductibleOptions.length; i++) {
        let item = CoverageModel.deductibleOptions[i];

        if (item.value == quoteRequestVM.getValue("deductible")) {
            viewModel.setValue("deductibleOption", item.key);
            break;
        }
    }

    useEffect(() => {
        if (!isLoading && requestPopulated) {
            //viewModel.setValue("buildingCoverage", quoteRequestVM.getValue("buildingCoverage"));
            //viewModel.setValue("contentsCoverage", quoteRequestVM.getValue("contentsCoverage"));
            viewModel.setValue("buildingCoverageFormatted", quoteRequestVM.getValue<string>("buildingCoverage").toLocaleString());
            viewModel.setValue("contentsCoverageFormatted", quoteRequestVM.getValue<string>("contentsCoverage").toLocaleString());
            quoteRequestVM.isModelValid();
            window.scrollTo(0, 0);
        }
    }, [isLoading, requestPopulated]);

    const headerImg = () => {
        switch (quoteRequestVM.getValue<string>("chosenPropertyType")) {
            case "sfr":
                return SFRes;
                break;

            case "mobile":
                return Mobile;
                break;

            case "condo":
                return Condo;
                break;

            case "commercial":
                return Commercial;
                break;

            case "rcbap":
                return Condo;
                break;

            default:
                break;
        }
    };

    const contentHdr = isMobile ? (
        <>
            <img src={headerImg()} alt="Confirm these details about your property" />
            <h2 className="propertyheader">
                <span>Step 3:</span> Coverage
            </h2>
        </>
    ) : (
        <>
            <h2 className="propertyheader">
                <img src={headerImg()} alt="Confirm these details about your property" />
                <span>Step 3:</span> Coverage
            </h2>
        </>
    );

    const coverageBdy = (
        <>
            <DatePickerContainer>
                <ContactDetailStyle padding="35px 0px">
                    <h2 className="start-from">When do you want the policy coverage to start from?</h2>
                    <div className="date-selects">
                        <div>
                            <img alt="" src={calendar} />
                        </div>
                        <ContactDetailsContainerTransparent>
                            <SelectWidth minWidth={isMobile ? 101 : 198}>
                                <EditableInput<DateEffectiveModel>
                                    viewModel={dateEffectiveViewModel}
                                    fieldName="month"
                                    type="select"
                                    selectItems={[
                                        { key: "January", value: 0 },
                                        { key: "Febuary", value: 1 },
                                        { key: "March", value: 2 },
                                        { key: "April", value: 3 },
                                        { key: "May", value: 4 },
                                        { key: "June", value: 5 },
                                        { key: "July", value: 6 },
                                        { key: "August", value: 7 },
                                        { key: "September", value: 8 },
                                        { key: "October", value: 9 },
                                        { key: "November", value: 10 },
                                        { key: "December", value: 11 },
                                    ]}
                                />
                            </SelectWidth>
                        </ContactDetailsContainerTransparent>
                        <ContactDetailsContainerTransparent>
                            <SelectWidth minWidth={isMobile ? 48 : 89}>
                                <EditableInput<DateEffectiveModel>
                                    viewModel={dateEffectiveViewModel}
                                    fieldName="day"
                                    type="select"
                                    selectItems={dateEffectiveViewModel.selectDays}
                                />
                            </SelectWidth>
                        </ContactDetailsContainerTransparent>
                        <ContactDetailsContainerTransparent>
                            <SelectWidth minWidth={isMobile ? 64 : 120}>
                                <EditableInput<DateEffectiveModel>
                                    viewModel={dateEffectiveViewModel}
                                    fieldName="year"
                                    type="select"
                                    selectItems={dateEffectiveViewModel.selectYears}
                                />
                            </SelectWidth>
                        </ContactDetailsContainerTransparent>
                    </div>
                </ContactDetailStyle>
            </DatePickerContainer>
            <CoverageLevels viewModel={viewModel} />
        </>
    );

    const nextButton = () => {
        switch (quoteRequestVM.isWhatUserType) {
            case "propertyOwner":
                return (
                    <>
                        <GetQuotesButton disabled={!quoteRequestVM.canProceed} variant="contained" onClick={() => doSubmit()}>
                            Click here to get your quotes
                        </GetQuotesButton>
                    </>
                );
                break;

            case "realEstateAgent":
                return (
                    <>
                        {domainStores.AccountStore.IsLoggedIn && domainStores.AccountStore.IsRealtorAccount ? (
                            <GetQuotesButton disabled={!quoteRequestVM.canProceed} variant="contained" onClick={() => doSubmit()}>
                                Click here to get your quotes
                            </GetQuotesButton>
                        ) : (
                            <GetQuotesButton disabled={false} form="realtor-register" variant="contained" type="submit" onClick={() => submitRealtorRegister}>
                                Click here to get your quotes
                            </GetQuotesButton>
                        )}
                    </>
                );
                break;

            case "insuranceAgent":
                return (
                    <>
                        {domainStores.AccountStore.IsLoggedIn && domainStores.AccountStore.IsProAccount ? (
                            <GetQuotesButton disabled={!quoteRequestVM.canProceed} variant="contained" onClick={() => doSubmit()}>
                                Click here to get your quotes
                            </GetQuotesButton>
                        ) : (
                            <GetQuotesButton disabled={false} form="pro-register" variant="contained" type="submit" onClick={() => submitProRegister}>
                                Continue agency setup
                            </GetQuotesButton>
                        )}
                    </>
                );
                break;

            default:
                break;
        }
    };

    const submitRealtorRegister = async (e: any) => {
        quoteRequestVM.setValue(
            "dateEffective",
            dateTimeISOString(dateEffectiveViewModel.model.day, dateEffectiveViewModel.model.month, dateEffectiveViewModel.model.year),
        );
        viewModel.saveToQuoteRequest();

        let contactViewModel = domainStores.ContactViewModel;
        contactViewModel.setValue("terms", realtorRegisterViewModel.getValue("hasAcceptedTerms"));

        realtorRegisterViewModel.setValue("realtorName", realtorRegisterViewModel.agencyDetails.getValue("name"));
        realtorRegisterViewModel.agencyDetails.setValue("userId", null);

        // dirty: recaptcha is expected but never
        realtorRegisterViewModel.setValue("passCaptcha", true);

        e.preventDefault();
        //isValid will check all fields to make sure they are in a valid state.
        let error = "";
        if (realtorRegisterViewModel.checkModelValidity()) {
            const result = await realtorRegisterViewModel.registerRealtor();
            if (result.wasSuccessful) {
                setIsLoading(true);
                quoteRequestVM.submitActions();
                quoteRequestVM.tryGetQuotes().then(success => success !== undefined && history.push(success ? "/availablepolicies" : "/Step3"));
                error = "none";
            } else if (result.errors && result.errors[0] && result.errors[0].message) {
                setErrorMessage(result.errors[0].message);
                error = result.errors[0].message;
            } else {
                setErrorMessage("An unknown error occured.");
                error = "An unknown error occured.";
            }
        } else {
            error = "validation failed";
        }

        console.log("submitRealtorRegister error: " + error);
    };

    const submitProRegister = async (e: any) => {
        quoteRequestVM.setValue(
            "dateEffective",
            dateTimeISOString(dateEffectiveViewModel.model.day, dateEffectiveViewModel.model.month, dateEffectiveViewModel.model.year),
        );
        viewModel.saveToQuoteRequest();

        let contactViewModel = domainStores.ContactViewModel;
        contactViewModel.setValue("terms", proRegisterViewModel.getValue("hasAcceptedTerms"));

        proRegisterViewModel.setValue("agencyName", proRegisterViewModel.agencyDetails.getValue("name"));
        proRegisterViewModel.agencyDetails.setValue("userId", null);

        // dirty: recaptcha is expected but never
        proRegisterViewModel.setValue("passCaptcha", true);

        e.preventDefault();
        //isValid will check all fields to make sure they are in a valid state.
        let error = "";
        if (proRegisterViewModel.checkModelValidity()) {
            const result = await proRegisterViewModel.registerPro();
            if (result.wasSuccessful) {
                history.push("/carrier-settings");
                error = "none";
            } else if (result.errors && result.errors[0] && result.errors[0].message) {
                setErrorMessage(result.errors[0].message);
                error = result.errors[0].message;
            } else {
                setErrorMessage("An unknown error occured.");
                error = "An unknown error occured.";
            }
        } else {
            error = "validation failed";
        }

        console.log("submitProRegister error: " + error);
    };

    const doSubmit = () => {
        quoteRequestVM.setValue(
            "dateEffective",
            dateTimeISOString(dateEffectiveViewModel.model.day, dateEffectiveViewModel.model.month, dateEffectiveViewModel.model.year),
        );

        viewModel.saveToQuoteRequest();

        setIsLoading(true);
        quoteRequestVM.submitActions();
        quoteRequestVM.tryGetQuotes().then(success => success !== undefined && history.push(success ? "/availablepolicies" : "/emailexists"));
    };

    const showLoginRegister = () => {
        switch (quoteRequestVM.getUserType) {
            case "propertyOwner":
                return (
                    <>
                        <OwnerRegister />
                    </>
                );
                break;

            case "realEstateAgent":
                return (
                    <>
                        {domainStores.AccountStore.IsLoggedIn && domainStores.AccountStore.IsRealtorAccount ? (
                            <PolicyHolder viewModel={quoteRequestVM} />
                        ) : (
                            <form id="realtor-register" onSubmit={submitRealtorRegister}>
                                <RealtorRegister viewModel={realtorRegisterViewModel} />
                            </form>
                        )}
                    </>
                );
                break;

            case "insuranceAgent":
                return (
                    <>
                        {domainStores.AccountStore.IsLoggedIn && domainStores.AccountStore.IsProAccount ? (
                            <PolicyHolder viewModel={quoteRequestVM} />
                        ) : (
                            <form id="pro-register" onSubmit={submitProRegister}>
                                <ProAgentRegister viewModel={proRegisterViewModel} />
                            </form>
                        )}
                    </>
                );
                break;

            default:
                break;
        }
    };

    const editBar = (
        <EditBar>
            <LocationEdit editlink="/Step1" />
            <YouYourEdit editlink="/Step2" />
        </EditBar>
    );

    return useObserver(() => (
        <>
            <DivWrapperBGFixed additionalcss={"background-image: url(" + PXFuel + ");"}>
                {!propertyDetailVM.IsLoading ? (
                    <>
                        {editBar}
                        <ConfirmDetailsPanel>
                            <div className="uniqueOptions">{contentHdr}</div>
                            {coverageBdy}
                            {showLoginRegister()}
                        </ConfirmDetailsPanel>

                        <PlaceHolder>{nextButton()}</PlaceHolder>
                    </>
                ) : (
                    <>
                        <QuoteLoading />
                    </>
                )}
            </DivWrapperBGFixed>
            <Footer isTemp={false} />
        </>
    ));
};
