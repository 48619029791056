// Libraries
import { useObserver } from "mobx-react-lite";
import React from "react";
import { Box, Button, Divider, Typography, Paper } from "@material-ui/core";

// Custom
import { CarrierSettingsModal } from "./CarrierSettingsModal";
import { useInsurerStatisticsBodyStyles } from "../../StylesAppSpecific/Agent/AgentDashboard";
import { getInsurerLogo } from "../../Utils/InsurerLogo";
import AgentInsurerStatisticsViewModel from "../../ViewModels/Agent/AgentInsurerStatisticsViewModel";
import { getInsurerName } from "../../Utils/InsurerName";

interface IProps {
    viewModel: AgentInsurerStatisticsViewModel;
}

export const InsurerStatisticsCard: React.FC<IProps> = (props: IProps) => {
    const classes = useInsurerStatisticsBodyStyles();

    const onSettingButtonClick = () => {
        props.viewModel.setCanDisplaySettings(true);
    };

    return useObserver(() => (
        <Paper className={classes.paper}>
            <Box className={classes.imageContainer}>
                <img alt={props.viewModel.insurerName} src={getInsurerLogo(props.viewModel.insurerName)} />
            </Box>
            <Box className={classes.textContainer}>
                <Box>
                    <Typography className={classes.title} component="div">
                        {getInsurerName(props.viewModel.insurerName)}
                    </Typography>
                </Box>
                <Box className={classes.statisticsContainer}>
                    <Box className={classes.statisticsSubContainer}>
                        <Typography className={classes.statisticsText} component="div">
                            <span>Produced: </span>
                            {props.viewModel.numberOfQuotesProduced}
                        </Typography>
                    </Box>
                    <Box className={classes.statisticsSubContainer}>
                        <Typography className={classes.statisticsText} component="div">
                            <span>Selected: </span>
                            {props.viewModel.numberOfQuotesSelected}
                        </Typography>
                    </Box>
                </Box>
                <Divider className={classes.solidDivider} />
                <Box className={classes.actionsContainer}>
                    <Box className={classes.actionContainer}>
                        <Typography className={props.viewModel.haveCarrierOrNFIPartner ? classes.verifiedText : classes.unverifiedText} component="div">
                            {props.viewModel.insurerStatusText}
                        </Typography>
                        {/*<Button
                            className={classes.verifyButton}>
                            Verify
                           </Button>
                           <VerificationModal
                            isOpen={false} />
                        */}
                    </Box>
                    <Box className={classes.actionContainer}>
                        <Button className={classes.settingsButton} onClick={onSettingButtonClick}>
                            Settings
                        </Button>
                        <CarrierSettingsModal viewModel={props.viewModel} />
                    </Box>
                </Box>
            </Box>
        </Paper>
    ));
};
