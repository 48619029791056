// Libraries
import * as MobX from "mobx";

// Core
import { FieldType, isEmptyOrWhitespace } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";

// Custom
import { ResultQuoteModel, PropertyQuoteModelDTO } from "../Models";
import { ChosenQuoteModel } from "../Models/ChosenQuoteModel";
import { ChosenQuoteForPDFModel } from "../Models/ChosenQuoteForPDFModel";
import { StoresInstance } from "../Stores";
import PropertyQuoteStore from "../Stores/Domain/PropertyQuoteStore";
import { getFoundationType } from "../Utils/FoundationType";
import { moneyFormat } from "../Utils/Money";
import { getPropertyTypeById } from "../Utils/PropertyType";
import { effectiveDateRange, easternStandardTime } from "../Utils/Time";
import { Server } from "../Globals/AppUrls";
import { isNullOrUndefined } from "util";
import { resultName, policyExtras } from "../Utils/ResultsNameMisc";
import AdminUserAndQuoteResultStore from "../Stores/Domain/Admin/AdminUserAndQuoteResultStore";

/**
 * View quote viewmodel.
 */
export default class QuoteViewModel extends ViewModelBase<ResultQuoteModel> {
    @MobX.observable
    public insurerName: string = "";

    @MobX.observable
    public id: string = "";

    // #region Policy Details

    @MobX.computed
    public get policyName() {
        return resultName(this.model.name, this.model.quoteMisc);
    }

    @MobX.computed
    public get policyInstruction() {
        return policyExtras(this.model.quoteMisc);
    }

    @MobX.computed
    public get policyQuoteReference() {
        return this.model.quoteNumber;
    }

    @MobX.computed
    public get policyStartDate() {
        return effectiveDateRange(PropertyQuoteStore.instance.model.policyEffectiveDate);
    }

    // #endregion Policy Details

    // #region Policy Holder

    @MobX.computed
    public get policyHolderAddress() {
        return PropertyQuoteStore.instance.model.address1;
    }

    @MobX.computed
    public get policyHolderEmailAddress() {
        return PropertyQuoteStore.instance.model.policyHolderEmail;
    }

    @MobX.computed
    public get policyHolderName() {
        return `${PropertyQuoteStore.instance.model.policyHolderFirstName} ${PropertyQuoteStore.instance.model.policyHolderLastName}`;
    }

    @MobX.computed
    public get policyHolderFirstName() {
        return `${PropertyQuoteStore.instance.model.policyHolderFirstName}`;
    }

    @MobX.computed
    public get policyHolderLastName() {
        return `${PropertyQuoteStore.instance.model.policyHolderLastName}`;
    }

    @MobX.computed
    public get policyHolderPhoneNumber() {
        return PropertyQuoteStore.instance.model.policyHolderPhoneNumber;
    }

    // #endregion Policy Holder

    // #region Property

    @MobX.computed
    public get propertyAddress() {
        return PropertyQuoteStore.instance.model.address1;
    }

    @MobX.computed
    public get propertyConstructionType() {
        return PropertyQuoteStore.instance.model.constructionTypeIdString;
    }

    @MobX.computed
    public get propertyConstructionYear() {
        return PropertyQuoteStore.instance.model.constructionYear;
    }

    @MobX.computed
    public get propertyFoundationType() {
        return getFoundationType(PropertyQuoteStore.instance.model.foundationTypeIdString);
    }

    @MobX.computed
    public get propertySize() {
        return `${PropertyQuoteStore.instance.model.squareFootage} sq ft`;
    }

    @MobX.computed
    public get propertyType() {
        return getPropertyTypeById(PropertyQuoteStore.instance.model.propertyId);
    }

    @MobX.computed
    public get propertyStories() {
        return PropertyQuoteStore.instance.model.noOfStories;
    }

    @MobX.computed
    public get propertyIsOverWater() {
        return PropertyQuoteStore.instance.model.isOverWater === true ? "Yes" : "No";
    }

    @MobX.computed
    public get propertyIsMobileHome() {
        return PropertyQuoteStore.instance.model.isMobileHome === true ? "Yes" : "No";
    }

    @MobX.computed
    public get propertyHasGarage() {
        return PropertyQuoteStore.instance.model.hasGarage === true ? "Yes" : "No";
    }

    @MobX.computed
    public get propertyHasEnclosure() {
        if (PropertyQuoteStore.instance.model.hasEnclosure === false) {
            return "No";
        } else {
            return PropertyQuoteStore.instance.model.enclosureTypes;
        }
    }

    @MobX.computed
    public get propertyNewProperty() {
        return PropertyQuoteStore.instance.model.isNewProperty ? "A new property" : "An existing property";
    }

    @MobX.computed
    public get propertyPolicyEffectiveDate() {
        return PropertyQuoteStore.instance.model.policyEffectiveDate;
    }

    @MobX.computed
    public get propertyOwnershipType() {
        switch (PropertyQuoteStore.instance.model.ownershipType) {
            case 1:
                return "Own and occupy";
            case 2:
                return "Own and rent";
            case 3:
                return "Rent";
        }

        return "";
    }

    @MobX.computed
    public get propertyOccupy() {
        switch (PropertyQuoteStore.instance.model.propertyOccupy) {
            case 50:
                return "0-50%";
            case 79:
                return "51-79%";
            case 100:
                return "80-100%";
        }

        return "";
    }

    @MobX.computed
    public get propertyInsurancePolicy() {
        switch (PropertyQuoteStore.instance.model.insurancePolicyType) {
            case 1:
                return "Yes";
            case 2:
                return "No";
            case 3:
                return "Expired";
        }

        return "";
    }

    @MobX.computed
    public get propertyElevationCertificate() {
        return PropertyQuoteStore.instance.model.elevationCertificateUrl !== "" ? "Yes" : "No";
    }

    @MobX.computed
    public get propertyFloodZone() {
        return PropertyQuoteStore.instance.model.floodZone;
    }

    // #endregion Property

    // #region Figures

    @MobX.computed
    public get buildingCoverage() {
        return moneyFormat(this.model.buildingCover, true);
    }

    @MobX.computed
    public get contentCoverage() {
        return moneyFormat(this.model.contentsCover, true);
    }

    @MobX.computed
    public get structuresCover() {
        return moneyFormat(this.model.otherStructuresCover, true);
    }

    @MobX.computed
    public get lossOfUse() {
        return moneyFormat(this.model.lossOfUse, true);
    }

    @MobX.computed
    public get deductible() {
        return moneyFormat(this.model.deductible, true);
    }

    @MobX.computed
    public get premium() {
        return moneyFormat(this.model.premium, true);
    }

    @MobX.computed
    public get taxesAndFees() {
        return moneyFormat(this.model.taxes + this.model.fees, true);
    }

    @MobX.computed
    public get total() {
        return moneyFormat(this.model.total, true);
    }

    public formatTrueFalse(setVal: string) {
        let retVal: string = "";
        switch (setVal) {
            case "true":
                retVal = "yes";
                break;
            default:
                retVal = "no";
        }
        return retVal;
    }

    public propertyOccupyType(setVal: number) {
        let retVal: string = "";
        switch (setVal) {
            case 50:
                retVal = "0-50%";
                break;
            case 79:
                retVal = "51-79%";
                break;
            default:
                retVal = "80-100%";
        }
        return retVal;
    }

    public ownershipType(setVal: string) {
        let retVal: string = "";
        switch (setVal) {
            case "ownAndOccupy":
                retVal = "I own and occupy this property";
                break;
            case "ownAndRent":
                retVal = "I own this property and rent it out to someone else";
                break;
            default:
                retVal = "I rent this property from someone else";
        }
        return retVal;
    }

    public lastQuoteDateFormatted(quoteDateString: string) {
        if (isNullOrUndefined(quoteDateString) || quoteDateString === "") {
            return "";
        }

        return easternStandardTime(quoteDateString).format("MM/DD/YY");
    }
    // #endregion Figures

    /**
     * Emails the info@
     * to be used if outside office hours (8am - 8pm)
     * Sends the PropertyQuoteStore model to the API
     */
    public async sendQuoteDetails(isSelect: boolean = false) {
        let quoteRequestViewModel = StoresInstance.domain.QuoteRequestViewModel;
        var chosenQuote: ChosenQuoteModel = {
            firstName: this.policyHolderFirstName,
            lastName: this.policyHolderLastName,
            //email: StoresInstance.domain.AccountStore.Email,
            email: this.policyHolderEmailAddress,
            agentEmailAddress: StoresInstance.domain.AccountStore.Email,
            agentFirstName: StoresInstance.domain.AccountStore.FirstName,
            agentLastName: StoresInstance.domain.AccountStore.LastName,
            agentTel: StoresInstance.domain.AccountStore.Phone,
            agencyName: StoresInstance.domain.AccountStore.AgencyName,
            tel: this.policyHolderPhoneNumber,
            insurerName: this.insurerName,
            policyQuoteReference: /* PropertyQuoteStore.instance.model.quoteNumber */ this.policyQuoteReference,
            policyStartDate: effectiveDateRange(PropertyQuoteStore.instance.model.policyEffectiveDate),
            propertyAddress: quoteRequestViewModel.getValue("address"),
            propertyConstructionType: quoteRequestViewModel.getValue("constructionType"),
            propertyConstructionYear: this.propertyConstructionYear.toString(),
            propertySize: PropertyQuoteStore.instance.model.squareFootage.toString(),
            propertyType: getPropertyTypeById(PropertyQuoteStore.instance.model.propertyId),
            buildingCoverage: moneyFormat(PropertyQuoteStore.instance.model.buildingCoverage, true),
            contentsCoverage: moneyFormat(PropertyQuoteStore.instance.model.contentsCoverage, true),
            otherStructures: moneyFormat(PropertyQuoteStore.instance.model.otherStructuresCover, true),
            lossOfUse: moneyFormat(PropertyQuoteStore.instance.model.lossOfUse, true),
            deductible: moneyFormat(PropertyQuoteStore.instance.model.deductible, true),
            premium: this.premium,
            taxes: this.taxesAndFees,
            fees: moneyFormat(this.model.fees, true),
            total: moneyFormat(this.model.total, true),
            floodZone: this.propertyFloodZone,
            numberOfFloors: quoteRequestViewModel.getValue("numberOfFloors"),
            isOverWater: this.formatTrueFalse(quoteRequestViewModel.getValue("isOverWater")),
            isMobileHome: this.formatTrueFalse(quoteRequestViewModel.getValue("isMobileHome")),
            hasGarage: this.formatTrueFalse(quoteRequestViewModel.getValue("hasGarage")),
            foundationType: quoteRequestViewModel.getValue("foundationType"),
            basementUsage: quoteRequestViewModel.getValue("enclosureTypes"),
            isNewProperty: quoteRequestViewModel.model.isNewProperty == "true" ? "A new property" : "An existing property",
            dateEffective: this.lastQuoteDateFormatted(PropertyQuoteStore.instance.model.policyEffectiveDate).toString(),
            ownershipType: this.ownershipType(quoteRequestViewModel.getValue("ownershipType")),
            propertyOccupy: this.propertyOccupyType(quoteRequestViewModel.getValue("propertyOccupy")).toString(),
            insurancePolicy: quoteRequestViewModel.getValue("insurancePolicy"),
            elevationCertificateUrl: quoteRequestViewModel.getValue("elevationCertificateUrl"),
            quoteName: this.policyName,
            quoteInstruction: this.policyInstruction,
            quoteMisc: this.model.quoteMisc,
            isSelect,
            referrerName: quoteRequestViewModel.getValue("widgetSource"),
        };

        StoresInstance.domain.PremVal = this.total;

        let apiResult = await this.Post<ChosenQuoteModel>(Server.Api.Quotes.EmailInfo, chosenQuote);
        if (apiResult.wasSuccessful) {
            console.log("Success Payload: " + apiResult.payload);
        } else {
            console.log("Payload Errors: " + apiResult.errors);
        }
        return apiResult;
    }

    /**
     * Constructor
     *
     * @param insurer The name of the insurer.
     * @param id The quote to load (if defined).
     */
    constructor(insurerName: string, id: string) {
        super(new ResultQuoteModel());
        this.setDecorators(ResultQuoteModel);

        if (!isEmptyOrWhitespace(insurerName) && !isEmptyOrWhitespace(id)) {
            this.insurerName = insurerName;
            this.id = id;

            this.load(id);
        }
    }

    // #region Navigation

    /**
     * Navigate back to the available policies page.
     */
    public navigateBack = (): void => {
        this.history.push("/availablepolicies");
    };

    // #endregion Navigation

    // #region Server Api

    /**
     * Loads the specified quote from the store.
     *
     * @param id The unique identifier of the quote to load.
     */
    public load = async (id: string): Promise<void> => {
        let quotesArr: ResultQuoteModel[] = [];

        quotesArr.push.apply(quotesArr, StoresInstance.domain.QuoteResultViewModel.model.aonEdgeResult.quotes);
        quotesArr.push.apply(quotesArr, StoresInstance.domain.QuoteResultViewModel.model.argeniaResult.quotes);
        quotesArr.push.apply(quotesArr, StoresInstance.domain.QuoteResultViewModel.model.fRSResult.quotes);
        quotesArr.push.apply(quotesArr, StoresInstance.domain.QuoteResultViewModel.model.neptuneResult.quotes);
        quotesArr.push.apply(quotesArr, StoresInstance.domain.QuoteResultViewModel.model.sterlingResult.quotes);
        quotesArr.push.apply(quotesArr, StoresInstance.domain.QuoteResultViewModel.model.selectiveResult.quotes);
        quotesArr.push.apply(quotesArr, StoresInstance.domain.QuoteResultViewModel.model.wrightResult.quotes);
        quotesArr.push.apply(quotesArr, StoresInstance.domain.QuoteResultViewModel.model.nFIPResult.quotes);
        quotesArr.push.apply(quotesArr, StoresInstance.domain.QuoteResultViewModel.model.wrightPrivateFloodResult.quotes);
        quotesArr.push.apply(quotesArr, StoresInstance.domain.QuoteResultViewModel.model.hiscoxFloodPlusResult.quotes);
        quotesArr.push.apply(quotesArr, StoresInstance.domain.QuoteResultViewModel.model.zurichResult.quotes);

        quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.aonEdgeResult.quotes);
        quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.argeniaResult.quotes);
        quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.fRSResult.quotes);
        quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.neptuneResult.quotes);
        quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.sterlingResult.quotes);
        quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.selectiveResult.quotes);
        quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.wrightResult.quotes);
        quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.nfipResult.quotes);
        quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.wrightPrivateFloodResult.quotes);
        quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.hiscoxFloodPlusResult.quotes);
        //quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.zurichResult.quotes);

        const model = quotesArr.find(q => q.id === id);

        if (!!model) {
            this.model = model;
        }
    };

    /**
     * Can accept the quote.
     */
    @MobX.computed
    public get canAcceptQuote(): boolean {
        let canAccept = !this.IsSaving && !this.IsLoading;

        console.log("Can accept quotes " + canAccept);

        return canAccept;
    }

    /**
     * Accept the quote.
     */
    public acceptQuote = async (): Promise<void> => {
        try {
            PropertyQuoteStore.instance.model.setFromResultQuoteModel(this.model, PropertyQuoteStore.instance.model.policyEffectiveDate);

            this.setIsSaving(true);

            const apiResult = await this.Post<PropertyQuoteModelDTO>(Server.Api.Property.SetPropertyQuote, PropertyQuoteStore.instance.model.toDto());

            if (apiResult.wasSuccessful) {
                PropertyQuoteStore.instance.model.fromDto(apiResult.payload);

                let quoteResultViewModel = StoresInstance.domain.QuoteResultViewModel;
                if (!quoteResultViewModel.chosenQuotes.includes(this.id)) {
                    //this.sendQuoteDetails();
                    quoteResultViewModel.chosenQuotes.push(this.id);
                }
                this.history.push(`/buypolicy/${this.insurerName}/${this.id}`);
            } else {
                // Error. What to do?
                this.setIsErrored(true);
            }
        } catch (exception) {
            // Error. What to do?
            this.setIsErrored(true);
        } finally {
            this.setIsSaving(false);
        }
    };

    /**
     * NFIP not returned but selected
     */
    public nfipNoQuoteSelected = (): void => {
        let quoteResultViewModel = StoresInstance.domain.QuoteResultViewModel;
        if (!quoteResultViewModel.chosenQuotes.includes(this.id)) {
            this.sendQuoteDetails();
            quoteResultViewModel.chosenQuotes.push(this.id);
        }
        this.history.push(`/buypolicy/${this.insurerName}/${this.id}`);
    };

    // #endregion Server Api

    // #region Viewmodel Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<ResultQuoteModel>): boolean {
        return true;
    }

    // #endregion Viewmodel Boilerplate
}
