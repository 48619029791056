// Libraries
import * as MobX from "mobx";

// Core
import { ViewModelBase } from "../../Core/ViewModels";
import { FieldType } from "../../Core/Utils/Utils";

// Custom
import FileModel, { FileStatus } from "../Models/FileModel";
import { BlankModel } from "../../Core/Models/BlankModel";

export default class FilesViewModel extends ViewModelBase<BlankModel> {
    /**
     * Constructor
     */
    constructor() {
        super(new BlankModel());

        this.setDecorators(BlankModel);
    }

    /**
     * An observable collection of files.
     */
    @MobX.observable
    public Files = MobX.observable<FileModel>([]);

    /**
     * A collection of files that can be displayed by the view.
     */
    @MobX.computed
    public get DisplayableFiles(): FileModel[] {
        return this.Files.filter(file => file.status !== FileStatus.ToBeDeleted);
    }

    // #region Actions

    /**
     * Adds one or more files to the observable collection.
     */
    @MobX.action
    public add = (files: FileList | null): void => {
        if (files !== null) {
            for (var fileIndex = 0; fileIndex < files.length; fileIndex++) {
                const file = files[fileIndex];
                const fileModel = new FileModel();

                fileModel.file = file;
                fileModel.name = file.name;
                fileModel.contentType = file.type;
                fileModel.size = file.size;
                fileModel.status = FileStatus.ToBeAdded;

                this.Files.push(fileModel);
            }
        }
    };

    /**
     * Deletes a file from the observable collection.
     */
    @MobX.action
    public delete = (fileModel: FileModel): void => {
        switch (fileModel.status) {
            case FileStatus.ToBeAdded:
                this.Files.remove(fileModel);
                break;

            case FileStatus.Added:
                fileModel.status = FileStatus.ToBeDeleted;
                break;
        }
    };

    /**
     * Replaces the observable collection.
     */
    @MobX.action
    public replace = (fileModels: FileModel[]) => {
        this.Files.replace(fileModels);
    };

    // #endregion Actions

    // #region Viewmodel Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Viewmodel Boilerplate
}
