import * as MobX from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IObservable } from "mobx";
import { IsNotEmpty, IsString, MinLength, MaxLength, Matches } from "class-validator";
import { isStandardPasswordStrengthEightChars } from "Core/Utils/Validations";

export class ResetPasswordModel extends ModelBase<ResetPasswordModel, ResetPasswordModelDTO> {
    @MobX.observable
    @IsNotEmpty({ message: "You must enter a password" })
    // @IsString()
    // @MinLength(8)
    // @MaxLength(20)
    /* @Matches(isStandardPasswordStrengthEightChars, {
		message: "Password must contain at least 8 characters and include upper & lower case letters and at least one digit.",
	}) */
    public newPassword: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "You must confirm your password" })
    public confirmPassword: string = "";

    @MobX.observable
    public token: string = "";

    @MobX.observable
    public rememberMe: boolean = false;

    @MobX.action
    fromDto(model: ResetPasswordModelDTO): void {
        this.newPassword = model.newPassword;
        this.token = model.token;
        this.rememberMe = model.rememberMe;
    }

    toDto(model: ResetPasswordModel): void {}
}

export interface ResetPasswordModelDTO {
    newPassword: string;
    token: string;
    rememberMe: boolean;
}
