/*global google*/
import React, { useEffect, useRef } from "react";
import { useObserver } from "mobx-react-lite";
import { GoogleMapProvider, MapBox, Marker } from "@googlemap-react/core";
import { StoresInstance } from "../../Stores/Stores";
import { CoverageModel, PropertyQuoteModel } from "../../Models";

// Styling & images
import { makeStyles, styled } from "@material-ui/styles";
import MapMarkerSvg from "../../../Content/icon-mapPin.svg";
import { googlePlaceResultToGeocodeResult } from "Core/Utils/Utils";
import { observe } from "mobx";

const image: HTMLImageElement = new Image(42, 64);
image.src = MapMarkerSvg;
const apiKey: string = "AIzaSyCgnFtd2hi5Frhea2jswhZrH2mWZT3IC2Q";
const DefaultZoom: number = 18;

const useStyles: any = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: "#FFFFFF",
        width: "100%",
        minHeight: "256px",
        "@media (min-width: 768px)": {
            minHeight: "335px",
        },
    },
    map: {
        overflow: "none",
        width: "100%",
        minHeight: "256px",
        "@media (min-width: 768px)": {
            minHeight: "335px",
        },
    },
}));

export const GoogleMap: React.FC = () => {
    const classes: Record<"root" | "map", string> = useStyles("map");
    const zoom: number = DefaultZoom;
    const domainStores = StoresInstance.domain;
    const [lat, setLat] = React.useState<any>(
        domainStores.QuoteRequestViewModel.getValue("latitude") ? (domainStores.QuoteRequestViewModel.getValue("latitude") as number) : 42.317877,
    );
    const [lng, setLng] = React.useState<any>(
        domainStores.QuoteRequestViewModel.getValue("longitude") ? (domainStores.QuoteRequestViewModel.getValue("longitude") as number) : -71.2376827,
    );
    const [latLng, setLatLng] = React.useState<any>(
        new google.maps.LatLng(
            domainStores.QuoteRequestViewModel.getValue("latitude") ? (domainStores.QuoteRequestViewModel.getValue("latitude") as number) : 42.317877,
            domainStores.QuoteRequestViewModel.getValue("longitude") ? (domainStores.QuoteRequestViewModel.getValue("longitude") as number) : -71.2376827,
        ),
    );
    const cChange = useRef(false);

    useEffect(() => {
        const unlisten1 = observe(domainStores.QuoteRequestViewModel.model, "latitude", updateLatLng);
        const unlisten2 = observe(domainStores.QuoteRequestViewModel.model, "longitude", updateLatLng);

        return () => {
            unlisten1();
            unlisten2();
        };
    }, []);

    function updateLatLng() {
        if (cChange.current) {
            setLatLng(
                new google.maps.LatLng(
                    domainStores.QuoteRequestViewModel.getValue("latitude") ? (domainStores.QuoteRequestViewModel.getValue("latitude") as number) : 42.317877,
                    domainStores.QuoteRequestViewModel.getValue("longitude")
                        ? (domainStores.QuoteRequestViewModel.getValue("longitude") as number)
                        : -71.2376827,
                ),
            );
            cChange.current = false;
        } else {
            cChange.current = true;
        }
    }

    var geocoder: google.maps.Geocoder = new google.maps.Geocoder();

    function handleLatChange(lat: any): void {
        setLat(lat as string);
    }

    function handleLngChange(lng: any): void {
        setLng(lng as string);
    }

    function handleOnDragEnd(event: google.maps.MouseEvent): void {
        if (event) {
            var latLng = new google.maps.LatLng(parseFloat(event.latLng.lat().toString()), parseFloat(event.latLng.lng().toString()));
            setLatLng(latLng);
        }
    }

    useEffect(() => {
        geocoder.geocode({ location: latLng }, function(
            // tslint:disable-next-line: typedef
            results,
        ) {
            if (results) {
                const location = results[0];
                if (location) {
                    const lat: any = location.geometry.location.lat();
                    const lng: any = location.geometry.location.lng();

                    domainStores.QuoteRequestViewModel.setValue("latitude", lat);
                    domainStores.QuoteRequestViewModel.setValue("longitude", lng);

                    if (location.geometry && location.formatted_address && location.types) {
                        const result = googlePlaceResultToGeocodeResult(location);
                        domainStores.QuoteRequestViewModel.setValue("buildingCoverage", CoverageModel.MODEL_DEFAULT_BUILDINGCOVERAGE);
                        domainStores.QuoteRequestViewModel.setValue("contentsCoverage", CoverageModel.MODEL_DEFAULT_CONTENTSCOVERAGE);
                        domainStores.QuoteRequestViewModel.setValue("deductible", CoverageModel.MODEL_DEFAULT_DEDUCTIBLEVALUE);
                        domainStores.QuoteRequestViewModel.setValue("address", result.placeName);
                        domainStores.QuoteRequestViewModel.setValue("city", result.city);
                        domainStores.QuoteRequestViewModel.setValue("county", result.county);
                        domainStores.QuoteRequestViewModel.setValue("state", result.region);
                        domainStores.QuoteRequestViewModel.setValue("zipcode", result.zipcode);
                        domainStores.QuoteRequestViewModel.setValue("latitude", result.center[1].toString());
                        domainStores.QuoteRequestViewModel.setValue("longitude", result.center[0].toString());
                        domainStores.QuoteRequestViewModel.model.resetDetails();
                        domainStores.QuoteRequestViewModel.resetGotFields();
                    }

                    handleLatChange(lat);
                    handleLngChange(lng);
                } else {
                    window.alert("No results found");
                }
            }
        });
    }, [latLng]);

    var img: google.maps.Icon = {
        url: MapMarkerSvg,
        scaledSize: new google.maps.Size(58, 72),
    };

    return useObserver(() => (
        <div className={classes.root + " mapRoot"}>
            <GoogleMapProvider key="mainmap">
                <MapBox
                    apiKey={apiKey}
                    className={classes.map + " mobMap"}
                    opts={{
                        zoom: zoom,
                        center: {
                            lat: parseFloat(lat),
                            lng: parseFloat(lng),
                        },
                        fullscreenControl: false,
                        mapTypeControl: false,
                        streetViewControl: false,
                        mapTypeId: google.maps.MapTypeId.SATELLITE,
                        tilt: 0,
                    }}
                    onCenterChanged={() => {
                        handleOnDragEnd;
                    }}
                />
                <Marker
                    id="marker"
                    opts={{
                        draggable: true,
                        position: {
                            lat: parseFloat(lat),
                            lng: parseFloat(lng),
                        },
                        optimized: false,
                        icon: img,
                    }}
                    onDragEnd={handleOnDragEnd}
                />
            </GoogleMapProvider>
        </div>
    ));
};
