// Libraries
import * as MobX from "mobx";

// Core
import { FieldType, isEmptyOrWhitespace } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";

// Custom
import { Server } from "../../Globals/AppUrls";
import { UserAgencyDetailsModel, UserAgencyDetailsModelDTO } from "../../Models/UserAgencyDetailsModel";
import { UserAgencyDetailWithRelatedModelDTO } from "../../Models/UserAgencyDetailWithRelatedModel";
import { StoresInstance } from "../../Stores";
import { moneyFormat } from "../../Utils/Money";
import AgentDashboardViewModel from "./AgentDashboardViewModel";

export default class AgentInsurerStatisticsViewModel extends ViewModelBase<UserAgencyDetailsModel> {
    private dashboardViewModel?: AgentDashboardViewModel;

    // #region Settings

    @MobX.observable
    public canDisplaySettings = false;

    @MobX.observable
    public canDisplayAgreementOverride = false;

    @MobX.action
    public setCanDisplaySettings = (canDisplaySettings: boolean): void => {
        this.canDisplaySettings = canDisplaySettings;
    };

    @MobX.computed
    public get canDisplayForm() {
        return this.model.isNFIPartnerAgency === "option-carrieraccount";
    }

    @MobX.computed
    public get canDisplayAgreement() {
        return (
            (this.model.isNFIPartnerAgency === "option-nfipartnergency" &&
                !StoresInstance.domain.AccountStore.HasAcceptedAgencyAgreement &&
                !!this.defaultModelDTO &&
                !this.defaultModelDTO.isNFIPartnerAgency) ||
            this.canDisplayAgreementOverride
        );
    }

    @MobX.computed
    public get canDisplayApiKeySettings() {
        return this.model.requiresAPIKey;
    }

    @MobX.computed
    public get canDisplayLoginIdAndPasswordSettings() {
        return this.model.requiresLoginNameAndPassword;
    }

    // #endregion Settings

    public setDashboardViewModel(dashboard: AgentDashboardViewModel) {
        this.dashboardViewModel = dashboard;
    }

    // #region Insurer Information

    @MobX.computed
    public get insurerName() {
        return this.model.insurerName;
    }

    @MobX.computed
    public get numberOfQuotesProduced() {
        return moneyFormat(this.model.numberOfQuotesProduced);
    }

    @MobX.computed
    public get numberOfQuotesSelected() {
        return moneyFormat(this.model.numberOfQuotesSelected);
    }

    @MobX.computed
    public get insurerStatusText() {
        let statusText = "No carrier credentials entered and not signed NFI agency agreement";

        switch (true) {
            case !!this.defaultModelDTO &&
                !isEmptyOrWhitespace(this.defaultModelDTO.id) &&
                this.defaultModelDTO.isNFIPartnerAgency === false &&
                !(isEmptyOrWhitespace(this.defaultModelDTO.agencyApiKey) && isEmptyOrWhitespace(this.defaultModelDTO.agencyLogin)):
                statusText = "Quote under my existing carrier agreement";
                break;

            case this.defaultModelDTO && !isEmptyOrWhitespace(this.defaultModelDTO.id) && this.defaultModelDTO.isNFIPartnerAgency === true:
                statusText = "Quote under NFI agency agreement";
                break;
        }

        return statusText;
    }

    @MobX.computed
    public get haveCarrierOrNFIPartner() {
        return (
            this.defaultModelDTO &&
            !isEmptyOrWhitespace(this.defaultModelDTO.id) &&
            !(
                isEmptyOrWhitespace(this.defaultModelDTO.agencyApiKey) &&
                isEmptyOrWhitespace(this.defaultModelDTO.agencyLogin) &&
                !this.defaultModelDTO.isNFIPartnerAgency
            )
        );
    }

    // #endregion Insurer Information

    // #region General Agent Settings

    @MobX.computed
    public get agencyName() {
        return this.model.agencyName;
    }

    @MobX.computed
    public get carrierOption() {
        return this.model.isNFIPartnerAgency;
    }

    @MobX.action
    public setCarrierOption = (option: string) => {
        this.model.isNFIPartnerAgency = option;
    };

    // #endregion General Agent Settings

    // #region Carrier Settings

    @MobX.computed
    public get agencyId() {
        return this.model.agencyLogin;
    }

    @MobX.action
    public setAgencyId = (id: string) => {
        this.model.agencyLogin = id;
    };

    @MobX.computed
    public get agencyPassword() {
        return this.model.agencyPassword;
    }

    @MobX.action
    public setAgencyPassword = (password: string) => {
        this.model.agencyPassword = password;
    };

    @MobX.computed
    public get agencyAPIKey() {
        return this.model.agencyApiKey;
    }

    @MobX.action
    public setAgencyAPIKey = (agencyApiKey: string) => {
        this.model.agencyApiKey = agencyApiKey;
    };

    // #endregion Carrier Settings

    // #region NFIP Agreement Carrier Settings

    @MobX.observable
    public confirmAgreement = false;

    @MobX.action
    public setConfirmAgreement = (confirmAgreement: boolean) => {
        this.confirmAgreement = confirmAgreement;
    };

    @MobX.observable
    public confirmSignature = false;

    @MobX.action
    public setConfirmSignature = (confirmSignature: boolean) => {
        this.confirmSignature = confirmSignature;
    };

    // #endregion NFIP Agreement Carrier Settings

    // #region Actions

    @MobX.computed
    public get canUpdateCarrierSettings() {
        // The credentials confguration settings.
        const requiresAPIKey = !!this.defaultModelDTO && this.defaultModelDTO.requiresAPIKey;
        const requiresLoginNameAndPassword = !!this.defaultModelDTO && this.defaultModelDTO.requiresLoginNameAndPassword;

        // The selection must be the carrier account.
        const carrierAccountSelected = this.model.isNFIPartnerAgency === "option-carrieraccount";
        const changedToCarrierAccount = carrierAccountSelected && !!this.defaultModelDTO && this.defaultModelDTO.isNFIPartnerAgency;

        // Login and password properties must be defined and changed.
        const loginIdPasswordPropertiesDefined = !isEmptyOrWhitespace(this.model.agencyLogin) && !isEmptyOrWhitespace(this.model.agencyPassword);
        const loginChanged = !!this.defaultModelDTO && this.model.agencyLogin !== this.defaultModelDTO.agencyLogin;
        const passwordChanged = !!this.defaultModelDTO && this.model.agencyPassword !== this.defaultModelDTO.agencyPassword;

        // API key must be defined and changed.
        const apiKeyPropertyDefined = !isEmptyOrWhitespace(this.model.agencyApiKey);
        const apiKeyPropertyChanged = !!this.defaultModelDTO && this.model.agencyApiKey !== this.defaultModelDTO.agencyApiKey;

        // The result is determined by the combination of credentials confguration settings.
        let propertiesDefined = false;
        let propertiesChanged = false;

        switch (true) {
            case !requiresAPIKey && requiresLoginNameAndPassword:
                propertiesDefined = loginIdPasswordPropertiesDefined;
                propertiesChanged = loginChanged || passwordChanged;
                break;

            case requiresAPIKey && !requiresLoginNameAndPassword:
                propertiesDefined = apiKeyPropertyDefined;
                propertiesChanged = apiKeyPropertyChanged;
                break;

            case requiresAPIKey && requiresLoginNameAndPassword:
                propertiesDefined = apiKeyPropertyDefined && loginIdPasswordPropertiesDefined;
                propertiesChanged = apiKeyPropertyChanged || loginChanged || passwordChanged;
                break;
        }

        // The logic for this is a little complicated by possible configurations.
        return carrierAccountSelected && propertiesDefined && (changedToCarrierAccount || propertiesChanged);
    }

    @MobX.computed
    public get canUpdateNFIPAccountSettings() {
        // The selection must be the NFIP account.
        const nfipAccountSelected = this.model.isNFIPartnerAgency === "option-nfipartnergency";
        const changedToNFIPAccount = nfipAccountSelected && !!this.defaultModelDTO && !this.defaultModelDTO.isNFIPartnerAgency;
        const isValidSelectionChange = (nfipAccountSelected && changedToNFIPAccount) || this.canDisplayAgreementOverride;

        // The must accept terms and conditions if they haven't already done so.
        const previouslyAcceptedAgreement = StoresInstance.domain.AccountStore.HasAcceptedAgencyAgreement;
        const acceptedAgreement = this.confirmAgreement && this.confirmSignature;

        return isValidSelectionChange && (previouslyAcceptedAgreement || acceptedAgreement);
    }

    @MobX.computed
    public get canUpdateSettings() {
        const agencyDefined = !isEmptyOrWhitespace(this.model.isNFIPartnerAgency);

        return agencyDefined && (this.canUpdateCarrierSettings || this.canUpdateNFIPAccountSettings);
    }

    @MobX.action
    public updateSettings = async (agreementSignature?: Blob): Promise<void> => {
        try {
            this.setIsSaving(true);

            let groupInsurerNames: string[] | undefined;

            if (this.dashboardViewModel) {
                this.dashboardViewModel!.groupedAgencyDetails.forEach(group => {
                    if (group.find(g => g.insurerName === this.insurerName)) {
                        groupInsurerNames = group.reduce((arr: string[], item) => {
                            if (item.insurerName !== this.insurerName) arr.push(item.insurerName);
                            return arr;
                        }, []);
                    }
                });
            }

            const apiResult = await this.Post<UserAgencyDetailWithRelatedModelDTO>(Server.Api.Agent.UpdateUserAgencyDetail, {
                ...this.model.toDto(),
                groupInsurerNames,
            });

            if (apiResult.wasSuccessful) {
                if (this.canDisplayAgreement && agreementSignature !== undefined) {
                    const data = new FormData();
                    data.append("signature", agreementSignature);
                    data.append("agencyId", StoresInstance.domain.AccountStore.AgencyId!);

                    const response = await this.Post(Server.Api.Pro.UploadSignature, data);

                    if (response && response.wasSuccessful) {
                        //StoresInstance.domain.AccountStore.setHasAcceptedAgencyAgreement(true);
                    } else {
                        this.setIsErrored(true);
                        return;
                    }
                }
                this.createModel(apiResult.payload.userAgencyDetail);
                StoresInstance.domain.AccountStore.setHasAcceptedAgencyAgreement(apiResult.payload.hasAcceptedUserAgencyAgreement);

                if (this.dashboardViewModel) {
                    let loaded = false;
                    this.dashboardViewModel!.groupedAgencyDetails.forEach(group => {
                        if (!loaded && group.includes(this)) {
                            loaded = true;
                            this.dashboardViewModel?.load();
                        }
                    });
                }
            } else {
                this.setIsErrored(true);
            }
        } catch (exception) {
            this.setIsErrored(true);
        } finally {
            this.setIsSaving(false);
            this.setCanDisplaySettings(false);
        }
    };

    @MobX.action
    public cancelSettings = () => {
        if (!!this.defaultModelDTO) {
            this.model.fromDto(this.defaultModelDTO);
        }

        this.setConfirmAgreement(false);
        this.setConfirmSignature(false);
        this.setCanDisplaySettings(false);
    };

    // #endregion Actions

    // #region Model

    @MobX.action
    public createModel(modelDTO: UserAgencyDetailsModelDTO): void {
        if (!!modelDTO) {
            this.defaultModelDTO = modelDTO;
            this.model.fromDto(modelDTO);
        }
    }

    @MobX.observable
    private defaultModelDTO?: UserAgencyDetailsModelDTO = undefined;

    // #endregion Model

    /**
     * Constructor
     */
    constructor(onlyAgreement?: boolean) {
        super(new UserAgencyDetailsModel());

        this.setDecorators(UserAgencyDetailsModel);

        console.log(StoresInstance.domain.AgencyDetailViewModel.getValue("name"));

        if (onlyAgreement) {
            MobX.runInAction(() => {
                this.canDisplaySettings = true;
                this.canDisplayAgreementOverride = true;
                this.setValue("isNFIPartnerAgency", "option-nfipartnergency");
                this.setValue("agencyName", StoresInstance.domain.AgencyDetailViewModel.getValue("name"));
            });
        }
    }

    // #region Viewmodel Boilerplate

    public afterUpdate(fieldName: keyof FieldType<UserAgencyDetailsModel>, value: any) {
        /* if (this.dashboardViewModel) {
            this.dashboardViewModel!.groupedAgencyDetails.forEach(group => {
                if (group.includes(this)) {
                    group.forEach(vm => {
                        if (vm !== this) {
                            vm.setValue(fieldName, value);
                        }
                    });
                }
            });
        } */
    }

    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Viewmodel Boilerplate
}
