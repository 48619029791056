// Styling & images
import { DivWrapper, GridSpaced, TitleWrapper, DivWrapperBGFixed } from "../StylesAppSpecific/GridHelperStyling";
import { HdrImgDisc, RoundLink } from "Custom/StylesAppSpecific/PageStyling";

// Libraries
import React, { useState, useEffect, useContext } from "react";
import { CoverageLevels } from "Custom/Components/Coverage";
import { CoverageViewModel, DateEffectiveViewModel } from "Custom/ViewModels";

// Components
import { Footer } from "../Components/Footer";
import { PropertyLoading } from "../Components/IsLoading";
import { Grid, useMediaQuery } from "@material-ui/core";
import PXFuel from "Content/quoteBG.jpg";
import SelectCoverage from "Content/SelectCoverage.svg";
import { StoresInstance, StoresContext, Stores } from "../Stores";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "../../Core/Utils/Hooks/UseReactRouter";
import { EstatedRequestModel, EstatedResponseModelDTO, CoverageModel, DateEffectiveModel } from "Custom/Models";
import { ApiResult } from "Core/Models";
import { ContactDetailStyle } from "Custom/StylesAppSpecific/FormStyling";
import { ContactDetailsContainerTransparent, SelectWidth } from "Custom/Components/PropertyDetails";
import { EditableInput } from "Core/Components/EditableInput";
import calendar from "Content/calendar.svg";
import styled from "styled-components";
import { dateTimeISOString } from "Custom/Utils/Time";

export const CoverageOptions: React.FC = () => {
    const domainStores = StoresInstance.domain;
    const quoteRequestVM = domainStores.QuoteRequestViewModel;

    const { history } = useRouter();
    window.scrollTo(0, 0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [viewModel] = useState(() => new CoverageViewModel());
    const [dateEffectiveViewModel] = useState(() => new DateEffectiveViewModel(quoteRequestVM.getValue("dateEffective")));

    let isMobile: boolean = useMediaQuery("screen and (max-width: 767px)") ? true : false;

    viewModel.setValue("buildingCoverage", quoteRequestVM.getValue("buildingCoverage"));
    viewModel.setValue("contentsCoverage", quoteRequestVM.getValue("contentsCoverage"));

    // Set the deductibleOption.
    // Use the value from quoteRequestVM.deductible to get the key for viewModel.deductibleOption
    // Can't just set viewModel.deductible as it is computed, and viewModel.deductibleOption is the key, not the value.
    for (let i = 0; i < CoverageModel.deductibleOptions.length; i++) {
        let item = CoverageModel.deductibleOptions[i];

        if (item.value == quoteRequestVM.getValue("deductible")) {
            viewModel.setValue("deductibleOption", item.key);
            break;
        }
    }

    const requestAddress: string = quoteRequestVM.getValue("address");
    const requestPopulated: boolean = requestAddress.length > 0 ? true : false;

    if (!requestPopulated) {
        history.push("./");
    }

    const doSubmit = () => {
        // Set the effective date in the request viewmodel.
        quoteRequestVM.setValue(
            "dateEffective",
            dateTimeISOString(dateEffectiveViewModel.model.day, dateEffectiveViewModel.model.month, dateEffectiveViewModel.model.year),
        );

        setIsLoading(true);
        viewModel.saveToQuoteRequest();
        domainStores.PropertyDetailViewModel.resetModel;

        if (requestPopulated && !domainStores.EstatedHasLoaded) {
            let tempStr: string = quoteRequestVM.getValue("address");
            let tempArr: string[] = tempStr.split(",");
            let tempCity: string = quoteRequestVM.getValue("city");
            let propertyRequest: EstatedRequestModel = {
                street: tempArr.length > 0 ? tempArr[0] : tempStr,
                city: tempCity.length > 0 ? tempCity : tempArr[1],
                state: quoteRequestVM.getValue("state"),
                zipcode: quoteRequestVM.getValue("zipcode"),
            };
            domainStores.PropertyDetailViewModel.getProperty(propertyRequest).then((apiResult: ApiResult<EstatedResponseModelDTO>) => {
                if (apiResult.wasSuccessful) {
                    if (domainStores.EstatedHasStructure) {
                        let hasCrawlspace: string = "false";

                        let basementType: string = apiResult.payload.data?.structure?.basement_type;
                        const hasGotBasementType = !!basementType;
                        if (hasGotBasementType) {
                            quoteRequestVM.setValue("hasBasement", basementType.toLowerCase() === "none" ? "false" : "true");
                        }
                        let standardized_land_use_type: string = apiResult.payload.data?.parcel?.standardized_land_use_type;
                        const hasGotLandUseType = !!standardized_land_use_type;
                        if (hasGotLandUseType) {
                            quoteRequestVM.setValue(
                                "isMobileHome",
                                standardized_land_use_type.toLowerCase() === "mobile or manufactured home" ? "true" : "false",
                            );
                            if (standardized_land_use_type.toLowerCase() === "mobile or manufactured home") {
                                quoteRequestVM.setValue("occupationType", "MobileHome");
                            }
                            quoteRequestVM.addGotField("isMobileHome");
                        }
                        const gotYear = apiResult.payload.data?.structure?.year_built;
                        const hasGotYear = !!gotYear;
                        if (hasGotYear) {
                            quoteRequestVM.setValue("yearBuilt", gotYear);
                            quoteRequestVM.addGotField("yearBuilt");
                        }
                        const gotStories = apiResult.payload.data?.structure?.stories;
                        const hasGotStories = !!gotStories;
                        if (hasGotStories) {
                            quoteRequestVM.setValue("numberOfFloors", parseInt(gotStories));
                            quoteRequestVM.addGotField("numberOfFloors");
                        }
                        const gotSqft = apiResult.payload.data?.structure?.total_area_sq_ft;
                        const hasGotSqft = !!gotSqft;
                        if (hasGotSqft) {
                            quoteRequestVM.setValue("buildingSqft", gotSqft);
                            quoteRequestVM.addGotField("buildingSqft");
                        }
                        const gotGarage = apiResult.payload.data?.structure?.parking_type;
                        const hasGotGarage = !!gotGarage;
                        if (hasGotGarage) {
                            quoteRequestVM.setValue("hasGarage", gotGarage.toLowerCase() == "garage" ? "true" : "false");
                            quoteRequestVM.addGotField("hasGarage");
                        }
                        const foundationType: string = apiResult.payload.data?.structure?.foundation_type;
                        let newFoundType: string = "";
                        if (foundationType) {
                            switch (foundationType.toLowerCase()) {
                                case "raised": {
                                    if (hasGotBasementType) {
                                        if (quoteRequestVM.getValue("hasBasement") === "true") {
                                            newFoundType = "basement";
                                        }
                                        if (quoteRequestVM.getValue("hasBasement") === "false") {
                                            newFoundType = "crawlspace";
                                            hasCrawlspace = "true";
                                        }
                                    }
                                    break;
                                }
                                case "slab": {
                                    newFoundType = "slab";
                                    break;
                                }
                                case "piers": {
                                    newFoundType = "piers";
                                    break;
                                }
                                default: {
                                    break;
                                }
                            }
                        }
                        const hasGotFoundationType = !!newFoundType;
                        if (hasGotFoundationType) {
                            quoteRequestVM.setValue("foundationType", newFoundType);
                            quoteRequestVM.addGotField("foundationType");
                            quoteRequestVM.setValue("hasBasement", newFoundType === "basement" ? "true" : "false");
                            quoteRequestVM.addGotField("hasBasement");
                        }
                        const constructionType: string = apiResult.payload.data?.structure?.construction_type;
                        let newConstructionType: string = "";
                        if (constructionType) {
                            switch (constructionType.toLowerCase()) {
                                case "masonry": {
                                    newConstructionType = "masonry";
                                    break;
                                }
                                case "brick": {
                                    newConstructionType = "masonry";
                                    break;
                                }
                                case "frame": {
                                    newConstructionType = "frame";
                                    break;
                                }
                                case "wood": {
                                    newConstructionType = "frame";
                                    break;
                                }
                                case "concrete": {
                                    newConstructionType = "masonry";
                                    break;
                                }
                                case "log": {
                                    newConstructionType = "log";
                                    break;
                                }
                                case "steel": {
                                    newConstructionType = "steel";
                                    break;
                                }
                                case null:
                                case "": {
                                    break;
                                }
                                default: {
                                    newConstructionType = "other";
                                    break;
                                }
                            }
                        }
                        const hasGotConstructionType = !!newConstructionType;
                        if (hasGotConstructionType) {
                            quoteRequestVM.setValue("constructionType", newConstructionType);
                            quoteRequestVM.addGotField("constructionType");
                        }
                        /* const gotOwnershipType = apiResult.payload.data?.owner?.owner_occupied.toLowerCase() == "yes" ? "ownAndOccupy" : "";
						const hasGotOwnershipType = !!gotOwnershipType;
						if (hasGotOwnershipType) {
							quoteRequestVM.setValue("ownershipType", gotOwnershipType);
							quoteRequestVM.addGotField("ownershipType");
						} */

                        quoteRequestVM.setValue("isOverWater", "false");
                        quoteRequestVM.addGotField("isOverWater");

                        quoteRequestVM.setValue("buildingType", "residential");
                        quoteRequestVM.setValue("hasCrawlSpace", hasCrawlspace);
                    } else {
                        /* let dateVal = new Date();
						let yearVal = dateVal.getUTCFullYear();
						quoteRequestVM.setValue("yearBuilt", yearVal.toString());
						quoteRequestVM.setValue("buildingSqft", 1000);
						quoteRequestVM.setValue("constructionType", "other");
						quoteRequestVM.setValue("foundationType", "other"); */
                        quoteRequestVM.setValue("buildingType", "residential");
                        /* quoteRequestVM.setValue("hasBasement", "false"); */
                        quoteRequestVM.setValue("hasCrawlSpace", "false");
                        /* quoteRequestVM.setValue("hasGarage", "false");
						quoteRequestVM.setValue("isMobileHome", "false");
						quoteRequestVM.setValue("isOverWater", "false");
						quoteRequestVM.setValue("isNewProperty", "false");
						quoteRequestVM.setValue("propertyOccupy", 100);
						quoteRequestVM.setValue("ownershipType", "ownAndOccupy");
						quoteRequestVM.setValue("insurancePolicy", "no");
						quoteRequestVM.setValue("numberOfFloors", 1); */
                    }
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    console.log(apiResult.errors);
                }
                history.push("/coveragedetails");
            });
        } else {
            history.push("/coveragedetails");
        }
    };

    const contentHdr = (
        <>
            <GridSpaced container margin={"0px auto"} additionalcss={"max-width: 688px; width: 100%;"}>
                <GridSpaced item xs={12} sm={12} additionalcss={"text-align: center;"}>
                    <HdrImgDisc src={SelectCoverage} alt={""} />
                </GridSpaced>
            </GridSpaced>

            <GridSpaced container margin={"0px auto"} additionalcss={"max-width: 740px; width: 100%;"}>
                <Grid item xs={12} sm={12} justify={"center"}>
                    <TitleWrapper padding={"0"} margin={"0"}>
                        <h1>
                            <span>Step 2:</span> Select your coverage:
                        </h1>
                    </TitleWrapper>
                </Grid>
            </GridSpaced>
        </>
    );

    return (
        <>
            <DivWrapperBGFixed additionalcss={`background-image: url(${PXFuel}); ${isMobile ? "padding-bottom: 20px;" : "padding-bottom: 100px;"}`}>
                {requestPopulated && !isLoading && (
                    <>
                        {contentHdr}
                        <DatePickerContainer>
                            <ContactDetailStyle>
                                <h2>When do you want the policy coverage to start from?</h2>
                                <div className="date-selects">
                                    <div>
                                        <img alt="" src={calendar} />
                                    </div>
                                    <ContactDetailsContainerTransparent>
                                        <SelectWidth minWidth={isMobile ? 101 : 198}>
                                            <EditableInput<DateEffectiveModel>
                                                viewModel={dateEffectiveViewModel}
                                                fieldName="month"
                                                type="select"
                                                selectItems={[
                                                    { key: "January", value: 0 },
                                                    { key: "Febuary", value: 1 },
                                                    { key: "March", value: 2 },
                                                    { key: "April", value: 3 },
                                                    { key: "May", value: 4 },
                                                    { key: "June", value: 5 },
                                                    { key: "July", value: 6 },
                                                    { key: "August", value: 7 },
                                                    { key: "September", value: 8 },
                                                    { key: "October", value: 9 },
                                                    { key: "November", value: 10 },
                                                    { key: "December", value: 11 },
                                                ]}
                                            />
                                        </SelectWidth>
                                    </ContactDetailsContainerTransparent>
                                    <ContactDetailsContainerTransparent>
                                        <SelectWidth minWidth={isMobile ? 48 : 89}>
                                            <EditableInput<DateEffectiveModel>
                                                viewModel={dateEffectiveViewModel}
                                                fieldName="day"
                                                type="select"
                                                selectItems={dateEffectiveViewModel.selectDays}
                                            />
                                        </SelectWidth>
                                    </ContactDetailsContainerTransparent>
                                    <ContactDetailsContainerTransparent>
                                        <SelectWidth minWidth={isMobile ? 64 : 120}>
                                            <EditableInput<DateEffectiveModel>
                                                viewModel={dateEffectiveViewModel}
                                                fieldName="year"
                                                type="select"
                                                selectItems={dateEffectiveViewModel.selectYears}
                                            />
                                        </SelectWidth>
                                    </ContactDetailsContainerTransparent>
                                </div>
                            </ContactDetailStyle>
                        </DatePickerContainer>
                        <CoverageLevels viewModel={viewModel} />

                        <RoundLink onClick={() => doSubmit()} title="Next Step">
                            Next
                        </RoundLink>
                    </>
                )}
                {requestPopulated && isLoading && (
                    <>
                        <PropertyLoading />
                    </>
                )}
                {!requestPopulated && <>ToDo</>}
            </DivWrapperBGFixed>
            <Footer isTemp={false} />
        </>
    );
};

const DatePickerContainer = styled.div`
    max-width: 1140px;
    margin: auto;

    .date-selects {
        width: auto !important;
        background: none !important;
        border: none !important;
        padding: 0 !important;

        > div:first-of-type {
            margin: auto 60px auto 0;

            @media (max-width: 767px) {
                margin-right: 5px;

                img {
                    width: 32px;
                }
            }
        }
    }

    .MuiBox-root {
        padding: 0;
    }

    .MuiSelect-select.MuiSelect-select {
        padding: 14px 37px 13px 20px;

        @media (max-width: 767px) {
            padding: 8px 25px 8px 7px;
        }
    }

    svg.MuiSelect-icon {
        margin: 0 20px 0 8px;
        top: auto;

        @media (max-width: 767px) {
            margin: 0 10px;
        }
    }

    .MuiInput-underline:before,
    .MuiInput-underline:after {
        content: none !important;
    }

    .MuiInputBase-root {
        margin: 0;
    }

    .MuiInputBase-input {
        font-weight: bold;
    }

    > div {
        background-color: #3a4650;

        @media (max-width: 767px) {
            border-radius: 0;
        }
    }
`;
