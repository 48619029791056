import * as GoogleAnalytics from "Core/Utils/App/GoogleAnalyticsUtils";
import { AccountStore } from "./Domain/AccountStores";
import { UserStore } from "./Domain/Admin/UserStore";
import { RoleStore } from "./Domain/Admin";
import { Stores } from "Custom/Stores/index";
import { QuoteRequestViewModel } from "../ViewModels/QuoteRequestViewModel";
import { QuoteResultViewModel } from "../ViewModels/QuoteResultViewModel";
import { ContactViewModel } from "../ViewModels/ContactViewModel";
import { PropertyQuoteViewModel } from "../ViewModels/PropertyQuoteViewModel";
import { MapStore } from "./Domain/MapStore";
import { CitiesViewModel } from "../ViewModels/Admin/CitiesViewModel";
import { StatesViewModel } from "../ViewModels/Admin/StatesViewModel";
import { PropertyDetailViewModel } from "../ViewModels/PropertyDetailViewModel";
import { DashboardViewModel } from "../ViewModels/DashboardViewModel";
import { AgencyDetailViewModel } from "Custom/ViewModels/Agent/AgencyDetailViewModel";
import { WidgetViewModel } from "../ViewModels/Admin/WidgetViewModel";
import { RealtorDetailViewModel } from "../ViewModels/Realtor/RealtorDetailViewModel";
import { FloodDataViewModel } from "../ViewModels/FloodDataViewModel";
import { defaultTheme } from "../StylesAppSpecific/AppStyling";
import { throttle } from "lodash-es";
import { EventArgs } from "react-ga";

// App
import * as Models from "../Models";
import { FormLockConcurrencyHubStore } from "./Domain/FormLockConcurrencyHubStore";
import { observable, action, computed, runInAction } from "mobx";
import * as History from "history";
import { CarrierSettingsViewModel } from "Custom/ViewModels/CarrierSettingsViewModel";
//import { EstatedRequestModel } from "../Models";

export class DomainStores {
    @observable public screenWidth = 0;
    @observable public isMobile = false;
    @observable public isTablet = false;
    @observable public isDesktop = false;
    @observable public carryOnRegardless = false;
    @observable public openSnackBar = false;
    @observable public openErrorDialog = false;
    @observable public errorDialogContent: string = "";
    @observable public snackBarContent: string = "";
    @observable public errorString: string = "";
    @observable public wrapper = false;
    public AccountStore: AccountStore = new AccountStore();
    public UserStore = new UserStore();
    public RoleStore = new RoleStore();
    public FormLockConcurrencyHubStore = new FormLockConcurrencyHubStore();
    public History: History.History = {} as History.History;
    public version: string = "0.0";

    public QuoteRequestViewModel: QuoteRequestViewModel = new QuoteRequestViewModel();
    public QuoteResultViewModel: QuoteResultViewModel = new QuoteResultViewModel();
    public CitiesViewModel: CitiesViewModel = new CitiesViewModel();
    public StatesViewModel: StatesViewModel = new StatesViewModel();
    public ContactViewModel: ContactViewModel = new ContactViewModel();
    public WidgetViewModel: WidgetViewModel = new WidgetViewModel();
    public MapStore: MapStore = new MapStore();
    public PropertyDetailViewModel: PropertyDetailViewModel = new PropertyDetailViewModel();
    public PropertyQuoteViewModel: PropertyQuoteViewModel = new PropertyQuoteViewModel();
    public DashboardViewModel: DashboardViewModel = new DashboardViewModel();
    public CarrierSettingsViewModel?: CarrierSettingsViewModel;
    public AgencyDetailViewModel: AgencyDetailViewModel = new AgencyDetailViewModel();
    public RealtorDetailViewModel: RealtorDetailViewModel = new RealtorDetailViewModel();
    public FloodDataViewModel: FloodDataViewModel = new FloodDataViewModel();

    public EstatedHasLoaded: boolean = false;
    @observable public EstatedHasStructure: boolean = false;
    public StructureUpdated: boolean = false;
    @observable public PermaLink: string = "";
    @observable public CityStatesId: string = "";
    @observable public CityIsHidden: boolean = false;
    @observable public PremVal: string = "";
    @observable public warningBanner: string | null = null;

    public init(stores: Stores, initialState: Models.InitialState) {
        GoogleAnalytics.Init("UA-1852243-72");
        //GoogleAnalytics.pageview("/");
        window.addEventListener(
            "resize",
            throttle(() => {
                this.setWidth();
            }, 500),
        );
        this.setWidth();
        this.AccountStore.init(stores, initialState);
        this.FormLockConcurrencyHubStore.init(stores, initialState);
        this.MapStore.init(stores);
        this.version = initialState.version;
        this.warningBanner = initialState.warningBanner;
    }

    private setWidth = () => {
        runInAction(() => {
            this.screenWidth = document.body.clientWidth;
            this.isMobile = this.screenWidth <= defaultTheme.breakpoints.values["md"];
            this.isTablet = this.screenWidth > defaultTheme.breakpoints.values["md"] && this.screenWidth <= defaultTheme.breakpoints.values["lg"];
            this.isDesktop = this.screenWidth > defaultTheme.breakpoints.values["lg"];
            document.body.classList.remove("mobile");
            document.body.classList.remove("tablet");
            document.body.classList.remove("desktop");
            if (this.isMobile) {
                document.body.classList.add("mobile");
            }
            if (this.isTablet) {
                document.body.classList.add("tablet");
            }
            if (this.isDesktop) {
                document.body.classList.add("desktop");
            }
        });
        //console.log(`Screen Mobile=${this.isMobile}, Tablet=${this.isTablet},Desktop=${this.isDesktop}`);
    };

    @action
    public setWrapper = (val: boolean) => {
        this.wrapper = val;
    };

    @action
    public setErrorString = (error: string) => {
        this.errorString = error;
    };

    public PageView = (page: string) => {
        GoogleAnalytics.pageview(page);
    };

    public SendEvent = (args: EventArgs) => {
        GoogleAnalytics.sendEvent(args);
    };

    @action
    public ShowSnackBar(content: string) {
        this.snackBarContent = content;
        this.openSnackBar = true;
    }

    @action
    public CloseSnackBar() {
        this.openSnackBar = false;
    }

    @action
    public ShowErrorDialog(content: string) {
        this.errorDialogContent = content;
        this.openErrorDialog = true;
    }
}
