// Libraries
import clsx from "clsx";
import { React, useRouter, useObserver, useState, useEffect } from "../../Globals/Base";
import { FormEvent } from "react";
import { Button, Checkbox, FormControlLabel, Grid, Paper, Typography } from "@material-ui/core";

// Core
import { EditableInput } from "../../../Core/Components/EditableInput";

// Content
import Puddles from "Content/homeBG2.png";

// Custom
import { HtmlEditor } from "../../AdminComponents";
import { FileSelectionButton } from "../../Components/FileUpload/FileSelectionButton";
import { Footer } from "../../Components/Footer";
import { DivWrapper, DivWrapperBGFixed } from "../../StylesAppSpecific/GridHelperStyling";
import EditCityViewModel from "../../ViewModels/Admin/EditCityViewModel";
import { useEditStyles } from "./EditStyling";

export const CityEdit: React.FC = () => {
    const classes = useEditStyles();
    const { history, location, match } = useRouter();
    const { id } = match.params as any;
    const [viewModel] = useState(() => new EditCityViewModel(id));

    // If the viewmodel is to allow navigation. set the viewmodel router.
    useEffect(() => {
        viewModel.setRouter(history, location);
    }, []);

    const doSubmit = async (event: FormEvent<HTMLFormElement>) => {
        // handle permalink
        viewModel.setPermLink();

        event.preventDefault();
        viewModel.save();
    };

    const onIsPublishedChanged = () => {
        viewModel.setIsPublished(!viewModel.isPublished);
    };

    return useObserver(() => (
        <form onSubmit={doSubmit}>
            <DivWrapperBGFixed additionalcss={"background-image: url(" + Puddles + ");"}>
                <DivWrapper className={classes.paperWrapper}>
                    <Paper className={classes.paper}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <EditableInput type="text" label="Title" validateOnBlur={true} viewModel={viewModel} fieldName="title" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <EditableInput type="text" label="Link" validateOnBlur={true} viewModel={viewModel} fieldName="permaLink" />
                            </Grid>
                            <Grid item xs={12}>
                                <EditableInput
                                    type="text"
                                    label="Metadata description"
                                    validateOnBlur={true}
                                    viewModel={viewModel}
                                    fieldName="metaDescription"
                                />
                            </Grid>
                            <Grid className={classes.isPublishedCheckboxWrapper} item xs={12} sm={3}>
                                <FormControlLabel
                                    label="Is published"
                                    control={<Checkbox checked={viewModel.isPublished} color="primary" onChange={onIsPublishedChanged} />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <FileSelectionButton
                                    allowMultipleFiles={false}
                                    fileTypes=".jpg, .jpeg, .png"
                                    onChange={viewModel.uploadImage}
                                    control={
                                        <Button color="primary" type="button" variant="contained">
                                            Background image
                                        </Button>
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography>{viewModel.image}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <HtmlEditor htmlContent={viewModel.model.pageBody} setHtmlContent={viewModel.setPageBody} />
                            </Grid>
                            <Grid className={clsx(classes.buttonWrapper, classes.submitButtonWrapper)} item xs={12} sm={6}>
                                <Button className={classes.button} color="primary" type="submit" variant="contained">
                                    Save
                                </Button>
                            </Grid>
                            <Grid className={clsx(classes.buttonWrapper, classes.cancelButtonWrapper)} item xs={12} sm={6}>
                                <Button className={classes.button} color="secondary" onClick={viewModel.cancel} type="button" variant="contained">
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </DivWrapper>
            </DivWrapperBGFixed>
            <Footer isTemp={false} />
        </form>
    ));
};
