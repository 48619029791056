// Libraries
import { observable, autorun, computed, runInAction, action } from "mobx";
import * as MobX from "mobx";

// Core
import { FieldType } from "../../Core/Utils/Utils";
import { ViewModelBase } from "../../Core/ViewModels/ViewModelBase";

// Custom
import { Server } from "../Globals/AppUrls";
import { PreviousPropertyQuoteModelDTO, PreviousPropertyQuoteModel, DashboardModelDTO, QuoteRequestModelV2, GenericId } from "../Models";
import { StoresInstance } from "../Stores";
import { QuoteRequestViewModel } from ".";
import moment from "moment";
import { isNullOrUndefined } from "util";

export class DashboardViewModel extends ViewModelBase<PreviousPropertyQuoteModel> {
    @MobX.observable
    public PropertyQuotes = MobX.observable<PreviousPropertyQuoteModelDTO>([]);

    @MobX.observable
    public FirstName = "";

    constructor() {
        super(new PreviousPropertyQuoteModel());
        this.setDecorators(PreviousPropertyQuoteModel);
    }

    public load = async (): Promise<void> => {
        try {
            this.setIsLoading(true);

            let apiResult = await this.Post<DashboardModelDTO>(Server.Api.Dashboard.GetLastUserPropertyQuotes);

            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.PropertyQuotes.replace(apiResult.payload.previousPropertyQuotes);
                    this.FirstName = apiResult.payload.firstName;
                });
            } else {
                this.setIsErrored(true);
            }
        } catch (exception) {
            this.setIsErrored(true);
        } finally {
            this.setIsLoading(false);
        }
    };

    public loadPropertyQuote = async (id: string): Promise<void> => {
        try {
            this.setIsLoading(true);

            let apiResult = await this.Post<QuoteRequestModelV2>(Server.Api.Dashboard.GetUserPropertyQuoteById, { id });

            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.setQuoteRequestViewModel(apiResult.payload);
                });
            } else {
                this.setIsErrored(true);
            }
        } catch (exception) {
            this.setIsErrored(true);
        } finally {
            this.setIsLoading(false);
        }
    };

    public deletePropertyQuote = async (propertyQuoteId: string): Promise<void> => {
        //let filteredQuote = this.PropertyQuotes.find(q => q.propertyQuoteId === propertyQuoteId);
        //let quoteToDelete: PropertyQuoteModel = new PropertyQuoteModel();
        //quoteToDelete.id = propertyQuoteId;
        let requestId: GenericId = {
            id: propertyQuoteId,
        };
        let apiResult = await this.Post(Server.Api.Dashboard.DeletePropertyQuote, requestId);
        if (apiResult.wasSuccessful) {
            this.load();
        }
    };

    private setQuoteRequestViewModel = (payload: any) => {
        let domainStores = StoresInstance.domain;

        const { model: quoteRequestModelV2 } = domainStores.QuoteRequestViewModel;

        domainStores.QuoteRequestViewModel.setModel(payload);
        quoteRequestModelV2.setValue("hasBasement", this.getStringValue(payload.hasBasement));
        quoteRequestModelV2.setValue("hasCrawlSpace", this.getStringValue(payload.hasCrawlSpace));
        quoteRequestModelV2.setValue("hasGarage", this.getStringValue(payload.hasGarage));
        quoteRequestModelV2.setValue("isOverWater", this.getStringValue(payload.isOverWater));
        quoteRequestModelV2.setValue("isMobileHome", this.getStringValue(payload.isMobileHome));
        quoteRequestModelV2.setValue("isNewProperty", this.getStringValue(payload.isNewProperty));
        quoteRequestModelV2.setValue("insurancePolicyType", payload.insurancePolicy);
        quoteRequestModelV2.setValue("insurancePolicy", payload.insurancePolicy);
        quoteRequestModelV2.setValue("enclosureTypesArr", observable<string>(payload.enclosureTypes.split(",")));
        quoteRequestModelV2.setValue("dateEffective", payload.dateEffective);

        domainStores.StructureUpdated = true;
        domainStores.EstatedHasStructure = false;
    };

    private getStringValue = (boolValue: any): "true" | "false" | undefined => {
        if (boolValue == true) {
            return "true";
        } else if (boolValue == false) {
            return "false";
        }
        return undefined;
    };

    public getPropertyQuotes = () => {
        return this.PropertyQuotes.slice();
    };

    // #region Utils
    public dateStrAsDate(quoteDate: string) {
        let retVal = Date.parse(quoteDate);
        return retVal;
    }
    //#endregion Utils

    public isFieldValid(fieldName: keyof FieldType<PreviousPropertyQuoteModel>, value: any, showErrors: boolean = true): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        if (showErrors) {
            this.setError(fieldName, errorMessage);
            this.setValid(fieldName, isValid);
        }

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
