import { makeStyles } from "@material-ui/core";
import { styled } from "../../Globals/Base";

export const Container = styled.div``;

export const useQuoteStyles = makeStyles(theme => ({
    [theme.breakpoints.down("xs")]: {
        h1: {
            fontSize: "18px",
        },
        h2: {},
        h3: {
            fontSize: "14px",
        },
    },
    gridContainerRowSpacing: {
        marginBottom: "9px",
        [theme.breakpoints.down("xs")]: {
            marginBottom: "0",
        },
    },
    title: {
        paddingBottom: "30px",
        [theme.breakpoints.down("xs")]: {
            paddingBottom: "15px",
        },
    },
    label: {
        fontSize: "14px",
        fontWeight: "bold",
        [theme.breakpoints.down("xs")]: {
            fontSize: "10px",
        },
    },
    text: {
        fontSize: "14px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "10px",
        },
    },
    labelTextLineBreak: {
        display: "block",
        margin: "10.5px 0",
    },
    headerLabelTextGridItem: {
        display: "flex",
        "& p:first-child": {
            width: "95px",
        },
        [theme.breakpoints.down("xs")]: {
            "& p:first-child": {
                width: "75px",
            },
        },
    },
    policyHolderLabelTextGridItem: {
        display: "flex",
        "& p:first-child": {
            width: "60px",
        },
        [theme.breakpoints.down("xs")]: {
            "& p:first-child": {
                width: "50px",
            },
        },
    },
    propertyInsuredLabelTextGridItem: {
        display: "flex",
        "& p:first-child": {
            width: "160px",
        },
        [theme.breakpoints.down("xs")]: {
            "& p:first-child": {
                width: "125px",
            },
        },
    },
    labelGridItem: {
        width: "50%",
        [theme.breakpoints.down("xs")]: {
            width: "60%",
        },
    },
    paragraph: {
        fontSize: "14px",
        fontWeight: "bold",
        [theme.breakpoints.down("xs")]: {
            fontSize: "10px",
        },
    },
    paragraphGridItem: {
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: "35px",
        width: "20%",
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "15px",
            width: "40%",
        },
    },
    deductibleColor: {
        color: "#129BDB",
    },
    background: {
        paddingBottom: "124px !important",
        [theme.breakpoints.down("xs")]: {
            paddingBottom: "65px !important",
        },
    },
    paper: {
        borderColor: "#129BDB",
        borderRadius: "15px",
        borderStyle: "solid",
        borderWidth: "5px",
    },
    body: {
        backgroundColor: "#E7F6FE",
        borderBottomLeftRadius: "15px",
        borderBottomRightRadius: "15px",
    },
    gridItem: {
        padding: "35px",
        [theme.breakpoints.down("xs")]: {
            padding: "20px 15px",
        },
    },
    gridItemNoTopPadding: {
        padding: "0 35px 35px",
        [theme.breakpoints.down("xs")]: {
            padding: "0 15px 20px",
        },
    },
    imageGridItem: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "35px",
        [theme.breakpoints.down("xs")]: {
            padding: "20px 15px",
        },
    },
    gridItemFlex: {
        flex: "1",
    },
    totalGridItem: {
        padding: "15px 35px",
        [theme.breakpoints.down("xs")]: {
            padding: "20px 15px",
        },
    },
    dottedDivider: {
        borderBottomColor: "#129BDB",
        borderBottomStyle: "dashed",
        borderBottomWidth: "1px",
        display: "flex",
        flex: "1",
        height: "1px",
        margin: "0 35px",
        [theme.breakpoints.down("xs")]: {
            margin: "0 15px",
        },
    },
    solidDivider: {
        borderBottomColor: "#129BDB",
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        display: "flex",
        flex: "1",
        height: "1px",
        margin: "0 35px",
        [theme.breakpoints.down("xs")]: {
            margin: "0 15px",
        },
    },
    navigationButton: {
        backgroundColor: "#129BDB",
        borderRadius: "27px",
        color: "#FFFFFF",
        fontSize: "18px",
        fontWeight: "bold",
        height: "54px",
        marginBottom: "40px",
        marginTop: "40px",
        padding: "0 30px",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#0F89C2",
        },
        [theme.breakpoints.down("xs")]: {
            borderRadius: "20px",
            height: "40px",
            marginBottom: "20px",
            marginTop: "20px",
            width: "100%",
        },
    },
    buyButtonGridItem: {
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "-40px",
        [theme.breakpoints.down("xs")]: {
            marginTop: "-30px",
        },
    },
    buyButton: {
        backgroundColor: "#129BDB",
        borderRadius: "50%",
        bottom: "-84px",
        color: "#FFFFFF",
        height: "168px",
        fontSize: "24px",
        fontWeight: "bold",
        lineHeight: "30px",
        margin: "0",
        padding: "0",
        textTransform: "none",
        width: "168px",
        "&:hover": {
            backgroundColor: "#0F89C2",
        },
        "&.Mui-disabled": {
            backgroundColor: "#93A0AB",
            opacity: "1",
        },
        [theme.breakpoints.down("xs")]: {
            bottom: "-50px",
            fontSize: "18px",
            height: "100px",
            lineHeight: "20px",
            width: "100px",
        },
    },
    policyHolderContent: {
        marginRight: "20px",
    },
}));
