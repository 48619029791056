import * as MobX from "mobx";
import { ModelBase } from "../../../Core/Models/ModelBase";

export class TaxesModel extends ModelBase<TaxesModel, TaxesModelDTO> {
    @MobX.observable
    public year: number = 0;

    @MobX.observable
    public amount: number = 0;

    @MobX.observable
    public excemptions: string[] = [];

    @MobX.observable
    public rate_code_area: string = "";

    public fromDto = (model: TaxesModelDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                this[key] = model[key];
            }
        }
    };
    public toDto = (model: TaxesModel) => {};

    public resetTaxes = () => {
        this.year = 0;
        this.amount = 0;
        this.excemptions = [];
        this.rate_code_area = "";
    };
}

export interface TaxesModelDTO {
    year: number;
    amount: number;
    excemptions: string[];
    rate_code_area: string;
}
