// Libraries
import { React, styled, useRouter, useObserver, useState, useEffect, useContext } from "../Globals/Base";
import ReactHtmlParser from "react-html-parser";
import { useMediaQuery } from "Core/Utils/Media";

// App
import { MapStore } from "../Stores/Domain/MapStore";
import { Stores, StoresContext } from "../Stores";

// Components
import { MetaHeader } from "../Components/SEOComponents";
import { SEOHeader } from "../Models";
import { AddressSelectorGoogle } from "../Components/AddressSelector";
import { QandAWHY, QandAFAQ } from "../Components/QandA";
import { HomeUSP } from "Custom/Components/USPGroups/HomeUSP";

// Custom
import { Footer } from "../Components/Footer";
import { Grid, Box } from "@material-ui/core";
import { DivWrapper, GridSpaced, DialogueWrapper, DivWrapperNoVH, DivWrapperBGFixed } from "../StylesAppSpecific/GridHelperStyling";
import { SEOStateContainer } from "../StylesAppSpecific/SEOStateStyling";
import { PreambleText, HdrImg } from "../StylesAppSpecific/PageStyling";
import StateViewModel from "../ViewModels/StateViewModel";
import Puddles from "../../Content/homeBG2.png";
import FamilyPicture from "Content/homeBG.jpg";
import { HomeVerticalCenter } from "Custom/StylesAppSpecific/HomeStyling";

export const State: React.FC = () => {
    const { history, location, match } = useRouter();
    let { id } = match.params as any;
    const [viewModel] = useState(() => new StateViewModel(id));
    const mapStoresContext: MapStore = useContext<Stores>(StoresContext).domain.MapStore;
    let isMobile: boolean;
    isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;

    // If the viewmodel is to allow navigation. set the viewmodel router.
    useEffect(() => {
        viewModel.setRouter(history, location);
    }, []);

    const contentHdr = (
        <>
            {!isMobile && (
                <>
                    <GridSpaced container justify={"center"} margin={"0px auto"} additionalcss={"max-width: 1140px; width: 100%;"}>
                        <GridSpaced item xs={12} sm={12} additionalcss={"max-width: 990px;"}>
                            <PreambleText padding={"0"} margin={"28px 0 20px"} fontsize={"50px"}>
                                Get a {viewModel.model.title} flood insurance policy in 3 simple steps
                            </PreambleText>
                        </GridSpaced>
                    </GridSpaced>
                </>
            )}

            {isMobile && (
                <>
                    <GridSpaced container margin={"0px auto"} justify={"center"} additionalcss={"max-width: 740px; width: 100%;"}>
                        <Grid item xs={12} sm={12}>
                            <PreambleText padding={"0"} margin={"30px auto"} fontsize={"24px"}>
                                Get a {viewModel.model.title} flood insurance policy in 3 simple steps
                            </PreambleText>
                        </Grid>
                    </GridSpaced>
                </>
            )}
        </>
    );

    const formPlacement = (
        <>
            <GridSpaced container margin={"0px auto"} additionalcss={"max-width: 688px; width: 100%;"}>
                {isMobile && (
                    <>
                        <GridSpaced item margin={"0px auto"}>
                            <DialogueWrapper additionalcss={"border-radius: 20px;"}>
                                <AddressSelectorGoogle />
                            </DialogueWrapper>
                        </GridSpaced>
                    </>
                )}

                {!isMobile && (
                    <>
                        <GridSpaced item margin={"0px auto 70px"}>
                            <DialogueWrapper>
                                <AddressSelectorGoogle />
                            </DialogueWrapper>
                        </GridSpaced>
                    </>
                )}
            </GridSpaced>
        </>
    );

    let metaData: SEOHeader = {
        title: viewModel.model.title.length > 0 ? viewModel.model.title : "My Flood Insurance - a revolutionary new way of buying flood insurance",
        description:
            viewModel.model.metaDescription.length > 0
                ? viewModel.model.metaDescription
                : "Get an instant Flood Insurance quote for your property. We offer quotes with several, top-rated private flood carriers so you can find the best coverage.",
    };

    return useObserver(() => (
        <>
            <MetaHeader Model={metaData} />
            <DivWrapper
                additionalcss={
                    "background: linear-gradient(to top, rgb(20, 32, 41) 10%, rgb(0, 0, 0, 0.0)), url(" +
                    (viewModel.model.image && viewModel.model.image != "" ? viewModel.model.image : FamilyPicture) +
                    ") center center / cover no-repeat;"
                }
            >
                <HomeVerticalCenter>
                    {contentHdr}
                    {formPlacement}
                </HomeVerticalCenter>
            </DivWrapper>
            {viewModel.model.pageBody !== "" && (
                <DivWrapperNoVH backgroundcolor={"rgba(27, 43, 55, 0.75)"}>
                    <SEOStateContainer additionalcss={"max-width: 1107px; width: 100%;"}>
                        <div>{ReactHtmlParser(viewModel.model.pageBody ? viewModel.model.pageBody : "")}</div>
                    </SEOStateContainer>
                </DivWrapperNoVH>
            )}
            <DivWrapperBGFixed additionalcss={"background-image: url(" + Puddles + ");"}>
                <DivWrapper backgroundcolor={"rgba(27, 43, 55, 0.75)"}>
                    <QandAWHY />
                    <QandAFAQ />
                </DivWrapper>
            </DivWrapperBGFixed>
            <Footer isTemp={false} />
        </>
    ));
};
