import React, { useState, useRef, useEffect } from "react";
import { useMediaQuery } from "../../Core/Utils/Media/mediaQuery";
import { useRouter, useObserver, StoresInstance } from "../Globals/Base";
import { GridSpaced, TitleWrapper, DivWrapper, DivWrapperNoVH, DivWrapperBGFixed } from "../StylesAppSpecific/GridHelperStyling";
import { HdrImgDisc, BulletedListStyling } from "../StylesAppSpecific/PageStyling";
import { Grid, Button, Checkbox } from "@material-ui/core";
import iconProRegister from "../../Content/icon-proRegister.svg";
import { Container, PartnerContainer } from "../StylesAppSpecific/CarrierSettingsStyling";
import PXFuel from "../../Content/quoteBG.jpg";
import { Footer } from "../Components/Footer/Footer";
import { CarrierSetting } from "../Components/Pro/CarrierSetting";
import radioYes from "Content/radioYes.svg";
import radioYesChecked from "Content/radioYes-checked.svg";
import { RememberMeLabel } from "Custom/StylesAppSpecific/LoginStyling";
import { NFIPartnerAgreementViewModel } from "Custom/ViewModels";
import SignatureCanvas from "react-signature-canvas";
import { Redirect } from "react-router-dom";
import { CarrierSettingViewModel } from "Custom/ViewModels/CarrierSettingViewModel";
import { AgencyAPIValidationLoading } from "Custom/Components/IsLoading";
import ProBackground from "Content/ProBackground.png";
import { carrierDBNameMap } from "Custom/ViewModels/CarrierSettingsViewModel";
import logoAonEdge from "Content/icon-logoAonEdge.png";

export const NFIPartnerPage: React.FC = () => {
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const isMobile = !!useMediaQuery("(max-width: 767px)");
    const { history } = useRouter();
    const [viewModel] = useState(() => StoresInstance.domain.CarrierSettingsViewModel);
    const [partner, setPartner] = useState(false);

    if (viewModel === undefined) {
        return <Redirect to="/carrier-settings" />;
    }

    const handleSubmit = async () => {
        if (!partner) {
            setIsLoading(true);
            viewModel.userAgencyDetails = viewModel.userAgencyDetails.filter(uad => uad && !uad.isNFIPartnerAgency);
            const result = await viewModel.upsert();
            setIsLoading(false);
            if (result.wasSuccessful) {
                history.push(`/agent`);
            } else if (result.errors && result.errors[0] && result.errors[0].message) {
                setErrorMessage(result.errors[0].message);
            } else {
                setErrorMessage("An unknown error occured.");
            }
        } else {
            history.push(`/nfipartner-agreement`);
        }
    };

    const contentHdr = (
        <>
            <GridSpaced container margin={"0px auto"} additionalcss={"max-width: 688px; width: 100%;"}>
                <GridSpaced item xs={12} sm={12} additionalcss={"text-align: center;"}>
                    <HdrImgDisc src={iconProRegister} alt={""} />
                </GridSpaced>
            </GridSpaced>

            <GridSpaced container margin={"0px auto"} additionalcss={"width: 100%;"}>
                <Grid item xs={12} sm={12} justify={"center"}>
                    <TitleWrapper padding={"0"} margin={"0"}>
                        <h1>Use National Flood Insurance as your partner agency?</h1>
                    </TitleWrapper>
                </Grid>
            </GridSpaced>
        </>
    );

    const noCarriers = (
        <>
            <h2>As you have no carrier accounts you will need to sign up with National Flood Insurance to process quotes.</h2>
            <DivWrapper className="divider" margin={"34px auto"} additionalcss={"min-height: unset;"} />
        </>
    );

    const hasCarriers = (
        <>
            {viewModel.userAgencyDetails.find(uad => uad && !uad.isNFIPartnerAgency) && (
                <>
                    <h2>Currently you will only be able to process quotes from</h2>
                    <div className="carrier-list">
                        <div className="divider" />
                        {viewModel.userAgencyDetails.map(agencyDetail => (
                            <React.Fragment key={`carrier-list-name-${agencyDetail.insurerName}`}>
                                {!agencyDetail.isNFIPartnerAgency && agencyDetail.insurerName !== "Zurich" ? (
                                    <h2>{agencyDetail.insurerName} account</h2>
                                ) : (
                                    undefined
                                )}
                            </React.Fragment>
                        ))}
                        <div className="divider" />
                    </div>
                </>
            )}
        </>
    );

    return useObserver(() => (
        <>
            <DivWrapperBGFixed
                additionalcss={
                    "background: linear-gradient(to top, rgb(20, 32, 41) 10%, rgb(0, 0, 0, 0.0)), url(" + ProBackground + ") center center / cover no-repeat;"
                }
            >
                {
                    /* isLoading ? (
					<AgencyAPIValidationLoading />
				) : ( */
                    <div style={{ margin: "0 20px" }}>
                        <DivWrapper
                            margin={"0px auto"}
                            additionalcss={isMobile ? "max-width: 1124px; padding-bottom: 80px;" : "max-width: 1124px; padding-bottom: 100px;"}
                        >
                            <PartnerContainer>
                                {contentHdr}
                                {viewModel.userAgencyDetails.length == 0 && noCarriers}
                                {viewModel.userAgencyDetails.length > 0 && hasCarriers}
                                <h2>Partnering with National Flood insurance allows you to process quotes from the following carriers:</h2>
                                <div className="carrier-logos">
                                    {Object.keys(viewModel.CarrierViewModels).map(cvmKey => {
                                        const cvm: CarrierSettingViewModel = viewModel.CarrierViewModels[cvmKey]!;
                                        if (["zurich", "hiscoxFloodPlus"].includes(cvmKey)) return null;

                                        return (
                                            <React.Fragment key={`carrier-list-logo-${cvmKey}`}>
                                                {viewModel.userAgencyDetails.find(u => u.insurerName === carrierDBNameMap[cvmKey])?.isNFIPartnerAgency ? (
                                                    <img src={cvm.image} alt={cvm.name} />
                                                ) : null}
                                            </React.Fragment>
                                        );
                                    })}
                                    <img src={logoAonEdge} alt="Aon Edge" />
                                </div>
                                <p>
                                    The agency partner program was designed to give insurance agencies easier access to multiple private flood insurance
                                    companies. By signing the agency partnership agreement, you receive the following benefits:
                                </p>
                                <BulletedListStyling>
                                    <li>We write, service, and retain flood policies for you</li>
                                    <li>You get paid through a generous commission structure</li>
                                    <li>Instant connection to many of the top-rated private flood carriers National Flood Insurance, LLC offers</li>
                                    <li>Direct access to our flood insurance experts, along with additional carriers</li>
                                </BulletedListStyling>
                                <div className="divider" style={{ margin: "34px auto 0" }} />
                                <span>
                                    <RememberMeLabel
                                        control={
                                            <Checkbox
                                                name="partner"
                                                icon={<img src={radioYes} alt="Y" />}
                                                checkedIcon={<img src={radioYesChecked} alt="N" />}
                                                checked={partner}
                                                onChange={e => {
                                                    setPartner(e.target.checked);
                                                }}
                                            />
                                        }
                                        label={
                                            <h2>
                                                I would like to partner with National Flood Insurance
                                                <br />
                                                <span>(this can be done later on your dashboard from the carrier settings option)</span>
                                            </h2>
                                        }
                                    />
                                </span>
                                {errorMessage && <p style={{ color: "red", marginLeft: "40px", marginTop: "20px" }}>{errorMessage}</p>}
                                <div className="btns">
                                    <Button className="save-btn" variant="contained" onClick={() => history.goBack()} disabled={isLoading}>
                                        {isLoading ? "Loading..." : "Previous"}
                                    </Button>
                                    <Button className="save-btn" variant="contained" onClick={handleSubmit} disabled={isLoading}>
                                        {isLoading ? "Loading..." : "Next"}
                                    </Button>
                                    <Button className="cancel-btn" variant="contained" onClick={() => history.push("./")}>
                                        Cancel
                                    </Button>
                                </div>
                            </PartnerContainer>
                        </DivWrapper>
                    </div>
                    /* ) */
                }
            </DivWrapperBGFixed>
            {/* isLoading || */ <Footer isTemp={false} />}
        </>
    ));
};
