// Libraries
import * as MobX from "mobx";

// Core
import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";

// Custom
import { Server } from "../../Globals/AppUrls";
import { AdminStatsAndLatestUserQuotesModel, AdminStatsAndLatestUserQuotesDTO } from "../../Models/AdminStatsAndLatestQuotesModel";
import { LatestUserQuoteModel } from "../../Models/LatestUserQuoteModel";
import { moneyFormat } from "../../Utils/Money";
import { IWarningBanner } from "../../Models/IWarningBanner";
import { ApiResult } from "../../../Core/Models/ApiResult";
import { StoresInstance } from "../../Stores/Stores";

export default class AdminDashboardViewModel extends ViewModelBase<AdminStatsAndLatestUserQuotesModel> {
    // #region Overall Statistics

    @MobX.computed
    public get numberOfQuotesProduced() {
        return moneyFormat(this.model.adminStatistics.numberOfQuotesProduced);
    }

    @MobX.computed
    public get numberOfQuotesSelected() {
        return moneyFormat(this.model.adminStatistics.numberOfQuotesSelected);
    }

    @MobX.computed
    public get numberOfRegsisteredUsers() {
        return moneyFormat(this.model.adminStatistics.numberOfRegsisteredUsers);
    }

    // #endregion Overall Statistics

    // #region Insurer Statistics

    @MobX.computed
    public get insurerStatistics() {
        const transformedInsurers: any[] = [];

        if (this.model.adminStatistics.insurerStatistics !== undefined) {
            const insurers = Array.from(this.model.adminStatistics.insurerStatistics.entries());

            insurers.map(insurer => {
                transformedInsurers.push([insurer[0], insurer[1]]);
            });
        }

        return transformedInsurers;
    }

    // #endregion Insurer Statistics

    // #region Users

    @MobX.computed
    public get users() {
        return this.model.users;
    }

    // #endregion Users

    // #region Warning Banner

    @MobX.observable
    public warningMessageError = "";

    @MobX.observable
    public warningMessageDirty = false;

    @MobX.observable
    public warningMessage = "";

    @MobX.observable
    public showWarningBanner = false;

    public saveWarningMessage() {
        this.upsertWarningBanner().then(() => {
            MobX.runInAction(() => {
                this.warningMessageDirty = false;
            });
        });
    }

    @MobX.action
    public saveShowWarningBanner(e: React.ChangeEvent<HTMLInputElement>) {
        this.showWarningBanner = e.target.checked;
        this.upsertWarningBanner();
    }

    @MobX.action
    public setWarningMessage(value: string) {
        this.warningMessageDirty = true;
        this.warningMessage = value;
    }

    private async getWarningBanner() {
        let response: undefined | ApiResult<IWarningBanner>;
        try {
            this.setWarningMessageError("");
            response = await this.Get(Server.Api.WarningBanner.GetWarningBanner);
            if (response && response.wasSuccessful) {
                if (response.payload) {
                    MobX.runInAction(() => {
                        this.warningMessage = response!.payload.warningBannerText;
                        this.showWarningBanner = response!.payload.warningBannerEnabled;
                    });
                }
            } else if (response && response.errors && response.errors[0] && response.errors[0].message) {
                this.setWarningMessageError(response.errors[0].message);
            } else {
                this.unknownWarningMessageError();
            }
        } catch {
            this.unknownWarningMessageError();
        }

        return response;
    }

    private async upsertWarningBanner() {
        const warningBanner: IWarningBanner = {
            warningBannerEnabled: this.showWarningBanner,
            warningBannerText: this.warningMessage,
        };

        let response: undefined | ApiResult<IWarningBanner>;
        try {
            this.setWarningMessageError("");
            response = await this.Post(Server.Api.WarningBanner.UpsertWarningBanner, warningBanner);
            if (response && response.wasSuccessful) {
                if (this.showWarningBanner) {
                    StoresInstance.domain.warningBanner = this.warningMessage;
                } else {
                    StoresInstance.domain.warningBanner = null;
                }
            } else if (response && response.errors && response.errors[0] && response.errors[0].message) {
                this.setWarningMessageError(response.errors[0].message);
            } else {
                this.unknownWarningMessageError();
            }
        } catch {
            this.unknownWarningMessageError();
        }

        return response;
    }

    @MobX.action
    private setWarningMessageError(value: string) {
        this.warningMessageError = value;
    }

    private unknownWarningMessageError() {
        this.setWarningMessageError("An unknown error occured.");
    }

    // #endregion Warning Banner

    /**
     * Constructor
     */
    constructor() {
        super(new AdminStatsAndLatestUserQuotesModel());

        this.saveWarningMessage = this.saveWarningMessage.bind(this);
        this.saveShowWarningBanner = this.saveShowWarningBanner.bind(this);

        this.setDecorators(AdminStatsAndLatestUserQuotesModel);

        this.load();
        this.getWarningBanner();
    }

    // #region Navigation

    public selectUser = (data: LatestUserQuoteModel) => {
        this.history.push(`/adminuserreport/${data.id}`);
    };

    // #endregion Navigation

    // #region Server Api

    public load = async (): Promise<void> => {
        try {
            this.setIsLoading(true);

            const apiResult = await this.Post<AdminStatsAndLatestUserQuotesDTO>(Server.Api.Admin.GetStatsAndLatestUserQuotes);

            if (apiResult.wasSuccessful) {
                this.model.fromDto(apiResult.payload);
            } else {
                this.setIsErrored(true);
            }
        } catch (exception) {
            this.setIsErrored(true);
        } finally {
            this.setIsLoading(false);
        }
    };

    // #endregion Server Api

    // #region Viewmodel Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Viewmodel Boilerplate
}
