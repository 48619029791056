import React, { useState } from "react";
import { useObserver } from "mobx-react-lite";
import { styled, useEffect, StoresInstance, useRouter } from "../Globals/Base";
import { EditableInput } from "../../Core/Components/EditableInput";
import UserViewModel from "../ViewModels/UserViewModel";
import { Button, Grid } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { User } from "Custom/Models/User";
import { ButtonLink } from "Custom/StylesAppSpecific/AdminStyling";

interface IProps {
    newUser?: boolean;
    viewModel: UserViewModel;
}

const Container = styled(Box)`
    padding: 36px 40px 0%;
    background-color: #3a4650;

    label {
        color: white;
        font-weight: bold;
        display: block;
        font-size: 1.5rem;
        line-height: 100%;

        input {
            padding-left: 20px;
            padding-right: 20px;
            border-radius: 15px !important;
            background-color: white;

            ::placeholder {
                font-style: italic;
            }
        }

        .MuiFormControl-root {
            width: 100%;
        }

        .MuiSelect-iconOutlined {
            top: 21px;
            right: 25px;
        }

        .MuiSelect-root {
            background-color: white;
            border-radius: 15px;
            padding-left: 20px;
            height: 30px;
            padding-top: 15px;
            padding-bottom: 5px;
        }

        > div {
            margin-top: 35px;
            font-size: 0;
        }
    }

    .MuiOutlinedInput-notchedOutline {
        border-radius: 15px !important;
        border-width: 7px !important;
    }

    .MuiOutlinedInput-root:not(.Mui-error) .MuiOutlinedInput-notchedOutline {
        border-color: #f2af4c !important;
    }
`;

const ButtonBox = styled(Box)`
    margin-top: 40px;
    margin-right: -40px;
    margin-left: -40px;
    padding: 40px;
    display: flex;
    justify-content: center;
    background-color: #666f77;
`;

interface IUserFormBtnProps {
    bgColor: string;
}

const UserFormBtn = styled(Button)<IUserFormBtnProps>`
    background-color: ${({ bgColor }) => bgColor};
    width: 200px;
    border-radius: 1000px;
    color: white;
    font-size: 1.5rem;
    padding: 14px 0 15px;
    margin: 0 8px;

    :hover {
        background-color: ${({ bgColor }) => bgColor};
    }
`;

export const UserForm: React.FC<IProps> = ({ newUser, viewModel }) => {
    const bind = viewModel.getContext();
    const [errorMessage, setErrorMessage] = useState("");
    const [loader, setLoader] = useState(false);
    const { history } = useRouter();

    useEffect(() => {
        viewModel.getAccessLevelOptions();
    }, []);

    const doSubmit = (e: any) => {
        e.preventDefault();
        viewModel.setValue("terms", true);
        if (viewModel.isModelValid()) {
            setErrorMessage("");
            setLoader(true);
            viewModel.postUserDetailAsync().then((data: User) => {
                setLoader(false);
                StoresInstance.domain.ShowSnackBar("User saved successfully");
                history.push("/admin/userslist");
            });
        }
    };

    return useObserver(() => (
        <>
            <form onSubmit={doSubmit}>
                <Container>
                    <Grid container spacing={6}>
                        <Grid item xs={6}>
                            <label htmlFor="input-field-firstname">
                                User Full Name
                                <EditableInput
                                    inputProps={{ id: "input-field-firstname", variant: "outlined" } as any}
                                    viewModel={viewModel}
                                    fieldName={bind.firstName}
                                />
                            </label>
                        </Grid>
                        <Grid item xs={6}>
                            <label htmlFor="input-field-lastname">
                                &nbsp;
                                <EditableInput
                                    inputProps={{ id: "input-field-lastname", variant: "outlined" } as any}
                                    viewModel={viewModel}
                                    fieldName={bind.lastName}
                                />
                            </label>
                        </Grid>
                        <Grid item xs={6}>
                            <label htmlFor="input-field-email">
                                Email address
                                <EditableInput
                                    inputProps={{ id: "input-field-email", variant: "outlined" } as any}
                                    viewModel={viewModel}
                                    fieldName={bind.email}
                                />
                            </label>
                        </Grid>
                        <Grid item xs={6} style={{ display: "flex" }}>
                            {newUser || (
                                <label htmlFor="button-passwordreset">
                                    &nbsp;
                                    <div>
                                        <ButtonLink
                                            id="button-passwordreset"
                                            onClick={() => viewModel.resetUserPasswordAsync()}
                                            disableElevation
                                            disableFocusRipple
                                            disableRipple
                                            disableTouchRipple
                                            color="secondary"
                                            disabled={viewModel.pwResetStatus === null}
                                        >
                                            Reset user password
                                        </ButtonLink>
                                        <p
                                            style={{
                                                paddingLeft: "20px",
                                                display: "inline",
                                                position: "relative",
                                                top: "10px",
                                                fontSize: "14px",
                                            }}
                                        >
                                            {viewModel.pwResetStatus === null ? (
                                                <>Loading...</>
                                            ) : viewModel.pwResetStatus === true ? (
                                                <>Password reset.</>
                                            ) : viewModel.pwResetStatus === false ? (
                                                <>There was a problem resetting this user's password.</>
                                            ) : null}
                                        </p>
                                    </div>
                                </label>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <label htmlFor="input-field-access-level">
                                Access level
                                <EditableInput
                                    type="select"
                                    inputProps={{ id: "input-field-access-level", variant: "outlined" } as any}
                                    viewModel={viewModel}
                                    fieldName={bind.accessLevel}
                                    selectItems={viewModel.accessLevelOptionsSelect}
                                />
                            </label>
                        </Grid>
                    </Grid>
                    <p style={{ color: "red" }}>{errorMessage}</p>
                    <ButtonBox>
                        <UserFormBtn bgColor="#129BDB" variant="contained" type="submit" disabled={loader}>
                            {newUser ? "Add" : "Save"}
                        </UserFormBtn>
                        <UserFormBtn bgColor="#333333" variant="contained" onClick={() => history.push("/admin/userslist")}>
                            Cancel
                        </UserFormBtn>
                    </ButtonBox>
                </Container>
            </form>
        </>
    ));
};
