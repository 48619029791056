import * as MobX from "mobx";
import { ModelBase } from "../../../Core/Models/ModelBase";

export class ValuationModel extends ModelBase<ValuationModel, ValuationModelDTO> {
    @MobX.observable
    public value: number = 0;

    @MobX.observable
    public high: number = 0;

    @MobX.observable
    public low: number = 0;

    @MobX.observable
    public forecast_standard_deviation: number = 0;

    @MobX.observable
    public date: string = "";

    public fromDto = (model: ValuationModelDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                this[key] = model[key];
            }
        }
    };
    public toDto = (model: ValuationModel) => {};

    public resetValuation = () => {
        this.value = 0;
        this.high = 0;
        this.low = 0;
        this.forecast_standard_deviation = 0;
        this.date = "";
    };
}

export interface ValuationModelDTO {
    value: number;
    high: number;
    low: number;
    forecast_standard_deviation: number;
    date: string;
}
