import { EditPreviousOptions } from ".";
import EnterYourLocation from "Content/EnterYourLocation.svg";
import { LocationEditContainer } from "./Styles";
import React from "react";
import { StoresInstance } from "../../Stores";

export interface ILocationEdit {
    editlink?: string;
}

export const LocationEdit: React.FC<ILocationEdit> = props => {
    const domainStores = StoresInstance.domain;
    const quoteRequestVM = domainStores.QuoteRequestViewModel;

    const requestAddress: string = quoteRequestVM.getValue("address");

    const editLink = () => {
        if (props.editlink) {
            return props.editlink;
        } else {
            return "/coverage";
        }
    };

    return (
        <>
            <EditPreviousOptions image={EnterYourLocation} editLink={editLink()}>
                <LocationEditContainer>{requestAddress}</LocationEditContainer>
            </EditPreviousOptions>
        </>
    );
};
