// Libraries
import { useObserver } from "mobx-react-lite";
import MaterialTable from "material-table";
import React, { useEffect } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";

// Core
import { ApiResult } from "../../../Core/Models";
import { useRouter } from "../../../Core/Utils";

// App
import UserViewModel from "Custom/ViewModels/UserViewModel";
import { dateTimeFormat, diffDates, easternStandardTime, isEmptyDate } from "../../Utils/Time";
import { UserDTO } from "Custom/Models/User";
import { useListStyles } from "./ListStyling";
import { ButtonLink } from "../../StylesAppSpecific/AdminStyling";
import styled from "styled-components";
import { User } from "../../Models/User";
import { StoresInstance } from "../../Stores/Stores";

import iconDownloadPDF from "Content/icon-downloadPDF.svg";

const userViewModel = new UserViewModel();

const TableStyles = styled.div`
    .MuiToolbar-root {
        background-color: #3a4650;
        color: white;
        padding: 37px 30px 30px;

        .table-header {
            display: flex;
            align-items: baseline;

            .new-user-btn {
                padding-left: 10px;
            }
        }

        input {
            color: white;
        }

        .MuiIconButton-root,
        .MuiIcon-root {
            color: white;

            &.Mui-disabled {
                opacity: 0.5;
            }
        }

        .MuiInput-underline:before {
            border-color: rgba(255, 225, 225, 0.42);
        }

        .MuiInput-underline:after {
            border-color: #fff;
        }
    }

    .MuiTableCell-footer {
        border: none;

        path {
            color: white;
        }

        .MuiToolbar-root {
            padding: 14px 30px;
        }

        .MuiTypography-caption {
            color: white;
        }
    }

    .height-100p {
        height: 100%;
    }

    .float-right {
        float: right;
    }
`;

const headerStyles: React.CSSProperties = {
    backgroundColor: "#E7F6FE",
};

export const UserListContainer: React.FC = () => {
    const classes = useListStyles();
    const { history } = useRouter();

    useEffect(() => {
        userViewModel.getAccessLevelOptions();
        const response = userViewModel.loadUsersAsync().then((apiResult: ApiResult<UserDTO[]>) => {
            if (apiResult.wasSuccessful) {
            }
        });
    }, []);

    const handleRowClick = (e: any, rowData: any) => {
        history.push(`/userslist/user/${rowData.id}`);
    };

    const handleNewClick = () => {
        history.push(`/userslist/user/0`);
    };

    const clickAgreementDownload = (e: React.MouseEvent<HTMLImageElement, MouseEvent>, userId: string) => {
        e.stopPropagation();
        StoresInstance.domain.AccountStore.addDlAuthCookie();
        window.open(`/agreementpdf/downloaduser/${userId}`, "_blank");
    };

    return useObserver(() => (
        <>
            <TableStyles>
                <MaterialTable
                    columns={[
                        { title: "First name", field: "firstName" },
                        { title: "Surname", field: "lastName" },
                        { title: "Email", field: "email" },
                        { title: "Phone No.", field: "phoneNumber" },
                        {
                            title: "Access Level",
                            field: "accessLevel",
                            render: (rowData: any) => {
                                const found = userViewModel.accessLevelOptionsSelect.find(a => a.value === rowData.accessLevel);
                                return (
                                    <>
                                        <span>{found ? found.key : ""}</span>
                                        {rowData.agencyAgreement && (
                                            <Box display="inline-flex" height="21px" className="float-right" marginRight="50%">
                                                <img
                                                    src={iconDownloadPDF}
                                                    alt="Download Agreement"
                                                    role="button"
                                                    onClick={e => clickAgreementDownload(e, rowData.id)}
                                                    className="height-100p"
                                                />
                                            </Box>
                                        )}
                                    </>
                                );
                            },
                        },
                        {
                            title: "Date Created",
                            field: "createdDate",
                            render: (rowData: any) => {
                                const date = isEmptyDate(rowData.createdDate) ? "Unknown" : easternStandardTime(rowData.createdDate).format(dateTimeFormat);
                                return date;
                            },
                            customFilterAndSearch: (value: any, rowData: any) => {
                                const date = isEmptyDate(rowData.createdDate) ? "Unknown" : easternStandardTime(rowData.createdDate).format(dateTimeFormat);
                                return date.indexOf(value) != -1;
                            },
                            customSort: (lhsValue: any, rhsValue: any) => {
                                return diffDates(lhsValue.createdDate, rhsValue.createdDate);
                            },
                        },
                    ]}
                    options={{
                        pageSize: 50,
                        pageSizeOptions: [10, 20, 50, 100],
                        emptyRowsWhenPaging: false,
                        headerStyle: headerStyles,
                    }}
                    data={userViewModel.getUsers}
                    onRowClick={handleRowClick}
                    title={
                        <div className="table-header">
                            <Typography variant="h1" component="h3">
                                Users
                            </Typography>
                            <ButtonLink
                                className="new-user-btn"
                                onClick={handleNewClick}
                                color="secondary"
                                disableElevation
                                disableFocusRipple
                                disableRipple
                                disableTouchRipple
                            >
                                Add new user
                            </ButtonLink>
                        </div>
                    }
                />
            </TableStyles>
            {/* </Grid>
		</Grid> */}{" "}
        </>
    ));
};
