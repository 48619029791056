// Libraries
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";

// Custom
import { StartNewQuoteCard } from "../../AgentComponents/AgentDashboard/StartNewQuoteCard";
import { OverallStatisticsCard } from "../../AgentComponents/AgentDashboard/OverallStatisticsCard";
import { InsurerHeader } from "../../AgentComponents/AgentDashboard/InsurerHeader";
import { InsurerStatisticsCard } from "../../AgentComponents/AgentDashboard/InsurerStatisticCard";
import { useRouter } from "../../Globals/Base";
import AgentDashboardViewModel from "../../ViewModels/Agent/AgentDashboardViewModel";
import { getInsurerLogo } from "../../Utils/InsurerLogo";
import { useDashboardStyles } from "Custom/StylesAppSpecific/Agent/AgentDashboard";
import AgentInsurerStatisticsViewModel from "Custom/ViewModels/Agent/AgentInsurerStatisticsViewModel";

function tempFilter(value: AgentInsurerStatisticsViewModel) {
    return value.insurerName !== "Zurich" && value.insurerName !== "Hiscox FloodPlus";
}

export const AgentDashboard: React.FC = () => {
    const { history, location } = useRouter();
    const [viewModel] = useState(() => new AgentDashboardViewModel());
    const classes = useDashboardStyles();

    useEffect(() => {
        // If the viewmodel is to allow navigation. set the viewmodel router.
        viewModel.setRouter(history, location);

        // With SPAs, we need to move back to the top of the page.
        window.scrollTo(0, 0);
    }, []);

    const renderStartNewQuote = () => {
        return (
            <React.Fragment>
                <Grid xs={12} item>
                    <StartNewQuoteCard />
                </Grid>
            </React.Fragment>
        );
    };

    const renderOverallStatistics = () => {
        return (
            <React.Fragment>
                <Grid xs={12} item>
                    <OverallStatisticsCard
                        numberOfQuotesProduced={viewModel.numberOfQuotesProduced}
                        numberOfQuotesSelected={viewModel.numberOfQuotesSelected}
                    />
                </Grid>
            </React.Fragment>
        );
    };

    const renderInsurerHeader = () => {
        return (
            <React.Fragment>
                <Grid xs={12} item>
                    <InsurerHeader />
                </Grid>
            </React.Fragment>
        );
    };

    const renderInsurers = () => {
        return (
            <React.Fragment>
                {viewModel.userAgencyDetails.filter(tempFilter).map(userAgencyDetails => (
                    <Grid xs={12} item>
                        <InsurerStatisticsCard viewModel={userAgencyDetails} />
                    </Grid>
                ))}
            </React.Fragment>
        );
    };

    return useObserver(() => (
        <React.Fragment>
            <Grid container spacing={5} className={classes.pageGrid}>
                {renderStartNewQuote()}
                {renderOverallStatistics()}
                {renderInsurerHeader()}
                {renderInsurers()}
            </Grid>
        </React.Fragment>
    ));
};
