import { GeocodeResult } from "../../Models";
import { Stores } from "./../Stores";
import { BaseStore } from "./BaseStore";
import { observable, action } from "mobx";

export class MapStore extends BaseStore {
    @observable public location: GeocodeResult | undefined;
    @observable public latitude: number = 28.079929;
    @observable public longitude: number = -80.603523;
    @observable public address: string = "";
    @observable public zipcode: string = "";
    @observable public region: string = "";
    @observable public county: string = "";
    @observable public hasAddress: boolean = false;
    @observable public pageSource: string = "";

    public constructor() {
        super();
        this.setInitialValues();
    }

    // tslint:disable-next-line: no-empty
    public init(stores: Stores): void {}

    private setInitialValues(): void {
        this.location = undefined;
        this.latitude = 28.079929;
        this.longitude = -80.603523;
        this.address = "";
        this.zipcode = "";
        this.region = "";
        this.county = "";
        this.hasAddress = false;
        this.pageSource = "";
    }

    @action
    public SetHasAddress(hasAddress: boolean): void {
        this.hasAddress = hasAddress;
    }

    @action
    public SetDummyAddress(address: string): void {
        this.address = address;
    }

    @action
    public SetLatitudeAndLongitude(latitude: number, longitude: number): void {
        this.latitude = latitude;
        this.longitude = longitude;
    }

    @action
    public populate(store: any): void {
        this.longitude = store.longitude;
        this.latitude = store.latitude;
        this.address = store.address;
        this.zipcode = store.zipcode;
        this.region = store.region;
        this.county = store.county;
        this.hasAddress = store.hasAddress;
    }

    @action
    public SetLocation(location: GeocodeResult): void {
        this.location = location;
        this.hasAddress = false;
        if (location) {
            this.longitude = location.center[0];
            this.latitude = location.center[1];
            this.address = location.placeName;
            this.zipcode = location.zipcode;
            this.region = location.region;
            this.county = location.county;
            this.hasAddress = true;
        }
    }

    @action
    public ResetLocation(): void {
        this.location = undefined;
        this.longitude = -80.603523;
        this.latitude = 28.079929;
        this.address = "";
        this.zipcode = "";
        this.region = "";
        this.county = "";
        this.hasAddress = false;
    }

    @action SetPageSource(page: string): void {
        this.pageSource = page;
    }

    // #region Widget stuff
    @observable
    public isAvailable: boolean = false;

    @action
    public setIsAvailable = (value: boolean) => {
        this.isAvailable = value;
    };

    @observable
    public routeWithDetails: string = "";

    @action
    public setRouteWithDetails = (address: string, city: string, county: string, state: string, zipcode: string, latitude: string, longitude: string) => {
        let retVal = "/widget/redirect";
        retVal += "/" + encodeURIComponent(address);
        retVal += "/" + encodeURIComponent(city);
        retVal += "/" + encodeURIComponent(county);
        retVal += "/" + encodeURIComponent(state);
        retVal += "/" + encodeURIComponent(zipcode);
        retVal += "/" + encodeURIComponent(latitude);
        retVal += "/" + encodeURIComponent(longitude);

        this.routeWithDetails = retVal;
    };
    // #endregion Widget stuff
}
