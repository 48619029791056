// Libraries
import { action, computed, observable, runInAction } from "mobx";
import React from "react";

// Core
import { FieldType, isNullOrEmpty, toCamel } from "../../Core/Utils/Utils";
import { ViewModelBase } from "../../Core/ViewModels/ViewModelBase";

// Custom
import { Server } from "../Globals/AppUrls";
import { QuoteResponseModel, QuoteResponseModelDTO, QuoteRequestModelV2, ResultQuoteModel, PropertyQuoteModelDTO, StringModel, ResultModel } from "../Models";
import QuoteViewModel from "../ViewModels/QuoteViewModel";
import { StoresInstance, Stores } from "../Stores";
import SignalRAuthenticatedConnection from "../Stores/SignalRAuthenticatedConnection";
import PropertyQuoteStore from "../Stores/Domain/PropertyQuoteStore";
import { moneyDollarFormat } from "../Utils/Money";
import { UserAgencyDetailsModelDTO, UserAgencyDetailsModel } from "Custom/Models/UserAgencyDetailsModel";
import { ApiResult } from "Core/Models";
import Axios from "axios";
import { ReRunQuotesViewModel } from "./Admin/ReRunQuotesViewModel";
import AdminUserAndQuoteResultStore from "Custom/Stores/Domain/Admin/AdminUserAndQuoteResultStore";
import { AppUrls } from "../Globals";
import { getInsurerLogo } from "Custom/Utils/InsurerLogo";

export const resultKeys = [
    "aonEdgeResult",
    "argeniaResult",
    "fRSResult",
    "neptuneResult",
    "sterlingResult",
    "hiscoxFloodPlusResult",
    "wrightPrivateFloodResult",
    "selectiveResult",
    "zurichResult",
    "femaResult",
];

export class QuoteResultViewModel extends ViewModelBase<QuoteResponseModel> {
    @observable
    public rerunViewModel = new ReRunQuotesViewModel();

    @observable
    public blobId?: string;

    // #region Sorting

    public sortOptions: any[] = [
        { key: "Premium low to high", value: 0 },
        { key: "Premium high to low", value: 1 },
        { key: "Deductible low to high", value: 2 },
        { key: "Deductible high to low", value: 3 },
    ];

    @observable
    public sortOptionSelection: number = 0;

    @action setSortOption = (value: number) => {
        this.sortOptionSelection = value;
    };

    @observable
    public clickedQuotes = observable<string>([]);

    @observable
    public chosenQuotes = observable<string>([]);

    // #endregion Sorting

    // #region isLoading flag
    @observable
    public isLoadingQuotes: boolean = false;

    @action
    public setIsLoadingQuotes = (value: boolean) => {
        this.isLoadingQuotes = value;
    };
    // #endregion isLoading flag

    // #region show state name (for Zurich)
    @observable
    public stateName: string = StoresInstance.domain.QuoteRequestViewModel.model.state;
    // //#endregion

    // #region for API Diagnosis
    @observable
    public forAPIDiagnosis: QuoteResponseModelDTO | undefined;

    @action
    public setForAPIDiagnosis = (value: QuoteResponseModelDTO) => {
        this.forAPIDiagnosis = value;
    };
    // #endregion for API Diagnosis

    @computed
    public get noQuotes() {
        let retVal = false;
        retVal = !this.canDisplayNFIPQuotes && !this.completedResults && !this.isLoadingQuotes;

        return retVal;
    }

    // #region Deductible Filter

    public deductibleFilterStep: number = 5;

    @observable
    public deductibleFilter: number = 0;

    @computed
    public get deductibleFilterMaxValue() {
        return this.deductibleFilterMax;
    }

    @computed
    public get formattedDeductibleFilter() {
        return moneyDollarFormat(this.deductibleFilter);
    }

    @action
    public setDeductibleFilter = (value: number) => {
        this.deductibleFilter = value;
    };

    // #endregion Deductible Filter

    // #region Displayable Quotes

    @computed
    public get showFloodZone() {
        let quoteRequestVM = StoresInstance.domain.QuoteRequestViewModel;

        return quoteRequestVM.getValue("fldZone");
    }

    @computed
    public get nfipQuotes() {
        return this.returnNFIPQuotes;
    }

    @computed
    public get wnfipQuotes() {
        return this.returnWNFIPQuotes;
    }

    @computed
    public get canDisplayWNFIPQuotes(): boolean {
        return this.wnfipQuotes.length > 0;
    }

    @computed
    public get wnfipHasPremium(): boolean {
        return this.canDisplayWNFIPQuotes ? (this.wnfipQuotes[0].premium > 0 ? true : false) : false;
    }

    @computed
    public get canDisplayNFIPQuotes(): boolean {
        return this.nfipQuotes.length > 0;
    }

    @computed
    public get nfipHasPremium(): boolean {
        return this.canDisplayNFIPQuotes ? (this.nfipQuotes[0].premium > 0 ? true : false) : false;
    }

    @computed
    public get showNFIQuotes(): boolean {
        let retVal = false;
        if (this.canDisplayWNFIPQuotes && this.wnfipQuotes[0].premium > 0) {
            retVal = true;
        }

        if (this.canDisplayNFIPQuotes && this.nfipQuotes[0].premium > 0) {
            retVal = true;
        }

        return retVal;
    }

    /**
     * Check either Wright NFIP or NFIP have returned something
     */
    @computed
    public get haveNFIPQuotes(): boolean {
        let retVal = this.canDisplayNFIPQuotes || this.canDisplayWNFIPQuotes ? true : false;
        return retVal;
    }

    @computed
    public get privateQuotes() {
        return this.filterAndSort(this.returnPrivateQuotes, this.sortOptionSelection, this.deductibleFilter);
    }

    @computed
    public get canDisplayPrivateQuotes(): boolean {
        return this.privateQuotes.length > 0;
    }

    @computed
    public get completedResults(): boolean {
        return this.model.completedResults;
    }

    // #endregion Displayable Quotes

    constructor() {
        super(new QuoteResponseModel());
        this.setDecorators(QuoteResponseModel);
    }

    @computed
    private get returnWNFIPQuotes() {
        let quotesArr: ResultQuoteModel[] = [];

        quotesArr.push.apply(quotesArr, this.model.wrightResult.quotes);

        return quotesArr;
    }

    @computed
    private get returnNFIPQuotes() {
        let quotesArr: ResultQuoteModel[] = [];

        quotesArr.push.apply(quotesArr, this.model.nFIPResult.quotes);

        return quotesArr;
    }

    @computed
    private get returnPrivateQuotes() {
        let quotesArr: ResultQuoteModel[] = [];

        /*         
        for (const key of resultKeys) {
            const quotes = (this.model[key] as ResultModel).quotes;
            Array.prototype.push.apply(quotesArr, quotes);

            const adminQuotes = (AdminUserAndQuoteResultStore.instance.model[key] as ResultModel).quotes;
            Array.prototype.push.apply(quotesArr, adminQuotes);
        } 
        */

        quotesArr.push.apply(quotesArr, this.model.aonEdgeResult.quotes);
        quotesArr.push.apply(quotesArr, this.model.argeniaResult.quotes);
        quotesArr.push.apply(quotesArr, this.model.fRSResult.quotes);
        quotesArr.push.apply(quotesArr, this.model.neptuneResult.quotes);
        quotesArr.push.apply(quotesArr, this.model.sterlingResult.quotes);
        //quotesArr.push.apply(quotesArr, this.model.selectiveResult.quotes); <-- Don't introduce YET
        quotesArr.push.apply(quotesArr, this.model.wrightPrivateFloodResult.quotes);
        quotesArr.push.apply(quotesArr, this.model.hiscoxFloodPlusResult.quotes);
        quotesArr.push.apply(quotesArr, this.model.zurichResult.quotes);

        return quotesArr;
    }

    @computed
    private get deductibleFilterMax(): number {
        const step = this.deductibleFilterStep;

        const privateDeductibales = this.returnPrivateQuotes
            .map(q => q.deductible)
            .sort((dlhs, drhs) => {
                return drhs - dlhs;
            });

        const wnfipDeductibles = this.returnWNFIPQuotes
            .map(q => q.deductible)
            .sort((dlhs, drhs) => {
                return drhs - dlhs;
            });

        const nfipDeductibles = this.returnNFIPQuotes
            .map(q => q.deductible)
            .sort((dlhs, drhs) => {
                return drhs - dlhs;
            });

        const privateDeductibalesMax = privateDeductibales.length > 0 ? (privateDeductibales[0] > step ? privateDeductibales[0] : step) : step;

        const nfipDeductiblesMax = nfipDeductibles.length > 0 ? (nfipDeductibles[0] > step ? nfipDeductibles[0] : step) : step;
        const wnfipDeductiblesMax = wnfipDeductibles.length > 0 ? (wnfipDeductibles[0] > step ? wnfipDeductibles[0] : step) : step;

        const max = privateDeductibalesMax > nfipDeductiblesMax ? privateDeductibalesMax : nfipDeductiblesMax;

        return Math.ceil(max / step) * step;
    }

    private filterAndSort = (quotes: ResultQuoteModel[], sortOptionSelection: number, deductibleFilter: number): ResultQuoteModel[] => {
        // Filter zero premiums.
        var filteredQuotes = quotes.filter(q => q.total !== 0);

        // Filter by deductible.
        filteredQuotes = filteredQuotes.filter(q => q.deductible >= deductibleFilter);

        // Sort by option.
        switch (sortOptionSelection) {
            case 0:
                filteredQuotes.sort((qlhs, qrhs) => qlhs.total - qrhs.total);
                break;

            case 1:
                filteredQuotes.sort((qlhs, qrhs) => qrhs.total - qlhs.total);
                break;

            case 2:
                filteredQuotes.sort((qlhs, qrhs) => qlhs.deductible - qrhs.deductible);
                break;

            case 3:
                filteredQuotes.sort((qlhs, qrhs) => qrhs.deductible - qlhs.deductible);
                break;
        }

        return filteredQuotes;
    };

    @action
    public resetAll = () => {
        AdminUserAndQuoteResultStore.instance.model.reset();
        this.rerunViewModel = new ReRunQuotesViewModel();
        this.model.resetAll();
        this.clickedQuotes.replace([]);
        this.chosenQuotes.replace([]);
    };

    // #region Navigation

    public selectQuote = (quote: ResultQuoteModel, setAgreementNeeded: (path: string) => void): void => {
        console.log("selectQuote...");
        console.log(this.apiDetails);
        console.log(quote.name);
        if (!StoresInstance.domain.AccountStore.IsProAccount || this.apiDetails.find(a => a.insurerName === quote.name)) {
            this.history.push(`/policy/${quote.name}/${quote.id}`);
        } else {
            setAgreementNeeded(`/policy/${quote.name}/${quote.id}`);
        }
    };

    public selectEmptyNFIP = (setAgreementNeeded: (path: string) => void): void => {
        if (!StoresInstance.domain.AccountStore.IsProAccount || this.apiDetails.find(a => a.insurerName === "Wright NFIP")) {
            this.history.push(`/policy/Wright NFIP/notQuoted`);
        } else {
            setAgreementNeeded(`/policy/Wright NFIP/notQuoted`);
        }
    };

    public selectQuoteForPDF = (quote: ResultQuoteModel): void => {
        const params = new URLSearchParams({
            blobId: this.blobId!,
            quoteId: quote.id,
            tz: new Date().getTimezoneOffset().toString(),
        });

        window.open(`${AppUrls.Server.Api.Quotes.GetQuotePDF}?${params.toString()}`, "_blank");
    };

    public getQuoteSummaryPDF = (): void => {
        const params = new URLSearchParams({
            blobId: this.blobId!,
            tz: new Date().getTimezoneOffset().toString(),
        });

        window.open(`${AppUrls.Server.Api.Quotes.GetQuoteSummaryPDF}?${params.toString()}`, "_blank");
    };

    // #endregion Navigation

    // #region Server Api

    public apiDetails: UserAgencyDetailsModel[] = [];

    public canGetQuote: boolean = false;

    public getAgencyDetails = async (): Promise<void> => {
        const response = await this.Post<UserAgencyDetailsModelDTO[]>(Server.Api.Agent.GetUserAgencyDetails);

        if (response && response.wasSuccessful) {
            this.handleAPIDetails(response.payload);
        } else if (response && response.errors && response.errors[0] && response.errors[0].message) {
            // Error: response.errors[0].message
        } else {
            // Error connecting to server
        }
    };

    public handleAPIDetails = (details: UserAgencyDetailsModelDTO[]) => {
        //console.log("handleAPIDetails");
        //console.log(details);
        for (const detail of details) {
            let existingDetails = this.apiDetails.find(d => d.insurerName === detail.insurerName);
            if (!existingDetails) {
                existingDetails = new UserAgencyDetailsModel();
            }
            existingDetails.fromDto(detail);

            if (!(!existingDetails.isNFIPartnerAgency && isNullOrEmpty(existingDetails.agencyApiKey) && isNullOrEmpty(existingDetails.agencyLogin))) {
                this.apiDetails.push(existingDetails);
            }
        }
        //console.log("after handleAPIDetauls", this.apiDetails);
    };

    public getQuotes = async (): Promise<void> => {
        if (this.canGetQuote) {
            this.setIsLoadingQuotes(true);
            this.setDeductibleFilter(0);
            this.resetAll();
            const _ = (message: QuoteResponseModelDTO) => {};

            try {
                SignalRAuthenticatedConnection.instance.connection.off("quotesresults", _);

                const onReceiveMessage = (message: QuoteResponseModelDTO) => {
                    this.model.fromDto(message);
                    // If we have received all quotes, we can stop listening.
                    if (message.completedResults) {
                        console.log(message);
                        this.setForAPIDiagnosis(message);
                        this.setIsLoadingQuotes(false);
                        SignalRAuthenticatedConnection.instance.connection.off("quotesresults", onReceiveMessage);
                    }
                };
                SignalRAuthenticatedConnection.instance.connection.on("quotesresults", onReceiveMessage);

                //setTimeout(async () => {
                try {
                    const apiResult = await this.Post<PropertyQuoteModelDTO>(
                        Server.Api.Quotes.GetQuotes,
                        StoresInstance.domain.QuoteRequestViewModel.model.toDto(),
                    );

                    // We need to set is loading here as the base viewmodel sets the property
                    // to false on return, which is too early. Should be the responsibilty of
                    // the custom code to decide this.
                    this.setIsLoadingQuotes(true);

                    if (apiResult.wasSuccessful) {
                        PropertyQuoteStore.instance.model.fromDto(apiResult.payload);
                    } else {
                        this.setIsErrored(true);
                        this.setIsLoadingQuotes(false);
                    }
                } catch (exception) {
                    this.setIsErrored(true);
                    this.setIsLoadingQuotes(false);
                } finally {
                    this.canGetQuote = false;
                }
                //}, 1500);
            } catch (exception) {
                // Signal connection failed
                this.setIsErrored(true);
                this.setIsLoadingQuotes(false);
            }
        }
    };

    public getQuotesNoSR = async (): Promise<void> => {
        if (this.canGetQuote) {
            this.setIsLoadingQuotes(true);
            this.setDeductibleFilter(0);
            this.resetAll();
            let quoteRequestVM = StoresInstance.domain.QuoteRequestViewModel;
            const apiResult = await this.Post<PropertyQuoteModelDTO>(Server.Api.Quotes.GetQuotes, quoteRequestVM.model.toDto());
            if (apiResult.wasSuccessful) {
                PropertyQuoteStore.instance.model.fromDto(apiResult.payload);
                //this.setForAPIDiagnosis(StoresInstance.domain.);

                runInAction(() => {
                    this.blobId = apiResult.payload.blobId;
                });

                let quoteInterval = setInterval(async () => {
                    //Do call to blob
                    let blobResult = "https://nationalfloodinsurance.blob.core.windows.net/filestore/" + apiResult.payload.blobId + "/GetQuotesJob.json";
                    const blobApiResult: any = await Axios.get(blobResult);
                    let newCamelResult: any = toCamel(blobApiResult.data.Result);
                    this.model.fromDto(newCamelResult);
                    if (blobApiResult.data.Result.CompletedResults) {
                        clearInterval(quoteInterval);
                        this.setForAPIDiagnosis(this.model);
                        /* console.log(this.model); */
                        // following model and api call emails the Quotes to client/Agent
                        var blobIdModel: StringModel = {
                            id: apiResult.payload.blobId,
                        };
                        let apiR = await this.Post(Server.Api.Quotes.EmailQuotes, blobIdModel);
                        if (!apiR.wasSuccessful) {
                            console.log("error: " + apiResult.errors);
                        }
                        this.setIsLoadingQuotes(false);
                        this.canGetQuote = false;
                    }
                }, 5000);
            } else {
                this.setIsErrored(true);
                this.setIsLoadingQuotes(false);
            }
        }
    };

    // #endregion Server Api

    // #region Viewmodel Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<QuoteResponseModel>, value: any, showErrors: boolean = true): boolean {
        return true;
    }

    // #endregion Viewmodel Boilerplate
}
