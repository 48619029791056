import { action, computed, observable, runInAction } from "mobx";
import { FieldType, isEmptyOrWhitespace, getUrlSearchParams } from "Core/Utils/Utils";
import { LoginModel, ResetPasswordModel, ResetPasswordModelDTO } from "../Models";
import { AccountStatus, ApiResult } from "Core/Models";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { TestModel } from "../../Core/Models/TestModel";
import { AppUrls } from "Custom/Globals";

export class ResetPasswordViewModel extends ViewModelBase<ResetPasswordModel> {
    @observable
    public tokenValid?: boolean;

    constructor() {
        super(new ResetPasswordModel());
        //EN - Havent figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(ResetPasswordModel);
        //(window as any).model = this.model;
        //(window as any).proxy = proxy;
    }

    public getTokenFromUrl = () => {
        const urlSearchParams = getUrlSearchParams();
        const forgotPasswordToken = urlSearchParams.get("forgottoken");
        if (forgotPasswordToken) {
            this.model.setValue("token", forgotPasswordToken);
            this.verifyForgotPasswordTokenAsync();
        } else {
            runInAction(() => {
                this.tokenValid = false;
            });
        }
    };

    public verifyForgotPasswordTokenAsync = async (): Promise<void> => {
        let apiResult = await this.Post<ResetPasswordModelDTO>(AppUrls.Server.Api.Account.ResetPassword.VerifyToken, this.model);
        runInAction(() => {
            this.tokenValid = apiResult.wasSuccessful;

            if (this.tokenValid) {
                this.model.fromDto(apiResult.payload);
            }
        });
    };

    public resetPasswordAsync = async (): Promise<ApiResult<AccountStatus>> => {
        let apiResult = await this.Post<AccountStatus>(AppUrls.Server.Api.Account.ResetPassword.Reset, this.model);
        return apiResult;
    };

    public isFieldValid(fieldName: keyof FieldType<ResetPasswordModel>, value: any): boolean {
        let { isValid, errorMessage } = this.validateDecorators(fieldName);

        if (fieldName === "confirmPassword") {
            errorMessage = this.isConfirmPasswordValid;
            isValid = errorMessage === "";
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    //public afterUpdate: undefined;
    afterUpdate(fieldName: keyof FieldType<ResetPasswordModel>, value: any): void {
        //Do something like call an api or whatever/
        //Can use this instead of mobx reaction
        return;
    }
    //public beforeUpdate: undefined;
    beforeUpdate(fieldName: keyof FieldType<ResetPasswordModel>, value: any): any {
        //Do something and return the new value if you want or just use it to perform another action
        /* if (value === "something" && fieldName === "emailAddress") {
			value = "boo";
		} */
        return value;
    }

    @computed
    public get isPasswordValid(): string {
        let retval = "";
        if (isEmptyOrWhitespace(this.getValue("newPassword") as string)) return "Password is required";
        if (this.getValue<string>("newPassword").length < 4) return "Password must be greater than 4 characters";

        return retval;
    }

    public get isConfirmPasswordValid(): string {
        if (this.getValue("newPassword") !== this.getValue("confirmPassword") && this.getValue("newPassword") !== "") return "Passwords must match";
        return "";
    }
}
