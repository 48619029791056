// Libraries
import React from "react";
import { useObserver } from "mobx-react-lite";

// Components
import { UsersList } from "./UsersList";
import { AdminDashboard } from "./AdminDashboard";
import { Cities } from "./Cities";
import { States } from "./States";
import { Widgets } from "./Widgets";
import { Footer } from "../../Components/Footer";
import { useMediaQuery } from "Core/Utils/Media";

// Styling & images
import { Box, Tab } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useAdminStyles, TabsCustom } from "../../StylesAppSpecific/AdminStyling";
import { DivWrapper, GridSpaced, DialogueWrapper, DivWrapperBGFixed } from "../../StylesAppSpecific/GridHelperStyling";
import { AdminWrapTabDiv } from "../../StylesAppSpecific/MFIAdminStyling";
import Puddles from "Content/homeBG2.png";
import { useRouter } from "../../../Core/Utils/Hooks/UseReactRouter";
import { useEffect } from "../../Globals/Base";
import { AdminTabs } from "../../AdminComponents/AdminTabs";
import { StoresInstance } from "../../Stores/Stores";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Typography component="div" role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            <Box>{children}</Box>
        </Typography>
    );
}

const tabs = [undefined, "userslist", "editwidget", "editstate", "editcity"];

export const Admin: React.FC = () => {
    const classes = useAdminStyles();
    const [value, setValue] = React.useState(0);
    const { match } = useRouter();
    let isMobile: boolean;
    isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;

    useEffect(() => {
        if (match) {
            const { tab } = match.params as any;
            if (tab !== undefined) {
                const tabIndex = tabs.indexOf(tab);
                if (tabIndex < 0) {
                    setValue(0);
                } else {
                    setValue(tabIndex);
                }
            }
        }
    }, [match]);

    function handleChange(newValue: number) {
        setValue(newValue);
        const tab = tabs[newValue];
        if (tab === undefined) {
            window.history.replaceState(undefined, "", `/admin`); // Only set display, not through react router as we don't need to change page
        } else {
            window.history.replaceState(undefined, "", `/admin/${tab}`); // Only set display, not through react router as we don't need to change page
        }
    }

    return useObserver(() => (
        <>
            {StoresInstance.domain.AccountStore.isInRole("admin") && <AdminTabs value={value} onChange={handleChange} />}
            <DivWrapperBGFixed additionalcss={"background-image: url(" + Puddles + ");"}>
                {!isMobile && (
                    <>
                        <DivWrapper padding="40px 10px" backgroundcolor={"rgba(27, 43, 55, 0.75)"}>
                            <AdminWrapTabDiv additionalcss={"max-width: 1140px; width: 100%;"}>
                                <TabPanel value={value} index={0}>
                                    <AdminDashboard />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <UsersList />
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <Widgets />
                                </TabPanel>
                                <TabPanel value={value} index={3}>
                                    <States />
                                </TabPanel>
                                <TabPanel value={value} index={4}>
                                    <Cities />
                                </TabPanel>
                            </AdminWrapTabDiv>
                        </DivWrapper>
                    </>
                )}
                {isMobile && (
                    <>
                        <DivWrapper padding="10px" backgroundcolor={"rgba(27, 43, 55, 0.75)"}>
                            <AdminWrapTabDiv additionalcss={"max-width: 1140px; width: 100%;"}>
                                <TabPanel value={value} index={0}>
                                    <AdminDashboard />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <UsersList />
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <Widgets />
                                </TabPanel>
                                <TabPanel value={value} index={3}>
                                    <States />
                                </TabPanel>
                                <TabPanel value={value} index={4}>
                                    <Cities />
                                </TabPanel>
                            </AdminWrapTabDiv>
                        </DivWrapper>
                    </>
                )}
            </DivWrapperBGFixed>
            <Footer isTemp={false} />
        </>
    ));
};
