import { Box, Button, Fade, Paper, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { styled, useObserver } from "../Globals/Base";

// Components
import { Footer } from "../Components/Footer";

import { EditableInput } from "Core/Components/EditableInput";
import { ForgotPasswordModel } from "../Models";
import { ForgotPasswordViewModel } from "../ViewModels/ForgotPasswordViewModel";
import { useRouter } from "Core/Utils";
import { DivWrapper, GridSpaced, DialogueWrapper, DivWrapperBGFixed } from "../StylesAppSpecific/GridHelperStyling";
import Puddles from "Content/homeBG2.png";
import MFIheaderlogo from "Content/logoForDarkBG.png";
import { LoginBox, SignInButton, LoginForm, HeaderLogo, ForgotPasswordButton } from "Custom/StylesAppSpecific/LoginStyling";
import { Centered } from "Core/Components/Centered";
import iconTick from "Content/icon-check.svg";

export const ForgotPasswordPage: React.FunctionComponent = () => {
    const [viewModel] = useState(() => new ForgotPasswordViewModel());
    const { history } = useRouter();
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const doSubmit = async (e: any) => {
        e.preventDefault();
        if (viewModel.isModelValid()) {
            setIsLoading(true);
            let apiResult: any = await viewModel.ForgotPasswordAsync();
            if (apiResult !== null) {
                if (apiResult.wasSuccessful) {
                    history.push(`/forgotpassword-success`);
                } else {
                    if (apiResult && apiResult.errors && apiResult.errors.length > 0) {
                        setErrorMessage("* " + apiResult.errors[0].message);
                    } else {
                        setErrorMessage("* Unknown error has occurred.");
                    }
                }
            }
            setIsLoading(false);
        }
    };

    return useObserver(() => (
        <>
            <DivWrapperBGFixed additionalcss={"background-image: url(" + Puddles + ");"}>
                <DivWrapper backgroundcolor={"rgba(27, 43, 55, 0.75)"}>
                    <Centered>
                        <Box textAlign="center">
                            <HeaderLogo src={MFIheaderlogo} alt="My Flood Insurance" />
                        </Box>
                        <Fade in timeout={1000}>
                            <Box paddingBottom="80px">
                                <Box border="10px #129BDB solid" borderRadius="28px" bgcolor="white" padding="30px 30px 0">
                                    <LoginBox padding="0 115px 5px">Forgotten Password</LoginBox>
                                    <LoginForm onSubmit={doSubmit}>
                                        {errorMessage !== "" && (
                                            <Typography variant="caption" style={{ color: "red" }}>
                                                {errorMessage}
                                            </Typography>
                                        )}
                                        <Typography variant="body1" color="textPrimary" align="center" style={{ maxWidth: "fit-content" }}>
                                            If you have forgotten your password, please enter your email address below and we will send you a link to reset it.
                                        </Typography>
                                        <label htmlFor="input-email">
                                            Your email address
                                            <EditableInput
                                                type="email"
                                                validateOnBlur={true}
                                                viewModel={viewModel}
                                                fieldName="emailAddress"
                                                inputProps={
                                                    {
                                                        id: "input-email",
                                                        placeholder: "Your email address",
                                                        variant: "outlined",
                                                    } as any
                                                }
                                            />
                                        </label>
                                        <Box padding="10px 0"></Box>

                                        <ForgotPasswordButton type="submit" variant="contained" disabled={isLoading}>
                                            {/* isLoading ? "Please Wait..." : "Sign in" */}
                                            Send
                                        </ForgotPasswordButton>
                                    </LoginForm>
                                </Box>
                            </Box>
                        </Fade>
                    </Centered>
                </DivWrapper>
            </DivWrapperBGFixed>
            <Footer isTemp={false} />
        </>
    ));
};
