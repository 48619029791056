// Libraries
import React, { useState, useContext, useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "Core/Utils";
import { Redirect } from "react-router";

// App
import { StoresContext, Stores, StoresInstance } from "Custom/Stores";
import { LoginViewModel } from "../ViewModels/LoginViewModel";
import { nameofFactory } from "Core/Utils/Utils";
import { LoginModel } from "../Models";
import { Centered } from "Core/Components/General";

// Components
import { Footer } from "../Components/Footer";

// Styling & images
import { Theme, Fade, Box, Avatar, Typography, Button, Paper, Link, FormControlLabel, Checkbox } from "@material-ui/core";
import MFIheaderlogo from "Content/logoForDarkBG.png";
import radioYes from "Content/radioYes.svg";
import radioYesChecked from "Content/radioYes-checked.svg";
import { HeaderLogo, LoginForm, LoginBox, RememberMeLabel, SignInButton, ShowPasswordIcon } from "../StylesAppSpecific/LoginStyling";

import { AppHeader } from "../../Core/Components/Styles/Styles";
import { DivWrapper, GridSpaced, DialogueWrapper, DivWrapperBGFixed } from "../StylesAppSpecific/GridHelperStyling";
import { AdminWrapTabDiv } from "../StylesAppSpecific/MFIAdminStyling";
import Puddles from "Content/homeBG2.png";
import LockOutlinedIcon from "@material-ui/core/SvgIcon/SvgIcon";
import { EditableInput } from "Core/Components/EditableInput";
import { styled } from "../Globals/Base";
import "icon-Eye-closed.svg";
import EyeClosed from "Content/icon-Eye-closed.svg";
import EyeOpen from "Content/icon-Eye-open.svg";

const domainStores = StoresInstance.domain;
const nameof = nameofFactory<LoginModel>();

export const LoginPage: React.FunctionComponent = () => {
    const { history, location, match } = useRouter();
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [loginViewModel] = useState(() => new LoginViewModel());
    const [showPassword, setShowPassword] = useState(false);

    if (domainStores.AccountStore.IsLoggedIn) {
        return <Redirect to={"/"} />;
    }

    useEffect(() => {}, []);

    const goToForgotPassword = () => {
        history.push("/forgotpassword");
    };

    const doSubmit = async (e: any) => {
        e.preventDefault();
        if (loginViewModel.isModelValid()) {
            setIsLoading(true);
            let apiResult = await loginViewModel.login();
            if (apiResult !== null) {
                if (apiResult.wasSuccessful) {
                    domainStores.AccountStore.getLoginState(apiResult.payload);
                    if (domainStores.AccountStore.isInRole("admin") || domainStores.AccountStore.isInRole("producer")) {
                        history.push("/admin");
                    } else {
                        const { from } = (location.state as any) || {
                            from: { pathname: domainStores.AccountStore.IsProAccount ? "/agent" : "/dashboard" },
                        };
                        if (from) {
                            history.push(from.pathname);
                        }
                    }
                } else {
                    if (apiResult && apiResult.errors && apiResult.errors.length > 0) {
                        setErrorMessage("* " + apiResult.errors[0].message);
                    } else {
                        setErrorMessage("* Unknown error has occurred.");
                    }
                }
            }
            setIsLoading(false);
        }
    };

    let handleShowPassword = (e: any) => {
        setShowPassword(!showPassword);
    };

    return useObserver(() => (
        <>
            <DivWrapperBGFixed additionalcss={"background-image: url(" + Puddles + ");"}>
                <DivWrapper backgroundcolor={"rgba(27, 43, 55, 0.75)"}>
                    <Centered>
                        {/* <Box textAlign="center">
							<HeaderLogo src={MFIheaderlogo} alt="My Flood Insurance" />
						</Box> */}
                        <Fade in timeout={1000}>
                            <Box paddingBottom="80px">
                                <Box border="10px #129BDB solid" borderRadius="28px" bgcolor="white" padding="30px 30px 0">
                                    <LoginBox padding="0 115px 5px">
                                        <span className="blue-text">Login</span> to your account
                                    </LoginBox>
                                    <LoginForm onSubmit={doSubmit}>
                                        {errorMessage !== "" && (
                                            <Typography variant="caption" style={{ color: "red" }}>
                                                {errorMessage}
                                            </Typography>
                                        )}
                                        <label htmlFor="input-email">
                                            Your email address
                                            <EditableInput
                                                type="email"
                                                validateOnBlur={true}
                                                viewModel={loginViewModel}
                                                fieldName="emailAddress"
                                                inputProps={
                                                    {
                                                        id: "input-email",
                                                        placeholder: "Your email address",
                                                        variant: "outlined",
                                                    } as any
                                                }
                                            />
                                        </label>
                                        <Box position="relative">
                                            <label htmlFor="input-pass" style={{ position: "relative" }}>
                                                Your password
                                                <EditableInput
                                                    type={showPassword ? "text" : "password"}
                                                    viewModel={loginViewModel}
                                                    fieldName="password"
                                                    inputProps={
                                                        {
                                                            id: "input-pass",
                                                            placeholder: "Your password",
                                                            variant: "outlined",
                                                        } as any
                                                    }
                                                />
                                                <ShowPasswordIcon
                                                    onClick={(e: any) => handleShowPassword(e)}
                                                    title={showPassword ? "Hide password" : "Show password"}
                                                >
                                                    <img src={showPassword ? EyeClosed : EyeOpen} alt="" />
                                                </ShowPasswordIcon>
                                            </label>
                                            <Link
                                                className="forgot-pass-link"
                                                onClick={() => goToForgotPassword()}
                                                style={{
                                                    position: "absolute",
                                                    right: 0,
                                                    bottom: loginViewModel.getValid("password") ? undefined : 0,
                                                    cursor: "pointer",
                                                }}
                                            >
                                                Forgotten password
                                            </Link>
                                        </Box>
                                        <RememberMeLabel
                                            control={
                                                <Checkbox
                                                    name="rememberMe"
                                                    icon={<img src={radioYes} alt="Y" />}
                                                    checkedIcon={<img src={radioYesChecked} alt="N" />}
                                                    checked={loginViewModel.getValue("rememberMe")}
                                                    onChange={e => loginViewModel.setValue("rememberMe", e.target.checked)}
                                                />
                                            }
                                            label="Keep me signed in"
                                        />
                                        <SignInButton type="submit" variant="contained" disabled={isLoading}>
                                            {/* isLoading ? "Please Wait..." : "Sign in" */}Sign in
                                        </SignInButton>
                                    </LoginForm>
                                </Box>
                            </Box>
                        </Fade>
                    </Centered>
                </DivWrapper>
            </DivWrapperBGFixed>
            <Footer isTemp={false} />
        </>
    ));
};
