import * as MobX from "mobx";
import { ModelBase } from "../../../Core/Models/ModelBase";

export class WarningPropertyModel extends ModelBase<WarningPropertyModel, WarningPropertyModelDTO> {
    @MobX.observable
    public fips: string = "";

    @MobX.observable
    public apn: string = "";

    public fromDto = (model: WarningPropertyModelDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                this[key] = model[key];
            }
        }
    };
    public toDto = (model: WarningPropertyModel) => {};

    public resetWarningProperty = () => {
        this.fips = "";
        this.apn = "";
    };
}

export interface WarningPropertyModelDTO {
    fips: string;
    apn: string;
}
