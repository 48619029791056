// User interface friendly strings.
const FOUNDATIONTYPE_SLAB = "Slab on grade";
const FOUNDATIONTYPE_BASEMENT = "Raised floor with basement";
const FOUNDATIONTYPE_CRAWLSPACE = "Raised floor with crawlspace";
const FOUNDATIONTYPE_PIERS = "Elevated on piers";
const FOUNDATIONTYPE_UNKNOWN = "Other, not sure";

// Transform by foundation type.
export const getFoundationType = (type: string): string => {
    let name = "";

    switch (type) {
        case "slab":
            name = FOUNDATIONTYPE_SLAB;
            break;

        case "basement":
            name = FOUNDATIONTYPE_BASEMENT;
            break;

        case "crawlspace":
            name = FOUNDATIONTYPE_CRAWLSPACE;
            break;

        case "piers":
            name = FOUNDATIONTYPE_PIERS;
            break;

        case "other":
        default:
            name = FOUNDATIONTYPE_UNKNOWN;
    }

    return name;
};
