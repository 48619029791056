// Libraries
import React from "react";
import { Typography, Paper } from "@material-ui/core";

// Custom
import { useOverallStatisticsStyles } from "../../StylesAppSpecific/Admin/AdminDashboard";

interface IProps {
    title: string;
    text: string;
}

export const OverallStatisticCard: React.FC<IProps> = (props: IProps) => {
    const classes = useOverallStatisticsStyles();

    return (
        <Paper className={classes.paper}>
            <Typography className={classes.title}>{props.title}</Typography>
            <Typography className={classes.text}>{props.text}</Typography>
        </Paper>
    );
};
