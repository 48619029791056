// Libraries
import * as MobX from "mobx";

// Core
import { FieldType, isEmptyOrWhitespace, toCamel } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";

// Custom
import { Server } from "../../Globals/AppUrls";
import { ResultModel, ResultQuoteModel, StringModel } from "../../Models";
import { AdminUserAndQuoteResultModel, AdminUserAndQuoteResultModelDTO } from "../../Models/AdminUserAndQuoteResultModel";
import { moneyDollarFormat } from "../../Utils/Money";
import AdminUserAndQuoteResultStore from "../../Stores/Domain/Admin/AdminUserAndQuoteResultStore";
import axios from "axios";
import { ReRunQuotesViewModel } from "./ReRunQuotesViewModel";
import { resultKeys } from "../QuoteResultViewModel";
import { AppUrls } from "Custom/Globals";

export default class AdminUserReportQuotesViewModel extends ViewModelBase<AdminUserAndQuoteResultModel> {
    @MobX.observable
    public rerunViewModel?: ReRunQuotesViewModel;

    // #region Sorting

    public sortOptions: any[] = [
        { key: "Premium low to high", value: 0 },
        { key: "Premium high to low", value: 1 },
        { key: "Deductible low to high", value: 2 },
        { key: "Deductible high to low", value: 3 },
    ];

    @MobX.observable
    public sortOptionSelection: number = 0;

    @MobX.action
    public setSortOption = (value: number) => {
        this.sortOptionSelection = value;
    };

    // #endregion Sorting

    @MobX.observable
    public blobId: string = "";

    @MobX.computed
    public get name() {
        return `${AdminUserAndQuoteResultStore.instance.model.firstName} ${AdminUserAndQuoteResultStore.instance.model.lastName}`;
    }

    @MobX.computed
    public get address() {
        return AdminUserAndQuoteResultStore.instance.model.request.address;
    }

    @MobX.computed
    public get deductible() {
        return moneyDollarFormat(AdminUserAndQuoteResultStore.instance.model.deductible);
    }

    // #region Displayable Quotes

    @MobX.computed
    public get nfipQuotes() {
        return this.returnNFIPQuotes;
    }

    @MobX.computed
    public get canDisplayNFIPQuotes(): boolean {
        return this.nfipQuotes.length > 0;
    }

    @MobX.computed
    public get privateQuotes() {
        return this.filterAndSort(this.returnPrivateQuotes, this.sortOptionSelection);
    }

    @MobX.computed
    public get canDisplayPrivateQuotes(): boolean {
        return this.privateQuotes.length > 0;
    }

    // #endregion Displayable Quotes

    /**
     * Constructor
     *
     * @param blobId The quote result to load (if defined).
     */
    constructor(blobId: string) {
        super(new AdminUserAndQuoteResultModel());
        this.setDecorators(AdminUserAndQuoteResultModel);
        this.blobId = blobId;

        if (!isEmptyOrWhitespace(blobId) && blobId !== "null") {
            (async () => {
                let doAdminCall = true;
                // Only load the quote results if the blob id has changed.
                if (blobId !== AdminUserAndQuoteResultStore.instance.model.blobId) {
                    AdminUserAndQuoteResultStore.instance.model.reset();
                    doAdminCall = await this.load(blobId);
                }
                if (doAdminCall) {
                    const apiResult1 = await this.Post<string | undefined>(Server.Api.Admin.GetAdminQuotes, { id: blobId });

                    MobX.runInAction(() => {
                        this.rerunViewModel = new ReRunQuotesViewModel(apiResult1.wasSuccessful ? apiResult1.payload : undefined);
                    });
                }
            })();
        } else {
            AdminUserAndQuoteResultStore.instance.model.reset();
        }
    }

    @MobX.computed
    private get returnNFIPQuotes() {
        let quotesArr: ResultQuoteModel[] = [];

        quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.wrightResult.quotes);

        return quotesArr;
    }

    @MobX.computed
    private get returnPrivateQuotes() {
        let quotesArr: ResultQuoteModel[] = [];

        quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.aonEdgeResult.quotes);
        quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.argeniaResult.quotes);
        quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.fRSResult.quotes);
        quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.neptuneResult.quotes);
        quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.sterlingResult.quotes);
        //quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.wrightPFAPlusResult.quotes);
        quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.hiscoxFloodPlusResult.quotes);
        quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.wrightPrivateFloodResult.quotes);
        quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.selectiveResult.quotes);
        //quotesArr.push.apply(quotesArr, AdminUserAndQuoteResultStore.instance.model.zurichResult.quotes);

        return quotesArr;
    }

    private filterAndSort = (quotes: ResultQuoteModel[], sortOptionSelection: number): ResultQuoteModel[] => {
        // Filter zero premiums.
        var filteredQuotes = quotes.filter(q => q.total !== 0);

        // Sort by option.
        switch (sortOptionSelection) {
            case 0:
                filteredQuotes.sort((qlhs, qrhs) => qlhs.total - qrhs.total);
                break;

            case 1:
                filteredQuotes.sort((qlhs, qrhs) => qrhs.total - qlhs.total);
                break;

            case 2:
                filteredQuotes.sort((qlhs, qrhs) => qlhs.deductible - qrhs.deductible);
                break;

            case 3:
                filteredQuotes.sort((qlhs, qrhs) => qrhs.deductible - qlhs.deductible);
                break;
        }

        return filteredQuotes;
    };

    // #region Navigation

    public backToDashboard = () => {
        this.history.push("/admin");
    };

    public backToUserReport = () => {
        this.history.push(`/adminuserreport/${AdminUserAndQuoteResultStore.instance.model.id}`);
    };

    public selectQuote = (quoteId: string) => {
        this.history.push(`/adminuserreportquote/${this.blobId}/${quoteId}`);
    };

    public selectQuoteForPDF = (quote: ResultQuoteModel): void => {
        const params = new URLSearchParams({
            blobId: this.blobId!,
            quoteId: quote.id,
            tz: new Date().getTimezoneOffset().toString(),
        });

        window.open(`${AppUrls.Server.Api.Quotes.GetQuotePDF}?${params.toString()}`, "_blank");
    };

    // #endregion Navigation

    // #region Server Api

    /**
     * Loads the specified quotes from the server.
     *
     * @param id The unique identifier of the quote result to load.
     */
    public load = async (id: string): Promise<boolean> => {
        try {
            this.setIsLoading(true);

            const apiResult = await this.Post<AdminUserAndQuoteResultModelDTO>(Server.Api.Admin.GetUserAndQuoteResult, { id });

            if (apiResult.wasSuccessful) {
                AdminUserAndQuoteResultStore.instance.model.fromDto(apiResult.payload);
                return true;
            } else {
                this.setIsErrored(true);
            }
        } catch (exception) {
            this.setIsErrored(true);
        } finally {
            this.setIsLoading(false);
        }
        return false;
    };

    // #endregion Server Api

    // #region Viewmodel Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Viewmodel Boilerplate
}
